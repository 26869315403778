/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import SuccessorBorrowersRepository from 'repositories/SuccessorBorrowersRepository';

import { createRestSlice } from 'utils/RestSlice';

const successorBorrowersRestSlice = createRestSlice({
  resource: 'successorBorrower',
  repository: SuccessorBorrowersRepository,
  slices: [
    'loadSuccessorBorrower',
    'updateSuccessorBorrower',
    'deleteSuccessorBorrower',
    'createSuccessorBorrower',
    'loadSuccessorBorrowerChoices',
  ],
});

const relatedEntitiesState = {
  relatedEntities: [],
  areRelatedEntitiesLoading: false,
  relatedEntitiesLoadError: null,
};

const loansState = {
  loans: [],
  areLoansLoading: false,
  loansLoadError: null,
};

const initialState = {
  ...successorBorrowersRestSlice.initialState,
  ...relatedEntitiesState,
  ...loansState,
};

const configurationReducers = {
  startConfigurationLoad(state) {
    state.isConfigurationLoading = true;
    state.configuration = [];
    state.configurationLoadError = null;
  },
  configurationLoadFulfilled(state, { payload }) {
    state.isConfigurationLoading = false;
    state.configuration = payload;
  },
  configurationLoadRejected(state, { payload }) {
    state.isConfigurationLoading = false;
    state.configuration = [];
    state.configurationLoadError = payload;
  },
};

const successorBorrowersSlice = createSlice({
  name: 'successorBorrowers',
  initialState,
  reducers: {
    ...successorBorrowersRestSlice.reducers,
    ...configurationReducers,
  },
});

export default successorBorrowersSlice.reducer;
export const successorBorrowersRestHooks = successorBorrowersRestSlice.hooks(
  successorBorrowersSlice,
  'SuccessorBorrowersSlice',
);

export const useConfigurationActions = () => {
  const dispatch = useDispatch();

  const loadConfiguration = id => {
    dispatch(successorBorrowersSlice.actions.startConfigurationLoad());
    return SuccessorBorrowersRepository.configuration(id)
      .then(({ data }) => {
        dispatch(successorBorrowersSlice.actions.configurationLoadFulfilled(data.data));
      })
      .catch(e => {
        dispatch(successorBorrowersSlice.actions.configurationLoadRejected(e));
      });
  };

  return {
    loadConfiguration,
  };
};
