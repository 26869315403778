import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    sidebar: {
      width: theme.sizes.sidebar.width,
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
    },
    sidebarTitle: {
      margin: 0,
      marginLeft: 24,
      marginTop: 24,
      padding: 0,
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    search: {
      marginRight: 8,
    },
    table: {
      '& th, td': {
        width: 48,

        '&:first-child': {
          width: 'auto',
        },

        '&:last-child': {
          fontWeight: 'bold',
        },
      },
      '& tbody *:last-child td': {
        fontWeight: 'bold',
      },
    },
    selectContainer: {
      marginLeft: 'auto',
    },
    select: {
      outline: 0,
      border: 'none',
      padding: 0,
      backgroundColor: 'transparent',
      '&:hover, &:focus': {
        border: 'none',
        backgroundColor: 'transparent',
      },
      '&.Mui-focused': {
        border: 'none',
      },
      '&$focused': {
        backgroundColor: 'red',
      },
    },
    selectWide: {
      width: 'fit-content',
      paddingLeft: '8px',
      textAlign: 'end',
    },
    loaderWrap: {
      width: '100%',
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
  }),
  { name: 'HistoricalData' },
);
