import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';

import { useGetManagersQuery } from 'domain/manager/apiSlice';
import { ManagersService } from 'domain/manager/service';

import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { getStaffLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Managers';

export const Managers = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const {
    data: managersData,
    isFetching: isManagersOptionsLoading,
    error: managersOptionsLoadError,
  } = useGetManagersQuery();

  const managersTableData = ManagersService.getTableData(managersData);
  const managersTableKeys = ManagersService.getTableKeys();

  useEffect(() => {
    if (managersOptionsLoadError) displayErrorsInToast([managersOptionsLoadError]);
  }, [managersOptionsLoadError]);

  const sideBarContent = getStaffLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout>
        <DocumentTitle title={TITLE} />
        <Typography variant="h1">{TITLE}</Typography>
        <div className={classes.actionPanel}>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.staffManagerCreatePath()}
              className={classes.addItem}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Manager
            </Button>
          </ActionPanelButtonWrapper>
        </div>
        <ListTable
          detailPath={appRoutes.staffManagerPath}
          isLoading={isManagersOptionsLoading}
          tableHeader={managersTableKeys}
          tableData={managersTableData}
          columnConfig={ManagersService.managersColumnConfig}
          isInnerScrollable
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
