import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

import { columns, createTableRows, makeTotal } from 'presenters/FundingNotesPresenter';

import { fundingNotesRestHooks, useFundingNotesTotalActions } from 'store/FundingNotesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const selectTableRows = createSelector(
  fundingNotes => fundingNotes.items,
  items => createTableRows(items),
);
const selectTotal = createSelector(
  fundingNotesTotal => fundingNotesTotal.item,
  item => makeTotal(item),
);

export const useFundingNotes = () => {
  const {
    loadFundingNotes,
    createFundingNote,
    updateFundingNote,
    deleteFundingNote,
    fundingNotes,
    fundingNote,
  } = fundingNotesRestHooks.use();
  const { loadFundingNotesTotal } = useFundingNotesTotalActions();

  const tableRows = selectTableRows(fundingNotes);

  const fundingNotesTotal = useSelector(state => state.FundingNotesSlice.fundingNotesTotal);

  return {
    loadFundingNotes: params => loadFundingNotes({ isExcess: false, ...params }),
    createFundingNote,
    updateFundingNote,
    deleteFundingNote,
    areFundingNotesLoading: useFetchStatus(fundingNotes?.loadingStatus).isPending,
    areFundingNotesLoadingFinished: useFetchStatus(fundingNotes?.loadingStatus).isFinished,
    fundingNotes: fundingNotes.items,
    tableRows,
    columns,
    loadFundingNotesTotal,
    total: selectTotal(fundingNotesTotal),
    fundingNotesErrors: fundingNote.errors,
  };
};
