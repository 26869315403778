import { isNil, slice, last, concat } from 'ramda';

import { formatCurrency } from 'utils/numbers';
import { getYearsOptions } from 'utils/select';

export const memberAccountingTableColumnConfig = {
  id: { displayedName: 'sbid', isLink: true },
  filingRef: { displayedName: 'filing ref' },
  name: { displayedName: 'sb name' },
  oidBalance: { displayedName: 'oid in balance' },
  debitPrincipalPayment: { displayedName: 'debit principal payment' },
  debitMortgageIntExp: { displayedName: 'debit mortgage int exp' },
  debitFeesPayable: { displayedName: 'debit fees payable' },
  creditIntIncmForFloat: { displayedName: 'credit int\nincm for\nfloat' },
  creditIntIncmForOid: { displayedName: 'credit int incm for oid' },
  debitOrCreditSecurities: { displayedName: 'debit or credit securities' },
  endingSecurityBalance: { displayedName: 'ending security balance' },
  endingMortgageBalance: { displayedName: 'ending mortgage balance' },
  endingCustodianFeesOutstanding: { displayedName: 'ending custodial fees outstanding' },
};

export const formatMemberAccountingTableData = item => {
  if (isNil(item)) return {};

  const list = item.sbAccountingTable;
  const tableSummary = [item.sbAccountingSummary];
  const preparedData = [
    ...concat(list, tableSummary).map(listItem => ({
      id: listItem.id,
      data: {
        ...listItem,
        oidBalance: formatCurrency(listItem.oidBalance),
        debitPrincipalPayment: formatCurrency(listItem.debitPrincipalPayment),
        debitMortgageIntExp: formatCurrency(listItem.debitMortgageIntExp),
        debitFeesPayable: formatCurrency(listItem.debitFeesPayable),
        creditIntIncmForFloat: formatCurrency(listItem.creditIntIncmForFloat),
        creditIntIncmForOid: formatCurrency(listItem.creditIntIncmForOid),
        debitOrCreditSecurities: formatCurrency(listItem.debitOrCreditSecurities),
        endingSecurityBalance: formatCurrency(listItem.endingSecurityBalance),
        endingMortgageBalance: formatCurrency(listItem.endingMortgageBalance),
        endingCustodianFeesOutstanding: formatCurrency(listItem.endingCustodianFeesOutstanding),
      },
    })),
  ];
  return {
    tableRows: slice(0, -1, preparedData),
    footerRow: last(preparedData).data,
  };
};

export { getYearsOptions };
