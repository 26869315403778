import getLocalStorageEnums from 'utils/getLocalStorageEnums';

export const getBankAccountTypes = () => {
  const { bankAccountTypes = [] } = getLocalStorageEnums();
  return bankAccountTypes.reduce((acc, type) => ({ ...acc, [type.value]: type.value }), {});
};

export const getBankAccountTypeNames = () => {
  const { bankAccountTypes = [] } = getLocalStorageEnums();
  return bankAccountTypes.reduce((acc, type) => ({ ...acc, [type.value]: type.label }), {});
};
