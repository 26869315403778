import React from 'react';

import { TableRow, TableCell, Button } from '@material-ui/core/';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { columns } from 'domain/reconciliationInput/service';

import { useEditableTableContext } from 'hooks/useEditableTableControls';

import ApprovedForRecCell from 'pages/LoanReconciliationInputs/components/ApprovedForRecCell';
import useStyles from 'pages/LoanReconciliationInputs/useStyles';

const DELETE_BUTTON = 'Delete';
const EDIT_BUTTON = 'Edit';

const isApprovedForRecCell = name => name === 'isApprovedForRec';

export const DataRow = props => {
  const { row, onDelete, onEdit } = props;
  const classes = useStyles();

  const { isDataRowsDisabled } = useEditableTableContext();

  const dataKeys = columns.getDataKeys();

  return (
    <TableRow className={clsx({ [classes.disabledRow]: isDataRowsDisabled })}>
      {dataKeys.map((key, index) => (
        <TableCell
          key={`data-cell-${index}`}
          className={clsx(classes.tableCellFont, {
            [classes.highlighted]: row.properties[key]?.isHighlighted,
          })}
        >
          {isApprovedForRecCell(key) ? (
            <ApprovedForRecCell
              value={row.data[key]}
              comment={row.data.comment}
              isDataRowsDisabled={isDataRowsDisabled}
            />
          ) : (
            row.data[key]
          )}
        </TableCell>
      ))}
      <TableCell>
        <Button
          color="primary"
          type="submit"
          className={classes.saveButton}
          onClick={onEdit}
          disabled={isDataRowsDisabled}
        >
          {EDIT_BUTTON}
        </Button>
        <Button className={classes.deleteButton} onClick={onDelete} disabled={isDataRowsDisabled}>
          {DELETE_BUTTON}
        </Button>
      </TableCell>
    </TableRow>
  );
};

DataRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      comment: PropTypes.string,
    }),
    properties: PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        isHighlighted: PropTypes.bool,
      }),
    }),
  }),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};
