import { isNil } from 'ramda';
import queryString from 'query-string';
import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { AxiosRequestConfig } from 'axios';

import { camelize, decamelize } from 'utils/keysConverter';
import { instance as axiosInstance } from 'utils/FetchHelpers';
import { pickSerializibleDataFromResponse } from 'utils/objects';

export const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params, headers }) => {
    try {
      const response = await axiosInstance({
        method,
        url,
        headers,
        data: !isNil(data) && decamelize(data),
        params: !isNil(params) && decamelize(params),
        paramsSerializer: parameters =>
          queryString.stringify(parameters, { encode: false, arrayFormat: 'none' }),
        withCredentials: true,
      });
      return { data: camelize(pickSerializibleDataFromResponse(response)) };
    } catch (axiosError) {
      return {
        error: axiosError,
      };
    }
  };
