import { makeStyles } from '@material-ui/core';
import authBackground from 'assets/authBackground.png';

export default makeStyles(
  () => ({
    main: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'start',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        width: '100%',
        height: '316px',
        background: `url(${authBackground}), linear-gradient(180deg, #FFFFFF 0%, #ECF1F5 100%) no-repeat center bottom`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',
        zIndex: 1,
      },
      height: '100vh',
    },
    logo: {
      position: 'absolute',
      left: '48px',
      top: '40px',
      right: 0,
      zIndex: 2,
    },
    logoQa: {
      height: '48px',
    },
    qaBadge: {
      height: '20px',
      marginBottom: '18px',
      marginLeft: '5px',
    },
    wrap: {
      marginTop: '225px',
      position: 'absolute',
      zIndex: 3,
    },
  }),
  { name: 'AuthLayout' },
);
