import { decamelize } from 'humps';
import { isNil } from 'ramda';

import { SORT_DIRECTION, SORTING_DIRECTION_SYMBOL } from 'enums';

export const calculatePageCount = (itemsCount, pageSize) => Math.ceil(itemsCount / pageSize);

const isDescDirection = direction => direction === SORT_DIRECTION.desc;

export const makeOrderingParam = (sortedBy, sortingDirection, format = decamelize) => {
  if (isNil(sortedBy)) return undefined;
  if (isNil(sortingDirection)) return format(sortedBy);
  return `${isDescDirection(sortingDirection) ? SORTING_DIRECTION_SYMBOL.desc : ''}${format(
    sortedBy,
  )}`;
};

export const withResetPageParam = param => ({ ...param, page: 1 });

export const getNextPage = url => {
  if (isNil(url)) return null;
  const resource = new URL(url);
  const page = resource.searchParams.get('page');
  return Number(page);
};
