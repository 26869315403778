import { camelize } from 'humps';

import { IExtendedSuccessorBorrower } from 'domain/successorBorrower/types';
import { ILoanCountByStatus } from 'domain/successorBorrower/schemas/common';

import { formatCurrency } from 'utils/numbers';

export const getSnapshotsData = (successorBorrower: IExtendedSuccessorBorrower) => {
  const snapshot = successorBorrower?.snapshot;

  const getLoansList = (data: ILoanCountByStatus) => {
    const loanCountByStatus = data?.statuses.reduce((acc, item) => {
      const status = camelize(item.status);
      return { [status]: item.amount, ...acc };
    }, {}) as ILoanCountByStatus;

    return [
      {
        label: 'Total Loans',
        value: data?.total,
      },
      {
        label: 'Total In Progress',
        value: loanCountByStatus?.inProgress,
      },
      {
        label: 'Total Active In Payment',
        value: loanCountByStatus?.activeInPayment,
      },
      {
        label: 'Total Inactive',
        value: loanCountByStatus?.inactive,
      },
      {
        label: 'Total Active Paid',
        value: loanCountByStatus?.activePaid,
      },
      {
        label: 'Total On Hold',
        value: loanCountByStatus?.indefinitelyOnHold,
      },
    ];
  };

  return {
    pool: {
      ...snapshot?.pool,
      loansList: getLoansList(snapshot?.pool?.loanCountByStatus),
      bloombergPoolBalance: formatCurrency(snapshot?.pool?.bloombergPoolBalance),
      outstandingPrincipalBalance: formatCurrency(snapshot?.pool?.outstandingPrincipalBalance),
      outstandingSecuritiesBalance: formatCurrency(snapshot?.pool?.outstandingSecuritiesBalance),
      poolSecuritiesCost: formatCurrency(snapshot?.pool?.poolSecuritiesCost),
      poolPrincipalBalanceAtTimeOfDefeasance: formatCurrency(
        snapshot?.pool?.poolPrincipalBalanceAtTimeOfDefeasance,
      ),
      poolOriginalLoanAmount: formatCurrency(snapshot?.pool?.poolOriginalLoanAmount),
    },
    entity: {
      ...snapshot?.entity,
      loansList: getLoansList(snapshot?.entity?.loanCountByStatus),
      outstandingPrincipalBalance: formatCurrency(snapshot?.entity?.outstandingPrincipalBalance),
      bloombergPoolBalance: formatCurrency(snapshot?.entity?.bloombergPoolBalance),
      outstandingSecuritiesBalance: formatCurrency(snapshot?.entity?.outstandingSecuritiesBalance),
      poolSecuritiesCost: formatCurrency(snapshot?.entity?.poolSecuritiesCost),
      poolPrincipalBalanceAtTimeOfDefeasance: formatCurrency(
        snapshot?.entity?.poolPrincipalBalanceAtTimeOfDefeasance,
      ),
      poolOriginalLoanAmount: formatCurrency(snapshot?.entity?.poolOriginalLoanAmount),
    },
  };
};
