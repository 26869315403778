import PropTypes from 'prop-types';
import { keys } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { formatCurrency, formatDigits } from 'utils/numbers';
import { getAddressString } from 'utils/dataTransform';

import AddressPresenter from './AddressPresenter';

export const moodysLookupOptionColumnConfig = {
  originalPrincipalBalance: {
    displayedName: 'Original Principal Balance',
  },
  propertyAddress: {
    displayedName: 'Property Address',
  },
  masterServicerLoanNumber: {
    displayedName: 'Master Servicer Loan Number',
  },
  coupon: {
    displayedName: 'Coupon',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
};

export const getDisplayedName = fieldName =>
  moodysLookupOptionColumnConfig[fieldName]?.displayedName ?? fieldName;

export const prepareMoodysDataForPaste = data => {
  const prepared = {
    ...data,
    originalPrincipalBalance: formatDigits(data.originalPrincipalBalance),
    monthlyPaymentAmount: formatDigits(data.monthlyPaymentAmount),
    maturityDate: formatDate(data.maturityDate),
    noteDated: formatDate(data.noteDated),
  };
  return prepared;
};

export default new Presenter(
  {
    dealName: PropTypes.string,
    bloombergName: PropTypes.string,
    propertyAddress: AddressPresenter.shape(),
    originalPrincipalBalance: PropTypes.string,
    term: PropTypes.number,
    amortization: PropTypes.number,
    coupon: PropTypes.number,
    originalLender: PropTypes.string,
    monthlyPaymentAmount: PropTypes.string,
    interestMethod: PropTypes.string,
    masterServicerLoanNumber: PropTypes.string,
    maturityDate: PropTypes.date,
    noteDated: PropTypes.date,
    paymentDay: PropTypes.number,
  },

  {
    moodysLoanLookupOptionKeys() {
      return keys(moodysLookupOptionColumnConfig);
    },

    moodysLoanLookupOptions(data) {
      if (!data) return [];
      return data.map(loan => ({
        label: loan.dealName,
        value: loan.dealName,
        data: {
          ...loan,
          propertyAddress: getAddressString(loan.propertyAddress),
          maturityDate: formatDate(loan.maturityDate),
          originalPrincipalBalance: formatCurrency(loan.originalPrincipalBalance),
          monthlyPaymentAmount: formatCurrency(loan.monthlyPaymentAmount),
        },
        rawData: {
          ...loan,
        },
      }));
    },
  },
);
