import { useSelector } from 'react-redux';
import { filter } from 'ramda';

import OrganizationPresenter from 'presenters/OrganizationPresenter';
import OrganizationIndexPresenter from 'presenters/OrganizationIndexPresenter';
import OrganizationShowPresenter from 'presenters/OrganizationShowPresenter';

import {
  organizationsRestHooks,
  useRegisteredAgentsActions,
  useProvidersActions,
} from 'store/OrganizationsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getEntityLastUpdated } from 'utils/date';

export const useOrganizations = () => {
  const {
    organizations,
    loadOrganizations,
    organization,
    loadOrganization,
    updateOrganization,
    createOrganization,
    deleteOrganization,
  } = organizationsRestHooks.use();

  const organizationOptions = getSelectOptions(
    organizations.items,
    'id',
    OrganizationPresenter.name,
  );

  const filteredOrganizationOptions = organizationType =>
    getSelectOptions(
      organizations.items.filter(item => item.organizationType === organizationType),
      'id',
      OrganizationPresenter.name,
    );

  const { loadRegisteredAgents } = useRegisteredAgentsActions();
  const { registeredAgents } = useSelector(state => state.OrganizationsSlice);
  const registeredAgentsOptions = getSelectOptions(
    registeredAgents,
    'id',
    OrganizationPresenter.selectName,
  );

  const { loadProviders } = useProvidersActions();
  const { providers } = useSelector(state => state.OrganizationsSlice);
  const providersOptions = getSelectOptions(providers, 'id', OrganizationPresenter.selectName);

  const organizationsTableData = OrganizationIndexPresenter.tableData(organizations.items);
  const organizationTableData = OrganizationShowPresenter.tableData(organization?.item);

  const filteredOrganizations = organizationType =>
    filter(
      item => !organizationType || item.organizationType === organizationType,
      organizations.items,
    );

  const filteredTableData = organizationType =>
    OrganizationIndexPresenter.tableData(filteredOrganizations(organizationType));

  const lastUpdated = getEntityLastUpdated(organization.item);

  return {
    organizations,
    loadOrganizations,
    organization: organization.item,
    loadOrganization,
    organizationOptions,
    loadRegisteredAgents,
    registeredAgentsOptions,
    loadProviders,
    providersOptions,
    organizationsTableData,
    filteredTableData,
    filteredOrganizationOptions,
    organizationTableData,
    updateOrganization,
    createOrganization,
    deleteOrganization,
    isOrganizationsLoading: useFetchStatus(organizations?.loadingStatus).isPending,
    isOrganizationLoading: useFetchStatus(organization?.loadingStatus).isPending,
    organizationErrors: organization?.errors,
    lastUpdated,
  };
};
