import React, { useMemo } from 'react';

import { Button } from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';

import { useGetPoolsQuery } from 'domain/pool/apiSlice';
import { PoolsService } from 'domain/pool/service';

import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Pools';

export const Pools = () => {
  const classes = useStyles();

  const {
    data: PoolsData,
    error: PoolsDataLoadError,
    isFetching: isPoolsLoading,
  } = useGetPoolsQuery();

  const poolsTableData = useMemo(() => PoolsService.getTableData(PoolsData), [PoolsData]);

  const tableHeader = PoolsService.getTableHeader();

  const { elementRef, elementHeight } = useRefElementHeight();

  useHandleRtkQueryErrors(PoolsDataLoadError);

  return (
    <RootLayoutV2>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={TITLE} />
        <Header title={TITLE} ref={elementRef} shouldUseSidebarState={false}>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.poolCreatePath()}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Pool
            </Button>
          </ActionPanelButtonWrapper>
        </Header>
        <ListTable
          detailPath={appRoutes.poolPath}
          isLoading={isPoolsLoading}
          tableHeader={tableHeader}
          tableData={poolsTableData}
          columnConfig={PoolsService.columnConfig}
          tableClasses={classes.table}
          isOuterScrollable
          fixedTopOffset={elementHeight}
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
