import { bloombergConfigurationsRestHooks } from 'store/BloombergConfigurationsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useBloombergConfigurations = () => {
  const { bloombergConfiguration, loadBloombergConfiguration, updateBloombergConfiguration } =
    bloombergConfigurationsRestHooks.use();

  return {
    bloombergConfiguration,
    loadBloombergConfiguration,
    updateBloombergConfiguration,
    isBloombergConfigurationLoading: useFetchStatus(bloombergConfiguration.loadingStatus).isPending,
  };
};
