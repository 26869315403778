import React, { useEffect, useState } from 'react';

import { isNil, not, isEmpty } from 'ramda';
import { clsx } from 'clsx';

import YearSelectAutocomplete from 'components/YearSelectAutocomplete';
import OidReportDetailsHeader from 'components/OidReportDetailsHeader';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';
import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { getCurrentYear } from 'utils/date';

import SuccessorBorrowerSummary from './components/SuccessorBorrowerSummary';
import Summary from './components/Summary';
import JournalEntry from './components/JournalEntry';
import AccountingTable from './components/AccountingTable';
import useStyles from './useStyles';

const SELECT_YEAR_LABEL = 'Select the Year';

export const OIDBalanceSuccessorBorrowerDetails = () => {
  const {
    params: { id },
  } = useRouter();
  const classes = useStyles();

  const { displayErrorsInToast, formatErrors, displayFieldErrorsInToast } = useErrors();

  const {
    loadSuccessorBorrowerDetails,
    areSBDetailsLoading,
    getYearsOptions,
    successorBorrowerReportLoadingErrors,
    successorBorrowerReport,
  } = useOIDBalanceReports();

  const [year, setYear] = useState(getCurrentYear());

  const { elementRef: headerRef, elementHeight: fixedTopOffset } = useRefElementHeight();

  useEffect(() => {
    loadSuccessorBorrowerDetails(id, { year });
  }, [year]);

  useEffect(() => {
    const error = successorBorrowerReportLoadingErrors;
    if (isNil(error)) return;
    const { nonFieldErrors, backendServicesError, fieldErrors } = formatErrors(error);
    displayErrorsInToast([nonFieldErrors, backendServicesError]);
    if (not(isEmpty(fieldErrors))) {
      displayFieldErrorsInToast(fieldErrors);
    }
  }, [successorBorrowerReportLoadingErrors]);

  const handleYearChange = (_, value) => {
    if (not(isNil(value))) {
      setYear(value.option);
    }
  };

  const name = successorBorrowerReport?.name;
  const memberName = successorBorrowerReport?.memberName;

  const pathsForBreadcrumbs = [
    { name: 'Live Forms', path: appRoutes.liveFormsPath() },
    { name: 'OID Balance Report', path: appRoutes.liveFormsOIDBalanceReportPath() },
    { name: memberName },
    { name },
  ];

  const detailsLinks = [{ name: 'View Details', path: appRoutes.successorBorrowerPath(id) }];

  const getDefaultValue = currentYear => ({ options: currentYear, label: String(currentYear) });

  return (
    <RootLayoutV2 isLoading={areSBDetailsLoading || isNil(successorBorrowerReport)}>
      <ContentLayout disableTopPadding>
        <OidReportDetailsHeader
          ref={headerRef}
          title={name}
          links={detailsLinks}
          pathsForBreadcrumbs={pathsForBreadcrumbs}
        >
          <SuccessorBorrowerSummary />
          <YearSelectAutocomplete
            label={SELECT_YEAR_LABEL}
            options={getYearsOptions()}
            onChange={handleYearChange}
            defaultValue={getDefaultValue(year)}
            className={classes.autocomplete}
          />
        </OidReportDetailsHeader>
        <AccountingTable fixedTopOffset={fixedTopOffset} />
        <div className={clsx(classes.gridLayout, classes.blockPadding)}>
          <Summary />
          <JournalEntry />
        </div>
      </ContentLayout>
    </RootLayoutV2>
  );
};
