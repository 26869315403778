import PropTypes from 'prop-types';

import { LOANS_STATUSES } from 'const';

import { DEAL_FILTER } from 'enums';

import {
  formatDate,
  isBeforeYesterdayDate,
  isValidWorkdayAfterTomorrow,
  isRestOfThisWeekDate,
  isSinceNextWeekDate,
  isTodayDate,
  isValidTomorrowWorkday,
  getAssumedDate,
} from 'utils/date';
import { formatCurrency } from 'utils/numbers';

export const columnConfig = {
  assumedDate: {
    displayedName: 'Accepted Date',
  },
  filingRef: {
    displayedName: 'Filling Ref',
  },
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  dealName: {
    displayedName: 'Property Name',
    withTags: true,
  },
  bloombergName: {
    displayedName: 'Bloomberg Name',
  },
  defeasanceConsultantShortName: {
    displayedName: 'Consult',
  },
  servicerCounsel1Name: {
    displayedName: 'Servicer Counsel',
  },
  masterServicerShortName: {
    displayedName: 'Master Servicer',
  },
  alternativeServicerShortName: {
    displayedName: 'Sub Servicer',
  },
  custodianShortName: {
    displayedName: 'Cust',
  },
  parRepayType: {
    displayedName: 'PRP Code',
  },
  originalLoanDocumentsReviewedDate: {
    displayedName: 'Loan Docs Reviewed Date',
  },
  certifiedArticlesSentToServicerCounselDate: {
    displayedName: 'Good Standings Sent Date',
  },
  securitiesBrokerDealerName: {
    displayedName: 'Broker Dealer',
  },
  buyUpAmount: {
    displayedName: 'Buy Up',
    align: 'right',
  },
  sbType: {
    displayedName: 'SB Type',
  },
};

export const getTableHeader = () => Object.keys(columnConfig);

export const getTableData = list => {
  if (!list) return null;
  return list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      assumedDate: formatDate(rowData.assumedDate),
      originalLoanDocumentsReviewedDate: formatDate(rowData.originalLoanDocumentsReviewedDate),
      certifiedArticlesSentToServicerCounselDate: formatDate(
        rowData.certifiedArticlesSentToServicerCounselDate,
      ),
      buyUpAmount: formatCurrency(rowData.buyUpAmount),
    },
  }));
};

export const sortDealsByFilters = dealsData => {
  const inProgressDeals = dealsData[LOANS_STATUSES.inProgress];
  const onHoldDeals = dealsData[LOANS_STATUSES.indefinitelyOnHold];
  return {
    [DEAL_FILTER.all]: getTableData(
      inProgressDeals?.filter(deal => isBeforeYesterdayDate(getAssumedDate(deal.assumedDate))),
    ),
    [DEAL_FILTER.today]: getTableData(
      inProgressDeals?.filter(deal => isTodayDate(getAssumedDate(deal.assumedDate))),
    ),
    [DEAL_FILTER.tomorrow]: getTableData(
      inProgressDeals?.filter(deal => isValidTomorrowWorkday(getAssumedDate(deal.assumedDate))),
    ),
    [DEAL_FILTER.dayAfterTomorrow]: getTableData(
      inProgressDeals?.filter(deal =>
        isValidWorkdayAfterTomorrow(getAssumedDate(deal.assumedDate)),
      ),
    ),
    [DEAL_FILTER.restOfThisWeek]: getTableData(
      inProgressDeals?.filter(deal => isRestOfThisWeekDate(getAssumedDate(deal.assumedDate))),
    ),
    [DEAL_FILTER.remainingDeals]: getTableData(
      inProgressDeals?.filter(deal => isSinceNextWeekDate(getAssumedDate(deal.assumedDate))),
    ),
    [DEAL_FILTER.onHold]: getTableData(onHoldDeals),
  };
};

export const extendedLoanDealPropTypes = PropTypes.shape({
  assumedDate: PropTypes.date,
  id: PropTypes.number,
  filingRef: PropTypes.number,
  dealName: PropTypes.string,
  servicerCounsel1Name: PropTypes.string,
  masterServicerShortName: PropTypes.string,
  accountantShortName: PropTypes.string,
  custodianShortName: PropTypes.string,
  custodianAccountNumber: PropTypes.number,
  originalLoanDocumentsReviewedDate: PropTypes.date,
  certifiedArticlesSentToServicerCounselDate: PropTypes.date,
  executionDefeasanceDocumentsReceivedDate: PropTypes.date,
  parRepayType: PropTypes.number,
  buyUpAmount: PropTypes.number,
  sbType: PropTypes.string,
});
