import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableBottomRow: {
      background: theme.palette.background.default,
    },
    tableBottomCell: {
      height: 48,
      padding: [16, 12],
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
  }),
  { name: 'BuyUpPending' },
);
