import React, { useEffect, useState } from 'react';

import { MenuItem, Select, Button } from '@material-ui/core';
import clsx from 'clsx';
import xlsIcon from 'assets/xls.svg';
import loaderIcon from 'assets/loader.svg';
import AssumedDealsRepository from 'repositories/AssumedDealsRepository';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import * as OptionsService from 'domain/options/service';

import { useAssumedDeals } from 'hooks/api/useAssumedDeals';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import { prepareDataToExport } from 'utils/reports';

import useStyles from './useStyles';

const DEFAULT_CRITERION = 'master_servicer';
const TITLE = 'Deals Assumed Sortable';

export const AssumedDeals = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const [criterion, setCriterion] = useState(DEFAULT_CRITERION);
  const [isExportLoading, setExportLoading] = useState(false);

  const {
    loadAssumedDeals,
    areAssumedDealsLoading,
    assumedDealsTableData,
    assumedDealsColumnConfig,
    assumedDealsTableHeader,
  } = useAssumedDeals();

  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());
  const assumedDealsLiveFormCriteria =
    OptionsService.getAssumedDealsLiveFormCriteriaOptions(options);

  const { elementRef, elementHeight } = useRefElementHeight();

  const assumedDealsExportData = prepareDataToExport(
    assumedDealsTableHeader,
    assumedDealsTableData,
  );

  const handleCriteriaSelectChange = e => {
    setCriterion(e.target.value);
  };

  const handleExportButtonClick = () => {
    setExportLoading(true);
    AssumedDealsRepository.exportToExcel({
      exportName: criterion,
      headers: assumedDealsTableHeader,
      rows: assumedDealsExportData,
    })
      .then(() => {
        setExportLoading(false);
      })
      .catch(() => {
        displayErrorsInToast();
        setExportLoading(false);
      });
  };

  const fetchData = async () => {
    try {
      await loadAssumedDeals({ criterion });
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [criterion]);

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState isHorizontal>
        <div>
          <Select
            className={clsx(classes.select, classes.selectWide)}
            classes={{ root: classes.select }}
            variant="standard"
            name="criteriaSelect"
            defaultValue={criterion}
            onChange={handleCriteriaSelectChange}
          >
            {assumedDealsLiveFormCriteria.map(option => (
              <MenuItem className={classes.menuItem} key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <Button
            className={classes.exportButton}
            startIcon={
              isExportLoading ? <img src={loaderIcon} alt="xls" /> : <img src={xlsIcon} alt="xls" />
            }
            variant="outline"
            color="primary"
            onClick={() => handleExportButtonClick()}
          >
            Export to Excel
          </Button>
        </div>
      </Header>
      <ListTable
        tableClasses={classes.table}
        isLoading={areAssumedDealsLoading}
        tableHeader={assumedDealsTableHeader}
        tableData={assumedDealsTableData}
        columnConfig={assumedDealsColumnConfig}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
