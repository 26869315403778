import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValid, format as formatDate } from 'date-fns';

import { ERROR_MESSAGES, FORM_FIELD_TYPE } from 'enums';

import { parseDate, today } from 'utils/date';
import { preventEmptyString } from 'utils/yupHelpers';
import { preventNullProps } from 'utils/forms';
import { replaceEmptyStringsToNull } from 'utils/forms';
import { toCurrencyNumber } from 'utils/numbers';

export const resolver = yupResolver(
  Yup.object({
    entryDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.invalidDate)
      .nullable()
      .required(ERROR_MESSAGES.date.requiredDate),
    cashReceipt: Yup.number().transform(preventEmptyString).nullable(),
    loanPayment: Yup.number().transform(preventEmptyString).nullable(),
    cashBalance: Yup.number().transform(preventEmptyString).nullable(),
  }),
);

export const defaultValues = {
  cashReceipt: '',
  loanPayment: '',
  cashBalance: '',
  entryDate: today(),
};

export const formFields = [
  { name: 'entryDate', type: FORM_FIELD_TYPE.date },
  { name: 'cashReceipt', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'loanPayment', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'cashBalance', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
];

export const prepareBeforeSending = data => {
  const { entryDate } = data;
  return replaceEmptyStringsToNull({
    ...data,
    entryDate: isValid(entryDate) ? formatDate(entryDate, 'yyyy-MM-dd') : null,
  });
};

export const prepareBeforeSetting = data => {
  const { entryDate } = data;
  return {
    ...preventNullProps({
      ...data,
      cashReceipt: toCurrencyNumber(data?.cashReceipt),
      loanPayment: toCurrencyNumber(data?.loanPayment),
      cashBalance: toCurrencyNumber(data?.cashBalance),
    }),
    entryDate: parseDate('yyyy-MM-dd')(entryDate),
  };
};
