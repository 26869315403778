import { useEffect, useState } from 'react';

import * as OptionsService from 'domain/options/service';

import localStorage from 'utils/localStorage';

export function useSetOptionsToLocalStorage(optionsData) {
  const [isOptionsSet, setIsOptionsSet] = useState(false);

  useEffect(() => {
    if (optionsData) {
      const optionsForEnums = OptionsService.getOptionsForEnums(optionsData);
      localStorage.setItem('enums', optionsForEnums);
      setIsOptionsSet(true);
    }
  }, [optionsData]);

  return { isOptionsSet };
}
