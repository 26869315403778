import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    formTitle: {
      textAlign: 'center',
    },
    message: {
      ...theme.typography.body3,
      color: theme.palette.primary.main,
      opacity: '0.7',
      textAlign: 'center',
    },
    inputMargin: {
      marginTop: '24px',
    },
    button: {
      display: 'block',
      height: '40px',
      width: '257px',
      marginTop: '24px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  }),
  { name: 'PasswordForm' },
);
