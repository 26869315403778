import { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import OIDBalanceRepository from 'repositories/OIDBalanceRepository';

import {
  formatMemberAccountingTableData,
  memberAccountingTableColumnConfig,
  getYearsOptions,
} from 'presenters/OIDBalanceMemberDetailsPresenter';

import { useOIDBalanceMemberDetailsActions } from 'store/OIDBalanceReportsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const selectTableData = createSelector(data => data, formatMemberAccountingTableData);

export const useOIDBalanceMemberDetails = () => {
  const { createLongPollingWorker, OIDBalanceMemberDetails } = useOIDBalanceMemberDetailsActions();

  const memberDetails = OIDBalanceMemberDetails.item;
  const { loadingStatus } = OIDBalanceMemberDetails;
  const memberDetailsLoadingError = OIDBalanceMemberDetails.errors;
  const accountingTableRows = selectTableData(memberDetails);

  const loadMemberDetails = useMemo(
    () => createLongPollingWorker(OIDBalanceRepository.OIDBalanceMemberDetailsTasks),
    [],
  );

  return {
    loadMemberDetails,
    accountingTableColumns: memberAccountingTableColumnConfig,
    accountingTableRows,
    memberDetails,
    memberDetailsLoadingError,
    getYearsOptions,
    areMemberDetailsLoading: useFetchStatus(loadingStatus).isPending,
    areMemberDetailsLoadingFinished: useFetchStatus(loadingStatus).isFinished,
  };
};
