import { includes } from 'ramda';

export const getFiltersFromData = data => ({
  servicerCounsel: data.servicerCounsel?.value,
  loanServicer: data.loanServicer?.value,
  originalLender: data.originalLender?.map(lender => lender?.value),
});

export const getFiltersFromQueryString = data => ({
  servicerCounsel: data.servicerCounsel,
  loanServicer: data.loanServicer,
  originalLender: data.originalLender,
});

export const getDefaultOption = (options, defaultValue, multiple = false) => {
  const defaultOption = multiple ? [] : null;
  if (!options || !defaultValue) return defaultOption;
  const findOption = multiple
    ? () => options.filter(option => includes(option.value, defaultValue))
    : () => options.find(option => option.value === String(defaultValue));
  return findOption();
};
