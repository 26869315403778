import React from 'react';

import { Typography, Paper, List, ListItem } from '@material-ui/core';
import { keys } from 'ramda';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';

import useStyles from 'pages/OIDBalanceSuccessorBorrowerDetails/useStyles';

const TITLE = 'Summary';

export const Summary = () => {
  const { successorBorrowerReport, SBReportSummaryTableFields } = useOIDBalanceReports();
  const classes = useStyles();

  return (
    <div>
      <Typography className={classes.listTitle}>{TITLE}</Typography>
      <Paper className={classes.listWrap}>
        <List className={classes.list}>
          {keys(SBReportSummaryTableFields).map((key, index) => {
            const { displayedName } = SBReportSummaryTableFields[key];
            const data = successorBorrowerReport[key];
            return (
              <ListItem className={classes.listItem} key={`summary-${index}`}>
                <Typography className={classes.bodyBold}>{displayedName}</Typography>
                <Typography className={classes.body1}>{data}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
