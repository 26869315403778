import React, { useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import { Button, IconButton } from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import clsx from 'clsx';

import SearchChoicesList from 'components/SearchChoicesList';

import { SEARCH_BY_LABELS } from 'enums';

import useStyles from './useStyles';

const SearchPanel = ({ open, onClose }) => {
  const classes = useStyles();
  const [selectedSearchKey, setSelectedSearchKey] = useState('');
  const selectSearchBy = key => () => {
    setSelectedSearchKey(key);
  };

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{ className: clsx(classes.drawer, { [classes.wide]: !!selectedSearchKey }) }}
      ModalProps={{ BackdropProps: { className: classes.backdrop } }}
    >
      <div className={classes.inner}>
        {selectedSearchKey ? (
          <SearchChoicesList
            onBack={() => setSelectedSearchKey('')}
            onClose={onClose}
            searchKey={selectedSearchKey}
          />
        ) : (
          <>
            <div className={classes.label}>
              <SearchIcon />
              Search By
            </div>
            <div className={classes.outBox}>
              {Object.keys(SEARCH_BY_LABELS).map(key => (
                <Button
                  key={key}
                  className={clsx(classes.link, classes.withArrow)}
                  onClick={selectSearchBy(key)}
                >
                  {SEARCH_BY_LABELS[key]}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>

      <IconButton className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </Drawer>
  );
};

SearchPanel.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default SearchPanel;
