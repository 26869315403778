import React from 'react';

import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import doubleArrowIcon from 'assets/doubleArrow.svg';
import arrowDownIcon from 'assets/arrowDown.svg';
import arrowUpIcon from 'assets/arrowUp.svg';

import { SORT_DIRECTION, FIELD_ALIGNMENT } from 'enums';

import { usePaginationAndOrderingContext } from 'hooks/usePaginationAndOrdering';

import useStyles from './useStyles';

export const TableHeadContent = props => {
  const {
    tableHeader,
    columnConfig,
    isInnerScrollable = false,
    isMiniView,
    isPaginationEnabled,
    fixedTopOffset,
    sortedBy,
    setSortedBy,
    sortingDirection,
    setSortingDirection,
  } = props;
  const classes = useStyles();

  const paginationMethods = usePaginationAndOrderingContext();

  const isSortingActive = key => {
    const sortedByKey = isPaginationEnabled ? paginationMethods.ordering.sortedBy : sortedBy;
    return !!sortedByKey && sortedByKey === key;
  };

  const handleSortingWithPaginationClick = key => {
    const {
      ordering,
      setOrdering: setPaginationOrdering,
      toggleSortingDirection,
    } = paginationMethods;

    if (ordering.sortedBy === key) {
      toggleSortingDirection(key, ordering.sortingDirection);
    } else {
      setPaginationOrdering(
        { sortedBy: key, sortingDirection: SORT_DIRECTION.asc },
        { shouldResetPage: true },
      );
    }
  };

  const handleSortingClick = key => {
    if (sortedBy === key) {
      if (sortingDirection === SORT_DIRECTION.asc) {
        setSortingDirection(SORT_DIRECTION.desc);
      }
      if (sortingDirection === SORT_DIRECTION.desc) {
        setSortedBy(null);
        setSortingDirection(null);
      }
    } else {
      setSortedBy(key);
      setSortingDirection(SORT_DIRECTION.asc);
    }
  };

  const sortingButtonEndIcon = key => {
    const sortedByKey = isPaginationEnabled ? paginationMethods.ordering.sortedBy : sortedBy;
    const direction = isPaginationEnabled
      ? paginationMethods.ordering.sortingDirection
      : sortingDirection;
    if (!sortedByKey || key !== sortedByKey) {
      return doubleArrowIcon;
    }
    if (direction === SORT_DIRECTION.asc) {
      return arrowDownIcon;
    }
    return arrowUpIcon;
  };

  return (
    <TableHead
      className={clsx({
        [classes.headMiniView]: isMiniView,
      })}
    >
      <TableRow
        className={clsx({
          [classes.rowMiniView]: isMiniView,
        })}
      >
        {tableHeader.map(columnKey => {
          const {
            displayedName = null,
            sortingKey = columnKey,
            align = FIELD_ALIGNMENT.left,
            sortingEnabled = false,
          } = columnConfig[columnKey];
          return (
            <TableCell
              className={clsx(classes.tableHeadCell, {
                [classes.cellMiniView]: isMiniView,
                [classes.tableHeadCellSticky]: isInnerScrollable,
              })}
              style={{ top: fixedTopOffset }}
              variant="head"
              align={align}
              key={`th_${columnKey}`}
            >
              {sortingEnabled ? (
                <Button
                  className={clsx(classes.sortButton, {
                    [classes.sortingIsActive]: isSortingActive(columnKey),
                  })}
                  endIcon={
                    <img
                      className={classes.sortButtonIcon}
                      src={sortingButtonEndIcon(sortingKey)}
                      alt="doubleArrow"
                    />
                  }
                  variant="text"
                  color="secondary"
                  onClick={
                    isPaginationEnabled
                      ? () => handleSortingWithPaginationClick(sortingKey)
                      : () => handleSortingClick(sortingKey)
                  }
                >
                  {displayedName}
                </Button>
              ) : (
                displayedName
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

TableHeadContent.propTypes = {
  tableHeader: PropTypes.arrayOf(PropTypes.string),
  columnConfig: PropTypes.objectOf(
    PropTypes.shape({
      align: PropTypes.oneOf([FIELD_ALIGNMENT.right, FIELD_ALIGNMENT.left]),
      displayedName: PropTypes.string,
      sortingEnabled: PropTypes.bool,
      isLink: PropTypes.bool,
      withTags: PropTypes.bool,
      canBeHidden: PropTypes.bool,
      editable: PropTypes.bool,
      editableFieldType: PropTypes.string,
      maxWidth: PropTypes.string,
      width: PropTypes.string,
      sortingKey: PropTypes.string,
    }),
  ),
  isInnerScrollable: PropTypes.bool,
  isMiniView: PropTypes.bool,
  isPaginationEnabled: PropTypes.bool,
  fixedTopOffset: PropTypes.number,
  sortedBy: PropTypes.string,
  setSortedBy: PropTypes.func,
  sortingDirection: PropTypes.string,
  setSortingDirection: PropTypes.func,
};
