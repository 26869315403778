import React from 'react';

import PropTypes from 'prop-types';

import useStyles from './useStyles';

export const NonFieldError = props => {
  const { error } = props;
  const classes = useStyles();

  return (
    <div className={classes.errorWrap}>
      <span className={classes.error}>{error}</span>
    </div>
  );
};

NonFieldError.propTypes = {
  error: PropTypes.string,
};
