import PropTypes from 'prop-types';

import { FORM_FIELD_TYPE } from 'enums';

import { formatDate } from 'utils/date';
import Presenter from 'utils/PropTypesPresenter';

export const outstandingBindersColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  dealName: {
    displayedName: 'Loan Name',
  },
  status: {
    displayedName: 'Status',
  },
  isClosingBinderReceived: {
    displayedName: 'Closing Binder status',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.select,
    fieldKey: 'isClosingBinderReceived',
    selectValuesType: 'booleanTypes',
  },
  assumedDate: {
    displayedName: 'Accepted Date',
  },
  masterServicer: {
    displayedName: 'Master Servicer',
  },
  loanServicer: {
    displayedName: 'Loan Servicer',
  },
  servicerCounselName: {
    displayedName: 'Servicer Counsel',
  },
  servicerCouncelCompany: {
    displayedName: 'Servicer Councel Company',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    dealName: PropTypes.string,
    status: PropTypes.string,
    isClosingBinderReceived: PropTypes.bool,
    assumedDate: PropTypes.string,
    masterServicer: PropTypes.string,
    loanServicer: PropTypes.string,
    servicerCouncel: PropTypes.string,
    servicerCouncelCompany: PropTypes.string,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          assumedDate: formatDate(rowData.assumedDate),
        },
      }));
    },
  },
);
