/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import OrganizationsRepository from 'repositories/OrganizationsRepository';

import { getOrganizationTypeValues } from 'enums';

import { createRestSlice } from 'utils/RestSlice';
import { selectResultsFromResponse } from 'utils/objects';

const organizationsRestSlice = createRestSlice({
  resource: 'organization',
  repository: OrganizationsRepository,
  slices: [
    'loadOrganizations',
    'loadOrganization',
    'updateOrganization',
    'createOrganization',
    'deleteOrganization',
  ],
});

const providersState = {
  providers: [],
  areProvidersLoading: false,
  providersLoadError: null,
};

const registeredAgentsState = {
  registeredAgents: [],
  areRegisteredAgentsLoading: false,
  registeredAgentsLoadError: null,
};

const initialState = {
  ...organizationsRestSlice.initialState,
  ...registeredAgentsState,
  ...providersState,
};

const registeredAgentsReducers = {
  startRegisteredAgentsLoad(state) {
    state.areRegisteredAgentsLoading = true;
    state.registeredAgents = [];
    state.registeredAgentsLoadError = null;
  },
  registeredAgentsLoadFulfilled(state, { payload }) {
    state.areRegisteredAgentsLoading = false;
    state.registeredAgents = payload;
  },
  registeredAgentsLoadRejected(state, { payload }) {
    state.areRegisteredAgentsLoading = false;
    state.registeredAgents = [];
    state.registeredAgentsLoadError = payload;
  },
};

const providersReducers = {
  startProvidersLoad(state) {
    state.areProvidersLoading = true;
    state.providers = [];
    state.providersLoadError = null;
  },
  providersLoadFulfilled(state, { payload }) {
    state.areProvidersLoading = false;
    state.providers = payload;
  },
  providersLoadRejected(state, { payload }) {
    state.areProvidersLoading = false;
    state.providers = [];
    state.providersLoadError = payload;
  },
};

const organizationsSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    ...organizationsRestSlice.reducers,
    ...registeredAgentsReducers,
    ...providersReducers,
  },
});

export default organizationsSlice.reducer;
export const organizationsRestHooks = organizationsRestSlice.hooks(
  organizationsSlice,
  'OrganizationsSlice',
);

export const useRegisteredAgentsActions = () => {
  const dispatch = useDispatch();

  const loadRegisteredAgents = () => {
    dispatch(organizationsSlice.actions.startRegisteredAgentsLoad());
    return OrganizationsRepository.index({
      organizationType: getOrganizationTypeValues().registeredAgent,
    })
      .then(response => {
        dispatch(
          organizationsSlice.actions.registeredAgentsLoadFulfilled(
            selectResultsFromResponse(response),
          ),
        );
      })
      .catch(e => {
        dispatch(organizationsSlice.actions.registeredAgentsLoadRejected(e));
      });
  };

  return {
    loadRegisteredAgents,
  };
};

export const useProvidersActions = () => {
  const dispatch = useDispatch();

  const loadProviders = () => {
    dispatch(organizationsSlice.actions.startProvidersLoad());
    return OrganizationsRepository.index({
      organizationType: getOrganizationTypeValues().financialServices,
    })
      .then(response => {
        dispatch(
          organizationsSlice.actions.providersLoadFulfilled(selectResultsFromResponse(response)),
        );
      })
      .catch(e => {
        dispatch(organizationsSlice.actions.providersLoadRejected(e));
      });
  };

  return {
    loadProviders,
  };
};
