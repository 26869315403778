import React, { useEffect, FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';
import { any } from 'ramda';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import DocumentTitle from 'components/DocumentTitle';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import * as OptionsService from 'domain/options/service';
import { useGetOrganizationsQuery } from 'domain/organization/apiSlice';
import { useCreatePersonMutation, useUpdatePersonMutation } from 'domain/person/apiSlice';
import { IPerson } from 'domain/person/types';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import { appRoutes } from 'router/routes';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import { setServerErrorsToFields } from 'utils/errors';
import { getSelectOptions } from 'utils/getSelectOptions';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

type PersonFormProps = {
  isEdit: boolean;
  personId: number;
  formValues: IPerson;
  pageTitle: string;
  submitButtonText: string;
  lastUpdated: string;
};

const PersonForm: FC<PersonFormProps> = props => {
  const { isEdit, personId, formValues, pageTitle, submitButtonText, lastUpdated } = props;
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const classes: any = useStyles();
  const { push } = useRouter();
  const { displayErrorsInToast } = useErrors();

  const { data: options, isFetching: isOptionsLoading } = useGetOptionsQuery(
    null,
    getOptionsQueryParams(),
  );
  const stateSelectOptions = OptionsService.selectStateSelectOptions(options);

  const {
    data: organizations,
    isFetching: isOrganizationsLoading,
    error: organizationsLoadError,
  } = useGetOrganizationsQuery('');

  const organizationOptions = getSelectOptions(organizations, 'id');

  const [updatePerson, { error: personUpdateError, isLoading: isPersonUpdateLoading }] =
    useUpdatePersonMutation();

  const [createPerson, { error: personCreateError, isLoading: isPersonCreateLoading }] =
    useCreatePersonMutation();

  const personErrors = personUpdateError || personCreateError;

  const selectValues = {
    states: stateSelectOptions,
    organizations: organizationOptions,
  };

  useEffect(() => {
    if (organizationsLoadError) {
      displayErrorsInToast([organizationsLoadError]);
    }
  }, [organizationsLoadError]);

  const methods = useForm({
    resolver,
  });
  const {
    setError,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (personErrors) {
      setServerErrorsToFields(setError, errors, personErrors);
    }
  }, [personErrors]);

  const isFormDirty = methods.formState.isDirty;

  const prevRoute = appRoutes.partiesPeoplePath();

  const handleSubmit = async (values: IPerson) => {
    if (isEdit) {
      await updatePerson({ id: personId, data: values });
    } else {
      await createPerson(values);
    }
    push(prevRoute);
  };

  const handleCancel = () => {
    push(prevRoute);
  };

  const isRequestsLoading = [
    isPersonUpdateLoading,
    isPersonCreateLoading,
    isOrganizationsLoading,
    isOptionsLoading,
  ];

  if (any(loaderRequest => loaderRequest)(isRequestsLoading)) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <DocumentTitle title={pageTitle} />
      <FormProvider {...methods}>
        <NavHashLink className={classes.backButton} exact to={prevRoute}>
          Back
        </NavHashLink>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {pageTitle}
          </Typography>
          <div className={classes.actionPanel}>
            <Button onClick={() => handleCancel()} variant="contained">
              Cancel
            </Button>
            <Button
              disabled={!isFormDirty}
              onClick={methods.handleSubmit(handleSubmit)}
              variant="contained"
              color="secondary"
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            <FormField
              fieldArray={fieldArray}
              errors={errors}
              values={formValues}
              selectValues={selectValues}
            />
          </form>
          <div className={classes.contentSidebar}>
            {isEdit && lastUpdated && (
              <div className={classes.lastUpdated}>
                <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                <div>{lastUpdated}</div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </>
  );
};

export default PersonForm;
