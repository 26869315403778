import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

export const DatePicker = props => {
  const {
    field: { fieldKey: name, displayedName },
    childrenProps,
  } = props;
  const classes = useStyles();

  const methods = useFormContext();

  const {
    control,
    formState: { isSubmitting },
    errors,
  } = methods;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <label htmlFor={inputProps.name} className={classes.label}>
        {displayedName}
      </label>
      <KeyboardDatePicker
        id={name}
        helperText={null}
        disabled={isSubmitting}
        inputRef={ref}
        format="MM/dd/yyyy"
        autoComplete="off"
        {...inputProps}
        {...childrenProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />
    </>
  );
};

DatePicker.propTypes = {
  field: PropTypes.shape({ fieldKey: PropTypes.string, displayedName: PropTypes.string }),
  childrenProps: PropTypes.shape(),
};
