import { assocPath, clone, dissocPath, init, is, last, path } from 'ramda';

export const IdSerializer = (values, mappingDict) => {
  let result = clone(values);
  mappingDict.forEach(pathKey => {
    if (is(Array, pathKey)) {
      const value = path(pathKey, values);
      const pathKeyWithId = init(pathKey).concat(`${last(pathKey)}Id`);
      result = assocPath(pathKeyWithId, value, result);
    } else {
      const newKey = `${pathKey}Id`;
      result[newKey] = values[pathKey];
    }
    const deleteKey = is(Array, pathKey) ? pathKey : [pathKey];
    result = dissocPath(deleteKey, result);
  });
  return result;
};
