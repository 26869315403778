import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import clsx from 'clsx';

import FormError from 'components/FormError';

import { getFormFieldValue } from 'utils/getFormFieldValue';

import useStyles from './useStyles';

const FormRowPasswordInput = ({ field, errors, values }) => {
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const inputRef = useRef();
  const { fieldKey, displayedName } = field;

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  const initialValue = getFormFieldValue(fieldKey, values);

  useEffect(() => {
    setValue(fieldKey, initialValue);
  }, [initialValue]);

  return (
    <div className={clsx(classes.formColumn)}>
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
      </label>
      <div className={classes.inputWrap}>
        <Controller
          id={fieldKey}
          control={control}
          onFocus={() => inputRef.current.focus()}
          className={classes.formInput}
          name={fieldKey}
          defaultValue={initialValue}
          as={
            <TextField
              inputRef={inputRef}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                      onMouseDown={toggleShowPassword}
                      className={classes.visibilityButtonsContainer}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          }
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowPasswordInput.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
  }),
  errors: PropTypes.shape({}),
  values: PropTypes.shape({}),
};

export default FormRowPasswordInput;
