import React from 'react';

import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';
import { isEmpty } from 'ramda';

import useStyles from './useStyles';

const TagList = ({ tags, className }) => {
  const classes = useStyles();

  if (isEmpty(tags)) return null;

  return <Chip label={tags.join(' ')} className={clsx(classes.tag, className)} />;
};

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

export default TagList;
