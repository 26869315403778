import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    address: PropTypes.string,
    city: PropTypes.string,
    fax: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    organizationType: PropTypes.number,
    phone: PropTypes.string,
    shortName: PropTypes.string,
    state: PropTypes.string,
    website: PropTypes.string,
    zip: PropTypes.string,
  },
  {
    selectName(data) {
      return data?.name;
    },
  },
);
