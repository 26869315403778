import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    sidebar: {
      width: theme.sizes.sidebar.width,
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
    },
    sidebarTitle: {
      margin: 0,
      marginLeft: 24,
      marginTop: 24,
      padding: 0,
      color: theme.palette.primary.main,
      opacity: 0.4,

      textTransform: 'uppercase',
      marginBottom: 12,
    },
    content: {
      width: `calc(100% - ${theme.sizes.sidebar.width}px)`,
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
      backgroundColor: theme.palette.mercury,
      paddingLeft: 24,
      paddingRight: 24,
      paddingBottom: 20,
    },
    title: {
      marginBottom: 8,
    },
    subtitle: {
      marginTop: '-24px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.5,
    },
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.sizes.actionPanel.marginBottom,
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
  }),
  { name: 'RemindersCOA' },
);
