import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import Header from 'components/Header';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';
import ListTable from 'components/ListTable';

import * as PersonService from 'domain/person/service';
import { getTableData } from 'domain/person/service';
import { useGetPersonsQuery } from 'domain/person/apiSlice';

import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'People';

const People = () => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const classes: any = useStyles();
  const { displayErrorsInToast } = useErrors();
  const { elementRef, elementHeight } = useRefElementHeight();
  const {
    data: people,
    isFetching: arePeopleLoading,
    error: peopleLoadError,
  } = useGetPersonsQuery();
  const peopleTableData = getTableData(people);

  useEffect(() => {
    if (peopleLoadError) displayErrorsInToast([peopleLoadError]);
  }, [peopleLoadError]);

  return (
    <ContentLayout>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState>
        <ActionPanelButtonWrapper>
          <Button
            to={appRoutes.partiesPersonCreatePath()}
            variant="contained"
            color="secondary"
            className={classes.actionPanelButton}
            component={NavHashLink}
          >
            Add Person
          </Button>
        </ActionPanelButtonWrapper>
      </Header>
      <ListTable
        detailPath={appRoutes.partiesPersonEditPath}
        isLoading={arePeopleLoading}
        tableHeader={Object.keys(PersonService.columnConfig)}
        tableData={peopleTableData}
        columnConfig={PersonService.columnConfig}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};

export default People;
