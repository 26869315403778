import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { ReactComponent as FileUploadIcon } from 'assets/fileUpload.svg';

import { getFieldError } from 'utils/errors';

import useStyles from './useStyles';

const ToolsImport = ({ importData, setImportData }) => {
  const classes = useStyles();
  const [fieldError, setFieldError] = useState(null);

  useEffect(() => {
    const { error } = importData;
    const filesError = getFieldError('file', error);
    if (filesError) {
      setFieldError(filesError);
    }
  }, [importData]);

  const handleDropAccept = files => {
    setImportData({
      ...importData,
      files,
      error: null,
    });
  };

  const handleDropReject = rejections => {
    setImportData({
      ...importData,
      files: [],
      error: rejections[0].errors[0].message,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleDropAccept,
    onDropRejected: handleDropReject,
    maxFiles: 1,
    accept:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  });

  const acceptedFilesExist = importData.files.length > 0;

  return (
    <div className={classes.importContainer}>
      <span className={classes.title}>Choose a File</span>
      <div className={classes.dropboxContainer} {...getRootProps()}>
        <input {...getInputProps()} />
        {acceptedFilesExist ? (
          importData.files.map(file => (
            <span key={file.path}>
              {file.path} -{file.size} bytes
            </span>
          ))
        ) : (
          <div className={classes.placeholderWrap}>
            <FileUploadIcon />
            <p className={classes.text}>
              Drag and drop file in this
              <br />
              area or click to attach
            </p>
          </div>
        )}
      </div>
      {fieldError && <span className={classes.error}>{fieldError}</span>}
    </div>
  );
};

ToolsImport.propTypes = {
  setImportData: PropTypes.func,
  importData: PropTypes.shape({
    files: PropTypes.arrayOf(PropTypes.shape({})),
    error: PropTypes.shape(),
  }),
};

export default ToolsImport;
