import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    sidebar: {
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
      width: theme.sizes.sidebar.width,
      padding: 0,
      backgroundColor: theme.palette.white,
    },
    sidebarTitle: {
      marginTop: 24,
      padding: [0, 24],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    sidebarNav: {
      marginTop: 12,
      width: '100%',
      '& > a, & > span': {
        display: 'block',
        height: 40,
        textDecoration: 'none',
        padding: [12, 24],
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        color: theme.palette.primary.main,
        verticalAlign: 'middle',
        '&.active': {
          backgroundColor: theme.palette.catskillWhiteTwo,
          position: 'relative',
          '&:before': {
            content: "''",
            display: 'block',
            width: 4,
            height: 32,
            borderRadius: 34,
            backgroundColor: theme.palette.secondary.main,
            position: 'absolute',
            left: 4,
            top: 4,
          },
        },
      },
    },
    content: {
      width: `calc(100% - ${theme.sizes.sidebar.width}px)`,
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
      padding: [24, 24, 20, 24],
      backgroundColor: theme.palette.linkWater,
    },
    backButton: {
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      textDecoration: 'none',
    },
    contentTitle: {
      marginTop: 8,
      marginBottom: 8,
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
    },
    actionPanel: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: theme.sizes.actionPanel.marginBottom,
      '& > button': {
        marginRight: 8,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    deleteItem: {
      color: theme.palette.mandy,
      '&:hover': {
        color: theme.palette.white,
        backgroundColor: theme.palette.mandy,
      },
    },
    contentWrap: {
      marginTop: 32,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    contentSidebar: {
      width: 300,
      marginLeft: 60,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    checkboxWrap: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    contentForm: {
      width: '100%',
    },
    lastUpdated: {
      textAlign: 'right',
      color: theme.palette.primary,
    },
    lastUpdatedTitle: {
      fontSize: theme.typography.pxToRem(12),
      opacity: 0.4,
    },
    lockEditForm: {
      marginRight: 0,
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'PersonForm' },
);
