import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import filterIcon from 'assets/filter.svg';
import clsx from 'clsx';
import chevronIcon from 'assets/chevron.svg';
import { FormProvider, useForm } from 'react-hook-form';

import LoanFilterSelect from 'components/LoanFilterSelect';
import LoanFilterSelectAsync from 'components/LoanFilterSelectAsync';

import { useLoansFilters } from 'hooks/useLoansFilters';

import useStyles from 'pages/Loans/useStyles';

const ActionPanel = ({
  servicerCounselOptions,
  isServicerCounselLoading,
  loanServicerOptions,
  isLoanServicerLoading,
  isOptionsLoadFulfilled,
  onSubmit,
  onClick,
}) => {
  const classes = useStyles();
  const [isShownFilters, setShownFilters] = useState(false);
  const {
    loansFilterParams: { servicerCounsel, loanServicer },
  } = useLoansFilters();

  const toggleShownFilters = () => {
    onClick();
    setShownFilters(!isShownFilters);
  };

  const methods = useForm({
    defaultValues: {
      loanServicer: null,
      originalLender: [],
      servicerCounsel: null,
    },
  });

  return (
    <div className={classes.actionPanel}>
      <div>
        <Button
          classes={{ root: classes.addFilter }}
          startIcon={<img src={filterIcon} alt="filter" />}
          variant="text"
          color="primary"
          onClick={toggleShownFilters}
        >
          Filter
          <div
            className={clsx(classes.chevronIcon, {
              [classes.chevronIconRotated]: isShownFilters,
            })}
          >
            <img src={chevronIcon} alt="chevron" />
          </div>
        </Button>
      </div>
      {isShownFilters && (
        <div className={classes.filtersBox}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.filtersForm}>
              <LoanFilterSelect
                title="Servicer Counsel"
                name="servicerCounsel"
                options={servicerCounselOptions}
                defaultValue={servicerCounsel}
                isLoading={isServicerCounselLoading}
                isOptionsLoadFulfilled={isOptionsLoadFulfilled}
              />
              <LoanFilterSelect
                title="Loan Statement Servicer"
                name="loanServicer"
                options={loanServicerOptions}
                defaultValue={loanServicer}
                isLoading={isLoanServicerLoading}
                isOptionsLoadFulfilled={isOptionsLoadFulfilled}
              />
              <LoanFilterSelectAsync title="Original Lender" name="originalLender" multiple />
              <Button
                type="submit"
                variant="contained"
                color="default"
                className={classes.filterButton}
              >
                Apply Filter
              </Button>
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default ActionPanel;

ActionPanel.propTypes = {
  onClick: PropTypes.func,
  onSubmit: PropTypes.func,
  servicerCounselOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  loadServicerCounsel: PropTypes.func,
  isServicerCounselLoading: PropTypes.bool,
  loadLoanServicer: PropTypes.func,
  loanServicerOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  isLoanServicerLoading: PropTypes.bool,
  isOptionsLoadFulfilled: PropTypes.bool,
};
