import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { isEmpty } from 'ramda';
import ToolsRepository from 'repositories/ToolsRepository';

import Popup from 'components/Popup';

import { ERROR_MESSAGES } from 'enums';

import { useReportTask } from 'hooks/api/useReportTask';
import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';

import { reportActions } from 'utils/reports';
import { isDateBefore } from 'utils/date';

import DatePicker from './components/DatePicker';
import useStyles from './useStyles';
import { resolver, formFields, prepareForSend, formFieldsKeys } from './validation.js';

const TITLE = 'Journal Entries';
const SUCCESS = 'Successfully Generated';

export const JournalEntriesPopup = props => {
  const classes = useStyles();
  const { setPopupState } = props;
  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading, resetReportTask } =
    useReportTask();

  const { formatErrors, setErrorsToForm, displayErrorsInToast } = useErrors();

  const { showSuccessNotification } = useSnackbar();

  const methods = useForm({
    resolver,
    defaultValues: {
      [formFields.fromDate.fieldKey]: null,
      [formFields.toDate.fieldKey]: null,
    },
    shouldFocusError: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const { setError, errors, handleSubmit } = methods;

  useEffect(() => () => resetReportTask(), []);

  const handleImportClose = () => {
    setPopupState(null);
  };

  const fetchReportLocation = data => () => {
    const dates = prepareForSend(data);
    return ToolsRepository.journalEntriesReport(dates);
  };

  const handleDownloadClick = async data => {
    const toastText = `${TITLE} ${SUCCESS}`;

    if (isDateBefore(data.toDate, data.fromDate, { shouldResetTime: true })) {
      setError(formFields.toDate.fieldKey, {
        message: ERROR_MESSAGES.date.shouldBeAfter,
        shouldFocus: true,
      });
      return;
    }

    const fetchLocation = fetchReportLocation(data);
    try {
      const href = await initiateTaskReportLoading(fetchLocation);
      await reportActions.download(href);
      showSuccessNotification(toastText);
      handleImportClose();
    } catch (reportErrors) {
      if (!isEmpty(reportErrors)) {
        const { backendServicesError, nonFieldErrors, fieldErrors } = formatErrors(reportErrors);
        displayErrorsInToast([backendServicesError, nonFieldErrors]);
        setErrorsToForm(setError, errors, fieldErrors);
        finishLoading(reportErrors);
      }
    }
  };

  return (
    <Popup
      title={TITLE}
      submitButtonText="Download Report"
      toggleState={setPopupState}
      onSubmit={handleSubmit(handleDownloadClick)}
      onClose={handleImportClose}
      isLoading={isTaskReportLoading}
    >
      <FormProvider {...methods}>
        <div className={classes.dateInputWrap}>
          {formFieldsKeys.map(key => (
            <DatePicker field={formFields[key]} key={key} />
          ))}
        </div>
      </FormProvider>
    </Popup>
  );
};

JournalEntriesPopup.propTypes = {
  setPopupState: PropTypes.func,
};
