import React, { useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import LoanHeader from 'components/LoanHeader';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';

import {
  useGetLoanDefeasedPaymentsQuery,
  useGetLoanDefeasedPaymentsSummaryQuery,
} from 'domain/loanDefeasedPayment/apiSlice';
import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';
import { isStatusActiveInPayment } from 'utils/layoutStatus';

import DefeasedPaymentsContent from './components/DefeasedPaymentsContent';
import useStyles from './useStyles';

const OID_BALANCE = 'OID Balance';
const EDIT_BUTTON = 'Edit Property';

export const LoanDefeasedPayments = () => {
  const classes = useStyles();

  const {
    query: { id },
  } = useRouter();

  const header = useRef(null);

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);

  const {
    data: defeasedPaymentsData,
    isFetching: isDefeasedPaymentsLoading,
    error: defeasedPaymentsLoadError,
  } = useGetLoanDefeasedPaymentsQuery(id);

  const {
    data: summaryData,
    isFetching: isSummaryLoading,
    error: summaryLoadError,
  } = useGetLoanDefeasedPaymentsSummaryQuery(id);

  useHandleRtkQueryErrors(loanLoadError, defeasedPaymentsLoadError, summaryLoadError);

  const title = LoanService.getDealName(loanData);
  const loanStatus = LoanService.getLoanStatus(loanData);

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isDataLoading = isDefeasedPaymentsLoading || isLoanLoading || isSummaryLoading;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isDataLoading}>
      <DocumentTitle title={title} />
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <LoanHeader ref={header} title={title} loanStatus={loanStatus}>
          <div>
            {isStatusActiveInPayment(loanStatus) && (
              <Link
                href={appRoutes.OIDBalanceReportLoanPath(id)}
                color="secondary"
                className={classes.link}
              >
                {OID_BALANCE}
              </Link>
            )}
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.loanEditPath(id)}
              component={NavLink}
            >
              {EDIT_BUTTON}
            </Button>
          </div>
        </LoanHeader>
        <ContentLayout>
          <DefeasedPaymentsContent
            loanId={Number(id)}
            defeasedPaymentsData={defeasedPaymentsData}
            summaryData={summaryData}
          />
        </ContentLayout>
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
