import React from 'react';

import PersonForm from 'components/PersonForm';

import { getPersonDefaultValues } from 'domain/person/service';

import ContentLayout from 'layouts/ContentLayout';

const PersonAdd = () => (
  <ContentLayout>
    <PersonForm
      isEdit={false}
      formValues={getPersonDefaultValues()}
      pageTitle="New Person"
      submitButtonText="Add"
    />
  </ContentLayout>
);

export default PersonAdd;
