import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse } from 'utils/objects';
import { createTag, invalidateOnSuccess } from 'utils/rtkQuery';

export const loanEscrowAccountApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoanEscrowAccounts: builder.query({
      query: id => ({
        url: apiRoutes.loansEscrowAccountsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.loanEscrowAccount),
    }),
    updateLoanEscrowAccount: builder.mutation({
      query: ({ loanId, escrowAccountId, data }) => ({
        url: apiRoutes.loansEscrowAccountPath(loanId, escrowAccountId),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOnSuccess([{ type: CACHE_TAG.loanEscrowAccount, id: 'LIST' }]),
    }),
  }),
});

export const { useGetLoanEscrowAccountsQuery, useUpdateLoanEscrowAccountMutation } =
  loanEscrowAccountApi;
