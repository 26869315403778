import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';

import useStyles from 'pages/LoanReconciliationInputs/useStyles';

export const TextInputCell = props => {
  const { name, childrenProps } = props;
  const classes = useStyles();
  const methods = useFormContext();
  const { control, errors } = methods;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <>
      <TextField
        variant="outlined"
        multiline
        rows={4}
        inputRef={ref}
        {...inputProps}
        {...childrenProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />
    </>
  );
};

TextInputCell.propTypes = {
  name: PropTypes.string,
  childrenProps: PropTypes.shape({}),
};
