import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';
import { any } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';
import FormField from 'components/FormField';
import Loader from 'components/Loader';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import * as OptionsService from 'domain/options/service';

import { useOrganizations, useSuccessorBorrowers } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import { setServerErrorsToFields } from 'utils/errors';
import { IdSerializer } from 'utils/serializer';
import { getOrganizationTypes } from 'utils/getOrganizationsType';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const AdministrativeAgentForm = ({
  isEdit,
  formValues,
  pageTitle,
  submitButtonText,
  isDefaultDataLoading,
  requestError,
  onSubmit,
  onCancel,
  previousRoute,
  lastUpdated,
}) => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const methods = useForm({
    resolver,
  });
  const isFormDirty = methods.formState.isDirty;
  const { setError, errors } = methods;

  const { data: options, isFetching: isOptionsLoading } = useGetOptionsQuery(
    null,
    getOptionsQueryParams(),
  );
  const stateSelectOptions = OptionsService.selectStateSelectOptions(options);

  const { isConfigurationLoading } = useSuccessorBorrowers();

  const {
    providersOptions,
    filteredOrganizationOptions,
    loadProviders,
    loadOrganizations,
    isOrganizationsLoading,
  } = useOrganizations();

  const selectValues = {
    states: stateSelectOptions,
    providers: providersOptions,
    registeredAgents: filteredOrganizationOptions(getOrganizationTypes(options).registeredAgent),
  };

  const fetchData = async () => {
    try {
      await Promise.all([loadProviders(), loadOrganizations()]);
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (requestError) {
      setServerErrorsToFields(setError, errors, requestError);
    }
  }, [requestError]);

  const handleSubmit = async values => {
    const mappingDict = ['registeredAgent', ['bankAccount', 'provider']];
    const serializedValues = IdSerializer(values, mappingDict);
    onSubmit(serializedValues);
  };

  const handleCancel = () => onCancel();

  const isRequestsLoading = [
    isConfigurationLoading,
    isDefaultDataLoading,
    isOrganizationsLoading,
    isOptionsLoading,
  ];

  if (any(loaderRequest => loaderRequest)(isRequestsLoading)) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <DocumentTitle title={pageTitle} />
      <FormProvider {...methods}>
        <NavHashLink className={classes.backButton} exact to={previousRoute}>
          Back
        </NavHashLink>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {pageTitle}
          </Typography>
          <div className={classes.actionPanel}>
            <Button onClick={() => handleCancel()} variant="contained">
              Cancel
            </Button>
            <Button
              disabled={!isFormDirty}
              onClick={methods.handleSubmit(handleSubmit)}
              variant="contained"
              color="secondary"
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            <FormField
              fieldArray={fieldArray}
              errors={methods.errors}
              values={formValues}
              selectValues={selectValues}
            />
          </form>
          <div className={classes.contentSidebar}>
            {isEdit && (
              <div className={classes.lastUpdated}>
                <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                <div>{lastUpdated}</div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </>
  );
};

AdministrativeAgentForm.propTypes = {
  isEdit: PropTypes.bool,
  formValues: PropTypes.shape({}),
  pageTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
  requestError: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  previousRoute: PropTypes.string,
  isDefaultDataLoading: PropTypes.bool,
  lastUpdated: PropTypes.string,
};

export default AdministrativeAgentForm;
