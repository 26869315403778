import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { DateConst, StatusConst } from 'const';

import { ERROR_MESSAGES, FORM_FIELD_TYPE, STATUS_COLUMN } from 'enums';

import BankAccountPresenter from 'presenters/BankAccountPresenter';
import AddressPresenter from 'presenters/AddressPresenter';

import { transformDate } from 'utils/date';
import { bankAccountValidationSchema, optionalAddressSchemas } from 'utils/validationSchemas';

export const resolver = yupResolver(
  Yup.object({
    name: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    shortName: Yup.string().trim().nullable(),
    parentCompany: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .required(ERROR_MESSAGES.required),
    cmbsPool: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    administrativeAgent: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    filingRef: Yup.string().nullable().required(ERROR_MESSAGES.required),
    status: Yup.string().trim().required(),
    statusNote: Yup.string().trim().nullable(),
    ein: Yup.string().trim().nullable(),
    einDateFiledOn: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    einVerificationLetterRecordDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    secretaryOfStateId: Yup.string().trim().nullable(),
    sosAcceptedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    northCarolinaCertificateOfAuthorityApplicationDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    northCarolinaCertificateOfAuthorityAcceptedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    northCarolinaSecretaryOfStateId: Yup.string().trim().nullable(),
    jurisdiction: Yup.string().trim().nullable(),
    address: optionalAddressSchemas,
    fiscalYear: Yup.string().trim().nullable(),
    registeredAgent: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    registeredOfficeAddress: optionalAddressSchemas,
    dateOfDissolutionOn: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    bankAccount: bankAccountValidationSchema,
  }),
);

export const fieldArray = [
  { fieldKey: 'name', displayedName: 'Name', type: FORM_FIELD_TYPE.input, isRequired: true },
  { fieldKey: 'shortName', displayedName: 'Short Name', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'parentCompany',
    displayedName: 'Parent Company',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'managers',
    placeholder: 'Select Parent Company',
    isRequired: true,
  },
  {
    fieldKey: 'cmbsPool',
    displayedName: 'CMBS Pool',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'pools',
    placeholder: 'Select CMBS Pool',
  },
  {
    fieldKey: 'administrativeAgent',
    displayedName: 'Administrative Agent',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'administrativeAgents',
    placeholder: 'Select Administrative Agent',
    isRequired: true,
  },
  {
    fieldKey: 'filingRef',
    displayedName: 'Ref Number',
    type: FORM_FIELD_TYPE.input,
    isRequired: true,
  },
  {
    fieldKey: 'status',
    displayedName: 'Status',
    type: FORM_FIELD_TYPE.enum,
    isRequired: true,
    options: [
      {
        key: STATUS_COLUMN.active,
        displayedName: 'Active',
        value: StatusConst.active,
      },
      { key: STATUS_COLUMN.inactive, displayedName: 'Inactive', value: StatusConst.inactive },
    ],
  },
  { fieldKey: 'statusNote', displayedName: 'Status Note', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'ein', displayedName: 'EIN', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'einDateFiledOn', displayedName: 'EIN Filed Date', type: FORM_FIELD_TYPE.date },
  {
    fieldKey: 'einVerificationLetterRecordDate',
    displayedName: 'EIN Verification Letter Received Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'secretaryOfStateId',
    displayedName: 'DE SOS ID',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'sosAcceptedDate',
    displayedName: 'DE SOS Accepted Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'northCarolinaCertificateOfAuthorityApplicationDate',
    displayedName: 'NC Certificate of Authority Application Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'northCarolinaCertificateOfAuthorityAcceptedDate',
    displayedName: 'NC Certificate of Authority Accepted Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'northCarolinaSecretaryOfStateId',
    displayedName: 'NC SOS ID',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'jurisdiction',
    displayedName: 'Jurisdiction',
    type: FORM_FIELD_TYPE.input,
    hasMargin: true,
  },
  {
    fieldKey: 'address',
    type: FORM_FIELD_TYPE.address,
    fields: AddressPresenter.formFields({
      prefix: 'address',
      titleName: 'Company Address',
      isReadonly: true,
    }),
  },
  {
    fieldKey: 'fiscalYear',
    displayedName: 'Fiscal Year Ending',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'registeredAgent',
    displayedName: 'DE Registered Agent',
    type: FORM_FIELD_TYPE.select,
    hasMargin: true,
    selectValuesType: 'registeredAgents',
    placeholder: 'Select Registered Agent',
  },
  {
    fieldKey: 'registeredOfficeAddress',
    type: FORM_FIELD_TYPE.address,
    fields: AddressPresenter.formFields({
      prefix: 'registeredOfficeAddress',
      titleName: 'Registered Office Address',
      isReadonly: true,
    }),
  },
  {
    fieldKey: 'dateOfDissolutionOn',
    displayedName: 'Date of Dissolution',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'comments',
    displayedName: 'Comments',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'bankAccount',
    type: FORM_FIELD_TYPE.bank,
    fields: BankAccountPresenter.formFields({
      prefix: 'bankAccount',
      titleName: 'Bank Account',
      isRequired: true,
    }),
  },
];
