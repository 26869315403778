import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    title: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.capeCod,
      marginBottom: 10,
    },
    row: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
    label: {
      display: 'block',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.capeCod,
      opacity: 0.6,
      width: 110,
    },
    value: {
      display: 'block',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.capeCod,
    },
  }),
  { name: 'CellServicer' },
);
