import React, { useState, useEffect } from 'react';

import { isValid } from 'date-fns';
import PropTypes from 'prop-types';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { formatDateToMonthYear } from 'utils/date';
import { getFieldError } from 'utils/errors';

import useStyles from './useStyles';

const TITLE = 'Choose month and year';

export const MonthInput = props => {
  const { importData, setImportData, name } = props;
  const classes = useStyles();
  const [value, setValue] = useState(new Date());
  const [fieldError, setFieldError] = useState(null);

  const handleMonthSubmit = date => {
    setImportData({
      ...importData,
      [name]: formatDateToMonthYear(date),
    });
  };

  const handleValueChange = (date, currentValue) => {
    setValue(currentValue);
    if (isValid(date)) {
      handleMonthSubmit(date);
    } else {
      handleMonthSubmit(null);
    }
  };
  const handleAccept = date => setValue(date);

  useEffect(() => {
    const { error } = importData;
    const monthError = getFieldError(name, error);
    if (monthError) {
      setFieldError(monthError);
    }
  }, [importData]);

  return (
    <div className={classes.monthInput}>
      <label className={classes.inputLabel} htmlFor={name}>
        {TITLE}
      </label>
      <KeyboardDatePicker
        id={name}
        onAccept={handleAccept}
        views={['month', 'year']}
        onChange={handleValueChange}
        name={name}
        helperText={null}
        format="MM/yyyy"
        value={value}
      />
      {fieldError && <span className={classes.error}>{fieldError}</span>}
    </div>
  );
};

MonthInput.propTypes = {
  importData: PropTypes.shape({
    error: PropTypes.shape(),
  }),
  setImportData: PropTypes.func,
  name: PropTypes.string,
};
