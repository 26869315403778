import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    bodyBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
    input: {
      height: '24px',
      '&>input': {
        textAlign: 'end',
      },
    },
  }),
  { name: 'BalanceAdjustmentInput' },
);
