const SEARCH_BY_LABELS = {
  successorBorrower: 'Successor Borrower',
  name: 'Loan by Name',
  id: 'Loan by PID',
  filingRef: 'Loan by Filing Ref',
  bloombergName: 'Loan by Bloomberg Name',
  custodianAccountNumber: 'Loan by Custodian Account Number',
  masterServicerLoanNumber: 'Loan by Loan number',
};

Object.freeze(SEARCH_BY_LABELS);

export { SEARCH_BY_LABELS };
