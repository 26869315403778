import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    root: {
      width: '100%',
    },
    text: {
      whiteSpace: 'pre-wrap',
    },
    listHeader: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.catskillWhite}`,
      paddingTop: '12px',
      paddingRight: '16px',
      paddingBottom: '12px',
      paddingLeft: '16px',
    },
    header: {
      alignSelf: 'center',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(21),
      color: theme.palette.elephant,
    },
    memberInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: '16px',
    },
    smallText: {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      textAlign: 'end',
      color: 'rgba(0, 0, 0, 0.4)',
    },
    fontBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.elephant,
    },
    fontLink: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
    },
    fontNormal: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.elephant,
    },
    columnHeader: {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.elephant,
      opacity: '0.4',
      marginBottom: '3px',
    },
    accordionDetails: {
      display: 'block',
    },
    details: {
      display: 'block',
      padding: 0,
    },
    accordionSummaryContent: {
      order: 2,
      paddingTop: '18px',
      paddingBottom: '18px',
      paddingRight: 0,
      margin: '0 0',
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    accordionExpanded: {
      margin: '0 0',
    },
    summaryWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    summary: {
      paddingRight: 0,
      borderBottom: `1px solid ${theme.palette.catskillWhite}`,
    },
    accordionExpandIcon: {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      order: 1,
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    balance: {
      marginRight: '16px',
      textAlign: 'right',
    },
    sbSummaryPadding: {
      paddingLeft: '56px',
    },
    loanPadding: {
      width: 'auto',
      paddingLeft: '96px',
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  }),
  { name: 'MembersNestedList' },
);
