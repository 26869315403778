export default {
  MuiFormControl: {
    root: {
      width: '100%',
      marginTop: 4,
    },
  },
  MuiTextField: {
    root: {
      marginTop: 0,
      marginBottom: 0,
      height: 'auto',
    },
  },
};
