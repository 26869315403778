import React from 'react';

import PropTypes from 'prop-types';
import { keys } from 'ramda';

import useStyles from './useStyles';

const CellInformation = ({ cellData, fieldLabels }) => {
  const classes = useStyles();

  return (
    <>
      {cellData?.title && <div className={classes.title}>{cellData?.title}</div>}
      {keys(fieldLabels).map(key => {
        if (!cellData?.fields[key]) return null;
        return (
          <div className={classes.row} key={key}>
            <span className={classes.label}>{fieldLabels[key]}</span>
            <span className={classes.value}>{cellData?.fields[key]}</span>
          </div>
        );
      })}
    </>
  );
};

CellInformation.propTypes = {
  cellData: PropTypes.shape({
    title: PropTypes.string,
    fields: PropTypes.shape({}),
  }),
  fieldLabels: PropTypes.shape({}),
};

export default CellInformation;
