import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableWrap: {
      marginTop: '40px',
      marginBottom: '40px',
    },
    accountingTable: {
      '& thead th:nth-last-child(-n+3)': {
        color: theme.palette.elephant,
      },
      '& thead th': {
        paddingRight: '12px',
        paddingLeft: '12px',
      },
      '& tbody td': {
        color: theme.palette.elephant,
      },
      '& tbody > tr > td:nth-last-child(-n+3)': {
        fontWeight: 'bold',
      },
      '& thead th:nth-child(n+2), td:nth-child(n+2)': {
        textAlign: 'right',
      },
    },
    tableBottomRow: {
      background: theme.palette.athensGray,
    },
    tableBottomCell: {
      padding: '12px',
    },
    listFooterFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    fontColorMain: {
      color: theme.palette.elephant,
    },
  }),
  { name: 'AccountingTable' },
);
