import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableHeadCell: {
      padding: '14px',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      color: theme.palette.santasGray,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      backgroundColor: theme.palette.athensGray,
      position: 'sticky',
      zIndex: 1,
    },
    rowMiniView: {
      padding: 0,
    },
    cellMiniView: {
      backgroundColor: theme.palette.white,
      padding: '12px',
    },
    tableHeadCellSticky: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
    sortButton: {
      maxHeight: '20px',
      outline: 0,
      border: 0,
      cursor: 'pointer',
      background: 'transparent',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      color: theme.palette.santasGray,
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    sortingIsActive: {
      color: theme.palette.primary.main,
    },
    sortButtonIcon: {
      width: '10px',
      stroke: theme.palette.santasGray,
    },
  }),
  { name: 'TableHeadContent' },
);
