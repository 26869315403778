import React from 'react';

import PropTypes from 'prop-types';
import { Link } from '@material-ui/core';
import clsx from 'clsx';
import { isNil } from 'ramda';

import EditableCell from 'components/EditableCell';
import TagList from 'components/TagList';

import { isActive, isInactive, isStatusColumn } from 'utils/table';

import useStyles from './useStyles';

export const CellComponent = props => {
  const { column, value, colKey, selectValues, onEdit, rowId } = props;
  const classes = useStyles();

  if (column?.editable) {
    const { fieldKey, editableFieldType } = column;
    return (
      <EditableCell
        onEdit={onEdit}
        editableFieldType={editableFieldType}
        fieldKey={fieldKey}
        value={value}
        rowId={rowId}
        options={selectValues?.[column?.selectValuesType] || []}
      />
    );
  }

  if (isNil(value)) return null;

  if (colKey === 'email') {
    return (
      <Link color="secondary" href={`mailto:${value}`} onClick={event => event.stopPropagation()}>
        {value}
      </Link>
    );
  }
  if (isStatusColumn(colKey)) {
    return (
      <TagList
        tags={[value]}
        className={clsx(classes.statusCol, {
          [classes.statusActive]: isActive(value),
          [classes.statusInactive]: isInactive(value),
        })}
      />
    );
  }

  return value;
};

CellComponent.propTypes = {
  column: PropTypes.shape({
    align: PropTypes.string,
    displayedName: PropTypes.string,
    sortingEnabled: PropTypes.bool,
    isLink: PropTypes.bool,
    withTags: PropTypes.bool,
    canBeHidden: PropTypes.bool,
    editable: PropTypes.bool,
    editableFieldType: PropTypes.string,
    fieldKey: PropTypes.string,
    selectValuesType: PropTypes.string,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  colKey: PropTypes.string,
  rowId: PropTypes.number,
  selectValues: PropTypes.shape(),
  onEdit: PropTypes.func,
};
