import React, { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import LoanForm from 'components/LoanForm';

import { useGetConfigurationDealQuery } from 'domain/configurationDeal/apiSlice';
import { useCreateLoanMutation } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';

import useRouter from 'hooks/useRouter';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { createLoanCRUDLinks } from 'utils/routes';
import { parseQueryString } from 'utils/url';

const SUBMIT_BUTTON_TEXT = 'Create';
const NEW_LOAN_TITLE = 'Loan Create';

const LoanCreate = () => {
  const { push } = useRouter();

  const {
    data: dealConfiguration,
    isFetching,
    error: dealConfigurationLoadError,
  } = useGetConfigurationDealQuery();

  const { search } = useLocation();
  const searchParamsData = parseQueryString(search);

  const defaultFormValues = LoanService.createDefaultValues({
    ...dealConfiguration,
    ...searchParamsData,
  });

  const [
    createLoan,
    { error: loanCreateError, isSuccess: isLoanCreateSuccess, isLoading: isLoanCreateLoading },
  ] = useCreateLoanMutation();

  const successorBorrowerId = searchParamsData?.successorBorrower;

  const previousRoute = successorBorrowerId
    ? appRoutes.successorBorrowerPath(successorBorrowerId)
    : appRoutes.dealsPath();

  const handleSubmitClick = params => createLoan(params);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isLoanCreateSuccess) push(previousRoute);
  }, [isLoanCreateSuccess]);

  const sideBarContent = createLoanCRUDLinks(false);

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <LoanForm
        defaultValuesLoadError={dealConfigurationLoadError}
        formValues={defaultFormValues}
        isDefaultDataLoading={isFetching}
        isEdit={false}
        isLoanSubmitLoading={isLoanCreateLoading}
        onCancel={handleCancelClick}
        onSubmit={handleSubmitClick}
        previousRoute={previousRoute}
        submitButtonText={SUBMIT_BUTTON_TEXT}
        submitQueryError={loanCreateError}
        title={NEW_LOAN_TITLE}
      />
    </RootLayoutV2>
  );
};

export default LoanCreate;
