import React, { useEffect } from 'react';

import AdministrativeAgentForm from 'components/AdministrativeAgentForm';

import {
  useGetAdministrativeAgentQuery,
  useUpdateAdministrativeAgentMutation,
} from 'domain/administrativeAgent/apiSlice';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import { getEntityLastUpdated } from 'utils/date';

const AdministrativeAgentEdit = () => {
  const {
    query: { id },
    push,
  } = useRouter();
  const { displayErrorsInToast } = useErrors();

  const {
    data: administrativeAgent,
    error: administrativeAgentLoadError,
    isLoading: isAdministrativeAgentLoading,
  } = useGetAdministrativeAgentQuery(id);

  const [
    updateAdministrativeAgent,
    {
      error: administrativeAgentUpdateError,
      isSuccess: isAdministrativeAgentUpdateSuccess,
      isLoading: isAdministrativeAgentUpdateLoading,
    },
  ] = useUpdateAdministrativeAgentMutation();

  useEffect(() => {
    if (administrativeAgentLoadError) displayErrorsInToast([administrativeAgentLoadError]);
  }, [administrativeAgentLoadError]);

  const previousRoute = appRoutes.staffAdministrativeAgentPath(id);

  const handleSubmitClick = values =>
    updateAdministrativeAgent({ id, administrativeAgent: values });

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isAdministrativeAgentUpdateSuccess) push(previousRoute);
  }, [isAdministrativeAgentUpdateSuccess]);

  const lastUpdated = getEntityLastUpdated(administrativeAgent);

  const isDataLoading = isAdministrativeAgentLoading || isAdministrativeAgentUpdateLoading;

  return (
    <ContentLayout>
      <AdministrativeAgentForm
        isEdit
        formValues={administrativeAgent}
        pageTitle="Administrative Agent Details"
        submitButtonText="Update"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        isDefaultDataLoading={isDataLoading}
        requestError={administrativeAgentUpdateError}
        previousRoute={previousRoute}
        lastUpdated={lastUpdated}
      />
    </ContentLayout>
  );
};

export default AdministrativeAgentEdit;
