import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const organizationApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getOrganizations: builder.query({
      query: params => ({
        url: apiRoutes.organizationsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.organization),
    }),
    getOrganization: builder.query({
      query: id => ({
        url: apiRoutes.partiesOrganizationPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.organization),
    }),
    updateOrganization: builder.mutation({
      query: ({ id, organization }) => ({
        url: apiRoutes.partiesOrganizationPath(id),
        method: 'PUT',
        data: organization,
      }),
      invalidatesTags: [{ type: CACHE_TAG.organization, id: 'LIST' }],
    }),
    createOrganization: builder.mutation({
      query: data => ({
        url: apiRoutes.organizationsPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.organization, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useLazyGetOrganizationQuery,
  useGetOrganizationQuery,
  useGetOrganizationQueryState,
  useUpdateOrganizationMutation,
  useCreateOrganizationMutation,
} = organizationApi;
