import React from 'react';

import { Typography, Button } from '@material-ui/core';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Check your inbox';
const MESSAGE =
  "If there's a user with such email address, the email is sent to this email address.";
const SIGN_IN = 'Sign In';

export const ConfirmationMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {TITLE}
      </Typography>
      <Typography className={classes.message}>{MESSAGE}</Typography>
      <Button
        variant="contained"
        color="secondary"
        href={appRoutes.authPath()}
        className={classes.button}
      >
        {SIGN_IN}
      </Button>
    </div>
  );
};
