import { createSlice } from '@reduxjs/toolkit';
import PersonsRepository from 'repositories/PersonsRepository';
import { useDispatch } from 'react-redux';

import { createRestSlice } from 'utils/RestSlice';
import { selectResultsFromResponse } from 'utils/objects';

const personsRestSlice = createRestSlice({
  resource: 'person',
  repository: PersonsRepository,
  slices: ['loadPersons', 'loadPerson', 'updatePerson', 'deletePerson', 'createPerson'],
});

const personChoices = {
  startPersonChoicesLoad(state) {
    state.arePersonChoicesLoading = true;
    state.personChoices = [];
    state.personChoicesLoadError = null;
  },
  personChoicesLoadFulfilled(state, { payload }) {
    state.arePersonChoicesLoading = false;
    state.personChoices = payload;
  },
  personChoicesLoadRejected(state, { payload }) {
    state.arePersonChoicesLoading = false;
    state.personChoices = [];
    state.personChoicesLoadError = payload;
  },
};

const initialState = {
  ...personsRestSlice.initialState,
};

const personsSlice = createSlice({
  name: 'persons',
  initialState,
  reducers: {
    ...personChoices,
    ...personsRestSlice.reducers,
  },
});

export const usePersonChoicesActions = () => {
  const dispatch = useDispatch();

  const loadPersonChoices = id => {
    dispatch(personsSlice.actions.startPersonChoicesLoad());
    return PersonsRepository.personChoices(id)
      .then(response => {
        dispatch(
          personsSlice.actions.personChoicesLoadFulfilled(selectResultsFromResponse(response)),
        );
      })
      .catch(e => {
        dispatch(personsSlice.actions.personChoicesLoadRejected(e));
      });
  };

  return {
    loadPersonChoices,
  };
};

export default personsSlice.reducer;
export const personsRestHooks = personsRestSlice.hooks(personsSlice, 'PersonsSlice');
