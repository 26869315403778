import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import FormRowInput from 'components/FormRowInput';
import FormRowSelect from 'components/FormRowSelect';
import FormRowDatepicker from 'components/FormRowDatepicker';
import FormRowEnumDatepicker from 'components/FormRowEnumDatepicker';
import FormAddress from 'components/FormAddress';
import FormBankAccount from 'components/FormBankAccount';
import FormRowEnum from 'components/FormRowEnum';
import FormRowCheckbox from 'components/FormRowCheckbox';
import FormRowDecimalInput from 'components/FormRowDecimalInput';
import FormRowPasswordInput from 'components/FormRowPasswordInput';
import FormRowSelectAutocomplete from 'components/FormRowSelectAutocomplete';

const isFieldReadonly = field => field.isReadonly ?? false;

export const FormField = ({
  fieldArray,
  values,
  errors,
  selectValues,
  disabledFieldOptions,
  changeFunctions,
}) =>
  fieldArray.map(field => {
    const disabled = isFieldReadonly(field) || (disabledFieldOptions[field.fieldKey] ?? false);
    const onChange = changeFunctions?.[field.fieldKey] || null;
    const isRequired = field.isRequired ?? false;
    switch (field.type) {
      case FORM_FIELD_TYPE.input:
        return (
          <FormRowInput
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
            disabled={disabled}
            isRequired={isRequired}
          />
        );

      case FORM_FIELD_TYPE.decimal:
        return (
          <FormRowDecimalInput
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
            disabled={disabled}
            isRequired={isRequired}
          />
        );

      case FORM_FIELD_TYPE.date:
        return (
          <FormRowDatepicker
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
            isRequired={isRequired}
          />
        );

      case FORM_FIELD_TYPE.select:
        return (
          <FormRowSelect
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
            options={selectValues?.[field?.selectValuesType] || []}
            disabled={disabled}
            onChangeField={onChange}
            isRequired={isRequired}
          />
        );

      case FORM_FIELD_TYPE.enum:
        return (
          <FormRowEnum
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
            options={field.options}
            isRequired={isRequired}
          />
        );

      case FORM_FIELD_TYPE.enumDate:
        return (
          <FormRowEnumDatepicker
            key={`${field.fieldKeyEnum} + ${field.fieldKeyInput}`}
            field={field}
            errors={errors}
            values={values}
            options={field.enumOptions}
          />
        );

      case FORM_FIELD_TYPE.checkbox:
        return <FormRowCheckbox field={field} errors={errors} />;

      case FORM_FIELD_TYPE.address:
        return (
          <FormAddress
            key={field.fieldKey}
            fields={field.fields}
            errors={errors}
            values={values}
            selectValues={selectValues}
            disabled={disabled}
          />
        );

      case FORM_FIELD_TYPE.bank:
        return (
          <FormBankAccount
            key={field.fieldKey}
            fields={field.fields}
            errors={errors}
            values={values}
            selectValues={selectValues}
          />
        );

      case FORM_FIELD_TYPE.password:
        return (
          <FormRowPasswordInput
            key={field.fieldKey}
            field={field}
            errors={errors}
            values={values}
          />
        );

      case FORM_FIELD_TYPE.selectAutocomplete:
        return (
          <FormRowSelectAutocomplete
            key={field.fieldKey}
            field={field}
            values={values}
            isRequired={isRequired}
            disabled={disabled}
          />
        );
      default:
        return null;
    }
  });

FormField.propTypes = {
  fieldArray: arrayOf(
    PropTypes.shape({
      fieldKey: PropTypes.string,
      displayedName: PropTypes.string,
      placeholder: PropTypes.string,
    }),
  ),
  values: PropTypes.shape({
    id: PropTypes.number,
  }),
  errors: PropTypes.shape({}),
  selectValues: PropTypes.shape({}),
  disabledFieldOptions: PropTypes.shape({}),
  changeFunctions: PropTypes.objectOf(PropTypes.func),
};

FormField.defaultProps = {
  disabledFieldOptions: {},
};
