import React from 'react';

import PropTypes from 'prop-types';
import { keys } from 'ramda';
import clsx from 'clsx';

import ListTable from 'components/ListTable';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';

import useStyles from 'pages/OIDBalanceSuccessorBorrowerDetails/useStyles';

import { appRoutes } from 'router/routes';

const FOOTER_TITLE = 'Total';

export const AccountingTable = props => {
  const { fixedTopOffset } = props;
  const { SBAccountingTableColumnConfig, SBAccountingTableData } = useOIDBalanceReports();
  const classes = useStyles();

  const { tableRows, footerRow } = SBAccountingTableData;
  const footerColumns = keys(SBAccountingTableColumnConfig).slice(1);

  const tableFooter = (
    <tr className={clsx(classes.tableFooter, classes.tableFooterFont, classes.fontColorMain)}>
      <td className={classes.tableBottomCell}>{FOOTER_TITLE}</td>
      {footerColumns.map(key => (
        <td className={classes.tableBottomCell} key={key}>
          {footerRow[key]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className={classes.tableWrap}>
      <ListTable
        detailPath={appRoutes.OIDBalanceReportLoanPath}
        tableHeader={keys(SBAccountingTableColumnConfig)}
        tableData={tableRows}
        columnConfig={SBAccountingTableColumnConfig}
        tableClasses={classes.accountingTable}
        tableFooterContent={tableFooter}
        isOuterScrollable
        fixedTopOffset={fixedTopOffset}
      />
    </div>
  );
};

AccountingTable.propTypes = {
  fixedTopOffset: PropTypes.number,
};
