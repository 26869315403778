import PropTypes from 'prop-types';

import { StatusDisplayedNameByCode } from 'const';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';

export const managersColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  name: {
    displayedName: 'Company Name',
    isLink: true,
  },
  status: {
    displayedName: 'Status',
  },
  form2553FiledDate: {
    displayedName: '2553 Filed',
  },
  form2553AcceptedDate: {
    displayedName: '2553 Accepted',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.number,
    form2553FiledDate: PropTypes.date,
    form2553AcceptedDate: PropTypes.date,
  },
  {
    name(manager) {
      return manager?.name || '';
    },
    tableKeys() {
      return Object.keys(managersColumnConfig);
    },
    tableData(list) {
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          status: StatusDisplayedNameByCode[rowData.status],
          form2553FiledDate: formatDate(rowData.form2553FiledDate),
          form2553AcceptedDate: formatDate(rowData.form2553AcceptedDate),
        },
      }));
    },
  },
);
