import { getSelectOptions } from 'utils/getSelectOptions';

import { IPerson, IPersonIndex } from './types';

export const getPersonOptions = (people: Array<IPerson | IPersonIndex>) =>
  getSelectOptions(
    people,
    'id',
    person => person.fullName || `${person.firstName} ${person.lastName}`,
  );

export const getTableData = (list = []) =>
  list.map(data => ({
    id: data.id,
    data,
  }));

export const columnConfig = {
  id: {
    displayedName: 'PID',
    canBeHidden: false,
  },
  fullName: {
    displayedName: 'Name',
    canBeHidden: false,
    isLink: true,
  },
  email: {
    displayedName: 'Email',
    canBeHidden: false,
  },
  workPhone: {
    displayedName: 'Work Phone',
    canBeHidden: false,
  },
  employerName: {
    displayedName: 'Company',
    canBeHidden: false,
  },
};

export const getPersonDefaultValues = () => ({
  address: '',
  cellPhone: '',
  city: '',
  email: '',
  employerId: null,
  firstName: '',
  lastName: '',
  state: null,
  workPhone: '',
  zip: '',
});
