import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    dateInputWrap: {
      marginLeft: '16px',
      marginRight: '32px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  }),
  { name: 'JournalEntriesPopup' },
);
