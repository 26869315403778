import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { ERROR_MESSAGES } from 'enums';

export const resolver = yupResolver(
  Yup.object({
    firstName: Yup.string().trim().required('First Name is required').nullable(),
    lastName: Yup.string().trim().required('Last Name is required').nullable(),
    email: Yup.string().trim().nullable(),
    workPhone: Yup.string().trim().nullable(),
    cellPhone: Yup.string().trim().nullable(),
    address: Yup.string().trim().nullable(),
    city: Yup.string().trim().nullable(),
    state: Yup.string().trim().nullable(),
    zip: Yup.string().trim().nullable(),
    employerId: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'firstName',
    displayedName: 'First Name',
    type: FORM_FIELD_TYPE.input,
    isRequired: true,
  },
  {
    fieldKey: 'lastName',
    displayedName: 'Last Name',
    type: FORM_FIELD_TYPE.input,
    isRequired: true,
  },
  { fieldKey: 'email', displayedName: 'Email', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'workPhone', displayedName: 'Work Phone', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'cellPhone', displayedName: 'Cell', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'address', displayedName: 'Address', type: FORM_FIELD_TYPE.input, multiline: true },
  { fieldKey: 'city', displayedName: 'City', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'state',
    displayedName: 'State / Region',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'states',
    placeholder: 'Select State',
  },
  { fieldKey: 'zip', displayedName: 'Postal Code', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'employerId',
    displayedName: 'Employer',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'organizations',
    placeholder: 'Select an Employer',
  },
];
