import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import { isStatusActive, isStatusActivePaid, isStatusInactive } from 'utils/layoutStatus';

import useStyles from './useStyles';

export const BackgroundFillByStatus = props => {
  const { children, status, isLoanStatuses, isSuccessorBorrowerStatuses } = props;
  const classes = useStyles();

  const isLoanStatusActive = () => isLoanStatuses && isStatusActive(status);
  const isLoanStatusInactive = () => isLoanStatuses && isStatusInactive(status);
  const isLoanStatusActivePaid = () => isLoanStatuses && isStatusActivePaid(status);
  const isSuccessorBorrowerStatusActive = () =>
    isSuccessorBorrowerStatuses && isStatusActive(status);
  const isSuccessorBorrowerStatusInactive = () =>
    isSuccessorBorrowerStatuses && isStatusInactive(status);

  return (
    <div
      className={clsx(classes.size, {
        [classes.backgroundStatusLoanActive]: isLoanStatusActive(),
        [classes.backgrounStatusLoandActivePaid]: isLoanStatusActivePaid(),
        [classes.backgroundStatusLoanInactive]: isLoanStatusInactive(),
        [classes.backgroundStatusSbActive]: isSuccessorBorrowerStatusActive(),
        [classes.backgroundStatusSbInactive]: isSuccessorBorrowerStatusInactive(),
      })}
    >
      {children}
    </div>
  );
};

BackgroundFillByStatus.propTypes = {
  status: PropTypes.string,
  isLoanStatuses: PropTypes.bool,
  isSuccessorBorrowerStatuses: PropTypes.bool,
};
