import React from 'react';

import PropTypes from 'prop-types';

import useStyles from './useStyles';

const HiddenInput = ({ parentName, inputRef, readOnly }) => {
  const classes = useStyles();

  return (
    <input
      readOnly={readOnly}
      name={`${parentName}-hiddenInput`}
      className={classes.hiddenInput}
      ref={inputRef}
    />
  );
};

HiddenInput.propTypes = {
  parentName: PropTypes.string,
  inputRef: PropTypes.shape(),
  readOnly: PropTypes.bool,
};

export default HiddenInput;
