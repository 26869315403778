import React, { useMemo, useState } from 'react';

import clsx from 'clsx';
import StickyBox from 'react-sticky-box/dist/esnext';
import { NavHashLink } from 'react-router-hash-link';
import {
  Typography,
  Button,
  Paper,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Portal,
} from '@material-ui/core';
import { isEmpty } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';
import SuccessorBorrowerSnapshots from 'components/SuccessorBorrowerSnapshots';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import ListTable from 'components/ListTable';
import NoDataMessage from 'components/NoDataMessage';
import SuccessorBorrowerSideNav from 'components/SuccessorBorrowerSideNav';

import {
  useGetSuccessorBorrowerQuery,
  useGetRelatedEntitiesQuery,
} from 'domain/successorBorrower/apiSlice';
import {
  SuccessorBorrowerService,
  SuccessorBorrowersService,
  SuccessorBorrowerPoolService,
  SnapshotService,
} from 'domain/successorBorrower/service';
import { PoolService } from 'domain/pool/service';

import { SB_HASH } from 'enums';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';
import { useScrollingContext } from 'hooks/useScrolling';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { isStatusActive, isStatusInactive } from 'utils/layoutStatus';

import useStyles from './useStyles';

export const SuccessorBorrower = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();

  const { setHeaderRef, scrollMarginTop } = useScrollingContext();

  const { columnConfig, poolColumnConfig, relatedEntitiesColumnConfig } = SuccessorBorrowerService;

  const [sideBarContainer, setRefSideBarContainer] = useState(null);

  const {
    data: successorBorrowerData,
    isFetching: isSuccessorBorrowerLoading,
    error: successorBorrowerLoadError,
  } = useGetSuccessorBorrowerQuery(id);

  const successorBorrower = useMemo(
    () => SuccessorBorrowerService.getSuccessorBorrower(successorBorrowerData),
    [successorBorrowerData],
  );

  const {
    data: relatedEntitiesData,
    isFetching: areRelatedEntitiesLoading,
    error: relatedEntitiesLoadError,
  } = useGetRelatedEntitiesQuery(id);

  const successorBorrowerTableData = SuccessorBorrowerService.getTableData(successorBorrower);

  const successorBorrowerTableKeys = SuccessorBorrowerService.getTableDataKeys();

  const poolData = useMemo(
    () => PoolService.getTableData(successorBorrowerData?.cmbsPool),
    [successorBorrowerData],
  );

  const poolTableKeys = SuccessorBorrowerPoolService.getPoolTableKeys();

  const snapshots = useMemo(
    () => SnapshotService.getSnapshotsData(successorBorrowerData),
    [successorBorrowerData],
  );

  const relatedEntitiesList = SuccessorBorrowersService.getTableData(relatedEntitiesData);

  useHandleRtkQueryErrors(successorBorrowerLoadError, relatedEntitiesLoadError);

  const titleNavLinkStyle = { scrollMarginTop };

  const pageTitle = useMemo(() => successorBorrower?.headerName, [successorBorrower]);

  const successorBorrowerStatus = successorBorrowerTableData?.status;

  const isDataLoading = isSuccessorBorrowerLoading || areRelatedEntitiesLoading;

  return (
    <>
      <Portal container={sideBarContainer}>
        <SuccessorBorrowerSideNav />
      </Portal>
      <RootLayoutV2 sideBarRef={setRefSideBarContainer} isPortal isLoading={isDataLoading}>
        <DocumentTitle title={pageTitle} />
        <StickyBox offsetTop={0} offsetBottom={10} className="sticky-box" ref={setHeaderRef}>
          <div
            className={clsx(classes.contentPanel, {
              [classes.backgroundActive]: isStatusActive(successorBorrowerStatus),
              [classes.backgroundInactive]: isStatusInactive(successorBorrowerStatus),
            })}
          >
            <Typography className={classes.contentTitle} variant="h1">
              {pageTitle}
            </Typography>
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.successorBorrowerEditPath(id)}
              component={NavHashLink}
            >
              Edit Successor Borrower
            </Button>
          </div>
        </StickyBox>
        <BackgroundFillByStatus status={successorBorrowerStatus} isSuccessorBorrowerStatuses>
          <ContentLayout>
            <h3 id={SB_HASH.details} className={classes.detailTitle} style={titleNavLinkStyle}>
              Information
            </h3>
            <div className={classes.detailWrap}>
              <div className={classes.content}>
                <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                  <Table>
                    <TableBody>
                      {successorBorrowerTableKeys.map(key => (
                        <TableRow key={`${id}_${key}`}>
                          <TableCell className={classes.fieldNameTableCell}>
                            {columnConfig[key].displayedName}
                          </TableCell>
                          <TableCell>{successorBorrowerTableData?.[key]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <h3 id={SB_HASH.pool} className={classes.detailTitle} style={titleNavLinkStyle}>
                  CMBS Pool
                </h3>
                <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                  <Table>
                    <TableBody>
                      {poolTableKeys.map(key => (
                        <TableRow key={`${id}_${key}`}>
                          <TableCell className={classes.fieldNameTableCell}>
                            {poolColumnConfig[key]?.displayedName}
                          </TableCell>
                          <TableCell>{poolData?.[key]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <StickyBox offsetTop={138} offsetBottom={10}>
                <div className={classes.statusWindow}>
                  <h3 className={clsx(classes.detailTitle, classes.entites)}>Related Entities</h3>
                  {isEmpty(relatedEntitiesList) ? (
                    <NoDataMessage />
                  ) : (
                    <ListTable
                      detailPath={appRoutes.successorBorrowerPath}
                      tableHeader={Object.keys(relatedEntitiesColumnConfig)}
                      columnConfig={relatedEntitiesColumnConfig}
                      tableData={relatedEntitiesList}
                      isMiniView
                    />
                  )}
                  <h3
                    id={SB_HASH.snapshots}
                    className={classes.detailTitle}
                    style={titleNavLinkStyle}
                  >
                    Snapshots
                  </h3>
                  <div className={classes.tableContainer}>
                    <SuccessorBorrowerSnapshots snapshots={snapshots} poolData={poolData} />
                  </div>
                </div>
              </StickyBox>
            </div>
          </ContentLayout>
        </BackgroundFillByStatus>
      </RootLayoutV2>
    </>
  );
};
