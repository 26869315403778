import React, { forwardRef } from 'react';

import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from 'assets/check.svg';
import { ErrorOutline } from '@material-ui/icons';

import useStyles from './useStyles';

const SnackbarToast = forwardRef((props, ref) => {
  const { message, isError, id, onClose } = props;
  const classes = useStyles();

  return (
    <Paper onClick={onClose} ref={ref} elevation={3} className={classes.container} id={id}>
      <div className={clsx(classes.iconWrap, { [classes.errorWrap]: isError })}>
        {isError ? <ErrorOutline /> : <CheckIcon />}
      </div>
      <span className={classes.text}>{message}</span>
    </Paper>
  );
});

SnackbarToast.propTypes = {
  message: PropTypes.string,
  isError: PropTypes.bool,
  id: PropTypes.number,
  onClose: PropTypes.func,
};

export default SnackbarToast;
