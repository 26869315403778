import React, { useEffect } from 'react';

import OrganizationForm from 'components/OrganizationForm';

import {
  useGetOrganizationQuery,
  useUpdateOrganizationMutation,
} from 'domain/organization/apiSlice';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import { getEntityLastUpdated } from 'utils/date';

const OrganizationEdit = () => {
  const { displayErrorsInToast } = useErrors();
  const {
    query: { id },
    push,
  } = useRouter();

  const {
    data: organization,
    error: organizationLoadError,
    isLoading: isOrganizationLoading,
  } = useGetOrganizationQuery(id);

  const [
    updateOrganization,
    {
      error: organizationUpdateError,
      isSuccess: isOrganizationUpdateSuccess,
      isLoading: isOrganizationUpdateLoading,
    },
  ] = useUpdateOrganizationMutation();

  useEffect(() => {
    if (organizationLoadError) displayErrorsInToast([organizationLoadError]);
  }, [organizationLoadError]);

  const previousRoute = appRoutes.partiesOrganizationPath(id);

  const handleSubmitClick = values => updateOrganization({ id, organization: values });

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isOrganizationUpdateSuccess) push(previousRoute);
  }, [isOrganizationUpdateSuccess]);

  const lastUpdated = getEntityLastUpdated(organization);

  const isDefaultDataLoading = isOrganizationUpdateLoading || isOrganizationLoading;

  return (
    <ContentLayout>
      <OrganizationForm
        isEdit
        formValues={organization}
        pageTitle="Organization Details"
        submitButtonText="Update"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        isDefaultDataLoading={isDefaultDataLoading}
        requestError={organizationUpdateError}
        previousRoute={previousRoute}
        lastUpdated={lastUpdated}
      />
    </ContentLayout>
  );
};

export default OrganizationEdit;
