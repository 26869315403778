import { createSlice } from '@reduxjs/toolkit';
import FreddieFloatPaymentRepository from 'repositories/FreddieFloatPaymentsRepository';

import { createRestSlice } from 'utils/RestSlice';

const freddieFloatPaymentsRestSlice = createRestSlice({
  resource: 'freddieFloatPayment',
  repository: FreddieFloatPaymentRepository,
  slices: ['loadFreddieFloatPayments'],
});

const initialState = {
  ...freddieFloatPaymentsRestSlice.initialState,
};

const freddieFloatPaymentsSlice = createSlice({
  name: 'freddieFloatPayments',
  initialState,
  reducers: {
    ...freddieFloatPaymentsRestSlice.reducers,
  },
});

export default freddieFloatPaymentsSlice.reducer;
export const freddieFloatPaymentsRestHooks = freddieFloatPaymentsRestSlice.hooks(
  freddieFloatPaymentsSlice,
  'FreddieFloatPaymentsSlice',
);
