import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { FORM_FIELD_TYPE } from 'enums';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { formatCurrency, toNumber } from 'utils/numbers';

export const buyUpPendingColumnConfig = {
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  filingRef: {
    displayedName: 'Ref',
  },
  dealName: {
    displayedName: 'Deal Name',
  },
  assumedDate: {
    displayedName: 'Assumed Date',
  },
  sideLetterBorrowerPrincipal: {
    displayedName: 'Borrower Principal',
  },
  buyUpPaymentDate: {
    displayedName: 'Paid Date',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.date,
    fieldKey: 'buyUpPaymentDate',
  },
  buyUpEstimate: {
    displayedName: 'Buy Up Estimate',
    align: 'right',
  },
  buyUpAmount: {
    displayedName: 'Buy Up Amount',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.decimal,
    fieldKey: 'buyUpAmount',
  },
  buyUpDifference: {
    displayedName: 'Buy Up Difference',
    align: 'right',
  },
};

const getDifference = item =>
  Number(item.buyUpAmount) > 0 ? Number(item.buyUpEstimate) - Number(item.buyUpAmount) : 0;

const getEstimateAvailability = item => !isNil(item.buyUpEstimate) && item.buyUpEstimate > 0;

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    dealName: PropTypes.string,
    assumedDate: PropTypes.date,
    sideLetterBorrowerPrincipal: PropTypes.string,
    buyUpPaymentDate: PropTypes.string,
    buyUpEstimate: PropTypes.string,
    buyUpAmount: PropTypes.string,
    buyUpDifference: PropTypes.string,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rowData => {
        const hasEstimate = getEstimateAvailability(rowData);
        const difference = getDifference(rowData);
        return {
          id: rowData.id,
          data: {
            ...rowData,
            buyUpAmount: toNumber(rowData.buyUpAmount),
            assumedDate: formatDate(rowData.assumedDate),
            buyUpEstimate: formatCurrency(rowData.buyUpEstimate),
            buyUpDifference: hasEstimate ? formatCurrency(difference) : 'N/A',
          },
        };
      });
    },
    totalAmount(list) {
      if (!list) return null;
      return formatCurrency(String(list.reduce((acc, item) => acc + Number(item.buyUpAmount), 0)));
    },
    totalDifference(list) {
      if (!list) return null;
      const totalDifference = list.reduce((acc, item) => {
        const hasEstimate = getEstimateAvailability(item);
        return hasEstimate ? acc + getDifference(item) : acc;
      }, 0);
      return totalDifference !== 0 ? formatCurrency(String(totalDifference)) : 'N/A';
    },
    tableKeys() {
      return Object.keys(buyUpPendingColumnConfig);
    },
  },
);
