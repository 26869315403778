import React, { Fragment, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import StickyBox from 'react-sticky-box/dist/esnext';
import { Button, Typography } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { any, has, keys, forEach, isNil, isEmpty, not } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';
import LeavingPageAlertPopup from 'components/LeavingPageAlertPopup';
import FormField from 'components/FormField';
import SelectForPrepopulate from 'components/SelectForPrepopulate';

import { LOANS_STATUSES } from 'const';

import * as OptionsService from 'domain/options/service';
import { useGetOptionsQuery } from 'domain/options/apiSlice';
import { useGetUsersQuery } from 'domain/user/apiSlice';
import * as UserService from 'domain/user/service';
import { useGetOrganizationsQuery } from 'domain/organization/apiSlice';
import * as OrganizationService from 'domain/organization/service';
import { useGetPersonChoicesQuery } from 'domain/personChoice/apiSlice';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';
import { useScrollingContext } from 'hooks/useScrolling';

import ContentLayout from 'layouts/ContentLayout';

import { prepareMoodysDataForPaste } from 'presenters/MoodysLoanLookupPresenter';

import { getEntityLastUpdated } from 'utils/date';
import { getOptionsQueryParams } from 'utils/rtkQuery';
import { setServerErrorsToFieldsRecursive } from 'utils/errors';
import { IdSerializer } from 'utils/serializer';
import { getLoanStatusOptions } from 'utils/loanStatusHelpers';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getOrganizationTypes, getOrganizationTypeValues } from 'utils/getOrganizationsType';

import { loanFields, loanSections, resolver } from './validation';
import useStyles from './useStyles';

const CANCEL = 'Cancel';

const LoanForm = props => {
  const {
    defaultValuesLoadError,
    formValues,
    isDefaultDataLoading,
    isEdit,
    isLoanSubmitLoading,
    onCancel,
    onSubmit,
    previousRoute,
    submitButtonText,
    submitQueryError,
    title,
  } = props;

  const {
    query: { id },
  } = useRouter();
  const classes = useStyles();

  const { setHeaderRef, scrollMarginTop } = useScrollingContext();

  const methods = useForm({
    resolver,
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
  });
  const { setError, errors, setValue } = methods;

  const isFormDirty = methods.formState.isDirty;

  const [isLeavingPageAlertDisabled, setLeavingPageAlertDisabled] = useState(false);
  const [isDisabledBuyUpEstimate, setDisabledBuyUpEstimate] = useState(false);

  const { data: usersData, error: usersLoadError, isFetching: isUsersLoading } = useGetUsersQuery();
  const usersOptions = useMemo(() => UserService.getUsersOptions(usersData), [usersData]);
  const { data: optionsData, isFetching: isOptionsLoading } = useGetOptionsQuery(
    null,
    getOptionsQueryParams(),
  );

  const {
    data: organizationsData,
    error: organizationsLoadError,
    isFetching: isOrganizationsLoading,
  } = useGetOrganizationsQuery();
  const organizationOptions = useMemo(
    () => getSelectOptions(organizationsData),
    [organizationsData],
  );
  const financialServices = useMemo(
    () =>
      OrganizationService.filterOrganizationsOptions(
        getOrganizationTypes(optionsData).financialServices,
        organizationsData,
      ),
    [organizationsData],
  );
  const custodianContact = useMemo(
    () =>
      OrganizationService.filterOrganizationsOptions(
        getOrganizationTypes(optionsData).custodianContact,
        organizationsData,
      ),
    [organizationsData],
  );

  const {
    stateSelectOptions,
    loanTypesOptions,
    parRepayTypesOptions,
    loanInterestMethodTypes,
    permittedInvestmentTypes,
    sideLetterNatureTypes,
    monetContractPercentages,
    partyToDesignateSBTypes,
    loanThirdPartyFeesStatuses,
    securitiesUserTypes,
  } = useMemo(() => OptionsService.getLoanFormOptionsTypes(optionsData), [optionsData]);

  const {
    data: personChoices,
    error: personChoicesLoadError,
    isFetching: isPersonChoicesLoading,
  } = useGetPersonChoicesQuery({
    employerType: getOrganizationTypeValues(optionsData).servicerCounsel,
  });

  useHandleRtkQueryErrors(
    usersLoadError,
    organizationsLoadError,
    personChoicesLoadError,
    defaultValuesLoadError,
  );

  const isBuyUpEstimate = status =>
    [LOANS_STATUSES.activeInPayment, LOANS_STATUSES.activePaid].includes(status);

  useEffect(() => {
    if (submitQueryError) {
      setServerErrorsToFieldsRecursive(setError, errors, submitQueryError);
    }
  }, [submitQueryError]);

  useEffect(() => {
    if (formValues) {
      setDisabledBuyUpEstimate(isBuyUpEstimate(formValues.status));
    }
  }, [formValues]);

  const selectValues = {
    servicerCounselPersons: personChoices,
    states: stateSelectOptions,
    loanStatuses: getLoanStatusOptions(),
    loanTypes: loanTypesOptions,
    parRepayTypes: parRepayTypesOptions,
    organizations: organizationOptions,
    financialServices,
    custodianContact,
    interestMethods: loanInterestMethodTypes,
    permittedInvestments: permittedInvestmentTypes,
    partySbDesignations: partyToDesignateSBTypes,
    loanThirdPartyFeesStatuses,
    securitiesUserTypes,
    sideLetterNatureTypes,
    users: usersOptions,
    monetContractPercentages,
  };

  const handleSubmit = async values => {
    setLeavingPageAlertDisabled(true);
    const mappingDict = [
      'truistNote',
      'dealManager',
      'successorBorrower',
      'loanType',
      'securitiesBrokerDealer',
      'blacklineDeal',
      'loanServicer',
      'servicerCounsel1',
      'servicerCounsel2',
      'custodian',
      'sharingArrangementFinancialServicesOrg',
      'defaultPermittedInvestment',
      'accountant',
      'defeasanceConsultant',
      'partyToDesignateSb',
      'originalLoanReviewer',
      'monetizationParty',
      'monetizationContract',
      'hedgeParty',
      'hedgeParty2',
    ];
    const serializedValues = IdSerializer(values, mappingDict);
    await onSubmit(id ? { loan: serializedValues, id } : serializedValues);
  };

  const handleCancelClick = () => onCancel();

  const isRequestsLoading = [
    isDefaultDataLoading,
    isOrganizationsLoading,
    isOptionsLoading,
    isUsersLoading,
    isPersonChoicesLoading,
  ];

  const options = [organizationOptions, usersOptions, personChoices];

  const handleStatusChange = data => {
    if (isNil(data)) return;
    setDisabledBuyUpEstimate(isBuyUpEstimate(data.value));
  };

  const handleMoodysSelectSubmit = value => {
    if (value) {
      const { rawData } = value;
      const preparedData = prepareMoodysDataForPaste(rawData);
      forEach(key => setValue(key, preparedData[key] ?? '', { shouldDirty: true }), keys(rawData));
    }
  };

  const disabledFieldOptions = useMemo(
    () => ({
      successorBorrower: formValues ? formValues.hasEscrowAccounts : false,
      buyUpEstimate: isDisabledBuyUpEstimate,
    }),
    [formValues, isDisabledBuyUpEstimate],
  );

  const additionalChangeFunctions = {
    status: handleStatusChange,
  };

  if (any(loaderRequest => loaderRequest)(isRequestsLoading)) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  const lastUpdated = getEntityLastUpdated(formValues);

  const titleNavLinkStyle = { scrollMarginTop };

  if (any(option => isEmpty(option))(options)) return null;

  return (
    <FormProvider {...methods}>
      <DocumentTitle title={title} isEdit={isEdit} />
      <StickyBox offsetTop={0} offsetBottom={10} className="sticky-box" ref={setHeaderRef}>
        <div className={classes.contentPanel}>
          <NavLink className={classes.backButton} exact to={previousRoute}>
            Back
          </NavLink>
          <div className={classes.panelWrap}>
            <div className={classes.tableLayout}>
              <Typography className={classes.contentTitle} variant="h1">
                {title}
              </Typography>
              {!isEdit && <SelectForPrepopulate onSubmit={handleMoodysSelectSubmit} />}
            </div>
            <div className={classes.actionPanel}>
              <Button
                onClick={handleCancelClick}
                className={classes.addItem}
                variant="contained"
                disabled={isLoanSubmitLoading}
              >
                {CANCEL}
              </Button>
              <Button
                disabled={!isFormDirty || isLoanSubmitLoading}
                onClick={methods.handleSubmit(handleSubmit)}
                className={classes.updateItem}
                variant="contained"
                color="secondary"
              >
                {submitButtonText}
              </Button>
            </div>
          </div>
        </div>
      </StickyBox>
      <ContentLayout>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            {loanSections.map(sectionName => {
              const section = loanFields[sectionName];
              const hasSubgroups = has('groups', section);
              if (hasSubgroups) {
                const { groups } = section;
                return (
                  <Fragment key={section.hash}>
                    <h3
                      id={section.hash}
                      className={classes.contentFormTitle}
                      style={titleNavLinkStyle}
                    >
                      {section.title}
                    </h3>
                    {groups.map(group => {
                      const groupTitle = group.displayedName;
                      return (
                        <Fragment key={groupTitle}>
                          <h3 className={classes.contentFormSubtitle}>{groupTitle}</h3>
                          <FormField
                            fieldArray={group.fields}
                            errors={errors}
                            values={formValues}
                            selectValues={selectValues}
                            disabledFieldOptions={disabledFieldOptions}
                            changeFunctions={additionalChangeFunctions}
                          />
                        </Fragment>
                      );
                    })}
                  </Fragment>
                );
              }
              return (
                <Fragment key={section.hash}>
                  <h3
                    id={section.hash}
                    className={classes.contentFormTitle}
                    style={titleNavLinkStyle}
                  >
                    {section.title}
                  </h3>
                  <FormField
                    fieldArray={section.fields}
                    errors={errors}
                    values={formValues}
                    selectValues={selectValues}
                    disabledFieldOptions={disabledFieldOptions}
                    changeFunctions={additionalChangeFunctions}
                  />
                </Fragment>
              );
            })}
          </form>
          <StickyBox offsetTop={138} offsetBottom={10} className="sticky-box">
            <div className={classes.contentSidebar}>
              {isEdit && (
                <div className={classes.lastUpdated}>
                  <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                  <div>{lastUpdated}</div>
                </div>
              )}
            </div>
          </StickyBox>
        </div>
      </ContentLayout>
      <LeavingPageAlertPopup when={isFormDirty && not(isLeavingPageAlertDisabled)} />
    </FormProvider>
  );
};

LoanForm.propTypes = {
  isEdit: PropTypes.bool,
  loanId: PropTypes.number,
  formValues: PropTypes.shape({
    hasEscrowAccounts: PropTypes.bool,
    status: PropTypes.string,
  }),
  submitButtonText: PropTypes.string,
  isDefaultDataLoading: PropTypes.bool,
  isLoanSubmitLoading: PropTypes.bool,
  submitQueryError: PropTypes.shape({}),
  defaultValuesLoadError: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  previousRoute: PropTypes.string,
  title: PropTypes.string,
};

export default LoanForm;
