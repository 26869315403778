import React from 'react';

import { Paper } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import UsersRepository from 'repositories/UsersRepository';

import useLoading from 'hooks/useLoading';
import { useErrors } from 'hooks/useErrors';

import useStyles from './useStyles';
import { resolver, defaultValues } from './validation';
import { EmailInputForm, ConfirmationMessage } from './components';

export const PasswordRecovery = () => {
  const classes = useStyles();
  const { displayErrorsInToast, formatErrors, setErrorsToForm } = useErrors();
  const { func: sendResetPasswordLinkToEmail, isFulfilled: isSendResetPasswordLinkLoadFulfilled } =
    useLoading(UsersRepository.resetPassword);

  const methods = useForm({
    resolver,
    defaultValues,
    shouldFocusError: true,
  });

  const onSubmitClick = async data => {
    try {
      await sendResetPasswordLinkToEmail(data);
    } catch (e) {
      const { backendServicesError, fieldErrors } = formatErrors(e);
      displayErrorsInToast([backendServicesError]);
      setErrorsToForm(methods.setError, null, fieldErrors);
    }
  };

  return (
    <Paper className={classes.container}>
      {isSendResetPasswordLinkLoadFulfilled ? (
        <ConfirmationMessage />
      ) : (
        <FormProvider {...methods}>
          <EmailInputForm onSubmit={onSubmitClick} />
        </FormProvider>
      )}
    </Paper>
  );
};
