import { has, not, isEmpty, is, isNil, keys, head } from 'ramda';

import { useSnackbar } from 'hooks/useSnackbar';

import { formatErrors } from 'presenters/ErrorsPresenter';

import { setServerErrorsToFields, getFieldErrorMessage } from 'utils/errors';

export const useErrors = () => {
  const { showErrorNotification } = useSnackbar();

  const displayErrorsInToast = (errors = []) => {
    if (isEmpty(errors) || not(is(Array, errors))) {
      showErrorNotification();
      return;
    }
    errors.forEach(error => {
      if (isNil(error) || error.isPageNotFoundError) return;
      if (has('message', error)) {
        showErrorNotification(error.message);
      }
    });
  };

  const displayFieldErrorsInToast = (fieldErrors = {}) => {
    const fieldNames = keys(fieldErrors);
    fieldNames.forEach(name =>
      displayErrorsInToast([{ message: `${name}: ${head(fieldErrors[name])}` }]),
    );
  };

  return {
    formatErrors,
    displayErrorsInToast,
    setErrorsToForm: setServerErrorsToFields,
    getFieldErrorMessage,
    displayFieldErrorsInToast,
  };
};
