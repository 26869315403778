const NAV_TYPES = {
  successorBorrowers: 'successorBorrowers',
  successorBorrowerDetail: 'successorBorrowerDetail',
  successorBorrowerCRUD: 'successorBorrowerCRUD',
  poolsCRUD: 'poolsCRUD',
  loans: 'loans',
  loanDetails: 'loanDetails',
  loanCreate: 'loanCreate',
  loanEdit: 'loanEdit',
  deals: 'deals',
  parties: 'parties',
  personCRUD: 'personCRUD',
  liveForms: 'liveForms',
  organizationCRUD: 'organizationCRUD',
  organizationDetail: 'organizationDetail',
  staff: 'staff',
  reminders: 'reminders',
  homepage: 'homepage',
  memberCRUD: 'memberCRUD',
  administrativeAgentCRUD: 'administrativeAgentCRUD',
  managerCRUD: 'managerCRUD',
  accountSettings: 'accountSettings',
  OIDBalanceReports: 'OIDBalanceReports',
};

Object.freeze(NAV_TYPES);

export { NAV_TYPES };
