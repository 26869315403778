import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    errorSectionTitle: {
      marginTop: '16px',
      marginBottom: '16px',
      textTransform: 'uppercase',
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: 'rgba(19, 51, 76, 0.4)',
    },
    listContainer: {
      color: theme.palette.elephant,
    },
    list: {
      marginBottom: '32px',
      paddingBottom: 0,
      paddingTop: 0,
      '& li:last-child': {
        border: 'none',
      },
    },
    listItem: {
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    listItemFont: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
    },
    textTrunc: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100ch',
    },
  }),
  { name: 'ErrorSection' },
);
