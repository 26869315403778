import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    overlay: {
      position: 'fixed',
      left: 0,
      top: 0,
      width: '100%',
      height: '100vh',
      background: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 100,
    },
    wrapper: {
      minWidth: 384,
      maxWidth: 728,
      backgroundColor: theme.palette.linkWater,
      borderRadius: 4,
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'space-between',
    },
    header: {
      alignSelf: 'flex-start',
      boxShadow: 'inset 0px -1px 0px rgba(19, 51, 76, 0.1)',
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: [12, 16],
      marginBottom: 32,
    },
    actionPanel: {
      padding: 32,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    title: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(32),
      color: theme.palette.primary.main,
    },
    closeButton: {
      width: 16,
      minWidth: 28,
    },
    closeIcon: {
      width: 16,
      height: 16,
      color: 'rgba(19, 51, 76, 0.4)',
    },
    cancelButton: {
      marginRight: 16,
      color: theme.palette.primary.main,
    },
    submitButton: {
      textTransform: 'none',
    },
    danger: {
      backgroundColor: theme.palette.coralRed,

      '&:hover': {
        backgroundColor: theme.palette.mandy,
      },
    },
  }),
  { name: 'Popup' },
);
