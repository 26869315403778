import PropTypes from 'prop-types';
import { keys } from 'ramda';

import { FORM_FIELD_TYPE } from 'enums';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { getLoanStatusLabel } from 'utils/loanStatusHelpers';

export const OutstandingAccountantColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  dealName: {
    displayedName: 'Property Name',
  },
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  successorBorrowerId: {
    displayedName: 'SB',
  },
  status: {
    displayedName: 'Status',
  },
  isAccountReportUploaded: {
    displayedName: 'Accountant Reports Uploaded',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.select,
    fieldKey: 'isAccountReportUploaded',
    selectValuesType: 'booleanTypes',
  },
  assumedDate: {
    displayedName: 'Accepted Date',
  },
  masterServicer: {
    displayedName: 'Master Servicer',
  },
  loanServicer: {
    displayedName: 'Loan Servicer',
  },
  servicerCounsel: {
    displayedName: 'Servicer Counsel Name',
  },
  accountant: {
    displayedName: 'Accountant Name',
  },
  accountantReportTitle: {
    displayedName: 'Accountant Report Title',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    dealName: PropTypes.string,
    servicerCounsel: PropTypes.string,
    accountantName: PropTypes.string,
    isAccountReportUploaded: PropTypes.bool,
    accountantReportTitle: PropTypes.string,
    status: PropTypes.string,
    assumedDate: PropTypes.date,
    masterServicer: PropTypes.string,
    accountant: PropTypes.string,
    successorBorrowerId: PropTypes.string,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rawData => ({
        id: rawData.id,
        data: {
          ...rawData,
          status: getLoanStatusLabel(rawData.status),
          assumedDate: formatDate(rawData.assumedDate),
        },
      }));
    },
  },
  {
    tableHeader(columnConfig = {}) {
      return keys(columnConfig);
    },
  },
);
