export default {
  props: {
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      disableShrink: true,
    },
    MuiTextField: {
      margin: 'normal',
    },
    MuiLink: {
      underline: 'always',
      color: 'secondary',
    },
  },
};
