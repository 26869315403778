import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

export const loansCountsByStatusApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoansCountsByStatus: builder.query({
      query: params => ({
        url: apiRoutes.loansCountsByStatusPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.loansCount],
    }),
    getLoansCountsByStatusPost: builder.query({
      query: data => ({
        url: apiRoutes.loansCountsByStatusQueryPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.loansCount],
    }),
  }),
});

export const { useGetLoansCountsByStatusQuery, useGetLoansCountsByStatusPostQuery } =
  loansCountsByStatusApi;
