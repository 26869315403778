import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    snapshots: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      marginBottom: 32,
      minHeight: 256,
    },
    snapshotsWrapper: {
      padding: [32, 24],
      background: theme.palette.white,
      border: [1, 'solid', theme.palette.athensGray],
      borderRadius: 2,
      marginRight: 24,
      minWidth: 380,
    },
    snapshotsList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      marginBottom: 24,
      paddingBottom: 24,
      position: 'relative',
      '&:after': {
        display: 'block',
        content: "''",
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        borderTop: [1, 'solid', '#DFE2EF'],
      },
      '& > li': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexFlow: 'row nowrap',
        paddingLeft: 10,
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(16),
        opacity: 0.8,
        color: theme.palette.primary.main,
        marginBottom: 10,
        '&:first-child': {
          paddingLeft: 0,
          fontWeight: 'bold',
          '& $valueLabeled': {
            '&:before': {
              display: 'block',
            },
          },
        },
        '&:last-child': {
          marginBottom: 0,
        },
      },
      '&:last-child': {
        marginBottom: 0,
        paddingBottom: 0,
        '&:after': {
          display: 'none',
        },
      },
    },
    value: {
      marginLeft: 48,
      minWidth: 30,
      textAlign: 'right',
      display: 'inline-block',
      '&:first-child': {
        marginLeft: 0,
      },
    },
    valueLabeled: {
      position: 'relative',
      '&:before': {
        display: 'none',
        position: 'absolute',
        top: -15,
        right: 0,
        content: "'Current'",
        fontSize: theme.typography.pxToRem(11),
        lineHeight: theme.typography.pxToRem(12),
        opacity: 0.4,
        color: theme.palette.primary.main,
        fontWeight: 'normal',
      },
      '&:nth-child(1)': {
        '&:before': {
          content: "'Current'",
        },
      },
      '&:nth-child(2)': {
        '&:before': {
          content: "'YoY'",
        },
      },
    },
    diff: {
      minWidth: 25,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      display: 'inline-block',
      textAlign: 'right',
    },
    diffPositive: {
      color: '#38C172',
    },
    diffNegative: {
      color: '#EF476F',
    },
  }),
  { name: 'YTDSnapshot' },
);
