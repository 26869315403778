import React from 'react';

import logoSrc from 'assets/logo.svg';
import qaBadge from 'assets/qaBadge.svg';
import logoQa from 'assets/logoQa.svg';

import { isQaEnvironment } from 'utils/environment';

import useStyles from './useStyles';

export const AuthLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.logo}>
        {isQaEnvironment() ? (
          <>
            <img className={classes.logoQa} src={logoQa} alt="Successor Borrower QA" />
            <img className={classes.qaBadge} src={qaBadge} alt="QA" />
          </>
        ) : (
          <img src={logoSrc} alt="Successor Borrower" />
        )}
      </div>
      <div className={classes.wrap}>{children}</div>
    </div>
  );
};
