import React, { useState, useEffect, useCallback, useMemo } from 'react';

import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Button, Checkbox, CircularProgress, debounce, TextField } from '@material-ui/core';
import union from 'assets/union.svg';
import { useFormContext, useController } from 'react-hook-form';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import LoansRepository from 'repositories/LoansRepository';
import { isEmpty, isNil } from 'ramda';

import { TimersConst } from 'const';

import useLoading from 'hooks/useLoading';
import { useErrors } from 'hooks/useErrors';
import { useLoansFilters } from 'hooks/useLoansFilters';

import useStyles from 'pages/Loans/useStyles';

import LoanFilterPresenter from 'presenters/LoanFilterPresenter';

import { getDefaultOption } from 'utils/loansFilters';
import { mergeOptions } from 'utils/select';
import { getNextPage } from 'utils/pagination';
import { getSentryOption, injectSentry, removeSentry } from 'utils/infiniteScrollUtils';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const DEFAULT_NEXT_PAGE = 2;

const getOptionLabel = option => option?.label || '';

const getOptionSelected = (option, chosenOption) => option.value === chosenOption.value;

const getNext = response => response?.data?.next;
const getResults = response => response?.data?.results;

const LoanFilterSelectAsync = props => {
  const { title, name, multiple = false } = props;

  const classes = useStyles();
  const { displayErrorsInToast, formatErrors } = useErrors();

  const [isFirstFulfilled, setIsFirstFulfilled] = useState(true);

  const [options, setOptions] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const clearInputValue = () => setInputValue('');

  const [nextPage, setNextPage] = useState(DEFAULT_NEXT_PAGE);
  const resetNextPage = () => setNextPage(DEFAULT_NEXT_PAGE);
  const hasMoreOptions = useMemo(() => !isNil(nextPage), [nextPage]);

  const {
    loansFilterParams: { originalLender: defaultValue },
  } = useLoansFilters();

  const {
    func: loadOriginalLenderOptions,
    isPending: isOptionsLoading,
    error: isOptionsLoadError,
  } = useLoading(LoansRepository.originalLenders);

  const { control, setValue } = useFormContext();

  const {
    field: { onChange, value: selectValue, ...autocompleteProps },
  } = useController({
    name,
    control,
  });

  const fetchOptions = async (params = null, defaultOptions, shouldPreserve = false) => {
    try {
      const response = await loadOriginalLenderOptions(params);
      setNextPage(getNextPage(getNext(response)));
      const preparedOptions = LoanFilterPresenter.originalLendersFilter(getResults(response));
      const withDefault = mergeOptions(defaultOptions, preparedOptions);
      const withSentry = injectSentry(withDefault, getSentryOption());
      setOptions(shouldPreserve ? mergeOptions(removeSentry(options), withSentry) : withSentry);
    } catch (e) {
      const { backendServicesError, nonFieldErrors } = formatErrors(e);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  };

  const loadNextOptions = async () =>
    fetchOptions(
      {
        originalLender: isEmpty(inputValue) ? undefined : inputValue,
        page: nextPage,
      },
      [],
      true,
    );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isOptionsLoading,
    hasNextPage: hasMoreOptions,
    onLoadMore: loadNextOptions,
    disabled: !!isOptionsLoadError,
  });

  const debounceOnInputValueChange = useCallback(
    debounce((param, defaultOptions) => {
      resetNextPage();
      fetchOptions({ originalLender: param, page: 1 }, defaultOptions);
    }, TimersConst.SEARCH_FIELD_DEBOUNCE_TIME),
    [],
  );

  useEffect(() => {
    const defaultValueOptions =
      LoanFilterPresenter.createOriginalLenderDefaultOptions(defaultValue);
    fetchOptions(null, defaultValueOptions);
  }, [defaultValue]);

  useEffect(() => {
    if (!isFirstFulfilled) {
      debounceOnInputValueChange(inputValue, selectValue);
    }
  }, [inputValue]);

  useEffect(() => {
    if (isEmpty(options)) return;
    if (isFirstFulfilled) {
      setValue(name, getDefaultOption(options, defaultValue, multiple));
      setIsFirstFulfilled(false);
    }
  }, [options, isFirstFulfilled]);

  useEffect(() => {
    if (selectValue !== defaultValue && !isFirstFulfilled) {
      setIsFirstFulfilled(true);
    }
  }, [defaultValue]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = e => setInputValue(e.target.value);

  const getTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => {
      const tagProps = { ...getTagProps({ index }) };
      return (
        <div
          className={clsx(classes.filterTag, tagProps.className)}
          key={`key-tag-${option.label}`}
        >
          <span>{option.label}</span>
          <Button className={classes.filterTagButton} onClick={tagProps?.onDelete}>
            <img height="12px" alt="union" width="12px" src={union} />
          </Button>
        </div>
      );
    });

  const getInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        className: clsx(
          params.InputProps.className,
          classes.filterInput,
          classes.filterInputMultipleTags,
        ),
        endAdornment: (
          <>
            {isOptionsLoading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      onChange={handleInputChange}
      autoComplete="off"
      size="small"
      variant="standard"
    />
  );

  const renderOptionComponent = (option, isCheckbox, isChecked) => {
    if (isCheckbox) {
      return (
        <>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            size="small"
            className={classes.filterCheckbox}
            checked={isChecked}
          />
          {option.label}
        </>
      );
    }
    return null;
  };

  const renderSentryComponent = useCallback(() => <div ref={sentryRef} />, [sentryRef, nextPage]);

  const getOption = (option, { selected }) => {
    if (!option.isSentry) {
      return (
        <div className={classes.filterOption}>
          {renderOptionComponent(option, multiple, selected)}
        </div>
      );
    }
    if (option.isSentry && hasMoreOptions) {
      return <div className={classes.filterOption}>{renderSentryComponent()}</div>;
    }
    return null;
  };

  return (
    <div className={clsx(classes.filterContainer, { [classes.filterContainerMultiple]: multiple })}>
      <h3 className={classes.optionTitle}>{title}</h3>
      <Autocomplete
        {...autocompleteProps}
        className={classes.filterAutocomplete}
        clearOnBlur={false}
        disableCloseOnSelect={multiple}
        filterOptions={choices => choices}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        inputValue={inputValue}
        limitTags={1}
        ListboxProps={{ ref: rootRef }}
        loading={isOptionsLoading}
        loadingText="Loading..."
        multiple={multiple}
        onClose={handleClose}
        onOpen={handleOpen}
        openOnFocus
        open={isOpen}
        options={options}
        renderInput={getInput}
        renderOption={getOption}
        renderTags={getTags}
        size="small"
        value={selectValue}
        onChange={(_, data, reason) => {
          if (reason === 'clear') {
            clearInputValue();
          }
          onChange(data);
        }}
      />
    </div>
  );
};
export default LoanFilterSelectAsync;

LoanFilterSelectAsync.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  multiple: PropTypes.bool,
};
