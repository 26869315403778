import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    rowLink: {
      color: theme.palette.secondary.main,
    },
  }),
  { name: 'CellContent' },
);
