import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AuthRepository from 'repositories/AuthRepository';

export const defaultAuth = {
  access: null,
  refresh: null,
};

export const getAuthFromLocalStorage = () => {
  const storage = JSON.parse(localStorage.getItem('dhc'));
  return storage ? storage?.auth?.data : defaultAuth;
};

const initialState = {
  auth: getAuthFromLocalStorage(),
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthData(state, { payload }) {
      state.auth = payload.data;
    },
    resetAuthData(state) {
      state.auth = {};
    },
  },
});

export const { actions } = authSlice;
export default authSlice.reducer;

export const useAuthActions = () => {
  const dispatch = useDispatch();

  const signIn = async attrs => {
    try {
      const { data } = await AuthRepository.login(attrs);
      dispatch(authSlice.actions.setAuthData(data));
      localStorage.setItem('dhc', JSON.stringify({ auth: { ...data } }));
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const signOut = async () => {
    try {
      await AuthRepository.logout();
    } catch (e) {
      await Promise.reject(e);
      throw e;
    }
    dispatch(authSlice.actions.resetAuthData());
    localStorage.removeItem('dhc');
    localStorage.removeItem('token');
  };

  return {
    signIn,
    signOut,
  };
};
