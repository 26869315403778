import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    wrapper: {
      padding: 24,
      background: '#ffffff',
      marginBottom: 24,
    },
  }),
  { name: 'BarChart' },
);
