import FileSaver from 'file-saver';

import { apiRoutes } from 'router/routes';

import { instance as axiosInstance } from 'utils/FetchHelpers';
import { fetchDataFromAmazonS3 } from 'utils/fetchDataFromAmazonS3';

export const loanReportOptions = id => [
  {
    label: 'Defeasance Summary Form ',
    value: apiRoutes.loanReportSummaryForm(id),
  },
  {
    label: 'Post Closing Servicer Admin',
    value: apiRoutes.loanReportPostClosingServicerAdminForm(id),
  },
  {
    label: 'PRP Merge + Checklist',
    value: apiRoutes.loanReportPRPMergeChecklist(id),
  },
  {
    label: 'SB Execution Doc Transmittal Form',
    value: apiRoutes.loanReportSBExecutionTransmittalDocForm(id),
  },
  {
    label: 'PRP Notice',
    value: apiRoutes.loanPrpNoticeDocForm(id),
  },
];

export const prepareDataToExport = (header, data) =>
  data.reduce((prev, item) => {
    const itemData = [];
    header.forEach(headerItem => {
      itemData.push(item.data[headerItem] || '');
    });
    prev.push(itemData);
    return prev;
  }, []);

const downloadReport = async url => {
  const { name, content } = await fetchDataFromAmazonS3(url, axiosInstance);
  FileSaver.saveAs(content, name);
};

export const reportActions = {
  download: url => downloadReport(url),
};
