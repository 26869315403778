import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { Input } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';

import useStyles from 'pages/LoanReconciliationInputs/useStyles';

export const DecimalInputCell = props => {
  const { name, childrenProps } = props;
  const methods = useFormContext();
  const { control, errors } = methods;
  const classes = useStyles();
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <div className={classes.inputCellWrap}>
      <Input
        name={name}
        autoComplete="off"
        className={classes.input}
        inputRef={ref}
        {...inputProps}
        {...childrenProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />
    </div>
  );
};

DecimalInputCell.propTypes = {
  name: PropTypes.string,
  childrenProps: PropTypes.shape(),
  isDisabled: PropTypes.bool,
};
