import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tag: {
      height: 16,
      fontSize: theme.typography.pxToRem(11),
      textTransform: 'uppercase',
      borderRadius: 2,
      backgroundColor: theme.palette.catskillWhiteTwo,
      fontWeight: 'bold',
      color: theme.palette.blueBayoux,
      '& > span': {
        paddingLeft: 4,
        paddingRight: 4,
      },
    },
  }),
  { name: 'TagList' },
);
