import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { pipe, omit } from 'ramda';

import { ERROR_MESSAGES } from 'enums';
import { FORM_FIELD_TYPE } from 'enums';

import { replaceEmptyStringsToNull } from 'utils/forms';
import { preventEmptyString } from 'utils/yupHelpers';
import { formatDateToYearMonth } from 'utils/date';

const validationDisabled = Yup.object({});

export const resolve = yupResolver(validationDisabled);

export const resolver = yupResolver(
  Yup.object({
    reconciliationYearMonth: Yup.date()
      .nullable()
      .typeError(ERROR_MESSAGES.date.monthYearFormat)
      .required(ERROR_MESSAGES.date.requiredDate),
    balancePerCustodian: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(preventEmptyString),
    floatInterest: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(preventEmptyString),
    custodianFee: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(preventEmptyString),
    other: Yup.number().typeError(ERROR_MESSAGES.number).nullable().transform(preventEmptyString),
    isApprovedForRec: Yup.boolean(),
    comment: Yup.string(),
  }),
);

export const formFields = [
  { name: 'reconciliationYearMonth', type: FORM_FIELD_TYPE.date, placeholder: 'Choose Month' },
  { name: 'balancePerCustodian', type: FORM_FIELD_TYPE.decimal, placeholder: '$0' },
  { name: 'floatInterest', type: FORM_FIELD_TYPE.decimal, placeholder: '$0' },
  { name: 'custodianFee', type: FORM_FIELD_TYPE.decimal, placeholder: '$0' },
  { name: 'other', type: FORM_FIELD_TYPE.decimal, placeholder: '$0' },
  { name: 'isApprovedForRec', type: FORM_FIELD_TYPE.checkbox, label: 'Yes' },
  {
    name: 'reconciliationDiscrepancy',
    type: FORM_FIELD_TYPE.decimal,
    placeholder: '$0',
    disabled: true,
  },
];

export const prepareBeforeSend = formData =>
  pipe(
    replaceEmptyStringsToNull,
    data => ({
      ...data,
      reconciliationYearMonth: formatDateToYearMonth(data.reconciliationYearMonth),
    }),
    omit(['reconciliationDiscrepancy']),
  )(formData);

export const defaultFormValues = {
  reconciliationYearMonth: new Date(),
  balancePerCustodian: '',
  floatInterest: '',
  custodianFee: '',
  other: '',
  reconciliationDiscrepancy: '-',
  isApprovedForRec: false,
  comment: '',
};
