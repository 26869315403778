import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { NavHashLink } from 'react-router-hash-link';

import useStyles from './useStyles';
import CellComponent from './components/CellComponent';

export const CellContent = props => {
  const classes = useStyles();
  const { columnConfig, rowData, colKey, rowDetailsPath, onEdit, selectValues } = props;

  const column = useMemo(() => columnConfig[colKey], [columnConfig, colKey]);
  const value = useMemo(() => rowData[colKey], [rowData, colKey]);

  if (column.isLink)
    return (
      <NavHashLink className={classes.rowLink} to={rowDetailsPath}>
        <CellComponent
          column={column}
          value={value}
          colKey={colKey}
          rowData={rowData}
          selectValues={selectValues}
          onEdit={onEdit}
        />
      </NavHashLink>
    );
  return (
    <CellComponent
      column={column}
      value={value}
      colKey={colKey}
      rowId={rowData.id}
      selectValues={selectValues}
      onEdit={onEdit}
    />
  );
};

CellContent.propTypes = {
  columnConfig: PropTypes.objectOf(
    PropTypes.shape({
      align: PropTypes.string,
      displayedName: PropTypes.string,
      sortingEnabled: PropTypes.bool,
      isLink: PropTypes.bool,
      withTags: PropTypes.bool,
      canBeHidden: PropTypes.bool,
      editable: PropTypes.bool,
      editableFieldType: PropTypes.string,
      maxWidth: PropTypes.string,
      width: PropTypes.string,
    }),
  ),
  rowData: PropTypes.shape(),
  colKey: PropTypes.string,
  rowDetailsPath: PropTypes.string,
  onEdit: PropTypes.func,
  selectValues: PropTypes.shape(),
};
