import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ERROR_MESSAGES } from 'enums';

export const defaultValues = {
  password: '',
  repeatPassword: '',
};

export const resolver = yupResolver(
  Yup.object({
    password: Yup.string().required(ERROR_MESSAGES.required),
    repeatPassword: Yup.string()
      .required(ERROR_MESSAGES.required)
      .oneOf([Yup.ref('password'), null], ERROR_MESSAGES.matchPassword),
  }),
);
