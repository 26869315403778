import React, { useEffect } from 'react';

import Header from 'components/Header';
import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import TableSummary from 'components/TableSummary';

import * as RecentlyClosedDealService from 'domain/recentlyClosedDeal/service';
import { useGetRecentlyClosedDealsQuery } from 'domain/recentlyClosedDeal/apiSlice';

import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { getHomeLinks } from 'utils/routes';

const TITLE = 'Recently Closed Deals';

export const HomeRecentlyClosedDeals = () => {
  const { displayErrorsInToast } = useErrors();

  const {
    data,
    isFetching: areRecentlyClosedDealsLoading,
    isError: isRecentlyClosedDealsLoadError,
    error: recentlyClosedDealsLoadError,
  } = useGetRecentlyClosedDealsQuery();

  const tableData = RecentlyClosedDealService.getTableData(data);
  const columnsKeys = RecentlyClosedDealService.getColumnsKeys();
  const summary = RecentlyClosedDealService.getSummaryData(data);
  const { recentlyClosedDealsColumnConfig } = RecentlyClosedDealService;

  const { elementRef: header, elementHeight } = useRefElementHeight();

  useEffect(() => {
    if (isRecentlyClosedDealsLoadError) {
      displayErrorsInToast([recentlyClosedDealsLoadError]);
    }
  }, [isRecentlyClosedDealsLoadError]);

  const sideBarContent = getHomeLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={TITLE} />
        <Header title={TITLE} ref={header} />
        <ListTable
          detailPath={appRoutes.loanPath}
          isLoading={areRecentlyClosedDealsLoading}
          tableHeader={columnsKeys}
          tableData={tableData}
          columnConfig={recentlyClosedDealsColumnConfig}
          isOuterScrollable
          fixedTopOffset={elementHeight}
        />
        <TableSummary summaryData={summary} />
      </ContentLayout>
    </RootLayoutV2>
  );
};
