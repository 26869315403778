import React, { forwardRef, ForwardedRef } from 'react';

import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { useAppAppearanceContext } from 'hooks/useAppAppearance';

import useStyles from './useStyles';

type HeaderProps = {
  children: JSX.Element;
  title: string;
  shouldUseSidebarState: boolean;
  isHorizontal?: boolean;
};

const Header = forwardRef((props: HeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
  const { children, title, shouldUseSidebarState = true, isHorizontal = false } = props;
  const classes = useStyles();
  const { isSideBarShown } = useAppAppearanceContext();

  return (
    <div className={classes.header} ref={ref}>
      <div
        className={clsx(classes.contentContainer, {
          [classes.showedSideBar]: shouldUseSidebarState && isSideBarShown,
          [classes.horizontalPlacement]: isHorizontal,
        })}
      >
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {children}
      </div>
    </div>
  );
});

export default Header;
