import React, { useEffect } from 'react';

import { keys, isEmpty } from 'ramda';
import format from 'date-fns/format';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';
import NoDataMessage from 'components/NoDataMessage';

import { BOOLEAN_OPTIONS } from 'domain/options/service';

import { useOutstandingBinders } from 'hooks/api/useOutstandingBinders';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { outstandingBindersColumnConfig } from 'presenters/OutstandingBindersPresenter';

import { appRoutes } from 'router/routes';

const TITLE = 'Outstanding Closing Binders';

export const OutstandingClosingBinders = () => {
  const {
    outstandingBindersTableData,
    loadOutstandingBinders,
    updateOutstandingBinder,
    isOutstandingBindersLoading,
    isOutstandingBindersLoadFinished,
  } = useOutstandingBinders();
  const { displayErrorsInToast } = useErrors();

  const { elementRef, elementHeight } = useRefElementHeight();

  const fetchData = async () => {
    try {
      await Promise.all([loadOutstandingBinders()]);
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectValues = {
    booleanTypes: BOOLEAN_OPTIONS,
  };

  const handleEdit = async (id, fieldKey, fieldValue) => {
    const params = {
      [fieldKey]: fieldValue,
      closingBinderReceivedDate: format(new Date(), 'yyyy-MM-dd'),
    };
    await updateOutstandingBinder(id, params);
  };

  const isNoDataMessageShown =
    isEmpty(outstandingBindersTableData) && isOutstandingBindersLoadFinished;

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState />
      {isNoDataMessageShown && <NoDataMessage />}
      <ListTable
        isLoading={isOutstandingBindersLoading}
        detailPath={appRoutes.loanDetailsPath}
        tableHeader={keys(outstandingBindersColumnConfig)}
        tableData={outstandingBindersTableData}
        columnConfig={outstandingBindersColumnConfig}
        onEdit={handleEdit}
        selectValues={selectValues}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
