import { isNil } from 'ramda';

import getLocalStorageEnums from 'utils/getLocalStorageEnums';

export const getLoanStatusLabel = status => {
  const { loanStatuses } = getLocalStorageEnums();
  if (isNil(loanStatuses)) return '';
  const statusItem = loanStatuses.find(item => item.value === status);
  return statusItem.label;
};

export const getLoanStatusOptions = () => getLocalStorageEnums().loanStatuses;

export const getLoansStatusesList = () => {
  const { loanStatuses } = getLocalStorageEnums();
  if (isNil(loanStatuses)) return [];
  const result = loanStatuses.map(item => item.value);
  return result;
};
