import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: '80px',
        height: '80px',
      },
    },
    section: {
      marginBottom: '32px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    sidebar: {
      width: '256px',
    },
    tableContent: {
      width: '100%',
    },
    sidebarTitle: {
      marginTop: '24px',
      padding: [0, '24px'],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: '12px',
    },
    tableContainer: {
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    content: {
      width: `calc(100% - ${theme.sizes.sidebar.width}px)`,
      minHeight: `calc(100vh - ${theme.sizes.header.height}px)`,
      padding: ['24px', '24px', '20px', '24px'],
    },
    snapshotWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    contentTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      marginTop: '64px',
      marginBottom: '16px',
      fontWeight: 'normal',
    },
  }),
  { name: 'HomeOverview' },
);
