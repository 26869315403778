import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params) {
    const url = apiRoutes.usersPath();
    return FetchHelpers.get(url, params);
  },
  async current(params) {
    const url = apiRoutes.currentUserPath();
    return FetchHelpers.get(url, params);
  },
  async updatePassword(params) {
    const url = apiRoutes.updatePasswordPath();
    return FetchHelpers.put(url, params);
  },
  async resetPassword(params) {
    const url = apiRoutes.passwordResetPath();
    return FetchHelpers.post(url, params);
  },
  async confirmPassword(params) {
    const url = apiRoutes.passwordResetConfirmPath();
    return FetchHelpers.post(url, params);
  },
  async validateToken(params) {
    const url = apiRoutes.passwordResetValidateTokenPath();
    return FetchHelpers.post(url, params);
  },
};
