import palette from '../palette';

export default {
  MuiFormGroup: {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexFlow: 'row wrap',
      flexDirection: 'row',
    },
  },
  MuiRadio: {
    root: {
      display: 'none',
    },
  },
  MuiFormControlLabel: {
    label: {
      lineHeight: '20px',
      fontSize: '14px',
      color: palette.primary.main,
    },
  },
};
