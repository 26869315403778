import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async show(params) {
    const url = apiRoutes.assumedDealsLiveFormPath();
    return FetchHelpers.get(url, params);
  },
  async exportToExcel(data) {
    const url = apiRoutes.excelExportPath();
    return FetchHelpers.postDownload(url, data);
  },
};
