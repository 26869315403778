import React, { useEffect } from 'react';

import { keys, isEmpty } from 'ramda';

import Header from 'components/Header';
import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import NoDataMessage from 'components/NoDataMessage';

import { BOOLEAN_OPTIONS } from 'domain/options/service';

import { useOutstandingAccountants } from 'hooks/api/useOutstandingAccountants';
import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { OutstandingAccountantColumnConfig } from 'presenters/OutstandingAccountantPresenter';

import { appRoutes } from 'router/routes';

const TITLE = 'Outstanding Accountant Report';

export const OutstandingAccountant = () => {
  const {
    outstandingAccountantsTableData,
    loadOutstandingAccountants,
    updateLiveLoan,
    isOutstandingAccountantsLoading,
    isOutstandingAccountantsLoadFinished,
  } = useOutstandingAccountants();
  const { displayErrorsInToast } = useErrors();

  const { elementRef, elementHeight } = useRefElementHeight();

  const fetchData = async () => {
    try {
      await loadOutstandingAccountants();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const selectValues = {
    booleanTypes: BOOLEAN_OPTIONS,
  };

  const handleEdit = async (id, fieldKey, fieldValue) => {
    await updateLiveLoan(id, {
      [fieldKey]: fieldValue,
    });
  };

  const isNoDataMessageShown =
    isEmpty(outstandingAccountantsTableData) && isOutstandingAccountantsLoadFinished;

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState />
      {isNoDataMessageShown && <NoDataMessage />}
      <ListTable
        detailPath={appRoutes.loanPath}
        isLoading={isOutstandingAccountantsLoading}
        tableHeader={keys(OutstandingAccountantColumnConfig)}
        tableData={outstandingAccountantsTableData}
        columnConfig={OutstandingAccountantColumnConfig}
        onEdit={handleEdit}
        selectValues={selectValues}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
