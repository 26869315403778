import React, { useEffect, useMemo } from 'react';

import { isNil } from 'ramda';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { LOAN_HASH } from 'src/enums';

import LoanEditableTable from 'components/LoanEditableTable';
import NavigationAlertPopup from 'components/NavigationAlertPopup';

import * as EscrowAccountService from 'domain/loanEscrowAccount/service';
import { useUpdateLoanEscrowAccountMutation } from 'domain/loanEscrowAccount/apiSlice';

import { useEditableTableControls, EditableTableProvider } from 'hooks/useEditableTableControls';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import {
  resolver,
  prepareBeforeSending,
  prepareBeforeSetting,
  formFields,
  defaultValues,
} from 'pages/LoanEscrowAccount/validation';
import useStyles from 'pages/LoanEscrowAccount/useStyles';

const TITLE = 'Escrow Account';
const HINT = 'Double-click the row to edit.';
const SAVE_BUTTON = 'Save changes';
const CANCEL_BUTTON = 'Cancel';
const FORM_ID = 'EscrowAccountForm';

export const EscrowAccountContent = props => {
  const { loanId, escrowAccountsData } = props;
  const classes = useStyles();
  const { displayErrorsInToast, formatErrors, setErrorsToForm } = useErrors();

  const [updateEscrowAccount, useUpdateMutationResult] = useUpdateLoanEscrowAccountMutation();
  const { isLoading: isUpdateEscrowAccountLoading } = useUpdateMutationResult;

  const escrowAccountsTableHeaders = EscrowAccountService.escrowAccountHeaders;
  const escrowAccountsTableRows = useMemo(
    () => EscrowAccountService.createTableRows(escrowAccountsData),
    [escrowAccountsData],
  );

  const tableControls = useEditableTableControls();
  const { isEditingFormShown, editableRowId, hideEditableRow } = tableControls;

  const methods = useForm({
    resolver,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues,
  });

  const { getValues, trigger: triggerValidation } = methods;

  useEffect(() => {
    if (useUpdateMutationResult.isSuccess) {
      hideEditableRow();
    }
    if (useUpdateMutationResult.isError) {
      const { error } = useUpdateMutationResult;
      const { backendServicesError, nonFieldErrors, fieldErrors } = formatErrors(error);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
      setErrorsToForm(methods.setError, methods.errors, fieldErrors);
    }
  }, [useUpdateMutationResult]);

  const handleSaveClick = async data => {
    const preparedData = prepareBeforeSending(data);
    updateEscrowAccount({ loanId, escrowAccountId: editableRowId, data: preparedData });
  };

  const handleCancelButtonClick = () => hideEditableRow();

  const handlePopupSaveClick = async () => {
    const isValid = await triggerValidation();
    if (!isValid) throw new Error();
    const data = getValues();
    const preparedData = prepareBeforeSending(data);
    updateEscrowAccount(loanId, editableRowId, preparedData);
  };

  return (
    <ContentLayout>
      <NavigationAlertPopup when={isEditingFormShown} onSubmit={handlePopupSaveClick} />
      <div className={classes.contentHeader}>
        <h3 id={LOAN_HASH.details} className={classes.detailTitle}>
          {TITLE}
        </h3>
        {isEditingFormShown ? (
          <div>
            <Button
              onClick={handleCancelButtonClick}
              className={classes.cancelBtn}
              variant="contained"
              disabled={isUpdateEscrowAccountLoading}
            >
              {CANCEL_BUTTON}
            </Button>
            <Button
              type="submit"
              form={FORM_ID}
              variant="contained"
              color="secondary"
              disabled={isUpdateEscrowAccountLoading}
            >
              {SAVE_BUTTON}
            </Button>
          </div>
        ) : (
          <p className={classes.hint}>{HINT}</p>
        )}
      </div>
      <div className={classes.detailWrap}>
        <div className={classes.tableWrap}>
          {!isNil(escrowAccountsTableRows) && (
            <FormProvider {...methods}>
              <EditableTableProvider {...tableControls}>
                <LoanEditableTable
                  onSave={handleSaveClick}
                  columns={escrowAccountsTableHeaders}
                  rows={escrowAccountsTableRows}
                  dataItems={escrowAccountsData}
                  prepareBeforeSetting={prepareBeforeSetting}
                  formId={FORM_ID}
                  formFields={formFields}
                />
              </EditableTableProvider>
            </FormProvider>
          )}
        </div>
      </div>
    </ContentLayout>
  );
};

EscrowAccountContent.propTypes = {
  loanId: PropTypes.number,
  escrowAccountsData: PropTypes.arrayOf(EscrowAccountService.loanEscrowAccountPropTypes),
};
