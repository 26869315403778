import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

export const resolver = yupResolver(Yup.object({}));

export const fieldArray = [
  {
    fieldKey: 'oneMonth',
    displayedName: '1 Month',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'threeMonths',
    displayedName: '3 Month',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'sixMonths',
    displayedName: '6 Month',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'oneYear',
    displayedName: '1 Year',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'threeYears',
    displayedName: '3 Year',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'fiveYears',
    displayedName: '5 Year',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'tenYears',
    displayedName: '10 Year',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
  {
    fieldKey: 'thirtyYears',
    displayedName: '30 Year',
    type: FORM_FIELD_TYPE.input,
    inputType: 'number',
  },
];
