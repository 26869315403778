import { createSlice } from '@reduxjs/toolkit';
import RemindersRepository from 'repositories/RemindersRepository';

import { createRestSlice } from 'utils/RestSlice';

const remindersRestSlice = createRestSlice({
  resource: 'reminder',
  repository: RemindersRepository,
  slices: ['loadReminders', 'updateReminder'],
});

const initialState = {
  ...remindersRestSlice.initialState,
};

const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    ...remindersRestSlice.reducers,
  },
});

export default remindersSlice.reducer;
export const remindersRestHooks = remindersRestSlice.hooks(remindersSlice, 'RemindersSlice');
