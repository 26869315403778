import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { keys } from 'ramda';

import {
  formatData,
  formatAccountingTableData,
  accountingTableColumnConfig,
  summaryOidOffByTableFields,
  journalEntryTableConfig,
  formatJournalEntryData,
  formatSuccessorBorrowersData,
  successorBorrowerSummaryTableFields,
  SBReportSummaryTableFields,
  getYearsOptions,
  SBJournalEntryTableFields,
  SBAccountingTableColumnConfig,
  formatSBAccountingTableData,
  formatJournalEntryPRPData,
  journalEntryPRPTableConfig,
} from 'presenters/OIDBalanceReportPresenter';

import {
  useOIDBalanceReportActions,
  useOIDBalanceLoanDetailsActions,
  useOIDBalanceSuccessorBorrowerDetailsActions,
} from 'store/OIDBalanceReportsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

// selectors
const selectLoanDetails = state => state.OIDBalanceReportsSlice.OIDBalanceLoanDetails;
const selectSuccessorBorrowerData = state =>
  state.OIDBalanceReportsSlice.OIDBalanceSuccessorBorrowerDetails;
const selectLoanDetailsItem = state => selectLoanDetails(state).item;
const selectSuccessorBorrowerItem = state => selectSuccessorBorrowerData(state).item;
const selectSuccessorBorrowerErrors = state => selectSuccessorBorrowerData(state)?.errors;
const selectFormattedData = createSelector(selectLoanDetailsItem, item => formatData(item));
const selectAccountingTableData = createSelector(selectLoanDetailsItem, item =>
  formatAccountingTableData(item),
);
const selectJournalEntryData = createSelector(selectLoanDetailsItem, item =>
  formatJournalEntryData(item),
);
const selectSuccessorBorrowerFormattedData = createSelector(selectSuccessorBorrowerItem, item =>
  formatSuccessorBorrowersData(item),
);
const selectSBAccountingTableData = createSelector(selectSuccessorBorrowerItem, item =>
  formatSBAccountingTableData(item),
);
const selectJournalEntryPRPData = createSelector(selectLoanDetailsItem, item =>
  formatJournalEntryPRPData(item),
);

// hook
export const useOIDBalanceReports = () => {
  const { createOIDBalanceLongPollingWorker, OIDBalanceReport, clearOIDBalanceReportCache } =
    useOIDBalanceReportActions();
  const { loadLoanDetails } = useOIDBalanceLoanDetailsActions();
  const { loadSuccessorBorrowerDetails } = useOIDBalanceSuccessorBorrowerDetailsActions();

  const { item: OIDBalanceReportData, loadingStatus: OIDBalanceReportLoadingStatus } =
    OIDBalanceReport;
  const { isPending: isOIDBalanceReportLoading } = useFetchStatus(OIDBalanceReportLoadingStatus);
  const { isFulfilled: isOIDBalanceReportFulfilled } = useFetchStatus(
    OIDBalanceReportLoadingStatus,
  );
  const { item: OIDBalanceLoanDetails, loadingStatus: loanReportLoadingStatus } =
    useSelector(selectLoanDetails);

  const loanDetailsData = useSelector(selectFormattedData);
  const accountingTableData = useSelector(selectAccountingTableData);
  const journalEntryTableData = useSelector(selectJournalEntryData);
  const journalEntryPRPTableData = useSelector(selectJournalEntryPRPData);

  const { item: successorBorrowerReportItem, loadingStatus: successorBorrowerLoadingStatus } =
    useSelector(selectSuccessorBorrowerData);
  const successorBorrowerReport = useSelector(selectSuccessorBorrowerFormattedData);
  const successorBorrowerReportLoadingErrors = useSelector(selectSuccessorBorrowerErrors);
  const SBAccountingTableData = useSelector(selectSBAccountingTableData);

  return {
    // index
    createOIDBalanceLongPollingWorker,
    isOIDBalanceReportLoading,
    isOIDBalanceReportFulfilled,
    OIDBalanceReportData,
    clearOIDBalanceReportCache,
    // loan details
    OIDBalanceLoanDetails,
    loanDetailsData,
    loadLoanDetails,
    areLoanDetailsLoading: useFetchStatus(loanReportLoadingStatus).isPending,
    summaryOidOffByTableFields,
    summaryOidOffByTableKeys: keys(summaryOidOffByTableFields),
    accountingTableColumnConfig,
    accountingTableData,
    accountingTableKeys: keys(accountingTableColumnConfig),
    journalEntryTableConfig,
    journalEntryTableData,
    journalEntryPRPTableData,
    // successor borrowers details
    loadSuccessorBorrowerDetails,
    areSBDetailsLoading: useFetchStatus(successorBorrowerLoadingStatus).isPending,
    successorBorrowerReportItem,
    successorBorrowerReport,
    successorBorrowerReportLoadingErrors,
    getYearsOptions,
    successorBorrowerSummaryTableFields,
    SBReportSummaryTableFields,
    SBJournalEntryTableFields,
    SBAccountingTableData,
    SBAccountingTableColumnConfig,
    journalEntryPRPTableConfig,
  };
};
