import React from 'react';

import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import clsx from 'clsx';

import TagList from 'components/TagList';

import { isValueTooLong } from 'utils/strings';
import { getRowDetailsPath } from 'utils/table';

import useStyles from './useStyles';
import CellContent from './components/CellContent';

export const TableBodyContent = ({
  tableHeader,
  columnConfig,
  detailPath,
  onEdit,
  selectValues,
  tableBottomContent,
  tableDataToRender,
}) => {
  const classes = useStyles();

  return (
    <TableBody>
      {tableDataToRender?.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className={clsx(classes.tableRow, {
            [classes.highlighted]: row.isHighlighted,
          })}
          draggable={false}
        >
          {tableHeader.map(colKey => (
            <td
              key={`${row.id}_${colKey}`}
              className={clsx(classes.tableCell, {
                [classes.wordBreak]: isValueTooLong(row?.data[colKey]),
              })}
              style={{
                textAlign: columnConfig[colKey]?.align,
                maxWidth: columnConfig[colKey]?.maxWidth,
                width: columnConfig[colKey]?.width,
              }}
            >
              <CellContent
                columnConfig={columnConfig}
                rowData={row?.data}
                colKey={colKey}
                rowDetailsPath={getRowDetailsPath(detailPath, row.id)}
                onEdit={onEdit}
                selectValues={selectValues}
              />
              {columnConfig[colKey]?.withTags && (
                <>
                  <br />
                  <TagList tags={row?.data?.tags} />
                </>
              )}
            </td>
          ))}
        </tr>
      ))}
      {tableBottomContent}
    </TableBody>
  );
};

TableBodyContent.propTypes = {
  tableHeader: PropTypes.arrayOf(PropTypes.string),
  columnConfig: PropTypes.objectOf(
    PropTypes.shape({
      align: PropTypes.string,
      displayedName: PropTypes.string,
      sortingEnabled: PropTypes.bool,
      isLink: PropTypes.bool,
      withTags: PropTypes.bool,
      canBeHidden: PropTypes.bool,
      editable: PropTypes.bool,
      editableFieldType: PropTypes.string,
      maxWidth: PropTypes.string,
      width: PropTypes.string,
    }),
  ),
  detailPath: PropTypes.func,
  onEdit: PropTypes.func,
  selectValues: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  tableBottomContent: PropTypes.element,
  tableDataToRender: PropTypes.arrayOf(PropTypes.shape()),
};
