import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    contentContainer: {
      maxWidth: theme.sizes.content.width,
    },
    tableContainer: {
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: 16,
    },
    contentSubtitle: {
      margin: [8, 0],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.primary.main,
      opacity: 0.5,
      letterSpacing: '0.02em',
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    editButton: {
      flexShrink: 0,
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',

      '& span': {
        opacity: 0.75,
      },
    },
    detailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      marginBottom: theme.spacing(4),
    },
    detailTitle: {
      paddingTop: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 16,
      fontWeight: 'normal',
    },
    table: {
      tableLayout: 'fixed',
    },
    tableCell: {
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  }),
  { name: 'Organization' },
);
