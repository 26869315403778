import React, { useEffect, useState } from 'react';

import { MenuItem, Select } from '@material-ui/core';
import clsx from 'clsx';

import ListTable from 'components/ListTable';
import DocumentTitle from 'components/DocumentTitle';
import Header from 'components/Header';

import { LOANS_STATUSES } from 'const';

import { useGetExtendedLoansQuery } from 'domain/extendedLoan/apiSlice';
import { UpcomingParRepaysService } from 'domain/extendedLoan/service';

import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { defaultMonthSelectValue, monthSelectValues } from 'utils/date';
import { getHomeLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Upcoming Property Par Repays';

export const HomeUpcomingParRepays = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const [selectedMonth, setSelectedMonth] = useState(defaultMonthSelectValue);
  const { elementRef: header, elementHeight } = useRefElementHeight();

  const fetchingParams = {
    parRepaysOnly: 1,
    ordering: 'par_repay_date,par_repay_notice_date,filing_ref,maturity_date',
    status: LOANS_STATUSES.activeInPayment,
    nextMonths: selectedMonth,
  };

  const {
    data,
    isFetching: areUpcomingParRepaysLoading,
    isError: isUpcomingParRepaysLoadError,
    error: upcomingParRepayLoadError,
  } = useGetExtendedLoansQuery(fetchingParams);

  const tableData = UpcomingParRepaysService.getTableData(data);
  const columnsKeys = UpcomingParRepaysService.getTableColumnsKeys();
  const { upcomingParRepaysColumnConfig } = UpcomingParRepaysService;

  const monthSelectOnChange = e => {
    setSelectedMonth(e.target.value);
  };

  useEffect(() => {
    if (isUpcomingParRepaysLoadError) {
      displayErrorsInToast([upcomingParRepayLoadError]);
    }
  }, [isUpcomingParRepaysLoadError]);

  const sideBarContent = getHomeLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={TITLE} />
        <Header title={TITLE} ref={header}>
          <div className={classes.actionPanel}>
            <Select
              className={clsx(classes.monthSelect, classes.actionPanelSelect)}
              variant="standard"
              name="monthSelect"
              displayEmpty
              defaultValue={selectedMonth}
              onChange={monthSelectOnChange}
            >
              {monthSelectValues.map(month => (
                <MenuItem className={classes.menuItem} key={`month_${month}`} value={month}>
                  {month} Months
                </MenuItem>
              ))}
              <MenuItem className={classes.menuItem} value={null}>
                All
              </MenuItem>
            </Select>
          </div>
        </Header>
        <ListTable
          detailPath={appRoutes.loanPath}
          isLoading={areUpcomingParRepaysLoading}
          tableHeader={columnsKeys}
          tableData={tableData}
          columnConfig={upcomingParRepaysColumnConfig}
          isOuterScrollable
          fixedTopOffset={elementHeight}
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
