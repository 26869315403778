import { useQueryParams, StringParam } from 'use-query-params';

import { withResetPageParam } from 'utils/pagination';

const useLoansStatus = () => {
  const [query, setQuery] = useQueryParams({
    status: StringParam,
  });

  const handleSetLoansStatus = (value, params) => {
    const { shouldResetPage } = params;
    const queryParam = shouldResetPage ? withResetPageParam({ status: value }) : { status: value };
    setQuery(queryParam);
  };

  return {
    loansStatus: query.status,
    setLoansStatus: handleSetLoansStatus,
  };
};

export { useLoansStatus };
