import React from 'react';

import PropTypes from 'prop-types';
import { Input } from '@material-ui/core';
import { useController, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { ErrorMessage } from '@hookform/error-message';
import useStyles from 'components/LoanEditableTable/useStyles';

export const DecimalInputCell = props => {
  const { name, childrenProps } = props;
  const { decimalScale } = childrenProps;
  const methods = useFormContext();
  const { control, errors } = methods;
  const classes = useStyles();
  const {
    field: { ref, onChange, ...inputProps },
  } = useController({
    name,
    control,
  });

  const handleValueChange = values => {
    const { floatValue } = values;
    onChange(floatValue ?? null);
  };

  return (
    <div className={classes.inputCellWrap}>
      <NumberFormat
        name={name}
        type="text"
        thousandSeparator=","
        decimalScale={decimalScale}
        customInput={Input}
        onValueChange={handleValueChange}
        autoComplete="off"
        className={classes.input}
        inputRef={ref}
        {...inputProps}
        {...childrenProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />
    </div>
  );
};

DecimalInputCell.propTypes = {
  name: PropTypes.string,
  childrenProps: PropTypes.shape(),
};
