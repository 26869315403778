import React from 'react';

import useStyles from './useStyles';

const ActionPanelButtonWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.actionPanelButtonWrapper}>{children}</div>;
};

export default ActionPanelButtonWrapper;
