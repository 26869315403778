import React from 'react';

import { Typography, Paper, List, ListItem } from '@material-ui/core';
import clsx from 'clsx';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';

import useStyles from './useStyles';
import DecimalInput from './components/DecimalInput';

export const SummaryOidOffBy = () => {
  const { loanDetailsData, summaryOidOffByTableKeys, summaryOidOffByTableFields } =
    useOIDBalanceReports();
  const classes = useStyles();
  const { id } = loanDetailsData;

  const [totalsKey, ...restSummaryTableKeys] = summaryOidOffByTableKeys;
  const totalsName = summaryOidOffByTableFields[totalsKey].displayedName;
  const totalsValue = loanDetailsData[totalsKey];

  return (
    <div>
      <Typography className={classes.listTitle}>Summary</Typography>
      <Paper className={classes.list}>
        <div className={classes.summaryTotal}>
          <Typography className={classes.bodyBold}>{totalsName}</Typography>
          <Typography className={classes.bodyBold}>{totalsValue}</Typography>
        </div>
        <List className={clsx(classes.summaryList, classes.markEvenElements)}>
          {restSummaryTableKeys.map((key, index) => {
            const { displayedName, isEditable } = summaryOidOffByTableFields[key];
            const value = loanDetailsData[key];
            if (isEditable) {
              return (
                <ListItem className={classes.summaryListItem} key={`summaryList${index}`}>
                  <DecimalInput field={{ displayedName, key, value, id }} />
                </ListItem>
              );
            }
            return (
              <ListItem className={classes.summaryListItem} key={`summaryList${index}`}>
                <Typography className={classes.bodyBold}>{displayedName}</Typography>
                <Typography className={classes.body1}>{value}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};
