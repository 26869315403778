import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    autocomplete: {
      maxWidth: '320px',
      '& .MuiInput-formControl': {
        marginTop: '4px',
      },
      '& .MuiInput-root': {
        paddingTop: 0,
      },
    },
  }),
  { name: 'Autocomplete' },
);
