import { isEmpty, filter, includes } from 'ramda';
import { MAX_JS_NUMBER_LENGTH } from 'const/MaxNumberLength';

export const preventEmptyString = (value, originalValue) => {
  if (isEmpty(String(originalValue).trim())) {
    return null;
  }
  return value;
};

const charToRemove = ['.', '-'];
const removeDotAndMinus = string => filter(char => !includes(char, charToRemove), string).join('');

export const testNumberLength = value => {
  const prepared = removeDotAndMinus(String(value));
  const { length } = prepared;
  return length <= MAX_JS_NUMBER_LENGTH;
};
