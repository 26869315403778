import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    navList: {
      padding: 0,
      minHeight: 40,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
    },
    navItem: {
      padding: [12, 24],
    },
    activeNavLink: {
      backgroundColor: theme.palette.catskillWhiteTwo,
      position: 'relative',
      '&:before': {
        content: "''",
        display: 'block',
        width: 4,
        minHeight: 32,
        borderRadius: 34,
        backgroundColor: theme.palette.secondary.main,
        position: 'absolute',
        left: 4,
        top: 4,
      },
    },
    disabledLink: {
      pointerEvents: 'none',
      opacity: 0.6,
    },
    sidebarTitle: {
      margin: 0,
      padding: [0, 24],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
  }),
  { name: 'loansSideNav' },
);
