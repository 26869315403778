import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, Input, Radio, RadioGroup } from '@material-ui/core';
import InputMask from 'react-input-mask';
import clsx from 'clsx';

import FormError from 'components/FormError';
import HiddenInput from 'components/HiddenInput';

import { DateConst } from 'const';

import { getFormFieldValue } from 'utils/getFormFieldValue';
import { formatDate } from 'utils/date';

import useStyles from './useStyles';

const FormRowEnumDatepicker = ({ field, errors, options, values }) => {
  const classes = useStyles();

  const enumRef = useRef();
  const inputRef = useRef();

  const { control } = useFormContext();
  const { fieldKeyInput, fieldKeyEnum, displayedName } = field;

  const enumValue = getFormFieldValue(fieldKeyEnum, values);
  const inputValue = getFormFieldValue(fieldKeyInput, values);
  const formatedInputValue = inputValue ? formatDate(inputValue) : '';

  const [radioButtonValue, setRadioButtonValue] = useState(enumValue);

  const isActive = value =>
    value?.toString().toLowerCase() === radioButtonValue?.toString().toLowerCase();

  const handleChange = event => {
    setRadioButtonValue(event.target.value);
  };

  const handleFocus = () => {
    enumRef.current.disabled = false;
    enumRef.current.focus();
  };

  return (
    <div
      className={clsx(classes.formRow, {
        [classes.divider]: field.hasMargin,
      })}
    >
      <legend className={classes.formLabel}>{displayedName}</legend>
      <div className={classes.rowEnumDatepicker}>
        <FormControl className={classes.fieldWrap} component="fieldset">
          <div>
            <Controller
              control={control}
              name={fieldKeyEnum}
              defaultValue={radioButtonValue}
              onFocus={handleFocus}
              as={
                <RadioGroup aria-label={fieldKeyEnum} className={classes.radioWrap}>
                  {options.map(option => (
                    <FormControlLabel
                      key={`${fieldKeyEnum}_${option.value}`}
                      className={clsx(classes.radioButtonLabel, {
                        [classes.activeRadioButtonLabel]: isActive(option.value),
                      })}
                      value={option.value}
                      control={<Radio onChange={handleChange} inputRef={enumRef} />}
                      label={option.displayedName}
                    />
                  ))}
                  <HiddenInput readOnly parentName={fieldKeyEnum} inputRef={enumRef} />
                </RadioGroup>
              }
            />
            <ErrorMessage
              className={classes.enumError}
              errors={errors}
              name={fieldKeyEnum}
              render={({ message }) => <FormError message={message} />}
            />
          </div>
          <div className={classes.enumWrap}>
            <Controller
              id={fieldKeyInput}
              control={control}
              name={fieldKeyInput}
              defaultValue={formatedInputValue}
              margin="normal"
              mask="99/99/9999"
              permanents={DateConst.PERMANENTS}
              alwaysShowMask={false}
              as={
                <InputMask>{inputProps => <Input {...inputProps} inputRef={inputRef} />}</InputMask>
              }
            />
            <ErrorMessage
              className={classes.enumError}
              errors={errors}
              name={fieldKeyInput}
              render={({ message }) => <FormError message={message} />}
            />
          </div>
        </FormControl>
      </div>
    </div>
  );
};

FormRowEnumDatepicker.propTypes = {
  field: PropTypes.shape({
    fieldKeyEnum: PropTypes.bool,
    fieldKeyInput: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
  }),
  errors: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayedName: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  values: PropTypes.shape({}),
};

export default FormRowEnumDatepicker;
