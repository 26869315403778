import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    label: PropTypes.string,
    value: PropTypes.string,
  },
  {
    originalLendersFilter(items) {
      return items
        ? items.map(item => ({
            label: item.originalLender,
            value: item.originalLender,
          }))
        : [];
    },
    servicersFilter(items) {
      return items
        ? items.map(item => ({
            label: item.name,
            value: String(item.id),
          }))
        : [];
    },
    createOriginalLenderDefaultOptions(values = []) {
      return values.map(item => ({ value: item, label: item }));
    },
  },
);
