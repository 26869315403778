import React from 'react';

import PropTypes from 'prop-types';

import DataRow from './components/DataRow';
import FormRow from './components/FormRow';

export const CommonTableRow = props => {
  const { row, isForm = false, rowKeys, formFields } = props;

  return isForm ? (
    <FormRow row={row} formFields={formFields} />
  ) : (
    <DataRow row={row} rowKeys={rowKeys} />
  );
};

CommonTableRow.propTypes = {
  rowKeys: PropTypes.arrayOf(PropTypes.string),
  isForm: PropTypes.bool,
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({}),
  }),
  formFields: PropTypes.arrayOf(PropTypes.shape({})),
};
