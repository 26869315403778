import { createSlice } from '@reduxjs/toolkit';
import AdministrativeAgentsRepository from 'repositories/AdministrativeAgentsRepository';

import { createRestSlice } from 'utils/RestSlice';

const administrativeAgentsRestSlice = createRestSlice({
  resource: 'administrativeAgent',
  repository: AdministrativeAgentsRepository,
  slices: [
    'loadAdministrativeAgents',
    'loadAdministrativeAgent',
    'updateAdministrativeAgent',
    'createAdministrativeAgent',
    'deleteAdministrativeAgent',
  ],
});

const initialState = {
  ...administrativeAgentsRestSlice.initialState,
};

const administrativeAgentsSlice = createSlice({
  name: 'administrativeAgents',
  initialState,
  reducers: {
    ...administrativeAgentsRestSlice.reducers,
  },
});

export default administrativeAgentsSlice.reducer;
export const administrativeAgentsRestHooks = administrativeAgentsRestSlice.hooks(
  administrativeAgentsSlice,
  'AdministrativeAgentsSlice',
);
