import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    headerPanel: {
      marginBottom: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerButton: {
      width: '88px',
      height: '32px',
      background: 'rgba(19, 51, 76, 0.07)',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.elephant,
    },
    table: {
      '& th': {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      '& td': {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    tableHeader: {
      marginBottom: '16px',
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgb(19, 51, 76, 0.4)',
    },
    tableHead: {
      background: '#EFF2F5',
    },
    tableHeadCell: {
      textAlign: 'left',
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
      color: theme.palette.santasGray,
    },
    inputCell: {
      '& input': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    input: {
      height: '24px',
      '& .MuiInput-root': {
        height: '24px',
      },
    },
    checkboxCellWrap: {
      minWidth: '70px',
    },
    tableCellFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
    },
    highlighted: {
      color: theme.palette.crimson,
    },
    disabledRow: {
      backgroundColor: '#EFF2F5',
      '& td': {
        color: theme.palette.santasGray,
      },
    },
    disabledIconButton: {
      opacity: '0.33',
    },
    link: {
      marginRight: '20px',
    },
    autocomplete: {
      marginBottom: '24px',
    },
    summary: {
      width: 'fit-content',
    },
    custodianFeeLabel: {
      marginTop: '12px',
    },
    noRecordEntry: {
      color: theme.palette.primary.main,
    },
    saveButton: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
      paddingLeft: '0px',
    },
    deleteButton: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: '#E41515',
    },
    inputCellWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    errorMessage: {
      maxWidth: '200px',
      marginTop: '3px',
      marginBottom: '3px',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: '#E41515',
    },
    card: {
      padding: '16px',
    },
    buttonsPopup: {
      marginTop: '8px',
      display: 'flex',
      justifyContent: 'space-around',
      '& span': {
        color: '#4A6275',
      },
    },
    commentInput: {
      background: '1px solid red',
      '& .MuiOutlinedInput-multiline': {
        padding: '4px 8px',
      },
    },
    whiteText: {
      '& .MuiButton-label': {
        color: 'white',
      },
    },
    popperArrowInEdit: {
      zIndex: 1,
      position: 'absolute',
      width: '42px',
      bottom: 0,
      left: 0,
      marginBottom: '-13px',
    },
    popperArrow: {
      zIndex: 1,
      position: 'absolute',
      width: '52px',
      bottom: 0,
      left: 0,
      marginBottom: '-13px',
    },
    iconContainer: {
      marginBottom: '5px',
      marginLeft: '5px',
    },
    approvedForRecCell: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      width: 'fit-content',
    },
    triangleSvg: {
      height: '10px',
    },
    triangleArrowButton: {
      marginBottom: '10px',
      marginLeft: '5px',
    },
  }),
  { name: 'LoanReconciliationInputs' },
);
