import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    input: {
      width: '100%',
      minWidth: '320px',
      '& label + .MuiInput-formControl': {
        marginTop: '4px',
      },
    },
    icon: {
      '& span': {
        color: theme.palette.osloGray,
        opacity: 0.4,
      },
    },
  }),
  { name: 'PasswordInput' },
);
