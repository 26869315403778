import React from 'react';

import { Divider, IconButton, Input, CircularProgress } from '@material-ui/core';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import useStyles from './useStyles';

const SearchField = props => {
  const { placeholder, className, handleChange, isOptionsLoading } = props;

  const classes = useStyles();

  const onChange = event => {
    handleChange(event.target.value);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <Input
        type="search"
        className={classes.input}
        classes={{ focused: classes.inputFocused }}
        placeholder={placeholder}
        onChange={onChange}
        endAdornment={isOptionsLoading && <CircularProgress color="inherit" size={20} />}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton}>
        <SearchIcon />
      </IconButton>
    </div>
  );
};

SearchField.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  isOptionsLoading: PropTypes.bool,
};

SearchField.defaultProps = {
  placeholder: 'Search',
  handleChange: () => {},
};

export default SearchField;
