import searchIcon from 'assets/search.png';
import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.sizes.actionPanel.marginBottom,
    },
    actionSearch: {
      padding: [6, 41, 6, 12],
      marginRight: 8,
      height: 32,
      width: 252,
      backgroundColor: theme.palette.white,
      position: 'relative',
      '&:after': {
        content: "''",
        display: 'block',
        borderLeft: [2, 'solid', theme.palette.catskillWhite],
        width: 28,
        height: 28,
        background: `url(${searchIcon}) no-repeat center`,
        position: 'absolute',
        right: 0,
        top: 0,
      },
    },
    searchFocused: {
      '&:after': {
        borderLeft: [2, 'solid', theme.palette.secondary.main],
      },
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
  }),
  { name: 'AdministrativeAgents' },
);
