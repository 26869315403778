import { useRef, useState, useEffect, useCallback } from 'react';

import { debounce } from '@material-ui/core';

import { TimersConst } from 'const';

import { getTableHeightFromWindow } from 'utils/table';

export const useRefTableContainerHeight = isInnerScrollable => {
  const [tableContainerHeight, setTableContainerHeight] = useState('auto');
  const tableContentRef = useRef(null);

  const debounceOnResize = debounce(
    () => setTableContainerHeight(getTableHeightFromWindow(tableContentRef)),
    TimersConst.TABLE_RESIZE_DEBOUNCE_TIME,
  );

  const onResize = useCallback(() => debounceOnResize(), []);

  useEffect(() => {
    if (isInnerScrollable) {
      setTableContainerHeight(getTableHeightFromWindow(tableContentRef));
    }
  }, [isInnerScrollable]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return {
    tableContainerHeight,
    tableContentRef,
  };
};
