import React, { useEffect } from 'react';

import { isNil, isEmpty } from 'ramda';
import OIDBalanceRepository from 'repositories/OIDBalanceRepository';
import { Grid } from '@material-ui/core';

import OidReportDetailsHeader from 'components/OidReportDetailsHeader';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';
import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';
import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useReportTask } from 'hooks/api/useReportTask';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { reportActions } from 'utils/reports';

import SummaryOidOffBy from './components/SummaryOidOffBy';
import AccountingTable from './components/AccountingTable';
import JournalEntryTable from './components/JournalEntryTable';
import AdjustmentNoteInput from './components/AdjustmentNoteInput';

export const OIDBalanceLoanDetails = () => {
  const {
    params: { id },
  } = useRouter();

  const {
    loadLoanDetails,
    areLoanDetailsLoading,
    loanDetailsData,
    journalEntryTableConfig,
    journalEntryTableData,
    journalEntryPRPTableData,
    journalEntryPRPTableConfig,
  } = useOIDBalanceReports();

  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading } = useReportTask();

  const { formatErrors, displayErrorsInToast } = useErrors();

  const { elementRef: headerRef, elementHeight: fixedTopOffset } = useRefElementHeight();

  const fetchData = async () => {
    try {
      await loadLoanDetails(id);
    } catch (e) {
      const { nonFieldErrors, backendServicesError } = formatErrors(e);
      displayErrorsInToast([nonFieldErrors, backendServicesError]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const dealName = loanDetailsData?.dealName;
  const successorBorrowerId = loanDetailsData?.successorBorrowerId;
  const memberName = loanDetailsData?.memberName;
  const successorBorrowerName = loanDetailsData?.successorBorrowerName;

  const pathsForBreadcrumbs = [
    { name: 'Live Forms', path: appRoutes.liveFormsPath() },
    { name: 'OID Balance Report', path: appRoutes.liveFormsOIDBalanceReportPath() },
    { name: memberName },
    {
      name: successorBorrowerName,
      path: appRoutes.OIDBalanceReportSuccessoBorrowerPath(successorBorrowerId),
    },
    { name: dealName },
  ];

  const detailsLinks = [
    { path: appRoutes.loanPath(id), name: 'View Loan Details' },
    { path: appRoutes.loanReconciliationInputsPath(id), name: 'Reconciliation Inputs' },
  ];

  const fetchExcelModelLocation = () => OIDBalanceRepository.OIDBalanceReportTasksModel(id);

  const handleDownloadButtonClick = async () => {
    try {
      const href = await initiateTaskReportLoading(fetchExcelModelLocation);
      await reportActions.download(href);
    } catch (errors) {
      if (!isEmpty(errors)) {
        displayErrorsInToast([errors]);
        finishLoading(errors);
      }
    }
  };

  return (
    <RootLayoutV2 isLoading={areLoanDetailsLoading || isNil(loanDetailsData)}>
      <OidReportDetailsHeader
        ref={headerRef}
        title={dealName}
        links={detailsLinks}
        pathsForBreadcrumbs={pathsForBreadcrumbs}
        onDownload={handleDownloadButtonClick}
        isExcelLoading={isTaskReportLoading}
      />
      <ContentLayout disableTopPadding>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <SummaryOidOffBy />
          </Grid>
          <Grid item xs={8}>
            <Grid container item spacing={2}>
              <Grid item xs={6}>
                <JournalEntryTable
                  journalEntryConfig={journalEntryTableConfig}
                  journalEntryData={journalEntryTableData}
                  journalEntryTotal={journalEntryTableData?.totalsDebitAndCredit}
                />
              </Grid>
              <Grid item xs={6}>
                <AdjustmentNoteInput />
              </Grid>
              {!isNil(journalEntryPRPTableData) && (
                <Grid item xs={6}>
                  <JournalEntryTable
                    journalEntryConfig={journalEntryPRPTableConfig}
                    journalEntryData={journalEntryPRPTableData}
                    journalEntryTotal={journalEntryPRPTableData?.total}
                    isJournalEntryPRPTable
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <AccountingTable fixedTopOffset={fixedTopOffset} />
      </ContentLayout>
    </RootLayoutV2>
  );
};
