import { useState, useContext } from 'react';

import {
  EditableTableControlContext,
  EditableTableProvider,
} from 'contexts/EditableTableControlContext';

const useEditableTableContext = () => useContext(EditableTableControlContext);

const useEditableTableControls = () => {
  const [isCreatingFormShown, setCreatingFormShown] = useState(false);
  const [isEditingFormShown, setEditingFormShown] = useState(false);
  const [editableRowId, setEditableRowId] = useState(null);

  const showCreatingForm = () => setCreatingFormShown(true);
  const hideCreatingForm = () => setCreatingFormShown(false);
  const hideEditableRow = () => {
    setEditingFormShown(false);
    setEditableRowId(null);
  };
  const showEditableRow = () => setEditingFormShown(true);
  const isDataRowsDisabled = isCreatingFormShown || isEditingFormShown;

  return {
    isCreatingFormShown,
    hideCreatingForm,
    showCreatingForm,
    isEditingFormShown,
    showEditableRow,
    hideEditableRow,
    editableRowId,
    setEditableRowId: idRow => setEditableRowId(idRow),
    isDataRowsDisabled,
  };
};

export { useEditableTableControls, useEditableTableContext, EditableTableProvider };
