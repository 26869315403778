import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import DatePicker from 'components/DatePicker';

import { useInterestAccrualReport } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';

import { parseDate } from 'utils/date';

import useStyles from './useStyles';
import { resolver, formField } from './validation';

export const ReportYearInput = props => {
  const { reportYear, interestAccrualsLoadError } = useInterestAccrualReport();
  const { formatErrors, setErrorsToForm } = useErrors();
  const { onSubmit } = props;
  const classes = useStyles();

  const methods = useForm({
    mode: 'onSubmit',
    resolver,
    defaultValues: {
      [formField.fieldKey]: parseDate('y')(reportYear),
    },
  });

  const { errors, setError } = methods;

  useEffect(() => {
    if (interestAccrualsLoadError) {
      const { fieldErrors } = formatErrors(interestAccrualsLoadError);
      setErrorsToForm(setError, errors, fieldErrors);
    }
  }, [interestAccrualsLoadError]);

  return (
    <div className={classes.yearInput}>
      <FormProvider {...methods}>
        <DatePicker onSubmit={onSubmit} formField={formField} muiViewTypes={['year']} />
      </FormProvider>
    </div>
  );
};

ReportYearInput.propTypes = {
  onSubmit: PropTypes.func,
};
