import React from 'react';

import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';

import Popup from 'components/Popup';

import useStyles from './useStyles';

const ALERT_POPUP_TITLE = 'Save changes?';
const ALERT_POPUP_MESSAGE =
  'If you leave the page, you will not save the changed data.\nSave changes?';
const ALERT_POPUP_BUTTON_SUBMIT = 'Save changes';
const ALERT_POPUP_BUTTON_LEAVE = "Don't save changes";

export const NavigationAlertPopup = props => {
  const {
    when,
    title = ALERT_POPUP_TITLE,
    message = ALERT_POPUP_MESSAGE,
    submitButtonText = ALERT_POPUP_BUTTON_SUBMIT,
    leaveButtonText = ALERT_POPUP_BUTTON_LEAVE,
    onSubmit,
  } = props;
  const classes = useStyles();

  const handleSubmitClick = (onCancel, onConfirm) => async () => {
    try {
      await onSubmit();
      onConfirm();
    } catch (e) {
      onCancel();
    }
  };

  return (
    <NavigationPrompt when={when}>
      {({ isActive, onCancel, onConfirm }) => {
        // onConfirm - confirm the transition
        // onCancel - cancel the transition
        if (isActive) {
          return (
            <Popup
              title={title}
              message={message}
              submitButtonText={submitButtonText}
              cancelText={leaveButtonText}
              onSubmit={handleSubmitClick(onCancel, onConfirm)}
              onClose={onConfirm}
              onReject={onCancel}
              toggleState={() => null}
            >
              <div className={classes.popupInner}>
                <span className={classes.popupText}>{message}</span>
              </div>
            </Popup>
          );
        }
        return null;
      }}
    </NavigationPrompt>
  );
};

NavigationAlertPopup.propTypes = {
  when: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  onSubmit: PropTypes.func,
  submitButtonText: PropTypes.string,
  leaveButtonText: PropTypes.string,
};
