import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValid, format as formatDate } from 'date-fns';

import { ERROR_MESSAGES, FORM_FIELD_TYPE } from 'enums';

import { parseDate } from 'utils/date';
import { preventEmptyString } from 'utils/yupHelpers';
import { preventNullProps } from 'utils/forms';
import { replaceEmptyStringsToNull } from 'utils/forms';
import { toPercentNumber, toCurrencyNumber, convertPercentToCoefficient } from 'utils/numbers';

export const resolver = yupResolver(
  Yup.object({
    maturityDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.invalidDate)
      .nullable()
      .required(ERROR_MESSAGES.required),
    principalAmount: Yup.number()
      .transform(preventEmptyString)
      .required(ERROR_MESSAGES.required)
      .nullable(),
    interestRate: Yup.number().transform(preventEmptyString).nullable(),
    purchasePrice: Yup.number().transform(preventEmptyString).nullable(),
    purchaseYield: Yup.number().transform(preventEmptyString).nullable(),
    adjustedYield: Yup.number().transform(preventEmptyString).nullable(),
    adjustedPrice: Yup.number().transform(preventEmptyString).nullable(),
    cost: Yup.number().required(ERROR_MESSAGES.required).transform(preventEmptyString).nullable(),
    accruedInterest: Yup.number()
      .required(ERROR_MESSAGES.required)
      .transform(preventEmptyString)
      .nullable(),
    adjustedPurchasePrice: Yup.number().transform(preventEmptyString).nullable(),
    actualPurchasePrice: Yup.number().transform(preventEmptyString).nullable(),
    marketDiscount: Yup.number()
      .transform(preventEmptyString)
      .required(ERROR_MESSAGES.required)
      .nullable(),
    annualMarketDiscount: Yup.number().transform(preventEmptyString).nullable(),
  }),
);

export const formFields = [
  { name: 'maturityDate', type: FORM_FIELD_TYPE.date },
  { name: 'principalAmount', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'interestRate', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 3 },
  { name: 'purchasePrice', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 6 },
  { name: 'purchaseYield', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 3 },
  { name: 'adjustedYield', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 3 },
  { name: 'adjustedPrice', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 3 },
  { name: 'cost', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'accruedInterest', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  {
    name: 'adjustedPurchasePrice',
    type: FORM_FIELD_TYPE.decimal,
    placeholder: '$',
    decimalScale: 2,
  },
  { name: 'actualPurchasePrice', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'marketDiscount', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  {
    name: 'annualMarketDiscount',
    type: FORM_FIELD_TYPE.decimal,
    placeholder: '$',
    decimalScale: 2,
  },
];

export const prepareBeforeSending = data => {
  const { maturityDate } = data;
  return replaceEmptyStringsToNull({
    ...data,
    adjustedPrice: convertPercentToCoefficient(data?.adjustedPrice, 3),
    adjustedYield: convertPercentToCoefficient(data?.adjustedYield, 3),
    interestRate: convertPercentToCoefficient(data?.interestRate, 3),
    maturityDate: isValid(maturityDate) ? formatDate(maturityDate, 'yyyy-MM-dd') : null,
    purchasePrice: convertPercentToCoefficient(data?.purchasePrice, 6),
    purchaseYield: convertPercentToCoefficient(data?.purchaseYield, 3),
  });
};

export const prepareBeforeSetting = data => {
  const { maturityDate } = data;
  return {
    ...preventNullProps({
      ...data,
      accruedInterest: toCurrencyNumber(data?.accruedInterest),
      actualPurchasePrice: toCurrencyNumber(data?.actualPurchasePrice),
      adjustedPrice: toPercentNumber(data?.adjustedPrice, 3),
      adjustedPurchasePrice: toCurrencyNumber(data?.adjustedPurchasePrice),
      adjustedYield: toPercentNumber(data?.adjustedYield, 3),
      annualMarketDiscount: toCurrencyNumber(data?.annualMarketDiscount),
      cost: toCurrencyNumber(data?.cost),
      interestRate: toPercentNumber(data?.interestRate, 3),
      marketDiscount: toCurrencyNumber(data?.marketDiscount),
      principalAmount: toCurrencyNumber(data?.principalAmount),
      purchasePrice: toPercentNumber(data?.purchasePrice, 6),
      purchaseYield: toPercentNumber(data?.purchaseYield, 3),
    }),
    maturityDate: parseDate('yyyy-MM-dd')(maturityDate),
  };
};
