import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    main: {
      width: '100%',
      height: 'auto',
      padding: 40,
      background: theme.palette.background.paper,
      border: '1px solid',
      borderColor: theme.palette.athensGray,
      boxSizing: 'border-box',
      boxShadow: 'inset 0px -2px 0px #BEC2CC',
      borderRadius: 2,
    },
    contentForm: {
      maxWidth: 350,
      position: 'relative',
    },
    contentText: {
      fontSize: theme.typography.pxToRem(12),
      opacity: 0.5,
    },
    buttonsContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    loaderWrap: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      zIndex: 1,
      background: 'rgba(255, 255, 255, 0.5)',
    },
  }),
  { name: 'ChangePassword' },
);
