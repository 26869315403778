import { useState } from 'react';

import { TimersConst } from 'const/TimersConst';

import { useTaskStatus, ReportStatusConst } from 'utils/useTaskStatus';

export const useImportPolling = config => {
  const { requestMethod } = config;
  const [error, setError] = useState(null);
  const [taskStatus, setTaskStatus] = useState(ReportStatusConst.IDLE);

  let timerId = null;
  const startImportPolling = async url => {
    setTaskStatus(ReportStatusConst.IDLE);
    try {
      const result = await requestMethod(url);
      const {
        data: { status, errors },
      } = result.data;

      if (status === ReportStatusConst.STARTED) {
        setTaskStatus(ReportStatusConst.STARTED);
        timerId = setTimeout(() => {
          startImportPolling(url);
        }, TimersConst.REQUEST_INTERVAL_TIME);
      }

      if (status === ReportStatusConst.SUCCESS) {
        setTaskStatus(ReportStatusConst.SUCCESS);
        clearTimeout(timerId);
      }

      if (status === ReportStatusConst.FAILURE) {
        clearTimeout(timerId);
        setError(errors);
        setTaskStatus(ReportStatusConst.FAILURE);
      }
    } catch (e) {
      setError(e);
    }
  };

  return {
    startImportPolling,
    isTaskImportStatusSuccess: useTaskStatus(taskStatus).isSuccess,
    isTaskImportStatusFailure: useTaskStatus(taskStatus).isFailure,
    isTaskImportStatusStarted: useTaskStatus(taskStatus).isStarted,
    taskStatus,
    taskError: error,
  };
};
