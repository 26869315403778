import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async import(params) {
    const url = apiRoutes.excelImportTasksPath();
    const formData = FetchHelpers.paramsToFormData(params);
    return FetchHelpers.postMultipartFormData(url, formData, {}, true);
  },
  async getAfterImport(url) {
    return FetchHelpers.getRaw(url);
  },
  async CIBCReport(params) {
    const url = apiRoutes.CIBCReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async moodyReport(params) {
    const url = apiRoutes.moodyReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async sharingArrangementDefeasedLoansReport(organizationId, params) {
    const url = apiRoutes.sharingArrangementDefeasedLoansReportPath(organizationId);
    return FetchHelpers.postLocation(url, params);
  },
  async pricingModelReport(params) {
    const url = apiRoutes.pricingModelReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async buyUpReport(params) {
    const url = apiRoutes.buyUpReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async netsuiteAccountReport(params) {
    const url = apiRoutes.netsuiteAccountReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async dealsInProgressReport(params) {
    const url = apiRoutes.dealsInProgressReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async sideLetterReport(params) {
    const url = apiRoutes.sideLetterReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async consolidatedMasterReports(params) {
    const url = apiRoutes.consolidatedMasterReportsPath();
    return FetchHelpers.postLocation(url, params);
  },
  async propertiesDataDump(params) {
    const url = apiRoutes.propertiesDataDumpPath();
    return FetchHelpers.postLocation(url, params);
  },
  async prpTrackingSheetReport(params) {
    const url = apiRoutes.prpTrackingSheetPath();
    return FetchHelpers.postLocation(url, params);
  },
  async ratingAgencyReport(params) {
    const url = apiRoutes.ratingAgencyReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async maturityTrackingSheetReport(params) {
    const url = apiRoutes.maturityTrackingSheetPath();
    return FetchHelpers.postLocation(url, params);
  },
  async dissolutionFutureProjectionsReport(params) {
    const url = apiRoutes.dissolutionFutureProjectionsReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async dealClosingChecklist(params) {
    const url = apiRoutes.dealClosingChecklistReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async taskReport(location) {
    return FetchHelpers.getRaw(location);
  },
  async excelImportReconciliationInputs(params) {
    const url = apiRoutes.excelImportReconciliationInputsTasksPath();
    const formData = FetchHelpers.paramsToFormData(params);
    return FetchHelpers.postMultipartFormData(url, formData, {}, true);
  },
  async securitiesPercentageReport(params) {
    const url = apiRoutes.securitiesPercentageReportPath();
    return FetchHelpers.postLocation(url, params);
  },
  async fundingRequestReport(params) {
    const url = apiRoutes.reportFundingRequestReportTasksPath();
    return FetchHelpers.postLocation(url, params);
  },
  async journalEntriesReport(params) {
    const url = apiRoutes.reportsJournalEntriesTasksPath();
    return FetchHelpers.postLocation(url, params);
  },
  async freddieFloatPaymentReport() {
    const url = apiRoutes.reportsFreddieFloatPaymentTasksPath();
    return FetchHelpers.postLocation(url);
  },
};
