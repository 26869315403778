import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';

import ListTable from 'components/ListTable';
import DocumentTitle from 'components/DocumentTitle';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';

import { useMembers } from 'hooks/api/useMembers';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { membersColumnConfig } from 'presenters/MemberIndexPresenter';

import { appRoutes } from 'router/routes';

import { getStaffLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Members';

export const Members = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const { loadMembers, membersTableData, areMembersLoading, membersTableKeys } = useMembers();

  const fetchData = async () => {
    try {
      await loadMembers();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const sideBarContent = getStaffLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout>
        <DocumentTitle title={TITLE} />
        <Typography variant="h1">{TITLE}</Typography>
        <div className={classes.actionPanel}>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.staffMemberCreatePath()}
              className={classes.addItem}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Member
            </Button>
          </ActionPanelButtonWrapper>
        </div>
        <ListTable
          detailPath={appRoutes.staffMemberPath}
          isLoading={areMembersLoading}
          tableHeader={membersTableKeys}
          tableData={membersTableData}
          columnConfig={membersColumnConfig}
          isInnerScrollable
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
