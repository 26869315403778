import React, { useMemo, useEffect } from 'react';

import SuccessorBorrowerForm from 'components/SuccessorBorrowerForm';

import { useGetSuccessorBorrowerConfigurationQuery } from 'domain/successorBorrowerConfiguration/apiSlice';
import * as SuccessorBorrowerConfigurationService from 'domain/successorBorrowerConfiguration/service';
import { useCreateSuccessorBorrowerMutation } from 'domain/successorBorrower/apiSlice';

import useRouter from 'hooks/useRouter';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

const NEW_SUCCESSOR_BORROWER_TITLE = 'New Successor Borrower';

const SuccessorBorrowerAdd = () => {
  const { push } = useRouter();

  const { data: configuration, isFetching } = useGetSuccessorBorrowerConfigurationQuery();

  const defaultValues = useMemo(
    () => SuccessorBorrowerConfigurationService.createDefaultValues(configuration),
    [configuration],
  );

  const [
    createSuccessorBorrower,
    { error: successorBorrowerCreateError, isSuccess: isSuccessorBorrowerCreateSuccess },
  ] = useCreateSuccessorBorrowerMutation();

  const previousRoute = appRoutes.successorBorrowersPath();

  const handleSubmitClick = params => createSuccessorBorrower(params);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isSuccessorBorrowerCreateSuccess) push(previousRoute);
  }, [isSuccessorBorrowerCreateSuccess]);

  return (
    <RootLayoutV2>
      <SuccessorBorrowerForm
        isEdit={false}
        defaultSuccessorBorrowerData={defaultValues}
        isDefaultDataLoading={isFetching}
        submitButtonText="Add"
        requestError={successorBorrowerCreateError}
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        previousRoute={previousRoute}
        title={NEW_SUCCESSOR_BORROWER_TITLE}
      />
    </RootLayoutV2>
  );
};

export default SuccessorBorrowerAdd;
