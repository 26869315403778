import React, { useRef, useMemo } from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';
import { LOAN_HASH } from 'src/enums';

import DocumentTitle from 'components/DocumentTitle';
import TableSummary from 'components/TableSummary';
import LoanHeader from 'components/LoanHeader';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import ListTable from 'components/ListTable';

import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';
import { useGetLoanGovernmentSecuritiesQuery } from 'domain/loanGovernmentSecurity/apiSlice';
import * as GovernmentSecurityService from 'domain/loanGovernmentSecurity/service';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import RootLayoutV2 from 'layouts/RootLayoutV2';
import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';
import { isStatusActiveInPayment } from 'utils/layoutStatus';
import { getHeight } from 'utils/getElementHeight';

import useStyles from './useStyles';

const OID_BALANCE = 'OID Balance';

export const LoanGovernmentSecurities = () => {
  const classes = useStyles();

  const header = useRef(null);

  const {
    query: { id },
  } = useRouter();

  const { governmentSecuritiesColumnConfig } = GovernmentSecurityService;

  const {
    data: governmentSecuritiesData,
    error: governmentSecuritiesLoadError,
    isFetching: isGovernmentSecuritiesLoading,
  } = useGetLoanGovernmentSecuritiesQuery(id);

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);

  const governmentSecuritiesTableData = useMemo(
    () => GovernmentSecurityService.getTableData(governmentSecuritiesData),
    [governmentSecuritiesData],
  );
  const governmentSecuritiesTableKeys = GovernmentSecurityService.getTableKeys();
  const governmentSecuritiesSummaryData =
    GovernmentSecurityService.getSummaryTableData(governmentSecuritiesData);

  useHandleRtkQueryErrors(loanLoadError, governmentSecuritiesLoadError);

  const title = LoanService.getDealName(loanData);
  const loanStatus = LoanService.getLoanStatus(loanData);

  const tableOffset = getHeight(header);

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isDataLoading = isLoanLoading || isGovernmentSecuritiesLoading;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isDataLoading}>
      <DocumentTitle title={title} />
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <LoanHeader title={title} ref={header} loanStatus={loanStatus}>
          <div>
            {isStatusActiveInPayment(loanStatus) && (
              <Link
                href={appRoutes.OIDBalanceReportLoanPath(id)}
                color="secondary"
                className={classes.link}
              >
                {OID_BALANCE}
              </Link>
            )}
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.loanEditPath(id)}
              component={NavLink}
            >
              Edit Property
            </Button>
          </div>
        </LoanHeader>
        <ContentLayout>
          <h3 id={LOAN_HASH.details} className={classes.detailTitle}>
            Detail of Government Securities
          </h3>
          <div className={classes.detailWrap}>
            <div className={classes.tableWrap}>
              <ListTable
                className={classes.tableContent}
                detailPath={appRoutes.loanPath}
                tableHeader={governmentSecuritiesTableKeys}
                tableData={governmentSecuritiesTableData}
                columnConfig={governmentSecuritiesColumnConfig}
                fixedTopOffset={tableOffset}
                isOuterScrollable
              />
              <TableSummary summaryData={governmentSecuritiesSummaryData} />
            </div>
          </div>
        </ContentLayout>
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
