import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.sizes.actionPanel.marginBottom,
    },

    search: {
      marginRight: 8,
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
    tableContent: {
      marginTop: 16,
    },
  }),
  { name: 'People' },
);
