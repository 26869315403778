import { useSelector } from 'react-redux';

import { useConfigurationActions } from 'store/SuccessorBorrowersSlice';

export const useSuccessorBorrowers = () => {
  const { configuration, isConfigurationLoading } = useSelector(
    state => state.SuccessorBorrowersSlice,
  );

  const { loadConfiguration } = useConfigurationActions();

  return {
    loadConfiguration,
    configuration,
    isConfigurationLoading,
  };
};
