import { formatCurrency } from 'utils/numbers';

export const getNestedTableData = data => ({
  members: data.members.map(member => ({
    ...member,
    reportValue: formatCurrency(member.memberInterestAccrual),
    successorBorrowers: member.successorBorrowers.map(sb => ({
      ...sb,
      reportValue: formatCurrency(sb.successorBorrowerInterestAccrual),
      loans: sb.loans.map(loan => ({
        ...loan,
        reportValue: formatCurrency(loan.loanInterestAccrual),
      })),
    })),
  })),
});
