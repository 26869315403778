import React, { createContext } from 'react';

export const EditableTableControlContext = createContext();

export const EditableTableProvider = props => {
  const { children, ...rest } = props;
  return (
    <EditableTableControlContext.Provider value={rest}>
      {children}
    </EditableTableControlContext.Provider>
  );
};
