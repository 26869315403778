import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    gridLayout: {
      display: 'grid',
      gridTemplateColumns: 'auto minmax(0, 1fr)',
      gridTemplateRows: `${theme.sizes.header.height}px
        minmax(0, calc(100vh - ${theme.sizes.header.height}px))`,
      gridTemplateAreas: "'header header' 'sidebar main'",
      maxHeight: '100vh',
    },
    main: {
      gridArea: 'main',
      backgroundColor: theme.palette.linkWater,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    },
    sidebar: {
      gridArea: 'sidebar',
    },
  }),
  { name: 'RootLayoutV2' },
);
