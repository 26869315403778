import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    inputLabel: {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(19,51,76, 0.4)',
      marginBottom: '8px',
    },
    monthInput: {
      maxWidth: '320px',
      marginRight: 'auto',
      marginLeft: 'auto',
      marginBottom: '24px',
    },
    error: {
      color: theme.palette.coralRed,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(14),
    },
  }),
  { name: 'MonthInput' },
);
