import { createSlice } from '@reduxjs/toolkit';
import LiveLoansRepository from 'repositories/LiveLoansRepository';

import { createRestSlice } from 'utils/RestSlice';

const liveLoansRestSlice = createRestSlice({
  resource: 'liveLoan',
  repository: LiveLoansRepository,
  slices: ['updateLiveLoan'],
});

const initialState = {
  ...liveLoansRestSlice.initialState,
};

const liveLoansSlice = createSlice({
  name: 'liveLoans',
  initialState,
  reducers: {
    ...liveLoansRestSlice.reducers,
  },
});

export default liveLoansSlice.reducer;
export const liveLoansRestHooks = liveLoansRestSlice.hooks(liveLoansSlice, 'LiveLoansSlice');
