/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch } from 'react-redux';
import { createSlice } from '@reduxjs/toolkit';
import LoansRepository from 'repositories/LoansRepository';

import { createRestSlice } from 'utils/RestSlice';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const loansRestSlice = createRestSlice({
  resource: 'loan',
  repository: LoansRepository,
  slices: ['loadLoan'],
});

const loanPartialUpdate = {
  partialUpdatingStatus: FETCH_STATUSES.idle,
};

const loansState = {
  loan: { ...loanPartialUpdate },
};

const initialState = {
  ...loansState,
};

const loanPartialUpdateReducers = {
  startLoanPartialUpdate(state) {
    state.loan.partialUpdatingStatus = FETCH_STATUSES.pending;
    state.loan.errors = null;
  },
  loanPartialUpdateFulfilled(state) {
    state.loan.partialUpdatingStatus = FETCH_STATUSES.fulfilled;
  },
  loanPartialUpdateRejected(state, { payload }) {
    state.loan.partialUpdatingStatus = FETCH_STATUSES.rejected;
    state.loan.errors = payload;
  },
};

const loansSlice = createSlice({
  name: 'loans',
  initialState,
  reducers: {
    ...loansRestSlice.reducers,
    ...loanPartialUpdateReducers,
  },
});

export default loansSlice.reducer;
export const loansRestHooks = loansRestSlice.hooks(loansSlice, 'LoansSlice');

export const useLoansPartialUpdate = () => {
  const dispatch = useDispatch();

  const makeLoanPartialUpdate = async (id, params) => {
    dispatch(loansSlice.actions.startLoanPartialUpdate());

    try {
      await LoansRepository.loansPartialUpdate(id, params);
      dispatch(loansSlice.actions.loanPartialUpdateFulfilled());
    } catch (e) {
      dispatch(loansSlice.actions.loanPartialUpdateRejected(e));
      throw e;
    }
  };

  return {
    makeLoanPartialUpdate,
  };
};
