import React from 'react';

import PropTypes from 'prop-types';

import FormField from 'components/FormField';

import AddressPresenter from 'presenters/AddressPresenter';

const FormAddress = ({ fields, errors, values, selectValues }) => (
  <FormField fieldArray={fields} errors={errors} values={values} selectValues={selectValues} />
);

FormAddress.propTypes = {
  fields: PropTypes.arrayOf(AddressPresenter.shape()),
  errors: PropTypes.shape({}),
  values: PropTypes.shape({}),
  selectValues: PropTypes.shape({}),
};

export default FormAddress;
