import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  rowContainer: {
    width: '100%',
    minWidth: '200px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexFlow: 'row nowrap',
    marginBottom: 14,
  },
  inputWrap: {
    width: '100%',
  },
  option: {
    padding: '0 0 0 16px',
    justifyContent: 'start',
    height: '100%',
  },
  listbox: {
    maxHeight: '60vh',
  },
  listBoxItem: {
    marginTop: '6px',
    marginBottom: '6px',
  },
  formLabel: {
    color: theme.palette.primary.main,
    opacity: 0.5,
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: '0.2px',
    minWidth: 280,
    textAlign: 'left',
  },
  filterInput: {
    width: '100%',
  },
  loader: {
    marginTop: '-10px',
    width: '30px',
  },
  requiredMark: {
    color: 'red',
  },
  sentry: {
    height: '5px',
  },
}));
