import palette from '../palette';

export default {
  MuiSelect: {
    root: {
      width: '100%',
      background: palette.white,
      boxSizing: 'border-box',
      borderRadius: 3,
      fontSize: 14,
      lineHeight: '20px',
      color: palette.primary.main,
      padding: 0,
      opacity: 1,
      '&:focus': {
        backgroundColor: palette.white,
        opacity: 1,
      },
      '&$focused': {
        backgroundColor: palette.white,
        opacity: 1,
      },
    },
    select: {
      '&:focus': {
        backgroundColor: palette.white,
      },
    },
    input: {
      color: 'red',
    },
  },
};
