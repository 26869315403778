import React, { useEffect, useMemo } from 'react';

import { Typography, Button } from '@material-ui/core';
import OIDBalanceRepository from 'repositories/OIDBalanceRepository';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';
import { useErrors } from 'hooks/useErrors';
import { useSnackbar } from 'hooks/useSnackbar';

import ContentLayout from 'layouts/ContentLayout';

import ReportTable from 'pages/OIDBalanceReport/components/ReportTable';

import useStyles from './useStyles';

const BUTTON_TEXT = 'Recalculate Report';
const TITLE = 'OID Balance Report';

export const OIDBalanceReport = () => {
  const {
    createOIDBalanceLongPollingWorker,
    isOIDBalanceReportLoading,
    isOIDBalanceReportFulfilled,
    OIDBalanceReportData,
    clearOIDBalanceReportCache,
  } = useOIDBalanceReports();

  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const loadOIDBalanceReport = useMemo(
    () => createOIDBalanceLongPollingWorker(OIDBalanceRepository.OIDBalanceReportTask),
    [],
  );

  const { showErrorNotification } = useSnackbar();

  const showCalculationErrors = () => {
    OIDBalanceReportData.loanIdsWithCalculationWarning.forEach(loanWithError =>
      showErrorNotification(loanWithError.message),
    );
  };

  const fetchData = async () => {
    try {
      await loadOIDBalanceReport();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  const handleRecalculateCacheButtonClick = async () => {
    await OIDBalanceRepository.OIDBalanceReportClearCachePath();
    fetchData();
  };

  useEffect(() => {
    fetchData();
    return () => clearOIDBalanceReportCache();
  }, []);

  useEffect(() => {
    if (isOIDBalanceReportFulfilled) {
      showCalculationErrors();
    }
  }, [isOIDBalanceReportFulfilled]);

  return (
    <ContentLayout className={classes.reportWrap} isSmall>
      <DocumentTitle title={TITLE} />
      <div className={classes.headerWrap}>
        <Typography variant="h1">{TITLE}</Typography>
        <Button
          color="primary"
          className={classes.recalculateButton}
          onClick={handleRecalculateCacheButtonClick}
          disabled={isOIDBalanceReportLoading}
        >
          {BUTTON_TEXT}
        </Button>
      </div>
      {isOIDBalanceReportLoading ? <Loader /> : <ReportTable report={OIDBalanceReportData} />}
    </ContentLayout>
  );
};
