import React from 'react';

import { NAV_CONFIG } from 'router';
import { OrganizationSideNav } from 'components/OrganizationSideNav/OrganizationSideNav';

import SideNav from 'components/SideNav';
import LoansSideNav from 'components/LoansSideNav';

import { useGetOptionsQuery } from 'domain/options/apiSlice';

import { NAV_TYPES } from 'enums';

import { useAdministrativeAgents, useManagers, usePeople } from 'hooks/api';
import useRouter from 'hooks/useRouter';
import { useMembers } from 'hooks/api/useMembers';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import {
  accountSettingsLinks,
  administrativeAgentCRUDLinks,
  liveFormsLinks,
  memberCRUDLinks,
  managerCRUDLinks,
  partiesLinks,
  personEditLinks,
} from 'utils/routes';

const getNavTypeByPath = (pathname, match) => {
  const navEntries = Object.entries(NAV_CONFIG);

  const foundType = navEntries.find(entry => {
    const routes = entry[1];
    const foundRoute = routes.some(route => {
      const matched = match({ path: route, url: pathname });
      return matched?.isExact;
    });
    return foundRoute;
  });

  return foundType[0];
};

const useSideNavContent = () => {
  const { pathname, match } = useRouter();
  const navType = getNavTypeByPath(pathname, match);
  const { person } = usePeople();
  const { member } = useMembers();
  const { administrativeAgent } = useAdministrativeAgents();
  const { manager } = useManagers();
  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());

  switch (navType) {
    case NAV_TYPES.successorBorrowerCRUD: {
      return {
        markup: null,
      };
    }

    case NAV_TYPES.loans: {
      return {
        title: 'Loans',
        markup: <LoansSideNav />,
      };
    }

    case NAV_TYPES.parties: {
      return {
        title: 'Parties',
        markup: <SideNav links={partiesLinks(options)} />,
      };
    }

    case NAV_TYPES.personCRUD: {
      return {
        title: 'People',
        markup: <SideNav links={personEditLinks(person?.id)} />,
      };
    }

    case NAV_TYPES.liveForms: {
      return {
        title: 'Live Forms',
        markup: <SideNav links={liveFormsLinks()} />,
      };
    }

    case NAV_TYPES.organizationDetail: {
      return {
        markup: <OrganizationSideNav />,
      };
    }

    case NAV_TYPES.accountSettings: {
      return {
        title: 'Account Settings',
        markup: <SideNav links={accountSettingsLinks()} />,
      };
    }

    case NAV_TYPES.memberCRUD: {
      return {
        title: 'Members',
        markup: <SideNav links={memberCRUDLinks(member?.id)} />,
      };
    }

    case NAV_TYPES.administrativeAgentCRUD: {
      return {
        title: 'Administrative Agents',
        markup: <SideNav links={administrativeAgentCRUDLinks(administrativeAgent?.id)} />,
      };
    }

    case NAV_TYPES.managerCRUD: {
      return {
        title: 'Managers',
        markup: <SideNav links={managerCRUDLinks(manager?.id)} />,
      };
    }

    default:
      return null;
  }
};

export default useSideNavContent;
