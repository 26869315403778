import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ERROR_MESSAGES } from 'enums';

export const resolver = yupResolver(
  Yup.object({
    reconciliationYearMonth: Yup.date()
      .nullable()
      .typeError(ERROR_MESSAGES.date.monthYearFormat)
      .required(ERROR_MESSAGES.date.requiredDate),
  }),
);

export const formField = {
  displayedName: 'Report Month',
  fieldKey: 'reconciliationYearMonth',
};
