import React from 'react';

import PropTypes from 'prop-types';

import Loader from 'components/Loader';

import { useScrollingContext } from 'hooks/useScrolling';

import useStyles from './useStyles';

export const Main = props => {
  const { children, isLoading, className } = props;
  const { scrollContainerRef } = useScrollingContext();
  const classes = useStyles();

  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <main className={className} ref={scrollContainerRef}>
      {children}
    </main>
  );
};

Main.propTypes = {
  isLoading: PropTypes.bool,
  className: PropTypes.string,
};
