import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async login(params) {
    const url = apiRoutes.loginPath();
    return FetchHelpers.post(url, params);
  },
  async logout() {
    const url = apiRoutes.loginPath();
    return FetchHelpers.delete(url);
  },
  async fafLogin(params) {
    const url = apiRoutes.fafTokensPath();
    return FetchHelpers.post(url, params);
  },
};
