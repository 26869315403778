import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params) {
    const url = apiRoutes.remindersPath();
    return FetchHelpers.get(url, params);
  },
  async update(id, params) {
    const url = apiRoutes.successorBorrowerPath(id);
    return FetchHelpers.patch(url, params);
  },
};
