import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import useStyles from 'components/MembersNestedList/useStyles';

import LoansList from './components/LoansList';
import SBSummary from './components/SBSummary';

export const SuccessorBorrowersNestedList = props => {
  const classes = useStyles();
  const { successorBorrowers, detailsLinks } = props;
  return successorBorrowers.map((sb, index) => {
    const { loans } = sb;
    return (
      <div className={classes.root} key={`l2_${index}`}>
        <Accordion square>
          <AccordionSummary
            className={clsx(classes.summary, classes.sbSummaryPadding)}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            classes={{
              content: classes.accordionSummaryContent,
              expanded: classes.accordionExpanded,
              expandIcon: classes.accordionExpandIcon,
            }}
          >
            <SBSummary successorBorrower={sb} detailsLinks={detailsLinks} />
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <LoansList loans={loans} detailsLinks={detailsLinks} />
          </AccordionDetails>
        </Accordion>
      </div>
    );
  });
};

SuccessorBorrowersNestedList.propTypes = {
  successorBorrowers: arrayOf(
    PropTypes.shape({
      successorBorrowerId: PropTypes.number,
      successorBorrowerName: PropTypes.string,
      reportValue: PropTypes.string,
      loans: arrayOf(PropTypes.shape({})),
    }),
  ),
  detailLinks: PropTypes.shape({
    successorBorrowerDetailsLink: PropTypes.shape({
      path: PropTypes.func,
      linkName: PropTypes.string,
    }),
    loanDetailsLink: PropTypes.shape({ path: PropTypes.func, linkName: PropTypes.string }),
  }),
};
