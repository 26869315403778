import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { isNil, isEmpty } from 'ramda';
import { List, ListItem, Typography, Link, Paper } from '@material-ui/core';
import { OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import clsx from 'clsx';

import { appRoutes } from 'router/routes';

import { formatCurrency } from 'utils/numbers';

import useStyles from './useStyles';

const VIEW_DETAILS = 'View Details';
const NO_DATA_MESSAGE = 'No discrepancies found';

export const ReportList = props => {
  const { report } = props;
  const classes = useStyles();

  if (isNil(report)) return null;

  if (isEmpty(report))
    return <Typography className={clsx(classes.listItemFont)}>{NO_DATA_MESSAGE}</Typography>;

  return (
    <Paper elevation={2} className={classes.listContainer}>
      <List className={classes.list}>
        {report.map(item => {
          const { loanId, loanName, reconciliationDiscrepancy } = item;
          return (
            <ListItem divider className={classes.listItem} key={loanId}>
              <div>
                <Typography className={clsx(classes.listItemFont, classes.wordBreak, classes.name)}>
                  {`(${loanId}) ${loanName}`}
                </Typography>
                <Link
                  href={appRoutes.loanReconciliationInputsPath(loanId)}
                  color="secondary"
                  className={clsx(classes.listItemFont, classes.link)}
                  target="_blank"
                >
                  <span>
                    {VIEW_DETAILS}
                    <OpenInNewIcon className={classes.openInNewIcon} />
                  </span>
                </Link>
              </div>
              <Typography className={clsx(classes.listItemFont, classes.value)}>
                {formatCurrency(reconciliationDiscrepancy)}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
};

ReportList.propTypes = {
  report: arrayOf(
    PropTypes.shape({
      dealName: PropTypes.string,
      id: PropTypes.number,
    }),
  ),
};
