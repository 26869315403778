import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { not, isNil } from 'ramda';

import { useFundingNotes } from 'hooks/api';
import { useEditableTableContext } from 'hooks/useEditableTableControls';

import { prepareBeforeSending } from 'pages/FundingNotes/validation';

import { setServerErrorsToFields } from 'utils/errors';

import DataRow from './components/DataRow';
import FormRow from './components/FormRow';

export const CommonTableRow = props => {
  const { row, isForm = false, isCreating, onEdit, reloadData, handleDeleteLineClick } = props;

  const { errors, setError } = useFormContext();
  const { createFundingNote, updateFundingNote } = useFundingNotes();

  const { hideCreatingForm, hideEditableRow } = useEditableTableContext();

  const [serverErrors, setServerErrors] = useState(null);

  useEffect(() => {
    if (not(isNil(serverErrors))) {
      setServerErrorsToFields(setError, errors, serverErrors);
    }
  }, [serverErrors]);

  const handleInputSubmit = isCreatingMode => async data => {
    const preparedData = prepareBeforeSending(data);
    try {
      if (isCreatingMode) {
        await createFundingNote({
          ...preparedData,
        });
        hideCreatingForm();
      } else {
        const fundingNoteId = row.id;
        await updateFundingNote(fundingNoteId, {
          ...preparedData,
        });
        hideEditableRow();
      }
      reloadData();
    } catch (e) {
      setServerErrors(e);
    }
  };

  const handleCancel = () => {
    hideCreatingForm();
    hideEditableRow();
  };

  return isForm ? (
    <FormRow
      onSubmit={handleInputSubmit}
      onCancel={handleCancel}
      isCreating={isCreating}
      row={row}
    />
  ) : (
    <DataRow row={row} onDelete={handleDeleteLineClick} onEdit={onEdit} reloadData={reloadData} />
  );
};

CommonTableRow.propTypes = {
  isForm: PropTypes.bool,
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({}),
  }),
  isCreating: PropTypes.bool,
  onEdit: PropTypes.func,
  reloadData: PropTypes.func,
  handleDeleteLineClick: PropTypes.func,
};
