import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { isNil } from 'ramda';
import { Typography } from '@material-ui/core';

import MembersNestedList from 'components/MembersNestedList';

import { getNestedTableData } from 'presenters/OIDBalanceReportPresenter';

import { appRoutes } from 'router/routes';

import { createDetailsLink } from 'utils/links';

import useStyles from './useStyles';

const HEADER_TEXT = 'Corporate';
const OID_OFF_BY = 'OID of by';
const SUCCESSOR_BORROWER_LINK_NAME = 'View Report';
const LOAN_LINK_NAME = 'View Report';
const MEMBER_LINK_NAME = 'Corporate Report';

export const ReportTable = props => {
  const { report } = props;
  const classes = useStyles();

  if (isNil(report)) return null;

  const tableData = getNestedTableData(report);
  const { members, reportValue } = tableData;
  return (
    <div className={classes.listWrapper}>
      <div className={classes.listHeader}>
        <Typography className={classes.header}>{HEADER_TEXT}</Typography>
        <div>
          <Typography className={classes.smallText}>{OID_OFF_BY}</Typography>
          <Typography className={classes.fontBold}>{reportValue}</Typography>
        </div>
      </div>
      <MembersNestedList
        members={members}
        memberDetailsLink={createDetailsLink(
          appRoutes.OIDBalanceReportMembersPath,
          MEMBER_LINK_NAME,
        )}
        successorBorrowerDetailsLink={createDetailsLink(
          appRoutes.OIDBalanceReportSuccessoBorrowerPath,
          SUCCESSOR_BORROWER_LINK_NAME,
        )}
        loanDetailsLink={createDetailsLink(appRoutes.OIDBalanceReportLoanPath, LOAN_LINK_NAME)}
      />
    </div>
  );
};

ReportTable.propTypes = {
  report: PropTypes.shape({
    members: arrayOf(PropTypes.shape()),
  }),
};
