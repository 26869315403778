import { useEffect, useState, useMemo } from 'react';

import FileSaver from 'file-saver';

import { useLazyGetHelpFileQuery } from 'domain/helpFile/apiSlice';

import { FETCH_STATUSES } from 'enums';

import { useSnackbar } from 'hooks/useSnackbar';

import FetchHelpers from 'utils/FetchHelpers';
import { useFetchStatus as checkFetchStatus } from 'utils/fetchStatusUtils';
import { getNameFromUrl } from 'utils/url';

const ERROR_MESSAGE = 'An error occurred. Please try again later.';

export const useGetHelpFile = () => {
  const [status, setStatus] = useState(FETCH_STATUSES.idle);
  const [error, setError] = useState(null);

  const { showErrorNotification } = useSnackbar();

  const [getHelpFile, { data: helpFileData, error: helpFileLoadError }] = useLazyGetHelpFileQuery();

  const downloadAndSaveHelpFileData = async url => {
    try {
      const response = await FetchHelpers.downloadBlobData(url);
      const name = getNameFromUrl(response.config.url);
      const { data } = response;
      const type = response.headers['content-type'];
      const content = new Blob([data], { type });
      FileSaver.saveAs(content, name);
      setStatus(FETCH_STATUSES.fulfilled);
    } catch (e) {
      setError(e);
      setStatus(FETCH_STATUSES.failed);
    }
  };

  useEffect(() => {
    if (helpFileData) downloadAndSaveHelpFileData(helpFileData.documentation);
  }, [helpFileData]);

  useEffect(() => {
    if (helpFileLoadError) {
      setError(helpFileLoadError);
      setStatus(FETCH_STATUSES.failed);
    }
  }, [helpFileLoadError]);

  useEffect(() => {
    if (error) showErrorNotification(ERROR_MESSAGE);
  }, [error]);

  const loadHelpFile = () => {
    setStatus(FETCH_STATUSES.pending);
    getHelpFile();
  };

  return useMemo(() => [loadHelpFile, { ...checkFetchStatus(status) }], [status]);
};
