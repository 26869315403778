import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    header: {
      backgroundColor: theme.palette.linkWater,
      position: 'sticky',
      top: 0,
      paddingTop: '32px',
      paddingBottom: '16px',
      width: '100%',
      zIndex: 2,
    },
    contentContainer: {
      position: 'sticky',
      left: '24px',
      width: 'calc(100vw - 64px)',
    },
    horizontalPlacement: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    showedSideBar: {
      width: `calc(100vw - (${theme.sizes.sidebar.width}px + 64px))`,
    },
    title: {
      marginTop: 11,
    },
  }),
  { name: 'PageHeader' },
);
