import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    formRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: 14,
    },
    formLabel: {
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: '0.2px',
      minWidth: 280,
      textAlign: 'left',
    },
    inputWrap: {
      width: '100%',
    },
    formInput: {
      height: 36,
      width: '100%',
      opacity: 1,
      backgroundColor: theme.palette.white,
      '&:focus': {
        border: [2, 'solid', theme.palette.secondary.main],
      },
    },
    menuItem: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 36,
      },
    },
    requiredMark: {
      color: 'red',
    },
  }),
  { name: 'FormRowSelect' },
);
