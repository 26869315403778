import PropTypes from 'prop-types';

import { StatusDisplayedNameByCode } from 'const';

import { FORM_FIELD_TYPE, STATUS_COLUMN } from 'enums';

import Presenter, { shortDescriptionModel } from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { getConfigurationValue } from 'utils/objects';
import { getBankAccountTypeNames, getBankAccountTypes } from 'utils/getBankAccountType';

export const bankAccountInformationLabels = {
  number: 'Account Number',
  establishedDate: 'Date Established',
  status: 'Status',
  accountType: 'Type',
  provider: 'Provider',
};

export default new Presenter(
  {
    name: PropTypes.string,
    number: PropTypes.string,
    accountType: PropTypes.string,
    establishedDate: PropTypes.string,
    id: PropTypes.number,
    provider: shortDescriptionModel,
    status: PropTypes.string,
  },
  {
    tableData(data) {
      return {
        ...data,
        establishedDate: formatDate(data?.establishedDate),
        provider: data?.provider?.name,
        status: StatusDisplayedNameByCode[data?.status],
        accountType: getBankAccountTypeNames()[data?.type],
      };
    },
    defaultValues(configuration = {}) {
      return {
        establishedDate: getConfigurationValue(configuration, 'establishedDate'),
        id: getConfigurationValue(configuration, 'id'),
        name: getConfigurationValue(configuration, 'name'),
        number: getConfigurationValue(configuration, 'number'),
        provider: getConfigurationValue(configuration, 'provider'),
        accountType: getBankAccountTypes().operating,
        status: STATUS_COLUMN.active,
      };
    },
    formFields(props) {
      const { prefix, titleName, isRequired = false } = props;
      return [
        {
          fieldKey: `${prefix}.name`,
          displayedName: titleName,
          type: FORM_FIELD_TYPE.input,
          isRequired,
        },
        {
          fieldKey: `${prefix}.number`,
          displayedName: 'Account Number',
          type: FORM_FIELD_TYPE.input,
          isRequired,
        },
        {
          fieldKey: `${prefix}.establishedDate`,
          displayedName: 'Date Established',
          type: FORM_FIELD_TYPE.date,
        },
        {
          fieldKey: `${prefix}.status`,
          displayedName: 'Status',
          type: FORM_FIELD_TYPE.enum,
          isRequired,
          options: [
            {
              key: STATUS_COLUMN.active,
              displayedName: 'Active',
              value: STATUS_COLUMN.active,
            },
            {
              key: STATUS_COLUMN.inactive,
              displayedName: 'Inactive',
              value: STATUS_COLUMN.inactive,
            },
          ],
        },
        {
          fieldKey: `${prefix}.accountType`,
          displayedName: 'Account Type',
          type: FORM_FIELD_TYPE.enum,
          isRequired,
          options: [
            {
              key: getBankAccountTypes().operating,
              displayedName: 'Operating',
              value: getBankAccountTypes().operating,
            },
            {
              key: getBankAccountTypes().securities,
              displayedName: 'Securities',
              value: getBankAccountTypes().securities,
            },
            {
              key: getBankAccountTypes().trustee,
              displayedName: 'Trustee',
              value: getBankAccountTypes().trustee,
            },
          ],
        },
        {
          fieldKey: `${prefix}.provider`,
          displayedName: 'Provider',
          type: FORM_FIELD_TYPE.select,
          selectValuesType: 'providers',
          placeholder: 'Select Provider',
        },
      ];
    },
    formFieldsShape() {
      return PropTypes.arrayOf(
        PropTypes.shape({
          fieldKey: PropTypes.string,
          displayedName: PropTypes.string,
          type: PropTypes.string,
          options: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string,
              displayedName: PropTypes.string,
              value: PropTypes.string,
            }),
          ),
        }),
      );
    },
  },
);
