import React from 'react';

import PropTypes from 'prop-types';
import { Button, Paper } from '@material-ui/core/';

import TextInputCell from 'pages/LoanReconciliationInputs/components/TextInputCell';
import useStyles from 'pages/LoanReconciliationInputs/useStyles';

const CANCEL_BUTTON = 'Cancel';
const SAVE_COMMENT_BUTTON = 'Save';

export const Comment = props => {
  const { onSave, onCancel } = props;
  const classes = useStyles();
  return (
    <Paper className={classes.card} elevation={1}>
      <TextInputCell
        name="comment"
        childrenProps={{
          classes: { root: classes.commentInput },
          placeholder: 'Comment',
          variant: 'standard',
        }}
      />
      <div className={classes.buttonsPopup}>
        <Button
          onClick={onSave}
          variant="contained"
          color="secondary"
          className={classes.whiteText}
        >
          {SAVE_COMMENT_BUTTON}
        </Button>
        <Button onClick={onCancel}>{CANCEL_BUTTON}</Button>
      </div>
    </Paper>
  );
};

Comment.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};
