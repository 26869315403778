import { LOANS_STATUSES } from 'const';

import { STATUS_COLUMN } from 'enums';

export const isStatusActive = status =>
  status?.toLowerCase() === STATUS_COLUMN.active.toLowerCase() ||
  status?.toLowerCase() === LOANS_STATUSES.activeInPayment;

export const isStatusInactive = status =>
  status?.toLowerCase() === STATUS_COLUMN.inactive.toLowerCase() ||
  status?.toLowerCase() === LOANS_STATUSES.inactive;

export const isStatusActivePaid = status => status?.toLowerCase() === LOANS_STATUSES.activePaid;

export const isStatusActiveInPayment = status =>
  status?.toLowerCase() === LOANS_STATUSES.activeInPayment;
