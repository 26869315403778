export const COLORS = {
  alto: '#D5D5D5',
  athensGray2: '#FAFBFC',
  athensGray: '#EFF2F5',
  blackSqueeze: '#EDF2F9',
  blueBayoux2: '#4D6578',
  blueBayoux: '#4E6679',
  cadetBlue: '#B5BBC6',
  capeCod: '#383B3D',
  catskillWhite: '#EAEFF5',
  catskillWhiteThree: '#F4F7FA',
  catskillWhiteTwo: '#ECF1F5',
  cerulean: '#00A3E0',
  chablis: '#FFEFEF',
  coralRed: '#FD3C3C',
  crimson: '#E41515',
  elephant: '#13334C',
  emerald: '#38C172',
  fairPink: '#FFE8E8',
  feta: '#E6F8D7',
  ginFizz: '#FFF8E1',
  inactiveStatusBackground:
    'linear-gradient(0deg, rgba(255, 233, 155, 0.3), rgba(255, 233, 155, 0.3)), #FBFCFE',
  linkWater: '#FBFCFE',
  mandy: '#EB5050',
  mercury: '#E5E5E5',
  osloGray: '#858C94',
  porcelain: '#F3F5F6',
  santasGray: '#9699AE',
  slateGray: '#6B778C',
  tara: '#E1F6EA',
  tuna: '#383A43',
  white: '#FFFFFF',
};

export default {
  primary: {
    main: COLORS.elephant,
  },
  secondary: {
    main: COLORS.cerulean,
  },
  neutral: {
    light: COLORS.cadetBlue,
  },
  ...COLORS,
};
