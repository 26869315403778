import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from 'components/MembersNestedList/useStyles';

export const SBSummary = props => {
  const classes = useStyles();
  const {
    successorBorrower: { successorBorrowerName, reportValue, successorBorrowerId, filingRef },
    detailsLinks: { successorBorrowerDetailsLink },
  } = props;

  const handleLinkClick = e => e.stopPropagation();

  return (
    <div className={classes.summaryWrap}>
      <div>
        <Typography className={classes.fontNormal}>
          {`(${filingRef}) ${successorBorrowerName}`}
        </Typography>
        <Link
          className={classes.fontLink}
          color="secondary"
          href={successorBorrowerDetailsLink.path(successorBorrowerId)}
          onClick={handleLinkClick}
        >
          {successorBorrowerDetailsLink.linkName}
        </Link>
      </div>
      <Typography className={clsx(classes.balance, classes.fontNormal)}>{reportValue}</Typography>
    </div>
  );
};

SBSummary.propTypes = {
  successorBorrower: PropTypes.shape({
    successorBorrowerId: PropTypes.number,
    successorBorrowerName: PropTypes.string,
    reportValue: PropTypes.string,
    loans: arrayOf(PropTypes.shape({})),
    filingRef: PropTypes.string,
  }),
  detailsLinks: PropTypes.shape({
    successorBorrowerDetailsLink: PropTypes.shape({
      path: PropTypes.func,
      linkName: PropTypes.string,
    }),
    loanDetailsLink: PropTypes.shape({ path: PropTypes.func, linkName: PropTypes.string }),
  }),
};
