import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { isNil } from 'ramda';

import useStyles from './useStyles';

const TableSummary = props => {
  const { summaryData, className } = props;
  const classes = useStyles();

  if (isNil(summaryData)) return null;

  return (
    <div className={clsx(classes.wrapper, className)}>
      {summaryData.map((summaryRow, index) => (
        <div key={`val_${index}`} className={classes.row}>
          <span className={classes.label}>{summaryRow.label}</span>
          <span className={classes.value}>{summaryRow.value}</span>
        </div>
      ))}
    </div>
  );
};

TableSummary.propTypes = {
  summaryData: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
};

export default TableSummary;
