import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import clsx from 'clsx';

import FormError from 'components/FormError';
import HiddenInput from 'components/HiddenInput';

import { getFormFieldValue } from 'utils/getFormFieldValue';

import useStyles from './useStyles';

const REQUIRED = ' *';

const FormRowEnum = props => {
  const { field, errors, options, values, isRequired } = props;
  const classes = useStyles();

  const ref = useRef();
  const { control } = useFormContext();
  const { fieldKey, displayedName } = field;

  const formFieldValue = getFormFieldValue(fieldKey, values);

  const [radioButtonValue, setRadioButtonValue] = useState(formFieldValue);

  const isActive = value =>
    value?.toString().toLowerCase() === radioButtonValue?.toString().toLowerCase();

  const handleChange = event => {
    setRadioButtonValue(event.target.value);
  };

  const handleFocus = () => {
    ref.current.focus();
  };

  return (
    <div
      className={clsx(classes.formRow, {
        [classes.divider]: field.hasMargin,
      })}
      key={fieldKey}
    >
      <legend className={classes.formLabel}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </legend>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name={fieldKey}
          defaultValue={radioButtonValue}
          onFocus={handleFocus}
          as={
            <RadioGroup aria-label={fieldKey}>
              {options.map(option => (
                <FormControlLabel
                  key={`${fieldKey}_${option.value}`}
                  className={clsx(classes.radioButtonLabel, {
                    [classes.activeRadioButtonLabel]: isActive(option.value),
                  })}
                  value={option.value}
                  control={<Radio onChange={handleChange} />}
                  label={option.displayedName}
                />
              ))}
              <HiddenInput readOnly parentName={fieldKey} inputRef={ref} />
            </RadioGroup>
          }
        />
        <div className={classes.enumError}>
          <ErrorMessage
            errors={errors}
            name={fieldKey}
            render={({ message }) => <FormError message={message} />}
          />
        </div>
      </FormControl>
    </div>
  );
};

FormRowEnum.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
  }),
  errors: PropTypes.shape({}),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayedName: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
  ),
  values: PropTypes.shape({}),
  isRequired: PropTypes.bool,
};

export default FormRowEnum;
