import React from 'react';

import PageNotFoundMessage from 'components/PageNotFoundMessage';

import RootLayoutV2 from 'layouts/RootLayoutV2';

export const PageNotFound = () => (
  <RootLayoutV2>
    <PageNotFoundMessage />
  </RootLayoutV2>
);
