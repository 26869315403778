import React, { useEffect, useMemo } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button } from '@material-ui/core';
import { tail } from 'ramda';
import humps from 'humps';

import Header from 'components/Header';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';
import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import { getOrganizationsTableData, getFilteredTableData } from 'domain/organization/service';
import { useLazyGetOrganizationsQuery } from 'domain/organization/apiSlice';

import useRouter from 'hooks/useRouter';
import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { columnConfig } from 'presenters/OrganizationIndexPresenter';

import { appRoutes } from 'router/routes';

import { getOrganizationTypes } from 'utils/getOrganizationsType';
import { getOptionsQueryParams } from 'utils/rtkQuery';

export const Organizations = () => {
  const router = useRouter();
  const { displayErrorsInToast } = useErrors();
  const { elementRef, elementHeight } = useRefElementHeight();
  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());

  const organizationsKey = humps.camelize(tail(router.location.hash));
  const isTypeExists = !!getOrganizationTypes(options)[organizationsKey];

  const [
    getOrganizations,
    { data: organizations, isFetching: isOrganizationsLoading, error: organizationsLoadError },
  ] = useLazyGetOrganizationsQuery();

  const organizationsTableData = getOrganizationsTableData(organizations);

  useEffect(() => {
    if (organizationsLoadError) displayErrorsInToast([organizationsLoadError]);
  }, [organizationsLoadError]);

  useEffect(() => {
    if (!isTypeExists) router.replace(appRoutes.partiesOrganizationsPath());
    getOrganizations();
  }, []);

  const tableData = isTypeExists
    ? getFilteredTableData(getOrganizationTypes(options)[organizationsKey], organizations)
    : organizationsTableData;

  const title = useMemo(
    () =>
      isTypeExists
        ? `${getOrganizationTypes(options)[organizationsKey]} Organizations`
        : 'Organizations',
    [isTypeExists, organizationsKey],
  );

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={title} />
      <Header title={title} ref={elementRef} shouldUseSidebarState>
        <ActionPanelButtonWrapper>
          <Button
            to={appRoutes.partiesOrganizationCreatePath()}
            variant="contained"
            color="secondary"
            component={NavHashLink}
          >
            Add Organization
          </Button>
        </ActionPanelButtonWrapper>
      </Header>
      <ListTable
        detailPath={appRoutes.partiesOrganizationPath}
        isLoading={isOrganizationsLoading}
        tableHeader={Object.keys(columnConfig)}
        tableData={tableData}
        columnConfig={columnConfig}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
