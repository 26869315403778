import { useSelector } from 'react-redux';

import { useMissingReconciliationReportActions } from 'store/MissingReconciliationReportSlice.js';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useMissingReconciliationReport = () => {
  const { loadReport, resetReport } = useMissingReconciliationReportActions();
  const {
    missingReconciliationReport,
    loadingStatus,
    pagination,
    missingReconciliationReportLoadError,
  } = useSelector(state => state.MissingReconciliationReportSlice);

  return {
    loadReport,
    resetReport,
    missingReconciliationReport,
    reportLoadError: missingReconciliationReportLoadError,
    pagination,
    isReportLoading: useFetchStatus(loadingStatus).isPending,
    isReportFailed: useFetchStatus(loadingStatus).isFailed,
  };
};
