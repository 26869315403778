const LOAN_HASH = {
  details: 'details',
  parties: 'parties',
  dealChecklist: 'deal-checklist',
  noteIssues: 'note-issues',
  postClosing: 'post-closing',
  sharingMonetization: 'sharing-monetization',
  sharing: 'sharing',
  monetization: 'monetization',
  hedging: 'hedging',
};

Object.freeze(LOAN_HASH);

export { LOAN_HASH };
