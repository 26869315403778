import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useLocation } from 'react-router';
import 'assets/css/global.css';
import sidebar from 'assets/sidebar.svg';
import leftArrow from 'assets/leftArrow.svg';

import OptionsLoading from 'components/OptionsLoading';
import Error404 from 'components/Error404';

import { useAppAppearanceContext } from 'hooks/useAppAppearance';

import Header from './components/Header';
import useStyles from './useStyles';

const RootLayout = ({ children, sideNavContent = null }) => {
  const classes = useStyles();
  const { isSideBarHidden, toggleSideBar } = useAppAppearanceContext();
  const handleHideNavClick = () => toggleSideBar();

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  return (
    <>
      <div className={classes.gridLayout}>
        <Header />
        <OptionsLoading>
          <Error404 className={classes.main}>
            {sideNavContent && (
              <div className={clsx(classes.sidebar, { [classes.hiddenSidebar]: isSideBarHidden })}>
                <h2 className={classes.sidebarTitle}>{sideNavContent?.title}</h2>
                {sideNavContent?.markup}
                <button
                  type="button"
                  onClick={handleHideNavClick}
                  className={clsx(classes.hiddenNavButton, {
                    [classes.hideNavButton]: !isSideBarHidden,
                  })}
                >
                  <img
                    className={clsx({ [classes.hiddenButtonImage]: !isSideBarHidden })}
                    src={sidebar}
                    alt="sidebar"
                  />
                  <img
                    className={clsx({ [classes.hiddenButtonImage]: isSideBarHidden })}
                    src={leftArrow}
                    alt="leftArrow"
                  />
                  <span
                    className={clsx(classes.buttonText, {
                      [classes.hiddenButtonText]: isSideBarHidden,
                    })}
                  >
                    Hide
                  </span>
                </button>
              </div>
            )}
            <main className={classes.main}>{children}</main>
          </Error404>
        </OptionsLoading>
      </div>
      <div id="portal-root" />
    </>
  );
};

RootLayout.propTypes = {
  sideNavContent: PropTypes.shape({
    title: PropTypes.string,
    markup: PropTypes.element,
  }),
};

export default RootLayout;
