import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { DateConst, StatusConst } from 'const';

import { ERROR_MESSAGES, STATUS_COLUMN } from 'enums';

import AddressPresenter from 'presenters/AddressPresenter';
import BankAccountPresenter from 'presenters/BankAccountPresenter';

import { transformDate } from 'utils/date';
import { bankAccountValidationSchema, optionalAddressSchemas } from 'utils/validationSchemas';

export const resolver = yupResolver(
  Yup.object({
    name: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    shortName: Yup.string().trim().nullable(),
    filingRef: Yup.string().nullable(),
    status: Yup.string().trim().required(),
    statusNote: Yup.string().trim().nullable(),
    administrativeAgent: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .required(ERROR_MESSAGES.required),
    ein: Yup.string().trim().nullable(),
    einDateFiledOn: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    form2553FiledDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    form2553AcceptedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    secretaryOfStateId: Yup.string().trim().nullable(),
    sosAcceptedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    jurisdiction: Yup.string().trim().nullable(),
    address: optionalAddressSchemas,
    fiscalYear: Yup.string().trim().nullable(),
    registeredAgent: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    registeredOfficeAddress: optionalAddressSchemas,
    dateOfDissolutionOn: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    bankAccount: bankAccountValidationSchema,
  }),
);

export const fieldArray = [
  { fieldKey: 'name', displayedName: 'Name', type: FORM_FIELD_TYPE.input, isRequired: true },
  { fieldKey: 'shortName', displayedName: 'Short Name', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'filingRef', displayedName: 'Ref Number', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'status',
    displayedName: 'Status',
    type: FORM_FIELD_TYPE.enum,
    isRequired: true,
    options: [
      {
        key: STATUS_COLUMN.active,
        displayedName: 'Active',
        value: StatusConst.active,
      },
      { key: STATUS_COLUMN.inactive, displayedName: 'Inactive', value: StatusConst.inactive },
    ],
  },
  {
    fieldKey: 'statusNote',
    displayedName: 'Status Note',
    type: FORM_FIELD_TYPE.input,
    multiline: true,
  },

  {
    fieldKey: 'administrativeAgent',
    displayedName: 'Administrative Agent',
    type: FORM_FIELD_TYPE.select,
    hasMargin: true,
    selectValuesType: 'administrativeAgents',
    placeholder: 'Select Administrative Agent',
    isRequired: true,
  },

  { fieldKey: 'ein', displayedName: 'EIN', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'einDateFiledOn', displayedName: 'EIN Filed Date', type: FORM_FIELD_TYPE.date },
  {
    fieldKey: 'form2553FiledDate',
    displayedName: '2553 Filed Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'form2553AcceptedDate',
    displayedName: '2553 Accepted Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'secretaryOfStateId',
    displayedName: 'SOS ID',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'sosAcceptedDate',
    displayedName: 'SOS Accepted Date',
    type: FORM_FIELD_TYPE.date,
  },
  { fieldKey: 'jurisdiction', displayedName: 'Jurisdiction', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'address',
    type: FORM_FIELD_TYPE.address,
    fields: AddressPresenter.formFields({
      prefix: 'address',
      titleName: 'Company Address',
      isReadonly: true,
    }),
  },
  { fieldKey: 'fiscalYear', displayedName: 'Fiscal Year Ending', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'registeredAgent',
    displayedName: 'Registered Agent',
    type: FORM_FIELD_TYPE.select,
    hasMargin: true,
    selectValuesType: 'registeredAgents',
    placeholder: 'Select Registered Agent',
  },
  {
    fieldKey: 'registeredOfficeAddress',
    type: FORM_FIELD_TYPE.address,
    fields: AddressPresenter.formFields({
      prefix: 'registeredOfficeAddress',
      titleName: 'Registered Office Address',
      isReadonly: true,
    }),
  },
  {
    fieldKey: 'dateOfDissolutionOn',
    displayedName: 'Date of Dissolution',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'bankAccount',
    type: FORM_FIELD_TYPE.bank,
    fields: BankAccountPresenter.formFields({
      prefix: 'bankAccount',
      titleName: 'Bank Account',
      isRequired: true,
    }),
  },
];
