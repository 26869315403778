import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    link: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      height: 40,
      fontWeight: 'normal',
      color: theme.palette.elephant,
      textTransform: 'none',
      textDecoration: 'none',
      padding: [0, theme.spacing(1)],
      borderRadius: 4,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, .04)',
      },
      '& > span': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    noResults: {
      fontWeight: 'normal',
      color: theme.palette.elephant,
      marginLeft: '8px',
    },
    outBox: {
      display: 'block',
      margin: [0, -theme.spacing(1), 0],
    },
    backButton: {
      color: theme.palette.primary,
      margin: [20, 0],
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      '& svg': {
        height: 11,
      },
    },
    stickySearch: {
      paddingTop: '24px',
      paddingRight: '24px',
      paddingLeft: '24px',
      paddingBottom: '8px',
      marginRight: '-24px',
      marginLeft: '-24px',
      backgroundColor: 'white',
      position: 'sticky',
      top: 0,
    },
    search: {
      width: '100%',
    },
    label: {
      textTransform: 'uppercase',
      opacity: 0.4,
      color: theme.palette.elephant,
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: 8,
        width: 14,
      },
    },
  }),
  { name: 'SearchChoicesList' },
);
