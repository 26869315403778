import React, { useEffect } from 'react';

import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { not, isEmpty, isNil } from 'ramda';
import { useFormContext } from 'react-hook-form';

import * as ReconciliationInputService from 'domain/reconciliationInput/service';

import { useEditableTableContext } from 'hooks/useEditableTableControls';

import ReconciliationTableRow from 'pages/LoanReconciliationInputs/components/ReconciliationTableRow';
import useStyles from 'pages/LoanReconciliationInputs/useStyles';
import { defaultFormValues } from 'pages/LoanReconciliationInputs/validation';

import { pickData } from 'utils/forms';
import { parseDate } from 'utils/date';
import { preventNullProps } from 'utils/forms';

const TABLE_HEADER = 'Reconciliation Values';
const NO_INPUTS = 'No Reconciliation Inputs yet.';

const isEditableRow = (rowId, editableRowId) => rowId === editableRowId;

export const ReconciliationTable = props => {
  const { reconciliationInputsData, onCreate, onUpdate, onDelete } = props;

  const tableRows = ReconciliationInputService.createTableRows(reconciliationInputsData);
  const { columns } = ReconciliationInputService;

  const {
    isCreatingFormShown,
    hideCreatingForm,
    isEditingFormShown,
    showEditableRow,
    editableRowId,
    setEditableRowId,
  } = useEditableTableContext();

  const classes = useStyles();

  const { reset: resetForm } = useFormContext();

  useEffect(() => {
    if (isCreatingFormShown) {
      resetForm(defaultFormValues);
      return;
    }
    if (isNil(editableRowId)) {
      return;
    }
    const rowData = pickData(editableRowId, reconciliationInputsData);
    if (isEditingFormShown) {
      resetForm({
        ...preventNullProps(rowData),
        reconciliationYearMonth: parseDate('y-M')(rowData.reconciliationYearMonth),
      });
    }
  }, [isEditingFormShown, editableRowId, isCreatingFormShown]);

  const handleEditButtonClick = row => () => {
    const { id } = row;
    setEditableRowId(id);
    showEditableRow();
    hideCreatingForm();
  };

  if (isEmpty(reconciliationInputsData) && !isCreatingFormShown) {
    return <p className={classes.noRecordEntry}>{NO_INPUTS}</p>;
  }

  return (
    <>
      <Typography className={classes.tableHeader}>{TABLE_HEADER}</Typography>
      <TableContainer component={Paper}>
        <form>
          <Table aria-label="reconciliationTable" className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columns.getNames().map((name, index) => (
                  <TableCell key={`table-head-${index}`} className={classes.tableHeadCell}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isCreatingFormShown && (
                <ReconciliationTableRow isForm isCreating onCreate={onCreate} onUpdate={onUpdate} />
              )}
              {not(isEmpty(tableRows)) &&
                tableRows.map((row, index) => (
                  <ReconciliationTableRow
                    key={index}
                    row={row}
                    isForm={isEditableRow(row.id, editableRowId) && isEditingFormShown}
                    onEdit={handleEditButtonClick(row)}
                    onCreate={onCreate}
                    onUpdate={onUpdate}
                    onDelete={onDelete}
                  />
                ))}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
    </>
  );
};
ReconciliationTable.propTypes = {
  reconciliationInputsData: PropTypes.arrayOf(
    ReconciliationInputService.reconciliationInputPropTypes,
  ),
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};
