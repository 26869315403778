import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { FORM_FIELD_TYPE } from 'enums';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/numbers';

export const freddieFloatPaymentsColumnConfig = {
  id: {
    displayedName: 'PID',
    isLink: true,
  },
  filingRef: {
    displayedName: 'Ref',
  },
  alternateLoanNumber: {
    displayedName: 'Freddie Loan Number',
  },
  dealName: {
    displayedName: 'Deal Name',
  },
  assumedDate: {
    displayedName: 'Assumed Date',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  sharingArrangementPaymentDate: {
    displayedName: 'Paid Date',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.date,
    fieldKey: 'sharingArrangementPaymentDate',
  },
  totalFloatInterest: {
    displayedName: 'Total Float',
    align: 'right',
  },
  sharingPaymentToFreddie: {
    displayedName: 'Sharing Payment to Freddie',
    align: 'right',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    dealName: PropTypes.string,
    assumedDate: PropTypes.date,
    maturityDate: PropTypes.date,
    sharingArrangementPaymentDate: PropTypes.date,
    alternateLoanNumber: PropTypes.number,
    totalFloatInterest: PropTypes.number,
    sharingPaymentToFreddie: PropTypes.number,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          assumedDate: formatDate(rowData.assumedDate),
          maturityDate: formatDate(rowData.maturityDate),
          alternateLoanNumber: rowData.alternateLoanNumber,
          totalFloatInterest: formatCurrency(rowData.totalFloatInterest),
          sharingPaymentToFreddie: formatCurrency(rowData.sharingPaymentToFreddie),
        },
      }));
    },
    totals(list) {
      if (isEmpty(list)) return null;
      const totalFloatInterest = formatCurrency(
        String(list.reduce((acc, item) => acc + Number(item.totalFloatInterest), 0)),
      );
      const sharingPaymentToFreddie = formatCurrency(
        String(list.reduce((acc, item) => acc + Number(item.sharingPaymentToFreddie), 0)),
      );
      return {
        totalFloatInterest,
        sharingPaymentToFreddie,
      };
    },
    tableKeys() {
      return Object.keys(freddieFloatPaymentsColumnConfig);
    },
  },
);
