import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    popupInner: {
      padding: [0, 32],
    },
    popupText: {
      color: theme.palette.tuna,
      opacity: 0.7,
      whiteSpace: 'pre-line',
    },
  }),
  { name: 'AlertPopup' },
);
