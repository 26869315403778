import React from 'react';

import PropTypes from 'prop-types';
import { List, ListItem, Link } from '@material-ui/core';
import clsx from 'clsx';

import { useLoansStatus } from 'hooks/useLoansStatus';

import useStyles from './useStyles';

export const LoansByStatusLinksList = props => {
  const { loansNavLinks, onClick, isActiveLink = true, className } = props;

  const { loansStatus } = useLoansStatus();

  const classes = useStyles();

  const isActive = statusInLink => loansStatus === statusInLink && isActiveLink;

  const handleLinkClick = status => e => {
    e.preventDefault();
    onClick(status);
  };

  return (
    <List className={clsx(classes.navList, className)}>
      {loansNavLinks.map(navItem => {
        const { name, to, disabled, statusQueryParam } = navItem;
        return (
          <ListItem
            key={name}
            className={clsx(classes.navItem, {
              [classes.activeNavLink]: isActive(statusQueryParam),
              [classes.disabledLink]: disabled,
            })}
          >
            <Link
              href={to}
              onClick={handleLinkClick(statusQueryParam)}
              disabled={disabled}
              underline="none"
              color="primary"
            >
              {name}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};

LoansByStatusLinksList.propTypes = {
  loansNavLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      to: PropTypes.string,
      disabled: PropTypes.bool,
      statusQueryParam: PropTypes.string,
    }),
  ),
  onClick: PropTypes.func,
  isActiveLink: PropTypes.bool,
  className: PropTypes.string,
};
