import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';

import MemberForm from 'components/MemberForm';

import ContentLayout from 'layouts/ContentLayout';

import MemberShowPresenter from 'presenters/MemberShowPresenter';

import { useConfigurationActions } from 'store/SuccessorBorrowersSlice';

const MemberAdd = () => {
  const { loadConfiguration } = useConfigurationActions();
  const { configuration } = useSelector(state => state.SuccessorBorrowersSlice);

  useEffect(() => {
    loadConfiguration();
  }, []);
  return (
    <ContentLayout>
      <MemberForm
        isEdit={false}
        formValues={MemberShowPresenter.defaultValues(configuration)}
        pageTitle="New Member"
        submitButtonText="Add"
      />
    </ContentLayout>
  );
};
export default MemberAdd;
