import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { formatDate } from 'utils/date';
import { getLoanStatusLabel } from 'utils/loanStatusHelpers';

export const columnConfig = {
  filingRef: {
    displayedName: 'Filling Ref',
    canBeHidden: true,
    sortingEnabled: true,
  },
  id: {
    displayedName: 'PID',
    canBeHidden: false,
    sortingEnabled: true,
  },
  dealName: {
    displayedName: 'Property Name',
    canBeHidden: false,
    withTags: true,
    isLink: true,
    maxWidth: '250px',
  },
  bloombergName: {
    displayedName: 'Bloomberg Name',
  },
  status: {
    displayedName: 'Status',
    canBeHidden: false,
  },
  loanServicerName: {
    displayedName: 'Sub Servicer',
    canBeHidden: true,
  },
  loanServicerLoanNumber: {
    displayedName: 'Sub Loan #',
    maxWidth: '100px',
  },
  masterServicerLoanNumber: {
    displayedName: 'Master Loan #',
    canBeHidden: true,
    maxWidth: '100px',
  },
  custodianName: {
    displayedName: 'Custodian',
    canBeHidden: true,
  },
  custodianAccountNumber: {
    displayedName: 'Cust.Acc. #',
    canBeHidden: true,
  },
  assumedDate: {
    displayedName: 'Accepted Date',
    canBeHidden: true,
    sortingEnabled: true,
    sortingKey: 'assumedDate',
  },
  parRepayDate: {
    displayedName: 'Par Repay Date',
    canBeHidden: true,
  },
  maturityDate: {
    displayedName: 'Maturity Date',
    canBeHidden: true,
  },
  parRepayType: {
    displayedName: 'PRP Code',
    canBeHidden: true,
  },
};

export const getTableHeader = () => Object.keys(columnConfig);

export const getTableData = list => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      assumedDate: formatDate(rowData.assumedDate),
      rawAssumedDate: rowData.assumedDate,
      parRepayDate: formatDate(rowData.parRepayDate),
      maturityDate: formatDate(rowData.maturityDate),
      loanServicerName: rowData.loanServicerShortName || rowData.loanServicerName,
      custodianName: rowData.custodianShortName || rowData.custodianName,
      status: getLoanStatusLabel(rowData.status),
    },
  }));
};

export const loansDataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  filingRef: PropTypes.number,
  bloombergName: PropTypes.string,
  dealName: PropTypes.string,
  loanServicerName: PropTypes.string,
  masterServicerLoanNumber: PropTypes.number,
  custodianName: PropTypes.string,
  custodianAccountNumber: PropTypes.number,
  parRepayType: PropTypes.number,
  assumedDate: PropTypes.date,
  parRepayDate: PropTypes.date,
  maturityDate: PropTypes.date,
  servicerCounsel1Name: PropTypes.string,
  loanServicerLoanNumber: PropTypes.string,
});
