import PropTypes from 'prop-types';
import { capitalize } from '@material-ui/core';
import { SUMMARY_OF_DEALS_PERIODS } from 'enums/summaryOfDealsPeriods';

import Presenter from 'utils/PropTypesPresenter';

export const getSummaryOfDealsColumnConfig = period => ({
  periodTitle: {
    displayedName: SUMMARY_OF_DEALS_PERIODS[period],
  },
  periodCount: {
    displayedName: `Deals assumed by ${capitalize(period)}`,
  },
  cumulativeCount: {
    displayedName: 'cumulative',
  },
});

export default new Presenter(
  {
    periodTitle: PropTypes.oneOf(['month', 'quarter', 'year']),
    periodCount: PropTypes.number,
    cumulativeCount: PropTypes.number,
  },
  {
    tableData(list) {
      if (!list) return [];
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
        },
      }));
    },
  },
);
