import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import sidebar from 'assets/sidebar.svg';
import leftArrow from 'assets/leftArrow.svg';
import { isNil } from 'ramda';

import { useAppAppearanceContext } from 'hooks/useAppAppearance';

import SideBarLinks from './components/SideBarLinks';
import useStyles from './useStyles';

export const SideBar = forwardRef((props, ref) => {
  const { isPortal, sideBarContent, className } = props;
  const classes = useStyles();

  const { isSideBarHidden, toggleSideBar } = useAppAppearanceContext();
  const handleHideNavClick = () => toggleSideBar();

  return (
    <div className={clsx(className, classes.sidebar, { [classes.hiddenSidebar]: isSideBarHidden })}>
      {isPortal && <div ref={ref} />}
      {!isNil(sideBarContent) && (
        <SideBarLinks links={sideBarContent.links} title={sideBarContent.title} />
      )}
      <button
        type="button"
        onClick={handleHideNavClick}
        className={clsx(classes.hiddenNavButton, {
          [classes.hideNavButton]: !isSideBarHidden,
        })}
      >
        <img
          className={clsx({ [classes.hiddenButtonImage]: !isSideBarHidden })}
          src={sidebar}
          alt="sidebar"
        />
        <img
          className={clsx({ [classes.hiddenButtonImage]: isSideBarHidden })}
          src={leftArrow}
          alt="leftArrow"
        />
        <span
          className={clsx(classes.buttonText, {
            [classes.hiddenButtonText]: isSideBarHidden,
          })}
        >
          Hide
        </span>
      </button>
    </div>
  );
});

SideBar.propTypes = {
  isPortal: PropTypes.bool,
  sideBarContent: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        name: PropTypes.string,
        nestedLinks: PropTypes.arrayOf(
          PropTypes.shape({
            to: PropTypes.string,
            name: PropTypes.string,
          }),
        ),
      }),
    ),
    title: PropTypes.string,
  }),
  className: PropTypes.string,
};
