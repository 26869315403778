import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    reportWrap: {
      marginLeft: 'unset',
    },
    actionPanel: {
      marginBottom: '24px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    button: {
      alignSelf: 'end',
    },
  }),
  { name: 'MortgageInterestAccrualReport' },
);
