import React, { useEffect, useMemo, useRef } from 'react';

import { Typography } from '@material-ui/core';
import DataValidationRepository from 'repositories/DataValidationRepository';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';
import ScrollToTopButton from 'components/ScrollToTopButton';

import { useErrors } from 'hooks/useErrors';
import { useDataValidationReport } from 'hooks/api/useDataValidationReport';

import ContentLayout from 'layouts/ContentLayout';

import useStyles from './useStyles';
import Report from './components/Report';

const TITLE = 'Data Validation';

const DataValidationReport = () => {
  const classes = useStyles();

  const { displayErrorsInToast, formatErrors } = useErrors();

  const backToTopAnchorRef = useRef(null);

  const {
    createDataValidationLongPollingWorker,
    isDataValidationReportLoading,
    dataValidationLoadErrors,
    isDataValidationReportLoadFulfilled,
  } = useDataValidationReport();

  const loadDataValidationReport = useMemo(
    () => createDataValidationLongPollingWorker(DataValidationRepository.DataValidationTask),
    [],
  );

  useEffect(() => {
    loadDataValidationReport();
  }, []);

  useEffect(() => {
    if (dataValidationLoadErrors) {
      const { backendServicesError, nonFieldErrors } = formatErrors(dataValidationLoadErrors);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  }, [dataValidationLoadErrors]);

  return (
    <ContentLayout className={classes.layout}>
      <DocumentTitle title={TITLE} />
      <Typography variant="h1" ref={backToTopAnchorRef}>
        {TITLE}
      </Typography>
      {isDataValidationReportLoading && <Loader />}
      {isDataValidationReportLoadFulfilled && <Report />}
      <ScrollToTopButton anchorElement={backToTopAnchorRef?.current} />
    </ContentLayout>
  );
};

export default DataValidationReport;
