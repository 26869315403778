import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async DataValidationTask(params = {}) {
    const url = apiRoutes.reportDataValidationTasksPath();
    return FetchHelpers.post(url, params);
  },
};
