import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { Button, Typography } from '@material-ui/core';
import UsersRepository from 'repositories/UsersRepository';

import FormField from 'components/FormField';
import Loader from 'components/Loader';

import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const PASSWORD_CHANGED = 'Your password is successfully changed';

export const ChangePassword = () => {
  const classes = useStyles();
  const { setErrorsToForm } = useErrors();
  const { showSuccessNotification } = useSnackbar();
  const methods = useForm({
    resolver,
  });

  const [formErrors, setFormErrors] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const clearFields = () => {
    fieldArray.forEach(field => {
      methods.setValue(field.fieldKey, '');
    });
  };

  const handleSubmit = async values => {
    setLoading(true);
    try {
      setFormErrors(null);
      await UsersRepository.updatePassword(values);
      showSuccessNotification(PASSWORD_CHANGED);
      clearFields();
    } catch (e) {
      setFormErrors(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (formErrors) {
      setErrorsToForm(methods.setError, methods.errors, formErrors);
    }
  }, [formErrors]);

  return (
    <ContentLayout>
      <Typography variant="h1">Password</Typography>
      <FormProvider {...methods}>
        <div className={classes.main}>
          <form className={classes.contentForm}>
            {isLoading && (
              <div className={classes.loaderWrap}>
                <Loader />
              </div>
            )}
            <p className={classes.contentText}>
              After a successful password update, you will be redirected to the login page where you
              can log in with your new password.
            </p>
            <FormField fieldArray={fieldArray} errors={methods.errors} />
            <div className={classes.buttonsContainer}>
              <Button
                color="secondary"
                variant="contained"
                onClick={methods.handleSubmit(handleSubmit)}
              >
                Change Password
              </Button>
            </div>
          </form>
        </div>
      </FormProvider>
    </ContentLayout>
  );
};
