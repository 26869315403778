import React, { useEffect, useMemo, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Button, debounce } from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';
import { isEmpty, isNil } from 'ramda';
import { ReactComponent as ArrowLeftIcon } from 'assets/arrow_left.svg';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import SearchField from 'components/SearchField';

import { TimersConst } from 'const';

import useChoicesSearch from 'hooks/useChoicesSearch';
import { useErrors } from 'hooks/useErrors';

import { SEARCH_BY_LABELS, getOrderingKey, getTitle, createParams } from 'utils/choicesSearch';
import { mergeOptions } from 'utils/select';
import { getSentryOption, injectSentry, removeSentry } from 'utils/infiniteScrollUtils';

import useStyles from './useStyles';

const NO_RESULTS = 'Please try another search';

const SearchChoicesList = props => {
  const { searchKey, onClose, onBack } = props;

  const classes = useStyles();

  const [activePage, setActivePage] = useState(1);
  const [options, setOptions] = useState([]);
  const [isFirstUpdate, setFirstUpdate] = useState(true);
  const [shouldPreserve, setShouldPreserve] = useState(false);
  const [shouldUpdateOptions, setShouldUpdateOptions] = useState(false);

  const resetActivePage = () => setActivePage(1);
  const increaseActivePage = () => setActivePage(activePage + 1);

  const queryParams = {
    ordering: getOrderingKey(searchKey),
    page: activePage,
  };

  const { isChoicesLoading, loadChoices, searchChoices, clearChoices, hasNextPage } =
    useChoicesSearch(searchKey);

  const { displayErrorsInToast, formatErrors } = useErrors();

  const fetchOptions = async params => {
    try {
      await loadChoices(params);
      setShouldUpdateOptions(true);
    } catch (e) {
      const { backendServicesError, nonFieldErrors } = formatErrors(e);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  };

  const searchCallback = value => {
    resetActivePage();
    setOptions([]);
    setFirstUpdate(false);
    setShouldPreserve(false);
    fetchOptions({ page: 1, ...createParams(searchKey, value) });
  };

  const debouncedSearchOnType = useMemo(
    () => debounce(value => searchCallback(value), TimersConst.SEARCH_FIELD_DEBOUNCE_TIME),
    [],
  );

  const handleSearchChange = value => {
    debouncedSearchOnType(value);
  };

  const handleOnBack = () => {
    resetActivePage();
    clearChoices();
    onBack();
  };

  const loadNextOptions = () => {
    fetchOptions(queryParams);
    setShouldPreserve(true);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isChoicesLoading,
    hasNextPage,
    onLoadMore: loadNextOptions,
  });

  useEffect(() => {
    if (!isChoicesLoading && isFirstUpdate) fetchOptions(queryParams);
  }, [isFirstUpdate]);

  useEffect(() => {
    if (hasNextPage) increaseActivePage();
  }, [hasNextPage]);

  useEffect(() => {
    if (!isEmpty(searchChoices) && shouldUpdateOptions) {
      const withSentry = injectSentry(searchChoices, getSentryOption());
      setOptions(shouldPreserve ? mergeOptions(removeSentry(options), withSentry) : withSentry);
      setShouldUpdateOptions(false);
      if (shouldPreserve) document.getElementById('scrollElem').scrollIntoView();
    }
  }, [searchChoices, shouldUpdateOptions]);

  const renderSentryComponent = useCallback(() => <div ref={sentryRef} />, [sentryRef, activePage]);

  const getOption = option => {
    if (!option.isSentry) {
      return (
        <NavHashLink
          key={option.id}
          to={option.to}
          onClick={onClose}
          className={classes.link}
          title={option.name}
        >
          <span>{getTitle(searchKey, option)}</span>
        </NavHashLink>
      );
    }
    if (option.isSentry) {
      return <div id="scrollElem">{renderSentryComponent()}</div>;
    }
    return null;
  };

  return (
    <>
      <div className={classes.stickySearch}>
        <SearchField
          className={classes.search}
          placeholder={`Search by ${SEARCH_BY_LABELS[searchKey]}`}
          handleChange={handleSearchChange}
          isOptionsLoading={isChoicesLoading}
        />
        <div className={classes.outBox}>
          <Button
            className={classes.backButton}
            onClick={handleOnBack}
            startIcon={<ArrowLeftIcon />}
            color="secondary"
          >
            Back
          </Button>
        </div>
        <div className={classes.label}>{SEARCH_BY_LABELS[searchKey]}</div>
      </div>
      {!isNil(searchChoices) && !isChoicesLoading && (
        <div className={classes.outBox}>
          {isEmpty(searchChoices) && <span className={classes.noResults}>{NO_RESULTS}</span>}
          {options.map(item => getOption(item))}
        </div>
      )}
    </>
  );
};

SearchChoicesList.propTypes = {
  searchKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default SearchChoicesList;
