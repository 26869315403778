const TOOLS_POPUP = {
  import: 'import',
  updateAssumedDates: 'update_assumed_dates',
  masterReport: 'master_report',
  propertiesDump: 'properties-dump-tasks',
  pricingModel: 'pricing_model',
  cibc: 'cibc',
  moodyReport: 'moody_report',
  sharingArrangementDefeasedLoans: 'sharing_arrangement_defeased_loans',
  dealsInProgress: 'deals_in_progress',
  sideLetterReport: 'side_letter_report',
  buyUpReport: 'buy_up_report',
  netsuiteAccountReport: 'netsuite_account_report',
  prpTrackingSheet: 'prp_tracking_sheet',
  ratingAgencyReport: 'rating_agency_report',
  maturityTrackingSheet: 'maturity_tracking_sheet',
  dissolutionFutureProjectionsReport: 'dissolution_future_projections_report',
  dealClosingChecklist: 'deal_closing_checklist',
  reconciliationInputs: 'reconciliationInputs',
  agenciesSecuritiesPercentage: 'agenciesSecuritiesPercentage',
  fundingRequest: 'fundingRequest',
  journalEntries: 'journalEntries',
  freddieFloatPayment: 'freddieFloatPayment',
};

Object.freeze(TOOLS_POPUP);

export { TOOLS_POPUP };
