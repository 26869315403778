import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { formatDate, isBeforeTodayDate } from 'utils/date';

export const upcomingParRepaysColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  id: {
    displayedName: 'PID',
  },
  dealName: {
    displayedName: 'Property Name',
    isLink: true,
    withTags: true,
    width: '350px',
  },
  parRepayNoticeDate: {
    displayedName: 'Par Repay Notice Date',
  },
  parRepayNoticeSentDate: {
    displayedName: 'Par Repay Notice Sent Date',
  },
  parRepayType: {
    displayedName: 'Par Repay Type',
  },
  parRepayDate: {
    displayedName: 'Par Repay Date',
  },
  parRepayConservativeDate: {
    displayedName: 'Par Repay Date (Conservative)',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  masterServicerShortName: {
    displayedName: 'Master Short',
  },
  masterServicerLoanNumber: {
    displayedName: 'Master #',
    maxWidth: '130px',
  },
  alternativeServicerShortName: {
    displayedName: 'Sub Short',
  },
  loanServicerLoanNumber: {
    displayedName: 'Sub Loan #',
  },
  custodianShortName: {
    displayedName: 'Custodian',
  },
  custodianAccountNumber: {
    displayedName: 'Custodian #',
  },
};

export const getTableColumnsKeys = () => Object.keys(upcomingParRepaysColumnConfig);

export const getTableData = list => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    isHighlighted: isBeforeTodayDate(rowData.parRepayDate),
    data: {
      ...rowData,
      parRepayNoticeDate: formatDate(rowData.parRepayNoticeDate),
      parRepayNoticeSentDate: formatDate(rowData.parRepayNoticeSentDate),
      parRepayDate: formatDate(rowData.parRepayDate),
      parRepayConservativeDate: formatDate(rowData.parRepayConservativeDate),
      maturityDate: formatDate(rowData.maturityDate),
      parRepayNoticeLender: formatDate(rowData.parRepayNoticeLender),
      parRepayNoticeCustodian: formatDate(rowData.parRepayNoticeCustodian),
    },
  }));
};

export const upcomingParRepayPropTypes = PropTypes.shape({
  id: PropTypes.number,
  filingRef: PropTypes.number,
  dealName: PropTypes.string,
  parRepayNoticeDate: PropTypes.date,
  parRepayNoticeSentDate: PropTypes.date,
  parRepayType: PropTypes.number,
  parRepayDate: PropTypes.date,
  parRepayConservativeDate: PropTypes.date,
  maturityDate: PropTypes.date,
  masterServicerShortName: PropTypes.string,
  masterServicerLoanNumber: PropTypes.string,
  alternativeServicerShortName: PropTypes.string,
  alternateLoanNumber: PropTypes.string,
  custodianShortName: PropTypes.string,
  custodianAccountNumber: PropTypes.string,
  parRepayNoticeLender: PropTypes.date,
  parRepayNoticeCustodian: PropTypes.date,
  monetizationContract1Name: PropTypes.number,
  monetizationPercentage: PropTypes.number,
  monetizationFutureValue: PropTypes.string,
  monetizationPercentage2: PropTypes.number,
  monetizationFutureValue2: PropTypes.string,
  sharingArrangementFinancialServicesOrgName: PropTypes.string,
  sideLetter: PropTypes.bool,
  successorBorrowerShorNname: PropTypes.string,
  balloonPaymentAmount: PropTypes.string,
  assumedSate: PropTypes.date,
  defeasanceConsultantShortName: PropTypes.string,
  servicerCounsel1Name: PropTypes.string,
  accountantShortName: PropTypes.string,
  originalLoanDocumentsReviewedDate: PropTypes.date,
  certifiedArticlesSentToServicerCounselDate: PropTypes.date,
  executionDefeasanceDocumentsReceivedDate: PropTypes.date,
  securitiesBrokerDealerName: PropTypes.string,
  loanServicerLoanNumber: PropTypes.string,
  buyUpAmount: PropTypes.string,
  sbType: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
});
