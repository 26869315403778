import { isNil, keys } from 'ramda';

import { IExtendedSuccessorBorrower } from 'domain/successorBorrower/types';
import { ISuccessorBorrowerTableData } from 'domain/successorBorrower/schemas/common';

import { getAddressString } from 'utils/dataTransform';
import { formatDate } from 'utils/date';

export const columnConfig = {
  statusNote: {
    displayedName: 'Status Note',
  },
  cmbsPool: {
    displayedName: 'CMBS Pool',
  },
  administrativeAgent: {
    displayedName: 'Administrative Agent',
  },
  ein: {
    displayedName: 'EIN',
  },
  einDateFiledOn: {
    displayedName: 'EIN Filed Date',
  },
  einVerificationLetterRecordDate: {
    displayedName: 'EIN Verification Letter Received Date',
  },
  secretaryOfStateId: {
    displayedName: 'DE SOS ID',
  },
  sosAcceptedDate: {
    displayedName: 'Date of DE SOS',
  },
  northCarolinaCertificateOfAuthorityApplicationDate: {
    displayedName: 'NC Certificate of Authority Application Date',
  },
  northCarolinaCertificateOfAuthorityAcceptedDate: {
    displayedName: 'NC Certificate of Authority Accepted Date',
  },
  northCarolinaSecretaryOfStateId: {
    displayedName: 'NC SOS ID',
  },
  jurisdiction: {
    displayedName: 'Jurisdiction',
  },
  address: {
    displayedName: 'Address',
  },
  fiscalYear: {
    displayedName: 'Fiscal Year Ending',
  },
  registeredAgent: {
    displayedName: 'DE Registered Agent',
  },
  registeredOfficeAddress: {
    displayedName: 'Registered Office Address',
  },
  dateOfDissolutionOn: {
    displayedName: 'Date of Dissolution',
  },
  comments: {
    displayedName: 'Comments',
  },
  bankAccount: {
    displayedName: 'Bank Account',
  },
};

export const poolColumnConfig = {
  securityDescription: {
    displayedName: 'Security Description',
  },
  cusip: {
    displayedName: 'Cusip',
  },
  currentPrice: {
    displayedName: 'Bloomberg Pool Balance',
  },
  originalIssuePrice: {
    displayedName: 'Original Pool Issue',
  },
  bloombergPoolBalanceDate: {
    displayedName: 'Bloomberg Pool Balance Date',
  },
  bondTrustee: {
    displayedName: 'Bond Trustee',
  },
  masterServicer1: {
    displayedName: 'Master Servicer 1',
  },
  masterServicer2: {
    displayedName: 'Master Servicer 2',
  },
  masterServicerNotes: {
    displayedName: 'Master Servicer Notes',
  },
  ratingAgency1: {
    displayedName: 'Rating Agency 1',
  },
  ratingAgency2: {
    displayedName: 'Rating Agency 2',
  },
  ratingAgency3: {
    displayedName: 'Rating Agency 3',
  },
  ratingAgency4: {
    displayedName: 'Rating Agency 4',
  },
  isIndemnityGiven: {
    displayedName: 'Indemnity Given?',
  },
  indemnityNotes: {
    displayedName: 'Indemnity Notes',
  },
  indemnityDate: {
    displayedName: 'Indemnity Date',
  },
  indemnitor: {
    displayedName: 'Indemnitor',
  },
  indemnitySentFor: {
    displayedName: 'Indemnity Sent For',
  },
  issuerAbbreviation: {
    displayedName: 'Issuer Abbreviation',
  },
  issuerSeries: {
    displayedName: 'Issuer Series',
  },
  issuerName: {
    displayedName: 'Issuer Name',
  },
  certificateDefinition: {
    displayedName: 'Certificate Definition',
  },
  certificateHolderLabel: {
    displayedName: 'Certificate Holder Label',
  },
  specialServicer: {
    displayedName: 'Special Servicer',
  },
  fiscalAgent: {
    displayedName: 'Fiscal Agent',
  },
  psaDate: {
    displayedName: 'PSA Date (Month DD, YYYY)',
  },
  hasPoolSecuritized: {
    displayedName: 'Securitized?',
  },
};

export const relatedEntitiesColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
    isLink: true,
  },
  name: {
    displayedName: 'Entity Name',
  },
};

export const getSuccessorBorrower = (data: IExtendedSuccessorBorrower) => {
  if (isNil(data)) return null;
  return {
    name: data.name,
    headerName: `${data.filingRef ? `(${data.filingRef}) ` : ''}${data.name}`,
    managerCompany: data?.parentCompany?.name,
    tableData: {
      status: data.status,
      statusNote: data.statusNote,
      cmbsPool: data?.cmbsPool?.securityDescription,
      administrativeAgent: data?.administrativeAgent?.name,
      ein: data.ein,
      einDateFiledOn: formatDate(data.einDateFiledOn),
      einVerificationLetterRecordDate: formatDate(data.einVerificationLetterRecordDate),
      secretaryOfStateId: data.secretaryOfStateId,
      sosAcceptedDate: formatDate(data.sosAcceptedDate),
      northCarolinaCertificateOfAuthorityApplicationDate: formatDate(
        data.northCarolinaCertificateOfAuthorityApplicationDate,
      ),
      northCarolinaCertificateOfAuthorityAcceptedDate: formatDate(
        data.northCarolinaCertificateOfAuthorityAcceptedDate,
      ),
      northCarolinaSecretaryOfStateId: data.northCarolinaSecretaryOfStateId,
      jurisdiction: data.jurisdiction,
      address: getAddressString(data.address),
      fiscalYear: data.fiscalYear,
      registeredAgent: data?.registeredAgent?.name,
      registeredOfficeAddress: getAddressString(data.registeredOfficeAddress),
      dateOfDissolutionOn: formatDate(data.dateOfDissolutionOn),
      comments: data.comments,
      bankAccount: data.bankAccount?.number,
    },
  };
};

export const getTableData = (data: { tableData: ISuccessorBorrowerTableData }) =>
  data?.tableData ?? null;

export const getTableDataKeys = () => keys(columnConfig);
