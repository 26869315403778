import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { formatDate } from 'utils/date';
import { formatPercent, formatCurrency } from 'utils/numbers';

export const governmentSecuritiesColumnConfig = {
  securityType: {
    displayedName: 'Security Type',
  },
  cusip: {
    displayedName: 'Cusip',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  principalAmount: {
    displayedName: 'Principal Amount',
    align: 'right',
  },
  interestRate: {
    displayedName: 'Interest Rate',
    align: 'right',
  },
  price: {
    displayedName: 'Price',
    align: 'right',
  },
  cost: {
    displayedName: 'Cost',
    align: 'right',
  },
  accruedInterest: {
    displayedName: 'Accrued Interest',
    align: 'right',
  },
  purchasePrice: {
    displayedName: 'Purchase Price',
    align: 'right',
  },
};

const summaryConfig = {
  totalPrincipleAmount: 'Total Principal Amount',
  totalCost: 'Total Cost',
  totalAccruedInterest: 'Total Accrued Interest',
  totalPurchasePrice: 'Total Purchase Price',
};

export const getTableData = data => {
  if (isNil(data)) return null;
  const { items } = data;
  return items.map(marketDiscount => ({
    id: marketDiscount.id,
    data: {
      ...marketDiscount,
      interestRate: formatPercent(marketDiscount?.interestRate, 3),
      price: formatPercent(marketDiscount?.price, 6),
      maturityDate: formatDate(marketDiscount?.maturityDate),
      principalAmount: formatCurrency(marketDiscount?.principalAmount),
      cost: formatCurrency(marketDiscount?.cost),
      accruedInterest: formatCurrency(marketDiscount?.accruedInterest),
      purchasePrice: formatCurrency(marketDiscount?.purchasePrice),
    },
  }));
};

export const getTableKeys = () => Object.keys(governmentSecuritiesColumnConfig);

export const getSummaryTableData = data => {
  if (isNil(data)) return null;
  const summaryData = data.summary;
  return Object.keys(summaryConfig).map(key => ({
    label: summaryConfig[key],
    value: formatCurrency(summaryData[key]),
  }));
};

export const loanGovernmentSecurityPropTypes = PropTypes.shape({
  securityType: PropTypes.string,
  cusip: PropTypes.string,
  maturityDate: PropTypes.date,
  interestRate: PropTypes.number,
  price: PropTypes.number,
  cost: PropTypes.number,
  accruedInterest: PropTypes.number,
  purchasePrice: PropTypes.number,
});
