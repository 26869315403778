import React, { useEffect, useState } from 'react';

import Portal from '@material-ui/core/Portal';
import { NavHashLink } from 'react-router-hash-link';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { keys, isEmpty } from 'ramda';
import { OrganizationSideNav } from 'components/OrganizationSideNav/OrganizationSideNav';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';
import ListTable from 'components/ListTable';
import NoDataMessage from 'components/NoDataMessage';

import { useGetOrganizationQuery } from 'domain/organization/apiSlice';
import { getOrganizationTableData } from 'domain/organization/service';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { columnConfig } from 'presenters/OrganizationShowPresenter';
import { columnConfig as peopleColumnConfig } from 'presenters/PersonIndexPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const INFORMATION = '#information';

export const Organization = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const {
    replace,
    location: { hash },
    query: { id },
  } = useRouter();

  const [sideBarContainer, setRefSideBarContainer] = useState(null);

  const {
    data: organization,
    error: organizationLoadError,
    isFetching: isOrganizationLoading,
  } = useGetOrganizationQuery(id);

  const organizationTableData = getOrganizationTableData(organization);

  useEffect(() => {
    if (organizationLoadError) displayErrorsInToast([organizationLoadError]);
  }, [organizationLoadError]);

  useEffect(() => {
    if (!hash) replace({ pathname: appRoutes.partiesOrganizationPath(id), hash: INFORMATION });
  }, []);

  if (isOrganizationLoading) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  const peopleTableData = organizationTableData?.employees;

  const title = organizationTableData?.name;

  return (
    <>
      <Portal container={sideBarContainer}>
        <OrganizationSideNav organization={organization} />
      </Portal>
      <RootLayoutV2 sideBarRef={setRefSideBarContainer} isPortal isLoading={isOrganizationLoading}>
        <ContentLayout>
          <DocumentTitle title={title} />
          <div className={classes.contentContainer}>
            <h2 className={classes.contentSubtitle}>Parties</h2>
            <div className={classes.contentPanel}>
              <Typography className={classes.contentTitle} variant="h1">
                {title}
              </Typography>
              <Button
                type="button"
                className={classes.editButton}
                variant="text"
                color="primary"
                exact
                to={appRoutes.partiesOrganizationEditPath(id)}
                component={NavHashLink}
              >
                Edit Organization
              </Button>
            </div>
            <h3 id="information" className={classes.detailTitle}>
              Organization Details
            </h3>
            <div className={classes.detailWrap}>
              <div className={classes.tableContent}>
                <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                  <Table className={classes.table}>
                    <TableBody>
                      {keys(columnConfig).map(key => (
                        <TableRow key={`${id}_${key}`}>
                          <TableCell className={classes.tableCell}>
                            {columnConfig[key].displayedName}
                          </TableCell>
                          <TableCell>{organizationTableData[key]}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
          <h3 id="people" className={classes.detailTitle}>
            People
          </h3>
          {isEmpty(peopleTableData) ? (
            <NoDataMessage />
          ) : (
            <ListTable
              detailPath={appRoutes.partiesPersonEditPath}
              isLoading={isOrganizationLoading}
              tableHeader={Object.keys(peopleColumnConfig)}
              tableData={peopleTableData}
              columnConfig={peopleColumnConfig}
            />
          )}
        </ContentLayout>
      </RootLayoutV2>
    </>
  );
};
