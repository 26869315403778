import React from 'react';

import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import PasswordInput from 'components/PasswordInput';

import useStyles from './useStyles';

const TITLE = 'Reset your Password';
const MESSAGE = 'Password must be at least 8 characters long.';
const PASSWORD = 'New Password';
const REPEAT_PASSWORD = 'Repeat new password';
const BUTTON_TEXT = 'Change password';

export const PasswordForm = props => {
  const { onSubmit } = props;
  const classes = useStyles();
  const { handleSubmit } = useFormContext();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.formTitle}>
        {TITLE}
      </Typography>
      <Typography className={classes.message}>{MESSAGE}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <PasswordInput name="password" label={PASSWORD} />
        <PasswordInput
          name="repeatPassword"
          label={REPEAT_PASSWORD}
          className={classes.inputMargin}
        />

        <Button type="submit" variant="contained" color="secondary" className={classes.button}>
          {BUTTON_TEXT}
        </Button>
      </form>
    </div>
  );
};

PasswordForm.propTypes = {
  onSubmit: PropTypes.func,
};
