import React from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import LoanHeader from 'components/LoanHeader';

import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';
import {
  useGetLoanMarketDiscountsQuery,
  useGetLoanMarketDiscountsSummaryQuery,
} from 'domain/loanMarketDiscount/apiSlice';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { isStatusActiveInPayment } from 'utils/layoutStatus';
import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';

import useStyles from './useStyles';
import MarketDiscountContent from './components/MarketDiscountContent';

const EDIT_BUTTON = 'Edit Property';
const OID_BALANCE = 'OID Balance';

export const LoanMarketDiscount = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();

  const {
    data: marketDiscountsData,
    error: marketDiscountsLoadError,
    isFetching: isMarketDiscountsLoading,
  } = useGetLoanMarketDiscountsQuery(id);

  const {
    data: summaryData,
    error: summaryLoadError,
    isFetching: isSummaryLoading,
  } = useGetLoanMarketDiscountsSummaryQuery(id);

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);

  useHandleRtkQueryErrors(marketDiscountsLoadError, loanLoadError, summaryLoadError);

  const title = LoanService.getDealName(loanData);

  const loanStatus = LoanService.getLoanStatus(loanData);

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isDataLoading = isMarketDiscountsLoading || isLoanLoading || isSummaryLoading;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isDataLoading}>
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <DocumentTitle title={title} />
        <LoanHeader title={title} loanStatus={loanStatus}>
          <div>
            {isStatusActiveInPayment(loanStatus) && (
              <Link
                href={appRoutes.OIDBalanceReportLoanPath(id)}
                color="secondary"
                className={classes.link}
              >
                {OID_BALANCE}
              </Link>
            )}
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.loanEditPath(id)}
              component={NavLink}
            >
              {EDIT_BUTTON}
            </Button>
          </div>
        </LoanHeader>
        <ContentLayout>
          <MarketDiscountContent
            loanId={Number(id)}
            marketDiscountsData={marketDiscountsData}
            summaryData={summaryData}
          />
        </ContentLayout>
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
