import React from 'react';

import PropTypes from 'prop-types';
import 'assets/css/global.css';
import { isNil } from 'ramda';

import OptionsLoading from 'components/OptionsLoading';
import Error404 from 'components/Error404';

import Main from './components/Main';
import SideBar from './components/SideBar';
import Header from './components/Header';
import useStyles from './useStyles';

const RootLayoutV2 = props => {
  const {
    children,
    isLoading = false,
    sideBarContent = null,
    sideBarRef = null,
    isPortal = false,
  } = props;
  const classes = useStyles();

  const isSideBarPresent = !isNil(sideBarContent) || isPortal;

  return (
    <>
      <div className={classes.gridLayout}>
        <Header />
        <OptionsLoading>
          <Error404 className={classes.main}>
            {isSideBarPresent && (
              <SideBar
                ref={sideBarRef}
                sideBarContent={sideBarContent}
                className={classes.sidebar}
                isPortal={isPortal}
              />
            )}
            <Main className={classes.main} isLoading={isLoading}>
              {children}
            </Main>
          </Error404>
        </OptionsLoading>
      </div>
      <div id="portal-root" />
    </>
  );
};

RootLayoutV2.propTypes = {
  isLoading: PropTypes.bool,
  sideBarContent: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        to: PropTypes.string,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        nestedLinks: PropTypes.arrayOf(
          PropTypes.shape({
            to: PropTypes.string,
            name: PropTypes.string,
            disabled: PropTypes.bool,
          }),
        ),
      }),
    ),
    title: PropTypes.string,
  }),
  sideBarRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
  isPortal: PropTypes.bool,
};

export default RootLayoutV2;
