import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import LoansRepository from 'repositories/LoansRepository';
import SuccessorBorrowersRepository from 'repositories/SuccessorBorrowersRepository';

import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const initialState = {
  loadingStatus: FETCH_STATUSES.idle,
  searchResultsChoices: null,
  searchResultsChoicesLoadError: false,
};

const searchResultsChoicesReducers = {
  clearSearchResultsChoices(state) {
    state.loadingStatus = FETCH_STATUSES.idle;
    state.searchResultsChoices = null;
    state.searchResultsChoicesLoadError = null;
  },
  startSearchResultsChoicesLoad(state) {
    state.loadingStatus = FETCH_STATUSES.pending;
    state.searchResultsChoices = null;
    state.searchResultsChoicesLoadError = null;
  },
  searchResultsChoicesLoadFulfilled(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.fulfilled;
    state.searchResultsChoices = payload;
  },
  searchResultsChoicesLoadRejected(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.failed;
    state.searchResultsChoices = null;
    state.searchResultsChoicesLoadError = payload;
  },
};

const searchResultsChoicesSlice = createSlice({
  name: 'searchResultsChoices',
  initialState,
  reducers: {
    ...searchResultsChoicesReducers,
  },
});

export default searchResultsChoicesSlice.reducer;

export const useSearchResultsChoicesActions = () => {
  const dispatch = useDispatch();

  const loadChoices = (makeRequest, apiVersion) => async params => {
    dispatch(searchResultsChoicesSlice.actions.startSearchResultsChoicesLoad());
    try {
      const response = await makeRequest(params, apiVersion);
      dispatch(searchResultsChoicesSlice.actions.searchResultsChoicesLoadFulfilled(response));
    } catch (e) {
      dispatch(searchResultsChoicesSlice.actions.searchResultsChoicesLoadRejected(e));
    }
  };

  const clearChoices = () => {
    dispatch(searchResultsChoicesSlice.actions.clearSearchResultsChoices());
  };
  return {
    loadLoansSearchChoices: loadChoices(LoansRepository.choices),
    loadSuccessorBorrowersSearchChoices: loadChoices(SuccessorBorrowersRepository.choices),
    clearChoices,
  };
};
