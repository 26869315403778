import PropTypes from 'prop-types';

import { FORM_FIELD_TYPE } from 'enums';

import Presenter from 'utils/PropTypesPresenter';
import { getConfigurationValue } from 'utils/objects';

export default new Presenter(
  {
    city: PropTypes.string,
    id: PropTypes.number,
    postalCode: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  },
  {
    formFields(props) {
      const { prefix, titleName, prefixTitle = '', isReadonly = false } = props;
      return [
        {
          fieldKey: `${prefix}.street`,
          displayedName: titleName,
          type: FORM_FIELD_TYPE.input,
          isReadonly,
        },
        {
          fieldKey: `${prefix}.city`,
          displayedName: `${prefixTitle} City`,
          type: FORM_FIELD_TYPE.input,
          isReadonly,
        },
        {
          fieldKey: `${prefix}.state`,
          displayedName: `${prefixTitle} State`,
          type: FORM_FIELD_TYPE.select,
          selectValuesType: 'states',
          placeholder: 'Select State',
          isReadonly,
        },
        {
          fieldKey: `${prefix}.postalCode`,
          displayedName: `${prefixTitle} Zip`,
          type: FORM_FIELD_TYPE.input,
          isReadonly,
        },
      ];
    },
    defaultValues(configuration = {}) {
      return {
        city: getConfigurationValue(configuration, 'city'),
        id: getConfigurationValue(configuration, 'id', null),
        postalCode: getConfigurationValue(configuration, 'postalCode'),
        state: getConfigurationValue(configuration, 'state'),
        street: getConfigurationValue(configuration, 'street'),
      };
    },
  },
);
