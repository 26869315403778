import SummaryOfDealsPresenter from 'presenters/SummaryOfDealsPresenter';

import { summaryOfDealsRestHook } from 'store/SummaryOfDealsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useSummaryOfDeals = () => {
  const { summaryOfDeals, loadSummaryOfDeals } = summaryOfDealsRestHook.use();

  const SummaryOfDealsTableData = SummaryOfDealsPresenter.tableData(summaryOfDeals?.items).map(
    (item, index) => {
      const id = `${item?.data?.periodTitle || '0'}-${index}`;
      return { ...item, id };
    },
  );

  return {
    loadSummaryOfDeals,
    SummaryOfDealsTableData,
    areSummaryOfDealsLoading: useFetchStatus(summaryOfDeals?.loadingStatus).isPending,
  };
};
