import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    label: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.4)',
      marginBottom: '8px',
    },
    autocomplete: {
      maxWidth: '320px',
      '& .MuiInput-formControl': {
        marginTop: '4px',
      },
      '& .MuiInput-root': {
        paddingTop: 0,
      },
    },
  }),
  { name: 'Autocomplete' },
);
