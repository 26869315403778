import { useState } from 'react';

import { useDebouncedCallback } from 'use-debounce';

import { TimersConst } from 'const';

import { FETCH_STATUSES } from 'enums';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const useDebouncedUpdate = func => {
  const [status, setStatus] = useState(FETCH_STATUSES.idle);
  const [error, setError] = useState(null);

  const reset = () => {
    setStatus(FETCH_STATUSES.idle);
    setError(null);
  };

  const requestCallback = async (id, params) => {
    setError(null);
    setStatus(FETCH_STATUSES.pending);
    try {
      await func(id, params);
      setStatus(FETCH_STATUSES.fulfilled);
    } catch (e) {
      setStatus(FETCH_STATUSES.failed);
      setError(e);
    }
  };

  const fetchDebouncedRequest = useDebouncedCallback(
    requestCallback,
    TimersConst.SEARCH_FIELD_DEBOUNCE_TIME,
  );

  const { isIdle, isPending, isFulfilled, isFailed } = useFetchStatus(status);

  return {
    func: fetchDebouncedRequest,
    status,
    error,
    reset,
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isNotFinished: isIdle || isPending,
    isFinished: isFulfilled || isFailed,
  };
};

export default useDebouncedUpdate;
