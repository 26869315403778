import React from 'react';

import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { PAGINATION_CONST } from 'const';

import { usePaginationAndOrderingContext } from 'hooks/usePaginationAndOrdering';

import { calculatePageCount } from 'utils/pagination';

import useStyles from './useStyles';

export const PaginationPanel = props => {
  const { itemsCount } = props;
  const classes = useStyles();
  const { setPage, page, pageSize } = usePaginationAndOrderingContext();

  const handlePageChange = (_, pageNum) => {
    setPage(pageNum);
  };

  if (isNil(page)) return null;

  return (
    <div className={classes.root}>
      <Pagination
        page={page}
        count={calculatePageCount(itemsCount, pageSize ?? PAGINATION_CONST.pageSize)}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
        color="secondary"
      />
    </div>
  );
};

PaginationPanel.propTypes = {
  itemsCount: PropTypes.number,
};
