import React from 'react';

import { useController, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { KeyboardDatePicker } from '@material-ui/pickers';
import PropTypes from 'prop-types';

import useStyles from 'pages/FundingNotes/useStyles';

export const DatePickerCell = props => {
  const { name, childrenProps } = props;
  const classes = useStyles();

  const methods = useFormContext();
  const { control, formState, errors } = methods;
  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <div className={classes.inputCellWrap}>
      <KeyboardDatePicker
        id={name}
        helperText={null}
        disabled={formState.isSubmitting}
        inputRef={ref}
        className={classes.input}
        format="MM/dd/yyyy"
        autoComplete="off"
        {...inputProps}
        {...childrenProps}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className={classes.errorMessage}>{message}</p>}
      />
    </div>
  );
};

DatePickerCell.propTypes = {
  name: PropTypes.string,
  childrenProps: PropTypes.shape(),
};
