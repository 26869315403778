import React, { useEffect } from 'react';

import MemberForm from 'components/MemberForm';

import useRouter from 'hooks/useRouter';
import { useMembers } from 'hooks/api/useMembers';

import ContentLayout from 'layouts/ContentLayout';

const MemberEdit = () => {
  const { member, loadMember } = useMembers();
  const {
    query: { id },
  } = useRouter();

  useEffect(() => {
    loadMember(id);
  }, [id]);

  return (
    <ContentLayout>
      <MemberForm
        isEdit
        memberId={id}
        formValues={member}
        pageTitle="Member Details"
        submitButtonText="Update"
      />
    </ContentLayout>
  );
};

export default MemberEdit;
