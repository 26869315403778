import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ERROR_MESSAGES } from 'enums';

export const resolver = yupResolver(
  Yup.object({
    year: Yup.date()
      .nullable()
      .typeError(ERROR_MESSAGES.date.fullYear)
      .required(ERROR_MESSAGES.date.fullYear),
  }),
);

export const formField = {
  displayedName: 'Report Year',
  fieldKey: 'year',
};
