import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_STATUSES } from 'enums';

import { useLongPollingWithRedux } from 'hooks/useLongPollingWithRedux';

const initialState = {
  reportYear: null,
  interestAccrualsReport: {
    loadingStatus: FETCH_STATUSES.idle,
    item: null,
    errors: null,
  },
};

const interestAccrualTaskReportReducer = {
  setReportYear(state, { payload }) {
    state.reportYear = payload;
  },
  startInterestAccrualsReportLoad(state) {
    state.interestAccrualsReport.loadingStatus = FETCH_STATUSES.pending;
    state.interestAccrualsReport.item = null;
    state.interestAccrualsReport.errors = null;
  },
  interestAccrualsReportLoadFulfilled(state, { payload }) {
    state.interestAccrualsReport.item = payload;
    state.interestAccrualsReport.loadingStatus = FETCH_STATUSES.fulfilled;
  },
  interestAccrualsReportLoadRejected(state, { payload }) {
    state.interestAccrualsReport.loadingStatus = FETCH_STATUSES.failed;
    state.interestAccrualsReport.item = null;
    if (payload) {
      state.interestAccrualsReport.errors = payload;
    }
  },
};

const interestAccrualsReportSlice = createSlice({
  name: 'interestAccrualsReport',
  initialState,
  reducers: {
    ...interestAccrualTaskReportReducer,
  },
});

export default interestAccrualsReportSlice.reducer;

export const useInterestAccrualActions = () => {
  const dispatch = useDispatch();

  const setReportYear = year => dispatch(interestAccrualsReportSlice.actions.setReportYear(year));

  const createInterestAccrualsLongPollingWorker = useLongPollingWithRedux({
    onStart: () => dispatch(interestAccrualsReportSlice.actions.startInterestAccrualsReportLoad()),
    onFailure: error =>
      dispatch(interestAccrualsReportSlice.actions.interestAccrualsReportLoadRejected(error)),
    onSuccess: result =>
      dispatch(interestAccrualsReportSlice.actions.interestAccrualsReportLoadFulfilled(result)),
  });

  const interestAccrualsReport = useSelector(
    state => state.InterestAccrualReportSlice.interestAccrualsReport,
  );
  const reportYear = useSelector(state => state.InterestAccrualReportSlice.reportYear);

  return {
    setReportYear,
    createInterestAccrualsLongPollingWorker,
    interestAccrualsReport,
    reportYear,
  };
};
