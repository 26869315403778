import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

export const reconciliationDiscrepancyApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    deleteReconciliationDiscrepanciesCache: builder.mutation({
      query: () => ({
        url: apiRoutes.reconciliationDiscrepanciesReportCachePath(),
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: CACHE_TAG.reconciliationDiscrepancy, id: 'LIST' }],
    }),
  }),
});

export const { useDeleteReconciliationDiscrepanciesCacheMutation } = reconciliationDiscrepancyApi;
