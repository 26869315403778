import { isNil, pathOr } from 'ramda';

import { apiRoutes, appRoutes } from 'router/routes';

import { instance as axiosInstance } from 'utils/FetchHelpers';
import { isPasswordRecoveryPaths } from 'utils/paths';
import { getErrorMessageByStatus, NETWORK_ERROR, NOT_FOUND_ERROR } from 'utils/errors';

import { camelize } from './keysConverter';

const createAxiosResponseInterceptor = history => {
  const getErrors = data => {
    const result = isNil(data) ? null : camelize(data.errors);
    return result;
  };

  const updateToken = () =>
    axiosInstance.post(apiRoutes.refreshPath()).then(({ data }) => {
      localStorage.setItem('dhc', JSON.stringify({ auth: data }));
      return data;
    });

  const repeatRequest = (accessData, config) => {
    const token = accessData.data.access;
    const newConfig = {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` },
    };
    return axiosInstance.request(newConfig);
  };

  const goToAuthPage = () => {
    if (history.location.pathname === appRoutes.authPath()) return;
    history.push({
      pathname: appRoutes.authPath(),
      state: {
        from: history.location.pathname,
      },
    });
  };

  axiosInstance.interceptors.response.use(
    response => response,
    /* eslint-disable prefer-promise-reject-errors */
    // eslint-disable-next-line consistent-return
    async error => {
      const status = pathOr(null, ['response', 'status'], error);
      const data = pathOr(null, ['response', 'data'], error);
      const requestUrl = pathOr(null, ['response', 'config', 'url'], error);
      const originalRequestConfig = error.config;

      switch (status) {
        case 401:
          if (isPasswordRecoveryPaths(history)) {
            return Promise.reject(getErrors(data));
          }
          if (history.location.pathname === appRoutes.authPath()) {
            return Promise.reject(getErrors(data));
          }
          if (requestUrl !== apiRoutes.refreshPath() && !originalRequestConfig.isRetry) {
            try {
              const accessData = await updateToken();
              return await repeatRequest(accessData, { ...originalRequestConfig, isRetry: true });
            } catch (e) {
              goToAuthPage();
              return Promise.reject(e);
            }
          }
          goToAuthPage();
          return Promise.reject(error.message);
        case 422:
          if (requestUrl === apiRoutes.refreshPath()) {
            goToAuthPage();
          } else {
            return Promise.reject(getErrors(data));
          }
          break;
        case 404:
          if (!isPasswordRecoveryPaths(history)) {
            history.replace({
              state: {
                from: history.location.pathname,
                isPageNotFoundError: true,
              },
            });
          }
          return Promise.reject({
            message: getErrorMessageByStatus(NOT_FOUND_ERROR),
            isPageNotFoundError: true,
          });
        case null:
          if (error.toJSON().message === 'Network Error') {
            return Promise.reject({
              isBackendServicesError: true,
              message: getErrorMessageByStatus(NETWORK_ERROR),
            });
          }
          break;
        default:
          return Promise.reject({
            isBackendServicesError: true,
            message: getErrorMessageByStatus(status),
          });
      }
    },
  );
};

export default createAxiosResponseInterceptor;
