const FORM_FIELD_TYPE = {
  input: 'input',
  decimal: 'decimal',
  select: 'select',
  enum: 'enum',
  address: 'address',
  date: 'date',
  checkbox: 'checkbox',
  bank: 'bank',
  enumDate: 'enumDate',
  password: 'password',
  selectAutocomplete: 'selectAutocomplete',
};

Object.freeze(FORM_FIELD_TYPE);

export { FORM_FIELD_TYPE };
