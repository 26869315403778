import { useMemo } from 'react';

import { useQueryParams, ArrayParam } from 'use-query-params';

import { getFiltersFromData, getFiltersFromQueryString } from 'utils/loansFilters';
import { withResetPageParam } from 'utils/pagination';

const useLoansFilters = () => {
  const [query, setQuery] = useQueryParams({
    servicerCounsel: ArrayParam,
    loanServicer: ArrayParam,
    originalLender: ArrayParam,
  });

  const setLoansFilterParams = (data, params) => {
    const { shouldResetPage } = params;
    const filters = getFiltersFromData(data);
    const queryParams = shouldResetPage ? withResetPageParam({ ...filters }) : filters;
    setQuery(queryParams, 'pushIn');
  };

  const filters = useMemo(() => getFiltersFromQueryString(query), [query]);

  return {
    loansFilterParams: filters,
    setLoansFilterParams,
  };
};

export { useLoansFilters };
