import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: '80px',
        height: '80px',
      },
    },
    section: {
      marginBottom: '32px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    tableContainer: {
      width: '100%',
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    tableContent: {
      width: '100%',
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: 0,
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      backgroundColor: theme.palette.white,
      padding: [32, 24],
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
    },
    buttonsContainer: {
      minWidth: 'max-content',
    },
    panelInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    panelActive: {
      backgroundColor: theme.palette.feta,
    },
    panelActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    editButton: {
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',

      '& span': {
        opacity: 0.75,
      },
    },
    detailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    detailTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '16px',
      paddingTop: '16px',
      fontWeight: 'normal',
    },
    statusWindow: {
      width: '312px',
      marginLeft: '24px',
      backgroundColor: theme.palette.white,
      border: [1, 'solid', theme.palette.athensGray],
      borderRadius: 2,
      padding: '24px',
    },
    statusWrap: {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    statusTitle: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.neutral.light,
      margin: 0,
      marginBottom: '8px',
      textTransform: 'uppercase',
    },
    statusValue: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      margin: 0,
      color: theme.palette.capeCod,
    },
    link: {
      marginRight: '20px',
    },
    select: {
      outline: 0,
      borderColor: 'transparent',
      padding: 0,
      backgroundColor: 'transparent',

      '&:hover': {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },

      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    selectWide: {
      width: '130px',
      marginRight: theme.spacing(2),
    },
    fieldNameTableCell: {
      width: '50%',
    },
  }),
  { name: 'Loan' },
);
