import React, { useState, useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ToolsRepository from 'repositories/ToolsRepository';

import Popup from 'components/Popup';
import FormField from 'components/FormField';

import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';
import { useReportTask } from 'hooks/api/useReportTask';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const MoodyReportPopup = ({ setPopupState }) => {
  const classes = useStyles();
  const {
    initiateTaskReportLoading,
    isTaskReportLoading,
    finishLoading,
    resetReportTask,
    taskReportLoadError,
  } = useReportTask();
  const { showSuccessNotification } = useSnackbar();
  const { formatErrors } = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => () => resetReportTask(), []);

  const methods = useForm({
    resolver,
  });

  const handleSubmit = async formValues => {
    methods.clearErrors();

    const callback = ToolsRepository.moodyReport;
    try {
      setIsLoading(true);
      window.location.href = await initiateTaskReportLoading(callback, formValues);
      showSuccessNotification("Moody's Data report Successfully Generated");
      setPopupState(null);
    } catch (e) {
      setIsLoading(false);
      finishLoading(formatErrors(e));
    }
  };

  const handleImportClose = () => {
    setPopupState(null);
  };

  return (
    <Popup
      title="Moody's Data report"
      submitButtonText="Generate"
      toggleState={setPopupState}
      onSubmit={methods.handleSubmit(handleSubmit)}
      onClose={handleImportClose}
      isLoading={isLoading || isTaskReportLoading}
    >
      <FormProvider {...methods}>
        <form className={classes.formWrap}>
          <FormField fieldArray={fieldArray} errors={methods.errors} />
        </form>
        {taskReportLoadError?.nonFieldErrors && (
          <span className={classes.error}>{taskReportLoadError?.nonFieldError}</span>
        )}
      </FormProvider>
    </Popup>
  );
};

MoodyReportPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default MoodyReportPopup;
