import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export const defaultValues = {
  username: '',
  password: '',
};

export const resolver = yupResolver(
  Yup.object({
    username: Yup.string().trim().required('Username is required'),
    password: Yup.string().trim().required('Password is required'),
  }),
);
