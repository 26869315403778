import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    formInput: {
      width: 96,
      height: 24,
      opacity: 1,
      fontSize: theme.typography.pxToRem(12),
      backgroundColor: theme.palette.athensGray2,
      '&:focus': {
        border: [2, 'solid', theme.palette.secondary.main],
      },
      padding: [0, 5],
    },
    datepicker: {
      '& .MuiInputBase-root': {
        padding: [4, 8],
        width: 96,
        height: 24,
        opacity: 1,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(16),
        backgroundColor: theme.palette.athensGray2,
        '&:focus': {
          border: [2, 'solid', theme.palette.secondary.main],
        },
      },
      '& .MuiInputBase-input': {
        padding: 0,
      },
    },
    select: {
      backgroundColor: theme.palette.athensGray2,
      border: `2px solid ${theme.palette.catskillWhite}`,
      height: 28,
    },
    fieldError: {
      minWidth: '100%',
      maxWidth: 0,
    },
  }),
  { name: 'EditableCell' },
);
