import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';
import { ERROR_MESSAGES, LOAN_HASH } from 'src/enums';
import FundingNotesRepository from 'repositories/FundingNotesRepository';
import SuccessorBorrowersRepository from 'repositories/SuccessorBorrowersRepository';

import { DateConst } from 'const';

import AddressPresenter from 'presenters/AddressPresenter';

import { transformDate, transformToNull } from 'utils/date';
import { transformNumberNullableValue } from 'utils/dataTransform';
import { preventEmptyString, testNumberLength } from 'utils/yupHelpers';

export const resolver = yupResolver(
  Yup.object({
    dealName: Yup.string().trim().required(ERROR_MESSAGES.required),
    assumedDate: Yup.string()
      .transform(transformDate)
      .typeError(ERROR_MESSAGES.required)
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat)
      .required(ERROR_MESSAGES.required),
    accountingPayoffDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    dealKickoffDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    draftDefeasanceDocsReceivedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    maturityDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    noteDated: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    noteDatedNyOn: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    originalLoanDocumentsReviewedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    originalDefeasanceNotOriginalSbDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayConservativeDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayNoticeDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayNoticeLenderDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayNoticeCustodianDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayType: Yup.string().trim().nullable(),
    isAccountReportUploaded: Yup.boolean().nullable(),
    balanceAdjustment: Yup.number()
      .transform(preventEmptyString)
      .test('length', ERROR_MESSAGES.numberIsTooLong, testNumberLength)
      .nullable(),
    oidBalanceAdjustmentNote: Yup.string(),
    isAllowBuyThrough: Yup.boolean().nullable(),
    allowBuyThroughDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    docAllowBuyThroughDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    isBuyUp: Yup.boolean().nullable(),
    forwardRate: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    buyUpEstimate: Yup.number().transform(preventEmptyString).nullable(),
    finalReviewDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    firstScheduledPaymentDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    parRepayNoticeSentDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    loanSummaryPreparedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    postClosingAdminletterSentToServicerDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    closingBinderReceivedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    committeePackagePreparedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    finalDocumentsDeliveredToServicercounselDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    certifiedArticlesSentToServicerCounselDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    executionDefeasanceDocumentsReceivedDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    monetizationDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    sideLetterTerminationDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    isCrossDefaulted: Yup.boolean().nullable(),
    isInterestOnly: Yup.boolean().nullable(),
    status: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    hasSideLetter: Yup.string().trim().nullable(),
    isRatingAgencyConsentRequired: Yup.boolean().nullable(),
    isMonthlyPaymentAmountSame: Yup.boolean().nullable(),
    isNewYorkStyle: Yup.boolean().nullable(),
    isOriginalLoanDocumentsIssuesResolved: Yup.boolean().nullable(),
    isAddInterestCollected: Yup.boolean().nullable(),
    isMaturityNonbusiness: Yup.boolean().nullable(),
    isParRepayNegated: Yup.boolean().nullable(),
    monetizationFutureValue: Yup.number().transform(preventEmptyString).nullable(),
    sharingArrangementAmount: Yup.number().transform(preventEmptyString).nullable(),
    float2Received: Yup.number().transform(preventEmptyString).nullable(),
    parRepayCashReceived: Yup.number().transform(preventEmptyString).nullable(),
    buyUpPaymentDate: Yup.string().transform(transformDate).nullable(),
    adjustedParRepayBusinessDays2: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    isPartialDefeasance: Yup.boolean().nullable(),
    filingRef: Yup.string().nullable(),
    amortization: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    balloonPaymentAmount: Yup.number().transform(preventEmptyString).nullable(),
    buyUpAmount: Yup.number().transform(preventEmptyString).nullable(),
    costOfSecurities: Yup.number().transform(preventEmptyString).nullable(),
    coupon: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    custodianFee: Yup.number().transform(preventEmptyString).nullable(),
    dhcLegalFee: Yup.number().transform(preventEmptyString).nullable(),
    securitiesPurchasedFee: Yup.number().transform(preventEmptyString).nullable(),
    causeyAccruedInterest: Yup.number().transform(preventEmptyString).nullable(),
    floatRate: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    floatReceivedToDate: Yup.number().transform(preventEmptyString).nullable(),
    float1Received: Yup.number().transform(preventEmptyString).nullable(),
    monthlyPaymentAmount: Yup.number().transform(preventEmptyString).nullable(),
    originalBorrowerPercentageSplit: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    originalPrincipalBalanceNy: Yup.number().transform(preventEmptyString).nullable(),
    originalPrincipalBalance: Yup.number().transform(preventEmptyString).nullable(),
    principalBalanceAtTimeOfDefeasance: Yup.number().transform(preventEmptyString).nullable(),
    paymentDay: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    sharingArrangementPaymentDate: Yup.string().transform(transformDate).nullable(),
    sbAssumption: Yup.number().transform(preventEmptyString).nullable(),
    successorBorrowerPercentageSplit: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    term: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    thirdPartyFees: Yup.number().transform(preventEmptyString).nullable(),
    sideLetterTriggerYear: Yup.string()
      .trim()
      .nullable()
      .matches(/^(\s*|19[0-9][0-9]|2[0,1][0-9][0-9])$/, 'Must be a valid year'),
    postClosing: Yup.object({
      floatRate: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
      estimatedFloat: Yup.string(),
      floatReceived: Yup.string(),
      floatReceivedNote: Yup.string(),
    }),
    parRepayNetIncomeNotes: Yup.string().transform(transformToNull).nullable(),
    sbPrepayFees: Yup.number().transform(preventEmptyString).nullable(),
    totalProceeds: Yup.number().transform(preventEmptyString).nullable(),
    mortgageInterest: Yup.number().transform(preventEmptyString).nullable(),
    parRepayCashReceivedNotes: Yup.string().transform(transformToNull).nullable(),
    parRepayNetIncome: Yup.number().transform(preventEmptyString).nullable(),
    adjustedParRepayBusinessDaysDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    successorBorrower: Yup.string()
      .typeError(ERROR_MESSAGES.required)
      .required(ERROR_MESSAGES.required),
    collateralValue: Yup.number().transform(preventEmptyString).nullable(),
    totalAvailableAdvance: Yup.number().transform(preventEmptyString).nullable(),
    hedgeDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    notionalAmount: Yup.number().transform(preventEmptyString).nullable(),
    hedgeCounterpartyRef: Yup.string().trim(),
    hedgeEndDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    hedgedValue: Yup.number().transform(preventEmptyString).nullable(),
    hedgeRate: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    paybackDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    hedgeDate2: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    notionalAmount2: Yup.number().transform(preventEmptyString).nullable(),
    hedgeCounterpartyRef2: Yup.string().trim(),
    hedgeEndDate2: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    hedgedValue2: Yup.number().transform(preventEmptyString).nullable(),
    hedgeRate2: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(transformNumberNullableValue),
    paybackDate2: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    truistNote: Yup.number().nullable(),
  }),
);

const booleanOptions = [
  {
    key: true,
    displayedName: 'Yes',
    value: true,
  },
  { key: false, displayedName: 'No', value: false },
];

export const loanFields = {
  details: {
    title: 'Loan Details',
    hash: LOAN_HASH.details,
    fields: [
      {
        fieldKey: 'dealName',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Deal Name',
        isRequired: true,
      },
      {
        fieldKey: 'dealManager',
        displayedName: 'Deal Manager',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'users',
        placeholder: 'Select Manager',
      },
      {
        fieldKey: 'filingRef',
        displayedName: 'Filing Ref',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'bloombergName',
        displayedName: 'Bloomberg Name',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'successorBorrower',
        displayedName: 'Successor Borrower',
        type: FORM_FIELD_TYPE.selectAutocomplete,
        fetchOptions: SuccessorBorrowersRepository.choices,
        placeholder: 'Select Successor Borrower',
        isRequired: true,
      },
      {
        fieldKey: 'status',
        displayedName: 'Status',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'loanStatuses',
        placeholder: 'Select Status',
        isRequired: true,
      },
      {
        fieldKey: 'statusNote',
        displayedName: 'Status Note',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'propertyAddress',
        type: FORM_FIELD_TYPE.address,
        fields: AddressPresenter.formFields({
          prefix: 'propertyAddress',
          titleName: 'Property Address',
          prefixTitle: 'Property',
        }),
      },
      {
        fieldKey: 'loanType',
        displayedName: 'Property Type',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'loanTypes',
        placeholder: 'Select Property Type',
      },
      {
        fieldKey: 'noteDated',
        displayedName: 'Original or Assumed (if NY-Style) Note Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'noteName',
        displayedName: 'Original or Assumed Note Name',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'noteDatedNyOn',
        displayedName: 'Original Note Date (if NY-Style used above)',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'noteNameNy',
        displayedName: 'Original Note Name if NY Style Used Above',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'assumedDate',
        displayedName: 'Assumed Date',
        type: FORM_FIELD_TYPE.date,
        isRequired: true,
      },
      {
        fieldKey: 'otherLoanDocsAssumed',
        displayedName: 'Other Original Loan Docs Assumed',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalDefeasanceNotOriginalSbDate',
        displayedName: 'Original Defeasance Date (if not original SB)',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'firstScheduledPaymentDate',
        displayedName: 'First Scheduled SB Payment Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayDate',
        displayedName: 'Aggressive Par Repay Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayConservativeDate',
        displayedName: 'Conservative Par Repay Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayType',
        displayedName: 'Par Repay Type',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'parRepayTypes',
        placeholder: 'Select Par Repay Type',
      },
      {
        fieldKey: 'parRepayTimeLanguage',
        displayedName: 'Par Repay Time Language',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isParRepayNegated',
        displayedName: 'PRP Negated in defeasance docs',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKeyEnum: 'isDocAllowBuyThrough',
        fieldKeyInput: 'docAllowBuyThroughDate',
        displayedName:
          'Loan document language allows borrower to buy through the early date; Original Maturity Date Was:',
        type: FORM_FIELD_TYPE.enumDate,
        enumOptions: booleanOptions,
      },
      {
        fieldKeyEnum: 'isAllowBuyThrough',
        fieldKeyInput: 'allowBuyThroughDate',
        displayedName:
          'Lender allowing original borrower to buy through the early date; Original Maturity Date Was:',
        type: FORM_FIELD_TYPE.enumDate,
        enumOptions: booleanOptions,
      },
      {
        fieldKey: 'parRepayNote',
        displayedName: 'Par Repay Note',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'parRepayNoticeDate',
        displayedName: 'Par Repay Notice By Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayNoticeSentDate',
        displayedName: 'Par Repay Notice Sent Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayNoticeLenderDate',
        displayedName: 'PRP Notice to Day Lender',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'parRepayNoticeCustodianDate',
        displayedName: 'PRP Notice to Custodian',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'maturityDate',
        displayedName: 'Maturity Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'isMaturityNonbusiness',
        displayedName: 'Maturity Date is non-business',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'isAddInterestCollected',
        displayedName: 'Additional Interest Collected',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'businessdayConvention',
        displayedName: 'Business Day Convention',
        type: FORM_FIELD_TYPE.enum,
        options: [
          {
            key: 'before',
            displayedName: 'Before',
            value: 'Before',
          },
          {
            key: 'after',
            displayedName: 'After',
            value: 'After',
          },
          {
            key: 'silent',
            displayedName: 'Silent',
            value: 'Silent',
          },
        ],
      },
      {
        fieldKey: 'gracePeriodPayments',
        displayedName: 'Grace Period Payments',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'gracePeriodMaturity',
        displayedName: 'Grace Period Maturity',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'maturityDateNote',
        displayedName: 'Maturity Date Note',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'paymentDay',
        displayedName: 'Payment Day',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalLender',
        displayedName: 'Original Lender',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalPrincipalBalance',
        displayedName: 'Original or Assumed (if NY-Style) Principal Balance',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'originalPrincipalBalanceNy',
        displayedName: 'Original Principal Balance (if partial or NY-Style used above)',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'principalBalanceAtTimeOfDefeasance',
        displayedName: 'Principal Balance at Time of Defeasance',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'costOfSecurities',
        displayedName: 'Cost of Securities',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'securitiesUsed',
        displayedName: 'Securities Used',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'securitiesUserTypes',
        placeholder: 'Select One',
      },
      {
        fieldKey: 'securitiesBrokerDealer',
        displayedName: 'Securities Broker Dealer',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'organizations',
        placeholder: 'Select Securities Broker Dealer',
      },
      {
        fieldKey: 'monthlyPaymentAmount',
        displayedName: 'Monthly Payment Amount',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'isMonthlyPaymentAmountSame',
        displayedName: 'Monthly Payment Amount Constant',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'balloonPaymentAmount',
        displayedName: 'Balloon Payment',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'isInterestOnly',
        displayedName: 'Interest Only',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'monthlyPaymentAmountNotes',
        displayedName: 'Monthly Payment Amount Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'statementBalanceNotes',
        displayedName: 'Statement Balance Issue Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'term',
        displayedName: 'Term',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'amortization',
        displayedName: 'Amortization',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'coupon',
        displayedName: 'Coupon',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'interestMethod',
        displayedName: 'Interest Method',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'interestMethods',
        placeholder: 'Select Interest Method',
      },
      {
        fieldKey: 'isRatingAgencyConsentRequired',
        displayedName: 'Rating Agency Consent Required',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'ratingAgencyConsentNotes',
        displayedName: 'Rating Agency Consent Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isNewYorkStyle',
        displayedName: 'New York Style',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'newLenderText',
        displayedName: 'New Lender if NY Style',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isPartialDefeasance',
        displayedName: 'Partial or Received',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'partialDefeasanceNotes',
        displayedName: 'Partial Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isCrossDefaulted',
        displayedName: 'Cross-defaulted or Cross-collateralized',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'crossDefaultedNotes',
        displayedName: 'Cross Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'dealNotes',
        displayedName: 'Deal Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'dealKickoffDate',
        displayedName: 'Deal Kickoff Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'sbAssumption',
        displayedName: 'SB Assumption Fee',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'dhcLegalFee',
        displayedName: 'DHC Legal Fee',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'securitiesPurchasedFee',
        displayedName: 'Securities Purchased Fee',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'causeyAccruedInterest',
        displayedName: "Causey's Accrued Interest",
        type: FORM_FIELD_TYPE.decimal,
      },
    ],
  },

  parties: {
    title: 'Parties',
    hash: LOAN_HASH.parties,
    fields: [
      {
        fieldKey: 'masterServicerLoanNumber',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Master Servicer Loan Number',
      },
      {
        fieldKey: 'masterServicerName',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Master Servicer',
        isReadonly: true,
      },
      {
        fieldKey: 'loanServicer',
        displayedName: 'Loan Statement Servicer',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'organizations',
        placeholder: 'Select Loan Servicer',
      },
      {
        fieldKey: 'loanServicerLoanNumber',
        displayedName: 'Loan Servicer Loan Number',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'alternateLoanNumber',
        displayedName: 'Alternate Loan #:',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'alternateLoanNote',
        displayedName: 'Alternate Loan Note:',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'servicerCounsel1',
        displayedName: 'Servicer Counsel Contact 1:',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'servicerCounselPersons',
        placeholder: 'Select Servicer Counsel',
      },
      {
        fieldKey: 'servicerCounsel2',
        displayedName: 'Servicer Counsel Contact 2:',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'servicerCounselPersons',
        placeholder: 'Select Servicer Counsel',
      },
      {
        fieldKey: 'custodian',
        displayedName: 'Custodian',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'custodianContact',
        placeholder: 'Custodian',
      },
      {
        fieldKey: 'custodianAccountNumber',
        displayedName: 'Custodian Account Number',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'custodianFee',
        displayedName: 'Custodian Fee',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'defaultPermittedInvestment',
        displayedName: 'Default Permitted Investment:',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'permittedInvestments',
        placeholder: 'Select Default Permitted Investment',
      },
      {
        fieldKey: 'accountant',
        displayedName: 'Accountant:',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'organizations',
        placeholder: 'Select Accountant',
      },
      {
        fieldKey: 'accountantReportTitle',
        displayedName: 'Accountant Report Title',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'accountantReportType',
        displayedName: 'Accountant Report Type',
        type: FORM_FIELD_TYPE.enum,
        options: [
          {
            key: 'singleDeal',
            displayedName: 'Single Deal',
            value: 'Single Deal',
          },
          {
            key: 'sharedPortfolio',
            displayedName: 'Shared Portfolio',
            value: 'Shared Portfolio',
          },
        ],
      },
      {
        fieldKey: 'accountantReportNotes',
        displayedName: 'Accountant Report Type Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'defeasanceConsultant',
        displayedName: 'Defeasance Consultant',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'organizations',
        placeholder: 'Select Defeasance Consultant',
      },
      {
        fieldKey: 'sbDesignation',
        displayedName: 'SB Designation/Referral',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'partyToDesignateSb',
        displayedName: 'Party to Designate SB',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'partySbDesignations',
        placeholder: 'Select Party',
      },
      {
        fieldKey: 'originalBorrowerName',
        displayedName: 'Original Borrower Name',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerFormationEntityType',
        displayedName: 'Original Borrower Formation Entity Type',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerContact',
        displayedName: 'Original Borrower Contact',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerPhone',
        displayedName: 'Original Borrower Phone',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerEmail',
        displayedName: "Original Borrower's Email",
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCompany',
        displayedName: 'Original Borrower Company',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerNotes',
        displayedName: 'Original Borrower Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCounselContact',
        displayedName: 'Original Borrower Counsel Contact',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCounselPhone',
        displayedName: 'Original Borrower Counsel Phone',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCounselEmail',
        displayedName: 'Original Borrower Counsel Email',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCounselCompany',
        displayedName: 'Original Borrower Counsel Company',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalBorrowerCounselNotes',
        displayedName: 'Original Borrower Counsel Notes',
        type: FORM_FIELD_TYPE.input,
      },
    ],
  },

  dealChecklist: {
    title: 'Deal Checklist',
    hash: LOAN_HASH.dealChecklist,
    fields: [
      {
        fieldKey: 'draftDefeasanceDocsReceivedDate',
        displayedName: 'Draft Defeasance Docs Received Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'draftDefeasanceDocsComments',
        displayedName: 'Draft Defeasance Docs Comments Provided',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sbType',
        displayedName: 'SB Type',
        type: FORM_FIELD_TYPE.enum,
        options: [
          {
            key: 'new',
            displayedName: 'New',
            value: 'New',
          },
          {
            key: 'existing',
            displayedName: 'Existing',
            value: 'Existing',
          },
          {
            key: 'tbd',
            displayedName: 'TBD',
            value: 'TBD',
          },
        ],
      },
      {
        fieldKey: 'einRequested',
        displayedName: 'EIN Requested',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'organizationDocsSent',
        displayedName: 'SB Certificate of formation Sent For Filing',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sosFilingConfirmed',
        displayedName: 'SOS Filing Confirmed',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'einReceived',
        displayedName: 'EIN Received',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'bankAccountsRequested',
        displayedName: 'Bank Accounts Requested',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'opinionCertsPreparedSent',
        displayedName: 'Opinion Certs Prepared/Sent',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sbInfoSentToServicercounsel',
        displayedName: "SB Info (EIN/SOSID) Sent to Servicer's Counsel",
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sbDocsFormedSentToServicerCounsel',
        displayedName: 'SB Docs Formed / Sent to Servicer’s Counsel',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'defeasanceDocsSentToOutsidecounsel',
        displayedName: 'Defeasance Docs Sent to Outside Counsel',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sbDraftAuthorizationOpinionPrepared',
        displayedName: 'SB Draft Authorization Opinion Prepared',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sbDraftNonconOpinionPrepared',
        displayedName: 'SB Draft Noncon / Authority to File Opinions',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'certifiedArticlesRequested',
        displayedName: 'Updated Good Standings Requested',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'certifiedArticlesSentToServicerCounselDate',
        displayedName: "Updated Good Standings Sent to Servicer's Counsel",
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'originalLoanDocumentsRequested',
        displayedName: 'Original Loan Documents Requested',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalLoanDocumentsReceived',
        displayedName: 'Original Loan Documents Received',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'originalLoanDocumentsReviewedDate',
        displayedName: 'Original Loan Documents Reviewed Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'originalLoanReviewer',
        displayedName: 'Original Loan Documents Reviewed by',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'users',
        placeholder: 'Select User',
      },
      {
        fieldKey: 'originalLoanDocumentsIssues',
        displayedName: 'Original Loan Documents Issues',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isOriginalLoanDocumentsIssuesResolved',
        displayedName: 'Original Loan Documents Issues Resolved',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'draftAccountantReportReceived',
        displayedName: 'Draft Accountant Report Received',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'draftAccountantReportReviewed',
        displayedName: 'Draft Accountant Report Reviewed',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'servicerAmortizationScheduleReceived',
        displayedName: 'Servicer Am Schedule Recieved',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'servicerAmortizationScheduleReviewed',
        displayedName: 'Servicer Am Schedule Reviewed',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'executionDefeasanceDocumentsReceivedDate',
        displayedName: 'Execution Defeasance Documents Received Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'committeePackagePreparedDate',
        displayedName: 'Committee Package Prepared Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'loanSummaryPreparedDate',
        displayedName: 'Loan Summary Prepared Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'finalDocumentsDeliveredToServicercounselDate',
        displayedName: "Final Documents Delivered to Servicer's Counsel Date",
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'postClosingAdminletterSentToServicerDate',
        displayedName: 'Post Closing Admin Letter Sent to Servicer',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'finalReviewDate',
        displayedName: 'Final Review Completed Date',
        type: FORM_FIELD_TYPE.date,
      },
    ],
  },

  noteIssues: {
    title: 'Note Issues',
    hash: LOAN_HASH.noteIssues,
    fields: [
      {
        fieldKey: 'noteIssues',
        displayedName: 'Note Issues',
        type: FORM_FIELD_TYPE.input,
        multiline: true,
      },
    ],
  },

  postClosing: {
    title: 'Post Closing',
    hash: LOAN_HASH.postClosing,
    fields: [
      {
        fieldKey: 'accountingPayoffDate',
        displayedName: 'Accounting Payoff Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'floatRate',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Float Rate, %',
      },
      {
        fieldKey: 'floatReceivedToDate',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Float Received To Date',
        isReadonly: true,
      },
      {
        fieldKey: 'float1Received',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Float 1 Received',
      },
      {
        fieldKey: 'float2Received',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Float 2 Received',
      },
      {
        fieldKey: 'parRepayCashReceived',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'PRP Total Cash Received',
      },
      {
        fieldKey: 'parRepayCashReceivedNotes',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'PRP Total Cash Received Notes',
      },
      {
        fieldKey: 'parRepayNetIncome',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'PRP Net Income',
      },
      {
        fieldKey: 'parRepayNetIncomeNotes',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'PRP Net Income Notes',
      },
      {
        fieldKey: 'sbPrepayFees',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'SB Prepay Fees',
      },
      {
        fieldKey: 'totalProceeds',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Total Proceeds',
      },
      {
        fieldKey: 'mortgageInterest',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Mortgage Interest',
      },
      {
        fieldKey: 'isClosingBinderReceived',
        displayedName: 'Closing Binder Received',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'closingBinderReceivedDate',
        type: FORM_FIELD_TYPE.date,
        displayedName: 'Closing Binder Received Date:',
      },
      {
        fieldKey: 'closingBinderIssues',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Closing Binder Issues',
      },
      {
        fieldKey: 'thirdPartyFees',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Third Party Fees',
      },
      {
        fieldKey: 'thirdPartyFeesNote',
        type: FORM_FIELD_TYPE.input,
        displayedName: 'Third Party Fees Note',
      },
      {
        fieldKey: 'thirdPartyFeesStatus',
        type: FORM_FIELD_TYPE.select,
        displayedName: 'Third Party Fees Status',
        selectValuesType: 'loanThirdPartyFeesStatuses',
        placeholder: 'Select Status',
      },
      {
        fieldKey: 'isAccountReportUploaded',
        displayedName: 'Accountant Report Uploaded',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'balanceAdjustment',
        displayedName: 'OID Balance Adjustment',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'oidBalanceAdjustmentNote',
        displayedName: 'OID Balance Adjustment Note',
        type: FORM_FIELD_TYPE.input,
        multiline: true,
      },
    ],
  },

  sharing: {
    title: 'Sharing',
    hash: LOAN_HASH.sharing,
    fields: [
      {
        fieldKey: 'hasSideLetter',
        displayedName: 'Side Letter',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'sideLetterNotes',
        displayedName: 'Side Letter Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sideLetterTriggerYear',
        displayedName: 'Side Letter Trigger Year',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sideLetterBorrowerPrincipal',
        displayedName: 'Side Letter Borrower Principal',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sideLetterNature',
        displayedName: 'Side Letter Nature',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'sideLetterNatureTypes',
        placeholder: 'Select Side Letter Nature',
      },
      {
        fieldKey: 'originalBorrowerPercentageSplit',
        displayedName: 'Original Borrower Percentage Split',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'successorBorrowerPercentageSplit',
        displayedName: 'Successor Borrower Percentage Split',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'sideLetterTerminationDate',
        displayedName: 'Side Letter Termination Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'sideLetterTerminationNotes',
        displayedName: 'Side Letter Termination Notes',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'isBuyUp',
        displayedName: 'Buy Up',
        type: FORM_FIELD_TYPE.enum,
        options: booleanOptions,
      },
      {
        fieldKey: 'forwardRate',
        displayedName: 'FW Rate',
        type: FORM_FIELD_TYPE.input,
      },
      {
        fieldKey: 'buyUpEstimate',
        type: FORM_FIELD_TYPE.decimal,
        displayedName: 'Buy Up Estimate',
      },
      {
        fieldKey: 'buyUpAmount',
        displayedName: 'Buy Up Amount',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'buyUpPaymentDate',
        displayedName: 'Buy Up Payment Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'sharingArrangementFinancialServicesOrg',
        displayedName: 'Sharing Arrangement',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'financialServices',
        placeholder: 'Select Sharing Arrangement',
      },
      {
        fieldKey: 'sharingArrangementAmount',
        displayedName: 'Sharing Arrangement Amount',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'sharingArrangementPaymentDate',
        displayedName: 'Sharing Arrangement Payment Date',
        type: FORM_FIELD_TYPE.date,
      },
    ],
  },

  monetization: {
    title: 'monetization',
    hash: LOAN_HASH.monetization,
    fields: [
      {
        fieldKey: 'monetizationParty',
        displayedName: 'Monet Party',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'organizations',
        placeholder: 'Select Organization',
      },
      {
        fieldKey: 'monetContractAdvance',
        displayedName: 'Monet Contract (Advance %)',
        type: FORM_FIELD_TYPE.select,
        selectValuesType: 'monetContractPercentages',
        placeholder: 'Select Contract',
      },
      {
        fieldKey: 'monetizationDate',
        displayedName: 'Monet Date',
        type: FORM_FIELD_TYPE.date,
      },
      {
        fieldKey: 'collateralValue',
        displayedName: 'Collateral Value',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'totalAvailableAdvance',
        displayedName: 'Total Available Advance',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'monetizationFutureValue',
        displayedName: 'Initial Future Value',
        type: FORM_FIELD_TYPE.decimal,
      },
      {
        fieldKey: 'truistNote',
        displayedName: 'Truist Note #',
        type: FORM_FIELD_TYPE.selectAutocomplete,
        placeholder: 'Select Funding Note',
        fetchOptions: params =>
          FundingNotesRepository.index({ ...params, isHedgingAmountReserved: false }),
        isRequired: false,
      },
      {
        fieldKey: 'adjustedParRepayBusinessDaysDate',
        displayedName: 'Payback Date',
        type: FORM_FIELD_TYPE.date,
      },
    ],
  },

  hedging: {
    title: 'Hedging',
    hash: LOAN_HASH.hedging,

    groups: [
      {
        displayedName: 'party 1',
        fields: [
          {
            fieldKey: 'hedgeParty',
            displayedName: 'Hedge Party',
            type: FORM_FIELD_TYPE.select,
            selectValuesType: 'organizations',
            placeholder: 'Select Organization',
          },
          {
            fieldKey: 'hedgeDate',
            displayedName: 'Hedge Date',
            type: FORM_FIELD_TYPE.date,
          },
          {
            fieldKey: 'notionalAmount',
            displayedName: 'Notional Amount',
            type: FORM_FIELD_TYPE.decimal,
          },
          {
            fieldKey: 'hedgeCounterpartyRef',
            displayedName: 'Hedge Counterparty Ref #',
            type: FORM_FIELD_TYPE.input,
          },
          {
            fieldKey: 'hedgeEndDate',
            displayedName: 'Hedge End Date',
            type: FORM_FIELD_TYPE.date,
          },
          {
            fieldKey: 'hedgedValue',
            displayedName: 'Hedged Value',
            type: FORM_FIELD_TYPE.decimal,
          },
          {
            fieldKey: 'hedgeRate',
            displayedName: 'Hedge Rate (Fed Fund Rate - Fixed Rate From Exhibit)',
            type: FORM_FIELD_TYPE.input,
          },
          {
            fieldKey: 'paybackDate',
            displayedName: 'Payback Date',
            type: FORM_FIELD_TYPE.date,
          },
        ],
      },
      {
        displayedName: 'party 2',
        fields: [
          {
            fieldKey: 'hedgeParty2',
            displayedName: 'Hedge Party',
            type: FORM_FIELD_TYPE.select, // [Picklist Financial Institututions]
            selectValuesType: 'organizations',
            placeholder: 'Select Organization',
          },
          {
            fieldKey: 'hedgeDate2',
            displayedName: 'Hedge Date',
            type: FORM_FIELD_TYPE.date,
          },
          {
            fieldKey: 'notionalAmount2',
            displayedName: 'Notional Amount',
            type: FORM_FIELD_TYPE.decimal,
          },
          {
            fieldKey: 'hedgeCounterpartyRef2',
            displayedName: 'Hedge Counterparty Ref #',
            type: FORM_FIELD_TYPE.input,
          },
          {
            fieldKey: 'hedgeEndDate2',
            displayedName: 'Hedge End Date',
            type: FORM_FIELD_TYPE.date,
          },
          {
            fieldKey: 'hedgedValue2',
            displayedName: 'Hedged Value',
            type: FORM_FIELD_TYPE.decimal,
          },
          {
            fieldKey: 'hedgeRate2',
            displayedName: 'Hedge Rate (Fed Fund Rate - Fixed Rate From Exhibit)',
            type: FORM_FIELD_TYPE.input,
          },
          {
            fieldKey: 'paybackDate2',
            displayedName: 'Payback Date',
            type: FORM_FIELD_TYPE.date,
          },
        ],
      },
    ],
  },
};

export const loanSections = Object.keys(loanFields);
