import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { isNil, isEmpty } from 'ramda';

import MembersNestedList from 'components/MembersNestedList';

import { USER_NOTIFICATION } from 'enums';

import { getNestedTableData } from 'presenters/InterestAccrualReportPresenter';

import { appRoutes } from 'router/routes';

import { createDetailsLink } from 'utils/links';

import useStyles from './useStyles';

const SUCCESSOR_BORROWER_LINK_NAME = 'Successor Borrower Details';
const LOAN_LINK_NAME = 'Loan Details';
const COLUMN_HEADER = 'Accrued Interest';

export const ReportTable = props => {
  const { report } = props;
  const classes = useStyles();

  if (isNil(report)) return null;

  const tableData = getNestedTableData(report);
  const { members } = tableData;

  if (isEmpty(members))
    return <p className={classes.notification}>{USER_NOTIFICATION.noResults}</p>;

  return (
    <div className={classes.listWrapper}>
      <MembersNestedList
        members={members}
        successorBorrowerDetailsLink={createDetailsLink(
          appRoutes.successorBorrowerPath,
          SUCCESSOR_BORROWER_LINK_NAME,
        )}
        loanDetailsLink={createDetailsLink(appRoutes.loanPath, LOAN_LINK_NAME)}
        valueColumnHeaderText={COLUMN_HEADER}
      />
    </div>
  );
};

ReportTable.propTypes = {
  report: PropTypes.shape({
    members: arrayOf(PropTypes.shape()),
  }),
};
