import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    reportWrap: {
      marginLeft: 'unset',
    },
    headerWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    recalculateButton: {
      alignSelf: 'center',
      height: '32px',
      background: 'rgba(19, 51, 76, 0.07)',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.elephant,
    },
  }),
  { name: 'ReconciliationDiscrepanciesReport' },
);
