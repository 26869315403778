import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    formRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: 14,
      marginLeft: -8,
    },
  }),
  { name: 'FormRowCheckbox' },
);
