import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import BarChart from 'components/BarChart';
import GlobalSnapshot from 'components/GlobalSnapshot';
import YTDSnapshot from 'components/YTDSnapshot';

import * as GlobalSnapshotService from 'domain/globalSnapshot/service';
import { useGetGlobalSnapshotQuery } from 'domain/globalSnapshot/apiSlice';

import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { getHomeLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Overview';

export const HomeOverview = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const {
    data,
    isFetching: isGlobalSnapshotLoading,
    isError: isGlobalSnapshotLoadError,
    error: globalSnapshotLoadError,
  } = useGetGlobalSnapshotQuery();

  const assumeLoanRates = GlobalSnapshotService.getRates(data?.rates.assumeLoans);
  const dhcOnlyRates = GlobalSnapshotService.getRates(data?.rates.dhcOnlyDeals);
  const globalSnapshotData = GlobalSnapshotService.getGlobalSnapshotData(data);
  const YTDData = GlobalSnapshotService.getYtdData(data);
  const totalAssumedYTDData = GlobalSnapshotService.getTotalAssumedYTDData(data);

  useEffect(() => {
    if (isGlobalSnapshotLoadError) {
      displayErrorsInToast([globalSnapshotLoadError]);
    }
  }, [isGlobalSnapshotLoadError]);

  const sideBarContent = getHomeLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isGlobalSnapshotLoading}>
      <ContentLayout>
        <DocumentTitle title={TITLE} />
        <Typography variant="h1">{TITLE}</Typography>
        <div className={classes.snapshotWrapper}>
          <GlobalSnapshot globalSnapshotData={globalSnapshotData} title="Global Snapshot" />
          <div>
            <YTDSnapshot data={YTDData} needDiff needLabels />
            <YTDSnapshot data={totalAssumedYTDData} />
          </div>
        </div>
        <h3 className={classes.contentTitle}>Rates</h3>
        <BarChart
          className={classes.chart}
          title="Rate At Which We Assume Loans"
          datasets={assumeLoanRates?.dataset}
          labels={assumeLoanRates?.labels}
        />
        <BarChart
          className={classes.chart}
          title="Rate At Which We Receive DHC ONLY Deals"
          datasets={dhcOnlyRates?.dataset}
          labels={dhcOnlyRates?.labels}
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
