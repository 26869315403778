import React, { useEffect, useState } from 'react';

import { capitalize, MenuItem, Select } from '@material-ui/core';
import { keys } from 'ramda';
import clsx from 'clsx';
import { SUMMARY_OF_DEALS_PERIODS } from 'enums/summaryOfDealsPeriods';

import Header from 'components/Header';
import ListTable from 'components/ListTable';
import DocumentTitle from 'components/DocumentTitle';

import { useSummaryOfDeals } from 'hooks/api/useSummaryOfDeals';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import useStyles from 'pages/SummaryOfDeals/useStyles';

import { getSummaryOfDealsColumnConfig } from 'presenters/SummaryOfDealsPresenter';

const TITLE = 'Deals Assumed: Historical Data';

export const SummaryOfDeals = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const [periodType, setPeriodType] = useState(keys(SUMMARY_OF_DEALS_PERIODS)[1]);

  const { SummaryOfDealsTableData, areSummaryOfDealsLoading, loadSummaryOfDeals } =
    useSummaryOfDeals();

  const { elementRef, elementHeight } = useRefElementHeight();

  const handlePeriodSelectChange = e => {
    setPeriodType(e.target.value);
  };

  const summaryOfDealsColumnConfig = getSummaryOfDealsColumnConfig(periodType);

  const fetchData = async () => {
    try {
      await loadSummaryOfDeals(`${periodType}ly`);
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, [periodType]);

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState isHorizontal>
        <div className={classes.selectContainer}>
          <Select
            className={clsx(classes.select, classes.selectWide)}
            classes={{ root: classes.select }}
            variant="standard"
            name="periodSelect"
            defaultValue={periodType}
            onChange={handlePeriodSelectChange}
          >
            {Object.keys(SUMMARY_OF_DEALS_PERIODS).map(option => (
              <MenuItem className={classes.menuItem} key={option} value={option}>
                {`${capitalize(option)}ly`}
              </MenuItem>
            ))}
          </Select>
        </div>
      </Header>
      <ListTable
        isLoading={areSummaryOfDealsLoading}
        tableHeader={keys(summaryOfDealsColumnConfig)}
        tableData={SummaryOfDealsTableData}
        columnConfig={summaryOfDealsColumnConfig}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
