import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    username: PropTypes.string,
  },
  {
    userName(user) {
      return user?.username;
    },
  },
);
