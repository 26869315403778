import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    errorMessage: {
      width: 'min-content',
      minWidth: 'fill-available',
      textAlign: 'right',
      marginTop: '3px',
      marginBottom: '3px',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.coralRed,
    },
    label: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.4)',
      marginBottom: '8px',
    },
  }),
  { name: 'JournalEntriesDatePicker' },
);
