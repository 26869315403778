export default {
  MuiFormGroup: {
    root: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexFlow: 'row wrap',
      flexDirection: 'row',
    },
  },
};
