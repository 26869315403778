import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import ToolsRepository from 'repositories/ToolsRepository';
import { TimersConst } from 'const/TimersConst';
import { ReportStatusConst } from 'const/ReportStatusConst';

const initialState = {
  isTaskReportLoading: false,
  taskReport: null,
  taskReportLoadError: false,
};

const reportTaskReducer = {
  startReportTaskLoad(state) {
    state.isTaskReportLoading = true;
    state.taskReport = null;
    state.taskReportLoadError = null;
  },
  reportTaskLoadFulfilled(state, { payload }) {
    state.isTaskReportLoading = false;
    state.taskReport = payload;
  },
  reportTaskLoadRejected(state, { payload }) {
    state.isTaskReportLoading = false;
    state.taskReport = null;
    if (payload) {
      state.taskReportLoadError = payload;
    }
  },
  resetReportTask(state) {
    state.isTaskReportLoading = false;
    state.taskReport = null;
    state.taskReportLoadError = false;
  },
};

const taskReportSlice = createSlice({
  name: 'taskReport',
  initialState,
  reducers: {
    ...reportTaskReducer,
  },
});

export default taskReportSlice.reducer;

export const useTaskReportUrlActions = () => {
  const dispatch = useDispatch();

  const finishLoading = error => {
    dispatch(taskReportSlice.actions.reportTaskLoadRejected(error));
  };

  const loadTaskReport = taskLocation =>
    ToolsRepository.taskReport(taskLocation)
      .then(response => {
        const status = response.data?.data?.status;
        if (status === ReportStatusConst.FAILURE) {
          return {
            errors: response.data?.data?.errors,
            isError: true,
          };
        }
        if (status === ReportStatusConst.SUCCESS) {
          return response.headers.location;
        }
        return false;
      })
      .catch(e => ({
        errors: e,
        isError: true,
      }));

  const initiateTaskReportLoading = async (callback, data = {}, queryParams) => {
    dispatch(taskReportSlice.actions.startReportTaskLoad());
    const taskLocation = await callback(data, queryParams);
    let result = await loadTaskReport(taskLocation);

    return new Promise((resolve, reject) => {
      const checkingLocationInterval = setInterval(async () => {
        if (result) {
          clearInterval(checkingLocationInterval);
          if (result.isError) {
            reject(result.errors);
            return null;
          }
          dispatch(taskReportSlice.actions.reportTaskLoadFulfilled(result));
          resolve(result);
          return result;
        }
        result = await loadTaskReport(taskLocation);
        return false;
      }, TimersConst.REQUEST_INTERVAL_TIME);
    });
  };

  const resetReportTask = async () => dispatch(taskReportSlice.actions.resetReportTask());

  return {
    loadTaskReport,
    initiateTaskReportLoading,
    finishLoading,
    resetReportTask,
  };
};
