import { useEffect, useRef, useState, useContext } from 'react';

import { slice, isNil, isEmpty } from 'ramda';

import { ScrollingContext } from 'contexts/ScrollingContext';

import useRouter from 'hooks/useRouter';

import { getHeaderHeight } from 'utils/getElementHeight';

const getHashName = hash => slice(1, Infinity, hash);

export const useScrollingContext = () => useContext(ScrollingContext);

export const useScrolling = () => {
  const scrollContainerRef = useRef(null);
  const [headerRef, setHeaderRef] = useState(null);
  const [scrollMarginTop, setScrollMarginTop] = useState(null);

  const {
    location: { hash, pathname },
  } = useRouter();

  useEffect(() => {
    const containerElement = scrollContainerRef.current;
    if (isNil(containerElement) || !isEmpty(hash)) return;
    containerElement.scrollTop = 0;
  }, [pathname, scrollContainerRef]);

  useEffect(() => {
    const height = getHeaderHeight(headerRef);
    if (height) setScrollMarginTop(height);
  }, [headerRef]);

  useEffect(() => {
    if (isEmpty(hash) && isNil(scrollMarginTop)) return;
    const id = getHashName(hash);
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollMarginTop]);

  return {
    scrollContainerRef,
    headerRef,
    setHeaderRef,
    scrollMarginTop,
  };
};
