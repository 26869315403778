import React from 'react';

import PropTypes from 'prop-types';

import useStyles from './useStyles';

const DEFAULT_MESSAGE = 'No Data to display';

export const NoDataMessage = props => {
  const { message = DEFAULT_MESSAGE } = props;
  const classes = useStyles();

  return <p className={classes.noDataMessage}>{message}</p>;
};

NoDataMessage.propTypes = {
  message: PropTypes.string,
};
