import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    header: {
      backgroundColor: theme.palette.linkWater,
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: 2,
      paddingBottom: '16px',
      paddingTop: '12px',
    },
    contentContainer: {
      position: 'sticky',
      left: '24px',
      width: 'calc(100vw - 64px)',
    },
    titleSection: {
      marginTop: '16px',
      marginBottom: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'end',
    },
    title: {
      margin: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '60ch',
    },
    downloadButton: {
      marginLeft: '20px',
      height: '32px',
      background: 'rgba(19, 51, 76, 0.07)',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.elephant,
    },
    link: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      marginLeft: '20px',
    },
    line: {
      border: `1px solid ${theme.palette.mercury}`,
    },
  }),
  { name: 'OidReportDetailsHeader' },
);
