import { isEmpty, isNil, pathOr, pick } from 'ramda';

export const getConfigurationValue = (configuration, fieldName, defaultValue = '') =>
  configuration ? configuration[fieldName] : defaultValue;

export const isBlank = value => isNil(value) || isEmpty(value);

export const selectResultsFromResponse = response =>
  pathOr(undefined, ['data', 'results'], response);

export const selectDataFromResponse = response => pathOr(undefined, ['data', 'data'], response);

export const selectLocationFromResponse = response =>
  pathOr(undefined, ['headers', 'location'], response);

export const pickSerializibleDataFromResponse = response => pick(['headers', 'data'], response);

export const selectDocumentationFromResponse = response =>
  pathOr(undefined, ['data', 'data', 'documentation'], response);
