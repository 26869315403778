import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    backButton: {
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      textDecoration: 'none',
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: 0,
    },
    contentPanel: {
      padding: [32, 24],
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
      backgroundColor: theme.palette.white,
    },
    panelWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
    },
    actionPanel: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: theme.sizes.actionPanel.marginBottom,
      '& > button': {
        marginRight: 8,
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    deleteItem: {
      color: theme.palette.mandy,
      '&:hover': {
        color: theme.palette.white,
        backgroundColor: theme.palette.mandy,
      },
    },
    contentWrap: {
      marginTop: 32,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    contentSidebar: {
      width: 300,
      marginLeft: 60,
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
    },
    checkboxWrap: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    contentForm: {
      width: '100%',
    },
    lastUpdated: {
      textAlign: 'right',
      color: theme.palette.primary,
    },
    lastUpdatedTitle: {
      fontSize: theme.typography.pxToRem(12),
      opacity: 0.4,
    },
    lockEditForm: {
      marginRight: 0,
      marginBottom: theme.spacing(4),
    },
  }),
  { name: 'SuccessorBorrowerForm' },
);
