import { head, type, path, flatten, prop, isNil, propOr, keys } from 'ramda';
import { ERROR_MESSAGES } from 'src/const';

const initialErrorsState = {
  backendServicesError: {},
  nonFieldErrors: {},
  fieldErrors: {},
};

export const sortServerError = (error = {}) => {
  if (error.isBackendServicesError) return { ...initialErrorsState, backendServicesError: error };

  const errorKeys = keys(error);

  const fieldErrors = errorKeys
    .filter(key => key !== 'nonFieldErrors')
    .reduce((acc, key) => {
      const fieldError = error[key];
      return { ...acc, [key]: fieldError };
    }, {});

  const nonFieldErrors = propOr(null, 'nonFieldErrors')(error);

  const result = {
    ...initialErrorsState,
    nonFieldErrors: nonFieldErrors ? { message: head(nonFieldErrors) } : {},
    fieldErrors,
  };

  return result;
};

export const parseServerError = (error = {}, methods) => {
  const errorKeys = Object.keys(error);
  const nonFieldErrors = error?.nonFieldErrors || [];

  const result = {
    nonFieldError: head(nonFieldErrors),
    fieldErrors: {},
  };

  errorKeys.forEach(errorKey => {
    if (errorKey !== 'nonFieldErrors') {
      result.fieldErrors[errorKey] = head(error[errorKey]);
      methods.setError(errorKey, { type: 'field', message: head(error[errorKey]) });
    }
  });

  return result;
};

export const setServerErrorsToFields = (setError, errors, formErrors) => {
  Object.keys(formErrors).forEach(key => {
    setError(key, { message: formErrors[key][0], type: 'focus', shouldFocus: true });
    if (isNil(errors)) return;
    if (Object.values(errors)[0]?.ref) {
      Object.values(errors)[0].ref.focus();
    }
  });
};

const getPaths = (errors, pathName = '') => {
  if (!errors || type(errors) !== 'Object') return pathName;
  return Object.keys(errors).map(key =>
    getPaths(errors[key], pathName ? [pathName, key].join('.') : key),
  );
};

export const setServerErrorsToFieldsRecursive = (setError, errors, formErrors) => {
  const paths = flatten(getPaths(formErrors));
  paths.forEach(errorPath => {
    setError(errorPath, { message: path(errorPath.split('.'), formErrors) }, { shouldFocus: true });
  });
  if (Object.values(errors)[0]?.ref) {
    Object.values(errors)[0].ref.focus();
  }
};

export const NETWORK_ERROR = 'networkError';
export const NOT_FOUND_ERROR = 'notFoundError';

export const getErrorMessageByStatus = status => {
  if (status === NOT_FOUND_ERROR) {
    return ERROR_MESSAGES[status];
  }
  if (status === NETWORK_ERROR) {
    return ERROR_MESSAGES[status];
  }
  return ERROR_MESSAGES.defaultError;
};

export const getFieldError = (fieldName, errorData) => prop(fieldName, errorData);

export const getFieldErrorMessage = (fieldName, errors) => head(prop(fieldName, errors));

export const findError = errors => errors.find(e => !isNil(e));
