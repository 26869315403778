/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';

import { compose } from 'redux';
import { hot } from 'react-hot-loader/root';
import { Provider } from 'react-redux';
import 'utils/rollbar';
import { CustomRouter } from 'router';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';

import { useAppAppearance, AppAppearanceProvider } from 'hooks/useAppAppearance';

import { CustomThemeProvider } from 'layouts/CustomThemeProvider';

import store from 'store';

const App = () => {
  const appAppearanceMethods = useAppAppearance();

  return (
    <CustomThemeProvider>
      <SnackbarProvider maxSnack={5} autoHideDuration={7000}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <AppAppearanceProvider {...appAppearanceMethods}>
            <CustomRouter />
          </AppAppearanceProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </CustomThemeProvider>
  );
};

const provideStore = ProxyComponent => props => (
  <Provider store={store}>
    <ProxyComponent {...props} />
  </Provider>
);

App.propTypes = {};

export default compose(hot, provideStore)(App);
