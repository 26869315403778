import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';

import { getOptionSelected } from 'utils/select';

import useStyles from './useStyles';

const SELECT_YEAR_LABEL = 'Select the Year';

export const YearSelectAutocomplete = props => {
  const classes = useStyles();
  const { label = SELECT_YEAR_LABEL, options, onChange, defaultValue, className } = props;

  return (
    <Autocomplete
      className={clsx(classes.autocomplete, className)}
      autoSelect
      disableClearable
      autoHighlight
      onChange={onChange}
      options={options}
      defaultValue={defaultValue}
      getOptionSelected={getOptionSelected}
      getOptionLabel={option => option.label}
      renderInput={params => <TextField {...params} label={label} variant="standard" />}
    />
  );
};

YearSelectAutocomplete.propTypes = {
  label: PropTypes.string,
  options: arrayOf(PropTypes.shape({ option: PropTypes.number, label: PropTypes.string })),
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({ option: PropTypes.number, label: PropTypes.string }),
  className: PropTypes.string,
};
