/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function deleteResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        deleteStatus: FETCH_STATUSES.idle,
        errors: null,
      },
    },
    reducers: {
      deleteResourceStart(state) {
        state[resource].deleteStatus = FETCH_STATUSES.pending;
        state[resource].errors = null;
      },
      deleteResourceSuccess(state) {
        state[resource].deleteStatus = FETCH_STATUSES.fulfilled;
      },
      deleteResourceFail(state, { payload: { errors } }) {
        state[resource].deleteStatus = FETCH_STATUSES.failed;
        state[resource].errors = errors;
      },
    },
    actionCreators(restDispatch) {
      return {
        deleteResource: (id, params) => {
          restDispatch('deleteResourceStart');

          return repository
            .delete(id, params)
            .then(({ data }) => {
              restDispatch('deleteResourceSuccess', data);
            })
            .catch(errors => {
              restDispatch('deleteResourceFail', { errors });
              throw errors;
            });
        },
      };
    },
    abstractSelector: state => ({
      resource: state[resource],
    }),
  };
}
