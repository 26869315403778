import React from 'react';

import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import { useFormContext, useController } from 'react-hook-form';

import FormError from 'components/FormError';

import useStyles from './useStyles';

export const SimpleInput = props => {
  const { name, label, type, className } = props;
  const classes = useStyles();
  const { control, errors } = useFormContext();
  const {
    field: { ...inputProps },
  } = useController({
    control,
    name,
  });
  return (
    <div className={className}>
      <div>
        <TextField
          label={label}
          type={type}
          autoComplete="off"
          name="name"
          className={classes.input}
          {...inputProps}
        />
        <ErrorMessage
          id={name}
          errors={errors}
          name={name}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

SimpleInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
};
