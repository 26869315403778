const ERROR_MESSAGES = {
  date: {
    wrongFormat: 'Date should be in the MM/DD/YYYY format',
    monthYearFormat: 'Date should be in the MM/YYYY format',
    invalidDate: 'Invalid Date',
    fullYear: 'Full Year is Required',
    requiredMonth: 'Month is required',
    requiredDate: 'Date is required',
    shouldBeAfter: '"Date to" can\'t be before "Date from"',
  },
  number: 'Enter a valid number',
  required: 'Required',
  matchPassword: 'Passwords must match',
  emailIsRequired: 'Email is required',
  invalidEmail: 'Please enter a valid email address',
  numberIsTooLong: 'Ensure that there are no more than 15 digits in total',
};

Object.freeze(ERROR_MESSAGES);

export { ERROR_MESSAGES };
