import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    reportWrap: {
      marginLeft: 'unset',
    },
  }),
  { name: 'MissingReconciliationInputs' },
);
