import OutstandingAccountantPresenter from 'presenters/OutstandingAccountantPresenter';

import { outstandingAccountantsRestHook } from 'store/OutstandingAccountantsSlice';
import { liveLoansRestHooks } from 'store/LiveLoansSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useOutstandingAccountants = () => {
  const { outstandingAccountants, loadOutstandingAccountants } =
    outstandingAccountantsRestHook.use();

  const outstandingAccountantsTableData = OutstandingAccountantPresenter.tableData(
    outstandingAccountants?.items,
  );

  const { updateLiveLoan } = liveLoansRestHooks.use();

  const { isPending, isFinished } = useFetchStatus(outstandingAccountants?.loadingStatus);

  return {
    updateLiveLoan,
    loadOutstandingAccountants,
    outstandingAccountantsTableData,
    isOutstandingAccountantsLoading: isPending,
    isOutstandingAccountantsLoadFinished: isFinished,
  };
};
