import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    root: {
      marginTop: '16px',
      '& .MuiButtonBase-root': {
        backgroundColor: 'white',
      },
    },
  }),
  { name: 'PaginationPanel' },
);
