import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    statusCol: {
      fontSize: '11px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      backgroundColor: theme.palette.white,
    },
    statusActive: {
      color: theme.palette.emerald,
      backgroundColor: theme.palette.tara,
    },
    statusInactive: {
      color: theme.palette.coralRed,
      backgroundColor: theme.palette.chablis,
    },
  }),
  { name: 'CellComponent' },
);
