import { isNil, slice, last, keys } from 'ramda';

import { formatCurrency } from 'utils/numbers';
import { formatDate } from 'utils/date';
import { getYearsOptions } from 'utils/select';

export const getNestedTableData = data => ({
  reportValue: formatCurrency(data.totalOidBalance),
  members: data.members.map(member => ({
    ...member,
    reportValue: formatCurrency(member.memberTotalOidBalance),
    successorBorrowers: member.successorBorrowers.map(sb => ({
      ...sb,
      successorBorrowerId: sb.successorBorrowerId,
      successorBorrowerName: sb.successorBorrowerName,
      reportValue: formatCurrency(sb.sbTotalOidBalance),
      loans: sb.loans.map(loan => ({
        ...loan,
        reportValue: formatCurrency(loan.oidOffBy),
      })),
    })),
  })),
});

export const summaryOidOffByTableFields = {
  oidOffBy: { displayedName: 'OID off by' },
  id: { displayedName: 'PID' },
  filingRef: { displayedName: 'DHC #' },
  assumedDate: { displayedName: 'Assumed Date' },
  maturityDate: { displayedName: 'Maturity Date' },
  custodianAccountNumber: { displayedName: 'Custodian Account Number' },
  custodianFee: { displayedName: 'Custodian Fee' },
  totalGovernmentSecuritiesCost: { displayedName: 'Securities' },
  causeyAccruedInterest: { displayedName: 'Accrued Interest' },
  openingCache: { displayedName: 'Opening Cash' },
  balanceOnLoan: { displayedName: 'Loan Balance' },
  totalLoanPaymentsInterest: { displayedName: 'Loan Interest' },
  totalAnnualMarketDiscount: { displayedName: 'Market Discount' },
  balanceAdjustment: { displayedName: 'Balance adjustment', isEditable: true },
};

export const journalEntryTableConfig = {
  row: {
    securities: {
      displayedName: 'Securities',
    },
    defeasedLoan: {
      displayedName: 'Defeased Loan',
    },
    feesPayable: {
      displayedName: 'Fees Payable',
    },
  },
  columnNames: ['debit', 'credit'],
};

journalEntryTableConfig.getRowsKeys = () => keys(journalEntryTableConfig.row);

export const journalEntryPRPTableConfig = {
  row: {
    defeasanceSecurities: {
      displayedName: 'Defeasance Securities',
    },
    defeasedLoan: {
      displayedName: 'Defeased Loan',
    },
    oidInterestIncome: {
      displayedName: 'OID Interest Income',
    },
    oidInterestIncomeRoundingAdjustment: {
      displayedName: 'OID Interest Income - rounding Adjustment',
    },
    custodianFeesPayable: {
      displayedName: 'Custodian Fees Payable',
    },
    interestIncomeFloat: {
      displayedName: 'Interest Income Float',
    },
    interestExpenseDefeasance: {
      displayedName: 'Interest Expense Defeasance',
    },
    floatReceivable: {
      displayedName: 'Float Receivable',
    },
    sbPrepayFees: {
      displayedName: 'SB Prepay Fees',
    },
    dueFromDhc: {
      displayedName: 'Due From DHC',
    },
    proceeds: {
      displayedName: 'Proceeds',
    },
    cost: {
      displayedName: 'Cost',
    },
  },
  columnNames: ['debit', 'credit'],
};

journalEntryPRPTableConfig.getRowsKeys = () => keys(journalEntryPRPTableConfig.row);

export const accountingTableColumnConfig = {
  key: {
    displayedName: '',
  },
  debitPrincipalPayment: {
    displayedName: 'Debit Principal Payment',
  },
  debitMortgageIntExp: {
    displayedName: 'Debit Mortgage Int Exp.',
  },
  debitFeesPayable: {
    displayedName: 'Debit Fees Payable',
  },
  creditIntIncmForFloat: {
    displayedName: 'Credit Int INCM for Float',
  },
  creditIntIncmForOid: {
    displayedName: 'Credit Int INCM for OID',
  },
  debitOrCreditSecurities: {
    displayedName: 'Debit Or Credit Securities',
  },
  totalPayments: {
    displayedName: 'Total Payments',
  },
  endingSecurityBalance: {
    displayedName: 'Ending Security Balance',
  },
  endingMortgageBalance: {
    displayedName: 'Ending Mortgage Balance',
  },
};

export const formatData = data => {
  if (isNil(data)) return null;
  return {
    ...data,
    // summary oidOffBy
    causeyAccruedInterest: formatCurrency(data.causeyAccruedInterest),
    assumedDate: formatDate(data.assumedDate),
    maturityDate: formatDate(data.maturityDate),
    balanceOnLoan: formatCurrency(data.balanceOnLoan),
    openingCache: formatCurrency(data.openingCache),
    custodianFee: formatCurrency(data.custodianFee),
    oidOffBy: formatCurrency(data.oidOffBy),
    defeasedLoan: formatDate('12-31-1969'),
    totalGovernmentSecuritiesCost: formatCurrency(data.totalGovernmentSecuritiesCost),
    totalLoanPaymentsInterest: formatCurrency(data.totalLoanPaymentsInterest),
    totalAnnualMarketDiscount: formatCurrency(data.totalAnnualMarketDiscount),
    // entries to securities
    entries: formatCurrency(data.entries),
    endingSecuritiesBalance: formatCurrency(data.endingSecuritiesBalance),
    targetJobToDateFloat: formatCurrency(data.targetJobToDateFloat),
    lessEndingCacheEscrow: formatCurrency(data.lessEndingCacheEscrow),
    entriesOfSecuritiesAccountsMatch: formatCurrency(data.entriesOfSecuritiesAccountsMatch),
    endingCacheEscrow: formatCurrency(data.endingCacheEscrow),
    endingSecuritiesBalanceShouldBe: formatCurrency(data.endingSecuritiesBalanceShouldBe),
    // summary
    plusReceipts: formatCurrency(data.plusReceipts),
    lessPayments: formatCurrency(data.lessPayments),
    summaryTotal: formatCurrency(data.summaryTotal),
  };
};
export const formatAccountingTableData = item => {
  if (isNil(item)) return {};

  const list = item.accountingDataTable;
  const preparedData = [
    ...list.map(listItem => ({
      data: {
        ...listItem,
        debitPrincipalPayment: formatCurrency(listItem.debitPrincipalPayment),
        debitMortgageIntExp: formatCurrency(listItem.debitMortgageIntExp),
        debitFeesPayable: formatCurrency(listItem.debitFeesPayable),
        creditIntIncmForFloat: formatCurrency(listItem.creditIntIncmForFloat),
        creditIntIncmForOid: formatCurrency(listItem.creditIntIncmForOid),
        debitOrCreditSecurities: formatCurrency(listItem.debitOrCreditSecurities),
        totalPayments: formatCurrency(listItem.totalPayments),
        endingSecurityBalance: formatCurrency(listItem.endingSecurityBalance),
        endingMortgageBalance: formatCurrency(listItem.endingMortgageBalance),
      },
    })),
  ];
  return {
    tableRows: slice(0, -1, preparedData),
    footerTotals: last(preparedData).data,
  };
};
export const formatJournalEntryData = item => {
  if (isNil(item)) return null;
  const { journalEntryDataTable } = item;
  const { securities, defeasedLoan, feesPayable, totalsDebitAndCredit, total } =
    journalEntryDataTable;
  return {
    securities: {
      debit: formatCurrency(securities.debit),
      credit: formatCurrency(securities.credit),
    },
    defeasedLoan: {
      debit: formatCurrency(defeasedLoan.debit),
      credit: formatCurrency(defeasedLoan.credit),
    },
    feesPayable: {
      debit: formatCurrency(feesPayable.debit),
      credit: formatCurrency(feesPayable.credit),
    },
    totalsDebitAndCredit: {
      debit: formatCurrency(totalsDebitAndCredit.debit),
      credit: formatCurrency(totalsDebitAndCredit.credit),
    },
    total: formatCurrency(total),
  };
};

export const formatJournalEntryPRPData = item => {
  if (isNil(item) || isNil(item.journalEntryPrpDataTable)) return null;
  const { journalEntryPrpDataTable } = item;
  const {
    defeasanceSecurities,
    defeasedLoan,
    oidInterestIncome,
    oidInterestIncomeRoundingAdjustment,
    custodianFeesPayable,
    interestIncomeFloat,
    interestExpenseDefeasance,
    floatReceivable,
    sbPrepayFees,
    dueFromDhc,
    proceeds,
    cost,
    total,
  } = journalEntryPrpDataTable;
  return {
    defeasanceSecurities: {
      debit: formatCurrency(defeasanceSecurities.debit),
      credit: formatCurrency(defeasanceSecurities.credit),
    },
    defeasedLoan: {
      debit: formatCurrency(defeasedLoan.debit),
      credit: formatCurrency(defeasedLoan.credit),
    },
    oidInterestIncome: {
      debit: formatCurrency(oidInterestIncome.debit),
      credit: formatCurrency(oidInterestIncome.credit),
    },
    oidInterestIncomeRoundingAdjustment: {
      debit: formatCurrency(oidInterestIncomeRoundingAdjustment.debit),
      credit: formatCurrency(oidInterestIncomeRoundingAdjustment.credit),
    },
    custodianFeesPayable: {
      debit: formatCurrency(custodianFeesPayable.debit),
      credit: formatCurrency(custodianFeesPayable.credit),
    },
    interestIncomeFloat: {
      debit: formatCurrency(interestIncomeFloat.debit),
      credit: formatCurrency(interestIncomeFloat.credit),
    },
    interestExpenseDefeasance: {
      debit: formatCurrency(interestExpenseDefeasance.debit),
      credit: formatCurrency(interestExpenseDefeasance.credit),
    },
    floatReceivable: {
      debit: formatCurrency(floatReceivable.debit),
      credit: formatCurrency(floatReceivable.credit),
    },
    sbPrepayFees: {
      debit: formatCurrency(sbPrepayFees.debit),
      credit: formatCurrency(sbPrepayFees.credit),
    },
    dueFromDhc: {
      debit: formatCurrency(dueFromDhc.debit),
      credit: formatCurrency(dueFromDhc.credit),
    },
    proceeds: {
      debit: formatCurrency(proceeds.debit),
      credit: formatCurrency(proceeds.credit),
    },
    cost: {
      debit: formatCurrency(cost.debit),
      credit: formatCurrency(cost.credit),
    },
    total: {
      debit: formatCurrency(total.debit),
      credit: formatCurrency(total.credit),
    },
  };
};

export const successorBorrowerSummaryTableFields = {
  latestPayOffDate: { displayedName: 'Latest Payoff' },
  newLoansThisYear: { displayedName: 'New Loans this year' },
  nextPayOffDate: { displayedName: 'Next Payoff' },
  stillActiveLoans: { displayedName: 'Number still active as of today' },
  recsDone: { displayedName: 'Recs done' },
};

export const SBReportSummaryTableFields = {
  thisYearApproximateInvestmentLoss: { displayedName: 'This Year’s Approximate Investment Loss' },
  thisYearApproximateInvestmentIncome: { displayedName: 'This Year’s Approximate Interest Income' },
};

export const SBJournalEntryTableFields = {
  debitPrincipalPayment: { displayedName: 'Loan' },
  debitMortgageIntExp: { displayedName: 'Mortgage exp' },
  debitFeesPayable: { displayedName: 'Fees Payable' },
  creditIntIncmForFloat: { displayedName: 'Float' },
  creditIntIncmForOid: { displayedName: 'Int Income' },
  debitOrCreditSecurities: { displayedName: 'Securities' },
};

export const SBAccountingTableColumnConfig = {
  id: { displayedName: 'pid', isLink: true },
  dealName: { displayedName: 'Property name' },
  parRepayDate: { displayedName: 'Paid off' },
  oidBalance: { displayedName: 'oid in balance' },
  debitPrincipalPayment: { displayedName: 'Debit principal payment' },
  debitMortgageIntExp: { displayedName: 'Debit mortgage int exp.' },
  debitFeesPayable: { displayedName: 'Debit fees payable' },
  creditIntIncmForFloat: { displayedName: 'Credit int\nincm for\nfloat' },
  creditIntIncmForOid: { displayedName: 'Credit int incm for oid' },
  debitOrCreditSecurities: { displayedName: 'Debit or credit securities' },
  endingSecurityBalance: { displayedName: 'Ending security balance' },
  endingMortgageBalance: { displayedName: 'Ending mortgage balance' },
  endingCustodianFeesOutstanding: { displayedName: 'Ending custodial fees outstanding' },
};

export const formatSBAccountingTableData = item => {
  if (isNil(item)) return {};

  const list = item.accountingTable;
  const tableSummary = item.accountingTableSummary;
  const preparedData = [
    ...[...list, tableSummary].map(listItem => ({
      id: listItem.id,
      data: {
        ...listItem,
        parRepayDate: formatDate(listItem.parRepayDate),
        oidBalance: formatCurrency(listItem.oidBalance),
        debitPrincipalPayment: formatCurrency(listItem.debitPrincipalPayment),
        debitMortgageIntExp: formatCurrency(listItem.debitMortgageIntExp),
        debitFeesPayable: formatCurrency(listItem.debitFeesPayable),
        creditIntIncmForFloat: formatCurrency(listItem.creditIntIncmForFloat),
        creditIntIncmForOid: formatCurrency(listItem.creditIntIncmForOid),
        debitOrCreditSecurities: formatCurrency(listItem.debitOrCreditSecurities),
        endingSecurityBalance: formatCurrency(listItem.endingSecurityBalance),
        endingMortgageBalance: formatCurrency(listItem.endingMortgageBalance),
        endingCustodianFeesOutstanding: formatCurrency(listItem.endingCustodianFeesOutstanding),
      },
    })),
  ];
  return {
    tableRows: slice(0, -1, preparedData),
    footerRow: last(preparedData).data,
  };
};
export const formatSuccessorBorrowersData = data => {
  if (isNil(data)) return null;
  const {
    debitPrincipalPayment,
    debitMortgageIntExp,
    debitFeesPayable,
    creditIntIncmForFloat,
    creditIntIncmForOid,
    debitOrCreditSecurities,
  } = data.accountingTableSummary;

  return {
    ...data,
    // successorBorrowerSummary
    latestPayOffDate: formatDate(data.latestPayOffDate),
    nextPayOffDate: formatDate(data.nextPayOffDate) ?? '-',
    // summary
    thisYearApproximateInvestmentIncome: formatCurrency(data.thisYearApproximateInvestmentIncome),
    thisYearApproximateInvestmentLoss: formatCurrency(data.thisYearApproximateInvestmentLoss),
    // journal entry
    debitPrincipalPayment: formatCurrency(debitPrincipalPayment),
    debitMortgageIntExp: formatCurrency(debitMortgageIntExp),
    debitFeesPayable: formatCurrency(debitFeesPayable),
    creditIntIncmForFloat: formatCurrency(creditIntIncmForFloat),
    creditIntIncmForOid: formatCurrency(creditIntIncmForOid),
    debitOrCreditSecurities: formatCurrency(debitOrCreditSecurities),
  };
};

export { getYearsOptions };

export const getValue = (data, key) => data[key];
