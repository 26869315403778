import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    navLink: {
      display: 'block',
      minHeight: 40,
      textDecoration: 'none',
      padding: [12, 24],
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      verticalAlign: 'middle',
    },
    activeNavLink: {
      backgroundColor: theme.palette.catskillWhiteTwo,
      position: 'relative',
      '&:before': {
        content: "''",
        display: 'block',
        width: 4,
        minHeight: 32,
        borderRadius: 34,
        backgroundColor: theme.palette.secondary.main,
        position: 'absolute',
        left: 4,
        top: 4,
      },
    },
    hashNav: {
      '& > a': {
        padding: [12, 24, 12, 40],
        fontSize: theme.typography.pxToRem(14),
      },
    },
    disabled: {
      pointerEvents: 'none',
      opacity: 0.6,
    },
    icon: {
      verticalAlign: 'bottom',
      marginLeft: '9px',
    },
  }),
  { name: 'HashNav' },
);
