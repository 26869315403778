import React from 'react';

import { useFormContext } from 'react-hook-form';
import { TableRow, TableCell, Button } from '@material-ui/core/';
import PropTypes from 'prop-types';

import { FORM_FIELD_TYPE } from 'enums';

import { formFields } from 'pages/FundingNotes/validation';
import useStyles from 'pages/FundingNotes/useStyles';

import DatePickerCell from './components/DatePickerCell';
import DecimalInputCell from './components/DecimalInputCell';
import InputCell from './components/InputCell';

const SAVE_BUTTON = 'Save';
const CANCEL_BUTTON = 'Cancel';

export const FormRow = props => {
  const { isCreating, onSubmit, onCancel } = props;
  const methods = useFormContext();
  const classes = useStyles();
  const { handleSubmit } = methods;

  const mapping = {
    [FORM_FIELD_TYPE.input]: (name, properties) => (
      <InputCell
        name={name}
        childrenProps={{
          views: ['date', 'month', 'year'],
          ...properties,
        }}
      />
    ),

    [FORM_FIELD_TYPE.date]: (name, properties) => (
      <DatePickerCell
        name={name}
        childrenProps={{
          views: ['date', 'month', 'year'],
          ...properties,
        }}
      />
    ),
    [FORM_FIELD_TYPE.decimal]: (name, properties) => (
      <DecimalInputCell
        name={name}
        childrenProps={{
          ...properties,
        }}
      />
    ),
  };

  return (
    <TableRow>
      {formFields.map((field, index) => {
        const { name, type, placeholder, label } = field;
        return (
          <TableCell key={`form-field-${index}`} className={classes.inputCell}>
            {mapping[type](name, { placeholder, label }, index)}
          </TableCell>
        );
      })}
      <TableCell className={classes.inputCell}>
        <Button
          color="primary"
          className={classes.saveButton}
          onClick={handleSubmit(onSubmit(isCreating))}
        >
          {SAVE_BUTTON}
        </Button>
        <Button className={classes.deleteButton} onClick={onCancel}>
          {CANCEL_BUTTON}
        </Button>
      </TableCell>
    </TableRow>
  );
};

FormRow.propTypes = {
  isCreating: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  row: PropTypes.shape({
    data: PropTypes.shape({
      comment: PropTypes.string,
    }),
  }),
};
