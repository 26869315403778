import React from 'react';

import PropTypes from 'prop-types';

import { useEditableTableContext } from 'hooks/useEditableTableControls';

import DataRow from 'pages/LoanReconciliationInputs/components/DataRow';
import FormRow from 'pages/LoanReconciliationInputs/components/FormRow';

export const ReconciliationTableRow = props => {
  const { row, isForm = false, isCreating, onEdit, onCreate, onUpdate, onDelete } = props;

  const { hideCreatingForm, hideEditableRow } = useEditableTableContext();

  const handleDeleteButtonClick = rowId => async () => {
    await onDelete(rowId);
  };

  const handleCancelButtonClick = () => {
    hideCreatingForm();
    hideEditableRow();
  };

  const handleSaveButtonClick = rowId => (isCreating ? onCreate : onUpdate(rowId));

  return isForm ? (
    <FormRow
      onSubmit={handleSaveButtonClick(row?.id)}
      onCancel={handleCancelButtonClick}
      isCreating={isCreating}
      row={row}
    />
  ) : (
    <DataRow row={row} onDelete={handleDeleteButtonClick(row?.id)} onEdit={onEdit} />
  );
};

ReconciliationTableRow.propTypes = {
  isForm: PropTypes.bool,
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({}),
  }),
  isCreating: PropTypes.bool,
  onEdit: PropTypes.func,
  onCreate: PropTypes.func,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
};
