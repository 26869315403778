import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentActive: {
      backgroundColor: theme.palette.feta,
    },
    contentInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    contentActiveInPayment: {
      backgroundColor: theme.palette.feta,
    },
    contentActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    buttonText: {
      marginTop: 1.5,
    },
    hiddenButtonText: {
      display: 'none',
    },
    hiddenButtonImage: {
      display: 'none',
    },
    hiddenNavButton: {
      cursor: 'pointer',
      position: 'fixed',
      width: 30,
      height: 32,
      left: 0,
      top: 54,
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#007BC2',
      border: 0,
      outline: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 3,
    },
    hideNavButton: {
      position: 'fixed',
      width: 10,
      left: 256,
      '& > span, & > img': {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        color: '#ffffff',
        marginLeft: 6,
        opacity: '0%',
      },
      '&:hover': {
        content: "''",
        width: 64,
      },
      '&:hover > span, & > img': {
        content: "''",
        opacity: '100%',
      },
    },
    sidebar: {
      width: '256px',
      paddingTop: '24px',
      backgroundColor: theme.palette.white,
      borderRight: `1px solid ${theme.palette.catskillWhite}`,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    hiddenSidebar: {
      width: 8,
      overflow: 'hidden',
      paddingLeft: 8,
    },
  }),
  { name: 'SideBar' },
);
