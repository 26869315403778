import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import OIDBalanceRepository from 'repositories/OIDBalanceRepository';

import { FETCH_STATUSES } from 'enums';

import { useLongPollingWithRedux } from 'hooks/useLongPollingWithRedux';

const initialState = {
  OIDBalanceReport: {
    loadingStatus: FETCH_STATUSES.idle,
    item: null,
    errors: false,
  },
  OIDBalanceLoanDetails: {
    loadingStatus: FETCH_STATUSES.idle,
    errors: null,
    item: null,
  },
  OIDBalanceSuccessorBorrowerDetails: {
    loadingStatus: FETCH_STATUSES.idle,
    errors: null,
    item: null,
  },
  OIDBalanceMemberDetails: {
    loadingStatus: FETCH_STATUSES.idle,
    errors: null,
    item: null,
  },
};

const OIDBalanceReportReducer = {
  startOIDBalanceReportLoad(state) {
    state.OIDBalanceReport.loadingStatus = FETCH_STATUSES.pending;
    state.OIDBalanceReport.item = null;
    state.OIDBalanceReport.errors = null;
  },
  OIDBalanceReportLoadFulfilled(state, { payload }) {
    state.OIDBalanceReport.item = payload;
    state.OIDBalanceReport.loadingStatus = FETCH_STATUSES.fulfilled;
  },
  OIDBalanceReportLoadRejected(state, { payload }) {
    state.OIDBalanceReport.loadingStatus = FETCH_STATUSES.failed;
    if (payload) {
      state.OIDBalanceReport.errors = payload;
    }
  },
  clearOIDBalanceReport(state) {
    state.OIDBalanceReport.loadingStatus = FETCH_STATUSES.idle;
    state.OIDBalanceReport.item = null;
    state.OIDBalanceReport.errors = null;
  },
};

const OIDBalanceLoanDetailsReducers = {
  startOIDBalanceLoanDetailsLoad(state) {
    state.OIDBalanceLoanDetails.loadingStatus = FETCH_STATUSES.pending;
    state.OIDBalanceLoanDetails.item = null;
    state.OIDBalanceLoanDetails.errors = null;
  },
  OIDBalanceLoanDetailsLoadFulfilled(state, { payload }) {
    state.OIDBalanceLoanDetails.loadingStatus = FETCH_STATUSES.fulfilled;
    state.OIDBalanceLoanDetails.item = payload;
  },
  OIDBalanceLoanDetailsLoadRejected(state, { payload }) {
    state.OIDBalanceLoanDetails.loadingStatus = FETCH_STATUSES.failed;
    state.OIDBalanceLoanDetails.item = null;
    state.OIDBalanceLoanDetails.errors = payload;
  },
};

const OIDBalanceSuccessorBorrowerDetailsReducers = {
  startOIDBalanceSuccessorBorrowerDetailsLoad(state) {
    state.OIDBalanceSuccessorBorrowerDetails.loadingStatus = FETCH_STATUSES.pending;
    state.OIDBalanceSuccessorBorrowerDetails.item = null;
    state.OIDBalanceSuccessorBorrowerDetails.errors = null;
  },
  OIDBalanceSuccessorBorrowerDetailsLoadFulfilled(state, { payload }) {
    state.OIDBalanceSuccessorBorrowerDetails.loadingStatus = FETCH_STATUSES.fulfilled;
    state.OIDBalanceSuccessorBorrowerDetails.item = payload;
  },
  OIDBalanceSuccessorBorrowerDetailsLoadRejected(state, { payload }) {
    state.OIDBalanceSuccessorBorrowerDetails.loadingStatus = FETCH_STATUSES.failed;
    state.OIDBalanceSuccessorBorrowerDetails.item = null;
    state.OIDBalanceSuccessorBorrowerDetails.errors = payload;
  },
};

const OIDBalanceMemberDetailsReducers = {
  startOIDBalanceMemberDetailsLoad(state) {
    state.OIDBalanceMemberDetails.loadingStatus = FETCH_STATUSES.pending;
    state.OIDBalanceMemberDetails.item = null;
    state.OIDBalanceMemberDetails.errors = null;
  },
  OIDBalanceMemberDetailsLoadFulfilled(state, { payload }) {
    state.OIDBalanceMemberDetails.loadingStatus = FETCH_STATUSES.fulfilled;
    state.OIDBalanceMemberDetails.item = payload;
  },
  OIDBalanceMemberDetailsLoadRejected(state, { payload }) {
    state.OIDBalanceMemberDetails.loadingStatus = FETCH_STATUSES.failed;
    state.OIDBalanceMemberDetails.item = null;
    state.OIDBalanceMemberDetails.errors = payload;
  },
};

const OIDBalanceReportsSlice = createSlice({
  name: 'OIDBalanceReports',
  initialState,
  reducers: {
    ...OIDBalanceReportReducer,
    ...OIDBalanceLoanDetailsReducers,
    ...OIDBalanceSuccessorBorrowerDetailsReducers,
    ...OIDBalanceMemberDetailsReducers,
  },
});

export default OIDBalanceReportsSlice.reducer;

export const useOIDBalanceReportActions = () => {
  const dispatch = useDispatch();

  const createOIDBalanceLongPollingWorker = useLongPollingWithRedux({
    onStart: () => dispatch(OIDBalanceReportsSlice.actions.startOIDBalanceReportLoad()),
    onFailure: error =>
      dispatch(OIDBalanceReportsSlice.actions.OIDBalanceReportLoadRejected(error)),
    onSuccess: result =>
      dispatch(OIDBalanceReportsSlice.actions.OIDBalanceReportLoadFulfilled(result)),
  });

  const clearOIDBalanceReportCache = () =>
    dispatch(OIDBalanceReportsSlice.actions.clearOIDBalanceReport());
  const OIDBalanceReport = useSelector(state => state.OIDBalanceReportsSlice.OIDBalanceReport);
  return { createOIDBalanceLongPollingWorker, OIDBalanceReport, clearOIDBalanceReportCache };
};

export const useOIDBalanceLoanDetailsActions = () => {
  const dispatch = useDispatch();

  const loadLoanDetails = id => {
    dispatch(OIDBalanceReportsSlice.actions.startOIDBalanceLoanDetailsLoad());
    return OIDBalanceRepository.OIDBalanceLoanDetails({ id })
      .then(({ data }) => {
        dispatch(OIDBalanceReportsSlice.actions.OIDBalanceLoanDetailsLoadFulfilled(data.data));
      })
      .catch(e => {
        dispatch(OIDBalanceReportsSlice.actions.OIDBalanceLoanDetailsLoadRejected(e));
        throw e;
      });
  };
  return {
    loadLoanDetails,
  };
};

export const useOIDBalanceSuccessorBorrowerDetailsActions = () => {
  const dispatch = useDispatch();

  const loadSuccessorBorrowerDetails = async (id, params) => {
    dispatch(OIDBalanceReportsSlice.actions.startOIDBalanceSuccessorBorrowerDetailsLoad());
    try {
      const { data } = await OIDBalanceRepository.OIDBalanceSuccessorBorrowerDetails(id, params);
      dispatch(
        OIDBalanceReportsSlice.actions.OIDBalanceSuccessorBorrowerDetailsLoadFulfilled(data.data),
      );
    } catch (e) {
      dispatch(OIDBalanceReportsSlice.actions.OIDBalanceSuccessorBorrowerDetailsLoadRejected(e));
    }
  };
  return {
    loadSuccessorBorrowerDetails,
  };
};

export const useOIDBalanceMemberDetailsActions = () => {
  const dispatch = useDispatch();

  const createLongPollingWorker = useLongPollingWithRedux({
    onStart: () => dispatch(OIDBalanceReportsSlice.actions.startOIDBalanceMemberDetailsLoad()),
    onFailure: error =>
      dispatch(OIDBalanceReportsSlice.actions.OIDBalanceMemberDetailsLoadRejected(error)),
    onSuccess: result =>
      dispatch(OIDBalanceReportsSlice.actions.OIDBalanceMemberDetailsLoadFulfilled(result)),
  });

  const OIDBalanceMemberDetails = useSelector(
    state => state.OIDBalanceReportsSlice.OIDBalanceMemberDetails,
  );
  return { createLongPollingWorker, OIDBalanceMemberDetails };
};
