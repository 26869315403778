import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    address: PropTypes.string,
    cellPhone: PropTypes.string,
    city: PropTypes.string,
    email: PropTypes.string,
    employerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstName: PropTypes.string,
    id: PropTypes.number,
    lastName: PropTypes.string,
    state: PropTypes.string,
    workPhone: PropTypes.string,
    zip: PropTypes.string,
    updatedAt: PropTypes.string,
    updatedBy: PropTypes.string,
  },
  {
    defaultValues() {
      return {
        address: '',
        cellPhone: '',
        city: '',
        email: '',
        employerId: null,
        firstName: '',
        lastName: '',
        state: null,
        workPhone: '',
        zip: '',
      };
    },
    fullName(employee) {
      if (!employee) {
        return '';
      }
      return employee.fullName || `${employee.firstName} ${employee.lastName}`;
    },
    errors(person) {
      return person?.errors || {};
    },
  },
);
