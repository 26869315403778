import React, { useMemo } from 'react';

import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const EDIT = 'Edit';
const DEFAULT = '30 Capital DHC';

export const DocumentTitle = props => {
  const { title, isEdit } = props;

  const preparedTitle = useMemo(() => {
    if (!title) return null;
    return isEdit ? `${title} - ${EDIT}` : title;
  }, [title, isEdit]);

  return (
    <Helmet defaultTitle={DEFAULT}>
      <title>{preparedTitle}</title>
    </Helmet>
  );
};

DocumentTitle.propTypes = {
  title: PropTypes.string,
  isEdit: PropTypes.bool,
};
