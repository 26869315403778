import { head, has } from 'ramda';

const selectLabelKey = data => data?.name || '';
const DEFAULT_VALUE_KEY = 'id';

export const getSelectOptions = (
  array,
  valueKey = DEFAULT_VALUE_KEY,
  labelKeyFunc = selectLabelKey,
  filterBy,
) => {
  if (!array) {
    return [];
  }
  const resultArray = filterBy
    ? array.filter(item => item[filterBy.key] === filterBy.value)
    : array.slice();

  return resultArray.map(item => ({
    value: item[valueKey],
    label: labelKeyFunc(item),
  }));
};

export const serializeOptions = options => {
  if (!options) return [];
  const firstItem = head(options);
  if (has('value')(firstItem) && has('label')(firstItem)) return options;

  return options.map(item => ({
    value: item?.id,
    label: item?.name,
  }));
};
