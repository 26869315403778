import { isNil } from 'ramda';

import { IPool } from 'domain/pool/types';

import { FIELD_ALIGNMENT } from 'enums';

import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/numbers';

export const columnConfig = {
  securityDescription: {
    displayedName: 'Security Description',
    isLink: true,
    canBeHidden: false,
  },
  cusip: {
    displayedName: 'Cusip',
    canBeHidden: false,
  },
  masterServicer1Name: {
    displayedName: 'Master Servicer 1',
    canBeHidden: false,
  },
  masterServicer2Name: {
    displayedName: 'Master Servicer 2',
    canBeHidden: false,
  },
  ratingAgency1Name: {
    displayedName: 'RA 1',
    canBeHidden: false,
  },
  ratingAgency2Name: {
    displayedName: 'RA 2',
    canBeHidden: false,
  },
  ratingAgency3Name: {
    displayedName: 'RA 3',
    canBeHidden: false,
  },
  ratingAgency4Name: {
    displayedName: 'RA 4',
    canBeHidden: false,
  },
  specialServicer: {
    displayedName: 'Special Servicer',
    canBeHidden: false,
  },
  currentPrice: {
    displayedName: 'Bloomberg Pool Balance',
    canBeHidden: false,
    align: FIELD_ALIGNMENT.right,
  },
  bloombergPoolBalanceDate: {
    displayedName: 'Bloomberg Pool Balance Date',
    canBeHidden: false,
  },
};

export const getTableData = (poolsList: Array<IPool>) => {
  if (isNil(poolsList)) return null;
  return poolsList.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      currentPrice: rowData.currentPrice && formatCurrency(rowData.currentPrice),
      bloombergPoolBalanceDate: formatDate(rowData.bloombergPoolBalanceDate),
    },
  }));
};

export const getTableHeader = () => Object.keys(columnConfig);
