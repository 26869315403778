import React, { useState, useEffect } from 'react';

import { Paper } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import UsersRepository from 'repositories/UsersRepository';

import Loader from 'components/Loader';

import useRouter from 'hooks/useRouter';
import useLoading from 'hooks/useLoading';
import { useErrors } from 'hooks/useErrors';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';
import { resolver, defaultValues } from './validation';
import { PasswordForm, Message } from './components';

const MESSAGE_DATA = {
  success: {
    title: 'Your password has been reset successfully',
    message: 'You can now log in with your new password.',
    button: {
      title: 'Log in',
      path: appRoutes.authPath(),
    },
  },
  tokenIsInvalid: {
    title: "Password couldn't be reset",
    message: 'The reset token is invalid. Try to reset the password again.',
    button: {
      title: 'Change Password',
      path: appRoutes.passwordRecoveryPath(),
    },
  },
};

export const PasswordResetForm = () => {
  const classes = useStyles();
  const { query } = useRouter();
  const token = query?.token;
  const [isTokenValid, setIsTokenValid] = useState(true);

  const { displayErrorsInToast, formatErrors, setErrorsToForm } = useErrors();

  const { func: validateToken, isPending: isTokenValidationPending } = useLoading(
    UsersRepository.validateToken,
  );

  const { func: confirmPassword, isFulfilled: passwordConfirmLoadFullfilled } = useLoading(
    UsersRepository.confirmPassword,
  );

  const methods = useForm({
    resolver,
    defaultValues,
    shouldFocusError: true,
  });

  const runTokenValidation = async () => {
    try {
      await validateToken({ token });
      setIsTokenValid(true);
    } catch (err) {
      setIsTokenValid(false);
    }
  };

  useEffect(() => {
    runTokenValidation();
  }, []);

  const onSubmitClick = async data => {
    const { password } = data;
    try {
      await confirmPassword({ password, token });
    } catch (e) {
      const { backendServicesError, fieldErrors, nonFieldErrors } = formatErrors(e);
      setErrorsToForm(methods.setError, null, fieldErrors);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  };

  const currentMessage = () => (isTokenValid ? MESSAGE_DATA.success : MESSAGE_DATA.tokenIsInvalid);

  if (isTokenValidationPending) return <Loader />;

  if (!isTokenValid)
    return (
      <Paper className={classes.card}>
        <Message message={currentMessage()} />
      </Paper>
    );

  return (
    <Paper className={classes.card}>
      {passwordConfirmLoadFullfilled ? (
        <Message message={currentMessage()} />
      ) : (
        <FormProvider {...methods}>
          <PasswordForm onSubmit={onSubmitClick} />
        </FormProvider>
      )}
    </Paper>
  );
};
