import React, { useEffect } from 'react';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';

import { useBuyUpPending } from 'hooks/api/useBuyUpPending';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { buyUpPendingColumnConfig } from 'presenters/BuyUpPendingPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Buy Up Pending';

export const BuyUpPending = () => {
  const {
    loadBuyUpPendings,
    updateBuyUpPending,
    buyUpPendingTableData,
    buyUpPendingTableKeys,
    isBuyUpPendingLoading,
    totalAmount,
    totalDifference,
  } = useBuyUpPending();

  const classes = useStyles();

  const { displayErrorsInToast } = useErrors();

  const { elementRef, elementHeight } = useRefElementHeight();

  const fetchData = async () => {
    try {
      await loadBuyUpPendings();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id, fieldKey, fieldValue) => {
    const params = {
      [fieldKey]: fieldValue,
    };
    await updateBuyUpPending(id, params);
  };

  const tableBottomLine = (
    <tr className={classes.tableBottomRow}>
      <td colSpan={7} className={classes.tableBottomCell}>
        Summary
      </td>
      <td className={classes.tableBottomCell}>{totalAmount}</td>
      <td className={classes.tableBottomCell}>{totalDifference}</td>
    </tr>
  );

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState />
      <ListTable
        detailPath={appRoutes.loanPath}
        isLoading={isBuyUpPendingLoading}
        tableHeader={buyUpPendingTableKeys}
        tableData={buyUpPendingTableData}
        columnConfig={buyUpPendingColumnConfig}
        onEdit={handleEdit}
        tableBottomContent={tableBottomLine}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
