import { loansRestHooks, useLoansPartialUpdate } from 'store/LoansSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useLoans = () => {
  const { loan } = loansRestHooks.use();

  const loanPartialUpdateStatus = loan.partialUpdatingStatus;
  const { isPending: isLoanPartialUpdatePending } = useFetchStatus(loanPartialUpdateStatus);

  const { makeLoanPartialUpdate } = useLoansPartialUpdate();

  return {
    makeLoanPartialUpdate,
    isLoanPartialUpdatePending,
  };
};
