import { useState, useEffect, useRef } from 'react';

import { TimersConst } from 'const';

import FetchHelpers from 'utils/FetchHelpers';
import { ReportStatusConst } from 'utils/useTaskStatus';

const getData = response => response.data.data.result;
const getStatus = response => response.data?.data?.status;
const getErrors = response => response.data?.data?.errors;
const getUrlToPoll = response => response.headers.location;

const MAX_RETRY = 600; // ~20 min

export const useLongPollingWithRedux = params => {
  const { onStart, onSuccess, onFailure } = params;

  const [urlToPoll, setUrlToPoll] = useState(null);

  const processId = useRef(null);
  const [isPolling, setIsPolling] = useState(false);

  const startPolling = () => setIsPolling(true);
  const stopPolling = () => {
    setIsPolling(false);
    clearInterval(processId.current);
  };

  const loadData = async url => {
    let countdown = MAX_RETRY;
    let hasAnswer = false;
    processId.current = setInterval(async () => {
      if (countdown === 0) {
        stopPolling();
        return;
      }
      try {
        const response = await FetchHelpers.getRaw(url);
        const status = getStatus(response);
        switch (status) {
          case ReportStatusConst.FAILURE:
            if (!hasAnswer) onFailure(getErrors(response));
            hasAnswer = true;
            stopPolling();
            break;
          case ReportStatusConst.SUCCESS:
            if (!hasAnswer) onSuccess(getData(response));
            hasAnswer = true;
            stopPolling();
            break;
          default:
            countdown -= 1;
        }
      } catch (e) {
        onFailure(e);
        stopPolling();
      }
    }, TimersConst.REQUEST_INTERVAL_TIME);
  };

  useEffect(() => {
    if (urlToPoll) {
      startPolling();
    }
  }, [urlToPoll]);

  useEffect(() => {
    if (!isPolling) return;
    loadData(urlToPoll);
  }, [isPolling]);

  useEffect(() => () => stopPolling(), []);

  const createLoader = fetchUrlToPoll => async (data, queryParams) => {
    onStart();
    try {
      const response = await fetchUrlToPoll(data, queryParams);
      const url = getUrlToPoll(response);
      setUrlToPoll(url);
    } catch (e) {
      onFailure(e);
    }
  };

  const createLongPollingWorker = fetchUrl => createLoader(fetchUrl);

  return createLongPollingWorker;
};
