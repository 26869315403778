/* eslint-disable import/no-import-module-exports */
import { configureStore } from '@reduxjs/toolkit';
import { createApi } from '@reduxjs/toolkit/query/react';

import { CACHE_TAG } from 'enums';

import rootReducer from 'store/rootReducer';

import { axiosBaseQuery } from 'utils/axiosBaseQuery';

export const apiContainer = createApi({
  reducerPath: 'api',
  tagTypes: Object.values(CACHE_TAG),
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
});

const { reducerPath, reducer, middleware } = apiContainer;

const store = configureStore({
  reducer: {
    [reducerPath]: reducer,
    ...rootReducer,
  },
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), middleware],
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('store/rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require('store/rootReducer').default; // eslint-disable-line global-require
    store.replaceReducer(newRootReducer);
  });
}

export default store;
