import React, { useEffect } from 'react';

import LoanForm from 'components/LoanForm';

import { useGetLoanQuery, useUpdateLoanMutation } from 'domain/loan/apiSlice';

import useRouter from 'hooks/useRouter';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { createLoanCRUDLinks } from 'utils/routes';

const SUBMIT_BUTTON_TEXT = 'Update';

const LoanEdit = () => {
  const {
    query: { id },
    push,
  } = useRouter();

  const { data: loanData, isFetching, error: loanLoadError } = useGetLoanQuery(id);

  const [
    updateLoan,
    { error: loanUpdateError, isSuccess: isLoanUpdateSuccess, isLoading: isLoanUpdateLoading },
  ] = useUpdateLoanMutation();

  const previousRoute = appRoutes.loanPath(id);

  const handleSubmitClick = params => updateLoan(params);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isLoanUpdateSuccess) push(previousRoute);
  }, [isLoanUpdateSuccess]);

  const sideBarContent = createLoanCRUDLinks(true, id);

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <LoanForm
        defaultValuesLoadError={loanLoadError}
        formValues={loanData}
        isDefaultDataLoading={isFetching}
        isEdit
        isLoanSubmitLoading={isLoanUpdateLoading}
        onCancel={handleCancelClick}
        onSubmit={handleSubmitClick}
        previousRoute={previousRoute}
        submitButtonText={SUBMIT_BUTTON_TEXT}
        submitQueryError={loanUpdateError}
        title={loanData?.dealName}
      />
    </RootLayoutV2>
  );
};

export default LoanEdit;
