import { useSelector } from 'react-redux';

import PersonPresenter from 'presenters/PersonPresenter';
import PersonIndexPresenter from 'presenters/PersonIndexPresenter';

import { personsRestHooks, usePersonChoicesActions } from 'store/PersonsSlice';

import { getSelectOptions } from 'utils/getSelectOptions';
import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getEntityLastUpdated } from 'utils/date';

export const usePeople = () => {
  const { people, loadPersons, person, loadPerson, updatePerson, deletePerson, createPerson } =
    personsRestHooks.use();
  const personOptions = getSelectOptions(people?.items, 'id', PersonPresenter.fullName);

  const { loadPersonChoices } = usePersonChoicesActions();
  const { personChoices } = useSelector(state => state.PersonsSlice);

  const peopleTableData = PersonIndexPresenter.tableData(people.items);
  const lastUpdated = getEntityLastUpdated(person?.item);
  const personErrors = PersonPresenter.errors(person);

  return {
    people,
    peopleTableData,
    loadPersons,
    personOptions,
    person: person?.item,
    loadPerson,
    updatePerson,
    deletePerson,
    createPerson,
    arePeopleLoading: useFetchStatus(people?.loadingStatus).isPending,
    isPersonLoading: useFetchStatus(person?.loadingStatus).isPending,
    lastUpdated,
    loadPersonChoices,
    personChoices,
    personErrors,
  };
};
