import axios from 'axios';
import queryString from 'query-string';
import humps from 'humps';
import FileSaver from 'file-saver';
import { flatten, is, isNil, map } from 'ramda';

import { getAuthFromLocalStorage } from 'store/AuthSlice';

import { mapDatesToISO } from 'utils/keysConverter';
import { extractFileName } from 'utils/fileUtils';

import { camelize, decamelize } from './keysConverter';

const getToken = () => {
  const { access } = getAuthFromLocalStorage();
  return access;
};

export const instance = axios.create();

instance.defaults.headers.post['Content-Type'] = 'application/json';

instance.interceptors.request.use(config => {
  const token = getToken();
  const { headers, url } = config;
  const relative = url.startsWith('/');

  return {
    ...config,
    headers: { ...(relative && token && { Authorization: `Bearer ${token}` }), ...headers },
  };
});

function headersMultipartFormData() {
  return {
    Accept: '*/*',
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'multipart/form-data',
  };
}

function toFormDataQuery(key, value, lastKey = null) {
  if ((!is(Object, value) && !isNil(value)) || value instanceof File) {
    return { key, value };
  }

  if (is(Array, value)) {
    return flatten(
      value.map((arrayValue, i) => {
        const arKey =
          is(Object, arrayValue) && lastKey.includes('_attributes') ? `${key}[${i}]` : `${key}[]`;
        return toFormDataQuery(arKey, arrayValue, lastKey);
      }),
    );
  }
  return map(
    (nestedValue, nestedKey) => toFormDataQuery(`${key}[${nestedKey}]`, nestedValue, nestedKey),
    value,
  );
}

export default {
  get(url, params = {}, arrayFormat = 'none') {
    return instance
      .get(url, {
        params: decamelize(params),
        paramsSerializer: parameters =>
          queryString.stringify(parameters, { encode: false, arrayFormat }),
      })
      .then(camelize);
  },

  post(url, json) {
    const mappedJSON = mapDatesToISO(json);
    const body = decamelize(mappedJSON);
    return instance.post(url, body).then(camelize);
  },

  put(url, json) {
    const mappedJSON = mapDatesToISO(json);
    const body = decamelize(mappedJSON);

    return instance.put(url, body).then(camelize);
  },

  patch(url, json, queryParams = {}) {
    const mappedJSON = mapDatesToISO(json);
    const body = decamelize(mappedJSON);

    return instance
      .patch(url, body, {
        params: decamelize(queryParams),
        paramsSerializer: parameters =>
          queryString.stringify(parameters, { encode: false, arrayFormat: 'brackets' }),
      })
      .then(camelize);
  },

  delete(url, json) {
    const body = decamelize(json);

    return instance.delete(url, { data: body }).then(camelize);
  },

  postDownload(url, json) {
    const mappedJSON = mapDatesToISO(json);
    const body = decamelize(mappedJSON);

    return instance
      .post(url, body, {
        responseType: 'blob',
      })
      .then(response => {
        const fileName = extractFileName(response);
        FileSaver.saveAs(new Blob([response.data]), fileName);
      });
  },

  getRaw(url) {
    return instance.get(url, { maxRedirects: 0 }).then(camelize);
  },

  postLocation(url, json, params) {
    const mappedJSON = mapDatesToISO(json);
    const body = decamelize(mappedJSON);

    return instance
      .post(url, body, {
        params: decamelize(params),
        paramsSerializer: parameters => queryString.stringify(parameters, { encode: false }),
      })
      .then(response => response.headers?.location);
  },

  toFormData(model, attributes) {
    const formData = new FormData();
    const convertedParams = toFormDataQuery(model, attributes);
    flatten(convertedParams).forEach(({ key, value }) => {
      formData.append(key, value);
    });
    return formData;
  },

  paramsToFormData(params = {}) {
    const formData = new FormData();
    const paramsKeys = Object.keys(params);
    paramsKeys.forEach(key => {
      formData.append(humps.decamelize(key), params[key]);
    });
    return formData;
  },

  postMultipartFormData(url, formData, options = {}, isRaw = false) {
    return instance
      .post(url, formData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
        ...options,
      })
      .then(response => (isRaw ? response : camelize(response.data)));
  },

  putMultipartFormData(url, formData) {
    return instance
      .put(url, formData, {
        headers: headersMultipartFormData(),
        withCredentials: true,
        credentials: 'same-origin',
      })
      .then(response => camelize(response.data));
  },
  downloadBlobData(url) {
    return instance.get(url, { responseType: 'blob' });
  },
};
