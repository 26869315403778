import React from 'react';

import PropTypes from 'prop-types';
import { keys, isEmpty } from 'ramda';
import clsx from 'clsx';

import ListTable from 'components/ListTable';

import { useOIDBalanceMemberDetails } from 'hooks/api/useOIDBalanceMemberDetails';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const FOOTER_TITLE = 'Total';

export const AccountingTable = props => {
  const { fixedTopOffset } = props;
  const classes = useStyles();

  const { accountingTableColumns, accountingTableRows } = useOIDBalanceMemberDetails();

  if (isEmpty(accountingTableRows)) return null;

  const { tableRows, footerRow } = accountingTableRows;
  const footerColumns = keys(accountingTableColumns).slice(1);

  const tableFooter = (
    <tr className={clsx(classes.tableFooter, classes.tableFooterFont, classes.fontColorMain)}>
      <td className={classes.tableBottomCell}>{FOOTER_TITLE}</td>
      {footerColumns.map(key => (
        <td className={classes.tableBottomCell} key={key}>
          {footerRow[key]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className={classes.tableWrap}>
      <ListTable
        detailPath={appRoutes.OIDBalanceReportSuccessoBorrowerPath}
        tableHeader={keys(accountingTableColumns)}
        tableData={tableRows}
        columnConfig={accountingTableColumns}
        tableClasses={classes.accountingTable}
        tableFooterContent={tableFooter}
        isOuterScrollable
        fixedTopOffset={fixedTopOffset}
      />
    </div>
  );
};

AccountingTable.propTypes = {
  fixedTopOffset: PropTypes.number,
};
