import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    listTitle: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.4)',
      marginBottom: '8px',
    },
    list: {
      padding: '16px',
    },
    listPadding: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
    listColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    markEvenElements: {
      '&>div:nth-of-type(even)': {
        backgroundColor: '#F7F9FC',
      },
    },
    entryTableHead: {
      marginBottom: '8px',
    },
    entryTableFooter: {
      marginTop: '16px',
      marginBottom: '16px',
    },
    entryTableItem: {
      padding: '5px 8px',
    },
    entryTableTotal: {
      marginTop: '16px',
      textAlign: 'end',
    },
    tableItem: {
      marginTop: '8px',
    },
    bodyBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
    bodyNormal: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
    textEnd: {
      textAlign: 'end',
    },
    tableHeaderFont: {
      textTransform: 'uppercase',
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      fontStyle: 'normal',
      color: theme.palette.santasGray,
      textAlign: 'right',
    },
  }),
  { name: 'JournalEntryTable' },
);
