import React, { useState, useEffect } from 'react';

import { Typography, Button, Link } from '@material-ui/core';
import { isNil } from 'ramda';
import { FormProvider, useForm } from 'react-hook-form';

import DocumentTitle from 'components/DocumentTitle';
import TableSummary from 'components/TableSummary';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';

import {
  useGetReconciliationInputsQuery,
  useGetReconciliationInputsSummaryQuery,
  useGetReconciliationInputsAvailableYearsQuery,
  useCreateReconciliationInputMutation,
  useUpdateReconciliationInputMutation,
  useDeleteReconciliationInputMutation,
} from 'domain/reconciliationInput/apiSlice';
import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';
import * as ReconciliationInputService from 'domain/reconciliationInput/service';

import useRouter from 'hooks/useRouter';
import { useEditableTableControls, EditableTableProvider } from 'hooks/useEditableTableControls';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import YearSelect from 'pages/LoanReconciliationInputs/components/YearSelect';

import { appRoutes } from 'router/routes';

import { findError } from 'utils/errors';
import { isStatusActiveInPayment } from 'utils/layoutStatus';
import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';

import ReconciliationTable from './components/ReconciliationTable';
import useStyles from './useStyles';
import { prepareBeforeSend, resolver, defaultFormValues } from './validation';

const TITLE = 'Reconciliation Inputs';
const ADD_VALUE = 'Add Value';
const OID_BALANCE = 'OID Balance';
const SELECT_YEAR_LABEL = 'Select the Year';

export const LoanReconciliationInputs = () => {
  const {
    query: { id },
  } = useRouter();

  const classes = useStyles();

  const tableControls = useEditableTableControls();
  const { hideCreatingForm, showCreatingForm, hideEditableRow } = tableControls;

  const { defaultYearSelectOption } = ReconciliationInputService;
  const [yearOption, setYearOption] = useState(defaultYearSelectOption);

  const methods = useForm({
    resolver,
    defaultValues: defaultFormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const {
    data: reconciliationInputsData,
    isFetching: isReconciliationInputsLoading,
    error: reconciliationInputsLoadError,
  } = useGetReconciliationInputsQuery({
    loanId: id,
    year: isNil(yearOption.option) ? undefined : yearOption.option,
  });

  const {
    data: yearSelectOptionsData,
    error: yearSelectOptionsLoadError,
    isFetching: isYearSelectOptionsLoading,
  } = useGetReconciliationInputsAvailableYearsQuery(id);

  const [
    createReconciliationInput,
    {
      error: reconciliationInputCreateLoadError,
      isSuccess: isReconciliationInputCreatingSuccess,
      isLoading: isReconciliationInputCreatePending,
    },
  ] = useCreateReconciliationInputMutation();

  const [deleteReconciliationInput, { isLoading: isReconciliationInputDeletePending }] =
    useDeleteReconciliationInputMutation();

  const [
    updateReconciliationInput,
    {
      error: reconciliationInputUpdateLoadError,
      isSuccess: isReconciliationInputUpdatingSuccess,
      isLoading: isReconciliationInputUpdatePending,
    },
  ] = useUpdateReconciliationInputMutation();

  const yearSelectOptions =
    ReconciliationInputService.createYearSelectOptions(yearSelectOptionsData);

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);
  const loanStatus = LoanService.getLoanStatus(loanData);

  const {
    data: summary,
    isFetching: isSummaryLoading,
    error: summaryLoadError,
  } = useGetReconciliationInputsSummaryQuery(id);

  const summaryTableData = ReconciliationInputService.getTableSummary(summary, loanData);

  useHandleRtkQueryErrors(
    loanLoadError,
    summaryLoadError,
    reconciliationInputsLoadError,
    yearSelectOptionsLoadError,
    reconciliationInputCreateLoadError,
    reconciliationInputUpdateLoadError,
  );

  const { formatErrors, setErrorsToForm } = useErrors();

  useEffect(() => {
    const error = findError([
      reconciliationInputCreateLoadError,
      reconciliationInputUpdateLoadError,
    ]);
    if (!error) return;
    const { fieldErrors } = formatErrors(error);
    setErrorsToForm(methods.setError, methods.errors, fieldErrors);
  }, [reconciliationInputCreateLoadError, reconciliationInputUpdateLoadError]);

  useEffect(() => {
    if (isReconciliationInputCreatingSuccess) hideCreatingForm();
    if (isReconciliationInputUpdatingSuccess) hideEditableRow();
  }, [isReconciliationInputCreatingSuccess, isReconciliationInputUpdatingSuccess]);

  const handleCreate = async data => {
    const preparedData = prepareBeforeSend(data);
    await createReconciliationInput({
      ...preparedData,
      loanId: id,
    });
  };

  const handleUpdate = rowId => async data => {
    const preparedData = prepareBeforeSend(data);
    await updateReconciliationInput({
      id: rowId,
      data: {
        ...preparedData,
        loanId: id,
      },
    });
  };

  const handleDelete = async rowId => {
    await deleteReconciliationInput(rowId);
  };

  const handleAddButton = () => {
    hideEditableRow();
    showCreatingForm();
  };

  const handleYearChange = (_, value) => {
    if (isNil(value)) return;
    setYearOption(value);
    hideCreatingForm();
  };

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isDataLoading =
    isReconciliationInputsLoading ||
    isLoanLoading ||
    isSummaryLoading ||
    isYearSelectOptionsLoading ||
    isReconciliationInputCreatePending ||
    isReconciliationInputUpdatePending ||
    isReconciliationInputDeletePending;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isDataLoading}>
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <ContentLayout>
          <DocumentTitle title={TITLE} />
          <div className={classes.headerPanel}>
            <Typography variant="h1">{TITLE}</Typography>
            <div>
              {isStatusActiveInPayment(loanStatus) && (
                <Link
                  href={appRoutes.OIDBalanceReportLoanPath(id)}
                  color="secondary"
                  className={classes.link}
                >
                  {OID_BALANCE}
                </Link>
              )}
              <Button color="primary" className={classes.headerButton} onClick={handleAddButton}>
                {ADD_VALUE}
              </Button>
            </div>
          </div>
          <YearSelect
            label={SELECT_YEAR_LABEL}
            options={yearSelectOptions}
            onChange={handleYearChange}
            className={classes.autocomplete}
            value={yearOption}
          />

          {!isNil(reconciliationInputsData) && (
            <EditableTableProvider {...tableControls}>
              <FormProvider {...methods}>
                <ReconciliationTable
                  reconciliationInputsData={reconciliationInputsData}
                  onUpdate={handleUpdate}
                  onCreate={handleCreate}
                  onDelete={handleDelete}
                />
              </FormProvider>
            </EditableTableProvider>
          )}
          <TableSummary summaryData={summaryTableData} className={classes.summary} />
        </ContentLayout>
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
