import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    drawer: {
      width: '300px',
      boxShadow: 'none',
      overflow: 'visible',
    },
    inner: {
      paddingTop: 0,
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '24px',
      overflowY: 'auto',
    },
    wide: {
      width: '430px',
    },
    backdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
    },
    closeButton: {
      position: 'absolute',
      left: '100%',
      top: 0,
      margin: '8px',
      width: '32px',
      height: '32px',
      padding: '8px',
      color: theme.palette.white,
    },
    label: {
      textTransform: 'uppercase',
      opacity: 0.4,
      color: theme.palette.elephant,
      marginTop: '24px',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        marginRight: '8px',
        width: '14px',
      },
    },
    outBox: {
      margin: [0, '-8px', '32px'],
      '&:last-child': {
        marginBottom: 0,
      },
    },
    link: {
      width: '100%',
      display: 'flex',
      height: '40px',
      fontWeight: 'normal',
      justifyContent: 'space-between',
      color: theme.palette.elephant,
      textTransform: 'none',
      alignItems: 'center',
      padding: [0, '8px'],
    },
    withArrow: {
      '&::after': {
        content: '""',
        display: 'block',
        border: `solid ${theme.palette.elephant}`,
        borderWidth: '1px 1px 0 0',
        opacity: 0.4,
        transform: 'rotate(45deg)',
        width: '8px',
        height: '8px',
      },
    },
  }),
  { name: 'SearchPanel' },
);
