import React from 'react';

import PropTypes from 'prop-types';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import useStyles from './useStyles';

export const ScrollToTopButton = props => {
  const { anchorElement } = props;
  const classes = useStyles();

  const handleClick = () => {
    if (!anchorElement) return;
    anchorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  };

  return (
    <div onClick={handleClick} role="presentation" className={classes.root}>
      <Fab
        color="secondary"
        size="small"
        aria-label="scroll back to top"
        className={classes.button}
      >
        <KeyboardArrowUpIcon />
      </Fab>
    </div>
  );
};

ScrollToTopButton.propTypes = {
  anchorElement: PropTypes.instanceOf(Element),
};
