import { createSlice } from '@reduxjs/toolkit';
import BloombergConfigurationsRepository from 'repositories/BloombergConfigurationsRepository';

import { createRestSlice } from 'utils/RestSlice';

const bloombergConfigurationsRestSlice = createRestSlice({
  resource: 'bloombergConfiguration',
  repository: BloombergConfigurationsRepository,
  slices: ['loadBloombergConfiguration', 'updateBloombergConfiguration'],
});

const initialState = {
  ...bloombergConfigurationsRestSlice.initialState,
};

const bloombergConfigurationsSlice = createSlice({
  name: 'bloombergConfigurations',
  initialState,
  reducers: {
    ...bloombergConfigurationsRestSlice.reducers,
  },
});

export default bloombergConfigurationsSlice.reducer;
export const bloombergConfigurationsRestHooks = bloombergConfigurationsRestSlice.hooks(
  bloombergConfigurationsSlice,
  'BloombergConfigurationsSlice',
);
