import { createSlice } from '@reduxjs/toolkit';
import SummaryOfDealsRepository from 'repositories/SummaryOfDealsRepository';

import { createRestSlice } from 'utils/RestSlice';

const summaryOfDealsRestSlice = createRestSlice({
  resource: 'summaryOfDeal',
  repository: SummaryOfDealsRepository,
  slices: ['loadSummaryOfDeals'],
});

const initialState = {
  ...summaryOfDealsRestSlice.initialState,
};

const summaryOfDealsSlice = createSlice({
  name: 'summaryOfDeals',
  initialState,
  reducers: {
    ...summaryOfDealsRestSlice.reducers,
  },
});

export default summaryOfDealsSlice.reducer;
export const summaryOfDealsRestHook = summaryOfDealsRestSlice.hooks(
  summaryOfDealsSlice,
  'SummaryOfDealsSlice',
);
