import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    section: {
      marginBottom: '32px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    tableContainer: {
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    contentSubtitle: {
      margin: [8, 0],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.primary.main,
      opacity: 0.5,
      letterSpacing: '0.02em',
    },
    contentTitle: {
      marginTop: '11px',
      marginBottom: 0,
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      overflow: 'hidden',
      padding: [32, 24],
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
    },
    editButton: {
      flexShrink: 0,
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',

      '& span': {
        opacity: 0.75,
      },
    },
    detailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    content: {
      width: '100%',
    },
    fieldNameTableCell: {
      width: '40%',
    },
    detailTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: '16px',
      paddingTop: '16px',
      fontWeight: 'normal',
    },
    entites: {
      paddingTop: 0,
    },
    statusWindow: {
      minWidth: '312px',
      marginLeft: '24px',
      maxWidth: '380px',
    },
    statusWrap: {
      marginBottom: '24px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    statusTitle: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.neutral.light,
      margin: 0,
      marginBottom: '8px',
      textTransform: 'uppercase',
    },
    statusValue: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      margin: 0,
      color: theme.palette.capeCod,
    },
    backgroundActive: {
      backgroundColor: theme.palette.feta,
    },
    backgroundInactive: {
      background: theme.palette.fairPink,
    },
  }),
  { name: 'SuccessorBorrowersInfo' },
);
