import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    linksList: {
      '& > li:nth-child(n + 2)': {
        paddingLeft: '40px',
      },
    },
    newDealLink: {
      paddingLeft: '16px',
    },
  }),
  { name: 'SuccessorBorrowerSideNav' },
);
