import React, { useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { useQueryParams, StringParam } from 'use-query-params';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';

import { useMissingReconciliationReport } from 'hooks/api';
import {
  usePaginationAndOrderingContext,
  withResetPageParam,
} from 'hooks/usePaginationAndOrdering';

import ContentLayout from 'layouts/ContentLayout';

import { formatDateToYearMonth } from 'utils/date';

import useStyles from './useStyles';
import ReportMonthInput from './components/ReportMonthInput';
import ReportList from './components/ReportList';

const TITLE = 'Missing Reconciliation Inputs';

export const MissingReconciliationInputs = () => {
  const { loadReport, resetReport, isReportLoading } = useMissingReconciliationReport();
  const classes = useStyles();
  const paginationMethods = usePaginationAndOrderingContext();
  const { page } = paginationMethods;
  const [query, setQuery] = useQueryParams({
    yearMonth: StringParam,
  });

  const { yearMonth } = query;
  const setYearMonth = selectedYearMonth => {
    setQuery(withResetPageParam({ yearMonth: selectedYearMonth, page: 1 }));
  };

  useEffect(() => () => resetReport(), []);

  useEffect(() => {
    if (yearMonth && page) {
      loadReport({ reconciliationYearMonth: yearMonth, page });
    }
  }, [yearMonth, page]);

  const onSubmit = async data => {
    const { reconciliationYearMonth: date } = data;
    const formattedDate = formatDateToYearMonth(date);
    setYearMonth(formattedDate);
  };

  return (
    <ContentLayout className={classes.reportWrap} isSmall>
      <DocumentTitle title={TITLE} />
      <Typography variant="h1">{TITLE}</Typography>
      <ReportMonthInput onSubmit={onSubmit} />
      {isReportLoading ? <Loader /> : <ReportList />}
    </ContentLayout>
  );
};
