import React from 'react';

import PropTypes from 'prop-types';

import PageNotFoundMessage from 'components/PageNotFoundMessage';

import useRouter from 'hooks/useRouter';

export const Error404 = props => {
  const { children, className } = props;
  const { location } = useRouter();

  if (location?.state?.isPageNotFoundError) return <PageNotFoundMessage className={className} />;

  return children;
};

Error404.propTypes = {
  className: PropTypes.string,
};
