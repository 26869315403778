import { makeStyles } from '@material-ui/core';
import accountIcon from 'assets/account.svg';

export default makeStyles(
  theme => ({
    header: {
      gridArea: 'header',
      backgroundColor: theme.palette.primary.main,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 25,
      paddingRight: 24,
    },
    headerWrap: {
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
    },
    logo: {
      marginRight: 24,
    },
    logoQa: {
      marginRight: '3px',
    },
    qaBadge: {
      marginRight: '60px',
    },
    searchPanel: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.white,
      marginRight: 80,
      '& svg': {
        height: 13,
      },
    },
    headerNav: {
      '& > a': {
        textDecoration: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(16),
        color: theme.palette.white,
        opacity: 0.5,
        display: 'inline-block',
        marginRight: 16,
        '&:hover': {
          opacity: 1,
        },
        '&.active': {
          opacity: 1,
        },
        '&:last-child': {
          marginRight: 0,
        },
      },
    },
    authPanel: {
      justifySelf: 'end',
      display: 'block',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      height: theme.typography.pxToRem(24),
      position: 'relative',
      padding: [0, 8, 0, 32],
      color: theme.palette.white,
      textTransform: 'none',
      minWidth: 0,
      marginRight: -8,
      borderRadius: 12,
      '&:before': {
        display: 'block',
        content: "''",
        width: 24,
        height: 24,
        position: 'absolute',
        left: 0,
        top: 0,
        background: `url(${accountIcon}) no-repeat center`,
        backgroundSize: 'cover',
      },
    },
    authPanelMenu: {
      padding: '16px',
      paddingBottom: 0,
      position: 'absolute',
      width: '146px',
      top: '32px',
      right: 0,
      background: theme.palette.white,
      boxShadow: '0px 6px 16px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)',
      borderRadius: 4,
      zIndex: 10,
    },
    authPanelLink: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      fontWeight: 400,
      display: 'block',
      marginBottom: '16px',
    },
    divider: {
      background: theme.palette.blackSqueeze,
      height: 1,
      width: '100%',
    },
    helpLink: {
      marginBottom: 0,
    },
    helpFileLinkWrap: {
      height: '52px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    helpFileLoader: {
      width: '28px',
    },
  }),
  { name: 'Header' },
);
