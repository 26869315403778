import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValid, format as formatDate } from 'date-fns';

import { ERROR_MESSAGES } from 'enums';
import { FORM_FIELD_TYPE } from 'enums';

import { parseDate } from 'utils/date';
import { preventEmptyString } from 'utils/yupHelpers';
import { preventNullProps } from 'utils/forms';
import { replaceEmptyStringsToNull } from 'utils/forms';

export const resolver = yupResolver(
  Yup.object({
    maturityDate: Yup.date().typeError(ERROR_MESSAGES.date.invalidDate).nullable(),
    loanAmount: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .nullable()
      .transform(preventEmptyString),
    name: Yup.string().required(ERROR_MESSAGES.required),
  }),
);

export const formFields = [
  { name: 'name', type: FORM_FIELD_TYPE.input },
  { name: 'loanAmount', type: FORM_FIELD_TYPE.decimal, placeholder: '$0' },
  { name: 'maturityDate', type: FORM_FIELD_TYPE.date },
];

export const prepareBeforeSending = data => {
  const { maturityDate, ...restData } = data;
  return replaceEmptyStringsToNull({
    ...restData,
    maturityDate: isValid(maturityDate) ? formatDate(maturityDate, 'yyyy-MM-dd') : null,
  });
};

export const prepareBeforeSetting = data => {
  const { maturityDate, ...restData } = data;
  return {
    ...preventNullProps(restData),
    maturityDate: parseDate('yyyy-MM-dd')(maturityDate),
  };
};
