import MuiInput from './MuiInput';
import MuiInputLabel from './MuiInputLabel';
import Button from './Button';
import FormControl from './FormControl';
import MuiSelect from './MuiSelect';
import FormControlLabel from './FormControlLabel';
import MuiRadio from './MuiRadio';
import MuiFormGroup from './MuiFormGroup';
import MuiIconButton from './MuiIconButton';
import MuiTextField from './MuiTextField';

export default {
  overrides: {
    ...MuiInput,
    ...MuiInputLabel,
    ...Button,
    ...FormControl,
    ...MuiSelect,
    ...FormControlLabel,
    ...MuiRadio,
    ...MuiFormGroup,
    ...MuiIconButton,
    ...MuiTextField,
  },
};
