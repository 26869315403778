import React from 'react';

import { TableRow, TableCell, Button } from '@material-ui/core/';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useFundingNotes } from 'hooks/api';
import { useEditableTableContext } from 'hooks/useEditableTableControls';

import useStyles from 'pages/FundingNotes/useStyles';

import { isHedgingAmountId } from 'utils/fundingNotes';

const DELETE_BUTTON = 'Delete';
const EDIT_BUTTON = 'Edit';

export const DataRow = props => {
  const { row, onDelete, onEdit } = props;
  const classes = useStyles();
  const { columns } = useFundingNotes();
  const { isDataRowsDisabled } = useEditableTableContext();

  const dataKeys = columns.getDataKeys();

  return (
    <TableRow className={clsx({ [classes.disabledRow]: isDataRowsDisabled })}>
      {dataKeys.map((key, index) => (
        <TableCell key={`data-cell-${index}`} className={classes.tableCellFont}>
          {row.data[key]}
        </TableCell>
      ))}
      <TableCell>
        <Button
          color="primary"
          type="submit"
          className={classes.saveButton}
          onClick={onEdit}
          disabled={isDataRowsDisabled}
        >
          {EDIT_BUTTON}
        </Button>
        <Button
          className={classes.deleteButton}
          onClick={onDelete(row.id)}
          disabled={isDataRowsDisabled || isHedgingAmountId(row.data.id)}
        >
          {DELETE_BUTTON}
        </Button>
      </TableCell>
    </TableRow>
  );
};

DataRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      comment: PropTypes.string,
      id: PropTypes.number,
    }),
  }),
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};
