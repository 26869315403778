import React, { useEffect } from 'react';

import PoolForm from 'components/PoolForm';

import { useCreatePoolMutation } from 'domain/pool/apiSlice';
import { PoolService } from 'domain/pool/service';

import useRouter from 'hooks/useRouter';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

const NEW_POOL = 'New CMBS Pool';
const ADD = 'Add';

const PoolAdd = () => {
  const { push } = useRouter();

  const [
    createPool,
    {
      isLoading: isCreatePoolLoading,
      error: createPoolLoadError,
      isSuccess: createPoolLoadSuccess,
    },
  ] = useCreatePoolMutation();

  const previousRoute = appRoutes.poolsPath();

  useEffect(() => {
    if (createPoolLoadSuccess) push(previousRoute);
  }, [createPoolLoadSuccess]);

  const handleAddButtonClick = dataToSend => createPool(dataToSend);

  const handleCancelButtonClick = () => push(previousRoute);

  return (
    <RootLayoutV2>
      <PoolForm
        defaultPoolData={PoolService.getDefaultValues()}
        formSubmitError={createPoolLoadError}
        isEdit={false}
        isFormSubmitting={isCreatePoolLoading}
        onCancel={handleCancelButtonClick}
        onSubmit={handleAddButtonClick}
        pageTitle={NEW_POOL}
        previousRoute={previousRoute}
        submitButtonText={ADD}
      />
    </RootLayoutV2>
  );
};
export default PoolAdd;
