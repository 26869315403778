import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentWrap: {
      maxWidth: '970px',
    },
    headerPanel: {
      marginBottom: '8px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerButton: {
      height: '32px',
      background: 'rgba(19, 51, 76, 0.07)',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.elephant,
    },
    table: {
      '& th': {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
      '& td': {
        paddingTop: '4px',
        paddingBottom: '4px',
      },
    },
    tableHeader: {
      marginBottom: '16px',
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgb(19, 51, 76, 0.4)',
    },
    tableHead: {
      background: theme.palette.athensGray,
    },
    tableHeadCell: {
      textAlign: 'left',
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
      color: theme.palette.santasGray,
    },
    inputCell: {
      '& input': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    input: {
      height: '24px',
      '& .MuiInput-root': {
        height: '24px',
      },
      '& svg': {
        height: '20px',
      },
    },
    tableCellFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
    },
    totalRow: {
      height: '40px',
      backgroundColor: theme.palette.catskillWhiteThree,
    },
    totalRowFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(12),
      fontWeight: 700,
      color: theme.palette.capeCod,
    },
    disabledRow: {
      backgroundColor: theme.palette.athensGray,
      '& td': {
        color: theme.palette.santasGray,
      },
    },
    disabledIconButton: {
      opacity: '0.33',
    },
    saveButton: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
      paddingLeft: '0px',
    },
    deleteButton: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.crimson,
    },
    inputCellWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    errorMessage: {
      maxWidth: '200px',
      marginTop: '3px',
      marginBottom: '3px',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.crimson,
    },
    card: {
      padding: '16px',
    },
    whiteText: {
      '& .MuiButton-label': {
        color: 'white',
      },
    },
    popupInner: {
      padding: '0px 32px',
    },
    popupText: {
      whiteSpace: 'pre-line',
      color: theme.palette.tuna,
      opacity: 0.7,
    },
  }),
  { name: 'FundingNotes' },
);
