import palette from '../palette';

export default {
  MuiOutlinedInput: {
    root: {
      '&$focused $notchedOutline': {
        borderColor: 'transparent',
        borderWidth: 0,
      },
    },
  },
  MuiInputBase: {
    root: {
      width: '100%',
      background: palette.white,
      border: [2, 'solid', '#EAEFF5'],
      boxSizing: 'border-box',
      borderRadius: 3,
      fontSize: 14,
      lineHeight: '20px',
      color: palette.primary.main,
      padding: [8, 12],
      opacity: 1,
      '&$focused': {
        border: [2, 'solid', `${palette.secondary.main}`],
      },
      '&:hover': {
        border: [2, 'solid', '#EAEFF5'],
      },
      '&.Mui-disabled': {
        backgroundColor: palette.blackSqueeze,
      },
    },
  },
  MuiInput: {
    root: {
      width: '100%',
      height: 36,
      background: '#FAFBFC',
      border: [2, 'solid', '#EAEFF5'],
      boxSizing: 'border-box',
      borderRadius: 3,
      fontSize: 14,
      lineHeight: '20px',
      color: palette.primary.main,
      padding: [8, 12],
      backgroundColor: palette.white,
      '&$focused': {
        border: [2, 'solid', `${palette.secondary.main}`],
      },
    },
    multiline: {
      height: 'auto',
    },
  },
};
