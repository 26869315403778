/* eslint-disable react/prop-types */
import React from 'react';

import FormGroup from 'components/FormGroup';
import FormField from 'components/FormField';

const FormBankAccount = ({ fields, errors, values, selectValues }) => {
  const [titleField, ...restFields] = fields;

  return (
    <>
      <FormField
        fieldArray={[titleField]}
        errors={errors}
        values={values}
        selectValues={selectValues}
      />
      <FormGroup>
        <FormField
          fieldArray={restFields}
          errors={errors}
          values={values}
          selectValues={selectValues}
        />
      </FormGroup>
    </>
  );
};

FormBankAccount.propTypes = {};

export default FormBankAccount;
