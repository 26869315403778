import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    listTitle: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.4)',
      marginBottom: '8px',
    },
    list: {
      padding: '16px',
    },
    summaryListItem: {
      padding: '5px 8px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    summaryTotal: {
      marginBottom: '16px',
      padding: '5px 8px',
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.athensGray,
    },
    summaryList: {
      paddingLeft: '16px',
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
      display: 'flex',
      flexDirection: 'column',
    },
    bodyBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
    body1: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(18),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
  }),
  { name: 'SummaryOidOffBy' },
);
