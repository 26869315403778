import { getNameFromUrl } from './url';

export const fetchDataFromAmazonS3 = async (url, axiosInstance) => {
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  const name = getNameFromUrl(response.config.url);
  const { data } = response;
  const type = response.headers['content-type'];
  const blob = new Blob([data], { type });
  return {
    content: blob,
    name,
  };
};
