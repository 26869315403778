import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params) {
    const url = apiRoutes.successorBorrowersPath();
    return FetchHelpers.get(url, params);
  },
  async show(id, params) {
    const url = apiRoutes.successorBorrowerPath(id);
    return FetchHelpers.get(url, params);
  },
  async update(id, params) {
    const url = apiRoutes.successorBorrowerPath(id);
    return FetchHelpers.put(url, params);
  },
  async delete(id, params) {
    const url = apiRoutes.successorBorrowerPath(id);
    return FetchHelpers.delete(url, params);
  },
  async create(params) {
    const url = apiRoutes.successorBorrowersPath();
    return FetchHelpers.post(url, params);
  },
  async relatedEntities(id, params) {
    const url = apiRoutes.successorBorrowersRelatedEntitiesPath(id);
    return FetchHelpers.get(url, params);
  },
  async choices(params = {}) {
    const url = apiRoutes.successorBorrowersChoicesPath();
    return FetchHelpers.get(url, params);
  },
  async configuration(params) {
    const url = apiRoutes.successorBorrowersConfigurationPath();
    return FetchHelpers.get(url, params);
  },
};
