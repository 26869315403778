import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag, invalidateOnSuccess } from 'utils/rtkQuery';

export const loanMarketDiscountApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoanMarketDiscounts: builder.query({
      query: loanId => ({
        url: apiRoutes.loansMarketDiscountsPath(loanId),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.loanMarketDiscount),
    }),
    getLoanMarketDiscountsSummary: builder.query({
      query: loanId => ({
        url: apiRoutes.loansMarketDiscountsSummaryPath(loanId),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.loanMarketDiscountSummary],
    }),
    updateLoanMarketDiscount: builder.mutation({
      query: ({ loanId, marketDiscountId, data }) => ({
        url: apiRoutes.loanMarketDiscountPath(loanId, marketDiscountId),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOnSuccess([
        { type: CACHE_TAG.loanMarketDiscount, id: 'LIST' },
        CACHE_TAG.loanMarketDiscountSummary,
      ]),
    }),
  }),
});

export const {
  useGetLoanMarketDiscountsQuery,
  useUpdateLoanMarketDiscountMutation,
  useGetLoanMarketDiscountsSummaryQuery,
} = loanMarketDiscountApi;
