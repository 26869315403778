import React, { useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { NavHashLink } from 'react-router-hash-link';

import FormError from 'components/FormError';
import PasswordInput from 'components/PasswordInput';
import SimpleInput from 'components/SimpleInput';

import { useUsers } from 'hooks/api/useUsers';

import { appRoutes } from 'router/routes';

import { useAuthActions } from 'store/AuthSlice';

import useStyles from './useStyles';
import { defaultValues, resolver } from './validation';

const LoginForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { signIn } = useAuthActions();
  const { loadCurrentUser } = useUsers();
  const methods = useForm({ resolver, defaultValues });
  const [formErrors, setFormErrors] = useState(null);

  const fromLocation = history?.location?.state?.from;

  const goToHomePage = () => {
    history.push({ pathname: appRoutes.homeUpcomingParRepaysPath() });
  };

  const goToFromLocation = () => {
    history.push({ pathname: fromLocation });
  };

  const submitHandler = async formData => {
    try {
      setFormErrors(null);
      await signIn(formData);
      await loadCurrentUser();
      if (fromLocation) {
        if (fromLocation !== appRoutes.authPath()) {
          goToFromLocation();
        } else {
          goToHomePage();
        }
      } else {
        goToHomePage();
      }
    } catch (e) {
      setFormErrors(e?.nonFieldErrors);
    }
  };

  return (
    <div className={classes.formWrap}>
      <h2 className={classes.formTitle}>
        Log in to DHC
        <br />
        Successor Borrower Database
      </h2>
      <form className={classes.form} onSubmit={methods.handleSubmit(submitHandler)}>
        <FormProvider {...methods}>
          <SimpleInput className={classes.textField} name="username" label="User Name" />
          <PasswordInput name="password" label="Password" />
          {formErrors?.length > 0 &&
            formErrors.map((item, index) => <FormError key={`error_${index}`} message={item} />)}
          <Button
            type="submit"
            className={classes.submitButton}
            variant="contained"
            color="secondary"
          >
            Sign In
          </Button>
          <NavHashLink className={classes.forgotLink} to={appRoutes.passwordRecoveryPath()}>
            Forgot your Password?
          </NavHashLink>
        </FormProvider>
      </form>
    </div>
  );
};

export default LoginForm;
