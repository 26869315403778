import PropTypes from 'prop-types';

import { StatusConst, StatusDisplayedNameByCode } from 'const';

import { CELL_TYPES, STATUS_COLUMN } from 'enums';

import BankAccountPresenter from 'presenters/BankAccountPresenter';
import AddressPresenter from 'presenters/AddressPresenter';

import Presenter, { shortDescriptionModel } from 'utils/PropTypesPresenter';
import { getAddressString } from 'utils/dataTransform';
import { formatDate } from 'utils/date';

export const memberColumnConfig = {
  status: {
    displayedName: 'Status',
  },
  statusNote: {
    displayedName: 'Status Note',
  },
  administrativeAgent: {
    displayedName: 'Administrative Agent',
  },
  ein: {
    displayedName: 'EIN',
  },
  einDateFiledOn: {
    displayedName: 'EIN Filed Date',
  },
  form2553FiledDate: {
    displayedName: '2553 Filed Date',
  },
  form2553AcceptedDate: {
    displayedName: '2553 Accepted Date',
  },
  secretaryOfStateId: {
    displayedName: 'SOS ID',
  },
  sosAcceptedDate: {
    displayedName: 'SOS Accepted Date',
  },
  jurisdiction: {
    displayedName: 'Jurisdiction',
  },
  address: {
    displayedName: 'Company Address',
  },
  fiscalYear: {
    displayedName: 'Fiscal Year Ending',
  },
  registeredAgent: {
    displayedName: 'Registered Agent',
  },
  registeredOfficeAddress: {
    displayedName: 'Registered Office Address',
  },
  dateOfDissolutionOn: {
    displayedName: 'Date of Dissolution',
  },
  bankAccount: {
    displayedName: 'Bank Account',
    cellType: CELL_TYPES.information,
  },
};

export default new Presenter(
  {
    data: {
      id: PropTypes.number,
      registeredOfficeAddress: AddressPresenter.shape(),
      address: AddressPresenter.shape(),
      bankAccount: BankAccountPresenter.shape(),
      name: PropTypes.string,
      sosAcceptedDate: PropTypes.date,
      shortName: PropTypes.string,
      jurisdiction: PropTypes.string,
      secretaryOfStateId: PropTypes.string,
      einDateFiledOn: PropTypes.date,
      fiscalYear: PropTypes.string,
      dateOfDissolutionOn: PropTypes.date,
      form2553FiledDate: PropTypes.date,
      form2553AcceptedDate: PropTypes.date,
      filingRef: PropTypes.number,
      ein: PropTypes.string,
      status: PropTypes.string,
      statusNote: PropTypes.string,
      masterServicerName: PropTypes.string,
      specialServicer: PropTypes.string,
      fiscalAgent: PropTypes.string,
      psa: PropTypes.string,
      recordBookDir: PropTypes.string,
      indemnityGiven: PropTypes.string,
      indemnityNotes: PropTypes.string,
      certificateDefinition: PropTypes.string,
      certificateHolderLabel: PropTypes.string,
      enteredBy: PropTypes.string,
      enteredDate: PropTypes.date,
      registeredAgent: shortDescriptionModel,
      masterServicer: PropTypes.number,
      administrativeAgent: shortDescriptionModel,
    },
  },
  {
    tableData(data) {
      if (!data) {
        return {};
      }
      return {
        ...data,
        status: StatusDisplayedNameByCode[data?.status],
        registeredOfficeAddress: getAddressString(data.registeredOfficeAddress),
        address: getAddressString(data.address),
        administrativeAgent: data?.administrativeAgent.name,
        registeredAgent: data?.registeredAgent?.name,
        sosAcceptedDate: formatDate(data?.sosAcceptedDate),
        einDateFiledOn: formatDate(data?.einDateFiledOn),
        dateOfDissolutionOn: formatDate(data?.dateOfDissolutionOn),
        form2553FiledDate: formatDate(data?.form2553FiledDate),
        form2553AcceptedDate: formatDate(data?.form2553AcceptedDate),
        enteredDate: formatDate(data?.enteredDate),
        bankAccount: {
          title: data.bankAccount?.name,
          fields: BankAccountPresenter.tableData(data?.bankAccount),
        },
      };
    },
    defaultValues(configuration = {}) {
      const { companyAddress, registeredOfficeAddress } = configuration;
      return {
        status: StatusConst[STATUS_COLUMN.active],
        bankAccount: BankAccountPresenter.defaultValues(),
        address: AddressPresenter.defaultValues(companyAddress),
        registeredOfficeAddress: AddressPresenter.defaultValues(registeredOfficeAddress),
      };
    },
  },
);
