import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import { isEmpty } from 'ramda';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Button, Checkbox, CircularProgress, TextField } from '@material-ui/core';
import union from 'assets/union.svg';
import { useFormContext, useController } from 'react-hook-form';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';

import useStyles from 'pages/Loans/useStyles';

import { getDefaultOption } from 'utils/loansFilters';

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

const getOptionLabel = option => option?.label || '';

const getOptionSelected = (option, chosenOption) => option.value === chosenOption.value;

const LoanFilterSelect = props => {
  const { title, name, options, isLoading, defaultValue, multiple = false } = props;

  const { control } = useFormContext();

  const {
    field: { onChange, ...autocompleteProps },
  } = useController({
    name,
    control,
  });

  const classes = useStyles();

  useEffect(() => {
    if (isEmpty(options)) return;
    onChange(getDefaultOption(options, defaultValue, multiple));
  }, [defaultValue]);

  const [isOpened, setOpened] = useState(false);

  const handleOpen = () => {
    setOpened(true);
  };

  const handleClose = () => {
    setOpened(false);
  };

  const getTags = (tagValue, getTagProps) =>
    tagValue.map((option, index) => {
      const tagProps = { ...getTagProps(index) };
      return (
        <div
          className={clsx(classes.filterTag, tagProps.className)}
          key={`key-tag-${option.label}`}
        >
          <span>{option.label}</span>
          <Button className={classes.filterTagButton} onClick={tagProps?.onDelete}>
            <img height="12px" alt="union" width="12px" src={union} />
          </Button>
        </div>
      );
    });

  const getInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        className: clsx(params.InputProps.className, classes.filterInput, {
          [classes.filterInputOpened]: isOpened,
        }),
        endAdornment: (
          <>
            {isLoading && <CircularProgress color="inherit" size={20} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      onMouseDown={handleOpen}
      autoComplete="off"
      size="small"
      variant="standard"
    />
  );

  const getOption = (option, { selected }) => (
    <div className={classes.filterOption}>
      {multiple && (
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          size="small"
          style={{ margin: 0, padding: '0 8px 0 0' }}
          checked={selected}
        />
      )}
      {option.label}
    </div>
  );

  return (
    <div className={clsx(classes.filterContainer, { [classes.filterContainerMultiple]: multiple })}>
      <h3 className={classes.optionTitle}>{title}</h3>
      <Autocomplete
        {...autocompleteProps}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        size="small"
        limitTags={1}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        className={classes.filterAutocomplete}
        onOpen={handleOpen}
        onClose={handleClose}
        openOnFocus
        renderTags={getTags}
        options={options}
        renderInput={getInput}
        renderOption={getOption}
        loading={isLoading}
        loadingText="Loading..."
        clearOnBlur={false}
        onChange={(_, data) => {
          onChange(data);
        }}
      />
    </div>
  );
};
export default LoanFilterSelect;

LoanFilterSelect.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
  isLoading: PropTypes.bool,
  searchCallback: PropTypes.func,
  searchOnFirstOpenOnly: PropTypes.bool,
  multiple: PropTypes.bool,
};
