import { pathOr, uniq } from 'ramda';

export const getDefaultOption = (fieldKey, values) => ({
  label: pathOr(null, [fieldKey, 'name'], values),
  value: pathOr(null, [fieldKey, 'id'], values),
});

export const selectOptions = items => items.map(item => ({ label: item.name, value: item.id }));

export const mergeOptions = (options1, options2) => uniq([...options1, ...options2]);
