import PropTypes from 'prop-types';
import { FORM_FIELD_TYPE } from 'src/enums';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';

export const remindersColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
    isLink: true,
  },
  name: {
    displayedName: 'Successor Borrower',
  },
  northCarolinaCertificateOfAuthorityApplicationDate: {
    displayedName: 'NC COA Application Date',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.date,
    fieldKey: 'northCarolinaCertificateOfAuthorityApplicationDate',
  },
  northCarolinaCertificateOfAuthorityAcceptedDate: {
    displayedName: 'NC COA Accepted Date',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.date,
    fieldKey: 'northCarolinaCertificateOfAuthorityAcceptedDate',
  },
  northCarolinaSecretaryOfStateId: {
    displayedName: 'NC SOS Id',
    editable: true,
    editableFieldType: FORM_FIELD_TYPE.input,
    fieldKey: 'northCarolinaSecretaryOfStateId',
  },
  sosAcceptedDate: {
    displayedName: 'DE SOS DATE',
  },
  ein: {
    displayedName: 'Ein',
  },
  einDateFiledOn: {
    displayedName: 'Ein Filed Date',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    name: PropTypes.string,
    ein: PropTypes.string,
    einDateFiledOn: PropTypes.date,
    sosAcceptedDate: PropTypes.date,
    northCarolinaSecretaryOfStateId: PropTypes.string,
    northCarolinaCertificateOfAuthorityApplicationDate: PropTypes.date,
    northCarolinaCertificateOfAuthorityAcceptedDate: PropTypes.date,
  },
  {
    tableKeys() {
      return Object.keys(remindersColumnConfig);
    },
    tableData(list) {
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          einDateFiledOn: formatDate(rowData.einDateFiledOn),
          sosAcceptedDate: formatDate(rowData.sosAcceptedDate),
        },
      }));
    },
  },
);
