import React, { useMemo, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import { Input, MenuItem, Select } from '@material-ui/core';
import { isEmpty, equals, isNil } from 'ramda';
import { isValid } from 'date-fns';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

import FormError from 'components/FormError';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import { useErrors } from 'hooks/useErrors';
import { useSnackbar } from 'hooks/useSnackbar';

import { formatDate, transformDateForRequest } from 'utils/date';
import { serializeOptions } from 'utils/getSelectOptions';
import { defineFieldType } from 'utils/formFieldType';

import useStyles from './useStyles';

const SAVED = 'Saved';

const EditableCell = props => {
  const { editableFieldType, rowId, value: initialValue, fieldKey, onEdit, options } = props;

  const classes = useStyles();

  const { showSuccessNotification } = useSnackbar();

  const { formatErrors, displayErrorsInToast, getFieldErrorMessage } = useErrors();

  const inputMaskRef = useRef();

  const serializedOptions = useMemo(() => serializeOptions(options), [options]);

  const [defaultCellValue, setDefaultCellValue] = useState(initialValue);

  const [decimalValue, setDecimalValue] = useState(initialValue);

  const [fieldErrors, setFieldErrors] = useState(null);

  const handleEdit = newValue => onEdit(rowId, fieldKey, newValue);

  const isEqualsWithDefaultValue = newValue => equals(defaultCellValue, newValue);

  const handleChange = async valueForUpload => {
    setFieldErrors(null);
    try {
      await handleEdit(valueForUpload);
      showSuccessNotification(SAVED);
      setDefaultCellValue(valueForUpload);
      setFieldErrors(null);
    } catch (error) {
      setDefaultCellValue(initialValue);
      const {
        fieldErrors: currentFieldErrors,
        nonFieldErrors,
        backendServicesError,
      } = formatErrors(error);
      displayErrorsInToast([nonFieldErrors, backendServicesError]);
      if (!isEmpty(currentFieldErrors)) setFieldErrors(currentFieldErrors);
    }
  };

  const handleInputBlur = event => {
    const { value } = event.target;
    const valueForUpload = isEmpty(value) ? null : value;
    if (isEqualsWithDefaultValue(valueForUpload)) return;
    handleChange(valueForUpload);
  };

  const handleSelectChange = event => handleChange(event.target.value);

  const handleDateFieldBlur = event => {
    const { value } = event.target;
    const valueForUpload = transformDateForRequest(value);

    if (isEqualsWithDefaultValue(valueForUpload)) return;

    if (isEmpty(value)) {
      handleChange(null);
      return;
    }
    if (!isValid(new Date(value))) {
      setFieldErrors({ [fieldKey]: [ERROR_MESSAGES.date.wrongFormat] });
      return;
    }
    handleChange(valueForUpload);
  };

  const handleDecimalInputBlur = () => {
    const valueForUpload = isNil(decimalValue) ? null : decimalValue;
    if (isEqualsWithDefaultValue(valueForUpload)) return;
    handleChange(valueForUpload);
  };

  const handleDecimalValueChange = value => setDecimalValue(value.floatValue);

  return (
    <>
      {defineFieldType(editableFieldType).isInput && (
        <>
          <Input
            className={classes.formInput}
            defaultValue={defaultCellValue}
            name={fieldKey}
            onBlur={handleInputBlur}
          />
          {fieldErrors && (
            <FormError
              message={getFieldErrorMessage(fieldKey, fieldErrors)}
              className={classes.fieldError}
            />
          )}
        </>
      )}
      {defineFieldType(editableFieldType).isDecimal && (
        <>
          <NumberFormat
            name={fieldKey}
            value={decimalValue}
            onValueChange={handleDecimalValueChange}
            onBlur={handleDecimalInputBlur}
            decimalScale={2}
            type="text"
            thousandSeparator=","
            customInput={Input}
            autoComplete="off"
            className={classes.formInput}
          />
          {fieldErrors && (
            <FormError
              message={getFieldErrorMessage(fieldKey, fieldErrors)}
              className={classes.fieldError}
            />
          )}
        </>
      )}
      {defineFieldType(editableFieldType).isDate && (
        <>
          <InputMask
            id={`${fieldKey}-${rowId}`}
            className={classes.formInput}
            name={fieldKey}
            defaultValue={formatDate(defaultCellValue)}
            mask="99/99/9999"
            permanents={DateConst.PERMANENTS}
            alwaysShowMask={false}
            onBlur={handleDateFieldBlur}
          >
            {inputProps => <Input {...inputProps} inputRef={inputMaskRef} />}
          </InputMask>
          {fieldErrors && (
            <FormError
              message={getFieldErrorMessage(fieldKey, fieldErrors)}
              className={classes.fieldError}
            />
          )}
        </>
      )}
      {defineFieldType(editableFieldType).isSelect && (
        <>
          <Select
            className={classes.select}
            variant="outlined"
            name={fieldKey}
            onChange={handleSelectChange}
            value={defaultCellValue}
          >
            {serializedOptions?.map(option => (
              <MenuItem
                className={classes.menuItem}
                key={`${fieldKey}_${option.value}`}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {fieldErrors && (
            <FormError
              message={getFieldErrorMessage(fieldKey, fieldErrors)}
              className={classes.fieldError}
            />
          )}
        </>
      )}
    </>
  );
};

EditableCell.propTypes = {
  editableFieldType: PropTypes.string,
  rowId: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  fieldKey: PropTypes.string,
  onEdit: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
      label: PropTypes.string,
    }),
  ),
};

export default EditableCell;
