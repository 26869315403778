import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    toolsContainer: {
      marginLeft: 365,
    },
    toolsList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    toolsItem: {
      margin: 0,
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    iconWrapper: {
      padding: [0, 8],
      display: 'flex',
    },
    toolsButton: {
      justifyContent: 'flex-start',
    },
  }),
  { name: 'Tools' },
);
