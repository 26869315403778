import { ReportStatusConst } from 'const';

const useTaskStatus = status => {
  const isStarted = status === ReportStatusConst.STARTED;
  const isSuccess = status === ReportStatusConst.SUCCESS;
  const isFailure = status === ReportStatusConst.FAILURE;

  return {
    isStarted,
    isFailure,
    isSuccess,
    isFinished: isFailure || isSuccess,
  };
};

export { ReportStatusConst, useTaskStatus };
