import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useFormContext, useController } from 'react-hook-form';

import FormError from 'components/FormError';

import useStyles from './useStyles';

export const PasswordInput = props => {
  const { name, label, className } = props;
  const classes = useStyles();
  const [isPasswordShows, setPasswordShows] = useState(false);
  const { control, errors } = useFormContext();
  const {
    field: { ...inputProps },
  } = useController({
    control,
    name,
  });

  const handleClickShowPassword = () => {
    setPasswordShows(!isPasswordShows);
  };

  return (
    <div className={className}>
      <TextField
        type={isPasswordShows ? 'text' : 'password'}
        autoComplete="off"
        label={label}
        name="name"
        className={classes.input}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.icon}
                aria-label="toggle password"
                onMouseDown={e => e.preventDefault()}
                onClick={handleClickShowPassword}
              >
                {isPasswordShows ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        {...inputProps}
      />
      <ErrorMessage
        id={name}
        errors={errors}
        name={name}
        render={({ message }) => <FormError message={message} />}
      />
    </div>
  );
};

PasswordInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};
