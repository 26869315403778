import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

const FormError = props => {
  const { message, className } = props;
  const classes = useStyles();

  return <p className={clsx(classes.error, className)}>{message}</p>;
};

FormError.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default FormError;
