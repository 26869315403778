import React from 'react';

import PropTypes from 'prop-types';
import { TableRow, TableCell } from '@material-ui/core/';
import useStyles from 'components/LoanEditableTable/useStyles';

import { FORM_FIELD_TYPE } from 'enums';

import DatePickerCell from './components/DatePickerCell';
import DecimalInputCell from './components/DecimalInputCell';

export const FormRow = props => {
  const { formFields } = props;
  const classes = useStyles();

  const mapping = {
    [FORM_FIELD_TYPE.date]: (name, { placeholder, label }) => (
      <DatePickerCell
        name={name}
        className={classes.dateInput}
        childrenProps={{
          views: ['date', 'month', 'year'],
          placeholder,
          label,
        }}
      />
    ),
    [FORM_FIELD_TYPE.decimal]: (name, properties) => (
      <DecimalInputCell
        name={name}
        childrenProps={{
          ...properties,
        }}
      />
    ),
  };

  return (
    <TableRow>
      {formFields.map((field, index) => {
        const { name, type, placeholder, label, decimalScale } = field;
        return (
          <TableCell key={`form-field-${index}`} className={classes.inputCell}>
            {mapping[type](name, { placeholder, label, decimalScale }, index)}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

FormRow.propTypes = {
  formFields: PropTypes.arrayOf(PropTypes.shape({})),
};
