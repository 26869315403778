export const getAddressString = addressObj => {
  if (!addressObj) {
    return null;
  }

  let resultAddressString = '';
  const keys = ['street', 'city', 'state', 'postalCode'];
  keys.forEach(key => {
    if (addressObj[key]) {
      resultAddressString += resultAddressString ? `, ${addressObj[key]}` : addressObj[key];
    }
  });

  return resultAddressString;
};

export const transformNumberNullableValue = (value, originalValue) =>
  originalValue === '' ? null : value;
