import React from 'react';

import PropTypes from 'prop-types';
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

import useStyles from './useStyles';

const BarChart = ({ title, datasets = [], labels = [] }) => {
  const classes = useStyles();
  const options = {
    plugins: {
      legend: {
        align: 'end',
      },
      title: {
        display: true,
        text: title,
      },
    },
  };
  const data = {
    labels,
    datasets,
  };
  return (
    <div className={classes.wrapper}>
      <Bar className={classes.chartBar} data={data} options={options} height={100} />
    </div>
  );
};

BarChart.propTypes = {
  title: PropTypes.string,
  datasets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      data: PropTypes.arrayOf(PropTypes.number),
      backgroundColor: PropTypes.string,
    }),
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
};

export default BarChart;
