import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';

import { useGetAdministrativeAgentsQuery } from 'domain/administrativeAgent/apiSlice';
import { AdministrativeAgentsService } from 'domain/administrativeAgent/service';

import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { administrativeAgentsColumnConfig } from 'presenters/AdministrativeAgentIndexPresenter';

import { appRoutes } from 'router/routes';

import { getStaffLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Administrative Agents';

export const AdministrativeAgents = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const {
    data: administrativeAgentsData,
    isFetching: isAdministrativeAgentsLoading,
    error: administrativeAgentsLoadError,
  } = useGetAdministrativeAgentsQuery();

  const administrativeAgentsTableData =
    AdministrativeAgentsService.getTableData(administrativeAgentsData);
  const administrativeAgentsTableKeys = AdministrativeAgentsService.getTableKeys();

  useEffect(() => {
    if (administrativeAgentsLoadError) displayErrorsInToast([administrativeAgentsLoadError]);
  }, [administrativeAgentsLoadError]);

  const sideBarContent = getStaffLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout>
        <DocumentTitle title={TITLE} />
        <Typography variant="h1">{TITLE}</Typography>
        <div className={classes.actionPanel}>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.staffAdministrativeAgentCreatePath()}
              className={classes.addItem}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Administrative Agent
            </Button>
          </ActionPanelButtonWrapper>
        </div>
        <ListTable
          detailPath={appRoutes.staffAdministrativeAgentPath}
          isLoading={isAdministrativeAgentsLoading}
          tableHeader={administrativeAgentsTableKeys}
          tableData={administrativeAgentsTableData}
          columnConfig={administrativeAgentsColumnConfig}
          isInnerScrollable
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
