import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ToolsRepository from 'repositories/ToolsRepository';

import Popup from 'components/Popup';
import FormField from 'components/FormField';

import { useOrganizations } from 'hooks/api';
import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';
import { useReportTask } from 'hooks/api/useReportTask';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const SharingArrangementDefeasedLoansPopup = ({ setPopupState }) => {
  const classes = useStyles();
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const { formatErrors, displayErrorsInToast, setErrorsToForm } = useErrors();
  const { showSuccessNotification } = useSnackbar();

  const { initiateTaskReportLoading, isTaskReportLoading, resetReportTask } = useReportTask();
  const methods = useForm({
    resolver,
  });

  const organizationParams = { sharingArrangementFinancialOrganizationOnly: 1 };

  const { loadOrganizations, organizationOptions } = useOrganizations();

  const fetchData = async () => {
    try {
      await loadOrganizations(organizationParams);
      setSubmitButtonDisabled(false);
    } catch (e) {
      setSubmitButtonDisabled(true);
      const { backendServicesError } = formatErrors(e);
      displayErrorsInToast([backendServicesError]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => () => resetReportTask(), []);

  const handleImportClose = () => {
    setPopupState(null);
  };

  const handleImportSubmit = async () => {
    const { organizationId } = methods.getValues();
    const callback = ToolsRepository.sharingArrangementDefeasedLoansReport;
    const toastText = 'Sharing Arrangement Defeased Loans Report Successfully Generated';
    try {
      window.location.href = await initiateTaskReportLoading(callback, organizationId);
      showSuccessNotification(toastText);
    } catch (e) {
      const { nonFieldErrors, backendServicesError, fieldErrors } = formatErrors(e);
      displayErrorsInToast([nonFieldErrors, backendServicesError]);
      setErrorsToForm(methods.setError, methods.errors, fieldErrors);
    }
    handleImportClose();
  };

  const selectValues = {
    organizations: organizationOptions,
  };

  return (
    <Popup
      title="Sharing Arrangement Defeased Loans Report"
      submitButtonText="Generate"
      toggleState={setPopupState}
      onSubmit={handleImportSubmit}
      onClose={handleImportClose}
      isLoading={isTaskReportLoading}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    >
      <FormProvider {...methods}>
        <form className={classes.contentForm}>
          <FormField fieldArray={fieldArray} errors={methods.errors} selectValues={selectValues} />
        </form>
      </FormProvider>
    </Popup>
  );
};

SharingArrangementDefeasedLoansPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default SharingArrangementDefeasedLoansPopup;
