import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isValid, format as formatDate } from 'date-fns';

import { ERROR_MESSAGES, FORM_FIELD_TYPE } from 'enums';

import { parseDate, today } from 'utils/date';
import { preventEmptyString } from 'utils/yupHelpers';
import { preventNullProps } from 'utils/forms';
import { replaceEmptyStringsToNull } from 'utils/forms';
import { toCurrencyNumber, convertPercentToCoefficient, toPercentNumber } from 'utils/numbers';

export const resolver = yupResolver(
  Yup.object({
    paymentDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.invalidDate)
      .nullable()
      .required(ERROR_MESSAGES.required),
    principalBalance: Yup.number().transform(preventEmptyString).nullable(),
    principalPaid: Yup.number().transform(preventEmptyString).nullable(),
    interestRate: Yup.number().transform(preventEmptyString).nullable(),
    interest: Yup.number().transform(preventEmptyString).nullable(),
    totalLoanPayments: Yup.number().transform(preventEmptyString).nullable(),
  }),
);

export const defaultValues = {
  paymentDate: today(),
  principalBalance: '',
  principalPaid: '',
  interestRate: '',
  interest: '',
  totalLoanPayments: '',
};

export const formFields = [
  { name: 'paymentDate', type: FORM_FIELD_TYPE.date },
  { name: 'principalBalance', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'principalPaid', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'interestRate', type: FORM_FIELD_TYPE.decimal, placeholder: '%', decimalScale: 3 },
  { name: 'interest', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
  { name: 'totalLoanPayments', type: FORM_FIELD_TYPE.decimal, placeholder: '$', decimalScale: 2 },
];

export const prepareBeforeSending = data => {
  const { paymentDate } = data;
  return replaceEmptyStringsToNull({
    ...data,
    interestRate: convertPercentToCoefficient(data?.interestRate, 3),
    paymentDate: isValid(paymentDate) ? formatDate(paymentDate, 'yyyy-MM-dd') : null,
  });
};

export const prepareBeforeSetting = data => {
  const { paymentDate } = data;
  return {
    ...preventNullProps({
      ...data,
      principalBalance: toCurrencyNumber(data?.principalBalance),
      principalPaid: toCurrencyNumber(data?.principalPaid),
      interestRate: toPercentNumber(data?.interestRate, 3),
      interest: toCurrencyNumber(data?.interest),
      totalLoanPayments: toCurrencyNumber(data?.totalLoanPayments),
    }),
    paymentDate: parseDate('yyyy-MM-dd')(paymentDate),
  };
};
