import OutstandingBindersPresenter from 'presenters/OutstandingBindersPresenter';

import { outstandingBindersRestHooks } from 'store/OutstandingBindersSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useOutstandingBinders = () => {
  const { outstandingBinders, loadOutstandingBinders, updateOutstandingBinder } =
    outstandingBindersRestHooks.use();
  const outstandingBindersTableData = OutstandingBindersPresenter.tableData(
    outstandingBinders?.items,
  );

  const { isPending, isFinished } = useFetchStatus(outstandingBinders.loadingStatus);

  return {
    outstandingBinders,
    loadOutstandingBinders,
    updateOutstandingBinder,
    outstandingBindersTableData,
    isOutstandingBindersLoading: isPending,
    isOutstandingBindersLoadFinished: isFinished,
  };
};
