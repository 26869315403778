import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    size: {
      minHeight: '100%',
      minWidth: 'fit-content',
    },
    backgroundStatusLoanActive: {
      backgroundColor: theme.palette.feta,
    },
    backgroundStatusLoanInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    backgrounStatusLoandActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    backgroundStatusSbActive: {
      backgroundColor: theme.palette.feta,
    },
    backgroundStatusSbInactive: {
      backgroundColor: theme.palette.fairPink,
    },
  }),
  { name: 'BackgroundFillByStatus' },
);
