import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import { transformDate } from 'utils/date';

export const resolver = yupResolver(
  Yup.object({
    targetAssumedDate: Yup.string()
      .transform(transformDate)
      .required('Required')
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat)
      .nullable(),
    assumedDate: Yup.string()
      .transform(transformDate)
      .required('Required')
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat)
      .nullable(),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'targetAssumedDate',
    displayedName: 'All Loans with assumed date of',
    type: FORM_FIELD_TYPE.date,
    placeholder: 'Source date',
    isFullRow: true,
  },
  {
    fieldKey: 'assumedDate',
    displayedName: 'Will be changed to this date',
    type: FORM_FIELD_TYPE.date,
    placeholder: 'Destination date',
    isFullRow: true,
  },
];
