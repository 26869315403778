import palette, { COLORS } from '../palette';

export default {
  MuiButton: {
    root: {
      fontSize: 14,
      lineHeight: '20px',
      fontStyle: 'normal',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      textTransform: 'capitalize',
      padding: [6, 12],
      transition:
        'color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    contained: {
      borderRadius: 3,
      height: 32,
      color: palette.primary.main,
      backgroundColor: COLORS.porcelain,
      '&:hover': {
        backgroundColor: COLORS.alto,
      },
    },
    containedSecondary: {
      background: palette.secondary.main,
      borderRadius: 3,
      height: 32,
      color: COLORS.white,
      overflow: 'hidden',
    },
    textSecondary: {
      color: palette.secondary.main,
    },
    textPrimary: {
      color: palette.primary.main,
    },
    startIcon: {
      marginLeft: 0,
    },
  },
  MuiIconButton: {
    root: {
      padding: 0,
    },
  },
};
