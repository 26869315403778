import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag, invalidateOnSuccess } from 'utils/rtkQuery';

export const loanDefeasedPaymentApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoanDefeasedPayments: builder.query({
      query: loanId => ({
        url: apiRoutes.loansDefeasedPaymentsPath(loanId),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.loanDefeasedPayment),
    }),
    getLoanDefeasedPaymentsSummary: builder.query({
      query: loanId => ({
        url: apiRoutes.loansDefeasedPaymentsSummaryPath(loanId),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.loanDefeasedPaymentSummary],
    }),
    updateLoanDefeasedPayment: builder.mutation({
      query: ({ loanId, defeasedPaymentId, data }) => ({
        url: apiRoutes.loansDefeasedPaymentPath(loanId, defeasedPaymentId),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOnSuccess([
        { type: CACHE_TAG.loanDefeasedPayment, id: 'LIST' },
        CACHE_TAG.loanDefeasedPaymentSummary,
      ]),
    }),
  }),
});

export const {
  useGetLoanDefeasedPaymentsQuery,
  useGetLoanDefeasedPaymentsSummaryQuery,
  useUpdateLoanDefeasedPaymentMutation,
} = loanDefeasedPaymentApi;
