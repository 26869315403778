import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    formWrap: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: [0, 32],
    },
  }),
  { name: 'MoodyReportPopup' },
);
