import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentWrap: {
      paddingTop: 0,
    },
    actionPanel: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: theme.sizes.actionPanel.marginBottom,
    },
    table: {
      '& thead th:nth-child(3)': {
        minWidth: '140px',
      },
    },
  }),
  { name: 'Pools' },
);
