import {
  format,
  add,
  getDay,
  isSameDay,
  isAfter,
  isBefore,
  isWeekend,
  endOfMonth,
  sub,
  min,
  parse,
  isWithinInterval,
  startOfDay,
} from 'date-fns';
import { endOfWeekWithOptions } from 'date-fns/fp';
import { is, isEmpty, isNil, curry } from 'ramda';

import { DateConst } from 'const';

const getTimeUtc = date =>
  new Date(date.getTime() + date.getTimezoneOffset() * DateConst.HOUR_MS_OFFSET);

const isEmptyString = value => is(String, value) && isEmpty(value);

const formatDateString = (dateString, formatString, needUtcTransform = true) => {
  if (!dateString) return null;
  const date = needUtcTransform ? getTimeUtc(new Date(dateString)) : new Date(dateString);
  if (Number.isNaN(date.getTime())) {
    return 'invalid date';
  }

  return format(date, formatString);
};

export const formatDate = dateString => formatDateString(dateString, `${DateConst.DATE_FORMAT}`);

export const formatDateTime = dateString =>
  formatDateString(dateString, `${DateConst.DATE_FORMAT} ${DateConst.TIME_FORMAT}`);
export const isTodayDate = date => isSameDay(date, new Date());

export const isTomorrowDate = date => isSameDay(date, add(new Date(), { days: 1 }));

export const isValidTomorrowWorkday = date => {
  const tomorrowDate = add(new Date(), { days: 1 });
  if (isWeekend(tomorrowDate)) {
    return false;
  }
  return isTomorrowDate(date);
};

export const isBeforeYesterdayDate = date => {
  const yesterdayDate = sub(new Date(), { days: 1 });
  return isBefore(new Date(date), yesterdayDate);
};

export const isBeforeTodayDate = date => {
  if (isNil(date)) {
    return false;
  }
  const todayDate = new Date();
  return isBefore(new Date(date), todayDate);
};

export const isBeforeTomorrowDate = date => {
  const tomorrowDate = add(new Date(), { days: 1 });
  return isBefore(new Date(date), tomorrowDate);
};

export const isDayAfterTomorrowDate = date => isSameDay(date, add(new Date(), { days: 2 }));

export const isValidWorkdayAfterTomorrow = date => {
  const dayAfterTomorrowDate = add(new Date(), { days: 2 });
  if (isWeekend(dayAfterTomorrowDate)) {
    return false;
  }
  return isDayAfterTomorrowDate(date);
};

export const isRestOfThisWeekDate = date => {
  const todayDay = getDay(new Date());
  if ([6, 0].includes(todayDay)) {
    return false;
  }
  const dayAfterTomorrowDate = add(new Date(), { days: 2 });
  const endOfTheWeekDate = endOfWeekWithOptions({ weekStartsOn: 1 }, new Date());
  const fridayDate = add(endOfTheWeekDate, { days: -2 });
  const startIntervalDate = min([dayAfterTomorrowDate, fridayDate]);
  return isWithinInterval(date, { start: startIntervalDate, end: endOfTheWeekDate });
};

export const isSinceNextWeekDate = date => {
  const endOfWeekDate = endOfWeekWithOptions({ weekStartsOn: 1 }, new Date());
  return isAfter(date, endOfWeekDate);
};

export const monthSelectValues = [3, 6, 12, 18, 24, 36];
export const defaultMonthSelectValue = 12;

export const getEntityLastUpdated = entity =>
  entity?.updatedAt && entity?.updatedBy
    ? `${formatDateTime(entity.updatedAt)} by ${entity.updatedBy.username}`
    : '';

export const transformDate = (value, originalValue) =>
  formatDateString(originalValue, DateConst.TRANSFORM_DATE_FORMAT, false);

export const transformDateForRequest = (value, needUtcTransform = false) =>
  formatDateString(value, DateConst.TRANSFORM_DATE_FORMAT, needUtcTransform);

export const formatDateFromTo = (value, fromPattern, toPattern) => {
  if (isNil(value)) return value;
  return format(parse(value, fromPattern, new Date()), toPattern);
};

export const parseDate = curry((pattern, date) => {
  if (isNil(date)) return null;
  return parse(date, pattern, new Date());
});

export const transformToNull = value => {
  if (isEmptyString(value)) return null;
  return value;
};

export const getAssumedDate = dealDate => {
  const date = new Date(dealDate);
  const userTimezoneOffset = date.getTimezoneOffset() * 60000;
  const dateWithoutOffset = new Date(date.getTime() + userTimezoneOffset);
  return dateWithoutOffset;
};

export const getLastDayOfPreviousMonth = () => {
  const today = new Date();
  const prevMonth = sub(today, { months: 1 });
  return endOfMonth(prevMonth);
};

export const formatDateToMonthYear = date =>
  isNil(date) ? null : `${format(date, 'yyyy')}-${format(date, 'L')}`;

export const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear();
};

export const formatDateToYearMonth = date => `${format(date, 'yyyy')}-${format(date, 'L')}`;

export const getCurrentYearMonth = () => {
  const today = new Date();
  return format(today, 'yyyy-MM');
};

export const today = () => new Date();

export const isDateBefore = (date1, date2, params = { shouldResetTime: false }) =>
  params.shouldResetTime ? isBefore(startOfDay(date1), startOfDay(date2)) : isBefore(date1, date2);
