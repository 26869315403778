import AdministrativeAgentIndexPresenter from 'presenters/AdministrativeAgentIndexPresenter';
import AdministrativeAgentShowPresenter, {
  administrativeAgentColumnConfig,
} from 'presenters/AdministrativeAgentShowPresenter';

import { administrativeAgentsRestHooks } from 'store/AdministrativeAgentsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getEntityLastUpdated } from 'utils/date';

export const useAdministrativeAgents = () => {
  const {
    administrativeAgents,
    loadAdministrativeAgents,
    administrativeAgent,
    loadAdministrativeAgent,
    updateAdministrativeAgent,
    createAdministrativeAgent,
    deleteAdministrativeAgent,
  } = administrativeAgentsRestHooks.use();
  const administrativeAgentOptions = getSelectOptions(
    administrativeAgents.items,
    'id',
    AdministrativeAgentIndexPresenter.name,
  );
  const administrativeAgentsTableData = AdministrativeAgentIndexPresenter.tableData(
    administrativeAgents.items,
  );
  const administrativeAgentsTableKeys = AdministrativeAgentIndexPresenter.tableKeys();

  const administrativeAgentTableData = AdministrativeAgentShowPresenter.tableData(
    administrativeAgent.item,
  );
  const administrativeAgentLastUpdated = getEntityLastUpdated(administrativeAgent?.item);

  return {
    administrativeAgents,
    loadAdministrativeAgents,
    administrativeAgentsTableData,
    administrativeAgentsTableKeys,
    areAdministrativeAgentsLoading: useFetchStatus(administrativeAgents.loadingStatus).isPending,
    administrativeAgentOptions,

    administrativeAgent: administrativeAgent?.item,
    loadAdministrativeAgent,
    administrativeAgentTableData,
    updateAdministrativeAgent,
    createAdministrativeAgent,
    deleteAdministrativeAgent,
    isAdministrativeAgentLoading: useFetchStatus(administrativeAgent.loadingStatus).isPending,
    administrativeAgentErrors: administrativeAgent?.errors,
    administrativeAgentColumnConfig,
    administrativeAgentLastUpdated,
  };
};
