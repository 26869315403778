import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async OIDBalanceReportTask(params = {}) {
    const url = apiRoutes.OIDBalanceDiscrepanciesTasksPath();
    return FetchHelpers.post(url, params);
  },

  async OIDBalanceLoanDetails(params) {
    const url = apiRoutes.OIDBalanceDiscrepanciePath(params.id);
    return FetchHelpers.get(url);
  },

  async OIDBalanceSuccessorBorrowerDetails(id, params) {
    const url = apiRoutes.OIDBalanceDiscrepanciesSuccessorBorrowersPath(id);
    return FetchHelpers.get(url, params);
  },
  async OIDBalanceReportClearCachePath() {
    const url = apiRoutes.OIDBalanceReportCachePath();
    return FetchHelpers.delete(url);
  },
  async OIDBalanceReportTasksModel(id, params) {
    const url = apiRoutes.OIDBalanceReportTasksPath(id);
    return FetchHelpers.postLocation(url, params);
  },
  async OIDBalanceMemberDetailsTasks(params) {
    const url = apiRoutes.OIDBalanceDiscrepanciesMembersTasksPath();
    return FetchHelpers.post(url, params);
  },
  async OIDBalanceMemberReportExcel(params) {
    const url = apiRoutes.reportsOidBalanceMemberReportPath();
    return FetchHelpers.postLocation(url, params);
  },
};
