import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IPerson, IPersonIndex, IPersonShow, IPersonFormData } from './types';

export const personApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getPersons: builder.query<Array<IPersonIndex>, void>({
      query: () => ({
        url: apiRoutes.personsPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.person),
    }),
    getPerson: builder.query<IPerson, number>({
      query: id => ({
        url: apiRoutes.personPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.person),
    }),
    updatePerson: builder.mutation<IPerson, IPersonShow>({
      query: ({ id, data }) => ({
        url: apiRoutes.personPath(id),
        method: 'PUT',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.person, id: 'LIST' }],
    }),
    createPerson: builder.mutation<IPerson, IPersonFormData>({
      query: data => ({
        url: apiRoutes.personsPath(),
        method: 'POST',
        data,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.person, id: 'LIST' }],
    }),
  }),
});

export const {
  useGetPersonsQuery,
  useGetPersonQuery,
  useUpdatePersonMutation,
  useCreatePersonMutation,
} = personApi;
