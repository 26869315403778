import { FETCH_STATUSES } from 'enums';

const useFetchStatus = status => {
  const isIdle = status === FETCH_STATUSES.idle;
  const isPending = status === FETCH_STATUSES.pending;
  const isFulfilled = status === FETCH_STATUSES.fulfilled;
  const isFailed = status === FETCH_STATUSES.failed;

  return {
    isIdle,
    isPending,
    isFulfilled,
    isFailed,
    isFinished: isFulfilled || isFailed,
  };
};

export { FETCH_STATUSES, useFetchStatus };
