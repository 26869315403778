import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

export const configurationDealApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getConfigurationDeal: builder.query({
      query: () => ({
        url: apiRoutes.dealConfigurationPath(),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.configurationDeal],
    }),
  }),
});

export const { useGetConfigurationDealQuery } = configurationDealApi;
