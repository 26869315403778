import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';

import { isStatusActive, isStatusInactive, isStatusActivePaid } from 'utils/layoutStatus';

import useStyles from './useStyles';

export const LoanHeader = forwardRef((props, ref) => {
  const { children, title, loanStatus } = props;
  const classes = useStyles();

  return (
    <div className={classes.container} ref={ref}>
      <div
        className={clsx(classes.content, {
          [classes.panelActive]: isStatusActive(loanStatus),
          [classes.panelInactive]: isStatusInactive(loanStatus),
          [classes.panelActivePaid]: isStatusActivePaid(loanStatus),
        })}
      >
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        {children}
      </div>
    </div>
  );
});

LoanHeader.propTypes = {
  title: PropTypes.string,
  loanStatus: PropTypes.string,
};
