import React, { useRef, useState, useMemo } from 'react';

import Portal from '@material-ui/core/Portal';

import Header from 'components/Header';
import PaginationPanel from 'components/PaginationPanel';
import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import LoansSideNav from 'components/LoansSideNav';

import { ListTableConst } from 'const';

import { useGetLoansCountsByStatusPostQuery } from 'domain/loansCountsByStatus/apiSlice';
import { useGetLoansPostQuery } from 'domain/loan/apiSlice';
import { LoansService } from 'domain/loan/service';
import { useGetPersonChoicesQuery } from 'domain/personChoice/apiSlice';
import * as PersonChoicesService from 'domain/personChoice/service';
import { useGetOrganizationsQuery } from 'domain/organization/apiSlice';
import { useGetOptionsQuery } from 'domain/options/apiSlice';

import { usePaginationAndOrderingContext } from 'hooks/usePaginationAndOrdering';
import { useLoansStatus } from 'hooks/useLoansStatus';
import { useLoansFilters } from 'hooks/useLoansFilters';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { makeOrderingParam } from 'utils/pagination';
import { getOrganizationTypeValues } from 'utils/getOrganizationsType';
import { getOptionsQueryParams } from 'utils/rtkQuery';

import ActionPanel from './components/ActionPanel';
import useStyles from './useStyles';

const TITLE = 'Loans';
const COUNT_ITEMS_IS_ZERO = 0;

export const Loans = () => {
  const classes = useStyles();

  const header = useRef();

  const [sideBarContainer, setRefSideBarContainer] = useState(null);

  const [isFiltersShown, setIsFiltersShown] = useState(false);

  const { page, ordering, pageSize } = usePaginationAndOrderingContext();

  const { loansStatus } = useLoansStatus();

  const { setLoansFilterParams, loansFilterParams } = useLoansFilters();

  const { columnConfig } = LoansService;

  const { data: loansCountsByStatus } = useGetLoansCountsByStatusPostQuery(loansFilterParams);

  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());

  const {
    data: personChoicesData,
    isFetching: isServicerCounselLoading,
    error: serviceCounselLoadError,
  } = useGetPersonChoicesQuery();
  const servicerCounselOptions = useMemo(
    () => PersonChoicesService.getSelectInputChoices(personChoicesData),
    [personChoicesData],
  );

  const {
    data: organizationsData,
    isFetching: isLoanServicerLoading,
    error: organizationsLoadError,
  } = useGetOrganizationsQuery({
    organizationType: getOrganizationTypeValues(options).financialServices,
  });
  const loanServicerOptions = useMemo(
    () => PersonChoicesService.getSelectInputChoices(organizationsData),
    [organizationsData],
  );

  const loansQueryParams = {
    page,
    pageSize,
    ordering: makeOrderingParam(ordering.sortedBy, ordering.sortingDirection),
    ...loansFilterParams,
    status: loansStatus,
  };

  const {
    data: loansData,
    isFetching: isLoansLoading,
    isError: isLoansLoadingFailed,
    error: loansLoadError,
  } = useGetLoansPostQuery(loansQueryParams);
  const count = loansData?.count;
  const tableHeader = LoansService.getTableHeader();
  const tableData = useMemo(() => LoansService.getTableData(loansData?.results), [loansData]);

  useHandleRtkQueryErrors(loansLoadError, serviceCounselLoadError, organizationsLoadError);

  const handleLoadLoansWithFilter = data => {
    setLoansFilterParams(data, { shouldResetPage: true });
  };

  const handleFiltersClick = () => {
    setIsFiltersShown(!isFiltersShown);
  };

  return (
    <>
      <Portal container={sideBarContainer}>
        <LoansSideNav loansCountsByStatus={loansCountsByStatus} />
      </Portal>
      <RootLayoutV2 sideBarRef={setRefSideBarContainer} isPortal>
        <ContentLayout className={classes.contentWrap}>
          <DocumentTitle title={TITLE} />
          <Header title={TITLE} ref={header}>
            <ActionPanel
              servicerCounselOptions={servicerCounselOptions}
              isServicerCounselLoading={isServicerCounselLoading}
              loanServicerOptions={loanServicerOptions}
              isLoanServicerLoading={isLoanServicerLoading}
              onSubmit={handleLoadLoansWithFilter}
              onClick={handleFiltersClick}
            />
          </Header>
          <ListTable
            detailPath={appRoutes.loanPath}
            isLoading={isLoansLoading}
            tableHeader={tableHeader}
            tableData={tableData}
            columnConfig={columnConfig}
            isPaginationEnabled
            isOuterScrollable
            fixedTopOffset={
              isFiltersShown
                ? ListTableConst.LOANS_HEADER_WITH_FILTERS_OFFSET
                : ListTableConst.LOANS_HEADER_WITHOUT_FILTERS_OFFSET
            }
          />
          <PaginationPanel itemsCount={isLoansLoadingFailed ? COUNT_ITEMS_IS_ZERO : count} />
        </ContentLayout>
      </RootLayoutV2>
    </>
  );
};
