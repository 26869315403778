export default {
  CIBCReportPath: () => '/api/v1/reports/cibc-report.xlsx/',
  OIDBalanceDiscrepanciePath: loanId => `/api/v1/oid-balance-discrepancies/${loanId}/`,
  OIDBalanceDiscrepanciesMembersTasksPath: () => '/api/v1/oid-balance-discrepancies-members-tasks/',
  OIDBalanceDiscrepanciesSuccessorBorrowersPath: id =>
    `/api/v1/oid-balance-discrepancies/successor-borrowers/${id}/`,
  OIDBalanceDiscrepanciesTasksPath: () => '/api/v1/oid-balance-discrepancies-tasks/',
  OIDBalanceReportCachePath: () => '/api/v1/oid-balance-report-cache/',
  OIDBalanceReportTasksPath: id => `/api/v1/loans/${id}/reports/excel-generation-oid/`,
  assumedDealsLiveFormPath: () => '/api/v1/assumed-deals/',
  buyUpPendingsPath: () => '/api/v1/buy-up-pending/',
  buyUpReportPath: () => '/api/v1/reports/buy-up-report.xlsx/',
  configurationBloombergPath: () => '/api/v1/configurations/bloomberg/',
  consolidatedMasterReportsPath: () => '/api/v1/reports/consolidated-master-report.xlsx/',
  currentUserPath: () => '/api/v1/users/current/',
  dealClosingChecklistReportPath: () => '/api/v1/reports/deal-closing-checklist-report.pdf/',
  dealConfigurationPath: () => '/api/v1/configurations/deal/',
  dealsInProgressReportPath: () => '/api/v1/reports/deals-in-progress-report.xlsx/',
  dealsSummaryPath: period => `/api/v1/deals-summary-form/${period}/`,
  dissolutionFutureProjectionsReportPath: () =>
    '/api/v1/reports/dissolution-future-projections-report/',
  excelExportPath: () => '/api/v1/excel-export/',
  excelImportPath: () => '/api/v1/excel-import/',
  excelImportReconciliationInputsTasksPath: () =>
    '/api/v1/excel-import-reconciliation-inputs-tasks/',
  excelImportTasksPath: () => '/api/v1/excel-import-tasks/',
  fafTokensPath: () => '/api/v1/faf-tokens/',
  freddieFloatPaymentsPath: () => '/api/v1/freddie-float-payments/',
  fundingNotePath: id => `/api/v1/funding-notes/${id}/`,
  fundingNotesPath: () => '/api/v1/funding-notes/',
  fundingNotesTotalPath: () => '/api/v1/funding-notes/total/',
  globalSnapshotPath: () => '/api/v1/global-snapshot/',
  helpFilePath: () => '/api/v1/help-file/',
  interestAccrualsReportPath: () => '/api/v1/reports/interest-accruals-report.xlsx/',
  interestAccrualsTasksPath: () => '/api/v1/interest-accruals-tasks/',
  loanChoicesPath: () => '/api/v1/loans-choices/',
  loanGovernmentSecuritiesPath: id => `/api/v1/loans/${id}/government-security/`,
  loanMarketDiscountPath: (loanId, marketDiscountId) =>
    `/api/v1/loans/${loanId}/market-discounts/${marketDiscountId}/`,
  loanPath: id => `/api/v1/loans/${id}/`,
  loanPrpNoticeDocForm: id => `/api/v1/loans/${id}/reports/prp-notice/`,
  loanReportPRPMergeChecklist: id => `/api/v1/loans/${id}/reports/prp-merge-checklist/`,
  loanReportPostClosingServicerAdminForm: id =>
    `/api/v1/loans/${id}/reports/post-closing-servicer-admin-form/`,
  loanReportSBExecutionTransmittalDocForm: id =>
    `/api/v1/loans/${id}/reports/sb-execution-transmittal-doc-form/`,
  loanReportSummaryForm: id => `/api/v1/loans/${id}/reports/summary-form-report.pdf/`,
  loansCountsByStatusPath: () => '/api/v1/loans-counts-by-status/',
  loansCountsByStatusQueryPath: () => '/api/v1/loans-counts-by-status/query/',
  loansDefeasedPaymentPath: (loanId, defeasedPaymentId) =>
    `/api/v1/loans/${loanId}/defeased-payments/${defeasedPaymentId}/`,
  loansDefeasedPaymentsPath: loanId => `/api/v1/loans/${loanId}/defeased-payments/`,
  loansDefeasedPaymentsSummaryPath: loanId => `/api/v1/loans/${loanId}/defeased-payments/summary/`,
  loansEscrowAccountPath: (loanId, escrowAccountId) =>
    `/api/v1/loans/${loanId}/escrow-accounts/${escrowAccountId}/`,
  loansEscrowAccountsPath: id => `/api/v1/loans/${id}/escrow-accounts/`,
  loansExtendedListPath: () => '/api/v1/extended-loans/',
  loansMarketDiscountsPath: loanId => `/api/v1/loans/${loanId}/market-discounts/`,
  loansMarketDiscountsSummaryPath: loanId => `/api/v1/loans/${loanId}/market-discounts/summary/`,
  loansPath: () => '/api/v1/loans/',
  loansQueryPath: () => '/api/v1/loans/query/',
  loansReconciliationInputsAvailableYearsPath: id =>
    `/api/v1/loans/${id}/reconciliation-inputs/available-years/`,
  loansReconciliationInputsSummaryPath: loanId =>
    `/api/v1/loans/${loanId}/reconciliation-inputs/summary/`,
  loginPath: () => '/api/v1/token/',
  maturityTrackingSheetPath: () => '/api/v1/reports/maturity-tracking-sheet-report.pdf/',
  missingReconciliationInputsPath: () => '/api/v1/missing-reconciliation-inputs/',
  moodyReportPath: () => '/api/v1/reports/moody-report.xlsx/',
  moodysLoanLookupPath: () => '/api/v1/loan/external-search/',
  netsuiteAccountReportPath: () => '/api/v1/reports/netsuite-account-tasks/',
  optionsPath: () => '/api/v1/options/',
  organizationsPath: () => '/api/v1/organizations/',
  originalLendersPath: () => '/api/v1/original-lenders/',
  outstandingAccountantPath: () => '/api/v1/outstanding-accountants/',
  outstandingBindersPath: () => '/api/v1/closing-binder-loans/',
  partiesOrganizationPath: id => `/api/v1/organizations/${id}/`,
  passwordResetConfirmPath: () => '/api/v1/password-reset/confirm/',
  passwordResetPath: () => '/api/v1/password-reset/',
  passwordResetValidateTokenPath: () => 'api/v1/password-reset/validate-token/',
  personChoicePath: id => `/api/v1/persons-choices/?employer_type=${id}`,
  personPath: id => `/api/v1/persons/${id}/`,
  personsChoicesPath: () => '/api/v1/persons-choices/',
  personsPath: () => '/api/v1/persons/',
  poolPath: id => `/api/v1/pools/${id}/`,
  poolsChoicesPath: () => '/api/v1/pools-choices/',
  poolsPath: () => '/api/v1/pools/',
  pricingModelReportPath: () => '/api/v1/reports/pricing-model-report.xlsx/',
  propertiesDataDumpPath: () => '/api/v1/properties-dump-tasks/',
  prpTrackingSheetPath: () => '/api/v1/reports/prp-tracking-sheet-report.pdf/',
  ratingAgencyReportPath: () => '/api/v1/reports/rating-agency-report.pdf/',
  recentlyClosedDealsPath: () => '/api/v1/recently-closed-deals/',
  reconciliationDiscrepanciesTasksPath: () => '/api/v1/reconciliation-discrepancies-tasks/',
  reconciliationDiscrepanciesReportCachePath: () =>
    '/api/v1/reconciliation-discrepancies-report-cache/',
  reconciliationInputPath: id => `/api/v1/reconciliation-inputs/${id}/`,
  reconciliationInputsPath: () => '/api/v1/reconciliation-inputs/',
  refreshPath: () => '/api/v1/token/refresh/',
  remindersPath: () => '/api/v1/reminders/',
  reportDataValidationTasksPath: () => '/api/v1/report-data-validation-tasks/',
  reportFundingRequestReportTasksPath: () => '/api/v1/reports/funding-request-report-tasks/',
  reportsFreddieFloatPaymentTasksPath: () => '/api/v1/reports/freddie-float-payment-tasks/',
  reportsJournalEntriesTasksPath: () => '/api/v1/reports/journal-entries-tasks/',
  reportsOidBalanceMemberReportPath: () => '/api/v1/reports/oid-balance-member-report.xlsx/',
  securitiesPercentageReportPath: () => '/api/v1/reports/securities-percentage-report.xlsx/',
  sharingArrangementDefeasedLoansReportPath: id =>
    `/api/v1/organizations/${id}/reports/sharing-arrangement-defeased-loans-report.xlsx/`,
  sideLetterReportPath: () => '/api/v1/reports/side-letter-report.xlsx/',
  staffAdministrativeAgentPath: id => `/api/v1/administrative-agents/${id}/`,
  staffAdministrativeAgentsPath: () => '/api/v1/administrative-agents/',
  staffManagerPath: id => `/api/v1/managers/${id}/`,
  staffManagersPath: () => '/api/v1/managers/',
  staffMemberPath: id => `/api/v1/members/${id}/`,
  staffMembersPath: () => '/api/v1/members/',
  successorBorrowerPath: id => `/api/v1/successor-borrowers/${id}/`,
  successorBorrowersChoicesPath: () => '/api/v1/successor-borrower-choices/',
  successorBorrowersConfigurationPath: () => '/api/v1/successor-borrower-configuration/',
  successorBorrowersPath: () => '/api/v1/successor-borrowers/',
  successorBorrowersRelatedEntitiesPath: id =>
    `/api/v1/successor-borrowers/${id}/related-entities/`,
  updatePasswordPath: () => '/api/v1/current-user/password/',
  usersPath: () => '/api/v1/users/',
};
