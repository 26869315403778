import React from 'react';

import PropTypes from 'prop-types';
import { ContactFieldLabels } from 'src/const';

import useStyles from './useStyles';

const CellServicer = ({ cellData }) => {
  const classes = useStyles();

  const contactOneFieldKeys = Object.keys(cellData?.contact1 || {});
  const contactTwoFieldKeys = Object.keys(cellData?.contact2 || {});

  const renderFieldsByKeys = (fieldKeys, data) => {
    fieldKeys.map(key => (
      <div className={classes.row}>
        <span className={classes.label}>{ContactFieldLabels[key]}</span>
        <span className={classes.value}>{data[key]}</span>
      </div>
    ));
  };

  return (
    <>
      {cellData?.title && <div className={classes.title}>{cellData?.title}</div>}
      {renderFieldsByKeys(contactOneFieldKeys, cellData?.contact1)}
      {renderFieldsByKeys(contactTwoFieldKeys, cellData?.contact2)}
    </>
  );
};

CellServicer.propTypes = {
  cellData: PropTypes.shape({
    title: PropTypes.string,
    contact1: PropTypes.shape({}),
    contact2: PropTypes.shape({}),
  }),
};

export default CellServicer;
