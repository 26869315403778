import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    sidebarTitle: {
      margin: 0,
      padding: [0, 24],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
  }),
  { name: 'SideBarLinks' },
);
