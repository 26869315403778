import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: theme.sizes.actionPanel.marginBottom,
    },
    actionPanelSelect: {
      marginLeft: 'auto',
    },
    search: {
      marginRight: 8,
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
    tableContent: {
      marginTop: 16,
    },
    monthSelect: {
      '&.MuiInputBase-root': {
        outline: 0,
        border: 0,
        padding: 0,
        backgroundColor: 'transparent',
        width: 100,
      },
      '& .MuiSelect-root': {
        marginRight: '28px',
        textAlign: 'end',
        outline: 0,
        border: 0,
        padding: 0,
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'HomeUpcomingMaturities' },
);
