/* eslint-disable no-param-reassign */
import humps from 'humps';
import { append, pluck } from 'ramda';

import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function bulkResourcesActionSlice(options) {
  const { resource, pluralResource, repository } = options;
  const selectedName = `selected${humps.pascalize(resource)}Ids`;

  return {
    initialState: {
      [selectedName]: [],
    },
    reducers: {
      bulkResourcesActionStart(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.pending;
      },
      bulkResourcesActionSuccess(state) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[selectedName] = [];
      },
      bulkResourcesActionFail(state, { payload: { error } }) {
        state[pluralResource].loadingStatus = FETCH_STATUSES.failed;
        state[pluralResource].error = error;
      },
      selectResource(state, { payload: { id } }) {
        state[selectedName] = append(id, state[selectedName]);
      },
      deselectResource(state, { payload: { id } }) {
        state[selectedName] = state[selectedName].filter(i => i !== id);
      },
      selectAllResources(state) {
        state[selectedName] = pluck('id', state[pluralResource].items);
      },
      deselectAllResources(state) {
        state[selectedName] = [];
      },
    },
    actionCreators(restDispatch) {
      return {
        bulkResourcesAction: params => {
          restDispatch('bulkResourcesActionStart');

          return repository
            .bulkAction(params)
            .then(() => {
              restDispatch('bulkResourcesActionSuccess');
              restDispatch('deselectAllResources');
            })
            .catch(error => {
              restDispatch('bulkResourcesActionFail', { error });
              restDispatch('deselectAllResources');
              throw error;
            });
        },
        toggleSelectResource: (item, isChecked) => {
          if (isChecked) {
            restDispatch('selectResource', item);
          } else {
            restDispatch('deselectResource', item);
          }
        },
        toggleSelectAllResources: isChecked => {
          if (isChecked) {
            restDispatch('selectAllResources');
          } else {
            restDispatch('deselectAllResources');
          }
        },
      };
    },
    abstractSelector: state => ({
      selectedResourceIds: state[selectedName],
    }),
  };
}
