import palette from '../palette';

export default {
  MuiInputLabel: {
    root: {},
    formControl: {
      transform: 'none',
      position: 'static',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 11,
      lineHeight: '16px',
      letterSpacing: '0.2px',
      textTransform: 'uppercase',
      color: palette.primary.main,
      opacity: 0.4,
      '&$focused': {
        opacity: 1,
      },
      '&$shrink': {
        transform: 'none',
      },
    },
  },
};
