import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { LOAN_HASH } from 'src/enums';
import { isNil } from 'ramda';

import TableSummary from 'components/TableSummary';
import NavigationAlertPopup from 'components/NavigationAlertPopup';
import Loader from 'components/Loader';
import LoanEditableTable from 'components/LoanEditableTable';

import * as MarketDiscountService from 'domain/loanMarketDiscount/service';
import { useUpdateLoanMarketDiscountMutation } from 'domain/loanMarketDiscount/apiSlice';

import { useEditableTableControls, EditableTableProvider } from 'hooks/useEditableTableControls';
import { useErrors } from 'hooks/useErrors';

import {
  resolver,
  prepareBeforeSending,
  prepareBeforeSetting,
  formFields,
} from 'pages/LoanMarketDiscount/validation';
import useStyles from 'pages/LoanMarketDiscount/useStyles';

const TITLE = 'Market Discount';
const HINT = 'Double-click the row to edit.';
const SAVE_BUTTON = 'Save changes';
const CANCEL_BUTTON = 'Cancel';
const FORM_ID = 'loanMarketDiscountForm';

export const MarketDiscountContent = props => {
  const { loanId, marketDiscountsData, summaryData } = props;

  const classes = useStyles();

  const { displayErrorsInToast, formatErrors, setErrorsToForm } = useErrors();

  const tableControls = useEditableTableControls();
  const { isEditingFormShown, editableRowId, hideEditableRow } = tableControls;

  const summaryTableData = MarketDiscountService.getSummaryTableData(summaryData);

  const tableHeaders = MarketDiscountService.marketDiscountsTableHeaders;

  const tableRows = useMemo(
    () => MarketDiscountService.getTableRows(marketDiscountsData),
    [marketDiscountsData],
  );

  const [
    updateMarketDiscount,
    {
      isLoading: isMarketDiscountUpdating,
      error: marketDiscountUpdateLoadError,
      isSuccess: isMarketDiscountUpdateLoadSuccess,
    },
  ] = useUpdateLoanMarketDiscountMutation();

  useEffect(() => {
    if (isMarketDiscountUpdateLoadSuccess) hideEditableRow();
  }, [isMarketDiscountUpdateLoadSuccess]);

  const methods = useForm({
    resolver,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const { getValues, trigger: triggerValidation } = methods;

  useEffect(() => {
    if (!marketDiscountUpdateLoadError) return;
    const errors = formatErrors(marketDiscountUpdateLoadError);
    const { backendServicesError, nonFieldErrors, fieldErrors } = errors;
    displayErrorsInToast([nonFieldErrors, backendServicesError]);
    setErrorsToForm(methods.setError, methods.errors, fieldErrors);
  }, [marketDiscountUpdateLoadError]);

  const handleSaveClick = async data => {
    const preparedData = prepareBeforeSending(data);
    await updateMarketDiscount({
      loanId,
      marketDiscountId: editableRowId,
      data: preparedData,
    });
  };

  const handlePopupSaveClick = async () => {
    const isValid = await triggerValidation();
    if (!isValid) throw new Error();
    const data = getValues();
    const preparedData = prepareBeforeSending(data);
    await updateMarketDiscount({
      loanId,
      marketDiscountId: editableRowId,
      data: preparedData,
    }).unwrap();
  };

  const handleCancelButtonClick = () => hideEditableRow();

  return (
    <>
      <NavigationAlertPopup when={isEditingFormShown} onSubmit={handlePopupSaveClick} />
      <div className={classes.marketDiscountHeader}>
        <h3 id={LOAN_HASH.details} className={classes.detailTitle}>
          {TITLE}
        </h3>
        {isEditingFormShown ? (
          <div>
            <Button
              onClick={handleCancelButtonClick}
              className={classes.cancelBtn}
              variant="contained"
              disabled={isMarketDiscountUpdating}
            >
              {CANCEL_BUTTON}
            </Button>

            <Button
              type="submit"
              form={FORM_ID}
              variant="contained"
              color="secondary"
              disabled={isMarketDiscountUpdating}
            >
              {SAVE_BUTTON}
            </Button>
          </div>
        ) : (
          <p className={classes.hint}>{HINT}</p>
        )}
      </div>
      <div className={classes.detailWrap}>
        <div className={classes.tableWrap}>
          {!isNil(marketDiscountsData) ? (
            <FormProvider {...methods}>
              <EditableTableProvider {...tableControls}>
                <LoanEditableTable
                  onSave={handleSaveClick}
                  columns={tableHeaders}
                  rows={tableRows}
                  dataItems={marketDiscountsData}
                  prepareBeforeSetting={prepareBeforeSetting}
                  formId={FORM_ID}
                  formFields={formFields}
                />
              </EditableTableProvider>
            </FormProvider>
          ) : (
            <div className={classes.loaderWrap}>
              <Loader />
            </div>
          )}
          <TableSummary summaryData={summaryTableData} />
        </div>
      </div>
    </>
  );
};

MarketDiscountContent.propTypes = {
  loanId: PropTypes.number,
  marketDiscountsData: PropTypes.arrayOf(MarketDiscountService.marketDiscountDataPropTypes),
  summaryData: MarketDiscountService.summaryDataPropTypes,
};
