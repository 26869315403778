import { useMemo } from 'react';

import { useParams, useLocation, useHistory, useRouteMatch, matchPath } from 'react-router-dom';
import queryString from 'query-string';

import { camelize } from 'utils/keysConverter';

const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch;

  return useMemo(
    () => ({
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      params,
      camelizedQuery: camelize({
        ...queryString.parse(location.search),
        ...params,
      }),
      match,
      matchPath,
      location,
      history,
    }),
    [params, match, location, history],
  );
};

export default useRouter;
