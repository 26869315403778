import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import Popup from 'components/Popup';

import { useReportTask } from 'hooks/api/useReportTask';
import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';

import { reportActions } from 'utils/reports';

const SimpleDownloadReportPopup = ({ setPopupState, popupTitleText, callback }) => {
  const { initiateTaskReportLoading, isTaskReportLoading, resetReportTask } = useReportTask();
  const { showSuccessNotification } = useSnackbar();
  const { formatErrors, displayErrorsInToast } = useErrors();

  useEffect(() => () => resetReportTask(), []);

  const handleImportClose = () => {
    setPopupState(null);
  };

  const handleImportSubmit = async () => {
    const toastText = `${popupTitleText} Successfully Generated`;
    try {
      const href = await initiateTaskReportLoading(callback);
      await reportActions.download(href);
      showSuccessNotification(toastText);
    } catch (e) {
      if (!isEmpty(e)) {
        const { nonFieldErrors, backendServicesError } = formatErrors(e);
        displayErrorsInToast([nonFieldErrors, backendServicesError]);
      }
    }
    handleImportClose();
  };

  return (
    <Popup
      title={popupTitleText}
      submitButtonText="Download Report"
      toggleState={setPopupState}
      onSubmit={handleImportSubmit}
      onClose={handleImportClose}
      isLoading={isTaskReportLoading}
    />
  );
};

SimpleDownloadReportPopup.propTypes = {
  popupTitleText: PropTypes.string,
  setPopupState: PropTypes.func,
  callback: PropTypes.func,
};

SimpleDownloadReportPopup.defaultProps = {
  popupTitleText: 'Report',
};

export default SimpleDownloadReportPopup;
