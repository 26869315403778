import React from 'react';

import { TableRow, TableCell } from '@material-ui/core/';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import useStyles from 'components/LoanEditableTable/useStyles';

import { useEditableTableContext } from 'hooks/useEditableTableControls';

export const DataRow = props => {
  const { row, rowKeys } = props;
  const classes = useStyles();

  const { setEditableRowId, showEditableRow, isEditingFormShown } = useEditableTableContext();

  const handleDoubleClickOnRow = () => {
    if (isEditingFormShown) return;
    setEditableRowId(row.id);
    showEditableRow();
  };

  return (
    <TableRow
      onDoubleClick={handleDoubleClickOnRow}
      className={clsx({
        [classes.onHover]: !isEditingFormShown,
      })}
    >
      {rowKeys.map((key, index) => (
        <TableCell
          key={`data-cell-${index}`}
          className={clsx(classes.tableCellFont, classes.tableCell)}
        >
          {row.data[key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

DataRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.number,
    data: PropTypes.shape({
      comment: PropTypes.string,
    }),
  }),
  rowKeys: PropTypes.arrayOf(PropTypes.string),
};
