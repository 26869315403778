import ManagerIndexPresenter from 'presenters/ManagerIndexPresenter';
import ManagerShowPresenter from 'presenters/ManagerShowPresenter';

import { managersRestHooks } from 'store/ManagersSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getEntityLastUpdated } from 'utils/date';

export const useManagers = () => {
  const {
    manager,
    managers,
    loadManager,
    loadManagers,
    updateManager,
    createManager,
    deleteManager,
  } = managersRestHooks.use();
  const managersTableData = ManagerIndexPresenter.tableData(managers?.items) || [];
  const managersTableKeys = ManagerIndexPresenter.tableKeys();
  const managerOptions = getSelectOptions(managers?.items, 'id', ManagerIndexPresenter.name);

  const managerTableData = ManagerShowPresenter.tableData(manager?.item);
  const managerLastUpdated = getEntityLastUpdated(manager?.item);
  const managerErrors = ManagerShowPresenter.errors(manager);

  return {
    manager: manager?.item,
    managers,
    loadManager,
    loadManagers,
    managerTableData,
    managersTableData,
    managersTableKeys,
    updateManager,
    createManager,
    deleteManager,
    areManagersLoading: useFetchStatus(managers.loadingStatus).isPending,
    isManagerLoading: useFetchStatus(manager.loadingStatus).isPending,
    managerOptions,
    managerLastUpdated,
    managerErrors,
  };
};
