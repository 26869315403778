import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    wrapper: {
      marginTop: '24px',
      width: '400px',
      padding: '16px 20px',
      background: theme.palette.athensGray,
      mixBlendMode: 'multiply',
      borderRadius: 6,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      gap: '16px',
      marginBottom: '10px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    label: {
      display: 'block',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.8,
    },
    value: {
      display: 'block',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
    },
  }),
  { name: 'TableSummary' },
);
