import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params) {
    const url = apiRoutes.fundingNotesPath();
    return FetchHelpers.get(url, params);
  },
  async create(params) {
    const url = apiRoutes.fundingNotesPath();
    return FetchHelpers.post(url, params);
  },
  async delete(id, params) {
    const url = apiRoutes.fundingNotePath(id);
    return FetchHelpers.delete(url, params);
  },
  async update(id, params) {
    const url = apiRoutes.fundingNotePath(id);
    return FetchHelpers.put(url, params);
  },
  async bulkAction(params) {
    const { id, data } = params;
    const url = apiRoutes.fundingNotePath(id);
    return FetchHelpers.patch(url, data);
  },
  async total() {
    const url = apiRoutes.fundingNotesTotalPath();
    return FetchHelpers.get(url);
  },
};
