import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

const YTDSnapshot = ({ data, needDiff, needLabels }) => {
  const classes = useStyles();

  const renderSnapshotValue = (value, index) => (
    <span
      key={`YTD_value_${index}`}
      className={clsx(classes.value, {
        [classes.valueLabeled]: needLabels,
      })}
    >
      {value}
    </span>
  );

  const renderSnapshotItem = (item, index) => (
    <li key={`YTD_item_${index}`}>
      <span>{item.label}</span>
      <span>
        {item.values.map(renderSnapshotValue)}
        {needDiff && (
          <span
            className={clsx(classes.diff, {
              [classes.diffPositive]: item.diff > 0,
              [classes.diffNegative]: item.diff < 0,
            })}
          >
            {item.diff}
          </span>
        )}
      </span>
    </li>
  );

  return (
    <div className={classes.snapshots}>
      <div className={classes.snapshotsWrapper}>
        {data.lists.map((list, index) => (
          <ul key={`YTD_list_${index}`} className={classes.snapshotsList}>
            {list.map(renderSnapshotItem)}
          </ul>
        ))}
      </div>
    </div>
  );
};

YTDSnapshot.propTypes = {
  data: PropTypes.shape({
    lists: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
          diff: PropTypes.number,
        }),
      ),
    ),
  }),
  needDiff: PropTypes.bool,
  needLabels: PropTypes.bool,
};

export default YTDSnapshot;
