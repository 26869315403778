import React, { useState, useEffect, useRef } from 'react';

import { useFormContext } from 'react-hook-form';
import { TableRow, TableCell, Button, Popper, Paper, IconButton } from '@material-ui/core/';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import { FORM_FIELD_TYPE } from 'enums';

import { formFields } from 'pages/LoanReconciliationInputs/validation';
import DatePickerCell from 'pages/LoanReconciliationInputs/components/DatePickerCell/';
import DecimalInputCell from 'pages/LoanReconciliationInputs/components/DecimalInputCell';
import CheckboxTableCell from 'pages/LoanReconciliationInputs/components/CheckboxTableCell';
import Comment from 'pages/LoanReconciliationInputs/components/Comment';
import useStyles from 'pages/LoanReconciliationInputs/useStyles';

const SAVE_BUTTON = 'Save';
const CANCEL_BUTTON = 'Cancel';

export const FormRow = props => {
  const { onCancel, onSubmit, row } = props;
  const methods = useFormContext();
  const classes = useStyles();
  const { setValue, formState, handleSubmit } = methods;
  const [popperOpen, setPopperOpen] = useState(false);
  const anchor = useRef();
  const arrowRef = useRef();
  const [checkboxValue, setCheckboxValue] = useState(false);

  const showCommentPopper = () => setPopperOpen(true);
  const hideCommentPopper = () => setPopperOpen(false);

  const resetComment = () =>
    setValue('comment', row?.data?.comment ?? '', { shouldValidate: true });

  useEffect(() => {
    if (checkboxValue) {
      showCommentPopper();
    } else {
      hideCommentPopper();
      resetComment();
    }
  }, [checkboxValue]);

  useEffect(() => {
    const commentError = formState.errors.comment;
    if (commentError) showCommentPopper();
  }, [formState]);

  const handleAddComment = () => {
    hideCommentPopper();
  };
  const handleOpenComment = () => {
    showCommentPopper();
  };

  const handleCancelComment = () => {
    hideCommentPopper();
    resetComment();
  };

  const mapping = {
    [FORM_FIELD_TYPE.date]: (name, properties) => (
      <DatePickerCell
        name={name}
        childrenProps={{
          views: ['month', 'year'],
          ...properties,
        }}
      />
    ),
    [FORM_FIELD_TYPE.decimal]: (name, properties) => (
      <DecimalInputCell
        name={name}
        childrenProps={{
          ...properties,
        }}
      />
    ),
    [FORM_FIELD_TYPE.checkbox]: (name, properties, index) => (
      <div className={classes.checkboxCellWrap}>
        <span ref={anchor} />
        <CheckboxTableCell
          name={name}
          label={properties.label}
          aria-describedby={`checkbox-${index}`}
          setCheckboxValue={setCheckboxValue}
        />
        {checkboxValue && (
          <>
            <IconButton onClick={handleOpenComment} className={classes.triangleArrowButton}>
              <Icon name="triangleArrow" className={classes.triangleSvg} />
            </IconButton>
            <Popper
              open={popperOpen}
              id={`checkbox-${index}`}
              anchorEl={anchor.current}
              placement="top-start"
              keepMounted
              popperOptions={{ positionFixed: true }}
              modifiers={{
                arrow: {
                  enabled: true,
                  element: arrowRef.current,
                },
                offset: {
                  enable: true,
                  offset: '0, 18',
                },
              }}
            >
              <Icon name="popperArrow" iconRef={arrowRef} className={classes.popperArrowInEdit} />
              <Paper elevation={1}>
                <Comment onSave={handleAddComment} onCancel={handleCancelComment} />
              </Paper>
            </Popper>
          </>
        )}
      </div>
    ),
  };

  return (
    <TableRow>
      {formFields.map((field, index) => {
        const { name, type, placeholder, label, disabled } = field;
        return (
          <TableCell key={`form-field-${index}`} className={classes.inputCell}>
            {mapping[type](name, { placeholder, label, disabled }, index)}
          </TableCell>
        );
      })}
      <TableCell className={classes.inputCell}>
        <Button color="primary" className={classes.saveButton} onClick={handleSubmit(onSubmit)}>
          {SAVE_BUTTON}
        </Button>
        <Button className={classes.deleteButton} onClick={onCancel}>
          {CANCEL_BUTTON}
        </Button>
      </TableCell>
    </TableRow>
  );
};

FormRow.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  row: PropTypes.shape({
    data: PropTypes.shape({
      comment: PropTypes.string,
    }),
  }),
};
