import React, { useEffect, useMemo } from 'react';

import ManagerForm from 'components/ManagerForm';

import { useCreateManagerMutation } from 'domain/manager/apiSlice';
import { ManagerService } from 'domain/manager/service';
import { useGetSuccessorBorrowerConfigurationQuery } from 'domain/successorBorrowerConfiguration/apiSlice';

import useRouter from 'hooks/useRouter';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

const ManagerAdd = () => {
  const { push } = useRouter();
  const { data: configuration, isFetching } = useGetSuccessorBorrowerConfigurationQuery();

  const defaultValues = useMemo(
    () => ManagerService.getDefaultValues(configuration),
    [configuration],
  );

  const [
    createManager,
    {
      error: managerCreateError,
      isSuccess: isManagerCreateSuccess,
      isLoading: isManagerCreateLoading,
    },
  ] = useCreateManagerMutation();

  const previousRoute = appRoutes.staffManagersPath();

  const handleSubmitClick = values => createManager(values);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isManagerCreateSuccess) push(previousRoute);
  }, [isManagerCreateSuccess]);

  const isDataLoading = isManagerCreateLoading || isFetching;

  return (
    <ContentLayout>
      <ManagerForm
        isEdit={false}
        formValues={defaultValues}
        pageTitle="New Manager"
        submitButtonText="Add"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        isDefaultDataLoading={isDataLoading}
        requestError={managerCreateError}
        previousRoute={previousRoute}
      />
    </ContentLayout>
  );
};

export default ManagerAdd;
