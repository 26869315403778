import { IPool, IExtendedPool } from 'domain/pool/types';
import { IPoolCreate } from 'domain/pool/schemas/create';
import { IPoolUpdate } from 'domain/pool/schemas/update';

import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const poolApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getPools: builder.query<IPool, void>({
      query: params => ({
        url: apiRoutes.poolsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.pool),
    }),
    getPool: builder.query<IExtendedPool, number>({
      query: id => ({
        url: apiRoutes.poolPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.pool),
    }),
    createPool: builder.mutation<IExtendedPool, IPoolCreate>({
      query: formData => ({
        url: apiRoutes.poolsPath(),
        method: 'POST',
        data: formData,
      }),
      transformResponse: selectDataFromResponse,
      invalidatesTags: [{ type: CACHE_TAG.pool, id: 'LIST' }],
    }),
    updatePool: builder.mutation<IExtendedPool, { poolId: number; pool: IPoolUpdate }>({
      query: ({ poolId, pool }) => ({
        url: apiRoutes.poolPath(poolId),
        method: 'PUT',
        data: pool,
      }),
      invalidatesTags: [{ type: CACHE_TAG.pool, id: 'LIST' }],
    }),
  }),
});

export const { useGetPoolsQuery, useGetPoolQuery, useUpdatePoolMutation, useCreatePoolMutation } =
  poolApi;
