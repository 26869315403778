import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import DocumentTitle from 'components/DocumentTitle';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import { useGetOrganizationsQuery } from 'domain/organization/apiSlice';
import * as OrganizationService from 'domain/organization/service';
import { PoolService } from 'domain/pool/service';

import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';

import { IdSerializer } from 'utils/serializer';
import { setServerErrorsToFields } from 'utils/errors';
import { getEntityLastUpdated } from 'utils/date';
import { getOrganizationTypes } from 'utils/getOrganizationsType';
import { getOptionsQueryParams } from 'utils/rtkQuery';

import useStyles from './useStyles';
import { fieldArray, indemnitorOptions, resolver } from './validation';

const PoolForm = props => {
  const {
    defaultPoolData,
    formSubmitError,
    isPoolLoading,
    isEdit,
    isFormSubmitting,
    onCancel,
    onSubmit,
    pageTitle,
    previousRoute,
    submitButtonText,
  } = props;

  const classes = useStyles();

  const lastUpdated = getEntityLastUpdated(defaultPoolData);

  const {
    data: organizationsData,
    error: organizationsLoadError,
    isFetching: isOrganizationsLoading,
  } = useGetOrganizationsQuery();

  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());

  const financialServices = useMemo(
    () =>
      OrganizationService.filterOrganizationsOptions(
        getOrganizationTypes(options).financialServices,
        organizationsData,
      ),
    [organizationsData],
  );
  const ratingAgencies = useMemo(
    () =>
      OrganizationService.filterOrganizationsOptions(
        getOrganizationTypes(options).ratingAgency,
        organizationsData,
      ),
    [organizationsData],
  );

  const methods = useForm({
    resolver,
  });

  useHandleRtkQueryErrors(formSubmitError, organizationsLoadError);

  const { setError, errors } = methods;

  const isFormDirty = methods.formState.isDirty;

  useEffect(() => {
    if (formSubmitError) {
      setServerErrorsToFields(setError, errors, formSubmitError);
    }
  }, [formSubmitError]);

  const selectValues = {
    financialServices,
    ratingAgencies,
    indemnitorOptions,
  };

  const handleSubmit = async values => {
    const mappingDict = [
      'bondTrustee',
      'indemnitySentFor',
      'masterServicer1',
      'masterServicer2',
      'ratingAgency1',
      'ratingAgency2',
      'ratingAgency3',
      'ratingAgency4',
    ];
    const serializedValues = IdSerializer(values, mappingDict);
    onSubmit(serializedValues);
  };

  const handleCancel = () => onCancel();

  const isRequestsLoading = isPoolLoading || isOrganizationsLoading;

  if (isRequestsLoading) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <ContentLayout>
      <FormProvider {...methods}>
        <DocumentTitle title={pageTitle} />
        <NavHashLink className={classes.backButton} exact to={previousRoute}>
          Back
        </NavHashLink>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {pageTitle}
          </Typography>
          <div className={classes.actionPanel}>
            <Button onClick={handleCancel} variant="contained" disabled={isFormSubmitting}>
              Cancel
            </Button>
            <Button
              disabled={!isFormDirty || isFormSubmitting}
              onClick={methods.handleSubmit(handleSubmit)}
              variant="contained"
              color="secondary"
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            <FormField
              fieldArray={fieldArray}
              errors={methods.errors}
              values={defaultPoolData}
              selectValues={selectValues}
            />
          </form>
          <div className={classes.contentSidebar}>
            {isEdit && (
              <div className={classes.lastUpdated}>
                <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                <div>{lastUpdated}</div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </ContentLayout>
  );
};

PoolForm.propTypes = {
  defaultPoolData: PoolService.poolPropTypes,
  formSubmitError: PropTypes.shape({}),
  isPoolLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  isFormSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  pageTitle: PropTypes.string,
  previousRoute: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export default PoolForm;
