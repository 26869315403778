import FreddieFloatPaymentsPresenter, {
  freddieFloatPaymentsColumnConfig,
} from 'presenters/FreddieFloatPaymentsPresenter';

import { freddieFloatPaymentsRestHooks } from 'store/FreddieFloatPaymentsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useFreddieFloatPayments = () => {
  const { freddieFloatPayments, loadFreddieFloatPayments } = freddieFloatPaymentsRestHooks.use();

  const { isPending: isFreddieFloatPaymentsLoading } = useFetchStatus(
    freddieFloatPayments.loadingStatus,
  );

  const tableData = FreddieFloatPaymentsPresenter.tableData(freddieFloatPayments?.items);

  const tableKeys = FreddieFloatPaymentsPresenter.tableKeys();

  const totals = FreddieFloatPaymentsPresenter.totals(freddieFloatPayments?.items);
  return {
    freddieFloatPayments,
    loadFreddieFloatPayments,
    tableData,
    tableKeys,
    columnConfig: freddieFloatPaymentsColumnConfig,
    isFreddieFloatPaymentsLoading,
    totals,
  };
};
