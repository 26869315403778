import React, { useEffect, useMemo } from 'react';

import AdministrativeAgentForm from 'components/AdministrativeAgentForm';

import { useCreateAdministrativeAgentMutation } from 'domain/administrativeAgent/apiSlice';
import { AdministrativeAgentService } from 'domain/administrativeAgent/service';
import { useGetSuccessorBorrowerConfigurationQuery } from 'domain/successorBorrowerConfiguration/apiSlice';

import useRouter from 'hooks/useRouter';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

const AdministrativeAgentAdd = () => {
  const { push } = useRouter();
  const { data: configuration, isFetching } = useGetSuccessorBorrowerConfigurationQuery();

  const defaultValues = useMemo(
    () => AdministrativeAgentService.getDefaultValues(configuration),
    [configuration],
  );

  const [
    createAdministrativeAgent,
    {
      error: administrativeAgentCreateError,
      isSuccess: isAdministrativeAgentCreateSuccess,
      isLoading: isAdministrativeAgentCreateLoading,
    },
  ] = useCreateAdministrativeAgentMutation();

  const previousRoute = appRoutes.staffAdministrativeAgentsPath();

  const handleSubmitClick = values => createAdministrativeAgent(values);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isAdministrativeAgentCreateSuccess) push(previousRoute);
  }, [isAdministrativeAgentCreateSuccess]);

  const isDataLoading = isAdministrativeAgentCreateLoading || isFetching;
  return (
    <ContentLayout>
      <AdministrativeAgentForm
        isEdit={false}
        formValues={defaultValues}
        pageTitle="New Administrative Agent"
        submitButtonText="Add"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        isDefaultDataLoading={isDataLoading}
        requestError={administrativeAgentCreateError}
        previousRoute={previousRoute}
      />
    </ContentLayout>
  );
};

export default AdministrativeAgentAdd;
