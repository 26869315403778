import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    error: {
      color: 'red',
      margin: 0,
      padding: 0,
      display: 'block',
      alignSelf: 'flex-start',
    },
  }),
  { name: 'FormError' },
);
