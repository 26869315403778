import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    yearSelectWrap: {
      marginLeft: '16px',
      marginRight: '32px',
    },
    yearSelect: {
      maxWidth: 'none',
    },
  }),
  { name: 'ReportPopup' },
);
