export const poolColumnConfig = {
  securityDescription: {
    displayedName: 'Security Description',
  },
  cusip: {
    displayedName: 'Cusip',
  },
  currentPrice: {
    displayedName: 'Bloomberg Pool Balance',
  },
  originalIssuePrice: {
    displayedName: 'Original Pool Issue',
  },
  bloombergPoolBalanceDate: {
    displayedName: 'Bloomberg Pool Balance Date',
  },
  bondTrustee: {
    displayedName: 'Bond Trustee',
  },
  masterServicer1: {
    displayedName: 'Master Servicer 1',
  },
  masterServicer2: {
    displayedName: 'Master Servicer 2',
  },
  masterServicerNotes: {
    displayedName: 'Master Servicer Notes',
  },
  ratingAgency1: {
    displayedName: 'Rating Agency 1',
  },
  ratingAgency2: {
    displayedName: 'Rating Agency 2',
  },
  ratingAgency3: {
    displayedName: 'Rating Agency 3',
  },
  ratingAgency4: {
    displayedName: 'Rating Agency 4',
  },
  isIndemnityGiven: {
    displayedName: 'Indemnity Given?',
  },
  indemnityNotes: {
    displayedName: 'Indemnity Notes',
  },
  indemnityDate: {
    displayedName: 'Indemnity Date',
  },
  indemnitor: {
    displayedName: 'Indemnitor',
  },
  indemnitySentFor: {
    displayedName: 'Indemnity Sent For',
  },
  issuerAbbreviation: {
    displayedName: 'Issuer Abbreviation',
  },
  issuerSeries: {
    displayedName: 'Issuer Series',
  },
  issuerName: {
    displayedName: 'Issuer Name',
  },
  certificateDefinition: {
    displayedName: 'Certificate Definition',
  },
  certificateHolderLabel: {
    displayedName: 'Certificate Holder Label',
  },
  specialServicer: {
    displayedName: 'Special Servicer',
  },
  fiscalAgent: {
    displayedName: 'Fiscal Agent',
  },
  psaDate: {
    displayedName: 'PSA Date (Month DD, YYYY)',
  },
  hasPoolSecuritized: {
    displayedName: 'Securitized?',
  },
};

export const getPoolTableKeys = () => Object.keys(poolColumnConfig);
