import { LOAN_HASH, SB_HASH } from 'enums';

export default {
  homePath: () => '/home',
  homeUpcomingParRepaysPath: () => '/home/upcoming-property-par-repays',
  homeRecentlyClosedDealsPath: () => '/home/recently-closed-deals',
  homeUpcomingMaturitiesPath: () => '/home/upcoming-maturities',
  homeOverviewPath: () => '/home/overview',
  authPath: () => '/auth',
  successorBorrowersPath: () => '/successor-borrowers',
  successorBorrowerPath: id => `/successor-borrowers/${id}`,
  successorBorrowerDetailsPath: id => `/successor-borrowers/${id}#${SB_HASH.details}`,
  successorBorrowerPoolPath: id => `/successor-borrowers/${id}#${SB_HASH.pool}`,
  successorBorrowerSnapshotsPath: id => `/successor-borrowers/${id}#${SB_HASH.snapshots}`,
  successorBorrowerLoansPath: id => `/successor-borrowers/${id}/loans`,
  successorBorrowerLoansStatusPath: (id, status) => `/successor-borrowers/${id}/loans/${status}`,
  successorBorrowerEditPath: id => `/successor-borrowers/${id}/edit`,
  successorBorrowerCreatePath: () => '/successor-borrowers/create',
  poolsPath: () => '/pools',
  poolPath: id => `/pools/${id}`,
  poolEditPath: id => `/pools/${id}/edit`,
  poolCreatePath: () => '/pools/create',
  loansPath: () => '/loans',
  loansInStatusPath: status => `/loans#${status}`,
  loanPath: id => `/loans/${id}`,
  loanDetailsPath: id => `/loans/${id}#${LOAN_HASH.details}`,
  loanPartiesPath: id => `/loans/${id}#${LOAN_HASH.parties}`,
  loanDealChecklistPath: id => `/loans/${id}#${LOAN_HASH.dealChecklist}`,
  loanNoteIssuesPath: id => `/loans/${id}#${LOAN_HASH.noteIssues}`,
  loanPostClosingPath: id => `/loans/${id}#${LOAN_HASH.postClosing}`,
  loanSharingPath: id => `/loans/${id}#${LOAN_HASH.sharing}`,
  loanMonetizationPath: id => `/loans/${id}#${LOAN_HASH.monetization}`,
  loanHedgingPath: id => `/loans/${id}#${LOAN_HASH.hedging}`,
  loanEscrowPath: id => `/loans/${id}/escrow-account`,
  loanGovernmentSecuritiesPath: id => `/loans/${id}/detail-of-government-securities`,
  loanDefeasedPaymentsPath: id => `/loans/${id}/payments-of-defeased-loan`,
  loanMarketDiscountPath: id => `/loans/${id}/market-discount`,
  loanReconciliationInputsPath: id => `/loans/${id}/reconciliation-inputs`,
  loanCreatePath: () => '/loans/create',
  loanEditPath: id => `/loans/${id}/edit`,
  loanCRUDHashPath: (isEdit, id, hash) =>
    isEdit ? `/loans/${id}/edit#${hash}` : `/loans/create#${hash}`,
  dealsPath: () => '/deals',
  partiesPath: () => '/parties',
  partiesOrganizationsPath: () => '/parties/organizations',
  partiesPeoplePath: () => '/parties/people',
  partiesPersonEditPath: id => `/parties/people/${id}/edit`,
  partiesPersonCreatePath: () => '/parties/people/create',
  reportsPath: () => '/reports',
  liveFormsPath: () => '/live-forms',
  liveFormsReconciliationDiscrepanciesPath: () => '/live-forms/reconciliation-discrepancies',
  assumedDealsPath: () => '/live-forms/assumed-deals',
  liveFormsOIDBalanceReportPath: () => '/live-forms/oid-balance-report',
  OIDBalanceReportLoanPath: id => `/oid-balance-report/loans/${id}`,
  OIDBalanceReportSuccessoBorrowerPath: id => `/oid-balance-report/successor-borrowers/${id}`,
  OIDBalanceReportMembersPath: id => `/oid-balance-report/members/${id}`,
  annualMortgageInterestAccrualReportPath: () => '/live-forms/annual-mortgage-interest-accrual',
  outstandingAccountantPath: () => '/live-forms/outstanding-accountant',
  dealsSummaryOfDealsPath: () => '/live-forms/summary-of-deals',
  outstandingClosingBindersPath: () => '/live-forms/outstanding-closing-binders',
  buyUpPendingPath: () => '/live-forms/buy-up-pending',
  missingReconciliationInputsPath: () => '/live-forms/missing-reconciliation-inputs',
  liveFormsDataValidationReportPath: () => '/live-forms/data-validation-report',
  liveFormsCOAPath: () => '/live-forms/certificate-of-authority',
  partiesOrganizationCreatePath: () => '/parties/organizations/create',
  partiesOrganizationPath: id => `/parties/organizations/${id}`,
  partiesOrganizationEditPath: id => `/parties/organizations/${id}/edit`,
  staffPath: () => '/staff',
  staffAdministrativeAgentsPath: () => '/staff/administrative-agents',
  staffAdministrativeAgentPath: id => `/staff/administrative-agents/${id}`,
  staffAdministrativeAgentEditPath: id => `/staff/administrative-agents/${id}/edit`,
  staffAdministrativeAgentCreatePath: () => '/staff/administrative-agents/create',
  staffMembersPath: () => '/staff/members',
  staffMemberPath: id => `/staff/members/${id}`,
  staffMemberEditPath: id => `/staff/members/${id}/edit`,
  staffMemberCreatePath: () => '/staff/members/create',
  staffManagersPath: () => '/staff/managers',
  staffManagerPath: id => `/staff/managers/${id}`,
  staffManagerEditPath: id => `/staff/managers/${id}/edit`,
  staffManagerCreatePath: () => '/staff/managers/create',
  toolsPath: () => '/tools',
  changePassword: () => '/account-settings/password',
  fundingNotesPath: () => '/funding-notes',
  passwordRecoveryPath: () => '/password-recovery',
  passwordResetPath: () => '/password-reset',
  freddieFloatPaymentPath: () => '/freddie-float-payment',
};
