import { createSlice } from '@reduxjs/toolkit';
import OutstandingAccountantsRepository from 'repositories/OutstandingAccountantsRepository';

import { createRestSlice } from 'utils/RestSlice';

const outstandingAccountantsRestSlice = createRestSlice({
  resource: 'outstandingAccountant',
  repository: OutstandingAccountantsRepository,
  slices: ['loadOutstandingAccountants'],
});

const initialState = {
  ...outstandingAccountantsRestSlice.initialState,
};

const outstandingAccountantsSlice = createSlice({
  name: 'outstandingAccountants',
  initialState,
  reducers: {
    ...outstandingAccountantsRestSlice.reducers,
  },
});

export default outstandingAccountantsSlice.reducer;
export const outstandingAccountantsRestHook = outstandingAccountantsRestSlice.hooks(
  outstandingAccountantsSlice,
  'OutstandingAccountantsSlice',
);
