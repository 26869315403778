import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '20px',
    },
    title: {
      textAlign: 'center',
    },
    message: {
      ...theme.typography.body3,
      color: theme.palette.tuna,
      opacity: '0.7',
      textAlign: 'center',
    },
    button: {
      width: '183px',
    },
  }),
  { name: 'someName' },
);
