import { useInterestAccrualActions } from 'store/MortgageInterestAccrualReportSlice.js';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useInterestAccrualReport = () => {
  const {
    setReportYear,
    createInterestAccrualsLongPollingWorker,
    interestAccrualsReport,
    reportYear,
  } = useInterestAccrualActions();

  const {
    item: interestAccrualReport,
    loadingStatus,
    errors: interestAccrualsLoadError,
  } = interestAccrualsReport;

  const { isPending: isInterestAccrualsReportLoading } = useFetchStatus(loadingStatus);

  return {
    setReportYear,
    reportYear,
    interestAccrualReport,
    createInterestAccrualsLongPollingWorker,
    isInterestAccrualsReportLoading,
    interestAccrualsLoadError,
  };
};
