/* eslint-disable react/prop-types */
import React from 'react';

import { NavHashLink } from 'react-router-hash-link';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const EMPTY_VALUE_SYMBOL = '-';

const SuccessorBorrowerSnapshots = props => {
  const { snapshots, poolData } = props;
  const classes = useStyles();

  const renderSnapshotSummary = data => (
    <>
      <ul className={classes.snapshotsList}>
        {data?.loansList?.map(item => (
          <li key={item.label}>
            <span>{item.label}</span>
            <span>{item.value}</span>
          </li>
        ))}
      </ul>
      <div className={classes.snapshotsSummary}>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Outstanding Principal Balance</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.outstandingPrincipalBalance || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Bloomberg Pool Balance</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.bloombergPoolBalance || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Pool Percentage Held</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.poolPercentageHeld || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Outstanding Securities Balance</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.outstandingSecuritiesBalance || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Pool Securities Cost</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.poolSecuritiesCost || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>
            Pool Principal Balance at Time of Defeasance
          </span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.poolPrincipalBalanceAtTimeOfDefeasance || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
        <div className={classes.snapshotsSummaryRow}>
          <span className={classes.snapshotsSummaryLabel}>Pool Original Loan Amount</span>
          <span className={classes.snapshotsSummaryValue}>
            {data?.poolOriginalLoanAmount || `${EMPTY_VALUE_SYMBOL}`}
          </span>
        </div>
      </div>
    </>
  );

  return (
    <div className={classes.snapshots}>
      <div className={classes.snapshotsWrapper}>
        <div className={classes.snapshotsHeader}>
          <h4 className={classes.snapshotsTitle}>Total Pool Snapshot</h4>
          <span className={classes.snapshotsPool}>
            <NavHashLink className={classes.snapshotsPool} to={appRoutes.poolPath(poolData?.id)}>
              {poolData?.securityDescription}
            </NavHashLink>
          </span>
        </div>
        <ul className={classes.snapshotsList}>
          <li>
            <span>Total Successor Borrowers</span>
            <span>{snapshots?.pool?.successorBorrowers?.allSuccessorBorrowers}</span>
          </li>
          <li>
            <span>Total Active</span>
            <span>{snapshots?.pool?.successorBorrowers?.active}</span>
          </li>
          <li>
            <span>Total Inactive</span>
            <span>{snapshots?.pool?.successorBorrowers?.inactive}</span>
          </li>
        </ul>
        {renderSnapshotSummary(snapshots.pool)}
      </div>
      <div className={classes.snapshotsWrapper}>
        <div className={classes.snapshotsHeader}>
          <h4 className={classes.snapshotsTitle}>Entity Snapshot</h4>
        </div>
        {renderSnapshotSummary(snapshots.entity)}
      </div>
    </div>
  );
};

export default SuccessorBorrowerSnapshots;
