import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    table: {
      width: '100%',
      backgroundColor: 'white',
    },
    tableBorders: {
      borderCollapse: 'separate',
      borderLeft: theme.borders,
      borderRight: theme.borders,
      '& thead th': {
        borderTop: theme.borders,
        borderBottom: theme.borders,
      },
      '& tbody td': {
        borderBottom: theme.borders,
      },
      '& tfoot td': {
        borderBottom: theme.borders,
      },
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '80px',
        height: '80px',
      },
    },
    tableContent: {
      width: '100%',
    },
    tableContainerScrollable: {
      height: '100%',
      overflow: 'auto',
    },
  }),
  { name: 'ListTable' },
);
