import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const columnConfig = {
  id: {
    displayedName: 'PID',
    canBeHidden: false,
  },
  fullName: {
    displayedName: 'Name',
    canBeHidden: false,
    isLink: true,
  },
  email: {
    displayedName: 'Email',
    canBeHidden: false,
  },
  workPhone: {
    displayedName: 'Work Phone',
    canBeHidden: false,
  },
  employerName: {
    displayedName: 'Company',
    canBeHidden: false,
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    fullName: PropTypes.string,
    email: PropTypes.string,
    workPhone: PropTypes.string,
    employerName: PropTypes.string,
  },
  {
    tableData(list) {
      return (list || []).map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
        },
      }));
    },
  },
);
