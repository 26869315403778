import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

export const resolver = yupResolver(
  Yup.object({
    address: Yup.string().trim().nullable().required('This field is required'),
    city: Yup.string().trim().nullable(),
    fax: Yup.string().trim().nullable(),
    name: Yup.string().trim().nullable().required('This field is required'),
    organizationType: Yup.string().trim().required('This field is required').nullable(),
    phone: Yup.string().trim().nullable(),
    shortName: Yup.string().trim().nullable(),
    state: Yup.string().trim().nullable(),
    website: Yup.string().trim().url('Web Site must be a valid URL').nullable(),
    zip: Yup.string().trim().nullable(),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'name',
    displayedName: 'Organization Name',
    type: FORM_FIELD_TYPE.input,
    isRequired: true,
  },
  { fieldKey: 'shortName', displayedName: 'Organization Short Name', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'organizationType',
    displayedName: 'Organization Type',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'organizationTypes',
    placeholder: 'Select Type',
    isRequired: true,
  },
  { fieldKey: 'website', displayedName: 'Web Site', type: FORM_FIELD_TYPE.input, inputType: 'url' },
  { fieldKey: 'phone', displayedName: 'Phone', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'fax', displayedName: 'Fax', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'address',
    displayedName: 'Address',
    type: FORM_FIELD_TYPE.input,
    multiline: true,
    isRequired: true,
  },
  { fieldKey: 'city', displayedName: 'City', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'state',
    displayedName: 'State / Region',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'states',
    placeholder: 'Select Region',
  },
  { fieldKey: 'zip', displayedName: 'Postal Code', type: FORM_FIELD_TYPE.input },
];
