import { useLocalStorage } from '@rehooks/local-storage';

import { LOCAL_STORAGE_KEY } from 'enums';

import { useReconciliationDiscrepanciesActions } from 'store/ReconciliationDiscrepanciesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getCurrentYearMonth } from 'utils/date';

export const useReconciliationDiscrepancies = () => {
  const [reportYearMonth, setReportYearMonth] = useLocalStorage(
    LOCAL_STORAGE_KEY.reconciliationDiscrepanciesReportYearMonth,
    getCurrentYearMonth(),
  );

  const {
    createReconciliationDiscrepanciesLongPollingWorker,
    reconciliationDiscrepancies,
    loadingStatus,
    reconciliationDiscrepanciesLoadError,
    resetReconciliationDiscrepanciesData,
  } = useReconciliationDiscrepanciesActions();

  return {
    createReconciliationDiscrepanciesLongPollingWorker,
    report: reconciliationDiscrepancies,
    isReportLoading: useFetchStatus(loadingStatus).isPending,
    reconciliationDiscrepanciesLoadError,
    resetReconciliationDiscrepanciesData,
    reportYearMonth,
    setReportYearMonth,
  };
};
