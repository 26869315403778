import { find, propEq, isNil, keys, isEmpty, toPairs } from 'ramda';

export const pickData = (id, data) => find(propEq('id', id))(data);

export const preventNullProps = values =>
  keys(values).reduce((acc, key) => {
    const prop = values[key];
    const newProp = isNil(prop) ? '' : prop;
    return { ...acc, [key]: newProp };
  }, {});

export const replaceEmptyStringsToNull = data =>
  toPairs(data).reduce((acc, pair) => {
    const [key, value] = pair;
    const newValue = isEmpty(value) ? null : value;
    return { ...acc, [key]: newValue };
  }, {});
