import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { isNil, not } from 'ramda';

import DatePicker from 'components/DatePicker';

import { useReconciliationDiscrepancies } from 'hooks/api';

import { setServerErrorsToFields } from 'utils/errors';
import { parseDate } from 'utils/date';

import useStyles from './useStyles';
import { resolver, formField } from './validation';

export const MonthInput = props => {
  const { reconciliationDiscrepanciesLoadError, reportYearMonth } =
    useReconciliationDiscrepancies();

  const { onSubmit } = props;
  const classes = useStyles();

  const methods = useForm({
    mode: 'onSubmit',
    resolver,
    shouldFocusError: true,
    defaultValues: {
      reconciliationYearMonth: parseDate('y-M', reportYearMonth),
    },
  });

  const { setError, errors } = methods;

  useEffect(() => {
    if (not(isNil(reconciliationDiscrepanciesLoadError))) {
      setServerErrorsToFields(setError, errors, reconciliationDiscrepanciesLoadError);
    }
  }, [reconciliationDiscrepanciesLoadError]);

  return (
    <div className={classes.yearInput}>
      <FormProvider {...methods}>
        <DatePicker
          onSubmit={onSubmit}
          formField={formField}
          muiViewTypes={['month', 'year']}
          format="MM/yyyy"
        />
      </FormProvider>
    </div>
  );
};

MonthInput.propTypes = {
  onSubmit: PropTypes.func,
};
