import { isNil, last, isEmpty } from 'ramda';
import numeral from 'numeral';

export const CURRENCY_TYPES = {
  USD: '$',
  empty: '',
};

export const getTwoDigitsAfterDotNumber = number => {
  const numberInString = String(number);
  if (numberInString.indexOf('.') === -1) return `${number}.00`;
  return numberInString.slice(0, numberInString.indexOf('.') + 3);
};

export const formatCurrency = (number, currencyType = CURRENCY_TYPES.USD) => {
  if (isNil(number)) {
    return '-';
  }
  const isNegative = Number(number) < 0;
  const twoDigitsAfterDotNumber =
    Number(getTwoDigitsAfterDotNumber(number)) * (isNegative ? -1 : 1);
  const formattedNumber = `${isNegative ? '-' : ''}${currencyType}${Intl.NumberFormat('en-US', {
    options: {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    },
  }).format(Number(twoDigitsAfterDotNumber))}`;

  if (formattedNumber.indexOf('.') === -1) {
    return `${formattedNumber}.00`;
  }

  if (formattedNumber.indexOf('.') === formattedNumber.length - 2) {
    return `${formattedNumber}0`;
  }

  return formattedNumber;
};

export const formatDigits = number => {
  if (isNil(number) || isEmpty(number)) {
    return '';
  }

  const isLastDot = last(number) === '.';
  const isLastDotAndZero = last(number.slice(0, -1)) === '.' && last(number) === '0';
  const isSingleDot = (number.match(/\./g) || []).length <= 1;
  const hasZeroAfterDot = (number.match(/\.0/g) || []).length > 0;
  let formattedNumber = number;

  if (isLastDot || isLastDotAndZero) {
    if (isSingleDot || hasZeroAfterDot) {
      return formattedNumber;
    }
    return formattedNumber.slice(0, -1);
  }

  formattedNumber = numeral(formattedNumber).format('0,0.[00]');
  return formattedNumber;
};

// formats numbers to percent
export const formatPercent = (number, roundingNumber = 0) => {
  if (isNil(number)) {
    return '-';
  }
  return `${(Number(number) * 100).toFixed(roundingNumber)}%`;
};

export const formatSelectId = selectValue => selectValue?.value || null;

// formats strings to percent
export const toPercent = num => (isNil(num) ? '-' : `${numeral(num).value()}%`);

// formats numbers to numbers(percentValue)
export const toPercentNumber = (num, roundingNumber = 0) => {
  if (isNil(num)) return null;
  return numeral(num).multiply(100).value().toFixed(roundingNumber);
};

export const toCurrencyNumber = num => {
  if (isNil(num)) return null;
  return Number(numeral(num).value().toFixed(2));
};
export const convertPercentToCoefficient = (num, currentLength) => {
  if (isNil(num)) return null;
  const coefficient = Number(num) / 100;
  return Number(coefficient.toFixed(currentLength + 2));
};

export const toNumber = value => (isNil(value) ? null : Number(value));

export const isZeroOrNull = value => (isNil(value) ? true : Number(value) === 0);
