import { propOr, isNil } from 'ramda';

const getRefCurrent = propOr(null, 'current');

const MIN_HEIGHT = 0;

export const getElementHeight = elementRef => {
  const nodeElement = getRefCurrent(elementRef);
  if (nodeElement) {
    return nodeElement.nodeHeight || MIN_HEIGHT;
  }
  return MIN_HEIGHT;
};

export const getHeight = elementRef => {
  const nodeElement = getRefCurrent(elementRef);
  if (isNil(nodeElement)) return MIN_HEIGHT;
  const rectangle = nodeElement.getBoundingClientRect();
  const result = rectangle.height;
  return result;
};

export const getHeaderHeight = element => {
  if (!element) return null;
  const height = element.getBoundingClientRect
    ? element.getBoundingClientRect().height
    : element?.nodeHeight;
  return height ?? MIN_HEIGHT;
};
