import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableBottomRow: {
      background: theme.palette.background.default,
    },
    tableBottomCell: {
      height: '48px',
      padding: '16px 12px',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 600,
    },
    rightAlign: {
      textAlign: 'right',
    },
  }),
  { name: 'FreddieFloatPayment' },
);
