import React, { useMemo, useState } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import StickyBox from 'react-sticky-box/dist/esnext';
import { Typography, Button, Portal } from '@material-ui/core';
import clsx from 'clsx';

import ListTable from 'components/ListTable';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import SuccessorBorrowerSideNav from 'components/SuccessorBorrowerSideNav';
import PaginationPanel from 'components/PaginationPanel';

import { useGetSuccessorBorrowerQuery } from 'domain/successorBorrower/apiSlice';
import { useGetLoansQuery } from 'domain/loan/apiSlice';
import { LoansService, SuccessorBorrowerLoansService } from 'domain/loan/service';
import { SuccessorBorrowerService } from 'domain/successorBorrower/service';

import useRouter from 'hooks/useRouter';
import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';
import { usePaginationAndOrderingContext } from 'hooks/usePaginationAndOrdering';
import { useLoansStatus } from 'hooks/useLoansStatus';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { isStatusActive, isStatusInactive } from 'utils/layoutStatus';

import useStyles from './useStyles';

const COUNT_ITEMS_IS_ZERO = 0;

export const SuccessorBorrowerLoans = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();

  const [sideBarContainer, setRefSideBarContainer] = useState(null);

  const { page } = usePaginationAndOrderingContext();

  const { loansStatus } = useLoansStatus();

  const {
    data: loansData,
    isFetching: areLoansLoading,
    error: loansLoadError,
    isError: isLoansLoadingError,
  } = useGetLoansQuery({ successorBorrowerId: id, status: loansStatus, page });

  const count = loansData?.count;

  const tableData = useMemo(() => LoansService.getTableData(loansData?.results), [loansData]);

  const { data: successorBorrowerData, error: successorBorrowerLoadError } =
    useGetSuccessorBorrowerQuery(id);

  const successorBorrower = useMemo(
    () => SuccessorBorrowerService.getSuccessorBorrower(successorBorrowerData),
    [successorBorrowerData],
  );

  const sbLoansTableHeader = SuccessorBorrowerLoansService.getSbLoansTableHeader();

  const sbLoansColumnConfig = SuccessorBorrowerLoansService.columnConfig;

  const { elementRef: header, elementHeight } = useRefElementHeight();

  useHandleRtkQueryErrors(successorBorrowerLoadError, loansLoadError);

  const successorBorrowerHeaderName = useMemo(
    () => successorBorrower?.headerName,
    [successorBorrower],
  );

  const successorBorrowerStatus = successorBorrower?.tableData?.status;

  return (
    <>
      <Portal container={sideBarContainer}>
        <SuccessorBorrowerSideNav />
      </Portal>
      <RootLayoutV2 sideBarRef={setRefSideBarContainer} isPortal>
        <StickyBox offsetTop={0} offsetBottom={10} className="sticky-box">
          <div
            ref={header}
            className={clsx(classes.contentPanel, {
              [classes.backgroundActive]: isStatusActive(successorBorrowerStatus),
              [classes.backgroundInactive]: isStatusInactive(successorBorrowerStatus),
            })}
          >
            <h2 className={classes.contentSubtitle}>Successor Borrowers</h2>
            <div className={classes.headerRow}>
              <Typography className={classes.contentTitle} variant="h1">
                {successorBorrowerHeaderName}
              </Typography>
              <Button
                type="button"
                className={classes.editButton}
                variant="text"
                color="primary"
                exact
                to={appRoutes.successorBorrowerEditPath(id)}
                component={NavHashLink}
              >
                Edit Successor Borrower
              </Button>
            </div>
          </div>
        </StickyBox>
        <BackgroundFillByStatus status={successorBorrowerStatus} isSuccessorBorrowerStatuses>
          <ContentLayout className={classes.contentWrap}>
            <h3 className={classes.detailTitle}>Loans</h3>
            <ListTable
              columnConfig={sbLoansColumnConfig}
              detailPath={appRoutes.loanPath}
              fixedTopOffset={elementHeight}
              isLoading={areLoansLoading}
              isOuterScrollable
              isPaginationEnabled
              tableData={tableData}
              tableHeader={sbLoansTableHeader}
            />
            <PaginationPanel itemsCount={isLoansLoadingError ? COUNT_ITEMS_IS_ZERO : count} />
          </ContentLayout>
        </BackgroundFillByStatus>
      </RootLayoutV2>
    </>
  );
};
