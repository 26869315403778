import React, { useRef } from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Link } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import LoanHeader from 'components/LoanHeader';

import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';
import { useGetLoanEscrowAccountsQuery } from 'domain/loanEscrowAccount/apiSlice';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { isStatusActiveInPayment } from 'utils/layoutStatus';
import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';

import EscrowAccountContent from './components/EscrowAccountContent';
import useStyles from './useStyles';

const OID_BALANCE = 'OID Balance';
const EDIT_BUTTON = 'Edit Property';

export const LoanEscrowAccount = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();

  const header = useRef(null);

  const {
    data: escrowAccountsData,
    isFetching: isEscrowAccountsLoading,
    error: escrowAccountLoadError,
  } = useGetLoanEscrowAccountsQuery(id);

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);

  useHandleRtkQueryErrors(escrowAccountLoadError, loanLoadError);

  const title = LoanService.getDealName(loanData);
  const loanStatus = LoanService.getLoanStatus(loanData);

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isDataLoading = isEscrowAccountsLoading || isLoanLoading;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isDataLoading}>
      <DocumentTitle title={title} />
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <LoanHeader ref={header} loanStatus={loanStatus} title={title}>
          <div>
            {isStatusActiveInPayment(loanStatus) && (
              <Link
                href={appRoutes.OIDBalanceReportLoanPath(id)}
                color="secondary"
                className={classes.link}
              >
                {OID_BALANCE}
              </Link>
            )}
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.loanEditPath(id)}
              component={NavLink}
            >
              {EDIT_BUTTON}
            </Button>
          </div>
        </LoanHeader>
        <EscrowAccountContent loanId={Number(id)} escrowAccountsData={escrowAccountsData} />
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
