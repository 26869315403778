import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import { transformDate } from 'utils/date';

export const resolver = yupResolver(
  Yup.object({
    assumedFromDate: Yup.string()
      .transform(transformDate)
      .required('Required')
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat)
      .nullable(),
    assumedToDate: Yup.string()
      .transform(transformDate)
      .required('Required')
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat)
      .nullable(),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'assumedFromDate',
    displayedName: 'Assumed date from',
    type: FORM_FIELD_TYPE.date,
    placeholder: 'Assumed date from',
    isFullRow: true,
  },
  {
    fieldKey: 'assumedToDate',
    displayedName: 'Assumed date to',
    type: FORM_FIELD_TYPE.date,
    placeholder: 'Assumed date to',
    isFullRow: true,
  },
];
