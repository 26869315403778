import React, { useState } from 'react';

import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Input, Typography } from '@material-ui/core';
import LoansRepository from 'repositories/LoansRepository';

import FormError from 'components/FormError';

import useDebouncedUpdate from 'hooks/useDebouncedUpdate';

import useStyles from './useStyles';

export const DecimalInput = props => {
  const {
    field: { displayedName, key, value: defaultValue, id },
  } = props;
  const classes = useStyles();
  const [inputValue, setInputValue] = useState(defaultValue ?? '');
  const { func: updateLoan, error, reset } = useDebouncedUpdate(LoansRepository.loansPartialUpdate);

  const handleInputChange = data => {
    const { value } = data;
    reset();
    setInputValue(value);
    updateLoan(id, { [key]: value });
  };

  return (
    <>
      <Typography className={classes.bodyBold}>{displayedName}</Typography>
      <div>
        <NumberFormat
          value={inputValue}
          decimalScale={2}
          onValueChange={handleInputChange}
          type="text"
          thousandSeparator=","
          customInput={Input}
          className={classes.input}
        />
        {error && <FormError message={error[key]} />}
      </div>
    </>
  );
};

DecimalInput.propTypes = {
  field: PropTypes.shape({
    displayedName: PropTypes.string,
    key: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.number,
  }),
};
