import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';

import useStyles from 'pages/LoanReconciliationInputs/useStyles';

export const CheckboxTableCell = props => {
  const { label, name, childrenProps, setCheckboxValue } = props;
  const classes = useStyles();

  const methods = useFormContext();
  const { control } = methods;
  const {
    field: { ref, value, onChange, ...inputProps },
  } = useController({
    name,
    control,
  });

  useEffect(() => {
    setCheckboxValue(value);
  }, [value]);

  return (
    <>
      <Checkbox
        name={name}
        className={classes.input}
        color="secondary"
        onChange={e => onChange(e.target.checked)}
        checked={value}
        inputRef={ref}
        {...inputProps}
        {...childrenProps}
      />
      <label htmlFor={name}>{label}</label>
    </>
  );
};

CheckboxTableCell.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  childrenProps: PropTypes.shape(),
  setCheckboxValue: PropTypes.func,
};
