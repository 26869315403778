import React, { useEffect, useMemo } from 'react';

import StickyBox from 'react-sticky-box/dist/esnext';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';
import { FormProvider, useForm } from 'react-hook-form';
import { any } from 'ramda';

import Loader from 'components/Loader';
import FormField from 'components/FormField';
import DocumentTitle from 'components/DocumentTitle';

import { useGetPoolsChoicesQuery } from 'domain/poolChoice/apiSlice';
import { useGetOrganizationsQuery } from 'domain/organization/apiSlice';
import { useGetAdministrativeAgentsQuery } from 'domain/administrativeAgent/apiSlice';
import { useGetManagersQuery } from 'domain/manager/apiSlice';
import { useGetOptionsQuery } from 'domain/options/apiSlice';
import * as OptionsService from 'domain/options/service';

import ContentLayout from 'layouts/ContentLayout';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import { setServerErrorsToFields } from 'utils/errors';
import { IdSerializer } from 'utils/serializer';
import { getEntityLastUpdated } from 'utils/date';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getOrganizationTypeValues } from 'utils/getOrganizationsType';

import { fieldArray, resolver } from './validation';
import useStyles from './useStyles';

const SuccessorBorrowerForm = props => {
  const {
    isEdit,
    defaultSuccessorBorrowerData,
    isDefaultDataLoading,
    submitButtonText,
    requestError,
    onSubmit,
    onCancel,
    previousRoute,
    title,
  } = props;

  const classes = useStyles();

  const methods = useForm({
    resolver,
  });

  const isFormDirty = methods.formState.isDirty;

  const { setError, errors } = methods;

  const { data: poolsChoicesData, isFetching: isPoolsChoicesLoading } = useGetPoolsChoicesQuery();
  const poolsChoices = useMemo(() => getSelectOptions(poolsChoicesData), [poolsChoicesData]);
  const { data: options, isFetching: isOptionsLoading } = useGetOptionsQuery(
    null,
    getOptionsQueryParams(),
  );
  const { data: registeredAgentsData, isFetching: isRegisteredAgentsOptionsLoading } =
    useGetOrganizationsQuery({
      organizationType: getOrganizationTypeValues(options).registeredAgent,
    });
  const registeredAgentsOptions = getSelectOptions(registeredAgentsData);

  const { data: providersData, isFetching: isProvidersOptionsLoading } = useGetOrganizationsQuery({
    organizationType: getOrganizationTypeValues(options).financialServices,
  });
  const providersOptions = getSelectOptions(providersData);

  const { data: administrativeAgentsData, isFetching: isAdministrativeAgentsOptionsLoading } =
    useGetAdministrativeAgentsQuery();
  const administrativeAgentOptions = getSelectOptions(administrativeAgentsData);

  const { data: managersData, isFetching: isManagersOptionsLoading } = useGetManagersQuery();
  const managerOptions = getSelectOptions(managersData);

  const stateSelectOptions = OptionsService.selectStateSelectOptions(options);

  const selectValues = {
    pools: poolsChoices,
    registeredAgents: registeredAgentsOptions,
    administrativeAgents: administrativeAgentOptions,
    managers: managerOptions,
    providers: providersOptions,
    states: stateSelectOptions,
  };

  useEffect(() => {
    if (requestError) {
      setServerErrorsToFields(setError, errors, requestError);
    }
  }, [requestError]);

  const handleSubmit = async values => {
    const mappingDict = [
      'cmbsPool',
      'parentCompany',
      'registeredAgent',
      'administrativeAgent',
      ['bankAccount', 'provider'],
    ];
    const serializedValues = IdSerializer(values, mappingDict);
    onSubmit(serializedValues);
  };

  const handleCancel = () => onCancel();

  const isRequestsLoading = [
    isDefaultDataLoading,
    isAdministrativeAgentsOptionsLoading,
    isOptionsLoading,
    isPoolsChoicesLoading,
    isRegisteredAgentsOptionsLoading,
    isProvidersOptionsLoading,
    isManagersOptionsLoading,
  ];

  const lastUpdated = getEntityLastUpdated(defaultSuccessorBorrowerData);

  if (any(loaderRequest => loaderRequest)(isRequestsLoading)) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <DocumentTitle title={title} isEdit={isEdit} />
      <StickyBox offsetTop={0} offsetBottom={10} className="sticky-box">
        <div className={classes.contentPanel}>
          <NavHashLink className={classes.backButton} exact to={previousRoute}>
            Back
          </NavHashLink>
          <div className={classes.panelWrap}>
            <Typography className={classes.contentTitle} variant="h1">
              {title}
            </Typography>
            <div className={classes.actionPanel}>
              <Button
                onClick={() => handleCancel()}
                className={classes.addItem}
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                disabled={!isFormDirty}
                onClick={methods.handleSubmit(handleSubmit)}
                className={classes.updateItem}
                variant="contained"
                color="secondary"
              >
                {submitButtonText}
              </Button>
            </div>
          </div>
        </div>
      </StickyBox>
      <ContentLayout>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            <FormField
              fieldArray={fieldArray}
              errors={methods.errors}
              values={defaultSuccessorBorrowerData}
              selectValues={selectValues}
            />
          </form>
          <StickyBox offsetTop={138} offsetBottom={10}>
            <div className={classes.contentSidebar}>
              {isEdit && (
                <div className={classes.lastUpdated}>
                  <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                  <div>{lastUpdated}</div>
                </div>
              )}
            </div>
          </StickyBox>
        </div>
      </ContentLayout>
    </FormProvider>
  );
};

SuccessorBorrowerForm.propTypes = {
  requestError: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  previousRoute: PropTypes.string,
  title: PropTypes.string,
  isEdit: PropTypes.bool,
  defaultSuccessorBorrowerData: PropTypes.shape({
    name: PropTypes.string,
  }),
  isDefaultDataLoading: PropTypes.bool,
  submitButtonText: PropTypes.string,
};

export default SuccessorBorrowerForm;
