import React, { useEffect, useState, useMemo } from 'react';

import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { Typography, debounce, TextField } from '@material-ui/core';
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import LoansRepository from 'repositories/LoansRepository';
import { keys, has, isEmpty, isNil, head } from 'ramda';

import FormError from 'components/FormError';
import Loader from 'components/Loader';

import { TimersConst } from 'const';

import useLoading from 'hooks/useLoading';
import { useErrors } from 'hooks/useErrors';

import MoodysLoanLookupPresenter, {
  moodysLookupOptionColumnConfig,
  getDisplayedName,
} from 'presenters/MoodysLoanLookupPresenter';

import { selectResultsFromResponse } from 'utils/objects';

import useStyles from './useStyles';

const getOptionLabel = option => option?.label || '';

const FIELD_KEY = 'text';
const PLACEHOLDER_TEXT = "Find Loan in Moody's by property name, address or loan id";

const SelectForPrepopulate = props => {
  const { onSubmit } = props;

  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isInputDirty, setInputDirty] = useState(false);
  const [fieldErrorMessage, setFieldErrorMessage] = useState(null);
  const { func: loadMoodysLoanLookup, isPending: isOptionsLoading } = useLoading(
    LoansRepository.moodysLoanLookup,
  );
  const { formatErrors, displayErrorsInToast } = useErrors();

  const showOptionsPopup = () => setOptionsOpen(true);
  const hideOptionsPopup = () => setOptionsOpen(false);
  const showCross = () => setSelectedOption('');
  const hideCross = () => setSelectedOption(null);

  useEffect(() => {
    if (!isEmpty(options)) {
      showOptionsPopup();
    }
  }, [options]);

  useEffect(() => {
    if (isInputDirty) {
      showCross();
    } else {
      hideCross();
      hideOptionsPopup();
    }
  }, [isInputDirty]);

  const searchCallback = async value => {
    if (!value) return;
    try {
      const response = await loadMoodysLoanLookup({ text: value });
      const items = selectResultsFromResponse(response);
      setOptions(MoodysLoanLookupPresenter.moodysLoanLookupOptions(items));
    } catch (e) {
      const {
        backendServicesError,
        fieldErrors: { text },
      } = formatErrors(e);
      displayErrorsInToast([backendServicesError]);
      const message = text ? head(text) : null;
      if (!isNil(message)) setFieldErrorMessage(message);
    }
  };

  const debounceOnType = useMemo(
    () => debounce(value => searchCallback(value), TimersConst.SEARCH_FIELD_DEBOUNCE_TIME),
    [],
  );

  const handleInputChange = e => {
    const { value } = e.target;
    const isValueEmpty = isEmpty(value);
    if (isValueEmpty) {
      setOptions([]);
    }
    setInputValue(value);
    setInputDirty(!isValueEmpty);
    debounceOnType(value);
  };

  const handleOpen = () => {
    if (!isEmpty(options)) {
      showOptionsPopup();
    }
  };

  const handleClose = () => {
    hideOptionsPopup();
  };

  const getInput = params => (
    <TextField
      {...params}
      InputProps={{
        ...params.InputProps,
        className: clsx(params.InputProps.className, classes.filterInput),
        placeholder: PLACEHOLDER_TEXT,
        endAdornment: (
          <>
            {isOptionsLoading && <Loader className={classes.loader} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      onChange={handleInputChange}
      autoComplete="off"
      size="small"
      variant="standard"
    />
  );

  const getOption = option => {
    const { label, data } = option;
    return (
      <div className={classes.optionContainer}>
        <Typography className={classes.optionHeader}>{label}</Typography>
        <Table
          classes={
            (classes.table,
            {
              root: classes.tableRoot,
            })
          }
          aria-label="simple table"
        >
          <TableBody>
            {keys(moodysLookupOptionColumnConfig).map(
              key =>
                has(key, data) && (
                  <TableRow key={key} classes={classes.tableRow}>
                    <TableCell
                      scope="row"
                      classes={(classes.tableCell, { root: classes.tableCellRoot })}
                    >
                      <Typography className={classes.optionKey}>{getDisplayedName(key)}</Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      classes={(classes.tableCell, { root: classes.tableCellRoot })}
                    >
                      <Typography className={classes.optionValue}>{data[key]}</Typography>
                    </TableCell>
                  </TableRow>
                ),
            )}
          </TableBody>
        </Table>
      </div>
    );
  };

  return (
    <div className={classes.filterContainer}>
      <Autocomplete
        name={FIELD_KEY}
        value={selectedOption}
        inputValue={inputValue}
        selectOnFocus
        size="small"
        limitTags={1}
        getOptionLabel={getOptionLabel}
        classes={{
          option: classes.option,
          listbox: classes.listbox,
        }}
        filterOptions={items => items}
        open={isOptionsOpen}
        options={options}
        renderInput={getInput}
        renderOption={getOption}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={(_, data, reason) => {
          if (reason === 'clear') {
            hideOptionsPopup();
            setInputValue('');
            hideCross();
            setInputDirty(false);
            setOptions([]);
          } else if (reason === 'select-option') {
            setSelectedOption(data);
            setInputValue(data.label);
            showOptionsPopup();
            onSubmit(data, reason);
          }
        }}
      />
      {fieldErrorMessage && <FormError message={fieldErrorMessage} />}
    </div>
  );
};

export default SelectForPrepopulate;

SelectForPrepopulate.propTypes = {
  onSubmit: PropTypes.func,
};
