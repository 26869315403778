import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  formLabel: {
    fontWeight: '700',
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
    color: 'rgba(19, 51, 76, 0.4)',
    textTransform: 'uppercase',
  },
  formInput: {
    marginTop: '4px',
  },
}));
