import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useQueryParam, StringParam } from 'use-query-params';

import DatePicker from 'components/DatePicker';

import { useMissingReconciliationReport } from 'hooks/api';

import { parseDate } from 'utils/date';
import { setServerErrorsToFields } from 'utils/errors';

import useStyles from './useStyles';
import { resolver, formField } from './validation';

export const ReportMonthInput = props => {
  const { onSubmit } = props;
  const classes = useStyles();
  const { reportLoadError } = useMissingReconciliationReport();
  const [yearMonth] = useQueryParam('yearMonth', StringParam);

  const methods = useForm({
    mode: 'onSubmit',
    resolver,
    defaultValues: {
      [formField.fieldKey]: parseDate('yyyy-MM')(yearMonth),
    },
  });

  const { errors, setError } = methods;

  useEffect(() => {
    if (reportLoadError) setServerErrorsToFields(setError, errors, reportLoadError);
  }, [reportLoadError]);

  return (
    <div className={classes.yearInput}>
      <FormProvider {...methods}>
        <DatePicker
          onSubmit={onSubmit}
          formField={formField}
          muiViewTypes={['month', 'year']}
          format="MM/yyyy"
        />
      </FormProvider>
    </div>
  );
};

ReportMonthInput.propTypes = {
  onSubmit: PropTypes.func,
};
