import { createSlice } from '@reduxjs/toolkit';
import BuyUpPendingRepository from 'repositories/BuyUpPendingRepository';

import { createRestSlice } from 'utils/RestSlice';

const buyUpPendingsRestSlice = createRestSlice({
  resource: 'buyUpPending',
  repository: BuyUpPendingRepository,
  slices: ['loadBuyUpPendings', 'updateBuyUpPending'],
});

const initialState = {
  ...buyUpPendingsRestSlice.initialState,
};

const buyUpPendingsSlice = createSlice({
  name: 'buyUpPendings',
  initialState,
  reducers: {
    ...buyUpPendingsRestSlice.reducers,
  },
});

export default buyUpPendingsSlice.reducer;
export const buyUpPendingsRestHooks = buyUpPendingsRestSlice.hooks(
  buyUpPendingsSlice,
  'BuyUpPendingsSlice',
);
