import React, { useRef } from 'react';

import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@material-ui/core';
import clsx from 'clsx';

import FormError from 'components/FormError';

import { DateConst } from 'const';

import { getFormFieldValue } from 'utils/getFormFieldValue';
import { formatDate } from 'utils/date';

import useStyles from './useStyles';

const REQUIRED = ' *';

const FormRowDatepicker = props => {
  const { field, errors, values, isColumnView, isRequired } = props;
  const classes = useStyles();
  const { control } = useFormContext();
  const { fieldKey, displayedName } = field;
  const inputRef = useRef(null);

  const value = getFormFieldValue(fieldKey, values)
    ? formatDate(getFormFieldValue(fieldKey, values))
    : '';

  const onFocus = () => {
    inputRef.current.focus();
  };

  return (
    <div
      className={clsx(classes.formRow, {
        [classes.divider]: field.hasMargin,
        [classes.columnView]: isColumnView,
      })}
      key={fieldKey}
    >
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </label>
      <div className={classes.inputWrap}>
        <Controller
          id={fieldKey}
          control={control}
          className={classes.formInput}
          name={fieldKey}
          value={value}
          defaultValue={value}
          margin="none"
          mask="99/99/9999"
          permanents={DateConst.PERMANENTS}
          alwaysShowMask={false}
          onFocus={onFocus}
          as={<InputMask>{inputProps => <Input {...inputProps} inputRef={inputRef} />}</InputMask>}
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowDatepicker.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
  }),
  errors: PropTypes.shape({}),
  values: PropTypes.shape({}),
  isColumnView: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default FormRowDatepicker;
