import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    contentForm: {
      width: 700,
      padding: 25,
    },
  }),
  { name: 'SharingArrangementDefeasedLoansReportPopup' },
);
