import { STATUS_COLUMN } from 'enums/statusColumn';

export const StatusConst = {
  [STATUS_COLUMN.active]: 'active',
  [STATUS_COLUMN.inactive]: 'inactive',
};

export const StatusDisplayedNameByCode = {
  [STATUS_COLUMN.active]: 'Active',
  [STATUS_COLUMN.inactive]: 'Inactive',
};
