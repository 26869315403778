import React from 'react';

import PropTypes from 'prop-types';

import SideNavLink from 'components/SideNavLink';

import useStyles from './useStyles';

export const SideBarLinks = props => {
  const { links, title } = props;

  const classes = useStyles();

  return (
    <>
      {Boolean(title) && <h2 className={classes.sidebarTitle}>{title}</h2>}
      <nav>
        {links.map(navItem => (
          <SideNavLink key={navItem.name} {...navItem} />
        ))}
      </nav>
    </>
  );
};

SideBarLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      name: PropTypes.string,
      nestedLinks: PropTypes.arrayOf(
        PropTypes.shape({
          to: PropTypes.string,
          name: PropTypes.string,
        }),
      ),
    }),
  ),
  title: PropTypes.string,
};
