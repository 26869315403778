import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    formWrap: {
      display: 'flex',
      flexFlow: 'row wrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: [0, 32],
    },
    formField: {
      width: '48%',
      marginRight: '2%',
    },
    fullRow: {
      widht: '48%',
      marginRight: '52%',
    },
    error: {
      color: 'red',
      margin: 0,
      padding: 0,
      display: 'block',
      alignSelf: 'flex-start',
    },
  }),
  { name: 'CIBCPopup' },
);
