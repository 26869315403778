import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableWrap: {
      marginBottom: '40px',
    },
    accountingTable: {
      '& thead th:nth-child(-n+3)': {
        textAlign: 'left',
      },
      '& thead th:nth-child(n+4)': {
        textAlign: 'right',
      },
      '& thead th:nth-child(9)': {
        whiteSpace: 'pre',
      },
      '& tbody td': {
        color: theme.palette.elephant,
      },
      '& tbody td:nth-child(3)': {
        maxWidth: '50ch',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
      '& tbody td:nth-child(n+4)': {
        textAlign: 'right',
      },
    },
    tableFooter: {
      background: theme.palette.athensGray,
      '& td:nth-child(n+4)': {
        textAlign: 'right',
      },
    },
    tableFooterFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    tableBottomCell: {
      padding: '12px',
    },
    fontColorMain: {
      color: theme.palette.elephant,
    },
  }),
  { name: 'AccountingTable' },
);
