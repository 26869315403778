import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format as formatDate } from 'date-fns';

import { ERROR_MESSAGES } from 'enums';

export const resolver = yupResolver(
  Yup.object({
    toDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.wrongFormat)
      .required(ERROR_MESSAGES.required)
      .nullable(),
    fromDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.wrongFormat)
      .required(ERROR_MESSAGES.required)
      .nullable(),
  }),
);

export const formFields = {
  fromDate: {
    displayedName: 'Date from',
    fieldKey: 'fromDate',
  },
  toDate: {
    displayedName: 'Date to',
    fieldKey: 'toDate',
  },
};

export const formFieldsKeys = Object.keys(formFields);

export const prepareForSend = data => ({
  toDate: formatDate(data.toDate, 'yyyy-MM-dd'),
  fromDate: formatDate(data.fromDate, 'yyyy-MM-dd'),
});
