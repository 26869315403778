import PropTypes from 'prop-types';
import { camelize } from 'humps';

import { formatCurrency } from 'utils/numbers';

const getStructureRates = (rates = []) =>
  rates.reduce(
    (acc, rate) => {
      acc.labels.push(rate.month);
      acc.twoYearsData.push(rate.twoYearsAgo);
      acc.lastYearData.push(rate.lastYear);
      acc.currentYearData.push(rate.thisYear);
      return acc;
    },
    { labels: [], twoYearsData: [], lastYearData: [], currentYearData: [] },
  );

export const getRates = (rates = []) => {
  const structuredRates = getStructureRates(rates);

  return {
    dataset: [
      {
        label: '2 Years Ago',
        data: structuredRates?.twoYearsData,
        backgroundColor: '#FFC90B',
      },
      {
        label: 'Last Year',
        data: structuredRates?.lastYearData,
        backgroundColor: '#4D95D8',
      },
      {
        label: 'This Year',
        data: structuredRates?.currentYearData,
        backgroundColor: '#EF476F',
      },
    ],
    labels: structuredRates.labels,
  };
};

const getSnapshot = data => data?.snapshot ?? {};

export const getGlobalSnapshotData = data => {
  const snapshot = getSnapshot(data);
  const loanCountByStatus = snapshot?.loanCountByStatus?.statuses.reduce((acc, item) => {
    const status = camelize(item.status);
    return { [status]: item.amount, ...acc };
  }, {});
  return {
    lists: [
      [
        {
          label: 'Total Successor Borrowers',
          value: snapshot?.successorBorrowers?.allSuccessorBorrowers,
        },
        {
          label: 'Active',
          value: snapshot?.successorBorrowers?.active,
        },
        {
          label: 'Inactive',
          value: snapshot?.successorBorrowers?.inactive,
        },
      ],
      [
        {
          label: 'Total Loans',
          value: snapshot?.loanCountByStatus?.total,
        },
        {
          label: 'Total In Progress',
          value: loanCountByStatus?.inProgress,
        },
        {
          label: 'Total Active In Payment',
          value: loanCountByStatus?.activeInPayment,
        },
        {
          label: 'Active In Payment CDHC',
          value: loanCountByStatus?.activeInPaymentCdhc,
        },
        {
          label: 'Active In Payment CDIH',
          value: loanCountByStatus?.activeInPaymentCdih,
        },
        {
          label: 'Total Inactive',
          value: loanCountByStatus?.inactive,
        },
        {
          label: 'Total Active Paid',
          value: loanCountByStatus?.activePaid,
        },
        {
          label: 'Total On Hold',
          value: loanCountByStatus?.indefinitelyOnHold,
        },
      ],
    ],
    summary: [
      {
        label: 'Unpaid Principal Balance',
        value: formatCurrency(snapshot?.unpaidPrincipalBalance),
      },
      {
        label: 'Outstanding Securities Balance',
        value: formatCurrency(snapshot?.outstandingSecuritiesBalance),
      },
      {
        label: 'Total Securities Cost',
        value: formatCurrency(snapshot?.securitiesCost),
      },
      {
        label: 'Total Original Loan Amount',
        value: formatCurrency(snapshot?.originalLoanAmount),
      },
      {
        label: 'Total Principal Balance at Time of Defeasance',
        value: formatCurrency(snapshot?.principalBalanceAtTimeOfDefeasance),
      },
    ],
  };
};

export const getYtdData = data => {
  const snapshot = getSnapshot(data);
  return {
    lists: [
      [
        {
          label: 'New Deals In YTD',
          values: [
            snapshot?.newDealsYearToDate?.current.yearToDate,
            snapshot?.newDealsYearToDate?.yearOverYear.yearToDate,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.yearToDate,
        },
        {
          label: 'This Month',
          values: [
            snapshot?.newDealsYearToDate?.current.month,
            snapshot?.newDealsYearToDate?.yearOverYear.month,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.month,
        },
        {
          label: 'This Week',
          values: [
            snapshot?.newDealsYearToDate?.current.week,
            snapshot?.newDealsYearToDate?.yearOverYear.week,
          ],
          diff: snapshot?.newDealsYearToDate?.difference.week,
        },
      ],
      [
        {
          label: 'Deals Closed YTD',
          values: [
            snapshot?.closedDealsYearToDate?.current.yearToDate,
            snapshot?.closedDealsYearToDate?.yearOverYear.yearToDate,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.yearToDate,
        },
        {
          label: 'This Month',
          values: [
            snapshot?.closedDealsYearToDate?.current.month,
            snapshot?.closedDealsYearToDate?.yearOverYear.month,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.month,
        },
        {
          label: 'This Week',
          values: [
            snapshot?.closedDealsYearToDate?.current.week,
            snapshot?.closedDealsYearToDate?.yearOverYear.week,
          ],
          diff: snapshot?.closedDealsYearToDate?.difference.week,
        },
      ],
    ],
  };
};

export const getTotalAssumedYTDData = data => {
  const snapshot = getSnapshot(data);
  return {
    lists: [
      [
        {
          label: 'Total Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.total],
          diff: null,
        },
        {
          label: 'DHC ONLY Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.dhcOnly],
          diff: null,
        },
        {
          label: 'CD Assumed YTD',
          values: [snapshot?.totalAssumedYearToDate?.other],
          diff: null,
        },
      ],
      [
        {
          label: 'Total In Progress',
          values: [snapshot?.totalInProgressYearToDate?.total],
          diff: null,
        },
        {
          label: 'DHC ONLY In Progress',
          values: [snapshot?.totalInProgressYearToDate?.dhcOnly],
          diff: null,
        },
        {
          label: 'Other',
          values: [snapshot?.totalInProgressYearToDate?.other],
          diff: null,
        },
      ],
    ],
  };
};

const commonStatsShape = PropTypes.shape({
  yearToDate: PropTypes.number,
  month: PropTypes.number,
  week: PropTypes.number,
});

export const globalSnapshotPropTypes = PropTypes.shape({
  snapshot: PropTypes.shape({
    closedDealsYearToDate: PropTypes.shape({
      current: commonStatsShape,
      difference: commonStatsShape,
      yearOverYear: commonStatsShape,
    }),
    loanCountByStatus: PropTypes.shape({
      statuses: PropTypes.arrayOf(
        PropTypes.shape({
          status: PropTypes.string,
          amount: PropTypes.number,
        }),
      ),
      total: PropTypes.number,
    }),
    loans: PropTypes.shape({
      activeInPayment: PropTypes.number,
      activePaid: PropTypes.number,
      allLoans: PropTypes.number,
      indefinitelyOnHold: PropTypes.number,
      inProgress: PropTypes.number,
      inactive: PropTypes.number,
      activeInPaymentCdhc: PropTypes.number,
      activeInPaymentCdih: PropTypes.number,
    }),
    newDealsYearToDate: PropTypes.shape({
      current: commonStatsShape,
      difference: commonStatsShape,
      yearOverYear: commonStatsShape,
    }),
    originalLoanAmount: PropTypes.number,
    outstandingSecuritiesBalance: PropTypes.number,
    principalBalanceAtTimeOfDefeasance: PropTypes.number,
    securitiesCost: PropTypes.number,
    successorBorrowers: PropTypes.shape({
      active: PropTypes.number,
      allSuccessorBorrowers: PropTypes.number,
      inactive: PropTypes.number,
    }),
    totalAssumedYearToDate: PropTypes.shape({
      dhcOnly: PropTypes.number,
      other: PropTypes.number,
      total: PropTypes.number,
    }),
    totalInProgressYearToDate: PropTypes.shape({
      dhcOnly: PropTypes.number,
      other: PropTypes.number,
      total: PropTypes.number,
    }),
    unpaidPrincipalBalance: PropTypes.number,
  }),
  rates: PropTypes.shape({
    assumeLoans: PropTypes.arrayOf(
      PropTypes.shape({
        lastYear: PropTypes.number,
        month: PropTypes.string,
        thisYear: PropTypes.number,
        twoYearsAgo: PropTypes.number,
      }),
    ),
    dhcOnlyDeals: PropTypes.arrayOf(
      PropTypes.shape({
        lastYear: PropTypes.number,
        month: PropTypes.string,
        thisYear: PropTypes.number,
        twoYearsAgo: PropTypes.number,
      }),
    ),
  }),
});
