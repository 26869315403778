import React, { useEffect } from 'react';

import PoolForm from 'components/PoolForm';

import { useUpdatePoolMutation, useGetPoolQuery } from 'domain/pool/apiSlice';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

const POOL_EDIT_TITLE = 'CMBS Pool Edit';
const UPDATE = 'Update';

const PoolEdit = () => {
  const {
    push,
    query: { id },
  } = useRouter();

  const previousRoute = appRoutes.poolPath(id);

  const {
    data: poolData,
    error: poolDataLoadError,
    isFetching: isPoolLoading,
  } = useGetPoolQuery(id);

  const [
    updatePool,
    {
      isSuccess: isUpdatePoolLoadSuccess,
      isLoading: isUpdatePoolLoading,
      error: updatePoolLoadError,
    },
  ] = useUpdatePoolMutation();

  useHandleRtkQueryErrors(poolDataLoadError);

  useEffect(() => {
    if (isUpdatePoolLoadSuccess) push(previousRoute);
  }, [isUpdatePoolLoadSuccess]);

  const handleEditButtonClick = dataToSend => updatePool({ poolId: id, pool: dataToSend });

  const handleCancelButtonClick = () => push(previousRoute);

  return (
    <RootLayoutV2>
      <PoolForm
        defaultPoolData={poolData}
        formSubmitError={updatePoolLoadError}
        isPoolLoading={isPoolLoading}
        isEdit
        isFormSubmitting={isUpdatePoolLoading}
        onCancel={handleCancelButtonClick}
        onSubmit={handleEditButtonClick}
        pageTitle={POOL_EDIT_TITLE}
        previousRoute={previousRoute}
        submitButtonText={UPDATE}
      />
    </RootLayoutV2>
  );
};

export default PoolEdit;
