import { createSlice } from '@reduxjs/toolkit';
import ManagersRepository from 'repositories/ManagersRepository';

import { createRestSlice } from 'utils/RestSlice';

const managersRestSlice = createRestSlice({
  resource: 'manager',
  repository: ManagersRepository,
  slices: ['loadManagers', 'loadManager', 'updateManager', 'createManager', 'deleteManager'],
});

const initialState = {
  ...managersRestSlice.initialState,
};

const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers: {
    ...managersRestSlice.reducers,
  },
});

export default managersSlice.reducer;
export const managersRestHooks = managersRestSlice.hooks(managersSlice, 'ManagersSlice');
