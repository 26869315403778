import { useSelector } from 'react-redux';

import { useCurrentUserActions, usersRestHooks } from 'store/UsersSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getSelectOptions } from 'utils/getSelectOptions';

export const useUsers = () => {
  const { users, loadUsers } = usersRestHooks.use();

  const usersOptions = getSelectOptions(users?.items, 'id', userLabel => userLabel.username);

  const { currentUser, loadingStatus } = useSelector(state => state.UsersSlice);

  const { loadCurrentUser } = useCurrentUserActions();

  return {
    users,
    usersOptions,
    loadUsers,
    loadCurrentUser,
    currentUser,
    isCurrentUserLoading: useFetchStatus(loadingStatus).isPending,
    isCurrentUserFulfilled: useFetchStatus(loadingStatus).isFulfilled,
    areUsersLoading: useFetchStatus(users?.loadingStatus).isPending,
  };
};
