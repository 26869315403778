import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  listWrapper: {
    border: `1px solid ${theme.palette.catskillWhite}`,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  notification: {
    color: theme.palette.primary.main,
  },
}));
