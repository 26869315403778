import React, { useEffect, useState, useMemo, useCallback } from 'react';

import PropTypes from 'prop-types';
import { useFormContext, useController } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';
import { isEmpty, isNil } from 'ramda';
import { Autocomplete } from '@material-ui/lab';
import { debounce, TextField } from '@material-ui/core';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import FormError from 'components/FormError';
import Loader from 'components/Loader';

import { TimersConst } from 'const';

import { useErrors } from 'hooks/useErrors';
import useLoading from 'hooks/useLoading';

import { selectResultsFromResponse } from 'utils/objects';
import {
  getDefaultOption,
  selectOptions as defaultSelectOptions,
  mergeOptions,
} from 'utils/selectAutocomplete';
import { getSentryOption, injectSentry, removeSentry } from 'utils/infiniteScrollUtils';
import { getNextPage } from 'utils/pagination';
import { isSuccessorBorrowersSearch } from 'utils/choicesSearch';

import useStyles from './useStyles';

const getOptionLabel = option => option?.label || '';

const REQUIRED = ' *';
const DEFAULT_NEXT_PAGE = 2;
const SEARCH = 'search';
const NAME = 'name';

export const FormRowSelectAutocomplete = props => {
  const {
    field: {
      fieldKey,
      displayedName,
      placeholder,
      fetchOptions,
      selectOptions = defaultSelectOptions,
    },
    values,
    isRequired,
    disabled = false,
  } = props;

  const defaultOption = useMemo(() => getDefaultOption(fieldKey, values), [values]);
  const { displayErrorsInToast, formatErrors } = useErrors();

  const classes = useStyles();
  const { control, errors } = useFormContext();

  const {
    field: { onChange, ref, onBlur },
  } = useController({
    control,
    name: fieldKey,
    defaultValue: defaultOption.value,
  });

  const {
    func: loadResource,
    isPending: isOptionsLoading,
    error: optionsLoadError,
  } = useLoading(fetchOptions);

  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState(defaultOption.label);

  const [searchString, setSearchString] = useState(undefined);

  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const [isInputDirty, setInputDirty] = useState(!isNil(defaultOption.label));

  const showCross = () => setSelectedOption('');
  const hideCross = () => setSelectedOption(null);

  const [nextPage, setNextPage] = useState(DEFAULT_NEXT_PAGE);
  const resetNextPage = () => setNextPage(DEFAULT_NEXT_PAGE);
  const hasMoreOptions = useMemo(() => !isNil(nextPage), [nextPage]);

  const getNext = response => response?.data?.next;

  const searchParams = isSuccessorBorrowersSearch(fieldKey) ? SEARCH : NAME;

  const loadOptions = async (params = null, currentSelectValue = null, shouldPreserve = false) => {
    try {
      const response = await loadResource(params);
      const next = getNextPage(getNext(response));
      setNextPage(next);
      const preparedOptions = selectOptions(selectResultsFromResponse(response));
      if (isEmpty(preparedOptions)) {
        setOptions(preparedOptions);
        return;
      }
      const withDefault = !isNil(currentSelectValue)
        ? [currentSelectValue, ...preparedOptions]
        : preparedOptions;
      const withSentry = injectSentry(withDefault, getSentryOption());
      const result = shouldPreserve ? mergeOptions(removeSentry(options), withSentry) : withSentry;
      setOptions(result);
    } catch (e) {
      const { backendServicesError, nonFieldErrors } = formatErrors(e);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  };

  useEffect(() => {
    loadOptions(null, defaultOption);
  }, []);

  useEffect(() => {
    if (isInputDirty) {
      showCross();
    } else {
      hideCross();
    }
  }, [isInputDirty]);

  const loadNextOptions = async () =>
    loadOptions(
      {
        [searchParams]: searchString,
        page: nextPage,
      },
      null,
      true,
    );

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: isOptionsLoading,
    hasNextPage: hasMoreOptions,
    onLoadMore: loadNextOptions,
    disabled: Boolean(optionsLoadError),
  });

  const debounceOnInputValueChange = useCallback(
    debounce(value => {
      setSearchString(isEmpty(value) ? undefined : value);
      setInputValue(value);
      resetNextPage();
      loadOptions({
        [searchParams]: isEmpty(value) ? undefined : value,
        page: 1,
      });
    }, TimersConst.SEARCH_FIELD_DEBOUNCE_TIME),
    [],
  );

  const handleInputChange = e => {
    const { value } = e.target;
    setInputValue(value);
    setInputDirty(!isEmpty(value));
    debounceOnInputValueChange(value);
  };

  const getInput = params => (
    <TextField
      {...params}
      inputRef={ref}
      InputProps={{
        ...params.InputProps,
        className: clsx(params.InputProps.className, classes.filterInput),
        placeholder,
        endAdornment: (
          <>
            {isOptionsLoading && <Loader className={classes.loader} />}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
      onChange={handleInputChange}
      autoComplete="off"
      size="small"
      variant="standard"
    />
  );

  const renderSentryComponent = useCallback(
    () => <div className={classes.sentry} ref={sentryRef} />,
    [sentryRef, nextPage],
  );

  const getOption = option => {
    if (!option.isSentry) {
      return <p className={classes.listBoxItem}>{option.label}</p>;
    }
    if (option.isSentry && hasMoreOptions) {
      return <div className={classes.filterOption}>{renderSentryComponent()}</div>;
    }
    return null;
  };

  return (
    <div className={classes.rowContainer}>
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </label>

      <div className={classes.inputWrap}>
        <Autocomplete
          id={fieldKey}
          name={fieldKey}
          value={selectedOption}
          inputValue={inputValue}
          loading={isOptionsLoading}
          selectOnFocus
          disabled={disabled}
          size="small"
          limitTags={1}
          ListboxProps={{ ref: rootRef }}
          getOptionLabel={getOptionLabel}
          className={classes.select}
          classes={{
            option: classes.option,
            listbox: classes.listbox,
          }}
          filterOptions={item => item}
          options={options}
          renderInput={getInput}
          renderOption={getOption}
          onBlur={onBlur}
          onChange={(_, data, reason) => {
            if (reason === 'clear') {
              setInputValue('');
              hideCross();
              setInputDirty(false);
              onChange(null);
              loadOptions();
            } else if (reason === 'select-option') {
              setSelectedOption(data);
              setInputValue(data.label);
              onChange(data.value);
            }
          }}
        />
        {optionsLoadError && <FormError message={optionsLoadError} />}
        <ErrorMessage
          errors={errors}
          name={`${fieldKey}Id`}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowSelectAutocomplete.propTypes = {
  onSubmit: PropTypes.func,
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    placeholder: PropTypes.string,
    fetchOptions: PropTypes.func,
    selectOptions: PropTypes.func,
  }),
  values: PropTypes.shape({}),
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};
