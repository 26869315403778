import React from 'react';

import { isNil } from 'ramda';
import { List, ListItem, Typography, Link, Paper } from '@material-ui/core';
import clsx from 'clsx';

import PaginationPanel from 'components/PaginationPanel';

import { useMissingReconciliationReport } from 'hooks/api';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const VIEW_DETAILS = 'View Details';
const ZERO_COUNT = 0;

export const ReportList = () => {
  const classes = useStyles();
  const {
    missingReconciliationReport: report,
    pagination: { count },
  } = useMissingReconciliationReport();

  if (isNil(report)) return null;

  return (
    <>
      <Paper elevation={2} className={classes.listContainer}>
        <List className={classes.list}>
          {report.map(item => {
            const { id, dealName } = item;
            return (
              <ListItem divider className={classes.listItem} key={id}>
                <Typography className={classes.listItemFont}>{`(${id}) ${dealName}`}</Typography>
                <Link
                  href={appRoutes.loanReconciliationInputsPath(id)}
                  color="secondary"
                  className={clsx(classes.listItemFont, classes.link)}
                >
                  {VIEW_DETAILS}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Paper>
      <PaginationPanel itemsCount={count ?? ZERO_COUNT} />
    </>
  );
};
