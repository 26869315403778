import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    listWrapper: {
      border: `1px solid ${theme.palette.catskillWhite}`,
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
    listHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.catskillWhite}`,
      paddingTop: '12px',
      paddingRight: '16px',
      paddingBottom: '12px',
      paddingLeft: '16px',
    },
    header: {
      alignSelf: 'center',
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(21),
      color: theme.palette.elephant,
    },
    smallText: {
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      textAlign: 'end',
      color: 'rgba(0, 0, 0, 0.4)',
    },
    fontBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.elephant,
    },
  }),
  { name: 'reportTable' },
);
