import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentWrap: {
      paddingTop: 0,
    },
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row wrap',
    },
    search: {
      marginRight: 8,
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
    tableContent: {
      marginTop: 16,
    },
    chevronIcon: {
      width: 24,
      height: 24,
      lineHeight: 0,
      fontSize: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'transform .2s ease',
    },
    chevronIconRotated: {
      transform: 'rotateZ(180deg)',
    },
    filtersBox: {
      display: 'flex',
      height: 56,
      width: '100%',
      marginTop: 20,
    },
    filtersForm: {
      display: 'flex',
      flexFlow: 'row nowrap',
      width: '100%',
    },
    optionTitle: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      margin: 0,
      marginBottom: 8,
      textTransform: 'uppercase',
      opacity: '.4',
    },
    filterContainer: {
      display: 'block',
      maxWidth: 320,
      width: '100%',
      position: 'relative',
      marginRight: 24,
    },
    filterContainerMultiple: {
      minWidth: 272,
    },
    filterTag: {
      padding: [1, 4],
      height: 21,
      backgroundColor: 'rgba(19, 51, 76, 0.05)',
      borderRadius: 2,
      display: 'flex',
      alignItems: 'center',
      maxWidth: 200,

      '& > span': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 144,
        lineClamp: 1,
        overflow: 'hidden',
      },
    },
    filterTagButton: {
      width: 16,
      height: 16,
      minWidth: 16,
      borderRadius: 8,
      backgroundColor: 'rgba(19, 51, 76, 0.05)',
      marginLeft: 4,
    },
    filterOption: {
      padding: [2, 8],
      display: 'flex',
      alignItems: 'center',
    },
    filterAutocomplete: {
      position: 'absolute',
      width: '100%',
    },
    filterInput: {
      paddingTop: 3,
      minHeight: 36,
    },
    filterInputMultipleTags: {
      height: 'auto',
    },
    filterButton: {
      alignSelf: 'flex-end',
      whiteSpace: 'nowrap',
      minWidth: 100,
    },
    filterCheckbox: {
      margin: 0,
      padding: '0 8px 0 0',
    },
  }),
  { name: 'Loans' },
);
