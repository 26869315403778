import queryString from 'query-string';
import humps from 'humps';
import { last, split } from 'ramda';

const createQueryString = queryStringObject => {
  const decamelized = humps.decamelizeKeys(queryStringObject, { separator: '-' });
  return queryString.stringify(decamelized);
};

export const parseQueryString = string => {
  const data = queryString.parse(string);
  return humps.camelizeKeys(data);
};

export const createURL = params => {
  const { path, searchParams } = params;
  const queryStr = createQueryString(searchParams);
  return `${path}?${queryStr}`;
};

export const isPdfUrl = url => last(split('.', new URL(url).pathname)) === 'pdf';

export const getNameFromUrl = url => last(new URL(url).pathname.split('/'));
