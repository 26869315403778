import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { isEmpty } from 'ramda';
import clsx from 'clsx';

import { useDataValidationReport } from 'hooks/api/useDataValidationReport';

import ErrorSection from 'pages/DataValidationReport/components/ErrorSection';

import useStyles from './useStyles';

const NO_ERRORS = 'No Errors';
const Report = () => {
  const classes = useStyles();
  const { navLinks, dataValidationReport } = useDataValidationReport();

  return (
    <>
      {isEmpty(dataValidationReport) && (
        <p className={clsx(classes.text, classes.fontColorMain)}>{NO_ERRORS}</p>
      )}
      <ul className={classes.linksContainer}>
        {navLinks.map((link, index) => {
          const { path, title } = link;
          return (
            <li className={clsx(classes.text, classes.links)} key={index}>
              <NavHashLink to={path}>{title}</NavHashLink>
            </li>
          );
        })}
      </ul>
      {dataValidationReport.map((item, index) => (
        <ErrorSection dataItem={item} key={index} />
      ))}
    </>
  );
};

export default Report;
