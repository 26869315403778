import BuyUpPendingPresenter from 'presenters/BuyUpPendingPresenter';

import { buyUpPendingsRestHooks } from 'store/BuyUpPendingsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useBuyUpPending = () => {
  const { buyUpPendings, loadBuyUpPendings, updateBuyUpPending } = buyUpPendingsRestHooks.use();
  const buyUpPendingTableData = BuyUpPendingPresenter.tableData(buyUpPendings?.items) || [];
  const buyUpPendingTableKeys = BuyUpPendingPresenter.tableKeys();
  const totalAmount = BuyUpPendingPresenter.totalAmount(buyUpPendings?.items);
  const totalDifference = BuyUpPendingPresenter.totalDifference(buyUpPendings?.items);

  return {
    buyUpPendings,
    loadBuyUpPendings,
    updateBuyUpPending,
    buyUpPendingTableData,
    buyUpPendingTableKeys,
    totalAmount,
    totalDifference,
    isBuyUpPendingLoading: useFetchStatus(buyUpPendings.loadingStatus).isPending,
  };
};
