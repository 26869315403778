import React, { useEffect, useState } from 'react';

import { Typography, Button } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import Popup from 'components/Popup';

import { useFundingNotes } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';
import { useEditableTableControls, EditableTableProvider } from 'hooks/useEditableTableControls';

import ContentLayout from 'layouts/ContentLayout';

import useStyles from './useStyles';
import FundingNotesTable from './components/FundingNotesTable';

const TITLE = 'Funding Notes';
const BUTTON_TEXT = 'Add Funding Note';
const SAFEGUARD_POPUP_TITLE = 'Remove Funding Note';
const SAFEGUARD_POPUP_MESSAGE = `You are about to delete the funding note.
This action won’t be canceled and all the data will be lost.
Are you sure?`;
const SAFEGUARD_CONFIRM_DELETE_BUTTON = 'Yes, save with no note';
const SAFEGUARD_CANCEL_BUTTON = 'Cancel';

export const FundingNotes = () => {
  const {
    loadFundingNotes,
    areFundingNotesLoadingFinished,
    loadFundingNotesTotal,
    deleteFundingNote,
    fundingNotesErrors,
  } = useFundingNotes();
  const classes = useStyles();
  const { displayErrorsInToast, formatErrors } = useErrors();

  const tableControls = useEditableTableControls();
  const { showCreatingForm, hideEditableRow } = tableControls;

  const [isSafeguardPopupOpen, setSafeguardPopupOpen] = useState(false);
  const [rowIdForDelete, setRowIdForDelete] = useState(null);
  const showSafeguardPopup = () => setSafeguardPopupOpen(true);
  const hideSafeguardPopup = () => setSafeguardPopupOpen(false);

  const handleAddButton = () => {
    hideEditableRow();
    showCreatingForm();
  };

  const fetchData = async () => {
    try {
      await loadFundingNotes();
      await loadFundingNotesTotal();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (fundingNotesErrors) {
      const { nonFieldErrors } = formatErrors(fundingNotesErrors);
      displayErrorsInToast([nonFieldErrors]);
    }
  }, [fundingNotesErrors]);

  const handleClosePopup = () => {
    hideSafeguardPopup();
  };

  const handlePopupDeleteClick = async () => {
    try {
      await deleteFundingNote(rowIdForDelete);
      fetchData();
    } catch (e) {
      // no action
    }
    hideSafeguardPopup();
  };

  const handleDeleteLineClick = rowId => () => {
    showSafeguardPopup();
    setRowIdForDelete(rowId);
  };

  return (
    <>
      <ContentLayout className={classes.contentWrap}>
        <DocumentTitle title={TITLE} />
        <div className={classes.headerPanel}>
          <Typography variant="h1">{TITLE}</Typography>
          <Button color="primary" className={classes.headerButton} onClick={handleAddButton}>
            {BUTTON_TEXT}
          </Button>
        </div>
        {areFundingNotesLoadingFinished && (
          <EditableTableProvider {...tableControls}>
            <FundingNotesTable
              handleDeleteLineClick={handleDeleteLineClick}
              reloadData={fetchData}
            />
          </EditableTableProvider>
        )}
      </ContentLayout>
      {isSafeguardPopupOpen && (
        <Popup
          title={SAFEGUARD_POPUP_TITLE}
          onClose={handleClosePopup}
          submitButtonText={SAFEGUARD_CONFIRM_DELETE_BUTTON}
          cancelText={SAFEGUARD_CANCEL_BUTTON}
          onSubmit={handlePopupDeleteClick}
          toggleState={setSafeguardPopupOpen}
        >
          <div className={classes.popupInner}>
            <span className={classes.popupText}>{SAFEGUARD_POPUP_MESSAGE}</span>
          </div>
        </Popup>
      )}
    </>
  );
};
