import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    breadcrumbs: {
      marginTop: '20px',
      '& p': {
        margin: 0,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '50ch',
      },
    },
    font: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
    },
    inactive: {
      color: 'rgba(19, 51, 76, 0.5)',
    },
  }),
  { name: 'Breadcrumbs' },
);
