import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    section: {
      marginBottom: 32,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    tableContainer: {
      marginBottom: 16,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    contentSubtitle: {
      margin: [8, 0],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.primary.main,
      opacity: 0.5,
      letterSpacing: '0.02em',
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: 0,
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      padding: [32, 24],
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
      background: theme.palette.white,
    },
    panelInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    panelActive: {
      backgroundColor: theme.palette.feta,
    },
    panelActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    link: {
      marginRight: '20px',
    },
    editButton: {
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',

      '& span': {
        opacity: 0.75,
      },
    },
    detailWrap: {
      display: 'flex',
      justifyContent: 'start',
      flexFlow: 'row nowrap',
    },
    tableWrap: {
      width: '100%',
      maxWidth: 1000,
    },
    detailTitle: {
      paddingTop: 16,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      marginTop: 0,
      marginBottom: 16,
      fontWeight: 'normal',
    },
    cancelBtn: {
      marginRight: '8px',
    },
    contentHeader: {
      display: 'flex',
      width: '1000px',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    hint: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: '0.4',
    },
  }),
  { name: 'LoanEscrowAccount' },
);
