import React from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';

import FormError from 'components/FormError';

import useStyles from './useStyles';

const FormRowCheckbox = ({ field, errors }) => {
  const classes = useStyles();
  const { control } = useFormContext();
  const { fieldKey, displayedName } = field;

  return (
    <div className={classes.formRow} key={fieldKey}>
      <div className={classes.inputWrap}>
        <Controller
          name={fieldKey}
          control={control}
          render={({ value, onChange }) => (
            <Checkbox onChange={e => onChange(e.target.checked)} checked={value} />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
      </label>
    </div>
  );
};

FormRowCheckbox.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
  }),
  errors: PropTypes.shape({}),
};

export default FormRowCheckbox;
