import React, { useEffect, useState } from 'react';

import ToolsRepository from 'repositories/ToolsRepository';
import PropTypes from 'prop-types';

import Popup from 'components/Popup';
import ToolsImport from 'components/ToolsImport';
import NonFieldError from 'components/NonFieldError';

import { useImportPolling } from 'hooks/useImportPolling';
import { useSnackbar } from 'hooks/useSnackbar';

import { formatDateToMonthYear } from 'utils/date';
import FetchHelpers from 'utils/FetchHelpers';

import MonthInput from './components/MonthInput';

const INPUT_NAME = 'reconciliationYearMonth';

const defaultImportState = {
  files: [],
  [INPUT_NAME]: formatDateToMonthYear(new Date()),
  error: null,
};

const ReconciliationImportPopup = props => {
  const { setPopupState } = props;

  const [importData, setImportData] = useState(defaultImportState);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isTaskImportStatusSuccess,
    isTaskImportStatusFailure,
    isTaskImportStatusStarted,
    taskStatus,
    taskError,
    startImportPolling,
  } = useImportPolling({ requestMethod: FetchHelpers.getRaw });

  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  const setErrors = err => {
    setIsLoading(false);
    setImportData({
      ...importData,
      error: { ...importData.error, ...err },
    });
  };

  useEffect(() => {
    if (isTaskImportStatusSuccess) {
      setPopupState(false);
      showSuccessNotification('File Successfully Imported');
    }
    if (isTaskImportStatusFailure) {
      setErrors(taskError);
      showErrorNotification('File import failure');
    }
  }, [taskStatus]);

  const handleImportSubmit = () => {
    if (importData.files.length > 0) {
      const reader = new FileReader();
      reader.onload = async () => {
        setIsLoading(true);
        try {
          const response = await ToolsRepository.excelImportReconciliationInputs({
            file: importData.files[0],
            [INPUT_NAME]: importData.reconciliationYearMonth,
          });
          await startImportPolling(response.headers.location);
        } catch (e) {
          setErrors(e);
          showErrorNotification('File import failure');
        }
      };
      reader.readAsArrayBuffer(importData.files[0]);
    }
  };

  const handleImportClose = () => {
    setImportData(defaultImportState);
  };

  const nonFieldErrors = importData.error?.nonFieldErrors;

  return (
    <Popup
      title="Import File"
      submitButtonText="Import File"
      toggleState={setPopupState}
      onSubmit={handleImportSubmit}
      onClose={handleImportClose}
      isLoading={isLoading || isTaskImportStatusStarted}
    >
      <MonthInput importData={importData} setImportData={setImportData} name={INPUT_NAME} />
      <ToolsImport importData={importData} setImportData={setImportData} />
      {nonFieldErrors && <NonFieldError error={nonFieldErrors[0]} />}
    </Popup>
  );
};

ReconciliationImportPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default ReconciliationImportPopup;
