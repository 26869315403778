import { CACHE_TAG } from 'enums';

import { apiContainer } from 'store';

import { selectLocationFromResponse, selectDataFromResponse } from 'utils/objects';

export const reportApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    initiateReportTask: builder.mutation({
      query: ({ url, data, params }) => ({
        method: 'POST',
        url,
        data,
        params,
      }),
      transformResponse: selectLocationFromResponse,
    }),
    getReportData: builder.query({
      query: ({ url }) => ({
        url,
        method: 'GET',
      }),
      transformResponse: response => ({
        ...selectDataFromResponse(response),
        location: selectLocationFromResponse(response),
      }),
      providesTags: [CACHE_TAG.report],
    }),
  }),
});

export const { useInitiateReportTaskMutation, useGetReportDataQuery } = reportApi;
