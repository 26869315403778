import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag, invalidateOnSuccess } from 'utils/rtkQuery';

const tagsToInvalidate = [
  CACHE_TAG.reconciliationInput,
  CACHE_TAG.reconciliationInputsSummary,
  CACHE_TAG.reconciliationInputsAvailableYears,
];

export const reconciliationInputApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getReconciliationInputs: builder.query({
      query: params => ({
        url: apiRoutes.reconciliationInputsPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.reconciliationInput),
    }),
    createReconciliationInput: builder.mutation({
      query: data => ({
        url: apiRoutes.reconciliationInputsPath(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    updateReconciliationInput: builder.mutation({
      query: ({ data, id }) => ({
        url: apiRoutes.reconciliationInputPath(id),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    deleteReconciliationInput: builder.mutation({
      query: id => ({
        url: apiRoutes.reconciliationInputPath(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOnSuccess(tagsToInvalidate),
    }),
    getReconciliationInputsSummary: builder.query({
      query: id => ({
        url: apiRoutes.loansReconciliationInputsSummaryPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.reconciliationInputsSummary],
    }),
    getReconciliationInputsAvailableYears: builder.query({
      query: id => ({
        url: apiRoutes.loansReconciliationInputsAvailableYearsPath(id),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: [CACHE_TAG.reconciliationInputsAvailableYears],
    }),
  }),
});

export const {
  useGetReconciliationInputsQuery,
  useGetReconciliationInputsSummaryQuery,
  useGetReconciliationInputsAvailableYearsQuery,
  useCreateReconciliationInputMutation,
  useUpdateReconciliationInputMutation,
  useDeleteReconciliationInputMutation,
} = reconciliationInputApi;
