import React, { useMemo } from 'react';

import { isNil } from 'ramda';

import SideNavLink from 'components/SideNavLink';
import LoansByStatusLinksList from 'components/LoansByStatusLinksList';

import { useGetLoansCountsByStatusQuery } from 'domain/loansCountsByStatus/apiSlice';

import { useLoansStatus } from 'hooks/useLoansStatus';
import useRouter from 'hooks/useRouter';

import { appRoutes } from 'router/routes';

import {
  createSuccessorBorrowerLoansNavLinks,
  createSuccessorBorrowerDetailsLink,
  createNewDealLink,
} from 'utils/routes';

import useStyle from './useStyles';

export const SuccessorBorrowerSideNav = () => {
  const {
    matchPath,
    match,
    push,
    query: { id: successorBorrowerId },
  } = useRouter();

  const { data: loansCountsByStatus } = useGetLoansCountsByStatusQuery({ successorBorrowerId });

  const { url: currentUrl } = match();

  const isSuccessorBorrowerLoansPath = !isNil(
    matchPath(currentUrl, { path: appRoutes.successorBorrowerLoansPath(':id') }),
  );

  const classes = useStyle();

  const { setLoansStatus } = useLoansStatus();

  const handleLoanStatusLinkClick = status => {
    if (!isSuccessorBorrowerLoansPath) {
      push(appRoutes.successorBorrowerLoansPath(successorBorrowerId));
    }
    setLoansStatus(status, { shouldResetPage: true });
  };

  const loansNavLinks = useMemo(
    () => createSuccessorBorrowerLoansNavLinks(successorBorrowerId, loansCountsByStatus),
    [loansCountsByStatus],
  );

  const detailsNavLink = createSuccessorBorrowerDetailsLink(successorBorrowerId);

  return (
    <>
      <SideNavLink {...detailsNavLink} />
      <LoansByStatusLinksList
        loansNavLinks={loansNavLinks}
        onClick={handleLoanStatusLinkClick}
        isActiveLink={isSuccessorBorrowerLoansPath}
        className={classes.linksList}
      />
      <div className={classes.newDealLink}>
        <SideNavLink {...createNewDealLink(successorBorrowerId)} />
      </div>
    </>
  );
};
