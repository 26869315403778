import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { format as formatDate } from 'date-fns';

import { ERROR_MESSAGES } from 'enums';

export const resolver = yupResolver(
  Yup.object({
    fundingDate: Yup.date()
      .typeError(ERROR_MESSAGES.date.invalidDate)
      .required(ERROR_MESSAGES.required)
      .nullable(),
  }),
);

export const formField = {
  displayedName: 'Choose the Date',
  fieldKey: 'fundingDate',
};

export const prepareForSend = data => ({
  fundingDate: formatDate(data.fundingDate, 'yyyy-MM-dd'),
});
