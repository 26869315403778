import PropTypes from 'prop-types';

import { StatusDisplayedNameByCode } from 'const';

import Presenter from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';

export const membersColumnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
  },
  name: {
    displayedName: 'Company Name',
    isLink: true,
  },
  status: {
    displayedName: 'Status',
  },
  ein: {
    displayedName: 'Ein',
  },
  secretaryOfStateId: {
    displayedName: 'SOS Id',
  },
  sosAcceptedDate: {
    displayedName: 'SOS Date',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    filingRef: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.number,
    ein: PropTypes.string,
    sosAcceptedDate: PropTypes.date,
    secretaryOfStateId: PropTypes.string,
  },
  {
    name(member) {
      return member?.name || '';
    },
    tableKeys() {
      return Object.keys(membersColumnConfig);
    },
    tableData(list) {
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
          status: StatusDisplayedNameByCode[rowData.status],
          sosAcceptedDate: formatDate(rowData.sosAcceptedDate),
        },
      }));
    },
  },
);
