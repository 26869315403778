import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import { isNil, isEmpty } from 'ramda';
import clsx from 'clsx';

import Loader from 'components/Loader';

import { FIELD_ALIGNMENT } from 'enums';

import { useRefTableContainerHeight } from 'hooks/useRefTableContainerHeight';

import { sortTableDataByKey } from 'utils/sorting';

import TableBodyContent from './components/TableBodyContent';
import TableHeadContent from './components/TableHeadContent';
import useStyles from './useStyles';

const ListTable = ({
  tableHeader,
  isLoading,
  columnConfig,
  tableData = [],
  detailPath,
  onEdit,
  tableClasses,
  selectValues,
  defaultSortingField,
  defaultSortingDirection,
  isInnerScrollable = false,
  isOuterScrollable = false,
  isMiniView,
  tableBottomContent,
  tableFooterContent,
  isPaginationEnabled,
  fixedTopOffset,
}) => {
  const classes = useStyles();

  const [sortedBy, setSortedBy] = useState(defaultSortingField);
  const [sortingDirection, setSortingDirection] = useState(defaultSortingDirection);

  const [tableDataToRender, setTableDataToRender] = useState(null);

  const { tableContentRef, tableContainerHeight } = useRefTableContainerHeight(isInnerScrollable);

  useEffect(() => {
    if (isPaginationEnabled) {
      setTableDataToRender(tableData);
    }
  }, [tableData, isLoading]);

  useEffect(() => {
    if (isPaginationEnabled) return;
    if (isNil(sortedBy) || isNil(sortingDirection)) {
      setTableDataToRender(tableData);
    } else {
      setTableDataToRender(sortTableDataByKey(tableData, sortedBy, sortingDirection));
    }
  }, [sortedBy, sortingDirection, tableData]);

  const renderTable = () => (
    <Table
      className={clsx(tableClasses, {
        [classes.table]: isOuterScrollable,
        [classes.tableBorders]: isOuterScrollable,
      })}
    >
      <TableHeadContent
        tableHeader={tableHeader}
        columnConfig={columnConfig}
        isInnerScrollable={isInnerScrollable}
        isMiniView={isMiniView}
        isPaginationEnabled={isPaginationEnabled}
        fixedTopOffset={fixedTopOffset}
        sortedBy={sortedBy}
        setSortedBy={setSortedBy}
        sortingDirection={sortingDirection}
        setSortingDirection={setSortingDirection}
        setTableDataToRender={setTableDataToRender}
      />
      <TableBodyContent
        tableHeader={tableHeader}
        columnConfig={columnConfig}
        detailPath={detailPath}
        onEdit={onEdit}
        selectValues={selectValues}
        tableBottomContent={tableBottomContent}
        tableDataToRender={tableDataToRender}
      />
      {tableFooterContent && <TableFooter>{tableFooterContent}</TableFooter>}
    </Table>
  );

  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  if (isNil(tableDataToRender) || isEmpty(tableDataToRender)) return null;

  if (isOuterScrollable) {
    return renderTable();
  }

  return (
    <div className={classes.tableContent} ref={tableContentRef}>
      <TableContainer
        component={Paper}
        className={clsx({ [classes.tableContainerScrollable]: isInnerScrollable })}
        style={{ maxHeight: isInnerScrollable ? tableContainerHeight : 'none' }}
      >
        {renderTable()}
      </TableContainer>
    </div>
  );
};

ListTable.propTypes = {
  tableHeader: PropTypes.arrayOf(PropTypes.string),
  isLoading: PropTypes.bool,
  columnConfig: PropTypes.objectOf(
    PropTypes.shape({
      align: PropTypes.oneOf([FIELD_ALIGNMENT.right, FIELD_ALIGNMENT.left]),
      displayedName: PropTypes.string,
      sortingEnabled: PropTypes.bool,
      isLink: PropTypes.bool,
      withTags: PropTypes.bool,
      canBeHidden: PropTypes.bool,
      editable: PropTypes.bool,
      editableFieldType: PropTypes.string,
    }),
  ),
  tableData: PropTypes.arrayOf(PropTypes.shape()),
  detailPath: PropTypes.func,
  tableClasses: PropTypes.string,
  onEdit: PropTypes.func,
  selectValues: PropTypes.objectOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  defaultSortingField: PropTypes.string,
  defaultSortingDirection: PropTypes.string,
  isInnerScrollable: PropTypes.bool,
  isMiniView: PropTypes.bool,
  tableBottomContent: PropTypes.element,
  tableFooterContent: PropTypes.element,
  isPaginationEnabled: PropTypes.bool,
  isOuterScrollable: PropTypes.bool,
  fixedTopOffset: PropTypes.number,
};

export default ListTable;
