import React, { useMemo } from 'react';

import clsx from 'clsx';
import StickyBox from 'react-sticky-box/dist/esnext';
import { NavHashLink } from 'react-router-hash-link';
import {
  Button,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Link,
} from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import CellServicer from 'components/CellServicer';
import CellInformation from 'components/CellInformation';
import BackgroundFillByStatus from 'components/BackgroundFillByStatus';
import LoanHeader from 'components/LoanHeader';

import { ContactFieldLabels } from 'const';

import { useGetLoanQuery } from 'domain/loan/apiSlice';
import { LoanService } from 'domain/loan/service';
import { getDisplayValue } from 'domain/loan/presenter';
import { useInitiateReportTaskMutation } from 'domain/report/apiSlice';

import { LOAN_HASH } from 'enums';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';
import { useSnackbar } from 'hooks/useSnackbar';
import { useScrollingContext } from 'hooks/useScrolling';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';
import { usePollingQuery } from 'hooks/usePollingQuery';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { isStatusActiveInPayment, isStatusActivePaid } from 'utils/layoutStatus';
import { loanReportOptions, reportActions } from 'utils/reports';
import { loanDetailLinks as createLoanDetailLinks } from 'utils/routes';

import useStyles from './useStyles';

const EDIT_PROPERTY = 'Edit Property';
const OID_BALANCE = 'OID Balance';
const SUCCESSFULLY_GENERATED = 'Successfully Generated';

export const Loan = () => {
  const classes = useStyles();

  const { displayErrorsInToast, formatErrors } = useErrors();

  const { showSuccessNotification } = useSnackbar();

  const {
    query: { id },
  } = useRouter();

  const { setHeaderRef, scrollMarginTop } = useScrollingContext();

  const { isServicerCell, isInformationCell, isCommonCell } = LoanService;

  const {
    dealChecklistColumnConfig,
    informationColumnConfig,
    noteIssuesColumnConfig,
    partiesColumnConfig,
    postClosingColumnConfig,
    sharingColumnConfig,
    monetizationColumnConfig,
    hedgingColumnConfig,
  } = LoanService;

  const { data: loanData, error: loanLoadError, isFetching: isLoanLoading } = useGetLoanQuery(id);

  useHandleRtkQueryErrors(loanLoadError);

  const loanStatus = LoanService.getLoanStatus(loanData);

  const loanTableData = useMemo(() => LoanService.getTableData(loanData), [loanData]);
  const {
    loanInformationKeys,
    loanPartiesKeys,
    dealChecklistKeys,
    noteIssuesKeys,
    postClosingKeys,
    sharingKeys,
    monetizationKeys,
    hedgingKeys: [hedgingParty1Keys, hedgingParty2Keys],
  } = useMemo(() => LoanService.getLoanSectionsKeys(), []);

  const loanReportTaskMutationResult = useInitiateReportTaskMutation();

  const [getLoanReport] = usePollingQuery(loanReportTaskMutationResult, {
    onError: e => {
      const { nonFieldErrors, backendServicesError } = formatErrors(e);
      displayErrorsInToast([nonFieldErrors, backendServicesError]);
    },
    onSuccess: async reportUrl => {
      try {
        await reportActions.download(reportUrl);
        showSuccessNotification(SUCCESSFULLY_GENERATED);
      } catch (e) {
        displayErrorsInToast([e]);
      }
    },
  });

  const getUrl = event => event?.target?.value;

  const handleReportImportChange = async event => getLoanReport(getUrl(event));

  const reportTypes = useMemo(() => loanReportOptions(id), [id]);

  const titleNavLinkStyle = { scrollMarginTop };

  const pageTitle = useMemo(() => loanTableData?.headerName, [loanTableData]);

  const sideBarContent = { links: createLoanDetailLinks(id) };

  const isOidBalanceLinkShown =
    isStatusActiveInPayment(loanStatus) || isStatusActivePaid(loanStatus);

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isLoanLoading}>
      <DocumentTitle title={pageTitle} />
      <BackgroundFillByStatus status={loanStatus} isLoanStatuses>
        <LoanHeader ref={setHeaderRef} title={pageTitle} loanStatus={loanStatus}>
          <div className={classes.buttonsContainer}>
            {isOidBalanceLinkShown && (
              <Link href={appRoutes.OIDBalanceReportLoanPath(id)} className={classes.link}>
                {OID_BALANCE}
              </Link>
            )}
            <Select
              className={clsx(classes.select, classes.selectWide)}
              classes={{ root: classes.select }}
              variant="standard"
              name="criteriaSelect"
              value=""
              displayEmpty
              onChange={handleReportImportChange}
              renderValue={() => 'Select a Report'}
            >
              {reportTypes.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <Button
              type="button"
              className={classes.editButton}
              variant="text"
              color="primary"
              exact
              to={appRoutes.loanEditPath(id)}
              component={NavHashLink}
            >
              {EDIT_PROPERTY}
            </Button>
          </div>
        </LoanHeader>

        <ContentLayout disableTopPadding>
          <h3 id={LOAN_HASH.details} className={classes.detailTitle} style={titleNavLinkStyle}>
            Loan Details
          </h3>
          <div className={classes.detailWrap}>
            <div className={classes.tableContent}>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {loanInformationKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {informationColumnConfig[key].displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3 id={LOAN_HASH.parties} className={classes.detailTitle} style={titleNavLinkStyle}>
                Parties
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {loanPartiesKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {partiesColumnConfig[key]?.displayedName}
                        </TableCell>
                        {isServicerCell(key) && (
                          <TableCell>
                            <CellServicer cellData={loanTableData?.[key]} />
                          </TableCell>
                        )}
                        {isInformationCell(key) && (
                          <TableCell>
                            <CellInformation
                              cellData={loanTableData?.[key]}
                              fieldLabels={ContactFieldLabels}
                            />
                          </TableCell>
                        )}
                        {isCommonCell(key) && (
                          <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3
                id={LOAN_HASH.dealChecklist}
                className={classes.detailTitle}
                style={titleNavLinkStyle}
              >
                Deal Checklist
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {dealChecklistKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {dealChecklistColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3
                id={LOAN_HASH.noteIssues}
                className={classes.detailTitle}
                style={titleNavLinkStyle}
              >
                Note Issues
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {noteIssuesKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {noteIssuesColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3
                id={LOAN_HASH.postClosing}
                className={classes.detailTitle}
                style={titleNavLinkStyle}
              >
                Post Closing
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {postClosingKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {postClosingColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3 id={LOAN_HASH.sharing} className={classes.detailTitle} style={titleNavLinkStyle}>
                Sharing
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {sharingKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {sharingColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3
                id={LOAN_HASH.monetization}
                className={classes.detailTitle}
                style={titleNavLinkStyle}
              >
                Monetization
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    {monetizationKeys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {monetizationColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <h3 id={LOAN_HASH.hedging} className={classes.detailTitle} style={titleNavLinkStyle}>
                Hedging
              </h3>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2} className={classes.detailTitle}>
                        Party 1
                      </TableCell>
                    </TableRow>
                    {hedgingParty1Keys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {hedgingColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={2} className={classes.detailTitle}>
                        Party 2
                      </TableCell>
                    </TableRow>
                    {hedgingParty2Keys.map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.fieldNameTableCell}>
                          {hedgingColumnConfig[key]?.displayedName}
                        </TableCell>
                        <TableCell>{getDisplayValue(loanTableData, key)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <StickyBox offsetTop={148} offsetBottom={10}>
              <div className={classes.statusWindow}>
                <div className={classes.statusWrap}>
                  <h3 className={classes.statusTitle}>Status</h3>
                  <p className={classes.statusValue}>{loanTableData?.status}</p>
                </div>
                <div className={classes.statusWrap}>
                  <h3 className={classes.statusTitle}>Successor Borrower</h3>
                  <Link
                    href={appRoutes.successorBorrowerPath(loanTableData?.successorBorrower?.id)}
                  >
                    {loanTableData?.successorBorrower?.name ?? ''}
                  </Link>
                </div>
              </div>
            </StickyBox>
          </div>
        </ContentLayout>
      </BackgroundFillByStatus>
    </RootLayoutV2>
  );
};
