import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    input: {
      width: '100%',
      minWidth: '320px',
      '& label + .MuiInput-formControl': {
        marginTop: '4px',
      },
    },
  }),
  { name: 'SimpleInput' },
);
