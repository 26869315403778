import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const userApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getUsers: builder.query({
      query: () => ({
        url: apiRoutes.usersPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.user),
    }),
  }),
});

export const { useGetUsersQuery } = userApi;
