import React, { useEffect, useRef, useState } from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { any, has, isNil, propOr } from 'ramda';

import FormError from 'components/FormError';

import { getFormFieldValue } from 'utils/getFormFieldValue';
import { serializeOptions } from 'utils/getSelectOptions';

import useStyles from './useStyles';

const REQUIRED = ' *';

const FormRowSelect = config => {
  const { field, errors, options, values, disabled, onChangeField, isRequired } = config;
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const { fieldKey, displayedName, placeholder } = field;
  const inputRef = useRef(null);
  const isFocused = useRef(false);
  const value = getFormFieldValue(fieldKey, values);
  const [serializedOptions, setSerializedOptions] = useState(serializeOptions(options));
  const updateSerializedOptions = () => {
    setSerializedOptions(serializeOptions(options));
  };

  const getDefaultValue = () => {
    if (has('id')(value)) {
      return value.id;
    }

    const isOptionValueEqValue = option => option.value === value;

    if (any(isOptionValueEqValue, serializedOptions)) {
      const index = serializedOptions.findIndex(isOptionValueEqValue);
      return serializedOptions[index].value;
    }

    return !isNil(value) ? value : null;
  };

  const [defaultValue, setDefaultValue] = useState(null);
  const updateDefaultValue = () => {
    setDefaultValue(getDefaultValue());
    setValue(fieldKey, getDefaultValue());
  };
  const getAutocompleteOptionValue = propOr(null, 'value');

  const getOptionLabel = option => {
    const optionLabel = option?.label;
    if (!isNil(optionLabel)) {
      return String(optionLabel);
    }

    const labelAfterSerialize = serializedOptions.find(item => item.value === option)?.label;
    if (!isNil(labelAfterSerialize)) {
      return String(labelAfterSerialize);
    }

    return String(option);
  };

  const onFocus = () => {
    isFocused.current = true;
    inputRef.current.focus();
  };

  const getOptionSelected = (option, chosenOption) =>
    option?.value === (chosenOption || chosenOption?.value);

  useEffect(() => {
    if (serializedOptions.length && !isFocused.current) {
      updateDefaultValue();
    }
  }, [serializedOptions.length]);

  useEffect(() => {
    if (options.length) {
      updateSerializedOptions();
    }
  }, [options]);

  return (
    <div className={classes.formRow} key={fieldKey}>
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </label>
      <div className={classes.inputWrap}>
        <Controller
          name={fieldKey}
          control={control}
          defaultValue={defaultValue}
          onFocus={onFocus}
          render={({ onChange, ...props }) => (
            <Autocomplete
              {...props}
              id={fieldKey}
              getOptionLabel={getOptionLabel}
              getOptionSelected={getOptionSelected}
              options={serializedOptions}
              openOnFocus
              selectOnFocus
              autoHighlight
              disabled={disabled}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder={placeholder}
                  inputRef={inputRef}
                  size="small"
                  variant="outlined"
                />
              )}
              onChange={(_, data) => {
                if (onChangeField) {
                  onChangeField(data);
                }
                return onChange(getAutocompleteOptionValue(data));
              }}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowSelect.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    placeholder: PropTypes.string,
  }),
  values: PropTypes.shape({
    id: PropTypes.number,
  }),
  errors: PropTypes.shape({}),
  options: arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      label: PropTypes.string,
    }),
  ),
  disabled: PropTypes.bool,
  onChangeField: PropTypes.func,
  isRequired: PropTypes.bool,
};

FormRowSelect.defaultProps = {
  disabled: false,
};

export default FormRowSelect;
