import React, { useState } from 'react';

import { Button, Typography } from '@material-ui/core';
import AuthRepository from 'repositories/AuthRepository';
import ToolsRepository from 'repositories/ToolsRepository';

import DocumentTitle from 'components/DocumentTitle';
import Icon from 'components/Icon';
import ImportPopup from 'components/ImportPopup';
import SimpleDownloadReportPopup from 'components/SimpleDownloadReportPopup';
import PricingModelPopup from 'components/PricingModelPopup';
import CIBCPopup from 'components/CIBCPopup';
import MoodyReportPopup from 'components/MoodyReportPopup';
import SharingArrangementDefeasedLoansPopup from 'components/SharingArrangementDefeasedLoansPopup';
import UpdateAssumedDatesPopup from 'components/UpdateAssumedDatesPopup';
import ReconciliationImportPopup from 'components/ReconciliationImportPopup';
import AgenciesSecuritiesPercentagePopup from 'components/AgenciesSecuritiesPercentagePopup';
import FundingRequestPopup from 'components/FundingRequestPopup';
import JournalEntriesPopup from 'components/JournalEntriesPopup';
import ExternalPostingForm from 'components/ExternalPostingForm';

import { TOOLS_POPUP } from 'enums';

import ContentLayout from 'layouts/ContentLayout';

import { getFafFormParams } from 'utils/getFafFormParams';

import useStyles from './useStyles';

const TITLE = 'Tools';

export const Tools = () => {
  const classes = useStyles();
  const [currentPopup, setCurrentPopup] = useState(null);
  const [externalFormOptions, setExternalFormOptions] = useState({ token: '', action: '' });

  const getFafAuthParams = async (access, refresh) => {
    const fafTokenResponse = await AuthRepository.fafLogin({ access, refresh });
    return fafTokenResponse?.data?.data;
  };

  const handleSubmitFafForm = async () => {
    const { access, refresh } = JSON.parse(localStorage.dhc).auth.data;
    const fafAuthParams = await getFafAuthParams(access, refresh);
    const fafFormParams = getFafFormParams(fafAuthParams);
    setExternalFormOptions(fafFormParams);
  };

  const toolsItems = [
    {
      iconName: 'import',
      title: 'Import',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.import);
      },
    },
    {
      iconName: 'import',
      title: 'Import via FAF',
      clickHandler: handleSubmitFafForm,
    },
    {
      iconName: 'import',
      title: 'Import Reconciliation Inputs',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.reconciliationInputs);
      },
    },
    {
      iconName: 'calendar',
      title: 'Update Assumed Dates',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.updateAssumedDates);
      },
    },
    {
      title: 'Agencies/Securities Percentage',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.agenciesSecuritiesPercentage);
      },
    },
    {
      title: 'Buy Up Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.buyUpReport);
      },
    },
    {
      title: 'CIBC',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.cibc);
      },
    },
    {
      title: 'Deal Closing Checklist Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.dealClosingChecklist);
      },
    },
    {
      title: 'Deals In Progress',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.dealsInProgress);
      },
    },
    {
      title: 'Dissolution Future Projections Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.dissolutionFutureProjectionsReport);
      },
    },
    {
      title: 'Freddie Float Payment Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.freddieFloatPayment);
      },
    },
    {
      title: 'Funding Request',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.fundingRequest);
      },
    },
    {
      title: 'Journal Entries',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.journalEntries);
      },
    },
    {
      title: 'Master Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.masterReport);
      },
    },
    {
      title: 'Maturity Tracking Sheet',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.maturityTrackingSheet);
      },
    },
    {
      title: "Moody's Data report",
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.moodyReport);
      },
    },
    {
      title: 'NetSuite Account Upload Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.netsuiteAccountReport);
      },
    },
    {
      title: 'Pricing Model',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.pricingModel);
      },
    },
    {
      title: 'Properties Data Dump',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.propertiesDump);
      },
    },
    {
      title: 'PRP Tracking Sheet',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.prpTrackingSheet);
      },
    },
    {
      title: 'Rating Agency Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.ratingAgencyReport);
      },
    },
    {
      title: 'Sharing Arrangement Defeased Loans Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.sharingArrangementDefeasedLoans);
      },
    },
    {
      title: 'Side Letter Report',
      clickHandler: () => {
        setCurrentPopup(TOOLS_POPUP.sideLetterReport);
      },
    },
  ];

  const isCurrentPopup = popupCode => popupCode === currentPopup;

  return (
    <ContentLayout>
      <DocumentTitle title={TITLE} />
      <div className={classes.toolsContainer}>
        <Typography variant="h1">{TITLE}</Typography>
        <ExternalPostingForm options={externalFormOptions} />
        <ul className={classes.toolsList}>
          {toolsItems.map(item => (
            <li className={classes.toolsItem} key={item.title}>
              {item.iconName && (
                <div className={classes.iconWrapper}>
                  <Icon name={item.iconName} />
                </div>
              )}
              <Button className={classes.toolsButton} color="secondary" onClick={item.clickHandler}>
                {item.title}
              </Button>
            </li>
          ))}
        </ul>
        {isCurrentPopup(TOOLS_POPUP.import) && <ImportPopup setPopupState={setCurrentPopup} />}
        {isCurrentPopup(TOOLS_POPUP.updateAssumedDates) && (
          <UpdateAssumedDatesPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.masterReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.consolidatedMasterReports}
            setPopupState={setCurrentPopup}
            popupTitleText="Master Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.propertiesDump) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.propertiesDataDump}
            setPopupState={setCurrentPopup}
            popupTitleText="Properties Data Dump"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.pricingModel) && (
          <PricingModelPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.cibc) && <CIBCPopup setPopupState={setCurrentPopup} />}
        {isCurrentPopup(TOOLS_POPUP.moodyReport) && (
          <MoodyReportPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.sharingArrangementDefeasedLoans) && (
          <SharingArrangementDefeasedLoansPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.buyUpReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.buyUpReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Buy Up Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.netsuiteAccountReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.netsuiteAccountReport}
            setPopupState={setCurrentPopup}
            popupTitleText="NetSuite Account Upload Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.sideLetterReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.sideLetterReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Side Letter Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.dealsInProgress) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.dealsInProgressReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Deals in progress Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.prpTrackingSheet) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.prpTrackingSheetReport}
            setPopupState={setCurrentPopup}
            popupTitleText="PRP Tracking Sheet Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.ratingAgencyReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.ratingAgencyReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Rating Agency Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.maturityTrackingSheet) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.maturityTrackingSheetReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Maturity Tracking Sheet Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.dissolutionFutureProjectionsReport) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.dissolutionFutureProjectionsReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Dissolution Future Projections Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.dealClosingChecklist) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.dealClosingChecklist}
            setPopupState={setCurrentPopup}
            popupTitleText="Deal Closing Checklist Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.freddieFloatPayment) && (
          <SimpleDownloadReportPopup
            callback={ToolsRepository.freddieFloatPaymentReport}
            setPopupState={setCurrentPopup}
            popupTitleText="Freddie Float Payment Report"
          />
        )}
        {isCurrentPopup(TOOLS_POPUP.reconciliationInputs) && (
          <ReconciliationImportPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.agenciesSecuritiesPercentage) && (
          <AgenciesSecuritiesPercentagePopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.fundingRequest) && (
          <FundingRequestPopup setPopupState={setCurrentPopup} />
        )}
        {isCurrentPopup(TOOLS_POPUP.journalEntries) && (
          <JournalEntriesPopup setPopupState={setCurrentPopup} />
        )}
      </div>
    </ContentLayout>
  );
};
