import { useRef, useEffect, useState } from 'react';

import { getHeight } from 'utils/getElementHeight';

export const useRefElementHeight = () => {
  const elementRef = useRef(null);
  const [elementHeight, setElementHeight] = useState(null);

  useEffect(() => {
    if (!elementRef) return;
    setElementHeight(getHeight(elementRef));
  });

  return {
    elementRef,
    elementHeight,
  };
};
