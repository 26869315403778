import { createSlice } from '@reduxjs/toolkit';
import OutstandingBindersRepository from 'repositories/OutstandingBindersRepository';

import { createRestSlice } from 'utils/RestSlice';

const outstandingBindersRestSlice = createRestSlice({
  resource: 'outstandingBinder',
  repository: OutstandingBindersRepository,
  slices: ['loadOutstandingBinders', 'updateOutstandingBinder'],
});

const initialState = {
  ...outstandingBindersRestSlice.initialState,
};

const outstandingBindersSlice = createSlice({
  name: 'outstandingBinders',
  initialState,
  reducers: {
    ...outstandingBindersRestSlice.reducers,
  },
});

export default outstandingBindersSlice.reducer;
export const outstandingBindersRestHooks = outstandingBindersRestSlice.hooks(
  outstandingBindersSlice,
  'OutstandingBindersSlice',
);
