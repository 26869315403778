import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    yearInput: {
      maxWidth: '320px',
      marginBottom: '24px',
    },
  }),
  { name: 'MonthInput' },
);
