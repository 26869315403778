import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentWrap: {
      paddingTop: 0,
    },
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    actionPanelSelect: {
      marginLeft: 'auto',
    },
    search: {
      marginRight: 8,
    },
    loaderWrap: {
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: 80,
        height: 80,
      },
    },
    tableContent: {
      marginTop: 16,
    },
    tableHeadCell: {
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      color: theme.palette.santasGray,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      backgroundColor: theme.palette.athensGray,
    },
    tableRow: {
      textDecoration: 'none',
      color: 'inherit',
    },
    tableCell: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
    },
    statusCol: {
      fontSize: 11,
      fontWeight: 'bold',
      textTransform: 'uppercase',
      '& span': {
        display: 'inline-block',
        padding: [1, 4, 0, 4],
      },
    },
    cellHidden: {
      display: 'none',
    },
    monthSelect: {
      '&.MuiInputBase-root': {
        outline: 0,
        border: 0,
        padding: 0,
        backgroundColor: 'transparent',
        width: 100,
      },
      '& .MuiSelect-root': {
        marginRight: '28px',
        textAlign: 'end',
        outline: 0,
        border: 0,
        padding: 0,
        backgroundColor: 'transparent',
      },
    },
  }),
  { name: 'HomeUpcomingParRepays' },
);
