import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@material-ui/core';
import clsx from 'clsx';

import FormError from 'components/FormError';

import { getFormFieldValue } from 'utils/getFormFieldValue';

import useStyles from './useStyles';

const REQUIRED = ' *';

const FormRowInput = props => {
  const { field, errors, values, isColumnView, disabled, isRequired } = props;
  const classes = useStyles();
  const { control, setValue } = useFormContext();
  const inputRef = useRef();
  const { fieldKey, displayedName, multiline, inputType = 'text' } = field;

  const initialValue = getFormFieldValue(fieldKey, values);

  useEffect(() => {
    setValue(fieldKey, initialValue);
  }, [initialValue]);

  return (
    <div
      className={clsx(classes.formRow, {
        [classes.divider]: field.hasMargin,
        [classes.columnView]: isColumnView,
      })}
    >
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </label>
      <div className={classes.inputWrap}>
        <Controller
          id={fieldKey}
          control={control}
          onFocus={() => inputRef.current.focus()}
          className={classes.formInput}
          name={fieldKey}
          defaultValue={initialValue}
          as={
            <Input inputRef={inputRef} multiline={multiline} type={inputType} disabled={disabled} />
          }
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowInput.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
    multiline: PropTypes.bool,
    inputType: PropTypes.string,
  }),
  errors: PropTypes.shape({}),
  values: PropTypes.shape({}),
  isColumnView: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default FormRowInput;
