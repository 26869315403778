import React, { forwardRef } from 'react';

import PropTypes from 'prop-types';
import { Typography, Link, Button } from '@material-ui/core';
import clsx from 'clsx';

import Breadcrumbs from 'components/Breadcrumbs';

import useStyles from './useStyles';

const BUTTON_TITLE = 'Download Excel Model';

export const OidReportDetailsHeader = forwardRef((props, ref) => {
  const { title, onDownload, links, pathsForBreadcrumbs, isExcelLoading, children, className } =
    props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.header, className)} ref={ref}>
      <div className={classes.contentContainer}>
        <Breadcrumbs paths={pathsForBreadcrumbs} />
        <div className={classes.titleSection}>
          <Typography variant="h1" className={classes.title}>
            {title}
          </Typography>
          <div>
            {links.map(({ path, name }) => (
              <Link href={path} color="secondary" className={classes.link} key={name}>
                {name}
              </Link>
            ))}
            {onDownload && (
              <Button
                color="primary"
                className={classes.downloadButton}
                onClick={onDownload}
                disabled={isExcelLoading}
              >
                {BUTTON_TITLE}
              </Button>
            )}
          </div>
        </div>
        <hr className={classes.line} />
        {children}
      </div>
    </div>
  );
});

OidReportDetailsHeader.propTypes = {
  title: PropTypes.string,
  onDownload: PropTypes.func,
  links: PropTypes.arrayOf(PropTypes.shape({ path: PropTypes.string, name: PropTypes.string })),
  pathsForBreadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({ path: PropTypes.string, name: PropTypes.string }),
  ),
  isExcelLoading: PropTypes.bool,
  className: PropTypes.string,
};
