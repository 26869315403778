import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    root: {
      display: 'inline-flex',
      alignItems: 'center',
      position: 'relative',
      width: 252,
    },
    input: {
      flex: 1,
      height: 32,
      paddingRight: 42,
    },
    inputFocused: {
      '& + $divider': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    divider: {
      position: 'absolute',
      right: 30,
      width: 2,
      backgroundColor: theme.palette.catskillWhite,
    },
    iconButton: {
      position: 'absolute',
      right: 2,
      top: 2,
      width: 28,
      height: 28,
      padding: 6,
    },
  }),
  { name: 'SearchField' },
);
