import { createSelector } from '@reduxjs/toolkit';

import { prepareDataValidationReport, getNavLinks } from 'presenters/DataValidationPresenter';

import { useDataValidationActions } from 'store/DataValidationReportSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

const selectPreparedReport = createSelector(
  slice => slice.items,
  report => prepareDataValidationReport(report),
);
const selectNavLinks = createSelector(selectPreparedReport, getNavLinks);

export const useDataValidationReport = () => {
  const { dataValidationReport, createDataValidationLongPollingWorker } =
    useDataValidationActions();
  const { loadingStatus } = dataValidationReport;
  const {
    isPending: isDataValidationReportLoading,
    isFulfilled: isDataValidationReportLoadFulfilled,
  } = useFetchStatus(loadingStatus);

  return {
    isDataValidationReportLoading,
    isDataValidationReportLoadFulfilled,
    dataValidationReport: selectPreparedReport(dataValidationReport),
    createDataValidationLongPollingWorker,
    navLinks: selectNavLinks(dataValidationReport),
    dataValidationLoadErrors: dataValidationReport.errors,
  };
};
