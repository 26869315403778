import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import useStyles from 'components/MembersNestedList/useStyles';

export const MemberSummary = props => {
  const {
    member: { reportValue, memberName, memberId },
    valueColumnHeaderText,
    memberDetailsLink,
  } = props;
  const classes = useStyles();

  const handleLinkClick = e => e.stopPropagation();

  return (
    <div className={classes.summaryWrap}>
      <div className={classes.memberInfo}>
        <Typography className={classes.fontBold}>{memberName}</Typography>
        {memberDetailsLink && (
          <Link
            href={memberDetailsLink.path(memberId)}
            color="secondary"
            className={classes.fontLink}
            onClick={handleLinkClick}
          >
            {memberDetailsLink.linkName}
          </Link>
        )}
      </div>
      <div className={classes.balance}>
        {valueColumnHeaderText && (
          <Typography className={classes.columnHeader}>{valueColumnHeaderText}</Typography>
        )}
        <Typography className={classes.fontBold}>{reportValue}</Typography>
      </div>
    </div>
  );
};

MemberSummary.propTypes = {
  valueColumnHeaderText: PropTypes.string,
  member: PropTypes.shape({
    memberName: PropTypes.string,
    memberId: PropTypes.number,
    reportValue: PropTypes.string,
    successorBorrowers: arrayOf(PropTypes.shape({})),
  }),
  memberDetailsLink: PropTypes.shape({
    path: PropTypes.func,
    linkName: PropTypes.string,
  }),
};
