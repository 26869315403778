import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    container: {
      position: 'sticky',
      top: 0,
      width: '100%',
      zIndex: 2,
    },
    content: {
      backgroundColor: theme.palette.linkWater,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      padding: '32px 24px',
      background: theme.palette.white,
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
    },
    panelInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    panelActive: {
      backgroundColor: theme.palette.feta,
    },
    panelActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    title: {
      margin: '11px 0 0 0',
    },
  }),
  { name: 'LoanHeader' },
);
