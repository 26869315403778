import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params) {
    const url = apiRoutes.outstandingBindersPath();
    return FetchHelpers.get(url, params);
  },
  async update(id, loan) {
    const url = apiRoutes.loanPath(id);
    return FetchHelpers.patch(url, loan);
  },
};
