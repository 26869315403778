import humps from 'humps';

import * as OptionsService from 'domain/options/service';

import { camelize } from 'utils/keysConverter';

export const getOrganizationTypeValues = options => {
  const optionsList = OptionsService.getOptionsForEnums(options) ?? [];
  const { organizationTypesOptions } = optionsList;
  return camelize(
    organizationTypesOptions.reduce(
      (acc, type) => ({ ...acc, [type.label]: humps.depascalize(type.label).replace(' ', '') }),
      {},
    ),
  );
};

export const getOrganizationTypes = options => {
  const optionsList = OptionsService.getOptionsForEnums(options) ?? [];
  const { organizationTypesOptions } = optionsList;
  return camelize(
    organizationTypesOptions.reduce((acc, type) => ({ ...acc, [type.label]: type.label }), {}),
  );
};
