import React, { useEffect, useMemo } from 'react';

import clsx from 'clsx';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';

import { useFreddieFloatPayments } from 'hooks/api/useFreddieFloatPayments';
import { useLoans } from 'hooks/api/useLoans';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Freddie Float Payment';
const TOTALS_TITLE = 'Totals';

export const FreddieFloatPayment = () => {
  const classes = useStyles();

  const {
    loadFreddieFloatPayments,
    tableData,
    tableKeys,
    columnConfig,
    isFreddieFloatPaymentsLoading,
    totals,
  } = useFreddieFloatPayments();

  const { makeLoanPartialUpdate } = useLoans();
  const { elementRef, elementHeight } = useRefElementHeight();

  const { displayErrorsInToast } = useErrors();

  const fetchData = async () => {
    try {
      await loadFreddieFloatPayments();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFieldChange = async (id, key, value) => {
    await makeLoanPartialUpdate(id, { [key]: value });
  };

  const tableBottomLine = useMemo(() => {
    if (!totals) return null;
    return (
      <tr className={classes.tableBottomRow}>
        <td colSpan={7} className={classes.tableBottomCell}>
          {TOTALS_TITLE}
        </td>
        <td className={clsx(classes.tableBottomCell, classes.rightAlign)}>
          {totals.totalFloatInterest}
        </td>
        <td className={clsx(classes.tableBottomCell, classes.rightAlign)}>
          {totals.sharingPaymentToFreddie}
        </td>
      </tr>
    );
  }, [totals]);

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} />
      <ListTable
        detailPath={appRoutes.loanPath}
        isLoading={isFreddieFloatPaymentsLoading}
        tableHeader={tableKeys}
        tableData={tableData}
        columnConfig={columnConfig}
        onEdit={handleFieldChange}
        isOuterScrollable
        fixedTopOffset={elementHeight}
        tableBottomContent={tableBottomLine}
      />
    </ContentLayout>
  );
};
