import { useContext, useMemo } from 'react';

import { useQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';

import { PaginationAndOrderingContext } from 'contexts/PaginationAndOrderingContext';

import { SORT_DIRECTION } from 'enums';

import { parseQueryStringOrdering } from 'utils/sorting';
import { makeOrderingParam, withResetPageParam } from 'utils/pagination';

const usePaginationAndOrderingContext = () => useContext(PaginationAndOrderingContext);

const usePaginationAndOrdering = () => {
  const pageParam = withDefault(NumberParam, 1);
  const pageSizeParam = withDefault(NumberParam, 30);
  const orderingParam = withDefault(StringParam, 'id');

  const [query, setQuery] = useQueryParams({
    page: pageParam,
    pageSize: pageSizeParam,
    ordering: orderingParam,
  });

  const handleSetPage = pageNum => {
    setQuery({ page: pageNum }, 'pushIn');
  };

  const handleSetOrdering = (sortOrdering, params = {}) => {
    const { shouldResetPage } = params;
    const { sortedBy, sortingDirection } = sortOrdering;
    const sortParams = makeOrderingParam(sortedBy, sortingDirection);
    const queryParams = shouldResetPage
      ? { ordering: sortParams, page: 1 }
      : { ordering: sortParams };
    setQuery(queryParams, 'pushIn');
  };

  const handleSetPageSize = pageSizeValue => {
    setQuery({ pageSize: pageSizeValue }, 'pushIn');
  };

  const toggleSortingDirection = (key, direction) => {
    const newDirection =
      direction === SORT_DIRECTION.asc ? SORT_DIRECTION.desc : SORT_DIRECTION.asc;
    handleSetOrdering({ sortedBy: key, sortingDirection: newDirection }, { shouldResetPage: true });
  };

  const parsedOrdering = useMemo(() => parseQueryStringOrdering(query.ordering), [query.ordering]);

  return {
    page: query.page,
    setPage: handleSetPage,
    ordering: parsedOrdering,
    setOrdering: handleSetOrdering,
    pageSize: query.pageSize,
    setPageSize: handleSetPageSize,
    toggleSortingDirection,
  };
};

export { usePaginationAndOrdering, usePaginationAndOrderingContext, withResetPageParam };
