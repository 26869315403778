import { StatusConst } from 'const';

import { STATUS_COLUMN } from 'enums';

import AddressPresenter from 'presenters/AddressPresenter';
import BankAccountPresenter from 'presenters/BankAccountPresenter';

export const createDefaultValues = (configuration = {}) => {
  const {
    parentCompanyId,
    administrativeAgentId,
    ein,
    einDateFiledOn,
    einVerificationLetterRecordDate,
    fiscalYear,
    jurisdiction,
    registeredAgentId,
    bankAccount,
    comments,
    companyAddress,
    registeredOfficeAddress,
  } = configuration;
  return {
    status: StatusConst[STATUS_COLUMN.active],
    bankAccount: BankAccountPresenter.defaultValues(bankAccount),
    address: AddressPresenter.defaultValues(companyAddress),
    registeredOfficeAddress: AddressPresenter.defaultValues(registeredOfficeAddress),
    parentCompany: parentCompanyId,
    administrativeAgent: administrativeAgentId,
    ein,
    einDateFiledOn,
    einVerificationLetterRecordDate,
    fiscalYear,
    jurisdiction,
    comments,
    registeredAgent: registeredAgentId,
  };
};
