import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    table: {
      backgroundColor: 'white',
      '& th': {
        paddingTop: '12px',
        paddingBottom: '12px',
      },
    },
    tableHead: {
      '& th:first-child': {
        minWidth: '150px',
      },
    },
    sticky: {
      position: 'sticky',
      top: '96px',
    },
    tableHeadCell: {
      background: theme.palette.athensGray,
      textAlign: 'left',
      fontWeight: '700',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(12),
      textTransform: 'uppercase',
      color: theme.palette.santasGray,
      zIndex: 1,
    },
    onHover: {
      '&:hover': {
        backgroundColor: theme.palette.catskillWhiteThree,
        cursor: 'pointer',
      },
    },
    tableCell: {
      paddingTop: '12px',
      paddingBottom: '12px',
    },
    tableCellFont: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
    },
    inputCell: {
      padding: '8px 4px',
      verticalAlign: 'top',
      '& input': {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    inputCellWrap: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    input: {
      height: '24px',
      '& .MuiInput-root': {
        height: '24px',
      },
      '& svg': {
        height: '20px',
      },
    },
    errorMessage: {
      maxWidth: '200px',
      marginTop: '3px',
      marginBottom: '3px',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.crimson,
    },
  }),
  { name: 'LoanEditableTable' },
);
