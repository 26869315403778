import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    actionPanelButtonWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
  { name: 'ActionPanelButtonWrapper' },
);
