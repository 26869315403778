import React, { useMemo } from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import { NavHashLink } from 'react-router-hash-link';
import { keys, isNil } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';

import { useGetPoolQuery } from 'domain/pool/apiSlice';
import { PoolService } from 'domain/pool/service';

import useRouter from 'hooks/useRouter';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

export const Pool = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();

  const {
    data: poolData,
    error: poolDataLoadError,
    isFetching: isPoolLoading,
  } = useGetPoolQuery(id);

  const poolTableData = useMemo(() => PoolService.getTableData(poolData), [poolData]);

  const { poolColumnConfig } = PoolService;

  useHandleRtkQueryErrors(poolDataLoadError);

  const title = useMemo(() => poolTableData?.securityDescription, [poolTableData]);

  return (
    <RootLayoutV2 isLoading={isPoolLoading}>
      <ContentLayout isSmall>
        {!isNil(poolTableData) && (
          <>
            <DocumentTitle title={title} />
            <h2 className={classes.contentSubtitle}>Pools</h2>
            <div className={classes.contentPanel}>
              <Typography className={classes.contentTitle} variant="h1">
                {title}
              </Typography>
              <Button
                type="button"
                className={classes.editButton}
                variant="text"
                color="primary"
                exact
                to={appRoutes.poolEditPath(id)}
                component={NavHashLink}
              >
                Edit Pool
              </Button>
            </div>
            <div className={classes.tableContent}>
              <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
                <Table className={classes.table}>
                  <TableBody>
                    {keys(poolColumnConfig).map(key => (
                      <TableRow key={`${id}_${key}`}>
                        <TableCell className={classes.tableCell}>
                          {poolColumnConfig[key].displayedName}
                        </TableCell>
                        <TableCell className={classes.tableCellValue}>
                          {poolTableData[key]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}
      </ContentLayout>
    </RootLayoutV2>
  );
};
