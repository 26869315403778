import humps from 'humps';

export function toResourceName(actionName, resource) {
  const capName = humps.pascalize(resource);
  return actionName.replace(capName, 'Resource');
}

export function toSliceName(actionName, resource) {
  return `${toResourceName(actionName, resource)}Slice`;
}

export function toRealName(actionName, resource) {
  const capName = humps.pascalize(resource);
  return actionName.replace('Resource', capName);
}

export function keysToRealName(resource) {
  return object =>
    Object.keys(object).reduce(
      (result, key) => ({ ...result, [toRealName(key, resource)]: object[key] }),
      {},
    );
}
