import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

export const ContentLayout = props => {
  const { children, className, isSmall = false, disableTopPadding } = props;
  const classes = useStyles();
  return (
    <div
      className={clsx(classes.contentWrap, className, {
        [classes.smallWidth]: isSmall,
        [classes.disableTopPadding]: disableTopPadding,
      })}
    >
      {children}
    </div>
  );
};

ContentLayout.propTypes = {
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  disableTopPadding: PropTypes.bool,
};
