import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    root: {
      position: 'fixed',
      bottom: '30px',
      right: '40px',
    },
    button: {
      '& span': {
        color: 'white',
      },
    },
  }),
  { name: 'ScrollToTopButton' },
);
