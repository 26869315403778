import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import useStyles from './useStyles';

const ExternalPostingForm = props => {
  const { options } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!isEmpty(options.action) && !isEmpty(options.token)) {
      document.externalPostingForm.submit();
    }
  }, [options]);

  return (
    <form
      method="POST"
      action={options.action}
      target="_blank"
      className={classes.postingForm}
      name="externalPostingForm"
    >
      {options &&
        Object.keys(options).map(key => (
          <input readOnly key={key} name={key} value={options[key]} />
        ))}
    </form>
  );
};

ExternalPostingForm.propTypes = {
  options: PropTypes.shape({
    action: PropTypes.string,
    token: PropTypes.string,
  }),
};

export default ExternalPostingForm;
