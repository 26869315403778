import React, { useEffect, useCallback } from 'react';

import { Typography, Button } from '@material-ui/core';
import { isNil } from 'ramda';
import ReconciliationDiscrepanciesRepository from 'repositories/ReconciliationDiscrepanciesRepository';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';

import { useDeleteReconciliationDiscrepanciesCacheMutation } from 'domain/reconciliationDiscrepancy/apiSlice';

import { useReconciliationDiscrepancies } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';

import { formatDateToYearMonth } from 'utils/date';

import useStyles from './useStyles';
import MonthInput from './components/MonthInput';
import ReportList from './components/ReportList';

const BUTTON_TEXT = 'Recalculate Report';
const TITLE = 'Reconciliation Discrepancies';

export const ReconciliationDiscrepancies = () => {
  const {
    createReconciliationDiscrepanciesLongPollingWorker: createLongPollingWorker,
    report,
    isReportLoading,
    reconciliationDiscrepanciesLoadError,
    resetReconciliationDiscrepanciesData,
    reportYearMonth,
    setReportYearMonth,
  } = useReconciliationDiscrepancies();

  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const [deleteCache, { error: deleteCacheMutationError }] =
    useDeleteReconciliationDiscrepanciesCacheMutation();

  useHandleRtkQueryErrors([deleteCacheMutationError]);

  const loadReport = useCallback(
    createLongPollingWorker(ReconciliationDiscrepanciesRepository.reconciliationDiscrepanciesTask),
    [],
  );

  useEffect(() => {
    if (isNil(reconciliationDiscrepanciesLoadError)) return;
    displayErrorsInToast([reconciliationDiscrepanciesLoadError]);
  }, [reconciliationDiscrepanciesLoadError]);

  useEffect(() => {
    loadReport({ reconciliationYearMonth: reportYearMonth });
    return () => resetReconciliationDiscrepanciesData();
  }, []);

  const handleYearMonthSubmitClick = data => {
    const formattedDate = formatDateToYearMonth(data.reconciliationYearMonth);
    setReportYearMonth(formattedDate);
    loadReport({ reconciliationYearMonth: formattedDate });
  };

  const handleRecalculateCacheButtonClick = async () => {
    deleteCache();
    loadReport({ reconciliationYearMonth: reportYearMonth });
  };

  return (
    <ContentLayout className={classes.reportWrap} isSmall>
      <DocumentTitle title={TITLE} />
      <div className={classes.headerWrap}>
        <Typography variant="h1">{TITLE}</Typography>
        <Button
          color="primary"
          className={classes.recalculateButton}
          onClick={handleRecalculateCacheButtonClick}
          disabled={isReportLoading}
        >
          {BUTTON_TEXT}
        </Button>
      </div>
      <MonthInput onSubmit={handleYearMonthSubmitClick} />
      {isReportLoading ? <Loader /> : <ReportList report={report} />}
    </ContentLayout>
  );
};
