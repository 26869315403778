/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function loadResourceChoicesSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        choices: [],
        choicesLoadingStatus: FETCH_STATUSES.idle,
      },
    },
    reducers: {
      loadResourceChoicesStart(state) {
        state[resource].choicesLoadingStatus = FETCH_STATUSES.pending;
        state[resource].choices = [];
      },
      loadResourceChoicesSuccess(state, { payload }) {
        state[resource].choicesLoadingStatus = FETCH_STATUSES.fulfilled;
        state[resource].choices = payload.results;
      },
      loadResourceChoicesFail(state, { payload: { errors } }) {
        state[resource].choicesLoadingStatus = FETCH_STATUSES.failed;
        state[resource].errors = errors;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadResourceChoices: (...params) => {
          restDispatch('loadResourceChoicesStart');

          return repository
            .choices(...params)
            .then(({ data }) => {
              restDispatch('loadResourceChoicesSuccess', data);
            })
            .catch(errors => {
              restDispatch('loadResourceChoicesFail', { errors });
              throw errors;
            });
        },
      };
    },
  };
}
