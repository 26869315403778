import React, { useEffect } from 'react';

import SuccessorBorrowerForm from 'components/SuccessorBorrowerForm';

import { useGetSuccessorBorrowerQuery } from 'domain/successorBorrower/apiSlice';
import { useUpdateSuccessorBorrowerMutation } from 'domain/successorBorrower/apiSlice';

import useRouter from 'hooks/useRouter';

import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

const SuccessorBorrowerEdit = () => {
  const {
    query: { id },
    push,
  } = useRouter();

  const { data: successorBorrowerData, isFetching } = useGetSuccessorBorrowerQuery(id);

  const [
    updateSuccessorBorrower,
    { error: successorBorrowerUpdateError, isSuccess: isSuccessorBorrowerUpdateSuccess },
  ] = useUpdateSuccessorBorrowerMutation();

  const previousRoute = appRoutes.successorBorrowerPath(id);

  const handleSubmitClick = params => updateSuccessorBorrower({ id, successorBorrower: params });

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isSuccessorBorrowerUpdateSuccess) push(previousRoute);
  }, [isSuccessorBorrowerUpdateSuccess]);

  return (
    <RootLayoutV2>
      <SuccessorBorrowerForm
        isEdit
        defaultSuccessorBorrowerData={successorBorrowerData}
        isDefaultDataLoading={isFetching}
        submitButtonText="Update"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        requestError={successorBorrowerUpdateError}
        previousRoute={previousRoute}
        title={successorBorrowerData?.name}
      />
    </RootLayoutV2>
  );
};

export default SuccessorBorrowerEdit;
