import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import clsx from 'clsx';

import useStyles from './useStyles';

export const YearSelect = props => {
  const classes = useStyles();
  const { label, onChange, className, value, options } = props;

  return (
    <Autocomplete
      className={clsx(classes.autocomplete, className)}
      autoHighlight
      blurOnSelect
      onChange={onChange}
      options={options}
      value={value}
      getOptionLabel={option => option.label}
      getOptionSelected={(option, chosenOption) => option.option === chosenOption.option}
      renderInput={params => <TextField {...params} label={label} variant="standard" />}
    />
  );
};

YearSelect.propTypes = {
  label: PropTypes.string,
  options: arrayOf(PropTypes.shape({ option: PropTypes.number, label: PropTypes.string })),
  onChange: PropTypes.func,
  defaultValue: PropTypes.shape({ option: PropTypes.number, label: PropTypes.string }),
  className: PropTypes.string,
  value: PropTypes.shape({ option: PropTypes.number, label: PropTypes.string }),
};
