import React, { useMemo, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import { not, isNil } from 'ramda';
import InterestAccrualRepository from 'repositories/InterestAccrualRepository';

import DocumentTitle from 'components/DocumentTitle';
import Loader from 'components/Loader';

import { useInterestAccrualReport } from 'hooks/api';
import { useShowOpeningLoader } from 'hooks/useShowOpeningLoader';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import useStyles from './useStyles';
import ReportTable from './components/ReportTable';
import ReportYearInput from './components/ReportYearInput';
import GetReportButton from './components/GetReportButton';

const TITLE = 'Annual Mortgage Interest Accrual Report';

export const MortgageInterestAccrualReport = () => {
  const { isOpeningLoaderShown } = useShowOpeningLoader();
  const classes = useStyles();
  const { displayErrorsInToast, formatErrors } = useErrors();

  const {
    createInterestAccrualsLongPollingWorker,
    isInterestAccrualsReportLoading,
    interestAccrualReport,
    reportYear,
    setReportYear,
    interestAccrualsLoadError,
  } = useInterestAccrualReport();

  const loadInterestAccrualsReport = useMemo(
    () => createInterestAccrualsLongPollingWorker(InterestAccrualRepository.interestAccrualsTasks),
    [],
  );

  const onSubmit = async data => {
    const { year: date } = data;
    const year = date.getFullYear();
    setReportYear(year);
    loadInterestAccrualsReport({ year });
  };

  useEffect(() => {
    if (interestAccrualsLoadError) {
      const { backendServicesError, nonFieldErrors } = formatErrors(interestAccrualsLoadError);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  }, [interestAccrualsLoadError]);

  const isOpeningShown = not(isNil(interestAccrualReport)) && isOpeningLoaderShown;

  return (
    <ContentLayout className={classes.reportWrap} isSmall>
      <DocumentTitle title={TITLE} />
      <Typography variant="h1">{TITLE}</Typography>
      <div className={classes.actionPanel}>
        <ReportYearInput onSubmit={onSubmit} />
        <GetReportButton year={reportYear} />
      </div>
      {isOpeningShown || isInterestAccrualsReportLoading ? (
        <Loader />
      ) : (
        <ReportTable report={interestAccrualReport} />
      )}
    </ContentLayout>
  );
};
