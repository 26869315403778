import PropTypes from 'prop-types';

import PersonIndexPresenter from 'presenters/PersonIndexPresenter';

import Presenter, { shortDescriptionModel } from 'utils/PropTypesPresenter';

export const columnConfig = {
  id: {
    displayedName: 'PID',
  },
  address: {
    displayedName: 'Address',
  },
  city: {
    displayedName: 'City',
  },
  fax: {
    displayedName: 'Fax',
  },
  name: {
    displayedName: 'Name',
  },
  organizationType: {
    displayedName: 'Organization Type',
  },
  phone: {
    displayedName: 'Phone',
  },
  shortName: {
    displayedName: 'Short Name',
  },
  state: {
    displayedName: 'State',
  },
  website: {
    displayedName: 'Website',
  },
  zip: {
    displayedName: 'Zip',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    address: PropTypes.string,
    city: PropTypes.string,
    fax: PropTypes.string,
    name: PropTypes.string,
    organizationType: shortDescriptionModel,
    phone: PropTypes.string,
    shortName: PropTypes.string,
    state: PropTypes.string,
    website: PropTypes.string,
    zip: PropTypes.string,
    employees: PropTypes.arrayOf(PersonIndexPresenter.shape()),
  },
  {
    tableData(data) {
      if (!data) {
        return {};
      }
      return {
        ...data,
        employees: PersonIndexPresenter.tableData(data.employees),
        organizationType: data?.organizationType?.name,
      };
    },
    defaultValues() {
      return {};
    },
  },
);
