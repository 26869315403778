import { not, isNil } from 'ramda';

export default {
  hasItem(key) {
    return not(isNil(localStorage.getItem(key)));
  },

  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  },

  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
};
