import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import { preventEmptyString } from 'utils/yupHelpers';
import { transformDate } from 'utils/date';

export const resolver = yupResolver(
  Yup.object({
    calculateForDate: Yup.string()
      .transform(transformDate)
      .required(ERROR_MESSAGES.required)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    floatRate: Yup.number().typeError(ERROR_MESSAGES.number).required(ERROR_MESSAGES.required),
    oneMonth: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    threeMonths: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    sixMonths: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    oneYear: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    threeYears: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    fiveYears: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    tenYears: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
    thirtyYears: Yup.number()
      .typeError(ERROR_MESSAGES.number)
      .transform(preventEmptyString)
      .nullable()
      .required(ERROR_MESSAGES.required),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'isAggressiveModel',
    displayedName: 'Agressive Model',
    type: FORM_FIELD_TYPE.checkbox,
    isFullRow: true,
  },
  {
    fieldKey: 'calculateForDate',
    displayedName: 'Date',
    type: FORM_FIELD_TYPE.date,
    isFullRow: true,
  },
  { fieldKey: 'oneMonth', displayedName: '1 Month', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'threeMonths', displayedName: '3 Month', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'sixMonths', displayedName: '6 Month', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'oneYear', displayedName: '1 Year', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'threeYears', displayedName: '3 Year', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'fiveYears', displayedName: '5 Year', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'tenYears', displayedName: '10 Year', type: FORM_FIELD_TYPE.input },
  { fieldKey: 'thirtyYears', displayedName: '30 Year', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'floatRate',
    displayedName: 'Float Rate',
    type: FORM_FIELD_TYPE.input,
    isFullRow: true,
  },
  {
    fieldKey: 'doUseHedgedValues',
    displayedName: 'Use Hedged Values?',
    type: FORM_FIELD_TYPE.checkbox,
    isFullRow: true,
  },
];
