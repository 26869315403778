const LOAN_ROUTES = {
  activeInPayment: 'active-in-payment',
  activePaid: 'active-paid',
  inProgress: 'in-progress',
  inactive: 'inactive',
  indefinitelyOnHold: 'indefinitelyOnHold',
};

Object.freeze(LOAN_ROUTES);

export { LOAN_ROUTES };
