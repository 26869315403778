import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ToolsRepository from 'repositories/ToolsRepository';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import Popup from 'components/Popup';
import FormRowInput from 'components/FormRowInput';
import FormRowDatepicker from 'components/FormRowDatepicker';
import FormRowCheckbox from 'components/FormRowCheckbox';

import { useBloombergConfigurations } from 'hooks/api';
import { useReportTask } from 'hooks/api/useReportTask';
import { useErrors } from 'hooks/useErrors';
import { useSnackbar } from 'hooks/useSnackbar';

import { getLastDayOfPreviousMonth } from 'utils/date';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const PricingModelPopup = ({ setPopupState }) => {
  const classes = useStyles();
  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading, resetReportTask } =
    useReportTask();
  const { formatErrors, setErrorsToForm, displayErrorsInToast } = useErrors();
  const { showSuccessNotification } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const methods = useForm({
    resolver,
  });
  const { setError, errors } = methods;

  const {
    bloombergConfiguration,
    loadBloombergConfiguration,
    updateBloombergConfiguration,
    isBloombergConfigurationLoading,
  } = useBloombergConfigurations();

  const fetchData = async () => {
    try {
      await loadBloombergConfiguration();
      setSubmitButtonDisabled(false);
    } catch (e) {
      setSubmitButtonDisabled(true);
      const { backendServicesError } = formatErrors(e);
      displayErrorsInToast(backendServicesError);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => () => resetReportTask(), []);

  const handleImportSubmit = async formValues => {
    methods.clearErrors();
    const callback = ToolsRepository.pricingModelReport;
    try {
      setIsLoading(true);
      await updateBloombergConfiguration(formValues);
      window.location.href = await initiateTaskReportLoading(callback, formValues);
      showSuccessNotification('Pricing Model Report Successfully Generated');
      setPopupState(null);
    } catch (err) {
      setIsLoading(false);
      const { fieldErrors, nonFieldErrors, backendServicesError } = formatErrors(err);
      setErrorsToForm(setError, errors, fieldErrors);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
      finishLoading(err);
    }
  };

  const handleImportClose = () => {
    setPopupState(null);
  };

  const configuration = bloombergConfiguration?.item || {};
  const values = { ...configuration, calculateForDate: getLastDayOfPreviousMonth() };

  return (
    <Popup
      title="Discount Rates"
      submitButtonText="Generate"
      toggleState={setPopupState}
      onSubmit={methods.handleSubmit(handleImportSubmit)}
      onClose={handleImportClose}
      isLoading={isLoading || isTaskReportLoading}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    >
      <FormProvider {...methods}>
        <form className={classes.formWrap}>
          {fieldArray.map(field => {
            switch (field.type) {
              case FORM_FIELD_TYPE.input:
                return (
                  <div
                    className={clsx(classes.formField, {
                      [classes.fullRow]: field.isFullRow,
                    })}
                  >
                    <FormRowInput
                      key={field.fieldKey}
                      field={field}
                      errors={methods.errors}
                      values={values}
                      isColumnView
                      disabled={isBloombergConfigurationLoading}
                    />
                  </div>
                );

              case FORM_FIELD_TYPE.date:
                return (
                  <div
                    className={clsx(classes.formField, {
                      [classes.fullRow]: field.isFullRow,
                    })}
                  >
                    <FormRowDatepicker
                      key={field.fieldKey}
                      field={field}
                      errors={methods.errors}
                      values={values}
                      isColumnView
                      disabled={isBloombergConfigurationLoading}
                    />
                  </div>
                );

              case FORM_FIELD_TYPE.checkbox:
                return (
                  <div
                    className={clsx(classes.formField, {
                      [classes.fullRow]: field.isFullRow,
                    })}
                  >
                    <FormRowCheckbox
                      key={field.fieldKey}
                      field={field}
                      errors={methods.errors}
                      values={values}
                      isColumnView
                      disabled={isBloombergConfigurationLoading}
                    />
                  </div>
                );

              default:
                return null;
            }
          })}
        </form>
      </FormProvider>
    </Popup>
  );
};

PricingModelPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default PricingModelPopup;
