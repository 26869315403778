const SORT_DIRECTION = {
  asc: 'ASC',
  desc: 'DESC',
};

const SORTING_DIRECTION_SYMBOL = {
  desc: '-',
};

Object.freeze(SORT_DIRECTION);

export { SORT_DIRECTION, SORTING_DIRECTION_SYMBOL };
