import React from 'react';

import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core/';

import Icon from 'components/Icon';

import { BOOLEAN_TEXT } from 'enums';

import useStyles from 'pages/LoanReconciliationInputs/useStyles';

import { isBlank } from 'utils/predicates';

export const ApprovedForRecCell = props => {
  const { value, comment, isDataRowsDisabled } = props;
  const classes = useStyles();

  const isCommentExists = !isBlank(comment);

  if (value !== BOOLEAN_TEXT.YES) return value;

  return (
    <Tooltip title={comment} disableHoverListener={isDataRowsDisabled || !isCommentExists} arrow>
      <div className={classes.approvedForRecCell}>
        {value}
        {isCommentExists && (
          <div className={classes.iconContainer}>
            <Icon name="triangleArrow" className={classes.triangleSvg} />
          </div>
        )}
      </div>
    </Tooltip>
  );
};

ApprovedForRecCell.propTypes = {
  value: PropTypes.string,
  comment: PropTypes.string,
  isDataRowsDisabled: PropTypes.bool,
};
