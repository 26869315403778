import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    bloombergName: PropTypes.string,
    custodianAccountNumber: PropTypes.string,
    filingRef: PropTypes.string,
    loanServicerLoanNumber: PropTypes.string,
    masterServicerLoanNumber: PropTypes.string,
  },
  {
    formatChoices(items) {
      return items.map(item => ({
        ...item,
        label: item.dealName,
        value: item.id,
      }));
    },
  },
);
