import { LOANS_STATUSES } from 'const';

import { getLoanStatusLabel } from 'utils/loanStatusHelpers';

const DEAL_FILTER = {
  all: 'all',
  today: 'today',
  tomorrow: 'tomorrow',
  dayAfterTomorrow: 'dayAfterTomorrow',
  restOfThisWeek: 'restOfThisWeek',
  remainingDeals: 'remainingDeals',
  onHold: 'onHold',
};

const getDealFilterNames = () => ({
  all: 'All Days Prior',
  today: "Today's Deals",
  tomorrow: "Tomorrow's deals",
  dayAfterTomorrow: "Day After Tomorrow's Deals",
  restOfThisWeek: "Rest  Of The Week's Deals",
  remainingDeals: 'Remaining Deals',
  onHold: getLoanStatusLabel(LOANS_STATUSES.indefinitelyOnHold),
});

Object.freeze(DEAL_FILTER);

export { DEAL_FILTER, getDealFilterNames };
