import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ERROR_MESSAGES } from 'enums';

export const defaultValues = {
  email: '',
};

export const resolver = yupResolver(
  Yup.object({
    email: Yup.string().email(ERROR_MESSAGES.invalidEmail).required(ERROR_MESSAGES.emailIsRequired),
  }),
);
