import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import {
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { keys } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';
import CellInformation from 'components/CellInformation';

import { useGetManagerQuery } from 'domain/manager/apiSlice';
import { ManagerService } from 'domain/manager/service';

import { CELL_TYPES } from 'enums';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { bankAccountInformationLabels } from 'presenters/BankAccountPresenter';

import { appRoutes } from 'router/routes';

import { getStaffLinks } from 'utils/routes';

import useStyles from './useStyles';

export const Manager = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const {
    query: { id },
  } = useRouter();

  const {
    data: managerData,
    isFetching: isManagerLoading,
    error: managerLoadError,
  } = useGetManagerQuery(id);

  const managerTableData = ManagerService.getTableData(managerData);

  const isInformationCell = key =>
    ManagerService.managerColumnConfig[key]?.cellType === CELL_TYPES.information;

  useEffect(() => {
    if (managerLoadError) displayErrorsInToast([managerLoadError]);
  }, [managerLoadError]);

  const title = managerTableData?.name;

  const sideBarContent = getStaffLinks();

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isManagerLoading}>
      <ContentLayout isSmall>
        <DocumentTitle title={title} />
        <h2 className={classes.contentSubtitle}>Managers</h2>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {title}
          </Typography>
          <Button
            type="button"
            className={classes.editButton}
            variant="text"
            color="primary"
            exact
            to={appRoutes.staffManagerEditPath(id)}
            component={NavHashLink}
          >
            Edit Manager
          </Button>
        </div>
        <div className={classes.tableContent}>
          <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
            <Table className={classes.table}>
              <TableBody>
                {keys(ManagerService.managerColumnConfig).map(key => (
                  <TableRow key={`${id}_${key}`}>
                    <TableCell className={classes.tableCell}>
                      {ManagerService.managerColumnConfig[key].displayedName}
                    </TableCell>
                    {isInformationCell(key) ? (
                      <TableCell className={classes.tableCell}>
                        <CellInformation
                          cellData={managerTableData[key]}
                          fieldLabels={bankAccountInformationLabels}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell}>{managerTableData[key]}</TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </ContentLayout>
    </RootLayoutV2>
  );
};
