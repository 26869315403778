import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    hiddenInput: {
      position: 'absolute',
      zIndex: -1,
      width: 0,
      height: 0,
    },
  }),
  { name: 'HiddenInput' },
);
