import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import LoansByStatusLinksList from 'components/LoansByStatusLinksList';

import { useLoansStatus } from 'hooks/useLoansStatus';

import { createLoansNavLinks } from 'utils/routes';

import useStyle from './useStyles';

const title = 'Loans';

export const LoansSideNav = props => {
  const { loansCountsByStatus } = props;

  const classes = useStyle();

  const { setLoansStatus } = useLoansStatus();

  const loansNavLinks = useMemo(
    () => createLoansNavLinks(loansCountsByStatus),
    [loansCountsByStatus],
  );

  const handleLoanStatusLinkClick = status => {
    setLoansStatus(status, { shouldResetPage: true });
  };

  return (
    <nav>
      <h2 className={classes.sidebarTitle}>{title}</h2>
      <LoansByStatusLinksList loansNavLinks={loansNavLinks} onClick={handleLoanStatusLinkClick} />
    </nav>
  );
};

LoansSideNav.propTypes = {
  loansCountsByStatus: PropTypes.shape({
    statuses: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        amount: PropTypes.number,
      }),
    ),
    total: PropTypes.number,
  }),
};
