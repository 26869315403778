import React, { useEffect, useState } from 'react';

import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import ToolsRepository from 'repositories/ToolsRepository';

import Popup from 'components/Popup';
import FormRowInput from 'components/FormRowInput';

import { useBloombergConfigurations } from 'hooks/api';
import { useReportTask } from 'hooks/api/useReportTask';
import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const CIBCPopup = ({ setPopupState }) => {
  const classes = useStyles();
  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading, resetReportTask } =
    useReportTask();
  const { formatErrors, displayErrorsInToast, setErrorsToForm } = useErrors();
  const { showSuccessNotification } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const methods = useForm({
    resolver,
  });

  const {
    bloombergConfiguration,
    loadBloombergConfiguration,
    updateBloombergConfiguration,
    isBloombergConfigurationLoading,
  } = useBloombergConfigurations();

  const fetchData = async () => {
    try {
      await loadBloombergConfiguration();
      setSubmitButtonDisabled(false);
    } catch (e) {
      setSubmitButtonDisabled(true);
      const { backendServicesError } = formatErrors(e);
      displayErrorsInToast([backendServicesError]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => () => resetReportTask(), []);

  const values = bloombergConfiguration?.item || {};

  const handleImportClose = () => {
    setPopupState(null);
  };

  const handleImportSubmit = async () => {
    methods.clearErrors();
    const formValues = methods.getValues();
    const callback = ToolsRepository.CIBCReport;
    const toastText = 'CIBC Report Successfully Generated';
    try {
      setIsLoading(true);
      await updateBloombergConfiguration(formValues);
      window.location.href = await initiateTaskReportLoading(callback, formValues);
      showSuccessNotification(toastText);
      setIsLoading(false);
      handleImportClose();
    } catch (e) {
      finishLoading(e);
      setIsLoading(false);
      const { fieldErrors, backendServicesError, nonFieldErrors } = formatErrors(e);
      setErrorsToForm(methods.setError, methods.errors, fieldErrors);
      displayErrorsInToast([backendServicesError, nonFieldErrors]);
    }
  };

  return (
    <Popup
      title="CIBC Report"
      submitButtonText="Generate"
      toggleState={setPopupState}
      onSubmit={handleImportSubmit}
      onClose={handleImportClose}
      isLoading={isLoading || isTaskReportLoading}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    >
      <FormProvider {...methods}>
        <form className={classes.formWrap}>
          {fieldArray.map(field => (
            <div
              key={field.fieldKey}
              className={clsx(classes.formField, {
                [classes.fullRow]: field.isFullRow,
              })}
            >
              <FormRowInput
                field={field}
                errors={methods.errors}
                values={values}
                isColumnView
                disabled={isBloombergConfigurationLoading}
              />
            </div>
          ))}
        </form>
      </FormProvider>
    </Popup>
  );
};

CIBCPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default CIBCPopup;
