import { useEffect, useState, useMemo } from 'react';

import { isNil } from 'ramda';

import { TimersConst } from 'const';

import { useGetReportDataQuery } from 'domain/report/apiSlice';

import { FETCH_STATUSES } from 'enums';

import { useTaskStatus as checkStatus } from 'utils/useTaskStatus';
import { useFetchStatus as checkFetchStatus } from 'utils/fetchStatusUtils';

const defaultState = {
  data: null,
  error: null,
  status: FETCH_STATUSES.idle,
};

export const usePollingQuery = (useInitiateTaskMutationResult, { onError, onSuccess }) => {
  const [initiateTask, initiateTaskMutationResult] = useInitiateTaskMutationResult;
  const { data: apiToPoll, error: initiateTaskLoadError } = initiateTaskMutationResult;

  const [pollingRequestState, setPollingRequestState] = useState(defaultState);
  const setState = data => setPollingRequestState({ ...defaultState, ...data });

  const { data: taskData, error: taskDataLoadError } = useGetReportDataQuery(
    { url: apiToPoll },
    {
      skip: isNil(apiToPoll) || checkFetchStatus(pollingRequestState.status).isFinished,
      pollingInterval: TimersConst.REQUEST_INTERVAL_TIME,
    },
  );

  const handlePending = () => {
    setState({ status: FETCH_STATUSES.pending });
  };

  const handleError = error => {
    setState({ error, status: FETCH_STATUSES.failed });
    onError(error);
  };

  const handleSuccess = data => {
    setState({ data, status: FETCH_STATUSES.fulfilled });
    onSuccess(data);
  };

  const initiate = url => {
    if (isNil(url)) return;
    handlePending();
    initiateTask({ url });
  };

  useEffect(() => {
    if (isNil(taskData)) return;
    const { status, errors } = taskData;
    if (checkStatus(status).isFailure) handleError(errors);
    if (checkStatus(status).isSuccess) handleSuccess(taskData.location);
  }, [taskData]);

  useEffect(() => {
    const requestError = [initiateTaskLoadError, taskDataLoadError].find(e => !isNil(e));
    if (!isNil(requestError)) handleError(requestError);
  }, [initiateTaskLoadError, taskDataLoadError]);

  const { isIdle, isPending, isFulfilled, isFailed } = checkFetchStatus(pollingRequestState.status);

  return useMemo(
    () => [
      initiate,
      {
        data: pollingRequestState.data,
        error: pollingRequestState.error,
        isIdle,
        isPending,
        isFulfilled,
        isFailed,
      },
    ],
    [pollingRequestState],
  );
};
