import { LOAN_ROUTES } from 'enums/loanRoutes';
import { isNil } from 'ramda';

import { LOANS_STATUSES } from 'const';

import { getLoanStatusLabel } from 'utils/loanStatusHelpers';

export const columnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
    canBeHidden: false,
  },
  id: {
    displayedName: 'PID',
    canBeHidden: false,
    isLink: true,
  },
  dealName: {
    displayedName: 'Property Name',
    canBeHidden: false,
    withTags: true,
  },
  assumedDate: {
    displayedName: 'Accepted Date',
    canBeHidden: false,
  },
  bloombergName: {
    displayedName: 'Bloomberg Name',
  },
  status: {
    displayedName: 'Status',
    canBeHidden: false,
  },
  loanServicerName: {
    displayedName: 'Sub Servicer',
    canBeHidden: false,
  },
  servicerCounsel1Name: {
    displayedName: 'Servicer Counsel Contact 1',
  },
};

export const getSbLoansTableHeader = () => Object.keys(columnConfig);

export const filterTableDataByStatus = (list, statusLabel) =>
  !isNil(list) ? list.filter(item => item?.data?.status === statusLabel) : null;

export const sortLoansListByStatus = tableData => ({
  [LOAN_ROUTES.all]: tableData,
  [LOAN_ROUTES.activeInPayment]: filterTableDataByStatus(
    tableData,
    getLoanStatusLabel(LOANS_STATUSES.activeInPayment),
  ),
  [LOAN_ROUTES.activePaid]: filterTableDataByStatus(
    tableData,
    getLoanStatusLabel(LOANS_STATUSES.activePaid),
  ),
  [LOAN_ROUTES.inactive]: filterTableDataByStatus(
    tableData,
    getLoanStatusLabel(LOANS_STATUSES.inactive),
  ),
  [LOAN_ROUTES.indefinitelyOnHold]: filterTableDataByStatus(
    tableData,
    getLoanStatusLabel(LOANS_STATUSES.indefinitelyOnHold),
  ),
  [LOAN_ROUTES.inProgress]: filterTableDataByStatus(
    tableData,
    getLoanStatusLabel(LOANS_STATUSES.inProgress),
  ),
});
