import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { Typography, Breadcrumbs as MuiBreadcrumbs, Link } from '@material-ui/core';
import clsx from 'clsx';
import { has } from 'ramda';

import useStyles from './useStyles';

const hasPath = has('path');

export const Breadcrumbs = props => {
  const { paths } = props;
  const classes = useStyles();

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
      {paths.map((link, index) => {
        const { name, path } = link;
        return hasPath(link) ? (
          <Link color="secondary" href={path} className={classes.font} key={index}>
            <p>{name}</p>
          </Link>
        ) : (
          <Typography className={clsx(classes.font, classes.inactive)} key={index}>
            {name}
          </Typography>
        );
      })}
    </MuiBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  paths: arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({ name: PropTypes.string, path: PropTypes.string }),
      PropTypes.shape({ name: PropTypes.string, isDisabled: PropTypes.bool }),
    ]),
  ),
};
