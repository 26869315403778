import React from 'react';

import { Typography, Paper, Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

const TITLE_ASSUMPTION = 'Journal Entry: Assumption';
const TITLE_PRP = 'Journal Entry: PRP';
const DEBIT = 'Debit';
const CREDIT = 'Credit';
const TOTAL = 'Total';

export const JournalEntryTable = props => {
  const {
    isJournalEntryPRPTable = false,
    journalEntryConfig,
    journalEntryData,
    journalEntryTotal,
  } = props;
  const classes = useStyles();

  const title = isJournalEntryPRPTable ? TITLE_PRP : TITLE_ASSUMPTION;

  return (
    <div>
      <Typography className={classes.listTitle}>{title}</Typography>
      <Paper className={classes.list}>
        <Grid container className={clsx(classes.entryTableHead, classes.textEnd)}>
          <Grid item xs={8}>
            <Typography className={classes.tableHeaderFont}>{DEBIT}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.tableHeaderFont}>{CREDIT}</Typography>
          </Grid>
        </Grid>
        <Divider />
        <div className={clsx(classes.listPadding, classes.markEvenElements)}>
          {journalEntryConfig.getRowsKeys().map((key, index) => {
            const row = journalEntryConfig.row[key];
            const data = journalEntryData[key];
            return (
              <Grid container className={classes.entryTableItem} key={`${key}-${index}`}>
                <Grid item xs={4}>
                  <Typography className={classes.bodyBold}>{row.displayedName}</Typography>
                </Grid>
                {journalEntryConfig.columnNames.map((name, idx) => (
                  <Grid item xs={4} key={`${name}-${idx}`}>
                    <Typography className={clsx(classes.bodyNormal, classes.textEnd)}>
                      {data[name]}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            );
          })}
        </div>
        <Divider />
        <Grid container className={classes.entryTableFooter}>
          <Grid item xs={4}>
            <Typography className={classes.bodyBold}>{TOTAL}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={clsx(classes.bodyBold, classes.textEnd)}>
              {journalEntryTotal.debit}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={clsx(classes.bodyBold, classes.textEnd)}>
              {journalEntryTotal.credit}
            </Typography>
          </Grid>
        </Grid>
        {!isJournalEntryPRPTable && (
          <>
            <Divider />
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={clsx(classes.bodyBold, classes.entryTableTotal, classes.textEnd)}
                >
                  {journalEntryData?.total}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </div>
  );
};

JournalEntryTable.propTypes = {
  isJournalEntryPRPTable: PropTypes.bool,
  journalEntryConfig: PropTypes.shape({
    columnNames: PropTypes.arrayOf(PropTypes.string),
    getRowsKeys: PropTypes.func,
    row: PropTypes.shape({}),
  }),
  journalEntryData: PropTypes.shape({ total: PropTypes.string }),
  journalEntryTotal: PropTypes.shape({ debit: PropTypes.string, credit: PropTypes.string }),
};
