import { makeStyles } from '@material-ui/core';
import authBackground from 'assets/authBackground.png';

export default makeStyles(
  theme => ({
    main: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        display: 'block',
        width: '100%',
        height: 223,
        background: `url(${authBackground}), linear-gradient(180deg, #FFFFFF 0%, #ECF1F5 100%)`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 100%',
        backgroundSize: 'auto 272px',
        zIndex: 1,
      },
      height: '100vh',
    },
    signContainer: {
      position: 'absolute',
      top: 140,
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: [40, 16],
      width: 380,
      height: 221,
      left: '50%',
      transform: 'translateX(-50%)',
      background: '#fff',
      border: '1px solid #E0E3E8',
      boxSizing: 'border-box',
      boxShadow: '0px 4px 24px rgba(0, 59, 81, 0.15)',
      borderRadius: 7,
      color: theme.palette.elephant,
      lineHeight: '32px',
      fontWeight: 500,
    },
    signCode: {
      fontWeight: 500,
      fontSize: 60,
      marginBottom: 16,
    },
    signText: {
      fontSize: 23,
    },
  }),
  { name: 'PageNotFoundMessage' },
);
