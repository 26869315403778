import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    actionPanel: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    table: {
      '& th, td': {
        width: 48,

        '&:first-child': {
          width: 'auto',
        },

        '&:last-child': {
          fontWeight: 'bold',
        },
      },
      '& tbody *:last-child td': {
        fontWeight: 'bold',
      },
    },
    select: {
      outline: 0,
      border: 'none',
      padding: 0,
      backgroundColor: 'transparent',

      '&:hover, &:focus': {
        border: 'none',
        backgroundColor: 'transparent',
      },

      '&$focused': {
        backgroundColor: 'red',
      },
      '&.Mui-focused': {
        border: 'none',
      },
    },
    selectWide: {
      width: 'fit-content',
      paddingLeft: '8px',
      textAlign: 'end',
    },
    loaderWrap: {
      width: '100%',
      minHeight: '70vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '80px',
        height: '80px',
      },
    },
    exportButton: {
      marginLeft: '16px',
      paddingRight: 0,
    },
  }),
  { name: 'AssumedDeals' },
);
