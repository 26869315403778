import React from 'react';

import PropTypes from 'prop-types';

import useStyles from './useStyles';

const GlobalSnapshot = ({ globalSnapshotData, title }) => {
  const classes = useStyles();

  return (
    <div className={classes.snapshots}>
      <div className={classes.snapshotsWrapper}>
        {title && (
          <div className={classes.snapshotsHeader}>
            <h4 className={classes.snapshotsTitle}>{title}</h4>
          </div>
        )}
        {globalSnapshotData.lists.map((list, index) => (
          <ul className={classes.snapshotsList} key={`snapshot_${index}`}>
            {list.map(item => (
              <li key={`${index}_${item.label}`}>
                <span>{item.label}</span>
                <span>{item.value}</span>
              </li>
            ))}
          </ul>
        ))}
        <div className={classes.snapshotsSummary}>
          {globalSnapshotData?.summary.map(summaryItem => (
            <div key={`summary_${summaryItem.label}`} className={classes.snapshotsSummaryRow}>
              <span className={classes.snapshotsSummaryLabel}>{summaryItem.label}</span>
              <span className={classes.snapshotsSummaryValue}>{summaryItem.value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

GlobalSnapshot.propTypes = {
  globalSnapshotData: PropTypes.shape({
    lists: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    ),
    summary: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ),
  }),
  title: PropTypes.string,
};

export default GlobalSnapshot;
