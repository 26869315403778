import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    formWrap: {
      width: '440px',
      background: '#FFFFFF',
      boxShadow: '0px 15px 24px rgba(19, 51, 76, 0.1), 0px 0px 1px rgba(19, 51, 76, 0.31)',
      borderRadius: 8,
      padding: [40, 60],
    },
    formTitle: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      textAlign: 'center',
      color: theme.palette.primary.main,
      letterSpacing: '-0.01em',
      marginBottom: '32px',
    },
    form: {
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    formField: {
      height: '56px',
      widht: '100%',
    },
    textField: {
      marginBottom: '24px',
    },
    submitButton: {
      marginTop: '24px',
      marginBottom: '24px',
      width: '187px',
      height: '40px',
    },
    forgotLink: {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 'normal',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
  }),
  { name: 'LoginForm' },
);
