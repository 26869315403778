import React from 'react';

import loaderIcon from 'assets/loader.svg';
import PropTypes from 'prop-types';

const Loader = props => {
  const { className } = props;
  return <img src={loaderIcon} alt="loading..." className={className} />;
};

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
