import { range, uniq } from 'ramda';

export const getSelectValueYear = currentYear => ({
  option: currentYear,
  label: String(currentYear),
});

export const getYearsOptions = (lookingBack = 5, lookingForward = 10) => {
  const today = new Date();
  const currentYear = today.getFullYear();
  const minYear = currentYear - lookingBack;
  const maxYear = currentYear + lookingForward;
  return range(minYear, maxYear + 1).map(year => ({ option: year, label: String(year) }));
};

export const getOptionSelected = (currentValue, valueTestAgainst) =>
  currentValue.option === valueTestAgainst.option;

export const mergeOptions = (options1, options2) => uniq([...options1, ...options2]);
