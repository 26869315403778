import React from 'react';

import PropTypes from 'prop-types';

import SideNav from 'components/SideNav';

import { organizationDetailLinks } from 'utils/routes';

import useStyle from './useStyles';

export const OrganizationSideNav = props => {
  const { organization } = props;

  const classes = useStyle();

  return (
    <nav>
      <h2 className={classes.sidebarTitle}>{organization?.name}</h2>
      <SideNav links={organizationDetailLinks(organization?.id)} />
    </nav>
  );
};

OrganizationSideNav.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
};
