import React from 'react';

import PropTypes from 'prop-types';
import { Button, ClickAwayListener } from '@material-ui/core';
import { ReactComponent as CloseIcon } from 'assets/close.svg';
import clsx from 'clsx';

import Loader from 'components/Loader';

import useStyles from './useStyles';

const Popup = ({
  children,
  title,
  submitButtonText,
  toggleState,
  onSubmit,
  onClose,
  onReject,
  isLoading,
  submitButtonAdditionalClass,
  isSubmitButtonDisabled,
  cancelText,
}) => {
  const classes = useStyles();

  const handleSubmitClick = () => {
    onSubmit();
  };
  const handleCloseClick = () => {
    onClose();
    toggleState(false);
  };
  const handleClickOnCross = () => {
    const makeAction = onReject ?? onClose;
    makeAction();
    toggleState(false);
  };

  return (
    <div className={classes.overlay}>
      <ClickAwayListener onClickAway={handleClickOnCross}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <span className={classes.title}>{title}</span>
            <Button className={classes.closeButton} onClick={() => handleClickOnCross()}>
              <CloseIcon className={classes.closeIcon} />
            </Button>
          </div>
          <div className={classes.content}>{children}</div>
          <div className={classes.actionPanel}>
            <div className={classes.loaderWrap}>{isLoading && <Loader />}</div>
            <div className={classes.buttonsWrap}>
              <Button
                className={classes.cancelButton}
                onClick={() => handleCloseClick()}
                variant="text"
              >
                {cancelText}
              </Button>
              <Button
                className={clsx(classes.submitButton, {
                  [classes[submitButtonAdditionalClass]]: submitButtonAdditionalClass,
                })}
                onClick={() => handleSubmitClick()}
                variant="contained"
                color="secondary"
                disabled={isLoading || isSubmitButtonDisabled}
              >
                {submitButtonText}
              </Button>
            </div>
          </div>
        </div>
      </ClickAwayListener>
    </div>
  );
};

Popup.propTypes = {
  children: PropTypes.node,
  toggleState: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onReject: PropTypes.func,
  title: PropTypes.string,
  submitButtonText: PropTypes.string,
  isLoading: PropTypes.bool,
  isSubmitButtonDisabled: PropTypes.bool,
  submitButtonAdditionalClass: PropTypes.oneOf(['danger', 'default']),
  cancelText: PropTypes.string,
};

Popup.defaultProps = {
  cancelText: 'Cancel',
};

export default Popup;
