import AuthSlice from './AuthSlice';
import SuccessorBorrowersSlice from './SuccessorBorrowersSlice';
import OrganizationsSlice from './OrganizationsSlice';
import PersonsSlice from './PersonsSlice';
import AdministrativeAgentsSlice from './AdministrativeAgentsSlice';
import ManagersSlice from './ManagersSlice';
import LoansSlice from './LoansSlice';
import MembersSlice from './MembersSlice';
import AssumedDealsSlice from './AssumedDealsSlice';
import RemindersSlice from './RemindersSlice';
import LiveLoansSlice from './LiveLoansSlice';
import BloombergConfigurationsSlice from './BloombergConfigurationsSlice';
import UsersSlice from './UsersSlice';
import OutstandingAccountantsSlice from './OutstandingAccountantsSlice';
import SummaryOfDealsSlice from './SummaryOfDealsSlice';
import TaskReportSlice from './ReportTaskSlice';
import OutstandingBindersSlice from './OutstandingBindersSlice';
import BuyUpPendingsSlice from './BuyUpPendingsSlice';
import OIDBalanceReportsSlice from './OIDBalanceReportsSlice';
import InterestAccrualReportSlice from './MortgageInterestAccrualReportSlice';
import ReconciliationDiscrepanciesSlice from './ReconciliationDiscrepanciesSlice';
import MissingReconciliationReportSlice from './MissingReconciliationReportSlice';
import SearchResultsChoicesSlice from './SearchResultsChoicesSlice';
import DataValidationReportSlice from './DataValidationReportSlice';
import FundingNotesSlice from './FundingNotesSlice';
import FreddieFloatPaymentsSlice from './FreddieFloatPaymentsSlice';

export default {
  AdministrativeAgentsSlice,
  AssumedDealsSlice,
  AuthSlice,
  BloombergConfigurationsSlice,
  BuyUpPendingsSlice,
  DataValidationReportSlice,
  FreddieFloatPaymentsSlice,
  FundingNotesSlice,
  InterestAccrualReportSlice,
  LiveLoansSlice,
  LoansSlice,
  ManagersSlice,
  MembersSlice,
  MissingReconciliationReportSlice,
  OIDBalanceReportsSlice,
  OrganizationsSlice,
  OutstandingAccountantsSlice,
  OutstandingBindersSlice,
  PersonsSlice,
  ReconciliationDiscrepanciesSlice,
  RemindersSlice,
  SearchResultsChoicesSlice,
  SuccessorBorrowersSlice,
  SummaryOfDealsSlice,
  TaskReportSlice,
  UsersSlice,
};
