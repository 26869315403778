import React, { useEffect, useMemo } from 'react';

import { PropTypes } from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { LOAN_HASH } from 'src/enums';

import TableSummary from 'components/TableSummary';
import LoanEditableTable from 'components/LoanEditableTable';
import NavigationAlertPopup from 'components/NavigationAlertPopup';

import { useUpdateLoanDefeasedPaymentMutation } from 'domain/loanDefeasedPayment/apiSlice';
import * as LoanDefeasedPaymentService from 'domain/loanDefeasedPayment/service';

import { useEditableTableControls, EditableTableProvider } from 'hooks/useEditableTableControls';
import { useErrors } from 'hooks/useErrors';

import {
  resolver,
  prepareBeforeSending,
  prepareBeforeSetting,
  formFields,
  defaultValues,
} from 'pages/LoanDefeasedPayments/validation';
import useStyles from 'pages/LoanDefeasedPayments/useStyles';

const TITLE = 'Payments on the Defeased Loan';
const HINT = 'Double-click the row to edit.';
const SAVE_BUTTON = 'Save changes';
const CANCEL_BUTTON = 'Cancel';

const FORM_ID = 'defeasedPaymentsForma';

export const DefeasedPaymentsContent = props => {
  const { loanId, defeasedPaymentsData, summaryData } = props;
  const classes = useStyles();
  const { formatErrors, displayErrorsInToast, setErrorsToForm } = useErrors();

  const [
    updateDefeasedPayment,
    {
      isLoading: isDefeasedPaymentUpdateLoading,
      error: defeasedPaymentUpdateError,
      isSuccess: isDefeasedPaymentUpdateSuccess,
    },
  ] = useUpdateLoanDefeasedPaymentMutation();

  const defeasedPaymentsTableHeaders = LoanDefeasedPaymentService.getHeaders();
  const defeasedPaymentsTableRows = useMemo(
    () => LoanDefeasedPaymentService.createTableRows(defeasedPaymentsData),
    [defeasedPaymentsData],
  );
  const summaryTableData = LoanDefeasedPaymentService.getSummaryTableData(summaryData);

  const tableControls = useEditableTableControls();

  const { isEditingFormShown, editableRowId, hideEditableRow } = tableControls;

  const methods = useForm({
    resolver,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
    defaultValues,
  });

  const { getValues, trigger: triggerValidation } = methods;

  useEffect(() => {
    if (isDefeasedPaymentUpdateSuccess) hideEditableRow();
  }, [isDefeasedPaymentUpdateSuccess]);

  useEffect(() => {
    if (!defeasedPaymentUpdateError) return;
    const errors = formatErrors(defeasedPaymentUpdateError);
    const { backendServicesError, nonFieldErrors, fieldErrors } = errors;
    displayErrorsInToast([backendServicesError, nonFieldErrors]);
    setErrorsToForm(methods.setError, methods.errors, fieldErrors);
  }, [defeasedPaymentUpdateError]);

  const handleSaveClick = async data => {
    const preparedData = prepareBeforeSending(data);
    await updateDefeasedPayment({
      loanId,
      defeasedPaymentId: editableRowId,
      data: preparedData,
    });
  };

  const handleCancelButtonClick = () => hideEditableRow();

  const handlePopupSaveClick = async () => {
    const isValid = await triggerValidation();
    if (!isValid) throw new Error();
    const data = getValues();
    const preparedData = prepareBeforeSending(data);
    await updateDefeasedPayment({
      loanId,
      defeasedPaymentId: editableRowId,
      data: preparedData,
    }).unwrap();
  };

  return (
    <>
      <NavigationAlertPopup when={isEditingFormShown} onSubmit={handlePopupSaveClick} />
      <div className={classes.contentHeader}>
        <h3 id={LOAN_HASH.details} className={classes.detailTitle}>
          {TITLE}
        </h3>
        {isEditingFormShown ? (
          <div>
            <Button
              onClick={handleCancelButtonClick}
              className={classes.cancelBtn}
              variant="contained"
              disabled={isDefeasedPaymentUpdateLoading}
            >
              {CANCEL_BUTTON}
            </Button>
            <Button
              type="submit"
              form={FORM_ID}
              variant="contained"
              color="secondary"
              disabled={isDefeasedPaymentUpdateLoading}
            >
              {SAVE_BUTTON}
            </Button>
          </div>
        ) : (
          <p className={classes.hint}>{HINT}</p>
        )}
      </div>
      <div className={classes.detailWrap}>
        <div className={classes.tableWrap}>
          <FormProvider {...methods}>
            <EditableTableProvider {...tableControls}>
              <LoanEditableTable
                onSave={handleSaveClick}
                columns={defeasedPaymentsTableHeaders}
                rows={defeasedPaymentsTableRows}
                dataItems={defeasedPaymentsData}
                prepareBeforeSetting={prepareBeforeSetting}
                formId={FORM_ID}
                formFields={formFields}
              />
            </EditableTableProvider>
          </FormProvider>
          <TableSummary summaryData={summaryTableData} />
        </div>
      </div>
    </>
  );
};

DefeasedPaymentsContent.propTypes = {
  defeasedPaymentsData: PropTypes.arrayOf(LoanDefeasedPaymentService.defeasedPaymentsDataPropTypes),
  loanId: PropTypes.number,
  summaryData: PropTypes.shape(LoanDefeasedPaymentService.summaryPropTypes),
};
