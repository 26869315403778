import React, { useEffect } from 'react';

import OrganizationForm from 'components/OrganizationForm';

import { useCreateOrganizationMutation } from 'domain/organization/apiSlice';

import useRouter from 'hooks/useRouter';

import ContentLayout from 'layouts/ContentLayout';

import OrganizationShowPresenter from 'presenters/OrganizationShowPresenter';

import { appRoutes } from 'router/routes';

const OrganizationAdd = () => {
  const { push } = useRouter();

  const [
    createOrganization,
    {
      error: organizationCreateError,
      isSuccess: isOrganizationCreateSuccess,
      isLoading: isOrganizationCreateLoading,
    },
  ] = useCreateOrganizationMutation();

  const previousRoute = appRoutes.partiesOrganizationsPath();

  const handleSubmitClick = values => createOrganization(values);

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isOrganizationCreateSuccess) push(previousRoute);
  }, [isOrganizationCreateSuccess]);

  return (
    <ContentLayout>
      <OrganizationForm
        isEdit={false}
        formValues={OrganizationShowPresenter.defaultValues()}
        pageTitle="New Organization"
        submitButtonText="Add"
        isDefaultDataLoading={isOrganizationCreateLoading}
        requestError={organizationCreateError}
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        previousRoute={previousRoute}
      />
    </ContentLayout>
  );
};

export default OrganizationAdd;
