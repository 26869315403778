import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import useStyles from './useStyles';

export const PageNotFoundMessage = props => {
  const { className } = props;

  const classes = useStyles();

  return (
    <div className={clsx(className, classes.main)}>
      <div className={classes.signContainer}>
        <span className={classes.signCode}>404</span>
        <span className={classes.signText}>Page not found</span>
      </div>
    </div>
  );
};

PageNotFoundMessage.propTypes = {
  className: PropTypes.string,
};
