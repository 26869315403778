export const createIndexTag = (result, type) =>
  result
    ? [...result.map(({ id }) => ({ type, id })), { type, id: 'LIST' }]
    : [{ type, id: 'LIST' }];

const createShowTag = (result, type) => (result ? [{ id: result.id, type }] : [type]);

export const createTag = {
  index: createIndexTag,
  show: createShowTag,
};

export const getOptionsQueryParams = () => ({ refetchOnMountOrArgChange: false });

export const invalidateOnSuccess = successTags => result => (result ? successTags : []);
