import React from 'react';

import PropTypes from 'prop-types';

import SideNavLink from 'components/SideNavLink';

const SideNav = ({ links = [] }) => (
  <nav>
    {links.map(navItem => (
      <SideNavLink key={navItem.name} {...navItem} />
    ))}
  </nav>
);

SideNav.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SideNav;
