import * as Yup from 'yup';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import { transformDate } from 'utils/date';

export const bankAccountValidationSchema = Yup.object({
  name: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  number: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  status: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  establishedDate: Yup.string()
    .transform(transformDate)
    .nullable()
    .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
  accountType: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
  provider: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
});

export const optionalAddressSchemas = Yup.object({
  street: Yup.string().trim().nullable(),
  city: Yup.string().trim().nullable(),
  state: Yup.string().trim().nullable(),
  postalCode: Yup.string().trim().nullable(),
});
