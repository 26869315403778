import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    gridLayout: {
      display: 'grid',
      gridTemplateColumns: 'auto minmax(0, 1fr)',
      gridTemplateRows: `${theme.sizes.header.height}px
        minmax(0, calc(100vh - ${theme.sizes.header.height}px))`,
      gridTemplateAreas: "'header header' 'sidebar main'",
      maxHeight: '100vh',
    },
    main: {
      gridArea: 'main',
      backgroundColor: theme.palette.linkWater,
      maxWidth: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    },
    sidebar: {
      width: '256px',
      paddingTop: '24px',
      gridArea: 'sidebar',
      backgroundColor: theme.palette.white,
      borderRight: `1px solid ${theme.palette.catskillWhite}`,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'auto',
    },
    sidebarTitle: {
      margin: 0,
      padding: [0, 24],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    contentActive: {
      backgroundColor: theme.palette.feta,
    },
    contentInactive: {
      background: theme.palette.inactiveStatusBackground,
    },
    contentActiveInPayment: {
      backgroundColor: theme.palette.feta,
    },
    contentActivePaid: {
      backgroundColor: theme.palette.fairPink,
    },
    hiddenNavButton: {
      cursor: 'pointer',
      position: 'fixed',
      width: 30,
      height: 32,
      left: 0,
      top: 54,
      borderRadius: '0 4px 4px 0',
      backgroundColor: '#007BC2',
      border: 0,
      outline: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 3,
    },
    hideNavButton: {
      position: 'fixed',
      width: 10,
      left: 256,
      '& > span, & > img': {
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        color: '#ffffff',
        marginLeft: 6,
        opacity: '0%',
      },
      '&:hover': {
        content: "''",
        width: 64,
      },
      '&:hover > span, & > img': {
        content: "''",
        opacity: '100%',
      },
    },
    buttonImage: {
      marginRight: 6,
    },
    buttonText: {
      marginTop: 1.5,
    },
    hiddenButtonText: {
      display: 'none',
    },
    hiddenButtonImage: {
      display: 'none',
    },
    hiddenSidebar: {
      width: 8,
      overflow: 'hidden',
      paddingLeft: 8,
    },
  }),
  { name: 'RootLayout' },
);
