import { useSelector } from 'react-redux';

import { useSearchResultsChoicesActions } from 'store/SearchResultsChoicesSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { addLinks, isSuccessorBorrowersSearch } from 'utils/choicesSearch';
import { selectResultsFromResponse } from 'utils/objects';

const useChoicesSearch = searchKey => {
  const { loadLoansSearchChoices, loadSuccessorBorrowersSearchChoices, clearChoices } =
    useSearchResultsChoicesActions();

  const choices = useSelector(state => state.SearchResultsChoicesSlice.searchResultsChoices);
  const choisesData = selectResultsFromResponse(choices);
  const loadingStatus = useSelector(state => state.SearchResultsChoicesSlice.loadingStatus);

  const loadChoices = async params => {
    if (isSuccessorBorrowersSearch(searchKey)) {
      loadSuccessorBorrowersSearchChoices(params);
      return;
    }
    loadLoansSearchChoices(params);
  };

  return {
    isSuccessorBorrowersSearch,
    isChoicesLoading: useFetchStatus(loadingStatus).isPending,
    loadChoices,
    clearChoices,
    searchChoices: addLinks(choisesData, searchKey),
    hasNextPage: !!choices?.data?.next,
  };
};

export default useChoicesSearch;
