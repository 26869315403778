import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    list: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'start',
      gap: '10px',
    },
    listTitle: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.4)',
      marginBottom: '8px',
    },
    listWrap: {
      paddingBottom: '17px',
      paddingTop: '17px',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    summaryList: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '120px',
      rowGap: '10px',
      paddingTop: '0px',
      paddingBottom: '0px',
      paddingLeft: '0px',
      paddingRight: '80px',
    },
    listItem: {
      padding: '0px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    gridLayout: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '24px',
      rowGap: '16px',
    },
    blockPadding: {
      marginBottom: '40px',
    },
    SBSummary: {
      marginTop: '24px',
      marginBottom: '40px',
    },
    tableWrap: {
      marginBottom: '40px',
    },
    accountingTable: {
      '& thead th:nth-child(-n+3)': {
        textAlign: 'left',
      },
      '& thead th:nth-child(n+4)': {
        textAlign: 'right',
      },
      '& thead th:nth-child(9)': {
        whiteSpace: 'pre',
      },
      '& tbody td': {
        color: theme.palette.elephant,
      },
      '& tbody td:nth-child(n+4)': {
        textAlign: 'right',
      },
    },
    tableFooter: {
      background: theme.palette.athensGray,
      '& td:nth-child(n+4)': {
        textAlign: 'right',
      },
    },
    tableFooterFont: {
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    tableBottomCell: {
      padding: '12px',
      whiteSpace: 'nowrap',
    },
    fontColorMain: {
      color: theme.palette.elephant,
    },
    bodyBold: {
      fontWeight: '500',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
    body1: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(18),
      fontStyle: 'normal',
      color: theme.palette.elephant,
    },
  }),
  { name: 'OIDBalanceSuccessorBorrowerDetails' },
);
