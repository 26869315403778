import { isNil, isEmpty } from 'ramda';

export const prepareDataValidationReport = dataList => {
  if (isNil(dataList)) return [];
  return dataList.map((errorItem, index) => {
    const hashPath = `link${index}`;
    return {
      title: errorItem.error,
      path: `#${hashPath}`,
      idTag: `${hashPath}`,
      ...errorItem,
    };
  });
};

export const getNavLinks = data =>
  isNil(data)
    ? []
    : data.filter(item => !isEmpty(item.loans)).map(({ title, path }) => ({ title, path }));

export const getLoansTitle = loan => {
  const { id, filingRef, dealName } = loan;
  return `(${id}) (${filingRef}) ${dealName}`;
};
