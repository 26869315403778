import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  filterContainer: {
    display: 'block',
    width: '100%',
    minWidth: '200px',
  },
  optionHeader: {
    marginBottom: '12px',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  option: {
    padding: '0 0 0 16px',
    justifyContent: 'start',
    height: '100%',
  },
  listbox: {
    maxHeight: '60vh',
    '& div': {
      borderBottom: `1px solid ${theme.palette.catskillWhite}`,
    },
    '& li:last-child>div': {
      borderBottom: 'none',
    },
  },
  optionContainer: {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  optionKey: {
    marginRight: '30px',
    marginBottom: '6px',
    minWidth: '180px',
    fontSize: '12px',
    lineHeight: '14px',
    color: theme.palette.santasGray,
  },
  optionValue: {
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
  },
  table: {
    width: '100%',
  },
  tableRoot: {
    borderCollapse: 'separate',
  },
  tableCellRoot: {
    padding: 0,
    border: 'none',
  },
  filterInput: {
    width: '100%',
  },
  loader: {
    marginTop: '-10px',
    width: '30px',
  },
}));
