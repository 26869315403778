import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import useStyles from 'components/MembersNestedList/useStyles';

import MemberSummary from './components/MemberSummary';
import SuccessorBorrowersNestedList from './components/SuccessorBorrowersNestedList';

export const MembersNestedList = props => {
  const classes = useStyles();
  const {
    members,
    successorBorrowerDetailsLink,
    loanDetailsLink,
    memberDetailsLink,
    valueColumnHeaderText,
  } = props;

  return (
    <>
      {members.map((member, index) => {
        const isFirstItem = index === 0;
        return (
          <div className={classes.root} key={index}>
            <Accordion square>
              <AccordionSummary
                className={clsx(classes.summary)}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                  content: classes.accordionSummaryContent,
                  expanded: classes.accordionExpanded,
                  expandIcon: classes.accordionExpandIcon,
                }}
              >
                <MemberSummary
                  member={member}
                  valueColumnHeaderText={isFirstItem ? valueColumnHeaderText : null}
                  memberDetailsLink={memberDetailsLink}
                />
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <SuccessorBorrowersNestedList
                  successorBorrowers={member.successorBorrowers}
                  detailsLinks={{ successorBorrowerDetailsLink, loanDetailsLink }}
                />
              </AccordionDetails>
            </Accordion>
          </div>
        );
      })}
    </>
  );
};

MembersNestedList.propTypes = {
  members: arrayOf(PropTypes.shape({})),
  memberDetailsLink: PropTypes.shape({
    path: PropTypes.func,
    linkName: PropTypes.string,
  }),
  successorBorrowerDetailsLink: PropTypes.shape({
    path: PropTypes.func,
    linkName: PropTypes.string,
  }),
  loanDetailsLink: PropTypes.shape({ path: PropTypes.func, linkName: PropTypes.string }),
  valueColumnHeaderText: PropTypes.string,
};
