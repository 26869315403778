import { equals, isNil } from 'ramda';

export const getFormFieldValue = (fieldKey, values) => {
  const fieldKeyArray = fieldKey.split('.');
  const value =
    fieldKeyArray.length > 1
      ? values?.[fieldKeyArray[0]]?.[fieldKeyArray[1]]
      : values?.[fieldKeyArray[0]];

  return isNil(value) || equals(value, '') ? undefined : value;
};
