import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button } from '@material-ui/core';
import { DEAL_FILTER, getDealFilterNames } from 'src/enums';
import { isEmpty } from 'ramda';

import DocumentTitle from 'components/DocumentTitle';
import Header from 'components/Header';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';
import ListTable from 'components/ListTable';
import NoDataMessage from 'components/NoDataMessage';

import { LOANS_STATUSES } from 'const';

import { useGetExtendedLoansQuery } from 'domain/extendedLoan/apiSlice';
import { DealsService } from 'domain/extendedLoan/service';

import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { dealsSideLinks as createDealsSideLinks } from 'utils/routes';

import useStyles from './useStyles';

const TITLE = 'Deals';

export const Deals = () => {
  const classes = useStyles();

  const dealFilterKeys = Object.keys(DEAL_FILTER);

  const { elementRef: pageHeaderRef, elementHeight: fixedTopOffset } = useRefElementHeight();

  const {
    data: dealsInProgress,
    isFetching: isDealsInProgressLoading,
    error: dealsInProgressLoadError,
  } = useGetExtendedLoansQuery({ status: LOANS_STATUSES.inProgress });

  const {
    data: dealsIndifinitelyOnHold,
    isFetching: isDealsIndifinitelyOnHoldLoading,
    error: dealsIndifinitelyOnHoldLoadError,
  } = useGetExtendedLoansQuery({ status: LOANS_STATUSES.indefinitelyOnHold });

  useHandleRtkQueryErrors(dealsInProgressLoadError, dealsIndifinitelyOnHoldLoadError);

  const listByFilters = DealsService.sortDealsByFilters({
    [LOANS_STATUSES.inProgress]: dealsInProgress,
    [LOANS_STATUSES.indefinitelyOnHold]: dealsIndifinitelyOnHold,
  });

  const isDealsDataLoading = isDealsInProgressLoading || isDealsIndifinitelyOnHoldLoading;

  const tableHeader = DealsService.getTableHeader();

  const sideBarContent = {
    title: 'Deals',
    links: createDealsSideLinks(listByFilters, isDealsDataLoading),
  };

  const titleNavLinkStyle = {
    scrollMarginTop: fixedTopOffset,
  };

  return (
    <RootLayoutV2 sideBarContent={sideBarContent}>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={TITLE} />
        <Header title={TITLE} ref={pageHeaderRef} shouldUseSidebarState>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.loanCreatePath()}
              className={classes.addItem}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Deal
            </Button>
          </ActionPanelButtonWrapper>
        </Header>
        {dealFilterKeys.map(filter => {
          const filterName = DEAL_FILTER[filter];
          const tableData = listByFilters[filterName];
          return (
            <>
              <h3
                id={filterName}
                className={classes.contentTitle}
                key={filter}
                style={titleNavLinkStyle}
              >
                {getDealFilterNames()[filter]}
              </h3>
              {isEmpty(tableData) ? (
                <NoDataMessage />
              ) : (
                <ListTable
                  key={`ListTable-${filter}`}
                  detailPath={appRoutes.loanPath}
                  isLoading={isDealsDataLoading}
                  tableHeader={tableHeader}
                  tableData={tableData}
                  columnConfig={DealsService.columnConfig}
                  isOuterScrollable
                  fixedTopOffset={fixedTopOffset}
                />
              )}
            </>
          );
        })}
      </ContentLayout>
    </RootLayoutV2>
  );
};
