import React, { useRef } from 'react';

import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useController, useFormContext } from 'react-hook-form';
import { Input } from '@material-ui/core';
import clsx from 'clsx';
import NumberFormat from 'react-number-format';

import FormError from 'components/FormError';

import { getFormFieldValue } from 'utils/getFormFieldValue';

import useStyles from './useStyles';

const REQUIRED = ' *';

const FormRowDecimalInput = props => {
  const { field, values, isColumnView, disabled, isRequired } = props;
  const classes = useStyles();
  const inputRef = useRef();
  const { fieldKey, displayedName, multiline, inputType = 'text' } = field;
  const initialValue = getFormFieldValue(fieldKey, values);

  const methods = useFormContext();
  const { control, errors } = methods;
  const {
    field: { onChange, ...inputProps },
  } = useController({
    name: fieldKey,
    control,
    defaultValue: initialValue,
  });

  const handleValueChange = inputValues => {
    const { value } = inputValues;
    onChange(value);
  };

  return (
    <div
      className={clsx(classes.formRow, {
        [classes.divider]: field.hasMargin,
        [classes.columnView]: isColumnView,
      })}
    >
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
        {isRequired && <span className={classes.requiredMark}>{REQUIRED}</span>}
      </label>
      <div className={classes.inputWrap}>
        <NumberFormat
          id={fieldKey}
          name={fieldKey}
          type={inputType}
          autoComplete="off"
          onValueChange={handleValueChange}
          customInput={Input}
          inputRef={inputRef}
          multiline={multiline}
          disabled={disabled}
          decimalScale={2}
          thousandSeparator=","
          {...inputProps}
        />
        <ErrorMessage
          errors={errors}
          name={fieldKey}
          render={({ message }) => <FormError message={message} />}
        />
      </div>
    </div>
  );
};

FormRowDecimalInput.propTypes = {
  field: PropTypes.shape({
    fieldKey: PropTypes.string,
    displayedName: PropTypes.string,
    hasMargin: PropTypes.bool,
    multiline: PropTypes.bool,
    inputType: PropTypes.string,
  }),
  errors: PropTypes.shape({}),
  values: PropTypes.shape({}),
  isColumnView: PropTypes.bool,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default FormRowDecimalInput;
