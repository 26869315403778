import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { FETCH_STATUSES } from 'enums';

import { useLongPollingWithRedux } from 'hooks/useLongPollingWithRedux';

const initialState = {
  dataValidationReport: {
    loadingStatus: FETCH_STATUSES.idle,
    items: null,
    errors: false,
  },
};

const dataValidationReportReducer = {
  startDataValidationReportLoad(state) {
    state.dataValidationReport.loadingStatus = FETCH_STATUSES.pending;
    state.dataValidationReport.items = null;
    state.dataValidationReport.errors = null;
  },
  dataValidationReportLoadFulfilled(state, { payload }) {
    state.dataValidationReport.items = payload;
    state.dataValidationReport.loadingStatus = FETCH_STATUSES.fulfilled;
  },
  dataValidationReportLoadRejected(state, { payload }) {
    state.dataValidationReport.loadingStatus = FETCH_STATUSES.failed;
    state.dataValidationReport.items = null;
    if (payload) {
      state.dataValidationReport.errors = payload;
    }
  },
};

const dataValidationReportSlice = createSlice({
  name: 'dataValidationReport',
  initialState,
  reducers: {
    ...dataValidationReportReducer,
  },
});

export default dataValidationReportSlice.reducer;

export const useDataValidationActions = () => {
  const dispatch = useDispatch();

  const createDataValidationLongPollingWorker = useLongPollingWithRedux({
    onStart: () => dispatch(dataValidationReportSlice.actions.startDataValidationReportLoad()),
    onFailure: error =>
      dispatch(dataValidationReportSlice.actions.dataValidationReportLoadRejected(error)),
    onSuccess: result =>
      dispatch(dataValidationReportSlice.actions.dataValidationReportLoadFulfilled(result)),
  });

  const dataValidationReport = useSelector(
    state => state.DataValidationReportSlice.dataValidationReport,
  );

  return { createDataValidationLongPollingWorker, dataValidationReport };
};
