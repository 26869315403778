import React, { useEffect, useState } from 'react';

import { isNil, isEmpty } from 'ramda';
import OIDBalanceRepository from 'repositories/OIDBalanceRepository';

import YearSelectAutocomplete from 'components/YearSelectAutocomplete';
import DocumentTitle from 'components/DocumentTitle';
import OidReportDetailsHeader from 'components/OidReportDetailsHeader';

import useRouter from 'hooks/useRouter';
import { useOIDBalanceMemberDetails } from 'hooks/api/useOIDBalanceMemberDetails';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';
import { useReportTask } from 'hooks/api/useReportTask';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { getCurrentYear } from 'utils/date';
import { getYearsOptions, getSelectValueYear } from 'utils/select';
import { reportActions } from 'utils/reports';

import AccountingTable from './components/AccountingTable';
import useStyles from './useStyles';

export const OIDBalanceMemberDetails = () => {
  const {
    params: { id },
  } = useRouter();
  const classes = useStyles();

  const { displayErrorsInToast, formatErrors, displayFieldErrorsInToast } = useErrors();

  const [year, setYear] = useState(getCurrentYear());

  const { loadMemberDetails, areMemberDetailsLoading, memberDetailsLoadingError, memberDetails } =
    useOIDBalanceMemberDetails();

  const { elementRef: headerRef, elementHeight: fixedTopOffset } = useRefElementHeight();

  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading } = useReportTask();

  useEffect(() => {
    loadMemberDetails({ memberId: id, year });
  }, [year]);

  useEffect(() => {
    if (!memberDetailsLoadingError) return;
    const { backendServicesError, nonFieldErrors, fieldErrors } =
      formatErrors(memberDetailsLoadingError);
    displayErrorsInToast([backendServicesError, nonFieldErrors]);
    if (!isEmpty(fieldErrors)) {
      displayFieldErrorsInToast(fieldErrors);
    }
  }, [memberDetailsLoadingError]);

  const handleYearSelect = (_, value) => {
    if (isNil(value)) return;
    setYear(value.option);
  };

  const fetchExcelModelLocation = () =>
    OIDBalanceRepository.OIDBalanceMemberReportExcel({ memberId: id, year });

  const handleDownloadExcelButtonClick = async () => {
    try {
      const href = await initiateTaskReportLoading(fetchExcelModelLocation);
      await reportActions.download(href);
    } catch (errors) {
      if (!isEmpty(errors)) {
        displayErrorsInToast([errors]);
        finishLoading(errors);
      }
    }
  };

  const name = memberDetails?.name;

  const pathsForBreadcrumbs = [
    { name: 'Live Forms', path: appRoutes.liveFormsPath() },
    { name: 'OID Balance Report', path: appRoutes.liveFormsOIDBalanceReportPath() },
    { name },
  ];

  const detailsLinks = [{ name: 'View Details', path: appRoutes.staffMemberPath(id) }];

  return (
    <RootLayoutV2 isLoading={areMemberDetailsLoading || isNil(memberDetails)}>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={name} />
        <OidReportDetailsHeader
          ref={headerRef}
          title={name}
          links={detailsLinks}
          pathsForBreadcrumbs={pathsForBreadcrumbs}
          isExcelLoading={isTaskReportLoading}
          onDownload={handleDownloadExcelButtonClick}
        >
          <YearSelectAutocomplete
            className={classes.autocomplete}
            options={getYearsOptions()}
            onChange={handleYearSelect}
            defaultValue={getSelectValueYear(year)}
          />
        </OidReportDetailsHeader>
        <AccountingTable fixedTopOffset={fixedTopOffset} />
      </ContentLayout>
    </RootLayoutV2>
  );
};
