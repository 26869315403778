import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    linksContainer: {
      marginTop: '8px',
      marginBottom: '40px',
      marginLeft: '-30px',
      display: 'flex',
      flexDirection: 'column',
      gap: '16px 32px',
      flexWrap: 'wrap',
      maxHeight: '90px',
      color: theme.palette.secondary.main,
    },
    text: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
    },
    fontColorMain: {
      color: theme.palette.elephant,
    },
    links: {
      '& a': {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
      },
      '& a:visited': {
        color: theme.palette.secondary.main,
      },
    },
  }),
  { name: 'Report' },
);
