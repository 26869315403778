import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    tableRow: {
      display: 'table-row',
      textDecoration: 'none',
      color: 'inherit',
      userSelect: 'text',
    },
    highlighted: {
      backgroundColor: theme.palette.ginFizz,
    },
    tableCell: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.capeCod,
      padding: theme.spacing(1.5),
      borderBottom: `1px solid ${theme.palette.mercury}`,
    },
    wordBreak: {
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
    },
  }),
  { name: 'TableBodyContent' },
);
