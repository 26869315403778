import React, { useEffect } from 'react';

import ManagerForm from 'components/ManagerForm';

import { useGetManagerQuery, useUpdateManagerMutation } from 'domain/manager/apiSlice';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';

import { appRoutes } from 'router/routes';

import { getEntityLastUpdated } from 'utils/date';

const ManagerEdit = () => {
  const { displayErrorsInToast } = useErrors();
  const {
    query: { id },
    push,
  } = useRouter();

  const {
    data: manager,
    error: managerLoadError,
    isLoading: isManagerLoading,
  } = useGetManagerQuery(id);

  const [
    updateManager,
    {
      error: managerUpdateError,
      isSuccess: isManagerUpdateSuccess,
      isLoading: isManagerUpdateLoading,
    },
  ] = useUpdateManagerMutation();

  useEffect(() => {
    if (managerLoadError) displayErrorsInToast([managerLoadError]);
  }, [managerLoadError]);

  const previousRoute = appRoutes.staffManagerPath(id);

  const handleSubmitClick = values => updateManager({ id, manager: values });

  const handleCancelClick = () => push(previousRoute);

  useEffect(() => {
    if (isManagerUpdateSuccess) push(previousRoute);
  }, [isManagerUpdateSuccess]);

  const lastUpdated = getEntityLastUpdated(manager);

  const isDataLoading = isManagerLoading || isManagerUpdateLoading;

  return (
    <ContentLayout>
      <ManagerForm
        isEdit
        formValues={manager}
        pageTitle="Manager Details"
        submitButtonText="Update"
        onSubmit={handleSubmitClick}
        onCancel={handleCancelClick}
        isDefaultDataLoading={isDataLoading}
        requestError={managerUpdateError}
        previousRoute={previousRoute}
        lastUpdated={lastUpdated}
      />
    </ContentLayout>
  );
};

export default ManagerEdit;
