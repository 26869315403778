import React, { createContext, useMemo } from 'react';

import { usePaginationAndOrdering } from 'hooks/usePaginationAndOrdering';

export const PaginationAndOrderingContext = createContext();

export const PaginationAndOrderingProvider = props => {
  const { children } = props;
  const paginationMethods = usePaginationAndOrdering();

  const value = useMemo(() => ({ ...paginationMethods, ...props }), [paginationMethods, props]);

  return (
    <PaginationAndOrderingContext.Provider value={value}>
      {children}
    </PaginationAndOrderingContext.Provider>
  );
};
