import { isNil, filter } from 'ramda';

import PersonIndexPresenter from 'presenters/PersonIndexPresenter';

import { getSelectOptions } from 'utils/getSelectOptions';

export const filterOrganizationsOptions = (organizationType, organizationsData) => {
  if (isNil(organizationsData)) return [];
  return getSelectOptions(
    organizationsData.filter(item => item.organizationType === organizationType),
  );
};

export const getOrganizationsTableData = (list = []) =>
  list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
    },
  }));

const filteredOrganizations = (organizationType, organizations) =>
  filter(item => !organizationType || item.organizationType === organizationType, organizations);

export const getFilteredTableData = (organizationType, organizations) =>
  getOrganizationsTableData(filteredOrganizations(organizationType, organizations));

export const getOrganizationTableData = data => {
  if (!data) {
    return {};
  }
  return {
    ...data,
    employees: PersonIndexPresenter.tableData(data.employees),
    organizationType: data?.organizationType?.name,
  };
};
