import { useSelector } from 'react-redux';

import { useTaskReportUrlActions } from 'store/ReportTaskSlice';

export const useReportTask = () => {
  const { isTaskReportLoading, taskReport, taskReportLoadError } = useSelector(
    state => state.TaskReportSlice,
  );
  const { initiateTaskReportLoading, finishLoading, resetReportTask } = useTaskReportUrlActions();

  return {
    isTaskReportLoading,
    taskReport,
    taskReportLoadError,
    finishLoading,
    initiateTaskReportLoading,
    resetReportTask,
  };
};
