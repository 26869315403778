import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    formRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
      marginBottom: 14,
    },
    formLabel: {
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: '0.2px',
      minWidth: 280,
      textAlign: 'left',
    },
    enumWrap: {
      width: '100%',
    },
    radioButtonLabel: {
      marginLeft: 0,
      marginRight: 16,
      padding: [8, 24],
      border: [1, 'solid', 'rgba(19, 51, 76, 0.2)'],
      boxSizing: 'border-box',
      borderRadius: 3,
    },
    activeRadioButtonLabel: {
      border: [1, 'solid', theme.palette.secondary.main],
      color: theme.palette.secondary.main,
    },
    enumError: {
      paddingTop: 12,
      alignSelf: 'flex-start',
    },
    requiredMark: {
      color: 'red',
    },
  }),
  { name: 'FormRowEnum' },
);
