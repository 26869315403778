import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    snapshotsWrapper: {
      padding: 24,
      background: theme.palette.white,
      border: [1, 'solid', theme.palette.athensGray],
      borderRadius: 2,
      maxWidth: 380,
      marginBottom: 24,
    },
    snapshotsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexFlow: 'row nowrap',
      marginBottom: 24,
    },
    snapshotsTitle: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(24),
      color: theme.palette.primary.main,
      margin: 0,
      fontWeight: 'bold',
      marginRight: 15,
    },
    snapshotsPool: {
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      fontWeight: 500,
      color: theme.palette.secondary.main,
    },
    snapshotsList: {
      listStyle: 'none',
      margin: 0,
      padding: 0,
      '&:last-child': {
        marginBottom: 24,
      },
      '& > li': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexFlow: 'row nowrap',
        paddingLeft: 10,
        fontSize: theme.typography.pxToRem(13),
        lineHeight: theme.typography.pxToRem(16),
        opacity: 0.8,
        color: theme.palette.primary.main,
        marginBottom: 10,
        '&:first-child': {
          paddingLeft: 0,
          fontWeight: 'bold',
        },
      },
    },
    snapshotsSummary: {
      paddingTop: 24,
    },
    snapshotsSummaryRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      flexFlow: 'row nowrap',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      opacity: 0.8,
      color: theme.palette.primary.main,
      marginBottom: '10px',
      gap: '8px',
    },
    snapshotsSummaryValue: {
      fontWeight: 'bold',
    },
  }),
  { name: 'SuccessorBorrowerSnapshots' },
);
