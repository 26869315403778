import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

export const resolver = yupResolver(
  Yup.object({
    oldPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    repeatPassword: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
  }),
);

export const fieldArray = [
  {
    fieldKey: 'oldPassword',
    displayedName: 'Current Password',
    type: FORM_FIELD_TYPE.password,
  },
  {
    fieldKey: 'newPassword',
    displayedName: 'New Password',
    type: FORM_FIELD_TYPE.password,
  },
  {
    fieldKey: 'repeatPassword',
    displayedName: 'Repeat New Password',
    type: FORM_FIELD_TYPE.password,
  },
];
