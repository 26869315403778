import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentPanel: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
      overflow: 'hidden',
      paddingTop: '12px',
      paddingBottom: '32px',
      paddingLeft: '24px',
      paddingRight: '24px',
      boxShadow: '0px 4px 4px 0px rgba(34, 60, 80, 0.15)',
    },
    headerRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: 80,
        height: 80,
      },
    },
    section: {
      marginBottom: 32,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    sidebarTitle: {
      marginTop: 24,
      padding: [0, 24],
      color: theme.palette.primary.main,
      opacity: 0.4,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      textTransform: 'uppercase',
      marginBottom: 12,
    },
    tableContainer: {
      marginBottom: 32,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    contentSubtitle: {
      margin: [28, 0, 8],
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.primary.main,
      opacity: 0.5,
      letterSpacing: '0.02em',
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: 0,
    },
    editButton: {
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',
      flexShrink: 0,
      '& span': {
        opacity: 0.75,
      },
    },
    detailWrap: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    contentWrap: {
      minHeight: '100%',
    },
    detailTitle: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.primary.main,
      opacity: 0.4,
      textTransform: 'uppercase',
      margin: '16px 0',
      fontWeight: 'normal',
    },
    statusWindow: {
      width: 312,
      marginLeft: 24,
      backgroundColor: theme.palette.white,
      border: [1, 'solid', theme.palette.athensGray],
      borderRadius: 2,
      padding: 24,
    },
    statusWrap: {
      marginBottom: 24,
      '&:last-child': {
        marginBottom: 0,
      },
    },
    statusTitle: {
      fontSize: theme.typography.pxToRem(11),
      lineHeight: theme.typography.pxToRem(16),
      color: theme.palette.neutral.light,
      margin: 0,
      marginBottom: 8,
      textTransform: 'uppercase',
    },
    statusValue: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(18),
      margin: 0,
      color: theme.palette.capeCod,
    },
    backgroundActive: {
      backgroundColor: theme.palette.feta,
    },
    backgroundInactive: {
      background: theme.palette.fairPink,
    },
  }),
  { name: 'SuccessorBorrowerLoans' },
);
