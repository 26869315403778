import { createTheme } from '@material-ui/core/styles';

import overrides from './overrides';
import palette from './palette';
import props from './props';

const htmlFontSize = 16;

const pxToRem = px => `${px / htmlFontSize}rem`;
const borders = `1px solid ${palette.mercury}`;

export const theme = createTheme({
  palette,
  borders,
  ...overrides,
  ...props,
  typography: {
    pxToRem,
    h1: {
      margin: [32, 0],
      fontSize: 29,
      fontWeight: 500,
      lineHeight: '32px',
      color: palette.primary.main,
      letterSpacing: '-0.4px',
    },
    h2: {
      fontSize: pxToRem(18),
      lineHeight: pxToRem(24),
      fontWeight: 500,
      letterSpacing: '-0.01em',
      color: palette.primary.main,
    },
    body3: {
      fontSize: pxToRem(12),
      lineHeight: pxToRem(26),
      fontWeight: 400,
      letterSpacing: '0.01em',
      color: palette.primary.main,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 780,
      md: 960,
      lg: 1280,
      xl: 1920,
      mobile: 780,
      tablet: 1280,
    },
  },
  sizes: {
    header: {
      height: 48,
    },
    sidebar: {
      width: 256,
    },
    actionPanel: {
      marginBottom: 16,
    },
    content: {
      width: '920px',
    },
  },
});
