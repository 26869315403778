import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

export const extendedLoanApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getExtendedLoans: builder.query({
      query: params => ({
        url: apiRoutes.loansExtendedListPath(),
        method: 'GET',
        params,
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.extendedLoan),
    }),
  }),
});

export const { useGetExtendedLoansQuery } = extendedLoanApi;
