import { isNil, isEmpty } from 'ramda';
import { decamelize } from 'humps';

import { SEARCH_BY_LABELS } from 'enums';

import { appRoutes } from 'router/routes';

const SEARCH_BY_KEY_MAPPING = {
  successorBorrower: {
    searchKey: 'search',
    ordering: 'filingRef,name',
    displayedKey: 'name',
  },
  name: {
    searchKey: 'name',
    ordering: 'name',
    displayedKey: 'dealName',
  },
  id: {
    searchKey: 'id',
    ordering: 'id',
    displayedKey: 'id',
  },
  filingRef: {
    searchKey: 'filingRef',
    ordering: 'filingRef',
    displayedKey: 'filingRef',
  },
  bloombergName: {
    searchKey: 'bloombergName',
    ordering: 'bloombergName',
    displayedKey: 'bloombergName',
  },
  custodianAccountNumber: {
    searchKey: 'custodianAccountNumber',
    ordering: 'custodianAccountNumber',
    displayedKey: 'custodianAccountNumber',
  },
  masterServicerLoanNumber: {
    searchKey: 'loanNumber',
    ordering: 'masterServicerLoanNumber',
    displayedKey: 'masterServicerLoanNumber',
  },
};

export const getSearchKey = searchKey => decamelize(SEARCH_BY_KEY_MAPPING[searchKey].searchKey);

export const getOrderingKey = searchKey => decamelize(SEARCH_BY_KEY_MAPPING[searchKey].ordering);

export const getTitle = (searchKey, item) => {
  const key = SEARCH_BY_KEY_MAPPING[searchKey].displayedKey;
  return item[key];
};

export const isSuccessorBorrowersSearch = key => key === 'successorBorrower';

export const addLinks = (choices, searchKey) => {
  if (isNil(choices)) return [];
  const path = isSuccessorBorrowersSearch(searchKey)
    ? appRoutes.successorBorrowerPath
    : appRoutes.loanPath;
  return choices.map(item => ({
    ...item,
    to: path(item.id),
  }));
};

export const createParams = (searchKey, inputValue) => {
  const params = {
    ordering: getOrderingKey(searchKey),
    [getSearchKey(searchKey)]: isEmpty(inputValue) ? undefined : inputValue,
  };
  return params;
};

export { SEARCH_BY_LABELS };
