import React, { useEffect, useRef } from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isEmpty } from 'ramda';

import FormError from 'components/FormError';

import useStyles from './useStyles';

export const DatePicker = props => {
  const {
    onSubmit,
    formField: { fieldKey, displayedName },
    muiViewTypes,
    format,
  } = props;
  const classes = useStyles();

  const inputRef = useRef(null);

  const methods = useFormContext();
  const { errors, control, handleSubmit, formState } = methods;
  const {
    field: { ...inputProps },
  } = useController({
    name: fieldKey,
    control,
  });

  useEffect(() => {
    if (!isEmpty(errors)) inputRef.current.focus();
  }, [errors, inputRef]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label className={classes.formLabel} htmlFor={fieldKey}>
        {displayedName}
      </label>
      <KeyboardDatePicker
        id={fieldKey}
        onAccept={handleSubmit(onSubmit)}
        views={muiViewTypes}
        autoComplete="off"
        className={classes.formInput}
        helperText={null}
        format={format}
        disabled={formState.isSubmitting}
        inputRef={inputRef}
        {...inputProps}
      />
      <ErrorMessage
        errors={errors}
        name={fieldKey}
        render={({ message }) => <FormError message={message} />}
      />
    </form>
  );
};

DatePicker.propTypes = {
  onSubmit: PropTypes.func,
  formField: PropTypes.shape({
    displayedName: PropTypes.string,
    fieldKey: PropTypes.string,
  }),
  muiViewTypes: arrayOf(PropTypes.string),
  format: PropTypes.string,
};
