import React from 'react';

import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { isEmpty } from 'ramda';
import ToolsRepository from 'repositories/ToolsRepository';

import YearSelect from 'components/YearSelect';
import Popup from 'components/Popup';

import { useReportTask } from 'hooks/api/useReportTask';
import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';

import { reportActions } from 'utils/reports';
import { getSelectValueYear, getYearsOptions } from 'utils/select';
import { getCurrentYear } from 'utils/date';

import useStyles from './useStyles';
import { resolver, formField } from './validation.js';

const TITLE = 'Agencies/Securities Percentage';
const SUCCESS = 'Successfully Generated';

const AgenciesSecuritiesPercentagePopup = props => {
  const classes = useStyles();
  const { setPopupState } = props;
  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading } = useReportTask();
  const { formatErrors, setErrorsToForm, displayErrorsInToast } = useErrors();
  const { showSuccessNotification } = useSnackbar();

  const methods = useForm({
    resolver,
    defaultValues: {
      [formField.fieldKey]: getSelectValueYear(getCurrentYear()).option,
    },
    shouldFocusError: true,
    mode: 'onSubmit',
  });

  const { watch, setError, errors } = methods;
  const watchYear = watch('year');

  const handleImportClose = () => {
    setPopupState(null);
  };

  const fetchReportLocation = () => ToolsRepository.securitiesPercentageReport({ year: watchYear });

  const handleImportSubmit = async () => {
    const toastText = `${TITLE} ${SUCCESS}`;
    try {
      const href = await initiateTaskReportLoading(fetchReportLocation);
      await reportActions.download(href);
      showSuccessNotification(toastText);
      handleImportClose();
    } catch (reportErrors) {
      if (!isEmpty(reportErrors)) {
        const { fieldErrors, backendServicesError, nonFieldErrors } = formatErrors(reportErrors);
        setErrorsToForm(setError, errors, fieldErrors);
        displayErrorsInToast([backendServicesError, nonFieldErrors]);
        finishLoading(reportErrors);
      }
    }
  };

  return (
    <Popup
      title={TITLE}
      submitButtonText="Download Report"
      toggleState={setPopupState}
      onSubmit={methods.handleSubmit(handleImportSubmit)}
      onClose={handleImportClose}
      isLoading={isTaskReportLoading}
    >
      <FormProvider {...methods}>
        <div className={classes.yearSelectWrap}>
          <YearSelect
            formField={formField}
            options={getYearsOptions(1, 10)}
            className={classes.yearSelect}
          />
        </div>
      </FormProvider>
    </Popup>
  );
};

AgenciesSecuritiesPercentagePopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default AgenciesSecuritiesPercentagePopup;
