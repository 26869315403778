import { ascend, descend, sort, path, isNil } from 'ramda';
import { camelize } from 'humps';

import { SORT_DIRECTION, SORTING_DIRECTION_SYMBOL } from 'enums';

export const sortTableDataByKey = (list, key, direction) => {
  if (isNil(list)) return null;
  const isAsc = direction === SORT_DIRECTION.asc;
  const sortingPath = path(['data', key]);
  const sortByKey = isAsc ? ascend(sortingPath) : descend(sortingPath);
  return sort(sortByKey, list);
};

const isDescSymbol = character => character === SORTING_DIRECTION_SYMBOL.desc;

export const parseQueryStringOrdering = ordering => {
  if (!ordering) return null;
  let sortingDirection = SORT_DIRECTION.asc;
  let sortedBy = ordering;
  const firstSymbol = ordering[0];
  if (isDescSymbol(firstSymbol)) {
    sortingDirection = SORT_DIRECTION.desc;
    sortedBy = ordering.substring(1);
  }
  return { sortingDirection, sortedBy: camelize(sortedBy) };
};
