import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    autocomplete: {
      marginTop: '40px',
    },
  }),
  { name: 'OIDBalanceMemberDetails' },
);
