import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    contentWrap: {
      padding: '12px 24px',
      width: '100%',
      minWidth: 'fit-content',
    },
    smallWidth: {
      margin: 'auto',
      minWidth: 'auto',
      maxWidth: `calc(${theme.sizes.content.width} + 48px)`,
    },
    disableTopPadding: {
      paddingTop: 0,
    },
  }),
  { name: 'ContentLayout' },
);
