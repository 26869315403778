import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    textarea: {
      marginBottom: '8px',
      '& label': {
        fontWeight: '400',
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(16),
        textTransform: 'uppercase',
        color: 'rgba(0, 0, 0, 0.4)',
      },
      '& .MuiInputLabel-formControl': {
        opacity: 1,
      },
      '& .MuiInputBase-root': {
        alignItems: 'flex-start',
        marginTop: '8px',
        height: '246px',
        overflowY: 'auto',
      },
    },
  }),
  { name: 'AdjustmentNoteInput' },
);
