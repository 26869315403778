import React, { useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import LoansRepository from 'repositories/LoansRepository';

import Popup from 'components/Popup';
import FormField from 'components/FormField';

import { LOANS_STATUSES } from 'const';

import { useOrganizations } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';
import { useSnackbar } from 'hooks/useSnackbar';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const UpdateAssumedDatesPopup = ({ setPopupState }) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const { formatErrors, displayErrorsInToast, setErrorsToForm } = useErrors();
  const { showSuccessNotification } = useSnackbar();
  const [isSubmitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  const methods = useForm({
    resolver,
  });

  const { setError, errors } = methods;

  const organizationParams = { sharingArrangementFinancialOrganizationOnly: 1 };

  const { loadOrganizations, organizationOptions } = useOrganizations();

  const fetchData = async () => {
    try {
      await loadOrganizations(organizationParams);
      setSubmitButtonDisabled(false);
    } catch (e) {
      setSubmitButtonDisabled(true);
      const { backendServicesError } = formatErrors(e);
      displayErrorsInToast([backendServicesError]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async formValues => {
    setIsLoading(true);
    const { assumedDate, targetAssumedDate } = formValues;
    const queryParams = {
      assumedDate: targetAssumedDate,
      status: LOANS_STATUSES.inProgress,
    };
    const updateParams = { assumedDate };

    LoansRepository.bulkUpdate(updateParams, queryParams)
      .then(response => {
        const {
          data: { affectedObjects },
        } = response.data;
        const text = pluralize('object', affectedObjects, true);
        showSuccessNotification(`${text} changed`);
        setIsLoading(false);
        setPopupState(null);
      })
      .catch(err => {
        const { fieldErrors, backendServicesError, nonFieldErrors } = formatErrors(err);
        displayErrorsInToast([backendServicesError, nonFieldErrors]);
        setErrorsToForm(setError, errors, fieldErrors);
        setIsLoading(false);
      });
  };

  const handleImportClose = () => {
    setPopupState(null);
  };

  const selectValues = {
    organizations: organizationOptions,
  };

  return (
    <Popup
      title="Update assumed dates"
      submitButtonText="Update"
      toggleState={setPopupState}
      onSubmit={methods.handleSubmit(handleSubmit)}
      onClose={handleImportClose}
      isLoading={isLoading}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
    >
      <FormProvider {...methods}>
        <form className={classes.formWrap}>
          <FormField fieldArray={fieldArray} errors={errors} selectValues={selectValues} />
        </form>
      </FormProvider>
    </Popup>
  );
};

UpdateAssumedDatesPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default UpdateAssumedDatesPopup;
