import React, { createContext } from 'react';

import { useScrolling } from 'hooks/useScrolling';

export const ScrollingContext = createContext();

export const ScrollingProvider = props => {
  const { children } = props;
  const scrollingMethods = useScrolling();

  return <ScrollingContext.Provider value={scrollingMethods}>{children}</ScrollingContext.Provider>;
};
