import PropTypes from 'prop-types';
import { fromPairs, keys, isEmpty } from 'ramda';

import Presenter from 'utils/PropTypesPresenter';

const countProps = {
  year: PropTypes.number,
  count: PropTypes.number,
};

const criteriaProps = {
  key: PropTypes.string,
  total: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.shape(countProps)),
};

const parseCriterionRow = item => ({
  id: item.key,
  data: {
    criteria: item.key,
    ...fromPairs(item?.values?.map(value => [value.year, value.count || ''])),
    total: item.total,
  },
});

export default new Presenter(
  {
    items: PropTypes.arrayOf(PropTypes.shape(criteriaProps)),
    summary: PropTypes.shape(criteriaProps),
  },
  {
    tableData(data) {
      if (!data) return [];
      return [...data.items.map(parseCriterionRow), parseCriterionRow(data?.summary)];
    },
    columnConfig(tableData) {
      if (isEmpty(tableData)) return {};
      return fromPairs(
        keys(tableData[0].data).map(key => {
          switch (key) {
            case 'criteria':
              return [key, { displayedName: 'Criteria' }];
            case 'total':
              return [key, { displayedName: 'Total' }];
            default:
              return [key, { displayedName: key }];
          }
        }),
      );
    },
    tableHeader(columnConfig = {}) {
      if (isEmpty(columnConfig)) return [];
      const { criteria, total, ...rest } = columnConfig;
      const headers = [...keys({ criteria }), ...keys(rest), ...keys({ total })];
      return headers;
    },
  },
);
