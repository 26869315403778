import React, { useEffect, useRef, useState } from 'react';

import ToolsRepository from 'repositories/ToolsRepository';
import PropTypes from 'prop-types';
import { join } from 'ramda';

import Popup from 'components/Popup';
import ToolsImport from 'components/ToolsImport';
import NonFieldError from 'components/NonFieldError';

import { useSnackbar } from 'hooks/useSnackbar';
import { useErrors } from 'hooks/useErrors';
import { useImportPolling } from 'hooks/useImportPolling';

import FetchHelpers from 'utils/FetchHelpers';

const defaultImportState = {
  files: [],
  error: null,
};

const ImportPopup = props => {
  const { setPopupState } = props;
  const [importData, setImportData] = useState(defaultImportState);
  const [isLoading, setIsLoading] = useState(false);
  const getImportOnTimeout = useRef(null);

  const setErrors = e => {
    setIsLoading(false);
    setImportData({
      ...defaultImportState,
      error: { ...importData.error, ...e },
    });
  };

  const {
    isTaskImportStatusSuccess,
    isTaskImportStatusFailure,
    isTaskImportStatusStarted,
    taskStatus,
    taskError,
    startImportPolling,
  } = useImportPolling({ requestMethod: FetchHelpers.getRaw });
  const { formatErrors, displayErrorsInToast } = useErrors();
  const { showSuccessNotification, showErrorNotification } = useSnackbar();

  useEffect(() => {
    if (isTaskImportStatusSuccess) {
      setPopupState(false);
      showSuccessNotification('File Successfully Imported');
    }
    if (isTaskImportStatusFailure) {
      setErrors(taskError);
      showErrorNotification('File import failure');
    }
  }, [taskStatus]);

  const handleImportSubmit = () => {
    if (importData.files.length > 0) {
      const reader = new FileReader();
      reader.onload = async () => {
        setIsLoading(true);
        try {
          const response = await ToolsRepository.import({
            file: importData.files[0],
          });
          await startImportPolling(response.headers.location);
        } catch (e) {
          setErrors(e);
          const { backendServicesError } = formatErrors(e);
          displayErrorsInToast([backendServicesError]);
        }
      };
      reader.readAsArrayBuffer(importData.files[0]);
    }
  };

  const handleImportClose = () => {
    setImportData(defaultImportState);
  };

  useEffect(() => () => clearTimeout(getImportOnTimeout.current), []);

  const nonFieldErrors = importData.error?.nonFieldErrors;

  return (
    <Popup
      title="Import File"
      submitButtonText="Import File"
      toggleState={setPopupState}
      onSubmit={handleImportSubmit}
      onClose={handleImportClose}
      isLoading={isLoading || isTaskImportStatusStarted}
    >
      <ToolsImport importData={importData} setImportData={setImportData} />
      {nonFieldErrors && <NonFieldError error={join(' ', nonFieldErrors)} />}
    </Popup>
  );
};

ImportPopup.propTypes = {
  setPopupState: PropTypes.func,
};

export default ImportPopup;
