import React from 'react';

import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import { isNil } from 'ramda';

import Popup from 'components/Popup';

import useStyles from './useStyles';

const TITLE = 'Leaving page';
const MESSAGE = 'All changes will be lost';
const STAY_BUTTON_TEXT = 'Back To Edit';
const LEAVE_BUTTON_TEXT = 'Leave';

export const LeavingPageAlertPopup = props => {
  const { when } = props;
  const classes = useStyles();

  const isSubpath = (currentLocation, nextLocation) =>
    !isNil(nextLocation) && nextLocation.pathname.startsWith(currentLocation.pathname);

  const handleWhen = (currentLocation, nextLocation) =>
    when && !isSubpath(currentLocation, nextLocation);

  return (
    <NavigationPrompt when={handleWhen}>
      {({ onCancel, onConfirm }) => (
        // onConfirm - confirm the transition
        // onCancel - cancel the transition
        <Popup
          title={TITLE}
          message={MESSAGE}
          submitButtonText={LEAVE_BUTTON_TEXT}
          cancelText={STAY_BUTTON_TEXT}
          onSubmit={onConfirm}
          onClose={onCancel}
          toggleState={() => null}
        >
          <div className={classes.popupInner}>
            <span className={classes.popupText}>{MESSAGE}</span>
          </div>
        </Popup>
      )}
    </NavigationPrompt>
  );
};

LeavingPageAlertPopup.propTypes = {
  when: PropTypes.bool,
};
