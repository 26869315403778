import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

export const resolver = yupResolver(Yup.object({}));

export const fieldArray = [
  {
    fieldKey: 'organizationId',
    displayedName: 'Sharing Arrangement Financial Organization',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'organizations',
    placeholder: 'Select Organization',
    isFullRow: true,
  },
];
