import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    postingForm: {
      display: 'none',
    },
  }),
  { name: 'ExternalPostingForm' },
);
