import React, { useEffect } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import {
  Button,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { keys } from 'ramda';

import CellInformation from 'components/CellInformation';
import DocumentTitle from 'components/DocumentTitle';

import { AdministrativeAgentService } from 'domain/administrativeAgent/service';
import { useGetAdministrativeAgentQuery } from 'domain/administrativeAgent/apiSlice';

import { CELL_TYPES } from 'enums';

import useRouter from 'hooks/useRouter';
import { useErrors } from 'hooks/useErrors';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { bankAccountInformationLabels } from 'presenters/BankAccountPresenter';

import { appRoutes } from 'router/routes';

import { getStaffLinks } from 'utils/routes';

import useStyles from './useStyles';

export const AdministrativeAgent = () => {
  const classes = useStyles();
  const {
    query: { id },
  } = useRouter();
  const { displayErrorsInToast } = useErrors();

  const {
    data: administrativeAgentData,
    isFetching: isAdministrativeAgentLoading,
    error: administrativeAgentLoadError,
  } = useGetAdministrativeAgentQuery(id);

  const administrativeAgentTableData =
    AdministrativeAgentService.getTableData(administrativeAgentData);

  const isInformationCell = key =>
    AdministrativeAgentService.administrativeAgentColumnConfig[key]?.cellType ===
    CELL_TYPES.information;

  useEffect(() => {
    if (administrativeAgentLoadError) displayErrorsInToast([administrativeAgentLoadError]);
  }, [administrativeAgentLoadError]);

  const sideBarContent = getStaffLinks();

  const title = administrativeAgentTableData?.name;

  return (
    <RootLayoutV2 sideBarContent={sideBarContent} isLoading={isAdministrativeAgentLoading}>
      <ContentLayout isSmall>
        <DocumentTitle title={title} />
        <h2 className={classes.contentSubtitle}>Administrative Agent</h2>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {title}
          </Typography>
          <Button
            type="button"
            className={classes.editButton}
            variant="text"
            color="primary"
            exact
            to={appRoutes.staffAdministrativeAgentEditPath(id)}
            component={NavHashLink}
          >
            Edit Administrative Agent
          </Button>
        </div>
        <div className={classes.tableContent}>
          <TableContainer component={Paper} classes={{ root: classes.tableContainer }}>
            <Table className={classes.table}>
              <TableBody>
                {keys(AdministrativeAgentService.administrativeAgentColumnConfig).map(key => (
                  <TableRow key={`${id}_${key}`}>
                    <TableCell className={classes.tableCell}>
                      {
                        AdministrativeAgentService.administrativeAgentColumnConfig[key]
                          .displayedName
                      }
                    </TableCell>
                    {isInformationCell(key) ? (
                      <TableCell className={classes.tableCell}>
                        <CellInformation
                          cellData={administrativeAgentTableData?.[key]}
                          fieldLabels={bankAccountInformationLabels}
                        />
                      </TableCell>
                    ) : (
                      <TableCell className={classes.tableCell}>
                        {administrativeAgentTableData?.[key]}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </ContentLayout>
    </RootLayoutV2>
  );
};
