import { makeStyles } from '@material-ui/core';

export default makeStyles(
  () => ({
    formGroup: {
      boxShadow: 'inset 2px 0px 0px rgba(19, 51, 76, 0.1)',
      '& label': {
        paddingLeft: 20,
      },
    },
  }),
  { name: 'FormGroup' },
);
