import React from 'react';

import PropTypes from 'prop-types';
import { Typography, Button } from '@material-ui/core';

import useStyles from './useStyles';

export const Message = props => {
  const { message } = props;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {message.title}
      </Typography>
      <Typography className={classes.message}>{message.message}</Typography>
      <Button
        variant="contained"
        color="secondary"
        href={message.button.path}
        className={classes.button}
      >
        {message.button.title}
      </Button>
    </div>
  );
};

Message.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  button: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string,
  }),
};
