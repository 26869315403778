import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { Typography, Link } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from 'components/MembersNestedList/useStyles';

export const LoansList = props => {
  const classes = useStyles();
  const {
    loans,
    detailsLinks: { loanDetailsLink },
  } = props;
  return loans.map((loan, index) => {
    const { loanName, reportValue, loanId } = loan;
    return (
      <div key={`l1_${index}`} className={classes.summary}>
        <div className={clsx(classes.summaryWrap, classes.loanPadding)}>
          <div>
            <Typography className={classes.fontNormal}>{`(${loanId}) ${loanName}`}</Typography>
            <Link
              className={classes.fontLink}
              color="secondary"
              href={loanDetailsLink.path(loanId)}
            >
              {loanDetailsLink.linkName}
            </Link>
          </div>
          <Typography className={clsx(classes.balance, classes.fontNormal)}>
            {reportValue}
          </Typography>
        </div>
      </div>
    );
  });
};

LoansList.propTypes = {
  loans: arrayOf(
    PropTypes.shape({
      loanId: PropTypes.number,
      loanName: PropTypes.string,
      reportValue: PropTypes.string,
    }),
  ),
  detailsLinks: PropTypes.shape({
    successorBorrowerDetailsLink: PropTypes.shape({
      path: PropTypes.func,
      linkName: PropTypes.string,
    }),
    loanDetailsLink: PropTypes.shape({ path: PropTypes.func, linkName: PropTypes.string }),
  }),
};
