import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    listContainer: {
      maxWidth: '920px',
      color: theme.palette.elephant,
    },
    list: {
      paddingBottom: 0,
      paddingTop: 0,
      '& li:last-child': {
        border: 'none',
      },
    },
    listItem: {
      padding: '16px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    name: {
      marginBottom: '4px',
    },
    listItemFont: {
      fontWeight: '400',
      fontSize: theme.typography.pxToRem(13),
      lineHeight: theme.typography.pxToRem(16),
      fontStyle: 'normal',
    },
    wordBreak: {
      wordBreak: 'break-word',
      overflowWrap: 'anywhere',
    },
    value: {
      alignSelf: 'baseline',
    },
    openInNewIcon: {
      fontSize: '1em',
      marginLeft: '1px',
    },
  }),
  { name: 'ReportTable' },
);
