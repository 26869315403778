import React from 'react';

import { useSnackbar as useNotistackSnackbar } from 'notistack';

import SnackbarToast from 'components/SnackbarToast';

const ERROR = 'Ooops! Something went wrong';
const SUCCESS = 'The operation has finished succesfully';

export const useSnackbar = () => {
  const { enqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  const showNotification = (message, isError = false, props = {}) => {
    const defaultMessage = isError ? ERROR : SUCCESS;
    const key = enqueueSnackbar(message ?? defaultMessage, {
      content: (id, text) => (
        <SnackbarToast
          onClose={() => isError && closeSnackbar(id)}
          id={id}
          message={text}
          isError={isError}
        />
      ),
      onClick: () => closeSnackbar(key),
      anchorOrigin: {
        horizontal: 'right',
        vertical: 'bottom',
      },
      ...props,
    });
  };

  const showErrorNotification = message => {
    showNotification(message, true, { persist: true });
  };

  return {
    showSuccessNotification: showNotification,
    showErrorNotification,
  };
};
