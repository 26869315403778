import React from 'react';

import PersonForm from 'components/PersonForm';

import { useGetPersonQuery } from 'domain/person/apiSlice';

import useRouter from 'hooks/useRouter';

import ContentLayout from 'layouts/ContentLayout';

import { getEntityLastUpdated } from 'utils/date';

const PersonEdit = () => {
  const {
    query: { id },
  } = useRouter();

  const { data: person } = useGetPersonQuery(id);

  const lastUpdated = getEntityLastUpdated(person);

  return (
    <ContentLayout>
      <PersonForm
        isEdit
        personId={id}
        formValues={person}
        pageTitle="Person Details"
        submitButtonText="Update"
        lastUpdated={lastUpdated}
      />
    </ContentLayout>
  );
};

export default PersonEdit;
