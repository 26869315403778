import PropTypes from 'prop-types';
import { isNil } from 'ramda';

import { FIELD_ALIGNMENT } from 'enums';

import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/numbers';

export const recentlyClosedDealsColumnConfig = {
  filingRef: {
    displayedName: 'Filling Ref',
  },
  id: {
    displayedName: 'PID',
  },
  dealName: {
    displayedName: 'Property Name',
    withTags: true,
    isLink: true,
  },
  assumedDate: {
    displayedName: 'Accepted Date',
  },
  parRepayDate: {
    displayedName: 'Par Repay Date',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  costOfSecurities: {
    displayedName: 'Cost of Securities',
    align: FIELD_ALIGNMENT.right,
  },
};

export const getColumnsKeys = () => Object.keys(recentlyClosedDealsColumnConfig);

export const getTableData = data => {
  if (isNil(data)) return null;
  const { items } = data;
  return items.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      assumedDate: formatDate(rowData.assumedDate),
      parRepayDate: formatDate(rowData.parRepayDate),
      maturityDate: formatDate(rowData.maturityDate),
      costOfSecurities: formatCurrency(rowData.costOfSecurities),
    },
  }));
};

export const getSummaryData = data => [
  {
    value: formatCurrency(data?.averageCostOfSecurities),
    label: 'Average Size of Deal for Last 20 Deals',
  },
  {
    value: formatCurrency(data?.totalCostOfSecurities),
    label: 'Total Cost of Securities for Last 20 Deals',
  },
];

export const recentlyClosedDealsPropTypes = PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      filingRef: PropTypes.number,
      dealName: PropTypes.string,
      assumedDate: PropTypes.date,
      parRepayDate: PropTypes.date,
      maturityDate: PropTypes.date,
      costOfSecurities: PropTypes.number,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  averageCostOfSecurities: PropTypes.number,
  totalCostOfSecurities: PropTypes.number,
});
