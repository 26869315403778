import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async interestAccrualsReportTask(params) {
    const url = apiRoutes.interestAccrualsReportPath();
    return FetchHelpers.post(url, params);
  },
  async interestAccrualsTasks(params) {
    const url = apiRoutes.interestAccrualsTasksPath();
    return FetchHelpers.post(url, params);
  },
};
