import React from 'react';

import { create } from 'jss';
import PropTypes from 'prop-types';
import { StylesProvider, ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import jssPresetDefault from 'jss-preset-default';

import { theme } from './theme';

const jss = create(jssPresetDefault());

export const CustomThemeProvider = ({ children }) => (
  <StylesProvider jss={jss}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StylesProvider>
);

CustomThemeProvider.propTypes = {
  children: PropTypes.node,
};

CustomThemeProvider.defaultProps = {
  children: null,
};
