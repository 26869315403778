/* eslint-disable no-param-reassign */
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

export default function loadResourceSlice(options) {
  const { resource, repository } = options;

  return {
    initialState: {
      [resource]: {
        loadingStatus: FETCH_STATUSES.idle,
        item: null,
      },
    },

    reducers: {
      loadResourceStart(state) {
        state[resource].loadingStatus = FETCH_STATUSES.pending;
        state[resource].item = null;
      },
      loadResourceSuccess(state, { payload }) {
        state[resource].loadingStatus = FETCH_STATUSES.fulfilled;
        state[resource].item = payload.data;
      },
      loadResourceFail(state, { payload: { errors } }) {
        state[resource].loadingStatus = FETCH_STATUSES.failed;
        state[resource].errors = errors;
      },
    },

    actionCreators(restDispatch) {
      return {
        loadResource: (...params) => {
          restDispatch('loadResourceStart');

          return repository
            .show(...params)
            .then(({ data }) => {
              restDispatch('loadResourceSuccess', data);
            })
            .catch(errors => {
              restDispatch('loadResourceFail', { errors });
              throw errors;
            });
        },
      };
    },
  };
}
