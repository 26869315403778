import apiRoutes from 'router/routes/ApiRoutes';

import FetchHelpers from 'utils/FetchHelpers';

export default {
  async index(params = {}) {
    const url = apiRoutes.missingReconciliationInputsPath();
    return FetchHelpers.get(url, params);
  },
};
