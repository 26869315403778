import { isNil } from 'ramda';

import { ListTableConst } from 'const';

import { STATUS_COLUMN } from 'enums';

export const isActive = value => value?.toLowerCase() === STATUS_COLUMN.active;
export const isInactive = value => value?.toLowerCase() === STATUS_COLUMN.inactive;
export const isStatusColumn = key => key === STATUS_COLUMN.columnName;

export const getRowDetailsPath = (detailPath, id) => (!isNil(detailPath) ? detailPath(id) : '');

export const getTableHeightFromWindow = tableContentRef => {
  const windowHeight = window.innerHeight;
  const tableContentTopOffset = tableContentRef.current?.getBoundingClientRect().top;
  return windowHeight - tableContentTopOffset - ListTableConst.TABLE_BOTTOM_OFFSET;
};
