import { createSlice } from '@reduxjs/toolkit';
import MembersRepository from 'repositories/MembersRepository';

import { createRestSlice } from 'utils/RestSlice';

const membersRestSlice = createRestSlice({
  resource: 'member',
  repository: MembersRepository,
  slices: ['loadMembers', 'loadMember', 'updateMember', 'createMember', 'deleteMember'],
});

const initialState = {
  ...membersRestSlice.initialState,
};

const membersSlice = createSlice({
  name: 'members',
  initialState,
  reducers: {
    ...membersRestSlice.reducers,
  },
});

export default membersSlice.reducer;
export const membersRestHooks = membersRestSlice.hooks(membersSlice, 'MembersSlice');
