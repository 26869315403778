import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    formRow: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'row nowrap',
    },
    columnView: {
      display: 'block',
    },
    formLabel: {
      display: 'block',
      color: theme.palette.primary.main,
      opacity: 0.5,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
      letterSpacing: '0.2px',
      minWidth: 280,
      textAlign: 'left',
      marginBottom: 14,
    },
    inputWrap: {
      width: '100%',
      minHeight: 56,
    },
    formInput: {
      width: '100%',
      opacity: 1,
      backgroundColor: theme.palette.white,
      '&:focus': {
        border: [2, 'solid', theme.palette.secondary.main],
      },
    },
    divider: {
      marginBottom: 40,
    },
    requiredMark: {
      color: 'red',
    },
  }),
  { name: 'FormRowInput' },
);
