import React from 'react';

import { NavHashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { any, isNil } from 'ramda';

import Icon from 'components/Icon';

import useRouter from 'hooks/useRouter';

import useStyles from './useStyles';

const SideNavLink = params => {
  const { name, to, disabled, nestedLinks, icon, activeOnPaths } = params;
  const classes = useStyles();
  const { matchPath, match } = useRouter();

  const { path } = match();

  const isMatch = subPath => !isNil(matchPath(subPath, { path }));

  const addIsActive = () => (activeOnPaths ? { isActive: () => any(isMatch, activeOnPaths) } : {});

  return (
    <>
      <NavHashLink
        exact
        smooth
        to={to}
        className={clsx(classes.navLink, { [classes.disabled]: disabled })}
        activeClassName={classes.activeNavLink}
        {...addIsActive()}
      >
        {name}
        {icon && <Icon name={icon} className={classes.icon} />}
      </NavHashLink>
      {nestedLinks && (
        <div className={classes.hashNav}>
          {nestedLinks?.map(nestedLink => (
            <SideNavLink key={nestedLink.to} {...nestedLink} />
          ))}
        </div>
      )}
    </>
  );
};

SideNavLink.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
  disabled: PropTypes.bool,
  nestedLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]).isRequired,
      disabled: PropTypes.bool,
    }),
  ),
  icon: PropTypes.string,
};

export default SideNavLink;
