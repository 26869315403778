import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { NavHashLink } from 'react-router-hash-link';
import { Button, Typography } from '@material-ui/core';
import { any } from 'ramda';

import FormField from 'components/FormField';
import Loader from 'components/Loader';
import DocumentTitle from 'components/DocumentTitle';

import { useGetOptionsQuery } from 'domain/options/apiSlice';
import * as OptionsService from 'domain/options/service';
import { AdministrativeAgentsService } from 'domain/administrativeAgent/service';
import { useGetAdministrativeAgentsQuery } from 'domain/administrativeAgent/apiSlice';

import { useMembers } from 'hooks/api/useMembers';
import useRouter from 'hooks/useRouter';
import { useOrganizations, useSuccessorBorrowers } from 'hooks/api';
import { useErrors } from 'hooks/useErrors';

import { appRoutes } from 'router/routes';

import { getOptionsQueryParams } from 'utils/rtkQuery';
import { IdSerializer } from 'utils/serializer';
import { getOrganizationTypes } from 'utils/getOrganizationsType';

import useStyles from './useStyles';
import { fieldArray, resolver } from './validation';

const MemberForm = ({ isEdit, memberId, formValues, pageTitle, submitButtonText }) => {
  const classes = useStyles();
  const { push } = useRouter();
  const { displayErrorsInToast } = useErrors();
  const { isMemberLoading, updateMember, createMember, memberLastUpdated } = useMembers();

  const methods = useForm({
    resolver,
  });
  const isFormDirty = methods.formState.isDirty;

  const { data: options } = useGetOptionsQuery(null, getOptionsQueryParams());
  const stateSelectOptions = OptionsService.selectStateSelectOptions(options);

  const { isConfigurationLoading } = useSuccessorBorrowers();

  const {
    data: administrativeAgentsData,
    isFetching: isAdministrativeAgentsLoading,
    error: administrativeAgentsLoadError,
  } = useGetAdministrativeAgentsQuery();

  const {
    providersOptions,
    filteredOrganizationOptions,
    loadProviders,
    loadOrganizations,
    isOrganizationsLoading,
  } = useOrganizations();

  const selectValues = {
    states: stateSelectOptions,
    administrativeAgents:
      AdministrativeAgentsService.administrativeAgentOptions(administrativeAgentsData),
    providers: providersOptions,
    registeredAgents: filteredOrganizationOptions(getOrganizationTypes(options).registeredAgent),
  };

  const fetchData = async () => {
    try {
      await Promise.all([loadOrganizations(), loadProviders()]);
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (administrativeAgentsLoadError) displayErrorsInToast([administrativeAgentsLoadError]);
  }, [administrativeAgentsLoadError]);

  const prevRoute = isEdit ? appRoutes.staffMemberPath(memberId) : appRoutes.staffMembersPath();

  const handleSubmit = async values => {
    const mappingDict = ['administrativeAgent', 'registeredAgent', ['bankAccount', 'provider']];
    const serializedValues = IdSerializer(values, mappingDict);
    if (isEdit) {
      await updateMember(memberId, serializedValues);
    } else {
      await createMember(serializedValues);
    }
    push(prevRoute);
  };

  const handleCancel = () => {
    push(prevRoute);
  };

  const areRequestsLoading = [
    isConfigurationLoading,
    isMemberLoading,
    isOrganizationsLoading,
    isAdministrativeAgentsLoading,
  ];
  if (any(loaderRequest => loaderRequest)(areRequestsLoading)) {
    return (
      <div className={classes.loaderWrap}>
        <Loader />
      </div>
    );
  }

  return (
    <>
      <DocumentTitle title={pageTitle} />
      <FormProvider {...methods}>
        <NavHashLink className={classes.backButton} exact to={prevRoute}>
          Back
        </NavHashLink>
        <div className={classes.contentPanel}>
          <Typography className={classes.contentTitle} variant="h1">
            {pageTitle}
          </Typography>
          <div className={classes.actionPanel}>
            <Button onClick={() => handleCancel()} variant="contained">
              Cancel
            </Button>
            <Button
              disabled={!isFormDirty}
              onClick={methods.handleSubmit(handleSubmit)}
              variant="contained"
              color="secondary"
            >
              {submitButtonText}
            </Button>
          </div>
        </div>
        <div className={classes.contentWrap}>
          <form className={classes.contentForm}>
            <FormField
              fieldArray={fieldArray}
              errors={methods.errors}
              values={formValues}
              selectValues={selectValues}
            />
          </form>
          <div className={classes.contentSidebar}>
            {isEdit && (
              <div className={classes.lastUpdated}>
                <div className={classes.lastUpdatedTitle}>Last Updated:</div>
                <div>{memberLastUpdated}</div>
              </div>
            )}
          </div>
        </div>
      </FormProvider>
    </>
  );
};

MemberForm.propTypes = {
  isEdit: PropTypes.bool,
  memberId: PropTypes.string,
  formValues: PropTypes.shape({}),
  pageTitle: PropTypes.string,
  submitButtonText: PropTypes.string,
};

export default MemberForm;
