import { useState, useEffect } from 'react';

import { find, equals, isNil } from 'ramda';

import { useErrors } from 'hooks/useErrors';

export const useHandleRtkQueryErrors = (...errors) => {
  const [errorToShow, setErrorToShow] = useState(null);
  const { displayErrorsInToast } = useErrors();

  const error = find(err => !isNil(err), errors) ?? null;

  if (!equals(error, errorToShow)) setErrorToShow(error);

  useEffect(() => {
    if (errorToShow) displayErrorsInToast([errorToShow]);
  }, [errorToShow]);
};
