import React from 'react';

import PropTypes from 'prop-types';
import { Button, Link, Typography } from '@material-ui/core';
import { useFormContext } from 'react-hook-form';

import SimpleInput from 'components/SimpleInput';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Password Recovery';
const MESSAGE = "We'll send a recovery link to your email.";
const LABEL = 'Email Address';
const BUTTON_TEXT = 'Send Recovery Link';
const RETURN = 'Return to';
const SIGN_IN = 'Sign In ';

export const EmailInputForm = props => {
  const { onSubmit } = props;
  const classes = useStyles();
  const { handleSubmit } = useFormContext();

  return (
    <div className={classes.container}>
      <Typography variant="h2" className={classes.formTitle}>
        {TITLE}
      </Typography>
      <Typography className={classes.message}>{MESSAGE}</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleInput name="email" label={LABEL} />
        <Button type="submit" variant="contained" color="secondary" className={classes.button}>
          {BUTTON_TEXT}
        </Button>
      </form>
      <Typography className={classes.signIn}>
        {`${RETURN} `}
        <Link className={classes.link} href={appRoutes.authPath()}>
          {SIGN_IN}
        </Link>
      </Typography>
    </div>
  );
};

EmailInputForm.propTypes = {
  onSubmit: PropTypes.func,
};
