import { createSlice } from '@reduxjs/toolkit';
import MissingReconciliationRepository from 'repositories/MissingReconciliationRepository';
import { useDispatch } from 'react-redux';

import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const initialState = {
  pagination: {
    previous: null,
    next: null,
    count: null,
  },
  loadingStatus: FETCH_STATUSES.idle,
  missingReconciliationReport: null,
  missingReconciliationReportLoadError: null,
};

const missingReconciliationReportReducers = {
  startMissingReconciliationReportLoad(state) {
    state.loadingStatus = FETCH_STATUSES.pending;
    state.missingReconciliationReport = null;
    state.missingReconciliationReportLoadError = null;
  },
  missingReconciliationReportLoadFulfilled(state, { payload }) {
    const { results } = payload;
    state.loadingStatus = FETCH_STATUSES.fulfilled;
    state.missingReconciliationReport = results;
    state.pagination = {
      previous: payload.previous,
      next: payload.next,
      count: payload.count,
    };
  },
  missingReconciliationReportLoadRejected(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.failed;
    state.missingReconciliationReport = null;
    state.missingReconciliationReportLoadError = payload;
    state.pagination = {
      previous: null,
      next: null,
      count: null,
    };
  },
  resetMissingReconciliationReport(state) {
    state.loadingStatus = FETCH_STATUSES.idle;
    state.missingReconciliationReport = null;
    state.missingReconciliationReportLoadError = null;
    state.pagination = {
      previous: null,
      next: null,
      count: null,
    };
  },
};

const missingReconciliationReportSlice = createSlice({
  name: 'missingReconciliationReport',
  initialState,
  reducers: {
    ...missingReconciliationReportReducers,
  },
});

export default missingReconciliationReportSlice.reducer;

export const useMissingReconciliationReportActions = () => {
  const dispatch = useDispatch();

  const resetReport = () =>
    dispatch(missingReconciliationReportSlice.actions.resetMissingReconciliationReport());

  const loadReport = async params => {
    dispatch(missingReconciliationReportSlice.actions.startMissingReconciliationReportLoad());
    try {
      const { data } = await MissingReconciliationRepository.index(params);
      dispatch(
        missingReconciliationReportSlice.actions.missingReconciliationReportLoadFulfilled(data),
      );
    } catch (e) {
      dispatch(missingReconciliationReportSlice.actions.missingReconciliationReportLoadRejected(e));
    }
  };

  return {
    loadReport,
    resetReport,
  };
};
