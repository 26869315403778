import React from 'react';

import PropTypes from 'prop-types';
import { ReactComponent as LogoLight } from 'assets/logo_light.svg';
import { ReactComponent as LogoDark } from 'assets/logo.svg';
import { ReactComponent as QaBadge } from 'assets/qaBadge.svg';
import { ReactComponent as LogoQa } from 'assets/logoQa.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import { ReactComponent as Import } from 'assets/import.svg';
import { ReactComponent as Calendar } from 'assets/calendar.svg';
import { ReactComponent as PlusCircle } from 'assets/plusCircle.svg';
import { ReactComponent as TriangleArrow } from 'assets/TriangleArrow.svg';
import { ReactComponent as PopperArrow } from 'assets/popperArrow.svg';

const Icon = props => {
  const { name, className, iconRef } = props;
  const svgIcons = {
    import: <Import className={className} />,
    calendar: <Calendar className={className} />,
    plusCircle: <PlusCircle className={className} />,
    triangleArrow: <TriangleArrow className={className} />,
    logoLight: <LogoLight className={className} />,
    logoDark: <LogoDark className={className} />,
    qaBadge: <QaBadge className={className} />,
    logoQa: <LogoQa className={className} />,
    searchIcon: <SearchIcon className={className} />,
    popperArrow: <PopperArrow className={className} ref={iconRef} />,
  };

  return svgIcons[name];
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  className: '',
};

export default Icon;
