import React, { useEffect } from 'react';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';

import { useReminders } from 'hooks/api/useReminders';
import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';

import { remindersColumnConfig } from 'presenters/ReminderPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const TITLE = 'Certificate of Authority Reminders';

const SUBTITLE =
  'North Carolina Certificate of Authority needs to be filed for the following Successor Borrowers.';

export const RemindersCOA = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();
  const {
    loadReminders,
    remindersTableData,
    areRemindersLoading,
    remindersTableKeys,
    updateReminder,
  } = useReminders();

  const { elementRef, elementHeight } = useRefElementHeight();

  const fetchData = async () => {
    try {
      await loadReminders();
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEdit = async (id, fieldKey, fieldValue) => {
    await updateReminder(id, {
      [fieldKey]: fieldValue,
    });
  };

  return (
    <ContentLayout disableTopPadding>
      <DocumentTitle title={TITLE} />
      <Header title={TITLE} ref={elementRef} shouldUseSidebarState>
        <p className={classes.subtitle}>{SUBTITLE}</p>
      </Header>
      <ListTable
        detailPath={appRoutes.successorBorrowerPath}
        isLoading={areRemindersLoading}
        tableHeader={remindersTableKeys}
        tableData={remindersTableData}
        columnConfig={remindersColumnConfig}
        onEdit={handleEdit}
        isOuterScrollable
        fixedTopOffset={elementHeight}
      />
    </ContentLayout>
  );
};
