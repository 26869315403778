import { useState } from 'react';

import { TimersConst } from 'const';

export const useShowOpeningLoader = () => {
  const [isOpeningLoaderShown, setShowOpeningLoader] = useState(true);
  setTimeout(() => setShowOpeningLoader(false), TimersConst.OPENING_LOADER_TIME);

  return {
    isOpeningLoaderShown,
  };
};
