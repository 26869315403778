import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    dropboxContainer: {
      backgroundColor: theme.palette.white,
      border: [1.5, 'dashed', '#E4E8EC'],
      boxSizing: 'border-box',
      borderRadius: 4,
      padding: [24, 44, 24, 44],
      textAlign: 'center',
    },
    placeholderWrap: {
      marginRight: '20px',
      display: 'flex',
      flexDirection: 'Row',
      alignItems: 'center',
    },
    text: {
      textAlign: 'left',
      marginLeft: '40px',
      color: theme.palette.blueBayoux2,
      opacity: 0.4,
      letterSpacing: '0.01em',
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(16),
    },
    error: {
      color: theme.palette.coralRed,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: theme.typography.pxToRem(14),
    },
    importContainer: {
      margin: [0, 32],
    },
    title: {
      display: 'block',
      fontSize: theme.typography.pxToRem(10),
      lineHeight: '100%',
      color: theme.palette.tuna,
      opacity: 0.7,
      letterSpacing: '0.01em',
      marginBottom: 10,
    },
  }),
  { name: 'ToolsImport' },
);
