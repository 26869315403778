import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

import ListTable from 'components/ListTable';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';

import useStyles from './useStyles';

export const AccountingTable = props => {
  const { fixedTopOffset } = props;
  const classes = useStyles();

  const { accountingTableColumnConfig, accountingTableKeys, accountingTableData } =
    useOIDBalanceReports();

  const { tableRows, footerTotals } = accountingTableData;

  const tableFooter = (
    <tr className={clsx(classes.tableBottomRow, classes.listFooterFont, classes.fontColorMain)}>
      {accountingTableKeys.map((key, index) => (
        <td className={classes.tableBottomCell} key={index}>
          {footerTotals[key]}
        </td>
      ))}
    </tr>
  );

  return (
    <div className={classes.tableWrap}>
      <ListTable
        tableHeader={accountingTableKeys}
        tableData={tableRows}
        columnConfig={accountingTableColumnConfig}
        tableClasses={classes.accountingTable}
        tableFooterContent={tableFooter}
        isOuterScrollable
        fixedTopOffset={fixedTopOffset}
      />
    </div>
  );
};

AccountingTable.propTypes = {
  fixedTopOffset: PropTypes.number,
};
