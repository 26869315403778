import { isNil } from 'ramda';

import { StatusDisplayedNameByCode } from 'const';

import { ISuccessorBorrower } from 'domain/successorBorrower/types';

import { SORT_DIRECTION } from 'enums';

import { formatDate } from 'utils/date';
import { formatCurrency } from 'utils/numbers';

export const columnConfig = {
  filingRef: {
    displayedName: 'Filing Ref',
    sortingEnabled: true,
    isLink: true,
  },
  successorBorrower: {
    displayedName: 'Successor Borrower',
  },
  status: {
    displayedName: 'Status',
    sortingEnabled: true,
  },
  masterServicer: {
    displayedName: 'Master Servicer',
  },
  ein: {
    displayedName: 'EIN',
  },
  bankNumber: {
    displayedName: 'Bank Account #',
    sortingEnabled: true,
  },
  sosId: {
    displayedName: 'SOS Id',
  },
  sosDate: {
    displayedName: 'SOS Date',
  },
  unpaidPrincipalBalance: {
    displayedName: 'Unpaid Principal Balance',
    align: 'right',
  },
};

export const tableHeadArray = () => Object.keys(columnConfig);

export const tableSorting = () => ({
  direction: SORT_DIRECTION.asc,
  field: 'filingRef',
});

export const getTableData = (list: Array<ISuccessorBorrower>) => {
  if (isNil(list)) return null;
  return list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      filingRef: rowData.filingRef,
      successorBorrower: rowData.shortName || rowData.name,
      status: StatusDisplayedNameByCode[rowData.status],
      masterServicer: rowData.cmbsMasterServicer1,
      ein: rowData.ein,
      sosId: rowData.secretaryOfStateId,
      sosDate: formatDate(rowData.sosAcceptedDate),
      unpaidPrincipalBalance: formatCurrency(rowData.unpaidPrincipalBalance),
    },
  }));
};
