import React from 'react';

import Loader from 'components/Loader';

import { useGetOptionsQuery } from 'domain/options/apiSlice';

import { useSetOptionsToLocalStorage } from 'hooks/useSetOptionsToLocalStorage';
import { useHandleRtkQueryErrors } from 'hooks/useHandleRtkQueryErrors';

import { getOptionsQueryParams } from 'utils/rtkQuery';

export const OptionsLoading = props => {
  const { children } = props;

  const { data: options, error: optionsLoadError } = useGetOptionsQuery(
    null,
    getOptionsQueryParams(),
  );

  const { isOptionsSet } = useSetOptionsToLocalStorage(options);

  useHandleRtkQueryErrors(optionsLoadError);

  if (!isOptionsSet) return <Loader />;

  return children;
};
