import React from 'react';

import { Typography, Paper, List, ListItem, Link } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

import { getLoansTitle } from 'presenters/DataValidationPresenter';

import { appRoutes } from 'router/routes';

import useStyles from './useStyles';

const VIEW_DETAILS = 'View Details';

const ErrorSection = props => {
  const classes = useStyles();
  const { dataItem } = props;
  const { title, loans } = dataItem;

  if (isEmpty(loans)) return null;

  return (
    <div id={dataItem.idTag}>
      <Typography className={classes.errorSectionTitle}>{title}</Typography>
      <Paper elevation={2} className={classes.listContainer}>
        <List className={classes.list}>
          {loans.map(loan => {
            const { id } = loan;
            const itemText = getLoansTitle(loan);
            return (
              <ListItem divider className={classes.listItem} key={id}>
                <Typography className={clsx(classes.listItemFont, classes.textTrunc)}>
                  {itemText}
                </Typography>
                <Link
                  href={appRoutes.loanPath(id)}
                  color="secondary"
                  className={clsx(classes.listItemFont, classes.link)}
                >
                  {VIEW_DETAILS}
                </Link>
              </ListItem>
            );
          })}
        </List>
      </Paper>
    </div>
  );
};

ErrorSection.propTypes = {
  dataItem: PropTypes.shape({
    idTag: PropTypes.string,
    title: PropTypes.string,
    loans: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        filingRef: PropTypes.string,
        dealName: PropTypes.string,
      }),
    ),
  }),
};

export default ErrorSection;
