import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoansRepository from 'repositories/LoansRepository';
import { FORM_FIELD_TYPE } from 'enums/formFieldType';

import { DateConst } from 'const';

import { ERROR_MESSAGES } from 'enums';

import LoansChoicesPresenter from 'presenters/LoansChoicesPresenter';

import { transformDate } from 'utils/date';
import { preventEmptyString } from 'utils/yupHelpers';

const booleanOptions = [
  { key: 'Yes', displayedName: 'Yes', value: true },
  { key: 'No', displayedName: 'No', value: false },
];

export const indemnitorOptions = [
  { label: 'Personal', value: 'Personal' },
  { label: 'Corporate', value: 'Corporate' },
];

export const resolver = yupResolver(
  Yup.object({
    bondTrustee: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    masterServicer1: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    masterServicer2: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    ratingAgency1: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    ratingAgency2: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    ratingAgency3: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    ratingAgency4: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    cusip: Yup.string().trim().nullable(),
    securityDescription: Yup.string().trim().nullable().required(ERROR_MESSAGES.required),
    currentPrice: Yup.number().transform(preventEmptyString).nullable(),
    bloombergPoolBalanceDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    originalIssuePrice: Yup.number().transform(preventEmptyString).nullable(),
    masterServicerNotes: Yup.string().trim().nullable(),
    specialServicer: Yup.string().trim().nullable(),
    fiscalAgent: Yup.string().trim().nullable(),
    psaDate: Yup.string().trim().nullable(),
    issuerAbbreviation: Yup.string().trim().nullable(),
    issuerSeries: Yup.string().trim().nullable(),
    issuerName: Yup.string().trim().nullable(),
    indemnityDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    indemnitor: Yup.string().trim().nullable(),
    isIndemnityGiven: Yup.boolean().nullable().required(ERROR_MESSAGES.required),
    indemnityNotes: Yup.string().trim().nullable(),
    indemnitySentFor: Yup.number().typeError(ERROR_MESSAGES.number).nullable(),
    certificateDefinition: Yup.string().trim().nullable(),
    certificateHolderLabel: Yup.string().trim().nullable(),
    hasPoolSecuritized: Yup.boolean().nullable().required(),
    sbDesignationDate: Yup.string()
      .transform(transformDate)
      .nullable()
      .matches(DateConst.DATE_FORMAT_REGEX, ERROR_MESSAGES.date.wrongFormat),
    sbDesignationNotes: Yup.string().trim().nullable(),
    sbDesignation: Yup.string().trim().nullable(),
    isLocked: Yup.bool(),
  }),
);

export const fieldArray = [
  { fieldKey: 'cusip', displayedName: 'CUSIP', type: FORM_FIELD_TYPE.input },
  {
    fieldKey: 'securityDescription',
    displayedName: 'Security Description',
    type: FORM_FIELD_TYPE.input,
    isRequired: true,
  },
  {
    fieldKey: 'currentPrice',
    displayedName: 'Bloomberg Pool Balance',
    type: FORM_FIELD_TYPE.decimal,
  },
  {
    fieldKey: 'originalIssuePrice',
    displayedName: 'Original Pool Issue Amount',
    type: FORM_FIELD_TYPE.decimal,
  },
  {
    fieldKey: 'bloombergPoolBalanceDate',
    displayedName: 'Bloomberg Pool Balance Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'bondTrustee',
    displayedName: 'Bond Trustee',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'financialServices',
    placeholder: 'Select Bond Trustee',
  },
  {
    fieldKey: 'masterServicer1',
    displayedName: 'Master Servicer 1',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'financialServices',
    placeholder: 'Select Servicer',
  },
  {
    fieldKey: 'masterServicer2',
    displayedName: 'Master Servicer 2',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'financialServices',
    placeholder: 'Select Servicer',
  },
  {
    fieldKey: 'masterServicerNotes',
    displayedName: 'Master Servicer Notes',
    type: FORM_FIELD_TYPE.input,
    multiline: true,
  },
  {
    fieldKey: 'ratingAgency1',
    displayedName: 'Rating Agency 1',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'ratingAgencies',
    placeholder: 'Select Rating Agency',
  },
  {
    fieldKey: 'ratingAgency2',
    displayedName: 'Rating Agency 2',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'ratingAgencies',
    placeholder: 'Select Rating Agency',
  },
  {
    fieldKey: 'ratingAgency3',
    displayedName: 'Rating Agency 3',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'ratingAgencies',
    placeholder: 'Select Rating Agency',
  },
  {
    fieldKey: 'ratingAgency4',
    displayedName: 'Rating Agency 4',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'ratingAgencies',
    placeholder: 'Select Rating Agency',
  },
  {
    fieldKey: 'isIndemnityGiven',
    displayedName: 'Indemnity Given?',
    type: FORM_FIELD_TYPE.enum,
    options: booleanOptions,
    isRequired: true,
  },
  {
    fieldKey: 'indemnityNotes',
    displayedName: 'Indemnity Notes',
    type: FORM_FIELD_TYPE.input,
    multiline: true,
  },
  {
    fieldKey: 'indemnityDate',
    displayedName: 'Indemnity Date',
    type: FORM_FIELD_TYPE.date,
  },
  {
    fieldKey: 'indemnitor',
    displayedName: 'Indemnitor',
    type: FORM_FIELD_TYPE.select,
    selectValuesType: 'indemnitorOptions',
    placeholder: 'Select Indemnitor',
  },
  {
    fieldKey: 'indemnitySentFor',
    displayedName: 'Indemnity Sent For',
    type: FORM_FIELD_TYPE.selectAutocomplete,
    fetchOptions: LoansRepository.choices,
    selectOptions: LoansChoicesPresenter.formatChoices,
    placeholder: 'Select Indemnity Sent For',
  },
  {
    fieldKey: 'issuerAbbreviation',
    displayedName: 'Issuer Abbreviation',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'issuerSeries',
    displayedName: 'Issuer Series',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'issuerName',
    displayedName: 'Issuer Name',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'certificateDefinition',
    displayedName: 'Certificate Definition',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'certificateHolderLabel',
    displayedName: 'Certificate Holder Label',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'specialServicer',
    displayedName: 'Special Servicer',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'fiscalAgent',
    displayedName: 'Fiscal Agent',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'psaDate',
    displayedName: 'PSA Date (Month DD, YYYY)',
    type: FORM_FIELD_TYPE.input,
  },
  {
    fieldKey: 'hasPoolSecuritized',
    displayedName: 'Securitized?',
    type: FORM_FIELD_TYPE.enum,
    options: booleanOptions,
    isRequired: true,
  },
  {
    fieldKey: 'sbDesignation',
    displayedName: 'SB Designation',
    type: FORM_FIELD_TYPE.input,
    multiline: true,
  },
];
