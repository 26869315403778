import { createSlice } from '@reduxjs/toolkit';
import AssumedDealsRepository from 'repositories/AssumedDealsRepository';

import { createRestSlice } from 'utils/RestSlice';

const assumedDealsRestSlice = createRestSlice({
  resource: 'assumedDeal',
  repository: AssumedDealsRepository,
  slices: ['loadAssumedDeal'],
});

const initialState = {
  ...assumedDealsRestSlice.initialState,
};

const assumedDealsSlice = createSlice({
  name: 'assumedDeals',
  initialState,
  reducers: {
    ...assumedDealsRestSlice.reducers,
  },
});

export default assumedDealsSlice.reducer;
export const assumedDealsRestHooks = assumedDealsRestSlice.hooks(
  assumedDealsSlice,
  'AssumedDealsSlice',
);
