import AssumedDealPresenter from 'presenters/AssumedDealPresenter';

import { assumedDealsRestHooks } from 'store/AssumedDealsSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useAssumedDeals = () => {
  const { assumedDeal, loadAssumedDeal } = assumedDealsRestHooks.use();

  const assumedDealsTableData = AssumedDealPresenter.tableData(assumedDeal?.item);

  const assumedDealsColumnConfig = AssumedDealPresenter.columnConfig(assumedDealsTableData);

  const assumedDealsTableHeader = AssumedDealPresenter.tableHeader(assumedDealsColumnConfig);

  return {
    loadAssumedDeals: loadAssumedDeal,
    assumedDealsTableData,
    assumedDealsColumnConfig,
    assumedDealsTableHeader,
    areAssumedDealsLoading: useFetchStatus(assumedDeal?.loadingStatus).isPending,
  };
};
