import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    loaderWrap: {
      width: '100%',
      height: `calc(100vh - ${theme.sizes.header.height}px)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& > img': {
        width: '80px',
        height: '80px',
      },
    },
    tableContent: {
      width: '100%',
    },
    tableContainer: {
      marginBottom: '16px',
      '&:last-child': {
        marginBottom: 0,
      },
    },
    contentTitle: {
      marginTop: 0,
      marginBottom: '32px',
    },
    contentSubtitle: {
      margin: '8px 0',
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      color: theme.palette.primary.main,
      opacity: 0.5,
      letterSpacing: '0.02em',
    },
    contentPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexFlow: 'row nowrap',
    },
    editButton: {
      flexShrink: 0,
      color: theme.palette.primary.main,
      background: 'linear-gradient(0deg, rgba(19, 51, 76, 0.07), rgba(19, 51, 76, 0.07))',

      '& span': {
        opacity: 0.75,
      },
    },
    table: {
      tableLayout: 'fixed',
    },
    tableCell: {
      verticalAlign: 'top',
      padding: '10px 24px',
      lineHeight: 'auto',
      '&:not(:last-child)': {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
      },
    },
  }),
  { name: 'Member' },
);
