import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectDataFromResponse } from 'utils/objects';

export const loanGovernmentSecurityApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getLoanGovernmentSecurities: builder.query({
      query: id => ({
        url: apiRoutes.loanGovernmentSecuritiesPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: [CACHE_TAG.loanGovernmentSecurity],
    }),
  }),
});

export const { useGetLoanGovernmentSecuritiesQuery } = loanGovernmentSecurityApi;
