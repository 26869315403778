import React from 'react';

import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import InterestAccrualRepository from 'repositories/InterestAccrualRepository';
import { isNil } from 'ramda';

import { useErrors } from 'hooks/useErrors';
import { useReportTask } from 'hooks/api';

import useStyles from 'pages/MortgageInterestAccrualReport/useStyles';

const DOWNLOAD = 'Download Report';

export const GetReportButton = props => {
  const { year } = props;
  const { initiateTaskReportLoading, isTaskReportLoading, finishLoading } = useReportTask();
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const getReportLocation = async params => {
    try {
      const data = await InterestAccrualRepository.interestAccrualsReportTask(params);
      return data.headers.location;
    } catch (e) {
      finishLoading(e);
      throw e;
    }
  };

  const handleDownloadReport = async () => {
    const params = { year };
    try {
      const report = await initiateTaskReportLoading(getReportLocation, params);
      window.location.href = report;
    } catch (e) {
      displayErrorsInToast([e]);
    }
  };

  return (
    <Button
      className={classes.button}
      disabled={isNil(year) || isTaskReportLoading}
      onClick={handleDownloadReport}
      variant="contained"
      color="secondary"
    >
      {DOWNLOAD}
    </Button>
  );
};

GetReportButton.propTypes = {
  year: PropTypes.number,
};
