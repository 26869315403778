import React, { useState, useEffect } from 'react';

import { TextField } from '@material-ui/core';
import LoansRepository from 'repositories/LoansRepository';

import FormError from 'components/FormError';

import { useOIDBalanceReports } from 'hooks/api/useOIDBalanceReports';
import useDebouncedUpdate from 'hooks/useDebouncedUpdate';
import { useErrors } from 'hooks/useErrors';

import useStyles from './useStyles';

const TITLE = 'OID Balance Adjustment Note';
const KEY = 'oidBalanceAdjustmentNote';
const DEFAULT_VALUE = '';

export const AdjustmentNoteInput = () => {
  const classes = useStyles();
  const { displayErrorsInToast, formatErrors } = useErrors();

  const { loanDetailsData } = useOIDBalanceReports();
  const { id, oidBalanceAdjustmentNote } = loanDetailsData;

  const [inputValue, setInputValue] = useState(oidBalanceAdjustmentNote ?? DEFAULT_VALUE);
  const { func: updateLoan, error, reset } = useDebouncedUpdate(LoansRepository.loansPartialUpdate);

  useEffect(() => {
    if (error) {
      const { nonFieldErrors, backendServicesError } = formatErrors(error);
      displayErrorsInToast([nonFieldErrors, backendServicesError]);
    }
  }, [error]);

  const handleInputChange = e => {
    const { value } = e.target;
    reset();
    setInputValue(value);
    updateLoan(id, { [KEY]: value });
  };

  return (
    <div>
      <TextField
        label={TITLE}
        name={KEY}
        value={inputValue ?? DEFAULT_VALUE}
        onChange={handleInputChange}
        className={classes.textarea}
        fullWidth
        multiline
      />
      {error && <FormError message={error[KEY]} />}
    </div>
  );
};
