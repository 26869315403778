import { createSlice } from '@reduxjs/toolkit';
import UsersRepository from 'repositories/UsersRepository';
import { useDispatch } from 'react-redux';

import { createRestSlice } from 'utils/RestSlice';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const usersRestSlice = createRestSlice({
  resource: 'user',
  repository: UsersRepository,
  slices: ['loadUsers'],
});

const currentUserReducers = {
  startCurrentUserLoad(state) {
    state.loadingStatus = FETCH_STATUSES.pending;
    state.currentUser = null;
    state.currentUserLoadError = null;
  },
  currentUserLoadFulfilled(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.fulfilled;
    state.currentUser = payload;
  },
  currentUserLoadRejected(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.failed;
    state.currentUser = null;
    state.currentUserLoadError = payload;
  },
};
const initialState = {
  ...usersRestSlice.initialState,
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    ...currentUserReducers,
    ...usersRestSlice.reducers,
  },
});

export const useCurrentUserActions = () => {
  const dispatch = useDispatch();

  const loadCurrentUser = () => {
    dispatch(usersSlice.actions.startCurrentUserLoad());
    return UsersRepository.current()
      .then(({ data }) => {
        dispatch(usersSlice.actions.currentUserLoadFulfilled(data.data));
      })
      .catch(e => {
        dispatch(usersSlice.actions.currentUserLoadRejected(e));
      });
  };

  return {
    loadCurrentUser,
  };
};

export default usersSlice.reducer;
export const usersRestHooks = usersRestSlice.hooks(usersSlice, 'UsersSlice');
