import React, { useEffect, useMemo } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button } from '@material-ui/core';

import DocumentTitle from 'components/DocumentTitle';
import ListTable from 'components/ListTable';
import Header from 'components/Header';
import ActionPanelButtonWrapper from 'components/ActionPanelButtonWrapper';

import { useGetSuccessorBorrowersQuery } from 'domain/successorBorrower/apiSlice';
import { SuccessorBorrowersService } from 'domain/successorBorrower/service';

import { useErrors } from 'hooks/useErrors';
import { useRefElementHeight } from 'hooks/useRefElementHeight';

import ContentLayout from 'layouts/ContentLayout';
import RootLayoutV2 from 'layouts/RootLayoutV2';

import { appRoutes } from 'router/routes';

import { sortTableDataByKey } from 'utils/sorting';

import useStyles from './useStyles';

const TITLE = 'Successor Borrowers';

export const SuccessorBorrowers = () => {
  const classes = useStyles();
  const { displayErrorsInToast } = useErrors();

  const {
    data,
    isFetching: isSuccessorBorrowersLoading,
    isError: isSuccessorBorrowersLoadError,
    error: successorBorrowersLoadError,
  } = useGetSuccessorBorrowersQuery();

  const tableData = useMemo(() => SuccessorBorrowersService.getTableData(data), [data]);
  const tableHeader = SuccessorBorrowersService.tableHeadArray();
  const successorBorrowerSorting = SuccessorBorrowersService.tableSorting();
  const { columnConfig } = SuccessorBorrowersService;

  const { elementRef, elementHeight } = useRefElementHeight();

  useEffect(() => {
    if (isSuccessorBorrowersLoadError) {
      displayErrorsInToast([successorBorrowersLoadError]);
    }
  }, [isSuccessorBorrowersLoadError]);

  return (
    <RootLayoutV2>
      <ContentLayout disableTopPadding>
        <DocumentTitle title={TITLE} />
        <Header title={TITLE} ref={elementRef} shouldUseSidebarState={false}>
          <ActionPanelButtonWrapper>
            <Button
              to={appRoutes.successorBorrowerCreatePath()}
              className={classes.addItem}
              variant="contained"
              color="secondary"
              component={NavHashLink}
            >
              Add Successor Borrower
            </Button>
          </ActionPanelButtonWrapper>
        </Header>
        <ListTable
          detailPath={appRoutes.successorBorrowerPath}
          tableHeader={tableHeader}
          isLoading={isSuccessorBorrowersLoading}
          tableData={sortTableDataByKey(
            tableData,
            successorBorrowerSorting.field,
            successorBorrowerSorting.direction,
          )}
          columnConfig={columnConfig}
          defaultSortingField={successorBorrowerSorting.field}
          defaultSortingDirection={successorBorrowerSorting.direction}
          isOuterScrollable
          fixedTopOffset={elementHeight}
        />
      </ContentLayout>
    </RootLayoutV2>
  );
};
