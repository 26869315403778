import PropTypes from 'prop-types';

import Presenter from 'utils/PropTypesPresenter';

export const columnConfig = {
  id: {
    displayedName: 'PID',
  },
  name: {
    displayedName: 'Organization Name',
    isLink: true,
  },
  shortName: {
    displayedName: 'Organization Short Name',
  },
  organizationType: {
    displayedName: 'Organization Type',
  },
};

export default new Presenter(
  {
    id: PropTypes.number,
    name: PropTypes.string,
    organizationType: PropTypes.number,
    shortName: PropTypes.string,
  },
  {
    tableData(list = []) {
      return list.map(rowData => ({
        id: rowData.id,
        data: {
          ...rowData,
        },
      }));
    },
  },
);
