import { CACHE_TAG } from 'enums';

import apiRoutes from 'router/routes/ApiRoutes';

import { apiContainer } from 'store';

import { selectResultsFromResponse, selectDataFromResponse } from 'utils/objects';
import { createTag } from 'utils/rtkQuery';

import { IExtendedSuccessorBorrower, ISuccessorBorrower } from './types';
import { ISuccessorBorrowerUpdate } from './schemas/update';
import { ISuccessorBorrowerCreate } from './schemas/create';

export const successorBorrowerApi = apiContainer.injectEndpoints({
  endpoints: builder => ({
    getSuccessorBorrowers: builder.query<Array<ISuccessorBorrower>, void>({
      query: () => ({
        url: apiRoutes.successorBorrowersPath(),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.successorBorrower),
    }),
    getSuccessorBorrower: builder.query<IExtendedSuccessorBorrower, number>({
      query: id => ({
        url: apiRoutes.successorBorrowerPath(id),
        method: 'GET',
      }),
      transformResponse: selectDataFromResponse,
      providesTags: result => createTag.show(result, CACHE_TAG.successorBorrower),
    }),
    updateSuccessorBorrower: builder.mutation<
      IExtendedSuccessorBorrower,
      { id: number; successorBorrower: ISuccessorBorrowerUpdate }
    >({
      query: ({ id, successorBorrower }) => ({
        url: apiRoutes.successorBorrowerPath(id),
        method: 'PUT',
        data: successorBorrower,
      }),
      invalidatesTags: [{ type: CACHE_TAG.successorBorrower, id: 'LIST' }],
    }),
    createSuccessorBorrower: builder.mutation<IExtendedSuccessorBorrower, ISuccessorBorrowerCreate>(
      {
        query: data => ({
          url: apiRoutes.successorBorrowersPath(),
          method: 'POST',
          data,
        }),
        transformResponse: selectDataFromResponse,
        invalidatesTags: [{ type: CACHE_TAG.successorBorrower, id: 'LIST' }],
      },
    ),
    getRelatedEntities: builder.query<ISuccessorBorrower, string>({
      query: id => ({
        url: apiRoutes.successorBorrowersRelatedEntitiesPath(id),
        method: 'GET',
      }),
      transformResponse: selectResultsFromResponse,
      providesTags: result => createTag.index(result, CACHE_TAG.successorBorrower),
    }),
  }),
});

export const {
  useGetSuccessorBorrowersQuery,
  useGetSuccessorBorrowerQuery,
  useGetRelatedEntitiesQuery,
  useUpdateSuccessorBorrowerMutation,
  useCreateSuccessorBorrowerMutation,
} = successorBorrowerApi;
