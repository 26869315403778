import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import { useLongPollingWithRedux } from 'hooks/useLongPollingWithRedux';

import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const initialState = {
  loadingStatus: FETCH_STATUSES.idle,
  reconciliationDiscrepancies: null,
  reconciliationDiscrepanciesLoadError: null,
};

const reconciliationDiscrepanciesReducers = {
  startReconciliationDiscrepanciesLoad(state) {
    state.loadingStatus = FETCH_STATUSES.pending;
    state.reconciliationDiscrepancies = null;
    state.reconciliationDiscrepanciesLoadError = null;
  },
  reconciliationDiscrepanciesLoadFulfilled(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.fulfilled;
    state.reconciliationDiscrepancies = payload;
  },
  reconciliationDiscrepanciesLoadRejected(state, { payload }) {
    state.loadingStatus = FETCH_STATUSES.failed;
    state.reconciliationDiscrepancies = null;
    state.reconciliationDiscrepanciesLoadError = payload;
  },
  resetReconciliationDiscrepanciesData(state) {
    state.loadingStatus = FETCH_STATUSES.idle;
    state.reconciliationDiscrepancies = null;
    state.reconciliationDiscrepanciesLoadError = null;
  },
};

const reconciliationDiscrepanciesSlice = createSlice({
  name: 'reconciliationDiscrepancies',
  initialState,
  reducers: {
    ...reconciliationDiscrepanciesReducers,
  },
});

export default reconciliationDiscrepanciesSlice.reducer;

export const useReconciliationDiscrepanciesActions = () => {
  const dispatch = useDispatch();

  const resetReconciliationDiscrepanciesData = () =>
    dispatch(reconciliationDiscrepanciesSlice.actions.resetReconciliationDiscrepanciesData());

  const createReconciliationDiscrepanciesLongPollingWorker = useLongPollingWithRedux({
    onStart: () =>
      dispatch(reconciliationDiscrepanciesSlice.actions.startReconciliationDiscrepanciesLoad()),
    onFailure: error =>
      dispatch(
        reconciliationDiscrepanciesSlice.actions.reconciliationDiscrepanciesLoadRejected(error),
      ),
    onSuccess: result =>
      dispatch(
        reconciliationDiscrepanciesSlice.actions.reconciliationDiscrepanciesLoadFulfilled(
          result.loans,
        ),
      ),
  });

  const { reconciliationDiscrepancies, loadingStatus, reconciliationDiscrepanciesLoadError } =
    useSelector(state => state.ReconciliationDiscrepanciesSlice);

  return {
    createReconciliationDiscrepanciesLongPollingWorker,
    reconciliationDiscrepancies,
    loadingStatus,
    reconciliationDiscrepanciesLoadError,
    resetReconciliationDiscrepanciesData,
  };
};
