/* eslint-disable react-hooks/rules-of-hooks */
import { createSlice } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import FundingNotesRepository from 'repositories/FundingNotesRepository';

import { createRestSlice } from 'utils/RestSlice';
import { FETCH_STATUSES } from 'utils/fetchStatusUtils';

const fundingNotesTotalInitialState = {
  fundingNotesTotal: {
    item: null,
    loadingStatus: FETCH_STATUSES.idle,
    errors: null,
  },
};

const fundingNotesTotalReducer = {
  loadFundingNotesTotalStart(state) {
    state.fundingNotesTotal.loadingStatus = FETCH_STATUSES.pending;
    state.fundingNotesTotal.item = null;
  },
  loadFundingNotesTotalSuccess(state, { payload }) {
    state.fundingNotesTotal.loadingStatus = FETCH_STATUSES.fulfilled;
    state.fundingNotesTotal.item = payload.data;
  },
  loadFundingNotesTotalFail(state, { payload: { errors } }) {
    state.fundingNotesTotal.loadingStatus = FETCH_STATUSES.failed;
    state.fundingNotesTotal.errors = errors;
  },
};

const fundingNotesRestSlice = createRestSlice({
  resource: 'fundingNote',
  repository: FundingNotesRepository,
  slices: [
    'loadFundingNote',
    'loadFundingNotes',
    'updateFundingNote',
    'deleteFundingNote',
    'createFundingNote',
    'bulkFundingNotesAction',
  ],
});

const initialState = {
  ...fundingNotesRestSlice.initialState,
  ...fundingNotesTotalInitialState,
};
const fundingNotesSlice = createSlice({
  name: 'fundingNotes',
  initialState,
  reducers: {
    ...fundingNotesRestSlice.reducers,
    ...fundingNotesTotalReducer,
  },
});

export default fundingNotesSlice.reducer;

export const fundingNotesRestHooks = fundingNotesRestSlice.hooks(
  fundingNotesSlice,
  'FundingNotesSlice',
);

export const useFundingNotesTotalActions = () => {
  const dispatch = useDispatch();

  const loadFundingNotesTotal = () => {
    dispatch(fundingNotesSlice.actions.loadFundingNotesTotalStart());
    return FundingNotesRepository.total()
      .then(({ data }) => {
        dispatch(fundingNotesSlice.actions.loadFundingNotesTotalSuccess(data));
      })
      .catch(e => {
        dispatch(fundingNotesRestSlice.actions.loadFundingNotesTotalFail(e));
      });
  };
  return {
    loadFundingNotesTotal,
  };
};
