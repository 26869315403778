import React, { useEffect } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import {
  TableContainer,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from '@material-ui/core/';
import PropTypes from 'prop-types';
import { not, isEmpty, isNil } from 'ramda';

import { useFundingNotes } from 'hooks/api';
import { useEditableTableContext } from 'hooks/useEditableTableControls';

import useStyles from 'pages/FundingNotes/useStyles';
import { resolver, prepareBeforeSetting } from 'pages/FundingNotes/validation';

import { pickData } from 'utils/forms';

import CommonTableRow from './components/CommonTableRow';

const defaultFormValues = {
  name: '',
  loanAmount: '',
  maturityDate: null,
};

const isEditableRow = (rowId, editableRowId) => rowId === editableRowId;
const TOTAL = 'Total';

export const FundingNotesTable = props => {
  const { reloadData, handleDeleteLineClick } = props;

  const { columns, tableRows, fundingNotes, total } = useFundingNotes();

  const {
    isCreatingFormShown,
    hideCreatingForm,
    showCreatingForm,
    isEditingFormShown,
    showEditableRow,
    hideEditableRow,
    editableRowId,
    setEditableRowId,
  } = useEditableTableContext();

  const classes = useStyles();

  const methods = useForm({
    resolver,
    defaultValues: defaultFormValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: true,
  });

  const { reset: resetForm } = methods;

  useEffect(() => {
    if (isEmpty(tableRows)) {
      hideEditableRow();
      showCreatingForm();
    } else {
      hideCreatingForm();
    }
  }, [tableRows]);

  // The form is reset accordingly, editing or adding is selected.
  useEffect(() => {
    if (isCreatingFormShown) {
      resetForm(defaultFormValues);
      return;
    }
    if (isNil(editableRowId)) {
      return;
    }
    const rowData = pickData(editableRowId, fundingNotes);
    if (isEditingFormShown) {
      resetForm(prepareBeforeSetting(rowData));
    }
  }, [isEditingFormShown, editableRowId, isCreatingFormShown]);

  const handleEditButton = row => () => {
    const { id } = row;
    setEditableRowId(id);
    showEditableRow();
    hideCreatingForm();
  };

  return (
    <FormProvider {...methods}>
      <TableContainer component={Paper}>
        <form>
          <Table aria-label="reconciliationTable" className={classes.table}>
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columns.getNames().map((name, index) => (
                  <TableCell key={`table-head-${index}`} className={classes.tableHeadCell}>
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isCreatingFormShown && <CommonTableRow isForm isCreating reloadData={reloadData} />}
              {not(isEmpty(tableRows)) &&
                tableRows.map((row, index) => (
                  <CommonTableRow
                    key={index}
                    row={row}
                    isForm={isEditableRow(row.id, editableRowId) && isEditingFormShown}
                    onEdit={handleEditButton(row)}
                    reloadData={reloadData}
                    handleDeleteLineClick={handleDeleteLineClick}
                  />
                ))}
              {not(isEmpty(tableRows)) && (
                <TableRow className={classes.totalRow}>
                  <TableCell className={classes.totalRowFont}>{TOTAL}</TableCell>
                  <TableCell className={classes.totalRowFont} colspan="3">
                    {total}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </form>
      </TableContainer>
    </FormProvider>
  );
};
FundingNotesTable.propTypes = {
  reloadData: PropTypes.func,
  handleDeleteLineClick: PropTypes.func,
};
