import React, { useRef, useState } from 'react';

import { NavHashLink } from 'react-router-hash-link';
import { Button, ClickAwayListener, Divider } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import SearchPanel from 'components/SearchPanel';
import Icon from 'components/Icon';
import Loader from 'components/Loader';

import { useGetHelpFile } from 'hooks/useGetHelpFile';
import { useUsers } from 'hooks/api/useUsers';
import useRouter from 'hooks/useRouter';

import UserPresenter from 'presenters/UserPresenter';

import { appRoutes } from 'router/routes';

import { useAuthActions } from 'store/AuthSlice';

import { isQaEnvironment } from 'utils/environment';

import useStyles from './useStyles';

const Header = () => {
  const classes = useStyles();

  const router = useRouter();
  const { signOut } = useAuthActions();
  const { currentUser } = useUsers();
  const navList = [
    { name: 'Home', to: appRoutes.homePath() },
    { name: 'Successor Borrowers', to: appRoutes.successorBorrowersPath() },
    { name: 'Pools', to: appRoutes.poolsPath() },
    { name: 'Loans', to: appRoutes.loansPath() },
    { name: 'Deals', to: appRoutes.dealsPath() },
    { name: 'Parties', to: appRoutes.partiesPath() },
    { name: 'Live Forms', to: appRoutes.liveFormsPath() },
    { name: 'Corp', to: appRoutes.staffPath() },
    { name: 'Tools', to: appRoutes.toolsPath() },
    { name: 'Funding Notes', to: appRoutes.fundingNotesPath() },
  ];
  const [loadHelpFile, { isPending: isHelpFileLoading }] = useGetHelpFile();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isAuthBoxOpen, setIsAuthBoxOpen] = useState(false);
  const authPanelButton = useRef();

  const toggleAuthPanelClick = () => {
    setIsAuthBoxOpen(!isAuthBoxOpen);
  };

  const handleCloseAuthPanel = () => {
    setIsAuthBoxOpen(false);
  };

  const handleLogOut = async () => {
    handleCloseAuthPanel();
    signOut();
    router.push(appRoutes.authPath());
  };

  const handleHelpClick = () => loadHelpFile();

  const currentUserName = UserPresenter.userName(currentUser);

  return (
    <header className={classes.header}>
      <div className={classes.headerWrap}>
        {isQaEnvironment() ? (
          <>
            <Icon name="logoQa" className={classes.logoQa} />
            <Icon name="qaBadge" className={classes.qaBadge} />
          </>
        ) : (
          <Icon name="logoLight" className={classes.logo} />
        )}
        <Button
          className={classes.searchPanel}
          onClick={() => setIsSearchOpen(!isSearchOpen)}
          startIcon={<Icon name="searchIcon" />}
        >
          Search By
        </Button>
        <nav className={classes.headerNav}>
          {navList.map(navItem => (
            <NavHashLink
              key={navItem.name}
              isActive={location => location?.url.startsWith(navItem.to)}
              to={navItem.to}
            >
              {navItem.name}
            </NavHashLink>
          ))}
        </nav>
      </div>
      <ClickAwayListener onClickAway={handleCloseAuthPanel}>
        <div className={classes.headerWrap}>
          <Button
            className={classes.authPanel}
            onClick={toggleAuthPanelClick}
            ref={authPanelButton}
          >
            {currentUserName}
          </Button>
          {isAuthBoxOpen && (
            <div className={classes.authPanelMenu}>
              <NavLink
                className={classes.authPanelLink}
                exact
                to={appRoutes.changePassword()}
                onClick={handleCloseAuthPanel}
              >
                Account Settings
              </NavLink>
              <NavLink className={classes.authPanelLink} exact to={{}} onClick={handleLogOut}>
                Log out
              </NavLink>
              <Divider className={classes.divider} orientation="horizontal" />
              <div className={classes.helpFileLinkWrap}>
                <NavLink
                  className={clsx(classes.authPanelLink, classes.helpLink)}
                  exact
                  to={{}}
                  onClick={handleHelpClick}
                >
                  Help
                </NavLink>
                {isHelpFileLoading && <Loader className={classes.helpFileLoader} />}
              </div>
            </div>
          )}
        </div>
      </ClickAwayListener>
      <SearchPanel open={isSearchOpen} onClose={() => setIsSearchOpen(false)} />
    </header>
  );
};

export default Header;
