const CACHE_TAG = {
  administrativeAgent: 'AdministrativeAgent',
  configurationDeal: 'ConfigurationDeal',
  extendedLoan: 'ExtendedLoan',
  globalSnapshot: 'GlobalSnapshot',
  helpFileLocation: 'HelpFileLocation',
  loan: 'Loan',
  loansCount: 'LoanCount',
  loanDefeasedPayment: 'LoanDefeasedPayment',
  loanDefeasedPaymentSummary: 'loanDefeasedPaymentSummary',
  loanEscrowAccount: 'LoanEscrowAccount',
  loanGovernmentSecurity: 'loanGovernmentSecurity',
  loanMarketDiscount: 'LoanMarketDiscount',
  loanMarketDiscountSummary: 'LoanMarketDiscountSummary',
  manager: 'Manager',
  organization: 'Organization',
  person: 'Person',
  personChoice: 'PersonChoice',
  pool: 'Pool',
  poolChoice: 'PoolChoice',
  user: 'User',
  recentlyClosedDeal: 'RecentlyClosedDeal',
  reconciliationDiscrepancy: 'ReconciliationDiscrepancy',
  report: 'Report',
  reconciliationInput: 'ReconciliationInput',
  reconciliationInputsSummary: 'ReconciliationInputsSummary',
  reconciliationInputsAvailableYears: 'ReconciliationInputsAvailableYears',
  successorBorrower: 'SuccessorBorrower',
  successorBorrowerChoice: 'SuccessorBorrowerChoice',
  successorBorrowerConfiguration: 'SuccessorBorrowerConfiguration',
  option: 'Option',
};

Object.freeze(CACHE_TAG);

export { CACHE_TAG };
