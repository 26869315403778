import { useState, useContext } from 'react';

import { AppAppearanceContext, AppAppearanceProvider } from 'contexts/AppAppearanceContext';

const useAppAppearanceContext = () => useContext(AppAppearanceContext);

const useAppAppearance = () => {
  const [isSideBarShown, setSideBarShown] = useState(true);

  const showSideNav = () => setSideBarShown(true);
  const hideSideNav = () => setSideBarShown(false);
  const toggleSideBar = () => setSideBarShown(!isSideBarShown);

  return {
    isSideBarShown,
    isSideBarHidden: !isSideBarShown,
    showSideNav,
    hideSideNav,
    toggleSideBar,
  };
};

export { useAppAppearance, useAppAppearanceContext, AppAppearanceProvider };
