import { values, keys, isNil } from 'ramda';

import { formatCurrency } from 'utils/numbers';
import { formatDateFromTo } from 'utils/date';

export const columns = [
  { name: 'Note' },
  { loanAmount: 'Loan Amount' },
  { maturityDate: 'Maturity Date' },
  { action: 'Action' },
];
columns.getNames = () => columns.map(item => values(item)[0]);
columns.getDataKeys = () => columns.map(item => keys(item)[0]).filter(key => key !== 'action');

export const createTableRows = list => {
  if (isNil(list)) return [];
  const result = list.map(rowData => ({
    id: rowData.id,
    data: {
      ...rowData,
      loanAmount: formatCurrency(rowData.loanAmount),
      maturityDate: formatDateFromTo(rowData.maturityDate, 'yyyy-MM-dd', 'MM/dd/yyyy') ?? '-',
    },
  }));
  return result;
};

export const makeTotal = item => {
  const total = item?.totalLoanAmount;
  if (!total) return ' - ';
  return formatCurrency(total);
};
