import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    errorWrap: {
      paddingRight: '32px',
      paddingLeft: '32px',
      textAlign: 'right',
    },
    error: {
      color: theme.palette.coralRed,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: theme.typography.pxToRem(14),
    },
  }),
  { name: 'NonFieldError' },
);
