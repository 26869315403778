import apiRoutes from 'router/routes/ApiRoutes';
import apiV2Routes from 'router/routes/ApiV2Routes';

import FetchHelpers from 'utils/FetchHelpers';
import { decamelize } from 'utils/keysConverter';
import { isApiV2 } from 'utils/apiVersions';

export default {
  async index(params = {}, apiVersion) {
    const url = isApiV2(apiVersion) ? apiV2Routes.loansPath() : apiRoutes.loansPath();
    return FetchHelpers.get(url, decamelize(params));
  },
  async choices(params = {}) {
    const url = apiRoutes.loanChoicesPath();
    return FetchHelpers.get(url, decamelize(params));
  },
  async show(id, params) {
    const url = apiRoutes.loanPath(id);
    return FetchHelpers.get(url, params);
  },
  async delete(id, params) {
    const url = apiRoutes.loanPath(id);
    return FetchHelpers.delete(url, params);
  },
  async update(id, params) {
    const url = apiRoutes.loanPath(id);
    return FetchHelpers.put(url, params);
  },
  async create(params) {
    const url = apiRoutes.loansPath();
    return FetchHelpers.post(url, params);
  },
  async loansPartialUpdate(id, params) {
    const url = apiRoutes.loanPath(id);
    return FetchHelpers.patch(url, params);
  },
  async governmentSecurities(id, params) {
    const url = apiRoutes.loanGovernmentSecuritiesPath(id);
    return FetchHelpers.get(url, params);
  },
  async originalLenders(params) {
    const url = apiRoutes.originalLendersPath();
    return FetchHelpers.get(url, params);
  },
  async servicerCounsel(params) {
    const url = apiRoutes.personsChoicesPath();
    return FetchHelpers.get(url, params);
  },
  async bulkUpdate(params, queryParams) {
    const url = apiRoutes.loansPath();
    return FetchHelpers.patch(url, params, queryParams);
  },
  async moodysLoanLookup(params) {
    const url = apiRoutes.moodysLoanLookupPath();
    return FetchHelpers.get(url, params);
  },
};
