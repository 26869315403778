import PropTypes from 'prop-types';
import { keys, isNil } from 'ramda';

import { BooleanFieldLabels } from 'const';

import { CELL_TYPES } from 'enums';

import PersonPresenter from 'presenters/PersonPresenter';
import AddressPresenter from 'presenters/AddressPresenter';

import { shortDescriptionModel } from 'utils/PropTypesPresenter';
import { formatDate } from 'utils/date';
import { formatCurrency, toPercent } from 'utils/numbers';
import { getAddressString } from 'utils/dataTransform';
import { getLoanStatusLabel } from 'utils/loanStatusHelpers';

export const informationColumnConfig = {
  dealName: {
    displayedName: 'Deal Name',
  },
  dealManager: {
    displayedName: 'Deal Manager',
  },
  filingRef: {
    displayedName: 'Filing Ref',
  },
  bloombergName: {
    displayedName: 'Bloomberg Name',
  },
  status: {
    displayedName: 'Status',
  },
  statusNote: {
    displayedName: 'Status Note',
  },
  propertyAddress: {
    displayedName: 'Property Address',
  },
  loanType: {
    displayedName: 'Property Type',
  },
  noteDated: {
    displayedName: 'Original or Assumed (if NY-Style) Note Date',
  },
  noteName: {
    displayedName: 'Original or Assumed Note Name',
  },
  noteDatedNyOn: {
    displayedName: 'Original Note Date (if NY-Style used above)',
  },
  noteNameNy: {
    displayedName: 'Original Note Name if NY Style Used Above',
  },
  assumedDate: {
    displayedName: 'Assumed Date',
  },
  otherLoanDocsAssumed: {
    displayedName: 'Other Original Loan Docs Assumed',
  },
  originalDefeasanceNotOriginalSbDate: {
    displayedName: 'Original Defeasance Date (if not original SB)',
  },
  firstScheduledPaymentDate: {
    displayedName: 'First Scheduled SB Payment Date',
  },
  parRepayDate: {
    displayedName: 'Aggressive Par Repay Date',
  },
  parRepayConservativeDate: {
    displayedName: 'Conservative Par Repay Date',
  },
  parRepayType: {
    displayedName: 'Par Repay Type',
  },
  parRepayTimeLanguage: {
    displayedName: 'Par Repay Time Language',
  },
  isParRepayNegated: {
    displayedName: 'PRP Negated in defeasance docs',
  },
  isAllowBuyThrough: {
    displayedName: 'Lender allowing original borrower to buy through the early date?',
  },
  allowBuyThroughDate: {
    displayedName: 'Original Maturity Date Was',
  },
  isDocAllowBuyThrough: {
    displayedName: 'Loan document language allows borrower to buy through the early date?',
  },
  docAllowBuyThroughDate: {
    displayedName: 'Original Maturity Date Was',
  },
  parRepayNote: {
    displayedName: 'Par Repay Note',
  },
  parRepayNoticeDate: {
    displayedName: 'Par Repay Notice By Date',
  },
  parRepayNoticeSentDate: {
    displayedName: 'Par Repay Notice Sent Date',
  },
  parRepayNoticeLenderDate: {
    displayedName: 'PRP Notice to Day Lender',
  },
  parRepayNoticeCustodianDate: {
    displayedName: 'PRP Notice to Custodian',
  },
  maturityDate: {
    displayedName: 'Maturity Date',
  },
  isMaturityNonbusiness: {
    displayedName: 'Maturity Date is non-business',
  },
  isAddInterestCollected: {
    displayedName: 'Additional Interest Collected',
  },
  businessdayConvention: {
    displayedName: 'Business Day Convention',
  },
  gracePeriodPayments: {
    displayedName: 'Grace Period Payments',
  },
  gracePeriodMaturity: {
    displayedName: 'Grace Period Maturity',
  },
  maturityDateNote: {
    displayedName: 'Maturity Date Note',
  },
  paymentDay: {
    displayedName: 'Payment Day',
  },
  originalLender: {
    displayedName: 'Original Lender',
  },
  originalPrincipalBalance: {
    displayedName: 'Original or Assumed (if NY-Style) Principal Balance',
  },
  originalPrincipalBalanceNy: {
    displayedName: 'Original Principal Balance (if partial or NY-Style used above)',
  },
  principalBalanceAtTimeOfDefeasance: {
    displayedName: 'Principal Balance at Time of Defeasance',
  },
  costOfSecurities: {
    displayedName: 'Cost of Securities',
  },
  securitiesUsed: {
    displayedName: 'Securities Used',
  },
  securitiesBrokerDealer: {
    displayedName: 'Securities Broker Dealer',
  },
  monthlyPaymentAmount: {
    displayedName: 'Monthly Payment Amount',
  },
  isMonthlyPaymentAmountSame: {
    displayedName: 'Monthly Payment Amount Constant',
  },
  balloonPaymentAmount: {
    displayedName: 'Balloon Payment',
  },
  isInterestOnly: {
    displayedName: 'Interest Only',
  },
  monthlyPaymentAmountNotes: {
    displayedName: 'Monthly Payment Amount Notes',
  },
  statementBalanceNotes: {
    displayedName: 'Statement Balance Issue Notes',
  },
  term: {
    displayedName: 'Term',
  },
  amortization: {
    displayedName: 'Amortization',
  },
  coupon: {
    displayedName: 'Coupon',
  },
  interestMethod: {
    displayedName: 'Interest Method',
  },
  isRatingAgencyConsentRequired: {
    displayedName: 'Rating Agency Consent Required',
  },
  ratingAgencyConsentNotes: {
    displayedName: 'Rating Agency Consent Notes',
  },
  isNewYorkStyle: {
    displayedName: 'New York Style',
  },
  newLenderText: {
    displayedName: 'New Lender if NY Style',
  },
  isPartialDefeasance: {
    displayedName: 'Partial',
  },
  partialDefeasanceNotes: {
    displayedName: 'Partial Notes',
  },
  isCrossDefaulted: {
    displayedName: 'Cross-defaulted or Cross-collateralized',
  },
  crossDefaultedNotes: {
    displayedName: 'Cross Notes',
  },
  dealNotes: {
    displayedName: 'Deal Notes',
  },
  dealKickoffDate: {
    displayedName: 'Deal Kickoff Date',
  },
  sbAssumption: {
    displayedName: 'SB Assumption Fee',
  },
  dhcLegalFee: {
    displayedName: 'DHC Legal Fee',
  },
  securitiesPurchasedFee: {
    displayedName: 'Securities Purchased Fee',
  },
  causeyAccruedInterest: {
    displayedName: "Causey's Accrued Interest",
  },
};

export const partiesColumnConfig = {
  masterServicerLoanNumber: {
    displayedName: 'Master Servicer Loan Number',
  },
  masterServicerName: {
    displayedName: 'Master Servicer',
  },
  loanServicer: {
    displayedName: 'Loan Statement Servicer',
  },
  loanServicerLoanNumber: {
    displayedName: 'Loan Servicer Loan Number',
  },
  alternateLoanNumber: {
    displayedName: 'Alternate Loan #:',
  },
  alternateLoanNote: {
    displayedName: 'Alternate Loan Note:',
  },
  servicerCounsel1: {
    displayedName: 'Servicer Counsel Contact 1:',
    cellType: CELL_TYPES.information,
  },
  servicerCounsel2: {
    displayedName: 'Servicer Counsel Contact 2:',
    cellType: CELL_TYPES.information,
  },
  custodian: {
    displayedName: 'Custodian',
    cellType: CELL_TYPES.information,
  },
  custodianAccountNumber: {
    displayedName: 'Custodian Account Number',
  },
  custodianFee: {
    displayedName: 'Custodian Fee:',
  },
  defaultPermittedInvestment: {
    displayedName: 'Default Permitted Investment:',
  },
  accountant: {
    displayedName: 'Accountant:',
  },
  accountantReportTitle: {
    displayedName: 'Accountant Report Title',
  },
  accountantReportType: {
    displayedName: 'Accountant Report Type',
  },
  accountantReportNotes: {
    displayedName: 'Accountant Report Type Notes',
  },
  defeasanceConsultant: {
    displayedName: 'Defeasance Consultant',
  },
  sbDesignation: {
    displayedName: 'SB Designation/Referral',
  },
  partyToDesignateSb: {
    displayedName: 'Party to Designate SB',
  },
  originalBorrowerName: {
    displayedName: "Original Borrower's Name",
  },
  originalBorrowerFormationEntityType: {
    displayedName: 'Original Borrower Formation Entity Type',
  },
  originalBorrowerContactField: {
    displayedName: 'Original Borrower Contact',
    cellType: CELL_TYPES.information,
  },
  originalBorrowerCounselContactField: {
    displayedName: 'Original Borrower Counsel Contact',
    cellType: CELL_TYPES.information,
  },
};

export const dealChecklistColumnConfig = {
  draftDefeasanceDocsReceivedDate: {
    displayedName: 'Draft Defeasance Docs Received Date',
  },
  draftDefeasanceDocsComments: {
    displayedName: 'Draft Defeasance Docs Comments Provided',
  },
  sbType: {
    displayedName: 'SB Type',
  },
  einRequested: {
    displayedName: 'EIN Requested',
  },
  organizationDocsSent: {
    displayedName: 'SB Certificate of formation Sent For Filing',
  },
  sosFilingConfirmed: {
    displayedName: 'SOS Filing Confirmed',
  },
  einReceived: {
    displayedName: 'EIN Received',
  },
  bankAccountsRequested: {
    displayedName: 'Bank Accounts Requested',
  },
  opinionCertsPreparedSent: {
    displayedName: 'Opinion Certs Prepared/Sent',
  },
  sbInfoSentToServicercounsel: {
    displayedName: "SB Info (EIN/SOSID) Sent to Servicer's Counsel",
  },
  sbDocsFormedSentToServicerCounsel: {
    displayedName: "SB Docs Formed / Sent to Servicer's Counsel",
  },
  defeasanceDocsSentToOutsidecounsel: {
    displayedName: 'Defeasance Docs Sent to Outside Counsel',
  },
  sbDraftAuthorizationOpinionPrepared: {
    displayedName: 'SB Draft Authorization Opinion Prepared',
  },
  sbDraftNonconOpinionPrepared: {
    displayedName: 'SB Draft Noncon / Authority to File Opinions',
  },
  certifiedArticlesRequested: {
    displayedName: 'Updated Good Standings Requested',
  },
  certifiedArticlesSentToServicerCounselDate: {
    displayedName: "Updated Good Standings Sent to Servicer's Counsel",
  },
  originalLoanDocumentsRequested: {
    displayedName: 'Original Loan Documents Requested',
  },
  originalLoanDocumentsReceived: {
    displayedName: 'Original Loan Documents Received',
  },
  originalLoanDocumentsReviewedDate: {
    displayedName: 'Original Loan Documents Reviewed Date',
  },
  originalLoanReviewer: {
    displayedName: 'Original Loan Documents Reviewed by',
  },
  originalLoanDocumentsIssues: {
    displayedName: 'Original Loan Documents Issues',
  },
  isOriginalLoanDocumentsIssuesResolved: {
    displayedName: 'Original Loan Documents Issues Resolved',
  },
  draftAccountantReportReceived: {
    displayedName: 'Draft Accountant Report Received',
  },
  draftAccountantReportReviewed: {
    displayedName: 'Draft Accountant Report Reviewed',
  },
  servicerAmortizationScheduleReceived: {
    displayedName: 'Servicer Am Schedule Received',
  },
  servicerAmortizationScheduleReviewed: {
    displayedName: 'Servicer Am Schedule Reviewed',
  },
  executionDefeasanceDocumentsReceivedDate: {
    displayedName: 'Execution Defeasance Documents Received Date',
  },
  committeePackagePreparedDate: {
    displayedName: 'Committee Package Prepared Date',
  },
  loanSummaryPreparedDate: {
    displayedName: 'Loan Summary Prepared Date',
  },
  finalDocumentsDeliveredToServicercounselDate: {
    displayedName: "Final Documents Delivered to Servicer's Counsel Date",
  },
  postClosingAdminletterSentToServicerDate: {
    displayedName: 'Post Closing Admin Letter Sent to Servicer',
  },
  finalReviewDate: {
    displayedName: 'Final Review Completed Date',
  },
};

export const noteIssuesColumnConfig = {
  noteIssues: {
    displayedName: 'Note Issues',
  },
};

export const postClosingColumnConfig = {
  accountingPayoffDate: {
    displayedName: 'Accounting Payoff Date',
  },
  floatRate: {
    displayedName: 'Float Rate',
  },
  floatReceivedToDate: {
    displayedName: 'Float Received To Date',
  },
  float1Received: {
    displayedName: 'Float 1 Received',
  },
  float2Received: {
    displayedName: 'Float 2 Received',
  },
  parRepayCashReceived: {
    displayedName: 'PRP Total Cash Received',
  },
  parRepayCashReceivedNotes: {
    displayedName: 'PRP Total Cash Received Notes',
  },
  parRepayNetIncome: {
    displayedName: 'PRP Net Income',
  },
  parRepayNetIncomeNotes: {
    displayedName: 'PRP Net Income Notes',
  },
  sbPrepayFees: {
    displayedName: 'SB Prepay Fees',
  },
  totalProceeds: {
    displayedName: 'Total Proceeds',
  },
  mortgageInterest: {
    displayedName: 'Mortgage Interest',
  },
  isClosingBinderReceived: {
    displayedName: 'Closing Binder Received',
  },
  closingBinderReceivedDate: {
    displayedName: 'Closing Binder Received Date:',
  },
  closingBinderIssues: {
    displayedName: 'Closing Binder Issues',
  },
  thirdPartyFees: {
    displayedName: 'Third Party Fees',
  },
  thirdPartyFeesNote: {
    displayedName: 'Third Party Fees Note',
  },
  thirdPartyFeesStatus: {
    displayedName: 'Third Party Fees Status',
  },
  isAccountReportUploaded: {
    displayedName: 'Accountant Report Uploaded',
  },
  balanceAdjustment: {
    displayedName: 'OID Balance Adjustment',
  },
  oidBalanceAdjustmentNote: {
    displayedName: 'OID Balance Adjustment Note',
  },
};

export const sharingColumnConfig = {
  hasSideLetter: {
    displayedName: 'Side Letter',
  },
  sideLetterNotes: {
    displayedName: 'Side Letter Notes',
  },
  sideLetterTriggerYear: {
    displayedName: 'Side Letter Trigger Year',
  },
  sideLetterBorrowerPrincipal: {
    displayedName: 'Side Letter Borrower Principal',
  },
  sideLetterNature: {
    displayedName: 'Side Letter Nature',
  },
  originalBorrowerPercentageSplit: {
    displayedName: 'Original Borrower Percentage Split',
  },
  successorBorrowerPercentageSplit: {
    displayedName: 'Successor Borrower Percentage Split',
  },
  sideLetterTerminationDate: {
    displayedName: 'Side Letter Termination Date',
  },
  sideLetterTerminationNotes: {
    displayedName: 'Side Letter Termination Notes',
  },
  isBuyUp: {
    displayedName: 'Buy Up',
  },
  forwardRate: {
    displayedName: 'FW Rate',
  },
  buyUpEstimate: {
    displayedName: 'Buy Up Estimate',
  },
  buyUpAmount: {
    displayedName: 'Buy Up Amount',
  },
  buyUpPaymentDate: {
    displayedName: 'Buy Up Payment Date',
  },
  sharingArrangementFinancialServicesOrg: {
    displayedName: 'Sharing Arrangement',
  },
  sharingArrangementAmount: {
    displayedName: 'Sharing Arrangement Amount',
  },
  sharingArrangementPaymentDate: {
    displayedName: 'Sharing Arrangement Payment Date',
  },
};

export const monetizationColumnConfig = {
  monetizationParty: {
    displayedName: 'Monet Party',
  },
  monetContractAdvance: {
    displayedName: 'Monet Contract (Advance %)',
  },
  monetizationDate: {
    displayedName: 'Monet Date',
  },
  collateralValue: {
    displayedName: 'Collateral Value',
  },
  totalAvailableAdvance: {
    displayedName: 'Total Available Advance',
  },
  monetizationFutureValue: {
    displayedName: 'Initial Future Value',
  },
  truistNote: {
    displayedName: 'Truist Note #',
  },
  adjustedParRepayBusinessDaysDate: {
    displayedName: 'Payback Date',
  },
};

export const hedgingColumnConfig = {
  hedgeParty: {
    displayedName: 'Hedge Party',
  },
  hedgeDate: {
    displayedName: 'Hedge Date',
  },
  notionalAmount: {
    displayedName: 'Notional Amount',
  },
  hedgeCounterpartyRef: {
    displayedName: 'Hedge Counterparty Ref #',
  },
  hedgeEndDate: {
    displayedName: 'Hedge End Date',
  },
  hedgedValue: {
    displayedName: 'Hedged Value',
  },
  hedgeRate: {
    displayedName: 'Hedge Rate (Fed Fund Rate - Fixed Rate From Exhibit)',
  },
  paybackDate: {
    displayedName: 'Payback Date',
  },
  hedgeParty2: {
    displayedName: 'Hedge Party',
  },
  hedgeDate2: {
    displayedName: 'Hedge Date',
  },
  notionalAmount2: {
    displayedName: 'Notional Amount',
  },
  hedgeCounterpartyRef2: {
    displayedName: 'Hedge Counterparty Ref #',
  },
  hedgeEndDate2: {
    displayedName: 'Hedge End Date',
  },
  hedgedValue2: {
    displayedName: 'Hedged Value',
  },
  hedgeRate2: {
    displayedName: 'Hedge Rate (Fed Fund Rate - Fixed Rate From Exhibit)',
  },
  paybackDate2: {
    displayedName: 'Payback Date',
  },
};

export const createDefaultValues = configuration => {
  if (isNil(configuration)) return null;

  const { status, defaultPermittedInvestmentId, dealManagerId, successorBorrower } = configuration;
  const dealKickoffDate = new Date();

  return {
    status,
    defaultPermittedInvestment: defaultPermittedInvestmentId,
    dealManager: dealManagerId,
    dealKickoffDate,
    successorBorrower: successorBorrower ? Number(successorBorrower) : null,
  };
};

export const isServicerCell = key => partiesColumnConfig[key]?.cellType === CELL_TYPES.servicer;

export const isInformationCell = key =>
  partiesColumnConfig[key]?.cellType === CELL_TYPES.information;

export const isCommonCell = key => typeof partiesColumnConfig[key]?.cellType === 'undefined';

const splitByParties = columnConfigKeys =>
  columnConfigKeys.reduce(
    (acc, key) => {
      const isBelongsToSecondParty = key.endsWith('2');
      const [party1, party2] = acc;
      if (isBelongsToSecondParty) {
        party2.push(key);
      } else {
        party1.push(key);
      }
      return [party1, party2];
    },
    [[], []],
  );

export const getLoanSectionsKeys = () => ({
  loanInformationKeys: keys(informationColumnConfig),
  loanPartiesKeys: keys(partiesColumnConfig),
  dealChecklistKeys: keys(dealChecklistColumnConfig),
  noteIssuesKeys: keys(noteIssuesColumnConfig),
  postClosingKeys: keys(postClosingColumnConfig),
  sharingKeys: keys(sharingColumnConfig),
  monetizationKeys: keys(monetizationColumnConfig),
  hedgingKeys: [...splitByParties(keys(hedgingColumnConfig))],
});

export const getLoanStatus = loan => loan?.status;
export const getDealName = loan => loan?.dealName;

export const getTableData = data => {
  if (isNil(data)) {
    return null;
  }

  return {
    ...data,
    headerName: `${data.filingRef ? `(${data.filingRef}) ` : ''}${data.dealName}`,
    status: getLoanStatusLabel(data.status),
    isCrossDefaulted: BooleanFieldLabels[data.isCrossDefaulted],
    propertyAddress: getAddressString(data?.propertyAddress),
    noteDated: formatDate(data.noteDated),
    noteDatedNyOn: formatDate(data.noteDatedNyOn),
    assumedDate: formatDate(data.assumedDate),
    accountingPayoffDate: formatDate(data.accountingPayoffDate),
    originalDefeasanceNotOriginalSbDate: formatDate(data.originalDefeasanceNotOriginalSbDate),
    firstScheduledPaymentDate: formatDate(data.firstScheduledPaymentDate),
    parRepayDate: formatDate(data.parRepayDate),
    parRepayConservativeDate: formatDate(data.parRepayConservativeDate),
    isParRepayNegated: BooleanFieldLabels[data?.isParRepayNegated],
    isAllowBuyThrough: BooleanFieldLabels[data?.isAllowBuyThrough],
    isDocAllowBuyThrough: BooleanFieldLabels[data?.isDocAllowBuyThrough],
    allowBuyThroughDate: formatDate(data.allowBuyThroughDate),
    docAllowBuyThroughDate: formatDate(data.docAllowBuyThroughDate),
    parRepayNoticeDate: formatDate(data.parRepayNoticeDate),
    parRepayNoticeSentDate: formatDate(data.parRepayNoticeSentDate),
    maturityDate: formatDate(data.maturityDate),
    isMaturityNonbusiness: BooleanFieldLabels[data?.isMaturityNonbusiness],
    isAddInterestCollected: BooleanFieldLabels[data?.isAddInterestCollected],
    isMonthlyPaymentAmountSame: BooleanFieldLabels[data?.isMonthlyPaymentAmountSame],
    isInterestOnly: BooleanFieldLabels[data?.isInterestOnly],
    isRatingAgencyConsentRequired: BooleanFieldLabels[data?.isRatingAgencyConsentRequired],
    isNewYorkStyle: BooleanFieldLabels[data?.isNewYorkStyle],
    isPartialDefeasance: BooleanFieldLabels[data?.isPartialDefeasance],
    crossCollateral: BooleanFieldLabels[data?.crossCollateral],
    businessdayConvention: data?.businessdayConvention,
    gracePeriodPayments: data?.gracePeriodPayments,
    gracePeriodMaturity: data?.gracePeriodMaturity,
    securitiesBrokerDealer: data?.securitiesBrokerDealer?.name,
    dealKickoffDate: formatDate(data?.dealKickoffDate),
    originalPrincipalBalance: formatCurrency(data?.originalPrincipalBalance),
    originalPrincipalBalanceNy: formatCurrency(data?.originalPrincipalBalanceNy),
    principalBalanceAtTimeOfDefeasance: formatCurrency(data?.principalBalanceAtTimeOfDefeasance),
    costOfSecurities: formatCurrency(data?.costOfSecurities),
    balloonPaymentAmount: formatCurrency(data?.balloonPaymentAmount),
    monthlyPaymentAmount: formatCurrency(data?.monthlyPaymentAmount),
    sbAssumption: formatCurrency(data?.sbAssumption),
    dhcLegalFee: formatCurrency(data?.dhcLegalFee),
    securitiesPurchasedFee: formatCurrency(data?.securitiesPurchasedFee),
    causeyAccruedInterest: formatCurrency(data?.causeyAccruedInterest),
    dealManager: data?.dealManager?.username,
    masterServicerName: data?.masterServicerName,
    // parties fields
    loanServicer: data?.loanServicer?.name,
    servicerCounsel1: {
      title: data?.servicerCounsel1?.employer?.name,
      fields: {
        name: PersonPresenter.fullName(data?.servicerCounsel1),
      },
    },
    servicerCounsel2: {
      title: data?.servicerCounsel2?.employer?.name,
      fields: {
        name: PersonPresenter.fullName(data?.servicerCounsel2),
      },
    },
    custodian: {
      title: data?.custodian?.name,
      fields: {
        name: PersonPresenter.fullName(data?.custodianContact),
      },
    },
    custodianFee: formatCurrency(data?.custodianFee),
    accountant: data?.accountant?.name,
    accountantContact: {
      title: null,
      fields: {
        name: PersonPresenter.fullName(data?.accountantContact),
      },
    },
    defeasanceConsultant: data?.defeasanceConsultant?.name,
    sbDesignation: data?.sbDesignation,
    originalBorrowerContactField: {
      title: null,
      fields: {
        name: data?.originalBorrowerContact,
        phone: data?.originalBorrowerPhone,
        email: data?.originalBorrowerEmail,
        organization: data?.originalBorrowerCompany,
        notes: data?.originalBorrowerNotes,
      },
    },
    originalBorrowerCounselContactField: {
      title: null,
      fields: {
        name: data?.originalBorrowerCounselContact,
        phone: data?.originalBorrowerCounselPhone,
        email: data?.originalBorrowerCounselEmail,
        organization: data?.originalBorrowerCounselCompany,
        notes: data?.originalBorrowerCounselNotes,
      },
    },
    // dealChecklist fields
    draftDefeasanceDocsReceivedDate: formatDate(data.draftDefeasanceDocsReceivedDate),
    certifiedArticlesSentToServicerCounselDate: formatDate(
      data.certifiedArticlesSentToServicerCounselDate,
    ),
    originalLoanDocumentsReviewedDate: formatDate(data.originalLoanDocumentsReviewedDate),
    executionDefeasanceDocumentsReceivedDate: formatDate(
      data.executionDefeasanceDocumentsReceivedDate,
    ),
    loanSummaryPreparedDate: formatDate(data.loanSummaryPreparedDate),
    finalDocumentsDeliveredToServicercounselDate: formatDate(
      data.finalDocumentsDeliveredToServicercounselDate,
    ),
    postClosingAdminletterSentToServicerDate: formatDate(
      data.postClosingAdminletterSentToServicerDate,
    ),
    finalReviewDate: formatDate(data.finalReviewDate),
    committeePackagePreparedDate: formatDate(data.committeePackagePreparedDate),
    originalLoanReviewer: data?.originalLoanReviewer?.username,
    isOriginalLoanDocumentsIssuesResolved:
      BooleanFieldLabels[data?.isOriginalLoanDocumentsIssuesResolved],
    // Post Closing Fields
    floatReceivedToDate: formatCurrency(data.floatReceivedToDate),
    float1Received: formatCurrency(data.float1Received),
    float2Received: formatCurrency(data?.float2Received),
    thirdPartyFees: formatCurrency(data.thirdPartyFees),
    isClosingBinderReceived: BooleanFieldLabels[data.isClosingBinderReceived],
    closingBinderReceivedDate: formatDate(data.closingBinderReceivedDate),
    prpTotalCashRecieved: formatCurrency(data?.parRepayCashrecieved),
    prpTotalCashRecievedNotes: data?.parRepayCashrecievedNotes,
    parRepayNetIncome: formatCurrency(data?.parRepayNetIncome),
    parRepayNetIncomeNotes: data?.parRepayNetIncomeNotes,
    sbPrepayFees: formatCurrency(data?.sbPrepayFees),
    totalProceeds: formatCurrency(data?.totalProceeds),
    mortgageInterest: formatCurrency(data?.mortgageInterest),
    isAccountReportUploaded: BooleanFieldLabels[data?.isAccountReportUploaded],
    balanceAdjustment: formatCurrency(data.balanceAdjustment),
    // Sharing/Monetization Fields
    hasSideLetter: BooleanFieldLabels[data?.hasSideLetter],
    sideLetterTerminationDate: formatDate(data.sideLetterTerminationDate),
    isBuyUp: BooleanFieldLabels[data?.isBuyUp],
    forwardRate: data?.forwardRate,
    buyUpEstimate: formatCurrency(data?.buyUpEstimate),
    buyUpAmount: formatCurrency(data.buyUpAmount),
    buyUpPaymentDate: formatDate(data?.buyUpPaymentDate),
    sharingArrangementFinancialServicesOrg: data?.sharingArrangementFinancialServicesOrg?.name,
    sharingArrangementAmount: formatCurrency(data?.sharingArrangementAmount),
    sharingArrangementPaymentDate: formatDate(data?.sharingArrangementPaymentDate),
    monetizationParty: data?.monetizationParty?.name,
    monetizationDate: formatDate(data.monetizationDate),
    adjustedParRepayBusinessDaysDate: formatDate(data?.adjustedParRepayBusinessDaysDate),
    monetizationFutureValue: formatCurrency(data?.monetizationFutureValue),
    monetizationContract: data?.monetizationContract?.name,
    parRepayCashReceived: formatCurrency(data?.parRepayCashReceived),
    parRepayNoticeLenderDate: formatDate(data?.parRepayNoticeLenderDate),
    parRepayNoticeCustodianDate: formatDate(data?.parRepayNoticeCustodianDate),
    defaultPermittedInvestment: data?.defaultPermittedInvestment?.name,
    loanType: data?.loanType?.name,
    partyToDesignateSb: data?.partyToDesignateSb?.name,
    collateralValue: formatCurrency(data?.collateralValue),
    totalAvailableAdvance: formatCurrency(data?.totalAvailableAdvance),
    monetContractAdvance: data?.monetContractAdvance,
    truistNote: data?.truistNote?.name,

    hedgeParty: data?.hedgeParty?.name,
    hedgeDate: formatDate(data?.hedgeDate),
    notionalAmount: formatCurrency(data?.notionalAmount),
    hedgeCounterpartyRef: data?.hedgeCounterpartyRef,
    hedgeEndDate: formatDate(data?.hedgeEndDate),
    hedgedValue: formatCurrency(data?.hedgedValue),
    hedgeRate: toPercent(data?.hedgeRate),
    paybackDate: formatDate(data?.paybackDate),

    hedgeParty2: data?.hedgeParty2?.name,
    hedgeDate2: formatDate(data?.hedgeDate2),
    notionalAmount2: formatCurrency(data?.notionalAmount2),
    hedgeCounterpartyRef2: data?.hedgeCounterpartyRef2,
    hedgeEndDate2: formatDate(data?.hedgeEndDate2),
    hedgedValue2: formatCurrency(data?.hedgedValue2),
    hedgeRate2: toPercent(data?.hedgeRate2),
    paybackDate2: formatDate(data?.paybackDate2),
  };
};

const contactModel = {
  id: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  employer: shortDescriptionModel,
};

export const loanDataPropTypes = PropTypes.shape({
  id: PropTypes.number,
  successorBorrower: shortDescriptionModel,
  securitiesBrokerDealer: shortDescriptionModel,
  newLenderText: PropTypes.string,
  masterServicerName: PropTypes.string,
  loanServicer: shortDescriptionModel,
  loanServicerContact1: contactModel,
  servicerCounsel1: contactModel,
  servicerCounsel2: contactModel,
  custodian: shortDescriptionModel,
  custodianContact: contactModel,
  accountant: shortDescriptionModel,
  accountantContact: contactModel,
  defeasanceConsultant: shortDescriptionModel,
  sharingArrangementFinancialServicesOrg: shortDescriptionModel,
  monetizationParty: shortDescriptionModel,
  monetizationContractId2: PropTypes.number,
  monetizationFutureValue: PropTypes.string,
  monetizationFraInterest2: PropTypes.string,
  adjustedParRepayBusinessDays2: PropTypes.date,
  isWatchlist: PropTypes.bool,
  watchlistNotes: PropTypes.string,
  isParRepayNegated: PropTypes.string,
  isMaturityNonbusiness: PropTypes.string,
  isAddInterestCollected: PropTypes.string,
  businessdayConvention: PropTypes.string,
  gracePeriodPayments: PropTypes.string,
  gracePeriodMaturity: PropTypes.string,
  float2Received: PropTypes.string,
  parRepayCashReceived: PropTypes.string,
  parRepayCashReceivedNotes: PropTypes.string,
  parRepayNetIncome: PropTypes.string,
  parRepayNetIncomeNotes: PropTypes.string,
  sbPrepayFees: PropTypes.string,
  totalProceeds: PropTypes.string,
  mortgageInterest: PropTypes.string,
  sbDesignation: PropTypes.string,
  sharingArrangementAmount: PropTypes.string,
  parRepayType: PropTypes.string,
  dealName: PropTypes.string,
  enteredBy: PropTypes.string,
  enteredDate: PropTypes.date,
  dealManager: {
    id: PropTypes.number,
    username: PropTypes.string,
  },
  filingRef: PropTypes.number,
  bloombergName: PropTypes.string,
  status: PropTypes.number,
  statusNote: PropTypes.string,
  propertyAddress: AddressPresenter.shape(),
  loanType: shortDescriptionModel,
  noteDated: PropTypes.date,
  noteName: PropTypes.string,
  noteNameNy: PropTypes.string,
  noteDatedNyOn: PropTypes.date,
  assumedDate: PropTypes.date,
  accountingPayoffDate: PropTypes.date,
  otherLoanDocsAssumed: PropTypes.string,
  originalDefeasanceNotOriginalSbDate: PropTypes.date,
  firstScheduledPaymentDate: PropTypes.date,
  parRepayDate: PropTypes.date,
  parRepayConservativeDate: PropTypes.date,
  parRepayTimeLanguage: PropTypes.string,
  isAllowBuyThrough: PropTypes.string,
  allowBuyThroughDate: PropTypes.date,
  isDocAllowBuyThrough: PropTypes.bool,
  docAllowBuyThroughDate: PropTypes.date,
  parRepayNote: PropTypes.string,
  parRepayNoticeDate: PropTypes.date,
  parRepayNoticeSentDate: PropTypes.date,
  parRepayNoticeLenderDate: PropTypes.date,
  parRepayNoticeCustodianDate: PropTypes.date,
  maturityDate: PropTypes.date,
  maturityDateNote: PropTypes.string,
  paymentDay: PropTypes.number,
  originalLender: PropTypes.string,
  originalPrincipalBalance: PropTypes.string,
  originalPrincipalBalanceNy: PropTypes.string,
  principalBalanceAtTimeOfDefeasance: PropTypes.string,
  costOfSecurities: PropTypes.string,
  securitiesUsed: PropTypes.string,
  monthlyPaymentAmount: PropTypes.string,
  isMonthlyPaymentAmountSame: PropTypes.string,
  balloonPaymentAmount: PropTypes.string,
  isInterestOnly: PropTypes.string,
  monthlyPaymentAmountNotes: PropTypes.string,
  statementBalanceNotes: PropTypes.string,
  term: PropTypes.number,
  amortization: PropTypes.number,
  coupon: PropTypes.number,
  interestMethod: PropTypes.string,
  isRatingAgencyConsentRequired: PropTypes.string,
  ratingAgencyConsentNotes: PropTypes.string,
  isNewYorkStyle: PropTypes.string,
  partialDefeasanceNotes: PropTypes.string,
  isPartialDefeasance: PropTypes.string,
  crossDefaultedNotes: PropTypes.string,
  isCrossDefaulted: PropTypes.string,
  dealNotes: PropTypes.string,
  dealKickoffDate: PropTypes.date,
  sbAssumption: PropTypes.string,
  dhcLegalFee: PropTypes.string,
  securitiesPurchasedFee: PropTypes.string,
  causeyAccruedInterest: PropTypes.string,
  masterServicerLoanNumber: PropTypes.string,
  loanServicerName: PropTypes.string,
  alternateLoanNumber: PropTypes.string,
  alternateLoanNote: PropTypes.string,
  custodianAccountNumber: PropTypes.string,
  custodianName: PropTypes.string,
  custodianFee: PropTypes.string,
  defaultPermittedInvestment: shortDescriptionModel,
  accountantReportTitle: PropTypes.string,
  accountantReportNotes: PropTypes.string,
  accountantReportType: PropTypes.string,
  partyToDesignateSb: shortDescriptionModel,
  originalBorrowerName: PropTypes.string,
  originalBorrowerContact: PropTypes.string,
  originalBorrowerPhone: PropTypes.string,
  originalBorrowerEmail: PropTypes.string,
  originalBorrowerCompany: PropTypes.string,
  originalBorrowerNotes: PropTypes.string,
  originalBorrowerCounselPhone: PropTypes.string,
  originalBorrowerCounselEmail: PropTypes.string,
  originalBorrowerCounselCompany: PropTypes.string,
  originalBorrowerCounselNotes: PropTypes.string,
  draftDefeasanceDocsReceivedDate: PropTypes.string,
  draftDefeasanceDocsComments: PropTypes.string,
  sbType: PropTypes.string,
  einRequested: PropTypes.string,
  organizationDocsSent: PropTypes.string,
  sosFilingConfirmed: PropTypes.string,
  einReceived: PropTypes.string,
  bankAccountsRequested: PropTypes.string,
  nonconOfficerCertPrepared: PropTypes.string,
  sbInfoSentToServicercounsel: PropTypes.string,
  defeasanceDocsSentToOutsidecounsel: PropTypes.string,
  orgDocsSentToOutsidecounsel: PropTypes.string,
  sbDraftAuthorizationOpinionPrepared: PropTypes.string,
  sbDraftNonconOpinionPrepared: PropTypes.string,
  certifiedArticlesRequested: PropTypes.string,
  certifiedArticlesSentToServicerCounselDate: PropTypes.date,
  originalLoanDocumentsRequested: PropTypes.string,
  originalLoanDocumentsReceived: PropTypes.string,
  originalLoanDocumentsReviewedDate: PropTypes.date,
  originalLoanDocumentsIssues: PropTypes.string,
  isOriginalLoanDocumentsIssuesResolved: PropTypes.string,
  originalLoanReviewer: {
    id: PropTypes.number,
    username: PropTypes.string,
  },
  draftAccountantReportReceived: PropTypes.string,
  draftAccountantReportReviewed: PropTypes.string,
  executionDefeasanceDocumentsReceivedDate: PropTypes.date,
  loanSummaryPreparedDate: PropTypes.date,
  finalDocumentsDeliveredToServicercounselDate: PropTypes.date,
  postClosingAdminletterSentToServicerDate: PropTypes.date,
  finalReviewDate: PropTypes.date,
  recordBookDir: PropTypes.string,
  loanDocsIncorporatedByReference: PropTypes.bool,
  agentForServiceProcessRequirement: PropTypes.bool,
  finStatementReportingDeposit: PropTypes.bool,
  amortizationStatementAttachedNote: PropTypes.bool,
  payeeRightChange: PropTypes.bool,
  missingTermsLetter: PropTypes.bool,
  crossCollateralNote: PropTypes.bool,
  amendOtherNote: PropTypes.bool,
  ratingAgencyDefiniteFees: PropTypes.bool,
  crossCollateral: PropTypes.bool,
  amendOther: PropTypes.bool,
  soleAsset: PropTypes.bool,
  raDefeasanceFees: PropTypes.bool,
  naccCca: PropTypes.bool,
  pwGmacGeEtc: PropTypes.bool,
  sbAssumeMort: PropTypes.bool,
  sbOtherThanNote: PropTypes.bool,
  sbComplySpe: PropTypes.bool,
  defeasanceCollateralPurchased: PropTypes.bool,
  sbLimitedGuaranty: PropTypes.bool,
  loanDocsIncorporatedByReferenceResolution: PropTypes.string,
  agentForServiceProcessRequirementResolution: PropTypes.string,
  finStatementReportingDepositResolution: PropTypes.string,
  amortizationStatementAttachedNoteResolution: PropTypes.string,
  payeeRightChangeResolution: PropTypes.string,
  missingTermsLetterResolution: PropTypes.string,
  crossCollateralNoteResolution: PropTypes.string,
  amendOtherNoteResolution: PropTypes.boolean,
  ratingAgencyDefiniteFeesResolution: PropTypes.string,
  crossCollateralResolution: PropTypes.string,
  amendOtherResolution: PropTypes.string,
  soleAssetResolution: PropTypes.string,
  raDefeasanceFeesResolution: PropTypes.string,
  naccCcaResolution: PropTypes.string,
  pwGmacGeEtcResolution: PropTypes.string,
  sbAssumeMortResolution: PropTypes.string,
  sbOtherThanNoteResolution: PropTypes.string,
  sbComplySpeResolution: PropTypes.string,
  defeasanceCollateralPurchasedResolution: PropTypes.string,
  sbLimitedGuarantyResolution: PropTypes.string,
  floatRate: PropTypes.string,
  float1Received: PropTypes.string,
  floatReceivedToDate: PropTypes.string,
  closingBinderStatus: PropTypes.string,
  closingBinderReceivedDate: PropTypes.date,
  closingBinderIssues: PropTypes.string,
  thirdPartyFees: PropTypes.string,
  thirdPartyFeesNote: PropTypes.string,
  thirdPartyFeesStatus: PropTypes.string,
  isAccountReportUploaded: PropTypes.string,
  balanceAdjustment: PropTypes.string,
  oidBalanceAdjustmentNote: PropTypes.string,
  hasSideLetter: PropTypes.string,
  sideLetterNotes: PropTypes.string,
  sideLetterTriggerYear: PropTypes.string,
  sideLetterBorrowerPrincipal: PropTypes.string,
  sideLetterNature: PropTypes.string,
  originalBorrowerPercentageSplit: PropTypes.string,
  successorBorrowerPercentageSplit: PropTypes.string,
  sideLetterTerminationDate: PropTypes.date,
  sideLetterTerminationNotes: PropTypes.string,
  isBuyUp: PropTypes.string,
  forwardRate: PropTypes.number,
  buyUpEstimate: PropTypes.string,
  buyUpAmount: PropTypes.string,
  buyUpPaymentDate: PropTypes.date,
  monetizationDate: PropTypes.date,
  adjustedParRepayBusinessDaysDate: PropTypes.date,
  monetizationContract: PropTypes.number,
  monetContractAdvance: PropTypes.string,

  collateralValue: PropTypes.number,
  totalAvailableAdvance: PropTypes.number,
  truistNote: PropTypes.shape({
    id: PropTypes.number,
    loan_amount: PropTypes.string,
    maturity_date: PropTypes.string,
    name: PropTypes.string,
  }),
  hasEscrowAccounts: PropTypes.bool,

  hedgeParty: PropTypes.string,
  hedgeDate: PropTypes.date,
  notionalAmount: PropTypes.number,
  hedgeCounterpartyRef: PropTypes.string,
  hedgeEndDate: PropTypes.date,
  hedgedValue: PropTypes.number,
  hedgeRate: PropTypes.number,
  paybackDate: PropTypes.date,
  hedgeParty2: PropTypes.string,
  hedgeDate2: PropTypes.date,
  notionalAmount2: PropTypes.number,
  hedgeCounterpartyRef2: PropTypes.string,
  hedgeEndDate2: PropTypes.date,
  hedgedValue2: PropTypes.number,
  hedgeRate2: PropTypes.number,
  paybackDate2: PropTypes.date,

  errors: PropTypes.shape({}),
});
