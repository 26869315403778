import React, { useEffect, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Table, TableRow, TableHead, TableBody, TableCell } from '@material-ui/core/';
import clsx from 'clsx';
import { not, isEmpty, isNil } from 'ramda';
import useStyles from 'components/LoanEditableTable/useStyles';

import { useEditableTableContext } from 'hooks/useEditableTableControls';

import { pickData } from 'utils/forms';

import CommonTableRow from './components/CommonTableRow';

const isEditableRow = (rowId, editableRowId) => rowId === editableRowId;

export const LoanEditableTable = props => {
  const { onSave, columns, rows, dataItems, prepareBeforeSetting, formId, formFields } = props;

  const { isEditingFormShown, editableRowId } = useEditableTableContext();
  const rowKeys = useMemo(() => columns.getDataKeys(), [columns]);
  const classes = useStyles();

  const { reset: resetForm, handleSubmit } = useFormContext();

  useEffect(() => {
    if (isNil(editableRowId)) return;
    const rowData = pickData(editableRowId, dataItems);
    if (isEditingFormShown) {
      resetForm(prepareBeforeSetting(rowData));
    }
  }, [isEditingFormShown, editableRowId]);

  return (
    <form id={formId} onSubmit={handleSubmit(onSave)}>
      <Table aria-label="reconciliationTable" className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            {columns.getNames().map((name, index) => (
              <TableCell
                key={`table-head-${index}`}
                className={clsx(classes.tableHeadCell, classes.sticky)}
              >
                {name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {not(isEmpty(rows)) &&
            rows.map((row, index) => (
              <CommonTableRow
                key={index}
                row={row}
                rowKeys={rowKeys}
                isForm={isEditableRow(row.id, editableRowId) && isEditingFormShown}
                formFields={formFields}
              />
            ))}
        </TableBody>
      </Table>
    </form>
  );
};

LoanEditableTable.propTypes = {
  onSave: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  dataItems: PropTypes.arrayOf(PropTypes.shape({})),
  prepareBeforeSetting: PropTypes.func,
  formId: PropTypes.string,
  formFields: PropTypes.arrayOf(PropTypes.shape({})),
};
