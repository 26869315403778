import humps from 'humps';

import getLocalStorageEnums from 'utils/getLocalStorageEnums';
import { camelize } from 'utils/keysConverter';

const getOrganizationTypeValues = () => {
  const { organizationTypesOptions = [] } = getLocalStorageEnums();
  return camelize(
    organizationTypesOptions.reduce(
      (acc, type) => ({ ...acc, [type.label]: humps.depascalize(type.label).replace(' ', '') }),
      {},
    ),
  );
};

const getOrganizationTypes = () => {
  const { organizationTypesOptions = [] } = getLocalStorageEnums();
  return camelize(
    organizationTypesOptions.reduce((acc, type) => ({ ...acc, [type.label]: type.label }), {}),
  );
};

export { getOrganizationTypeValues, getOrganizationTypes };
