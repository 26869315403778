import MemberIndexPresenter from 'presenters/MemberIndexPresenter';
import MemberShowPresenter from 'presenters/MemberShowPresenter';

import { membersRestHooks } from 'store/MembersSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';
import { getSelectOptions } from 'utils/getSelectOptions';
import { getEntityLastUpdated } from 'utils/date';

export const useMembers = () => {
  const { members, loadMembers, member, loadMember, updateMember, createMember, deleteMember } =
    membersRestHooks.use();
  const membersTableData = MemberIndexPresenter.tableData(members?.items) || [];
  const membersTableKeys = MemberIndexPresenter.tableKeys();

  const memberTableData = MemberShowPresenter.tableData(member?.item) || [];
  const memberLastUpdated = getEntityLastUpdated(member?.item);

  const memberOptions = getSelectOptions(members.items, 'id', MemberIndexPresenter.name);

  return {
    members,
    member: member?.item,
    loadMembers,
    loadMember,
    updateMember,
    createMember,
    deleteMember,
    membersTableData,
    membersTableKeys,
    memberTableData,
    memberOptions,
    areMembersLoading: useFetchStatus(members.loadingStatus).isPending,
    isMemberLoading: useFetchStatus(member.loadingStatus).isPending,
    memberLastUpdated,
  };
};
