import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    iconWrap: {
      alignSelf: 'stretch',
      width: 64,
      minHeight: 75,
      background: theme.palette.secondary.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.white,
      borderTopLeftRadius: 'inherit',
      borderBottomLeftRadius: 'inherit',
    },
    text: {
      display: 'block',
      width: 255,
      padding: [6, 12],
      color: theme.palette.primary.main,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
      textDecoration: 'none',
    },
    errorWrap: {
      background: theme.palette.coralRed,

      '& svg': {
        width: 34,
        height: 'auto',
      },
    },
  }),
  { name: 'SnackbarToast' },
);
