import { makeStyles } from '@material-ui/core';

export default makeStyles(
  theme => ({
    noDataMessage: {
      color: theme.palette.primary.main,
      marginBottom: 0,
    },
  }),
  { name: 'NoDataMessage' },
);
