import React from 'react';

import PropTypes, { arrayOf } from 'prop-types';
import { Select, MenuItem } from '@material-ui/core';
import { useFormContext, useController } from 'react-hook-form';
import clsx from 'clsx';
import { ErrorMessage } from '@hookform/error-message';

import FormError from 'components/FormError';

import useStyles from './useStyles';

export const YearSelect = props => {
  const classes = useStyles();

  const {
    formField: { fieldKey, displayedName },
    options,
    className,
  } = props;

  const methods = useFormContext();

  const { errors, control } = methods;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name: fieldKey,
    control,
  });

  return (
    <>
      <label htmlFor={inputProps.name} className={classes.label}>
        {displayedName}
      </label>
      <Select
        className={clsx(classes.autocomplete, className)}
        {...inputProps}
        inputProps={{
          ref,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          disablePortal: true,
        }}
      >
        {options.map(item => {
          const { label, option } = item;
          return (
            <MenuItem key={label} value={option}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
      <ErrorMessage
        errors={errors}
        name={fieldKey}
        render={({ message }) => <FormError message={message} />}
      />
    </>
  );
};

YearSelect.propTypes = {
  formField: PropTypes.shape({ fieldKey: PropTypes.string, displayedName: PropTypes.string }),
  options: arrayOf(PropTypes.shape({ option: PropTypes.number, label: PropTypes.string })),
  className: PropTypes.string,
};
