import ReminderPresenter from 'presenters/ReminderPresenter';

import { remindersRestHooks } from 'store/RemindersSlice';

import { useFetchStatus } from 'utils/fetchStatusUtils';

export const useReminders = () => {
  const { reminders, loadReminders, updateReminder } = remindersRestHooks.use();
  const remindersTableData = ReminderPresenter.tableData(reminders?.items) || [];
  const remindersTableKeys = ReminderPresenter.tableKeys();

  return {
    reminders,
    loadReminders,
    updateReminder,
    remindersTableData,
    remindersTableKeys,
    areRemindersLoading: useFetchStatus(reminders.loadingStatus).isPending,
  };
};
